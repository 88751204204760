<template>
  <div>
    <p v-if="sendInProgress" style="color: red">Sending in progress for another lead.</p>
    <div v-if="calculatedHowManyTimesCanSend">
      <p class="my-1">Can Send This Lead {{ timesCanSendThisLeadAsM }} times</p>
      <p style="color: red" class="my-1" v-if="userNotWorkingOnThisGeo">
        <!--        Error: you are not working on this Geo at the moment-->
        You Didn't Choose your preference GEO target Yet !! Please Go To link:
        <b-link to="/geo-selection">GEO SELECTION</b-link>
      </p>
      <b-checkbox v-model="useFinalReceiverTTL" v-if="iAmOtherLevelTrustedUser" display="block">
        Use FinalReceiver TimeToLive
      </b-checkbox>
      <b-checkbox v-model="usePartialPhoneFilter" v-if="iAmOtherLevelTrustedUser" display="block">
        Filter By Similar Phones Sent
      </b-checkbox>
      <b-checkbox
        v-model="filterByWorkersConnectedFinalReceivers"
        v-if="iAmOtherLevelTrustedUser"
        display="block"
      >
        Filter By Action's Worker Sent To FinalReceivers
      </b-checkbox>
      <b-form-group label="Send As" label-for="send-callback-toggle">
        <b-form-radio-group
          id="send-callback-toggle"
          :options="sendModeOptions"
          v-model="sendCallback"
          class="my-3"
          :disabled="requesting"
        ></b-form-radio-group>
      </b-form-group>
      <div style="display: block">
        <b-button @click="checkHowManyTimesCanSendLeadAsM(_id)" class="mx-2 my-1">
          Re-check Lead Status
        </b-button>
        <!--      <p class="my-1">{{ timesCanSendThisLeadAsM }} times</p>-->
        <b-button
          class="m-1"
          variant="primary"
          @click="sendMLead()"
          :disabled="timesCanSendThisLeadAsM <= 0 || sendInProgress"
        >
          Send
        </b-button>
        <p v-if="sendInProgress" style="color: red">
          Sending blocked because Sending in progress for another lead.
        </p>
      </div>
    </div>
    <div v-if="sentSuccess">
      <h5>Lead sent</h5>
      <p>Email: {{ result_fullAction ? result_fullAction.email : 'unknown' }}</p>
      <p>Geo: {{ result_fullAction ? result_fullAction.location : 'unknown' }}</p>
    </div>
    <div v-if="sentError">
      <h5>Error sending lead</h5>
    </div>
    <div v-if="gotFinalReceiverSuccess">FinalReceiver: {{ finalReceiver }}</div>
    <div v-if="gotFinalReceiverError">Error getting FinalReceiver</div>
    <div v-if="gotCommentSuccess">Comment: {{ comment }}</div>
    <div v-if="gotCommentError">Error getting comment</div>
    <div v-if="progress_something">
      <p>
        <!--        <b-spinner></b-spinner>-->
        {{ progress_text }}
        <!--        Loading...-->
      </p>
      <b-progress
        id="duration_progress_calculating_how_many_times_can_send"
        v-if="!duration_timed_out && progress_calculating_how_many_times_can_send"
        :value="duration_progress_calculating_how_many_times_can_send"
        :max="60000"
        show-progress
        animated
      ></b-progress>
      <b-progress
        id="duration_progress_send"
        v-if="!duration_timed_out && progress_send"
        :value="duration_progress_send"
        :max="60000"
        show-progress
        animated
      ></b-progress>
      <b-progress
        id="duration_progress_finalreceiver_check"
        v-if="!duration_timed_out && progress_finalreceiver_check"
        :value="duration_progress_finalreceiver_check"
        :max="progressBarDefaultDuration"
        show-progress
        animated
      ></b-progress>
      <b-progress
        id="duration_progress_comment_check"
        v-if="!duration_timed_out && progress_comment_check"
        :value="duration_progress_comment_check"
        :max="progressBarDefaultDuration"
        show-progress
        animated
      ></b-progress>
      <p v-if="duration_timed_out">Timed out</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  checkHowManyTimesCanSendLeadAsM,
  sendMLead,
  checkFinalReceiverUrl,
  requestFinalReceiverComment,
  checkMaffSendInProgress,
} from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';

export default {
  name: 'SendMLeadMenu',
  props: {
    _id: { type: String, default: '' },
    email: { type: String, default: '' },
    geo: { type: String, default: '' },
  },
  data() {
    return {
      userNotWorkingOnThisGeo: false,
      useFinalReceiverTTL: false,
      usePartialPhoneFilter: true,
      sendCallback: false,
      created: false,
      filterByWorkersConnectedFinalReceivers: true,
      timesCanSendThisLeadAsM: 0,
      calculatedHowManyTimesCanSend: false,
      progress_calculating_how_many_times_can_send: false,
      progress_send: false,
      progress_finalreceiver_check: false,
      progress_comment_check: false,
      gotFinalReceiverSuccess: false,
      gotFinalReceiverError: false,
      gotCommentSuccess: false,
      gotCommentError: false,
      sentSuccess: false,
      sentError: false,
      finalReceiver: '',
      comment: '',
      progressBarDefaultDuration: 120000,
      duration_progress_calculating_how_many_times_can_send: 0,
      duration_progress_send: 0,
      duration_progress_finalreceiver_check: 0,
      duration_progress_comment_check: 0,
      interval_finalreceiver_check: undefined,
      interval_comment_check: undefined,
      brokerNotAccepted: false,
      finalreceiver_check_started: 0,
      comment_check_started: 0,
      result_id: undefined,
      result_fullAction: undefined,
      interval_increase_duration: undefined,
      sendInProgress: false,
      // backdoor: 0,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'isAdmin']),
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    progress_something() {
      return (
        this.progress_calculating_how_many_times_can_send ||
        this.progress_send ||
        this.progress_finalreceiver_check ||
        this.progress_comment_check
      );
    },
    progress_text() {
      return this.progress_calculating_how_many_times_can_send
        ? 'Checking how many times can send lead...'
        : this.progress_send
        ? 'Sending lead...'
        : this.progress_finalreceiver_check
        ? 'Checking broker name...'
        : this.progress_comment_check
        ? 'Checking comments about broker...'
        : '';
    },
    duration_progress() {
      // this.backdoor;
      return this.progress_calculating_how_many_times_can_send
        ? this.duration_progress_calculating_how_many_times_can_send
        : this.progress_send
        ? this.duration_progress_send
        : this.progress_finalreceiver_check
        ? this.duration_progress_finalreceiver_check
        : this.progress_comment_check
        ? this.duration_progress_comment_check
        : 0;
    },
    duration_timed_out() {
      return this.duration_progress > this.progressBarDefaultDuration;
    },
    sendModeOptions() {
      const r = [];
      if (this.user.canSendMAutomatically || this.isAdmin) {
        r.push({ text: 'M', value: false });
      }
      if (this.user.canSendCAutomatically || this.isAdmin) {
        r.push({ text: 'Callback', value: true });
      }
      return r;
    },
  },
  methods: {
    setIncreaseDurationInterval(key) {
      this.clearIncreaseDurationInterval();
      this.interval_increase_duration = setInterval(() => this.increaseDuration(key), 100);
    },
    clearIncreaseDurationInterval() {
      clearInterval(this.interval_increase_duration);
    },
    increaseDuration(key) {
      // console.log(`increaseDuration this[key] ${this[key]}`);
      if (this[key] !== undefined) this[key] += 100;
      // this.backdoor += 1;
    },
    checkMaffSendInProgress() {
      checkMaffSendInProgress(this.token)
        .then((r) => {
          this.sendInProgress = r.data;
        })
        .catch((e) => handleRequestError(this, e, 'Error checking send in progress'));
    },
    checkHowManyTimesCanSendLeadAsM(_id) {
      if (
        !(
          this.isAdmin ||
          this.user.workingOnCountries.includes(this.geo) ||
          (this.user.canSendCAutomatically && this.sendCallback)
        )
      ) {
        console.log(
          `user not working on this geo; user.workingOnCountries ${this.user.workingOnCountries}, geo ${this.geo}`
        );
        this.timesCanSendThisLeadAsM = 0;
        this.userNotWorkingOnThisGeo = true;
        this.calculatedHowManyTimesCanSend = true;
        this.$store.commit('make_requesting', { req: false });
        return;
      }
      this.userNotWorkingOnThisGeo = false;
      this.$store.commit('make_requesting', { req: true });
      this.checkMaffSendInProgress();
      this.calculatedHowManyTimesCanSend = false;
      this.setIncreaseDurationInterval('duration_progress_calculating_how_many_times_can_send');
      checkHowManyTimesCanSendLeadAsM(this.token, {
        _id,
        sendCallback: this.sendCallback,
        useFinalReceiverTTL: this.useFinalReceiverTTL,
        usePartialPhoneFilter: this.usePartialPhoneFilter,
        filterByWorkersConnectedFinalReceivers: this.filterByWorkersConnectedFinalReceivers,
      })
        .then((r) => {
          this.timesCanSendThisLeadAsM = r.data.canSend;
          this.calculatedHowManyTimesCanSend = true;
          showCard(this, `Can send this lead ${r.data.canSend} times`, 'success', '');
          this.$store.commit('make_requesting', { req: false });
          this.clearIncreaseDurationInterval();
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on checking how many times can send`);
          this.$store.commit('make_requesting', { req: false });
          this.clearIncreaseDurationInterval();
        });
    },
    sendMLead() {
      this.$store.commit('make_requesting', { req: true });
      this.progress_send = true;
      this.brokerNotAccepted = false;
      this.setIncreaseDurationInterval('duration_progress_send');
      this.calculatedHowManyTimesCanSend = false;
      sendMLead(this.token, {
        _id: this._id,
        sendCallback: this.sendCallback,
        useFinalReceiverTTL: this.useFinalReceiverTTL,
        usePartialPhoneFilter: this.usePartialPhoneFilter,
      })
        .then((r) => {
          if (r.data.success) {
            showCard(this, `Lead sent`, 'success', '');
            console.log('lead sent');
            this.sentSuccess = true;
            if (r.data.fullAction) console.log('brokerAccepted', r.data.fullAction.brokerAccepted);
            if (r.data.fullAction && r.data.fullAction.brokerAccepted === 'true') {
              console.log('broker accepted');
              this.brokerNotAccepted = false;
              this.result_id = r.data.fullAction._id;
              this.result_fullAction = r.data.fullAction;
              this.progress_finalreceiver_check = true;
              this.interval_finalreceiver_check = setInterval(this.requestFinalReceiver, 10000);
              this.finalreceiver_check_started = Date.now();
              this.setIncreaseDurationInterval('duration_progress_finalreceiver_check');
            } else {
              this.result_id = undefined;
              this.result_fullAction = r.data.fullAction ? r.data.fullAction : undefined;
              this.brokerNotAccepted = true;
              console.log('broker not accepted');
              this.clearIncreaseDurationInterval();
            }
          } else {
            this.sentError = true;
            this.clearIncreaseDurationInterval();
          }
          this.progress_send = false;
          // this.checkHowManyTimesCanSendLeadAsM(this._id);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on trying to send leads`);
          console.error(e);
          this.progress_send = false;
          this.sentError = true;
          this.checkHowManyTimesCanSendLeadAsM(this._id);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    requestFinalReceiver() {
      console.log('requestFinalReceiver');
      this.$store.commit('make_requesting', { req: false });
      checkFinalReceiverUrl(this.token, { _id: this.result_id, dontInitiateLinkcheck: true })
        .then((r) => {
          if (r.data.success && r.data.finalReceiver && r.data.finalReceiver !== '') {
            clearInterval(this.interval_finalreceiver_check);
            this.finalReceiver = r.data.finalReceiver;
            this.gotFinalReceiverSuccess = r.data.success;
            showCard(this, `Final Receiver checked`, 'success', '');
            console.log(`Final Receiver checked`);
            this.progress_finalreceiver_check = false;
            this.progress_comment_check = true;
            this.interval_comment_check = setInterval(this.requestComment, 10000);
            this.setIncreaseDurationInterval('duration_progress_comment_check');
            this.comment_check_started = Date.now();
          } else if (
            Date.now() - this.finalreceiver_check_started >
            this.progressBarDefaultDuration
          ) {
            clearInterval(this.interval_finalreceiver_check);
            this.clearIncreaseDurationInterval();
            this.progress_finalreceiver_check = false;
            this.gotFinalReceiverError = true;
            showCard(this, `Final Receiver check timed out`, 'warning', '');
            console.log(`Final Receiver check timed out`);
          }
          // this.gotFinalReceiverError = true;
          // handleRequestError(
          //   this,
          //   r.data,
          //   `Unknown server error on trying to get Final Receiver`
          // );
          // }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on trying to get Final Receiver`);
          console.error(e);
          clearInterval(this.interval_finalreceiver_check);
          this.clearIncreaseDurationInterval();
          this.progress_finalreceiver_check = false;
          this.gotFinalReceiverError = true;
          this.$store.commit('make_requesting', { req: false });
        });
    },
    requestComment() {
      console.log('requestComment');
      this.$store.commit('make_requesting', { req: false });
      requestFinalReceiverComment(this.token, this.finalReceiver)
        .then((r) => {
          if (r.data.success) {
            clearInterval(this.interval_comment_check);
            this.comment = r.data.comment;
            this.gotCommentSuccess = true;
            showCard(this, `Comment checked`, 'success', '');
            console.log(`Comment checked`);
            this.progress_comment_check = false;
            this.clearIncreaseDurationInterval();
          } else if (Date.now() - this.comment_check_started > this.progressBarDefaultDuration) {
            clearInterval(this.interval_comment_check);
            this.clearIncreaseDurationInterval();
            this.gotCommentError = true;
            this.progress_comment_check = false;
            showCard(this, `Comment check timed out`, 'warning', '');
            console.log(`Comment check timed out`);
          }
          // this.gotCommentError = true;
          // handleRequestError(this, r.data, `Unknown server error on trying to get Comment`);
          // }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          clearInterval(this.interval_comment_check);
          this.clearIncreaseDurationInterval();
          handleRequestError(this, e, `Unknown server error on trying to get Comment`);
          console.error(e);
          this.progress_comment_check = false;
          this.gotCommentError = true;
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  created() {
    console.log(`SendMLeadMenu for action ${this._id} created`);
    if (this.user.canSendCAutomatically && !this.user.canSendMAutomatically) {
      this.sendCallback = true;
    }
    this.created = true;
    this.checkHowManyTimesCanSendLeadAsM(this._id);
  },
  watch: {
    sendCallback() {
      if (this.created) this.checkHowManyTimesCanSendLeadAsM(this._id);
    },
  },
};
</script>

<style scoped></style>
