<template>
  <div class="p-4">
    <div v-if="apiKey && apiKey !== 'undefined'">
      <div class="d-flex justify-content-end mb-3">
        <b-button variant="primary" @click="exportAsText">Export as Text File</b-button>
      </div>
      <sending-lead-docs
        :api-url="apiUrl"
        :api-key="apiKey"
        :live="live"
        class="my-4"
        ref="sendingLeadDocs"
      ></sending-lead-docs>
      <pulling-leads-docs
        :api-url="apiUrl"
        :email="email"
        class="my-4"
        ref="pullingLeadsDocs"
      ></pulling-leads-docs>
    </div>
    <div v-else>
      <p class="font-weight-bold">No box available for this user.</p>
      <p class="font-weight-bold">
        {{
          isAffiliate(user)
            ? 'Please ask your affiliate manager to create the Box for you.'
            : 'Please create the Box with this user as an Affiliate.'
        }}
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable new-cap */

import { mapState } from 'vuex';
import { getAffiliateIntegrationParams } from '@/utils/api';
import { isAffiliate } from '@/utils';
import SendingLeadDocs from './SendingLeadDocs.vue';
import PullingLeadsDocs from './PullingLeadsDocs.vue';

export default {
  name: 'AffiliateApiDocs',
  props: { affId: { type: String, default: '' }, boxId: { type: String, default: '' } },
  components: {
    SendingLeadDocs,
    PullingLeadsDocs,
  },
  computed: {
    ...mapState(['token', 'user']),
  },
  data() {
    return {
      apiUrl: 'https://grandmastercopy.me:2053/track/lead',
      apiKey: '',
      email: '',
      live: false,
    };
  },
  methods: {
    async exportAsText() {
      const sendingLeadDocs = this.$refs.sendingLeadDocs.$el;
      const pullingLeadsDocs = this.$refs.pullingLeadsDocs.$el;

      // Get the inner text of the components
      const sendingLeadText = sendingLeadDocs.innerText
        .replace(/([^/])?:\s/g, '$1: ')
        .replace(/Copy URL\n/g, '');
      const pullingLeadsText = pullingLeadsDocs.innerText
        .replace(/([^/])?:\s/g, '$1: ')
        .replace(/Copy URL\n/g, '');
      const divider = '===================================';
      const formattedText = `${sendingLeadText}\n\n${divider}\n\n${pullingLeadsText}`;
      // Create a Blob object from the text
      const blob = new Blob([formattedText], {
        type: 'text/plain',
      });

      // Create a URL for the Blob object and create a link element to download it
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'affiliate-api-docs.txt';

      // Click the link element to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and link element
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    isAffiliate(u) {
      return isAffiliate(u);
    },
  },
  mounted() {
    const options = { affId: this.affId, boxId: this.boxId };
    getAffiliateIntegrationParams(this.token, options)
      .then((response) => {
        this.apiUrl = response.data.apiUrl;
        this.apiKey = response.data.apiKey;
        this.email = response.data.email;
        this.live = response.data.live;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped></style>
