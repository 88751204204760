import { oidRegex } from '@/validators/_utils';
// ipRegex,
import { countryCodes } from '@/consts';
import codes from 'iso-lang-codes';
import PhoneNumber from 'awesome-phonenumber';

const { isEmail, isStrongPassword } = require('validator');

export default {
  b: (c) => oidRegex.test(c),
  email: (c) => !!c && typeof c === 'string' && c.length > 5 && isEmail(c),
  password: (c) =>
    !!c && typeof c === 'string' && c.length >= 8 && isStrongPassword(c, { minLength: 8 }), // true,
  firstname: (c) => !!c && c.length > 3,
  lastname: (c) => !!c && c.length > 3,
  phone: (c, f) => {
    if (!c || !c.length || c.length === 0) return false;
    try {
      const pn = new PhoneNumber(`${c[0] === '+' ? '' : '+'}${c}`, f.country);
      return pn.isValid();
    } catch (e) {
      return false;
    }
  },
  language: (c) => !!c && typeof c === 'string' && codes.validateLocaleCode(c),
  country: (c) => !!c && countryCodes.includes(c.toUpperCase()),
  // ip: (c) => !!c && ipRegex.test(c),
  so: (c) => !!c && c.length > 3,
  geoip_country: (c) => !c || countryCodes.includes(c),
};
