<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h6 class="mt-3">{{ name }}</h6>
    <label class="mb-2" style="color: #00a700">&lt;Key&gt;: &lt;Value&gt;</label>
    <b-button @click="addElement()" class="ml-2" variant="info">
      <font-awesome-icon icon="plus"></font-awesome-icon>
    </b-button>
    <b-form inline v-for="(e, i) in form" :key="i">
      <b-form-input
        class="array-item-inline"
        type="text"
        v-model="form[i][0]"
        @input="handleInput"
        :state="isValid.fields[i][0]"
      ></b-form-input>
      <!--      -->
      <b-form-select
        v-if="form[i][0] === 'Content-Type' && name === 'Request Headers'"
        id="requestparameters-requestdatatransferform"
        v-model="form[i][1]"
        :options="ContentTypeHeaderOptions"
        @input="handleInput"
      ></b-form-select>
      <b-form-input
        v-else
        class="array-item-inline"
        type="text"
        v-model="form[i][1]"
        @input="handleInput"
        :state="isValid.fields[i][1]"
      ></b-form-input>
      <!--
-->
      <b-button @click="removeElement(i)" variant="danger">
        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
import { castAuthArrayToObject, castAuthObjectToArray, formValid } from '@/validators';
import { validateAuthParams } from '@/validators/_utils';

export default {
  name: 'ObjectKeyValueForm',
  props: {
    name: { type: String, default: 'Request Headers' },
    object: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disallowEmpty: { type: Boolean, default: false },
  },
  data() {
    return {
      form: castAuthObjectToArray(cloneDeep(this.$props.object ? this.$props.object : {})),
      ContentTypeHeaderOptions: [
        'application/json',
        'application/json; charset=utf-8',
        'application/x-www-form-urlencoded',
        'multipart/formdata',
      ],
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = validateAuthParams(this.form, true);
      const form = (!this.disallowEmpty || fields.length > 0) && formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    emitEditEvent() {
      this.$emit('edit', castAuthArrayToObject(this.form));
    },
    addElement() {
      this.form.push([undefined, undefined]);
      this.backdoor += 1;
    },
    removeElement(index) {
      this.form.splice(index, 1);
      this.backdoor += 1;
    },
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
    handleInput() {
      this.backdoor += 1;
      this.emitEditEvent();
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
