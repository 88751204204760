var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',[_c('template',{slot:"links"},[(_vm.isAdmin)?_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'speedometer2', color: 'darkgreen', path: '/dashboard' }}}):_vm._e(),(_vm.loggedIn)?_c('sidebar-item',{attrs:{"link":{
        name: 'CRM',
        icon: 'table',
        color: 'darkgreen',
        path: '/crm',
      }}}):_vm._e(),(_vm.user && !_vm.user.maff)?_c('sidebar-item',{attrs:{"link":{
        name: 'Deposits',
        icon: 'cash-coin',
        color: 'darkgreen',
        path: '/deposits',
      }}}):_vm._e(),(_vm.isManager)?_c('sidebar-item',{attrs:{"link":{ name: 'Campaigns', icon: 'box', color: 'blue', path: '/campaigns' }}}):_vm._e(),(_vm.isManager)?_c('sidebar-item',{attrs:{"link":{ name: 'Test', icon: 'wifi', color: 'orange' }}},[(_vm.isManager)?_c('sidebar-item',{attrs:{"link":{ name: 'Lead', path: '/test-lead' }}}):_vm._e(),(_vm.isManager)?_c('sidebar-item',{attrs:{"link":{ name: 'Pulling', path: '/test-pulling' }}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"link":{ name: 'GMLevelDistribution', path: '/test-gm-level-distribution' }}}):_vm._e()],1):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"link":{
        name: 'Upload Leads File',
        icon: 'cloud-upload-fill',
        color: 'orange',
        path: '/upload-leads-file',
      }}}):_vm._e(),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"link":{
        name: 'Injection',
        icon: 'shuffle',
        color: 'orange',
        path: '/injections',
      }}}):_vm._e(),(_vm.isAdmin || _vm.isMasterAffiliate)?_c('sidebar-item',{attrs:{"link":{ name: 'Users', icon: 'person', color: 'darkblue', path: '/users' }}}):_vm._e(),(_vm.isManager)?_c('sidebar-item',{attrs:{"link":{ name: 'Boxes', icon: 'bounding-box', color: 'blue', path: '/boxes' }}}):_vm._e(),(_vm.OTHER_LEVEL_TRUSTED_USERS.includes(_vm.user.email))?_c('sidebar-item',{attrs:{"link":{ name: 'Logs', icon: 'clock-history', color: 'violet', path: '/work-history' }}}):_vm._e(),(!_vm.$rent)?_c('sidebar-item',{attrs:{"link":{ name: 'Daughters', path: '/daughters' }}}):_vm._e(),(!_vm.$rent)?_c('sidebar-item',{attrs:{"link":{ name: 'MetaCampaigns', path: '/metacampaigns' }}}):_vm._e(),(!_vm.$rent)?_c('sidebar-item',{attrs:{"link":{
        name: 'FinalReceivers',
        icon: 'bullseye',
        color: 'violet',
        path: '/finalreceivers',
      }}}):_vm._e(),(!_vm.$rent)?_c('sidebar-item',{attrs:{"link":{ name: 'Injection Send Results', path: '/injections-send-results' }}}):_vm._e(),(_vm.OTHER_LEVEL_TRUSTED_USERS.includes(_vm.user.email))?_c('sidebar-item',{attrs:{"link":{
        name: 'Box Settings',
        icon: 'tools',
        color: 'violet',
        path: '/bce-preset',
      }}}):_vm._e(),(_vm.isAffiliate && _vm.user && _vm.user.maff)?_c('sidebar-item',{attrs:{"link":{ name: 'Geo Selection', icon: 'geo-alt', path: '/geo-selection' }}}):_vm._e(),(_vm.canPush && _vm.maffRegisterLink !== '')?_c('b-nav-item',{attrs:{"href":_vm.maffRegisterLink,"target":"_blank","clas":"text-nowrap"}},[_c('i',{class:["bi-plus-square", 'mr-2'],style:({ color: 'orange' })}),_c('span',{staticClass:"nav-link-text text-nowrap"},[_vm._v("Add Lead")])]):_vm._e(),(_vm.isAffiliate || (_vm.user && _vm.user.integrator))?_c('sidebar-item',{attrs:{"link":{ name: 'Api Docs', icon: 'gear-wide-connected', path: '/affiliate-api-docs' }}}):_vm._e(),(
        (_vm.OTHER_LEVEL_TRUSTED_USERS.includes(_vm.user.email) || _vm.canPush) &&
        (_vm.servicename === 'grandmaster' || _vm.servicename === 'localgm') &&
        !_vm.$rent
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'PSP',
        icon: 'wallet',
        color: 'blue',
        path: '/psp',
      }}}):_vm._e(),(
        (_vm.OTHER_LEVEL_TRUSTED_USERS.includes(_vm.user.email) || _vm.hasWorkers) &&
        (_vm.servicename === 'grandmaster' || _vm.servicename === 'localgm') &&
        !_vm.$rent
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'Workers',
        icon: 'headset',
        color: 'blue',
        path: '/workers',
      }}}):_vm._e(),(_vm.OTHER_LEVEL_TRUSTED_USERS.includes(_vm.user.email) && !_vm.$rent)?_c('sidebar-item',{attrs:{"link":{
        name: 'Finance',
        icon: 'bank',
        color: 'darkgreen',
      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Transactions', path: '/financial-transactions' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Balances', path: '/balances' }}})],1):_vm._e()],1),_c('template',{slot:"links-after"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }