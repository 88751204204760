<template>
  <BoxesTable />
</template>

<script>
import BoxesTable from '@/components/pages/Boxes/BoxesTable/BoxesTable.vue';

export default {
  name: 'BoxesView',
  components: {
    BoxesTable,
  },
};
</script>
