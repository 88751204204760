<template>
  <div class="dashboard-wrapper">
    <div class="flex-overflow-wrap">
      <h3 class="mb-0 db-header">Dashboard</h3>
      <div id="dashboard-filters" class="flex-wrap-no-justify">
        <div class="db-header my-auto">
          <span class="ml-3 mr-0 my-auto">Time period</span>
          <b-form-radio-group
            id="btn-radios-period-1"
            size="sm"
            button-variant="outline-primary"
            v-model="period"
            :options="timePeriodOptions"
            name="btn-radios-period-1"
            buttons
            class="mx-3 my-auto"
          ></b-form-radio-group>
        </div>
        <!--        <b-button @click="testFileDownload()">Test File Download</b-button>-->

        <div
          v-if="period === 'custom'"
          class="align-items-center mx-3 flex-wrap-no-justify flex-sm-fill flex-xs-fill"
        >
          <div class="d-sm-flex align-items-sm-center flex-sm-fill flex-xs-fill db-header">
            <span class="mr-sm-2">From</span>
            <b-form-datepicker
              style="min-width: 288px"
              :date-format-options="dateFormatOptions"
              id="date-from"
              today-button
              reset-button
              close-button
              :max="custom.to"
              v-model="custom.from"
            ></b-form-datepicker>
          </div>
          <div class="d-sm-flex align-items-sm-center flex-sm-fill flex-xs-fill db-header">
            <span class="mx-sm-2">To</span>
            <b-form-datepicker
              style="min-width: 288px"
              :date-format-options="dateFormatOptions"
              id="date-to"
              today-button
              reset-button
              close-button
              :min="custom.from"
              v-model="custom.to"
            ></b-form-datepicker>
          </div>
        </div>
      </div>

      <!--        <b-form-radio-group-->
      <!--          v-if="allowSelectBy"-->
      <!--          id="btn-radios-select-by-1"-->
      <!--          size="sm"-->
      <!--          button-variant="outline-primary"-->
      <!--          v-model="selectBy"-->
      <!--          :options="selectByOptions"-->
      <!--          name="btn-radios-period-1"-->
      <!--          buttons-->
      <!--          class="mx-3 my-1"-->
      <!--        ></b-form-radio-group>-->
    </div>

    <DashboardSmallCardsRow
      :period="period"
      :customDate="customDateFormatted"
    ></DashboardSmallCardsRow>
    <!--    class="mx-lg-3 mx-2"-->
    <b-row class="dashboard-row dashboard-block">
      <!--    class="mb-2 mb-lg-3"  -->
      <ChartCard col-lg="6">
        <!--        v-slot="{ period }" // this is to make period choosable from inside ChartCard component -->
        <ActionsByPeriodChart
          :period="period"
          :customDate="customDateFormatted"
          entity="Lead"
          text="white"
          variant="primary"
          gradient
        ></ActionsByPeriodChart>
      </ChartCard>
      <ChartCard col-lg="6">
        <!--        period, -->
        <ActionsByPeriodChart
          :period="period"
          :customDate="customDateFormatted"
          entity="FTD"
          text="white"
          variant="info"
          gradient
        ></ActionsByPeriodChart>
      </ChartCard>
      <ChartCard v-slot="{ selectBy }" allow-select-by>
        <!--        period, -->
        <TreemapTotalByEntityChart
          variant="primary"
          gradient
          text="white"
          button-variant="white"
          :period="period"
          :customDate="customDateFormatted"
          entity="lead"
          :selectBy="selectBy"
        ></TreemapTotalByEntityChart>
      </ChartCard>
      <ChartCard v-slot="{ selectBy }" allow-select-by>
        <!--        period, -->
        <TreemapTotalByEntityChart
          variant="info"
          gradient
          text="white"
          button-variant="white"
          :period="period"
          :customDate="customDateFormatted"
          entity="FTD"
          :selectBy="selectBy"
        ></TreemapTotalByEntityChart>
      </ChartCard>
    </b-row>
  </div>
</template>

<script>
import ActionsByPeriodChart from '@/components/shared/ActionsByPeriodChart.vue';
import ChartCard from '@/components/shared/ChartCard.vue';
import TreemapTotalByEntityChart from '@/components/shared/TreemapTotalByEntityChart.vue';
import { dateFormatOptions, datepickerFormat, timePeriodOptions } from '@/consts';
import '@/assets/dashboard.css';
import DashboardSmallCardsRow from '@/components/shared/DashboardSmallCardsRow.vue';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import { testFileDownload } from '@/utils/api';
import { jsPDF } from 'jspdf';
import { dataToFile } from '@/utils';

export default {
  name: 'DashboardCharts',
  components: {
    DashboardSmallCardsRow,
    TreemapTotalByEntityChart,
    ChartCard,
    ActionsByPeriodChart,
  },
  data() {
    return {
      period: 'this day',
      timePeriodOptions,
      dateFormatOptions,
      custom: {
        from: DateTime.utc()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toFormat(datepickerFormat),
        to: DateTime.utc().toFormat(datepickerFormat),
      },
    };
  },
  computed: {
    ...mapState(['token']),

    customDateFormatted() {
      const options = cloneDeep(this.custom);
      if (options.from) {
        options.from = DateTime.fromFormat(options.from, datepickerFormat, { zone: 'utc' });
        options.from = options.from.isValid
          ? options.from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
          : undefined;
      }

      if (options.to) {
        options.to = DateTime.fromFormat(options.to, datepickerFormat, { zone: 'utc' });
        options.to = options.to.isValid ? options.to.toISO() : undefined;
      }
      return options;
    },
  },
  methods: {
    testFileDownload() {
      testFileDownload(this.token)
        .then((r) => {
          console.log(r.data);
          dataToFile(r.data, 'testfile.txt', true);
          // create file link in browser's memory
        })
        // })
        .catch((e) => console.error(e));
    },
    testPDF() {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      // doc.text('Hello world!', 10, 10);
      // doc.save('a4.pdf');
      const source = window.document.getElementById('topdf');
      doc.html(source).then(() => doc.save('a4.pdf'));
    },
  },
};
</script>

<style scoped></style>
