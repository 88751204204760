<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">Upload Leads File</h2>
    </div>

    <b-form-group
      class="my-2"
      label="Upload Name"
      label-for="injection-name"
      :invalid-feedback="invalidFeedback.injection.name"
      :state="isValid.fields.name"
    >
      <b-form-input
        type="text"
        id="injection-name"
        v-model="injectionForm.name"
        :state="isValid.fields.name"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Upload Leads To Box"
      label-for="box-to"
      :invalid-feedback="invalidFeedback.injection.boxTo"
      :state="isValid.fields.boxTo"
    >
      <!--      <b-form-select-->
      <!--        type="text"-->
      <!--        id="box-to"-->
      <!--        v-model="injectionForm.boxTo"-->
      <!--        :options="boxOptions"-->
      <!--        text-field="name"-->
      <!--        value-field="_id"-->
      <!--        :state="isValid.fields.boxTo"-->
      <!--      ></b-form-select>-->
      <multiselect
        id="box-to"
        v-model="injectionForm.boxTo"
        :options="boxOptions"
        track-by="name"
        label="name"
        :state="isValid.fields.boxTo"
        :closeOnSelect="true"
        :multiple="false"
      ></multiselect>
    </b-form-group>
    <!--    <b-form-checkbox class="my-2" v-model="injectionForm.paranoid">Paranoid</b-form-checkbox>-->
    <!--    Later make paranoid uploading only those actions that were not sent to anywhere else before -->

    <!--    <div class="my-2">-->
    <!--      <h5>-->
    <!--        Leads available by Country:-->
    <!--        <b-spinner small v-if="requesting"></b-spinner>-->
    <!--      </h5>-->
    <!--      <ul-->
    <!--        v-if="-->
    <!--          !requesting &&-->
    <!--          countriesCounts &&-->
    <!--          Array.isArray(countriesCounts) &&-->
    <!--          countriesCounts.length > 0-->
    <!--        "-->
    <!--      >-->
    <!--        <li v-for="(c, index) in countriesCounts" v-bind:key="index">-->
    <!--          {{ c.country }}: {{ c.count }}-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
    <!--    Count actions by countries in BoxTo, in order to better identify the box? -->

    <b-form-group
      label="Select File To Upload"
      label-for="leads-to-send"
      :invalid-feedback="invalidFeedback.injection.leadsToSend"
      :state="isValid.fields.leadsToSend"
      class="mt-2 mb-1"
    >
      <b-form-file
        id="leads-to-send"
        v-model="injectionForm.leadsToSend"
        :state="isValid.fields.leadsToSend"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept=".xlsx .csv"
      ></b-form-file>
    </b-form-group>
    <b-button class="mt-1 mb-2" variant="danger" size="sm" @click="clearFormField('leadsToSend')">
      Clear Selection
    </b-button>
    <!--    <b-form-checkbox class="my-2" v-model="injectionForm.randomizeIps">-->
    <!--      Randomize IPs in file-->
    <!--    </b-form-checkbox>-->

    <!--    Can later make it process file on front and send only already processed leads -->
    <!--    In this case will also show amount of leads by countries in selected file -->
    <p>
      Required fields are
      <b>firstname, lastname, phone, lanuage, email</b>
      <br />
    </p>
    <ul>
      <li>Country will be taken from Default Country, if not provided in file</li>
      <li>Source (so) will be taken from Default Leads Source, if not provided in file</li>
      <li>IP will be generated based on country, if not provided in file</li>
      <li>Password will be generated, if not provided in file</li>
    </ul>
    <b-button class="mt-1 mb-2" variant="info" size="sm" :href="exampleFilePath">
      Download Example File
    </b-button>

    <b-form-group class="my-1" label="Default Country" label-for="injection-default-country">
      <multiselect
        id="injection-default-country"
        v-model="injectionForm.defaultCountry"
        placeholder="Select one"
        :options="countryOptions"
        :allow-empty="true"
        track-by="value"
        @search-change="countryMultiSelectSearch"
        :custom-label="countryWithCode"
      ></multiselect>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Default Leads Source (Funnel Name, Description, etc.)"
      label-for="inj-so"
      :state="isValid.fields.so"
    >
      <b-form-input
        type="text"
        id="inj-so"
        v-model="injectionForm.so"
        :state="isValid.fields.so"
      ></b-form-input>
    </b-form-group>
    <div class="d-flex justify-content-between my-3">
      <b-button
        @click="saveInjection()"
        variant="primary"
        :disabled="!isValid.form || requesting || !injectionForm.leadsToSend"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <!--        {{ $route.params.id ? 'Save Injection' : 'Create Injection' }}-->
        Upload
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import { formValid, isValid } from '@/validators';
import {
  TRUSTED_USERS,
  invalidFeedback,
  statusGroupsOptions,
  countryOptions,
  daysOfWeek,
  dateFormatOptions,
} from '@/consts';
import _ from 'lodash';
import { handleRequestError, showCard, countAvgLeads, countryMultiSelectSearch } from '@/utils';
// eslint-disable-next-line no-unused-vars
import { saveInjection, countActionsForInj } from '@/utils/api';
import store from '@/store';

const itemInitialValues = {
  name: undefined,
  boxFrom: [],
  boxTo: undefined,
  statusGroups: [],
  active: true,
  sendAll: false,
  paranoid: false,
  manager: undefined,
  amountLeadsToSend: undefined,
  leadsToSave: undefined,
  so: undefined,
  startDate: undefined,
  startTime: undefined,
  endDate: undefined,
  endTime: undefined,
  startSelectDate: undefined,
  startSelectTime: undefined,
  endSelectDate: undefined,
  endSelectTime: undefined,
  batchSize: 1000,
  timeBetweenBatches1: 0.083,
  timeBetweenBatches2: 0.183,
  m: undefined,
  x: undefined,
  makeSalat: undefined,
  countAllActions: undefined,
  randomizeIps: true,
  defaultCountry: undefined,
  roundRobinOrderedAmounts: [],
  hourLimit: [0, 23],
  days: daysOfWeek.map((d) => d.item),
};

export default {
  name: 'UploadLeadsFile',
  data() {
    return {
      dateFormatOptions,
      campaignsCounts: [],
      countriesCounts: [],
      audioPlaying: 0,
      backdoor: 0,
      invalidFeedback,
      injectionForm: _.cloneDeep(itemInitialValues),
      timeBetweenBatchesOptions: [
        { text: '5 sec', value: 0.083 },
        { text: '30 sec', value: 0.5 },
        { text: '1 min', value: 1 },
        { text: '2 min', value: 2 },
        { text: '3 min', value: 3 },
        { text: '4 min', value: 4 },
        { text: '5 min', value: 5 },
        { text: '7 min', value: 7 },
        { text: '10 min', value: 10 },
        { text: '12 min', value: 12 },
        { text: '15 min', value: 15 },
        { text: '20 min', value: 20 },
        { text: '25 min', value: 25 },
        { text: '30 min', value: 30 },
        { text: '45 min', value: 45 },
        { text: '1 hour', value: 60 },
        { text: '2 hours', value: 120 },
      ],
      countryOptions,
      statusGroupsOptions,
      daysOfWeek,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'requesting', 'boxes', 'campaigns', 'affiliates', 'managers']),
    exampleFilePath() {
      return `${process.env.VUE_APP_BASE_URL}/example-leads-file.xlsx`;
    },
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    isValid() {
      this.backdoor;
      const fields = isValid('injection', this.injectionForm, this.injectionForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    boxOptions() {
      this.backdoor;
      return this.boxes
        .filter((b) => {
          if (!this.user) return false;
          const hidden = this.user.hiddenBoxes;
          if (hidden && Array.isArray(hidden) && hidden.includes(b._id)) return false;
          return !b.campaigns.some((c) => {
            const campaign = (this.campaigns || []).find((cs) => c.campaign === cs._id);
            return campaign && campaign.brokerApiType !== 'TECH_CAMPAIGN';
          });
        })
        .map((m) => {
          return { name: m.name, _id: m._id };
        });
    },
    managersOptions() {
      return this.managers.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    selectedBoxCountries() {
      const result = { selected: false, boxAccept: [], campaignsAccept: [], dontMatch: [] };
      const b = this.boxes.find((_b) => _b._id === this.injectionForm.boxTo);
      if (!b) return result;
      const bcc = _.uniq(_.flatten((b.campaigns ? b.campaigns : []).map((c) => c.countries)));
      const cc = _.uniq(
        _.flatten(
          (this.campaigns || []).filter((c) => c.boxes.includes(b._id)).map((c) => c.countries)
        )
      );
      const countriesDontMatch = bcc.filter((c) => !cc.includes(c));
      result.selected = true;
      result.boxAccept = bcc;
      result.campaignsAccept = cc;
      result.dontMatch = countriesDontMatch;
      return result;
    },
    boxToCampaigns() {
      if (!this.injectionForm.boxTo) return [];
      const bt = this.boxes.find((b) => b._id === this.injectionForm.boxTo);
      return bt && bt.campaigns
        ? bt.campaigns.map((c) => {
            if (c.name && c._id) return { name: c.name, _id: c._id };
            const cid = c.campaign._id ? c.campaign._id : c.campaign;
            const cf = (this.campaigns || []).find((ctf) => ctf._id === cid);
            return cf
              ? { name: cf.name ? cf.name : 'unknown', _id: cf._id ? cf._id : cf }
              : { name: 'unknown', id: cid };
          })
        : [];
    },
    avgLeadsPerHour() {
      return countAvgLeads(
        this.injectionForm.timeBetweenBatches1,
        this.injectionForm.timeBetweenBatches2,
        60,
        this.injectionForm.batchSize
      );
    },
    avgLeadsPerDay() {
      return countAvgLeads(
        this.injectionForm.timeBetweenBatches1,
        this.injectionForm.timeBetweenBatches2,
        60 * 24,
        this.injectionForm.batchSize
      );
    },
    injectionSpeedIsHigh() {
      return (
        (this.injectionForm.timeBetweenBatches1 < 1 &&
          this.injectionForm.timeBetweenBatches2 < 5) ||
        this.avgLeadsPerHour >= 60
      );
    },
    timeBetweenBatches1() {
      return this.injectionForm.timeBetweenBatches1;
    },
    timeBetweenBatches2() {
      return this.injectionForm.timeBetweenBatches2;
    },
    countryFilteredOptions() {
      return countryOptions.filter(({ value }) =>
        this.selectedBoxCountries.boxAccept.includes(value)
      );
    },
  },
  methods: {
    getCampaignCounts(oa) {
      const cc = this.campaignsCounts.find(
        (c) => c._id === (oa.campaign && oa.campaign._id ? oa.campaign._id : oa.campaign)
      );
      return cc && cc.count ? cc.count : 0;
    },
    addOrderedAmount() {
      this.injectionForm.roundRobinOrderedAmounts.push({ campaign: undefined, amount: 0 });
    },
    removeOrderedAmount(index) {
      this.injectionForm.roundRobinOrderedAmounts.splice(index, 1);
    },
    countActionsForInj() {
      // this.$store.commit('make_requesting', { req: true });
      // const injection = _.cloneDeep(this.injectionForm);
      // console.log(this.injectionForm.boxTo);
      // console.log(injection.boxTo);
      // const data = {
      //   countries: this.injectionForm.countries,
      //   boxFrom: this.injectionForm.boxFrom
      //     ? this.injectionForm.boxFrom.map((b) => (b._id ? b._id : b))
      //     : [],
      //   boxTo: this.injectionForm.boxTo
      //     ? this.injectionForm.boxTo._id
      //       ? this.injectionForm.boxTo._id
      //       : this.injectionForm.boxTo
      //     : undefined,
      //   startSelectDateTime:
      //     this.injectionForm.startSelectDate && this.injectionForm.startSelectTime
      //       ? `${this.injectionForm.startSelectDate}T${this.injectionForm.startSelectTime}`
      //       : undefined,
      //   endSelectDateTime:
      //     this.injectionForm.endSelectDate && this.injectionForm.endSelectTime
      //       ? `${this.injectionForm.endSelectDate}T${this.injectionForm.endSelectTime}`
      //       : undefined,
      //   sendAll: this.injectionForm.sendAll,
      //   paranoid: this.injectionForm.paranoid,
      // };
      // console.log('data before api function', data);
      // countActionsForInj(this.token, data)
      //   .then((r) => {
      //     if (r.status === 200 && !r.data.error && r.data.success) {
      //       if (r.data.countriesCounts) {
      //         this.countriesCounts = r.data.countriesCounts;
      //       } else {
      //         showCard(this, `Something went wrong on counting actions for injetion`, 'warning');
      //       }
      //       if (r.data.campaignsCounts) {
      //         this.campaignsCounts = r.data.campaignsCounts;
      //         this.injectionForm.roundRobinOrderedAmounts = r.data.campaignsCounts.map((cc) => {
      //           return {
      //             campaign: cc._id,
      //             name: cc.name ? cc.name : cc._id,
      //             amount: 0,
      //           };
      //         });
      //       } else {
      //         showCard(this, `Something went wrong on counting actions for injetion`, 'warning');
      //       }
      //       showCard(this, `Counted actions for injection`, 'success');
      //     } else {
      //       const cardError = r.data.message || `${r.status}: Unknown error on saving injection`;
      //       showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
      //     }
      //     this.$store.commit('make_requesting', { req: false });
      //   })
      //   .catch((e) => {
      //     handleRequestError(this, e, `Unknown server error on counting actions for injection`);
      //     this.$store.commit('make_requesting', { req: false });
      //   });
    },
    countryMultiSelectSearch,
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    clearFormField(field) {
      if (Object.keys(this.injectionForm).includes(field)) {
        this.injectionForm[field] = _.cloneDeep(itemInitialValues[field]);
      }
    },
    saveInjection() {
      if (this.isValid.form && this.injectionForm.leadsToSend) {
        this.$store.commit('make_requesting', { req: true });
        const injectionToSave = _.cloneDeep(this.injectionForm);
        injectionToSave.boxTo = injectionToSave.boxTo
          ? injectionToSave.boxTo._id
            ? injectionToSave.boxTo._id
            : injectionToSave.boxTo
          : undefined;
        if (!injectionToSave.boxFrom || injectionToSave.boxFrom.length === 0) {
          injectionToSave.boxFrom = [injectionToSave.boxTo];
        } else {
          injectionToSave.boxFrom = injectionToSave.boxFrom.map((bf) => (bf._id ? bf._id : bf));
        }
        if (injectionToSave.startDate && injectionToSave.startTime) {
          injectionToSave.startDateTime = `${injectionToSave.startDate}T${injectionToSave.startTime}`;
        }
        if (injectionToSave.endDate && injectionToSave.endTime) {
          injectionToSave.endDateTime = `${injectionToSave.endDate}T${injectionToSave.endTime}`;
        }
        if (injectionToSave.startDate && injectionToSave.startTime) {
          injectionToSave.startDateTime = `${injectionToSave.startDate}T${injectionToSave.startTime}`;
        }
        if (injectionToSave.endDate && injectionToSave.endTime) {
          injectionToSave.endDateTime = `${injectionToSave.endDate}T${injectionToSave.endTime}`;
        }
        if (injectionToSave.startSelectDate && injectionToSave.startSelectTime) {
          injectionToSave.startSelectDateTime = `${injectionToSave.startSelectDate}T${injectionToSave.startSelectTime}`;
        }
        if (injectionToSave.endSelectDate && injectionToSave.endSelectTime) {
          injectionToSave.endSelectDateTime = `${injectionToSave.endSelectDate}T${injectionToSave.endSelectTime}`;
        }

        if (injectionToSave.countries) {
          injectionToSave.countries = injectionToSave.countries.map(({ value }) => value);
        }
        if (injectionToSave.defaultCountry)
          injectionToSave.defaultCountry = injectionToSave.defaultCountry.value
            ? injectionToSave.defaultCountry.value
            : injectionToSave.defaultCountry;

        injectionToSave.roundRobinOrderedAmounts = JSON.stringify(
          this.injectionForm.roundRobinOrderedAmounts.filter(
            (oa) => oa && oa.amount && Number(oa.amount) > 0
          )
        );

        const days = [true, true, true, true, true, true, true];
        injectionToSave.days = days.map((d, j) => injectionToSave.days.includes(j));
        const formData = new FormData();
        Object.keys(injectionToSave).forEach((key) => {
          formData.append(key, injectionToSave[key]);
        });
        saveInjection(this.token, formData)
          .then(async (r) => {
            if (r.status === 200 && !r.data.error) {
              showCard(this, `Injection saved`, 'success');
              await this.$router.push(`/injections/${r.data._id}`);
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving injection`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving injection`);
          });
        this.$store.commit('make_requesting', { req: false });
      }
    },
    async updateData() {
      await store.dispatch('get_all_resources_one_request', { vm: this });
      this.backdoor += 1;
    },
  },
  mounted() {
    this.updateData();
  },
  watch: {
    'injectionForm.countries': function () {
      this.countActionsForInj();
    },
    'injectionForm.boxTo': function () {
      this.injectionForm.roundRobinOrderedAmounts = [];
      this.countActionsForInj();
    },
    'injectionForm.boxFrom': function () {
      this.countActionsForInj();
    },
    'injectionForm.sendAll': function () {
      this.countActionsForInj();
    },
    'injectionForm.startSelectDate': function () {
      this.countActionsForInj();
    },
    'injectionForm.startSelectTime': function () {
      this.countActionsForInj();
    },
    'injectionForm.endSelectDate': function () {
      this.countActionsForInj();
    },
    'injectionForm.endSelectTime': function () {
      this.countActionsForInj();
    },
    'injectionForm.paranoid': function () {
      this.countActionsForInj();
    },
    $route() {
      // this will be called any time the route changes
      console.log(this.$route.path);
      this.updateData();
      // if (this.$route.params.id) this.getBox(this.$route.params.id);
      // else {
      this.injectionForm = _.cloneDeep(itemInitialValues);
      this.initialForm = {};
      // }
    },
  },
};
</script>

<style scoped></style>
