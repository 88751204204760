<template>
  <header>
    <b-navbar toggleable="lg" :type="type" :variant="variant" class="border-bottom">
      <!--      <b-collapse id="nav-collapse" v-if="loggedIn" is-nav>-->
      <!--        <b-navbar-nav>-->
      <!--          <b-nav-item-->
      <!--            v-if="isAffiliate && user && user.maff"-->
      <!--            to="/geo-selection"-->
      <!--            active-class="active"-->
      <!--          >-->
      <!--            Geo Selection-->
      <!--          </b-nav-item>-->
      <!--        </b-navbar-nav>-->
      <!--      </b-collapse>-->
      <!--      <b-navbar-nav>-->
      <!--        <b-form inline>-->
      <!--          <b-nav-text>Color</b-nav-text>-->
      <!--          <b-form-select-->
      <!--            class="ml-1 mr-2"-->
      <!--            v-model="variant"-->
      <!--            :options="variantOptions"-->
      <!--          ></b-form-select>-->
      <!--          <b-nav-text>Type</b-nav-text>-->
      <!--          <b-form-select class="ml-1 mr-2" v-model="type" :options="typeOptions"></b-form-select>-->
      <!--        </b-form>-->
      <!--      </b-navbar-nav>-->
      <b-navbar-nav class="d-xl-none mr-3 ml-auto">
        <div
          class="px-2 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </b-navbar-nav>
      <b-navbar-nav class="mr-3 ml-auto" v-if="loggedIn">
        <b-nav-text>
          <h4>
            <b>
              <b-badge v-if="showFtds" :variant="ftds !== undefined ? 'success' : ''">
                FTDs
                <!--                  <span style="font-size: 2rem">-->
                <!--                    <b-badge :variant="ftds !== undefined ? 'success' : ''">-->
                {{ ftds !== undefined ? ftds : '...' }}
                <!--                    </b-badge>-->
                <!--                  </span>-->
              </b-badge>
            </b>
          </h4>
        </b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav class="mr-3 ml-auto" v-if="loggedIn">
        <b-nav-text>{{ currentTime }} GMT</b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav class="mr-3 ml-auto" v-if="loggedIn">
        <b-nav-text>Version: {{ metadata.build }}</b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav v-if="loggedIn" class="mr-3 ml-auto">
        <b-nav-item-dropdown>
          <template #button-content>
            <div class="d-inline-flex align-items-center">
              <span class="mr-3">{{ user && user.name ? user.name : 'Unknown' }}</span>
              <b-avatar></b-avatar>
            </div>
          </template>
          <b-dropdown-item :to="`/user/edit/${user._id}`" v-if="!isAffiliate">
            Profile
          </b-dropdown-item>
          <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav v-if="!loggedIn">
        <b-nav-item v-if="isAdmin">Version: {{ metadata.build }}</b-nav-item>
        <b-nav-item to="/login">Login</b-nav-item>
        <!--        <b-nav-item to="/system-commands">System Commands</b-nav-item>-->
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import * as metadata from '@/metadata.json';
import { getFtds } from '@/utils/api';
import { handleRequestError } from '@/utils';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';

export default {
  name: 'MainHeader',
  data() {
    return {
      currentTime: DateTime.utc().toFormat('HH:mm'),
      metadata,
      servicename_as_is: process.env.VUE_APP_SERVICENAME,
      servicename: process.env.VUE_APP_SERVICENAME
        ? String(process.env.VUE_APP_SERVICENAME).toLowerCase()
        : undefined,
      ftds: undefined,
      showFtds: true,
      OTHER_LEVEL_TRUSTED_USERS,
      variant: 'transparent',
      variantOptions: [
        'transparent',
        'primary',
        'success',
        'info',
        'warning',
        'danger',
        'dark',
        'darker',
        'light',
        'lighter',
      ],
      type: 'light',
      typeOptions: ['light', 'dark'],
    };
  },
  mounted() {
    this.getFtds();
    this.clockInterval = setInterval(() => {
      this.currentTime = DateTime.utc().toFormat('HH:mm');
    }, 2000);
    this.headerInterval = setInterval(() => this.getFtds(), 120000);
  },
  computed: {
    ...mapState(['loggedIn', 'user', 'isAdmin', 'isManager', 'isAffiliate', 'token']),
    canPush() {
      return this.user && (this.user.maff || this.user.canUploadLeads);
    },
    hasWorkers() {
      return this.user && this.user.hasWorkers;
    },
    maffRegisterLink() {
      console.log('maffRegisterLink user', this.user);
      if (process.env.VUE_APP_SERVICENAME.toLowerCase() !== 'grandmaster') return '';
      if (!(this.user && (this.user.maff || this.user.canUploadLeads))) return '';
      const regurl = process.env.VUE_APP_REGURL;
      const box =
        this.user.boxes && this.user.boxes.length > 0
          ? this.user.boxes[0]._id
            ? this.user.boxes[0]._id
            : this.user.boxes[0]
          : undefined;
      if (!regurl || !box) return '';
      return `${regurl}?key=${box}`;
    },
  },
  methods: {
    getFtds() {
      if (!this.loggedIn || !this.token) return;
      if (!this.user || this.user.integrator) return;
      getFtds(this.token)
        .then((r) => {
          if (r.data.success && r.data.ftds !== undefined) {
            this.showFtds = false;
            this.ftds = r.data.ftds;
            this.showFtds = true;
          } else {
            handleRequestError(this, r.data, `Unknown error updating headers ftds`);
          }
        })
        .catch((e) => {
          this.showFtds = false;
          console.error('Error updating headers ftds', e);
          this.showFtds = true;
          // handleRequestError(this, e, `Unknown server error updating headers ftds`);
        });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
  },
};
</script>
