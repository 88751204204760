/* eslint-disable camelcase */
import user from './user';
import campaign from './campaign';
import box from './box';
import injection from './injection';
import pulling from './pulling';
import testbox from './testbox';
import copylead from './copylead';
import distribution_simulation from './distribution_simulation';
import sendlead from './sendlead';
import assignToAffiliate from './assignToAffiliate';
import add_campaigns_to_boxes from './add_campaigns_to_boxes';
import psp from './psp';
import access_details from './access_details';
import worker from './worker';
import integration_PullingFromToDateSettings from './integration_PullingFromToDateSettings';
import integration_RequestParameters from './integration_RequestParameters';
import integration_PullingParameters from './integration_PullingParameters';
import integration_PullingPaginationParameters from './integration_PullingPaginationParameters';
import integration_PullingLeadsSettings from './integration_PullingLeadsSettings';
import integration_dataPreprocessingParameters from './integration_dataPreprocessingParameters';
import integration_FinalReceiverParameters from './integration_FinalReceiverParameters';
import integration_SendLeadParameters from './integration_SendLeadParameters';
import integration_PullingFTDsSettings from './integration_PullingFTDsSettings';
import integration_AuthParameters from './integration_AuthParameters';
import integration_AuthAttachParameters from './integration_AuthAttachParameters';
import bcePreset from './bcePreset';
import financialTransaction from './financialTransaction';
import testpulling from './testpulling';
import test_gm_level_distribution from './test_gm_level_distribution';
import { capCheck } from './_utils';
// zxc
const valid = {
  user,
  campaign,
  box,
  injection,
  pulling,
  testbox,
  testpulling,
  test_gm_level_distribution,
  copylead,
  assignToAffiliate,
  distribution_simulation,
  cap: capCheck,
  sendlead,
  add_campaigns_to_boxes,
  psp,
  access_details,
  worker,
  integration_PullingFromToDateSettings,
  integration_RequestParameters,
  integration_PullingParameters,
  integration_PullingPaginationParameters,
  integration_PullingLeadsSettings,
  integration_dataPreprocessingParameters,
  integration_FinalReceiverParameters,
  integration_SendLeadParameters,
  integration_PullingFTDsSettings,
  integration_AuthParameters,
  integration_AuthAttachParameters,
  bcePreset,
  financialTransaction,
};
export function isValid(type, form, outsideModifier = false, customValid = undefined) {
  // console.log('Validating', type);
  const formKeys = Object.keys(form);
  const r = {};
  const validator = type === 'custom' ? customValid : valid[type];
  formKeys.forEach((k) => {
    // console.log(validator[k]);
    if (typeof validator[k] === 'function')
      r[k] = validator[k] ? validator[k](form[k], outsideModifier) : Boolean(form[k]);
    if (typeof validator[k] === 'object')
      // if (Array.isArray(form[k]))
      //   r[k] = form[k].map((fk) => isValid('custom', fk, outsideModifier, validator[k]));
      // else
      r[k] = isValid('custom', form[k], outsideModifier, validator[k]);
    // console.log(k, r[k]);
  });
  // console.log(r);
  return r;
}

export function formValid(validatorResult) {
  const keys = Object.keys(validatorResult);
  for (let i = 0; i < keys.length; i += 1) {
    if (typeof validatorResult[keys[i]] === 'boolean' && !validatorResult[keys[i]]) return false;
    if (typeof validatorResult[keys[i]] === 'object' && !formValid(validatorResult[keys[i]]))
      return false;
  }
  return true;
}

export function undefinedArray(a) {
  return (
    Array.isArray(a) && // a.length === 0 ||
    a.every((e) => {
      return !e || (Array.isArray(e) && undefinedArray(e));
    })
  );
}

export function cleanFromUndefinedAndEmpty(a) {
  return a.filter((e) => e && e !== '' && !undefinedArray(e));
}

export function castAuthArrayToObject(k) {
  // (obj, key) {
  // const o = obj;
  // const a = o[key];
  // if (a && !undefinedArray(a)) {
  // if (a !== []) {
  const ac = cleanFromUndefinedAndEmpty(k);
  // if (ac.length > 0)
  // o[key] = ac
  const af = {};
  // ac.length > 0
  ac.filter((ace) => Array.isArray(ace) && ace.length === 2).forEach((ace) => {
    // const aco = {};
    // eslint-disable-next-line prefer-destructuring
    af[ace[0]] = ace[1];
    // return aco;
  });
  // else delete o[key];
  // }
  // } else delete o[key];
  return af;
}

export function castAuthObjectToArray(obj) {
  // , initial
  const resArray = [];
  if (obj) {
    // && obj !== {}
    Object.keys(obj).forEach((k) => {
      resArray.push([k, obj[k]]);
    });
  }
  // else {
  //   resArray = [];
  // }
  return resArray;
}
