<template>
  <Daughters />
</template>

<script>
import Daughters from '@/components/pages/Daughters/Daughters/Daughters.vue';

export default {
  name: 'DaughtersView',
  components: {
    Daughters,
  },
};
</script>
