<template>
  <CreateBox />
</template>

<script>
import CreateBox from '@/components/pages/CreateBox/CreateBox/CreateBox.vue';

export default {
  name: 'CreateBoxView',
  components: {
    CreateBox,
  },
};
</script>
