import { render, staticRenderFns } from "./PSPEditFtdsAmount.vue?vue&type=template&id=a384c56a&scoped=true&"
import script from "./PSPEditFtdsAmount.vue?vue&type=script&lang=js&"
export * from "./PSPEditFtdsAmount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a384c56a",
  null
  
)

export default component.exports