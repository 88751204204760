<template>
  <div class="container">
    <!--    <font-awesome-icon icon="clipboard"></font-awesome-icon>-->
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <form v-on:submit.prevent="login">
          <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
          <div class="form-group">
            <label for="email">Email Address</label>
            <input
              type="text"
              v-model="email"
              class="form-control"
              name="email"
              id="email"
              placeholder="Enter Email/Login"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              v-model="password"
              class="form-control"
              name="password"
              id="password"
              placeholder="Enter Password"
            />
          </div>
          <button :disabled="requesting" class="btn btn-lg btn-primary btn-block">
            <b-spinner small v-if="requesting"></b-spinner>
            Sign in
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { handleRequestError, showCard } from '@/utils';
// import router from '../router';
// import store from '../store';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapState(['requesting', 'serverUrl']),
  },

  methods: {
    login() {
      const endpoint = '/login';
      const url = this.serverUrl + endpoint;
      axios
        .post(url, {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          console.log('hmmm, show greeting mb?');
          showCard(this, `🐶 Привет, ${res.data.email}!`, 'success');
          this.$store.commit('authenticate', { user: res.data });
          this.email = '';
          this.password = '';
          this.$router.push({ name: 'Dashboard' });
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error', 'Не удалось войти 😐');
        });
    },
  },
};
</script>

<style scoped></style>
