<template>
  <div>
    <!--    <h5>Add Campaign to Box for Selected Countries with Default Settings</h5>-->
    <b-form-group class="my-1" label="Campaign" label-for="add-campaign">
      <multiselect
        id="add-campaign"
        v-model="addForm.campaigns"
        track-by="name"
        label="name"
        placeholder="Select one"
        :options="campaignsOptions"
        :allow-empty="true"
        :multiple="true"
        :closeOnSelect="false"
        :disabled="requesting || (campaign && campaign._id)"
        :state="isValid.fields.campaigns"
      ></multiselect>
    </b-form-group>
    <b-form-group class="my-1" label="Box" label-for="add-box">
      <multiselect
        id="add-box"
        v-model="addForm.boxes"
        track-by="name"
        label="name"
        placeholder="Select one"
        :options="boxesOptions"
        :allow-empty="false"
        :multiple="true"
        :closeOnSelect="false"
        :disabled="requesting || (box && box._id)"
        :state="isValid.fields.boxes"
      ></multiselect>
    </b-form-group>
    <b-form-group class="my-1" label="Geos" label-for="add-countries">
      <multiselect
        id="add-countries"
        v-model="addForm.countries"
        label="text"
        :options="campaignsCountryOptions"
        track-by="value"
        @search-change="countryMultiSelectSearch"
        :multiple="true"
        :custom-label="countryWithCode"
        :state="isValid.fields.countries"
        :allow-empty="false"
        :closeOnSelect="false"
        :disabled="requesting"
      ></multiselect>
    </b-form-group>
    <b-button
      @click="addCampaignsToBoxes()"
      class="m-2"
      variant="primary"
      :disabled="requesting || !isValid.form"
    >
      <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
      Add Campaigns to Boxes for selected GEOs
    </b-button>
  </div>
</template>

<script>
import _ from 'lodash';
import { countryOptions } from '@/consts';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { addCampaignsToBoxes } from '@/utils/api';
import { countryMultiSelectSearch, handleRequestError, showCard } from '@/utils';
import { formValid, isValid } from '@/validators';

export default {
  name: 'AddCampaignToBox',
  components: {
    Multiselect,
  },
  props: {
    box: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      backdoor: 0,
      addForm: {
        boxes: [],
        campaigns: [],
        countries: [],
      },
      countryOptions,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'boxes', 'campaigns', 'requesting']),
    isValid() {
      const fields = isValid('add_campaigns_to_boxes', this.addForm, this.addForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    campaignsOptions() {
      return _.cloneDeep(this.campaigns).map((c) => {
        return { name: c.name, _id: c._id };
      });
      // .filter((c) => !this.campaign || !this.campaign._id || this.campaign._id === c._id);
    },
    boxesOptions() {
      return _.cloneDeep(this.boxes).map((b) => {
        return { name: b.name, _id: b._id };
      });
      // .filter((b) => !this.box || !this.box._id || this.box._id === b._id);
    },
    campaignsCountryOptions() {
      const campaigns = _.cloneDeep(this.campaigns).filter((c) =>
        this.addForm.campaigns.some((campaign) => c._id === campaign || c._id === campaign._id)
      );
      return this.countryOptions.filter((co) =>
        campaigns.some(
          (campaign) =>
            campaign.countries.findIndex((cc) => cc === co.value || cc.value === co.value) >= 0
        )
      );
    },
  },
  methods: {
    countryMultiSelectSearch,
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    addCampaignsToBoxes() {
      console.log('addCampaignsToBoxes', this.addForm);
      if (!this.isValid.form) {
        return;
      }
      this.$store.commit('make_requesting', { req: true });
      const data = {
        boxes: this.addForm.boxes.map((b) => b._id),
        campaigns: this.addForm.campaigns.map((c) => c._id),
        countries: this.addForm.countries.map((c) => c.value),
      };
      addCampaignsToBoxes(this.token, data)
        .then((r) => {
          if (r.data.success) {
            showCard(this, `Added successfully`, 'success');
          } else {
            handleRequestError(this, r.data, `Unknown error on adding Campaigns to Boxes`);
          }
          this.$store.commit('make_requesting', { req: false });
          this.$emit('addCampaignsToBoxes', true);
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on adding Campaigns to Boxes`);
          this.$store.commit('make_requesting', { req: false });
          this.$emit('addCampaignsToBoxes', true);
        });
    },
  },
  created() {
    if (this.box && this.box._id) this.addForm.boxes.push(this.box);
    if (this.campaign && this.campaign._id) this.addForm.campaigns.push(this.campaign);
  },
};
</script>

<style scoped></style>
