<template>
  <WorkHistoryTable />
</template>

<script>
import WorkHistoryTable from '@/components/pages/WorkHistory/WorkHistoryTable/WorkHistoryTable.vue';

export default {
  name: 'WorkHistoryView',
  components: {
    WorkHistoryTable,
  },
};
</script>
