<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h4 class="mx-1 my-2">{{ name }}</h4>
    <p>Pulling Encode Data {{ form.EncodeData }}</p>
    <RequestParameters
      :RequestType="form.RequestType"
      :RequestDataTransferForm="form.RequestDataTransferForm"
      :EncodeData="form.EncodeData"
      :RequestHeaders="form.RequestHeaders"
      :Auth="form.Auth"
      @edit="
        handleMultipleKeysEditEvent($event, [
          'RequestType',
          'RequestDataTransferForm',
          'EncodeData',
          'RequestHeaders',
          'Auth',
        ])
      "
      @validate="
        handleMultipleKeysValidateEvent($event, [
          'RequestType',
          'RequestDataTransferForm',
          'EncodeData',
          'RequestHeaders',
          'Auth',
        ])
      "
    ></RequestParameters>

    <b-form-group
      class="my-3"
      label="Attach Auth Headers To"
      label-for="pullingparameters-attachauthheadersto"
      :state="isValid.fields.AttachAuthHeadersTo"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
    -->
      <b-form-select
        id="pullingparameters-attachauthheadersto"
        v-model="form.AttachAuthHeadersTo"
        :options="authAttachToOptions"
        @input="emitEditEvent"
        :state="isValid.fields.AttachAuthHeadersTo"
      ></b-form-select>
    </b-form-group>

    <PullingFromToDateSettings
      :FromToDate="form.FromToDate"
      @edit="handleEditEvent($event, 'FromToDate')"
      @validate="handleValidateEvent($event, 'FromToDate')"
    ></PullingFromToDateSettings>

    <!--    <PullingPaginationParameters-->
    <!--      :Pagination="form.Pagination"-->
    <!--      @edit="handleEditEvent($event, 'Pagination')"-->
    <!--      @validate="handleValidateEvent($event, 'Pagination')"-->
    <!--    ></PullingPaginationParameters>-->

    <PullingLeadsSettings
      :settings="form.Leads"
      @edit="handleEditEvent($event, 'Leads')"
      @validate="handleValidateEvent($event, 'Leads')"
    ></PullingLeadsSettings>

    <PullingFTDsSettings
      :settings="form.FTDs"
      @edit="handleEditEvent($event, 'FTDs')"
      @validate="handleValidateEvent($event, 'FTDs')"
    ></PullingFTDsSettings>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep, pick } from 'lodash';
import handleEditEvent from '@/utils/handleEditEvent';
import handleMultipleKeysEditEvent from '@/utils/handleMultipleKeysEditEvent';
import RequestParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/RequestParameters.vue';
import PullingFromToDateSettings from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PullingFromToDateSettings.vue';
// import PullingPaginationParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PullingPaginationParameters.vue';
import PullingLeadsSettings from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PullingLeadsSettings.vue';
import PullingFTDsSettings from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PullingFTDsSettings.vue';
import handleValidateEvent from '@/utils/handleValidateEvent';
import handleMultipleKeysValidateEvent from '@/utils/handleMultipleKeysValidateEvent';
import { formValid, isValid } from '@/validators';
import { mapPropFieldToValid } from '@/utils';

const initial = {
  RequestType: '',
  RequestDataTransferForm: '',
  EncodeData: true,
  // RequestHeaders: {},
  Auth: { method: 'direct' },
  AttachAuthHeadersTo: '',
  // FromToDate: {},
  // Pagination: {},
  // Leads: {},
  // FTDs: {},
};

const initialPropsList = [
  'RequestType',
  'RequestDataTransferForm',
  'EncodeData',
  'RequestHeaders',
  'Auth',
  'AttachAuthHeadersTo',
  'FromToDate',
  'Leads',
  'FTDs',
];

export default {
  name: 'PullingParameters',
  components: {
    PullingFTDsSettings,
    PullingLeadsSettings,
    // PullingPaginationParameters,
    PullingFromToDateSettings,
    RequestParameters,
  },
  props: {
    name: { type: String, default: 'Pulling Parameters' },
    parameters: {
      RequestType: { type: String, default: '' },
      RequestDataTransferForm: { type: String, default: '' },
      EncodeData: { type: Boolean, default: true },
      RequestHeaders: {
        type: Object,
        default: () => {
          return {};
        },
      },
      Auth: {
        type: Object,
        default: () => {
          return { method: 'direct' }; // TODO Auth with separateRequest
        },
      },
      AttachAuthHeadersTo: { type: String, default: '' },
      FromToDate: {
        type: Object,
        default: () => {
          return {};
        },
      },
      // Pagination: {
      //   type: Object,
      //   default: () => {
      //     return {};
      //   },
      // },
      Leads: {
        type: Object,
        default: () => {
          return {};
        },
      },
      FTDs: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  },
  data() {
    return {
      form: cloneDeep(
        this.$props.parameters ? pick(this.$props.parameters, initialPropsList) : initial
      ),
      valid: mapPropFieldToValid(pick(this.$props.parameters, initialPropsList), initial),
      authAttachToOptions: ['header', 'body'],
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = {
        ...this.valid,
        ...isValid('integration_PullingParameters', this.form, this.form),
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    handleMultipleKeysEditEvent,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleValidateEvent,
    handleMultipleKeysValidateEvent,
    // handleMultipleKeysValidateEvent(data, keys, formKey) {
    //   handleMultipleKeysValidateEvent(this, data, keys, formKey);
    // },
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
