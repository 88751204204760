<template>
  <ul v-show="show">
    <li v-for="(receiver, index) in item.receivers" :key="index">
      <div class="client-item">
        <router-link
          :to="getRouterLink(item.campaign._id, item.client, receiver.receiver)"
          class="mx-1 my-2 dataspan greentext"
          target="_blank"
        >
          {{
            receiver.receiver && receiver.receiver.length > 0
              ? receiver.receiver
              : 'No final receiver'
          }}
        </router-link>
        <div :class="['dataspan', type === 'positive' ? 'bluetext' : 'orangetext', 'my-2', 'mx-1']">
          {{ type === 'positive' ? receiver.positive : receiver.positiveToNegativeChanger }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { getRouterLink_SalesStatusClientsList } from '@/utils';

export default {
  name: 'SalesStatusClientsList_Receivers',
  props: {
    show: { type: Boolean, required: true },
    item: { type: Object, required: true },
    type: {
      type: String,
      validator(value) {
        // Define the allowed values for the prop
        const allowedValues = ['positive', 'positive-to-negative'];

        // Check if the prop value is included in the allowed values
        return allowedValues.includes(value);
      },
      required: true,
    },
  },
  data() {
    return {
      visibleClients: [],
    };
  },
  methods: {
    getRouterLink(campaignId, client, receiver) {
      return getRouterLink_SalesStatusClientsList(this, campaignId, client, receiver);
    },
    makeClientsVisible() {
      this.visibleClients = this.item.receivers.map((i, index) => index);
      // this.items.forEach((i, index) => {
      //   this.visibleClients2Level[index] = i.clients.map((j, index2) => index2);
      // });
    },
    toggleVisibility(index) {
      if (this.visibleClients.includes(index)) {
        // Client is already visible, so hide it
        const clientIndex = this.visibleClients.indexOf(index);
        this.visibleClients.splice(clientIndex, 1);
      } else {
        // Client is not visible, so show it
        this.visibleClients.push(index);
      }
    },
  },
};
</script>

<style scoped></style>
