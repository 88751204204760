<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">
        {{ _id ? 'Edit' : 'Add' }} Transaction{{ _id ? ` ${_id}` : '' }}
      </h2>
    </div>
    <b-form-group
      class="my-2"
      label="Campaign"
      label-for="transactionform-campaign"
      :state="isValid.fields.campaign"
    >
      <multiselect
        id="transactionform-campaign"
        v-model="transactionForm.campaign"
        :options="campaignOptions"
        track-by="name"
        label="name"
        :closeOnSelect="true"
        :multiple="false"
        :disabled="requesting"
        @select="clearMetaCampaign"
      ></multiselect>
    </b-form-group>
    <b-form-group
      class="my-2"
      label="MetaCampaign"
      label-for="transactionform-metacampaign"
      :state="isValid.fields.metaCampaign"
    >
      <multiselect
        id="transactionform-metacampaign"
        v-model="transactionForm.metaCampaign"
        :options="metaCampaignOptions"
        track-by="name"
        label="name"
        :closeOnSelect="true"
        :multiple="false"
        :disabled="requesting || metaCampaignOptions.length === 0"
      ></multiselect>
    </b-form-group>
    <b-form-group
      class="my-2"
      label="Type"
      label-for="transactionform-type"
      :invalid-feedback="invalidFeedback.financialTransaction.type"
      :state="isValid.fields.type"
    >
      <b-form-radio-group
        id="transactionform-type"
        size="md"
        button-variant="outline-primary"
        v-model="transactionForm.type"
        :options="financialTransactionTypeClosedOptions"
        name="radios-btn-default"
        buttons
        :disabled="requesting"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      class="my-2"
      label="Amount"
      label-for="transactionform-amount"
      :invalid-feedback="invalidFeedback.financialTransaction.amount"
      :state="isValid.fields.amount"
    >
      <b-form-input
        type="number"
        id="transactionform-amount"
        v-model="transactionForm.amount"
        :state="isValid.fields.amount"
        :disabled="requesting"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Date"
      label-for="transactionform-date"
      :invalid-feedback="invalidFeedback.financialTransaction.date"
      :state="isValid.fields.date"
    >
      <b-form-datepicker
        :date-format-options="dateFormatOptions"
        id="transactionform-date"
        today-button
        reset-button
        close-button
        v-model="transactionForm.date"
      ></b-form-datepicker>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Comment"
      label-for="transactionform-comment"
      :invalid-feedback="invalidFeedback.financialTransaction.comment"
      :state="isValid.fields.comment"
    >
      <b-form-textarea
        type="text"
        id="transactionform-comment"
        v-model="transactionForm.comment"
        :state="isValid.fields.comment"
        placeholder="Comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <div class="d-flex justify-content-between mb-3">
      <b-button
        @click="saveFinancialTransaction()"
        variant="primary"
        :disabled="!isValid.form || requesting"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        {{ transactionForm._id ? 'Save Transaction' : 'Create Transaction' }}
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { DateTime } from 'luxon';
import _ from 'lodash';
import {
  invalidFeedback,
  validFeedback,
  financialTransactionTypeClosedOptions,
  datepickerFormat,
  dateFormatOptions,
} from '@/consts';
import { mapState } from 'vuex';
import { formValid, isValid } from '@/validators';
import { getFinancialTransaction, saveFinancialTransaction } from '@/utils/api';
import { deleteUnchangedFields, handleRequestError, showCard } from '@/utils';

const itemInitialValues = {
  _id: undefined,
  campaign: undefined,
  metaCampaign: undefined,
  type: 'postpay',
  amount: undefined,
  date: DateTime.utc().toFormat(datepickerFormat),
  comment: undefined,
};

export default {
  name: 'EditTransaction',
  props: {
    _id: { type: String, default: '' },
  },
  data() {
    return {
      transactionForm: _.cloneDeep(itemInitialValues),
      validFeedback,
      invalidFeedback,
      backdoor: 0,
      initialForm: {},
      financialTransactionTypeClosedOptions,
      dateFormatOptions,
    };
  },
  computed: {
    campaignOptions() {
      return this.campaigns
        .filter(
          (c) => c.brokerApiType !== 'Daughter' || (c.metaCampaigns && c.metaCampaigns.length > 0)
        )
        .map((c) => {
          return { name: c.name, _id: c._id };
        });
    },
    metaCampaignOptions() {
      console.log('this.transactionForm.campaign', this.transactionForm.campaign);
      if (!this.transactionForm.campaign) return [];
      const campaign = this.campaigns.find((c) => c._id === this.transactionForm.campaign._id);
      console.log('campaign', campaign);
      if (!campaign || !campaign.metaCampaigns) return [];
      return campaign.metaCampaigns.map((mc) => {
        return { name: mc.name, _id: mc._id };
      });
    },
    ...mapState(['token', 'campaigns', 'affiliates', 'requesting', 'user', 'isAdmin']),
    isValid() {
      this.backdoor;
      const fields = isValid('financialTransaction', this.transactionForm, this.transactionForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
  },
  methods: {
    clearMetaCampaign() {
      this.transactionForm.metaCampaign = undefined;
    },
    saveFinancialTransaction() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        let toSave = _.cloneDeep(this.transactionForm);
        toSave.campaign = toSave.campaign._id;
        toSave.metaCampaign = toSave.metaCampaign._id;
        toSave.date = DateTime.fromFormat(toSave.date, datepickerFormat, { zone: 'utc' }).toISO();
        toSave = deleteUnchangedFields(this.initialForm, this.transactionForm, toSave);
        saveFinancialTransaction(this.token, toSave)
          .then(async (r) => {
            if (r.status === 200) {
              showCard(this, `${r.data.number} Transaction saved`, 'success');
              this.castFinancialTransactionToForm(r.data);
              this.$emit('transactionSaved', _.cloneDeep(r.data));
              this.backdoor += 1;
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving Transaction`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.$store.commit('make_requesting', { req: false });
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving Transaction`);
            this.$store.commit('make_requesting', { req: false });
          });
      }
    },
    getFinancialTransaction(_id) {
      console.log('Getting psp', _id);
      getFinancialTransaction(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castFinancialTransactionToForm(r.data);
            this.backdoor += 1;
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting Transaction`;
            showCard(
              this,
              cardError,
              r.status === 500 ? 'danger' : 'warning',
              'Error getting Transaction'
            );
          }
        })
        .catch((e) => {
          handleRequestError(
            e,
            'Unknown server error on getting Transaction',
            'Error getting Transaction'
          );
        });
    },
    castFinancialTransactionToForm(transaction) {
      const initial = _.cloneDeep(itemInitialValues);
      // cast campaign to edit form
      // prettier-ignore
      this.transactionForm = {
        _id: transaction._id ? transaction._id : initial.id,
        campaign: transaction.campaign ? { _id: transaction.campaign._id ? transaction.campaign._id : transaction.campaign,name : transaction.campaign.name ? transaction.campaign.name : 'unknown' } : initial.campaign,
        metaCampaign: transaction.metaCampaign ? { _id: transaction.metaCampaign._id ? transaction.metaCampaign._id : transaction.metaCampaign, name : transaction.metaCampaign.name ? transaction.metaCampaign.name : 'unknown' } : initial.metaCampaign,
        type: transaction.type ? transaction.type : initial.type,
        amount: transaction.amount ? transaction.amount : initial.amount,
        date: transaction.date && typeof transaction.date === 'string' && DateTime.fromISO(transaction.date, { setZone: true }).isValid ? DateTime.fromISO(transaction.date, { setZone: true }).toFormat(datepickerFormat) : initial.date,
        comment: transaction.comment ? transaction.comment : initial.comment,
      };
      this.initialForm = _.cloneDeep(this.transactionForm);
    },
  },
  mounted() {
    if (this._id && this._id !== '') this.getFinancialTransaction(this._id);
    else {
      this.transactionForm = _.cloneDeep(itemInitialValues);
      this.initialForm = {};
    }
  },
};
</script>

<style scoped></style>
