<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h4 class="mx-1 my-2">{{ name }}</h4>
    <KeyValueObjectArrayForm
      name="Broker Accepted OR Conditions"
      :arr="form.BrokerAcceptedORConditions"
      @edit="handleEditEvent($event, 'BrokerAcceptedORConditions')"
      @validate="handleValidateEvent($event, 'BrokerAcceptedORConditions')"
    ></KeyValueObjectArrayForm>
    <!--          disallowEmpty -->
    <KeyValueObjectArrayForm
      name="Broker NotAccepted OR Conditions"
      :arr="form.BrokerNotAcceptedORConditions"
      @edit="handleEditEvent($event, 'BrokerNotAcceptedORConditions')"
      @validate="handleValidateEvent($event, 'BrokerNotAcceptedORConditions')"
    ></KeyValueObjectArrayForm>
    <!--          disallowEmpty -->
  </div>
</template>

<script>
import '@/assets/form.scss';
import { omit, cloneDeep } from 'lodash';
import handleEditEvent from '@/utils/handleEditEvent';
import handleEditEventDirectKey from '@/utils/handleEditEventDirectKey';
import handleValidateEvent from '@/utils/handleValidateEvent';
import KeyValueObjectArrayForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/KeyValueObjectArrayForm.vue';
import { mapPropsToValid } from '@/utils';
import { formValid } from '@/validators'; // isValid

export default {
  name: 'BrokerAcceptanceParameters',
  components: { KeyValueObjectArrayForm },
  props: {
    name: { type: String, default: 'BrokerAcceptance Parameters' },
    BrokerAcceptedORConditions: { type: Array, default: () => [] },
    BrokerNotAcceptedORConditions: { type: Array, default: () => [] },
  },
  data() {
    return {
      form: cloneDeep(omit(this.$props, ['name'])),
      valid: mapPropsToValid(this.$props),
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = {
        ...this.valid,
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    handleEditEventDirectKey,
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleValidateEvent,

    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    emitValidateEvent() {
      this.$emit('validate', this.isValid.fields);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
