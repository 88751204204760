<template>
  <div>
    <h5>
      Leads Selection Dropouts for injection {{ name }}
      <b-button @click="getLeadsSelectionDropouts()" :disabled="requesting" class="m-2">
        <b-spinner small v-if="requesting"></b-spinner>
        <span v-else>🔄</span>
      </b-button>
    </h5>
    <p>Geo {{ countries.join(', ') }}</p>
    <!--    <div style="width: 506px; heigth: 506px; padding: 10px; overflow: scroll">-->
    <vue-json-pretty
      v-if="!requesting"
      class="my-2"
      :data="dropouts"
      :show-line="true"
      :select-on-click-node="true"
      :highlight-selected-node="true"
      :selectable-type="'single'"
      :show-select-controller="false"
      @click="(path, data) => selectJsonNode(path, data)"
      style="max-width: 506px; word-wrap: break-word"
    ></vue-json-pretty>
    <p v-else>
      <b-spinner small class="mx-1"></b-spinner>
      Requesting data...
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { getLeadsSelectionDropouts } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';

export default {
  name: 'leadsSelectionDropoutsPopup',
  components: { VueJsonPretty },
  props: {
    _id: { type: String, default: 'unknown' },
    name: { type: String, default: 'unknown' },
    countries: { typs: Array, default: [] },
  },
  data() {
    return { dropouts: {}, requesting: false };
  },
  computed: {
    ...mapState(['user', 'token']),
  },
  methods: {
    getLeadsSelectionDropouts() {
      this.requesting = true;
      if (this.$props._id === 'unknown') return;
      getLeadsSelectionDropouts(this.token, { _id: this.$props._id })
        .then((r) => {
          this.dropouts = r.data;
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown error', 'Error getting leadsSelectionDropouts');
          this.requesting = false;
          this.dropouts = ['Error getting dropouts'];
        });
    },
    selectJsonNode(path, data) {
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
    },
  },
  mounted() {
    this.getLeadsSelectionDropouts();
  },
};
</script>

<style scoped></style>
