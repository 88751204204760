/* eslint-disable dot-notation */
import { pick, cloneDeep } from 'lodash';
import tinycolor from 'tinycolor2';

export const mapColors = {
  blue: '#5e72e4',
  indigo: '#5603ad',
  purple: '#8965e0',
  pink: '#f3a4b5',
  red: '#f5365c',
  orange: '#fb6340',
  yellow: '#ffd600',
  green: '#2dce89',
  teal: '#11cdef',
  cyan: '#2bffc6',
  white: '#ffffff',
  'gray-100': '#f6f9fc',
  'gray-200': '#e9ecef',
  'gray-300': '#dee2e6',
  'gray-400': '#ced4da',
  'gray-500': '#adb5bd',
  'gray-600': '#8898aa',
  'gray-700': '#525f7f',
  'gray-800': '#32325d',
  'gray-900': '#212529',
  black: '#000000',
};
export const colorVariants = {
  default: '#172b4d',
  primary: '#5e72e4',
  secondary: '#888b8d',
  success: mapColors['green'],
  info: mapColors['teal'],
  warning: mapColors['orange'],
  danger: mapColors['red'],
  light: mapColors['gray-500'],
  dark: mapColors['gray-900'],
  blue: mapColors['blue'],
  indigo: mapColors['indigo'],
  purple: mapColors['purple'],
  pink: mapColors['pink'],
  red: mapColors['red'],
  orange: mapColors['orange'],
  yellow: mapColors['yellow'],
  green: mapColors['green'],
  teal: mapColors['teal'],
  cyan: mapColors['cyan'],
  white: mapColors['white'],
};

export const mapColorsNatural = pick(mapColors, [
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
  // 'white',
]);

export const toRGB = (c) => [
  parseInt(c.substring(1, 3), 16),
  parseInt(c.substring(3, 5), 16),
  parseInt(c.substring(5, 7), 16),
];
export const toHex = (c) => {
  const r = c[0].toString(16);
  const g = c[1].toString(16);
  const b = c[2].toString(16);
  const dd = (p) => (p.length === 2 ? p : `${p}${p}`);
  return `#${dd(r)}${dd(g)}${dd(b)}`;
};
export const mapColorsNaturalExtended = cloneDeep(mapColorsNatural);
Object.keys(mapColorsNatural).forEach((k) => {
  const color = mapColorsNatural[k];
  // mapColorsNaturalExtended[`${k}-reverse`] = toHex(toRGB(color).map((c) => Math.abs(255 - c)));
  let tc = tinycolor(color);
  for (let n = 1; n < 6; n += 1) {
    tc = tc.spin((360 / 6) * n);
    mapColorsNaturalExtended[`${k}-tc-${n}`] = `#${tc.toHex()}`;
  }
});
console.log(mapColorsNaturalExtended);

export const luminance = (r, g, b) => {
  const a = [r, g, b].map(function (vs) {
    const v = vs / 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrast = (rgb1, rgb2) => {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

export default {
  mapColors,
  colorVariants,
  mapColorsNatural,
  toRGB,
  toHex,
  mapColorsNaturalExtended,
};
