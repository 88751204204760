<template>
  <TestGMLevelDistribution />
</template>

<script>
import TestGMLevelDistribution from '@/components/pages/TestGMLevelDistribution/TestGMLevelDistribution.vue';

export default {
  name: 'TestGMLevelDistributionView',
  components: {
    TestGMLevelDistribution,
  },
};
</script>

<style scoped></style>
