import jwtDecode from 'jwt-decode';
import { USER_TOKEN } from '@/consts';

export default function getDecodedToken(t = undefined) {
  const token = t || localStorage.getItem(USER_TOKEN);

  if (token) {
    return jwtDecode(token);
  }

  return {};
}
