<template>
  <div>
    <b-button
      v-b-toggle="`collapse-breakdown-${groupName.toLowerCase()}`"
      variant="primary"
      :style="cssVars"
    >
      MCS Breakdown {{ total ? 'Total' : 'By Copies' }}
    </b-button>
    <b-collapse :id="`collapse-breakdown-${groupName.toLowerCase()}`" class="mt-2">
      <b-card :title="`Stats by ${groupName}`" sub-title="">
        <b-card v-for="(breakdown, index) in realitems" :key="index">
          <b-card-title>
            {{ breakdown.campaign_name ? breakdown.campaign_name : total ? 'Total' : 'unknown' }}
          </b-card-title>
          <b-card-sub-title>
            {{ breakdown.campaign_id ? breakdown.campaign_id : '' }}
          </b-card-sub-title>
          <b-card-group columns>
            <b-card
              v-for="(item, index) in breakdown.breakdown_by_countries
                ? breakdown.breakdown_by_countries
                : breakdown"
              :key="index"
            >
              <b-card-title>
                <!--              {{ item.item ? (item.item.name ? item.item.name : item.item) : 'unknown' }}-->
                {{ index }}
              </b-card-title>
              <!--              <b-card-sub-title>{{ item.item ? item.item._id : 'unknown' }}</b-card-sub-title>-->
              <div class="my-2">
                <div class="mx-2 my-1 dataspan orangetext">
                  Unique Clients: {{ item.number_mcs }}
                </div>
              </div>
              <div class="my-2">
                <div class="mx-2 my-1 dataspan bluetext">
                  Clients With Demand: {{ item.clients_with_demand }}
                </div>
              </div>
              <div class="my-2">
                <div class="mx-2 my-1 dataspan orangetext">
                  Active Hours: From {{ Math.min(...item.activeHours) }}:00 To
                  {{ Math.max(...item.activeHours) }}:59
                </div>
              </div>
              <div class="my-2">
                <div class="mx-2 my-1 dataspan bluetext">
                  Can Send M Now: {{ item.can_send_mlead_times_now }}
                </div>
              </div>
              <div class="my-2">
                <span class="mx-2 my-1 dataspan bluetext">
                  Sent M to Active Campaigns: {{ item.m }}
                </span>
                <span class="mx-2 my-1 dataspan bluetext">
                  Sent R to Active Campaigns: {{ item.r }}
                </span>
              </div>
              <div class="my-2">
                <div class="mx-2 my-1 dataspan forestgreen">
                  Can Send M if Cap Fulfilled: {{ item.can_send_mlead_times_by_cap }}
                </div>
              </div>
              <div class="my-2">
                <div class="mx-2 my-1 dataspan forestgreen">
                  Day's Cap: {{ item.dailyCapToday }}
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-card>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'MCSBreakdowns',
  props: {
    items: {
      type: [Object, Array],
      default: () => [],
    },
    groupName: { type: String, default: '' },
    minBtnWidth: { type: Number, default: 0 },
    total: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['token', 'boxes']),
    cssVars() {
      return {
        'min-width': `${this.minBtnWidth}px`,
        '--min-width': `${this.minBtnWidth}px`,
      };
    },
    realitems() {
      if (Array.isArray(this.items)) return this.items;
      // if (typeof this.items ==='object') {
      //   return Object.keys(this.items).map(k=>{return {name:}})
      // }
      return [this.items];
    },
    sortedItems() {
      return _.sortBy(this.items, [
        function (item) {
          return -item.number_mcs;
          // return item.item ? (item.item.name ? item.item.name : item.item) : 'unknown';
        },
      ]);
    },
  },
  methods: {
    getBreakdowns(breakdown) {
      return breakdown.breakdown_by_countries ? breakdown.breakdown_by_countries : breakdown;
    },
  },
};
</script>

<style scoped>
.togglebutton {
  min-width: var(--min-width);
}
</style>
