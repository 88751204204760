/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { getAuthHeader, isNotSet } from '@/utils';
import _ from 'lodash';
import { DateTime } from 'luxon';

// const base = 'https://template.com';
const base = process.env.VUE_APP_API_URL;
export const socketBaseUrl = `ws://${
  new URL(process.env.VUE_APP_BASE_URL).hostname
}:1337/websockets/`;
export const makeSocketUrl = (endpoint) => `${socketBaseUrl}${endpoint}`;

export async function copyLead(token, leadToCopy) {
  return axios.post(`${base}/action/copy`, leadToCopy, { headers: getAuthHeader(token) });
}
export async function assignToAffiliate(token, data) {
  return axios.post(`${base}/action/assign-to-affiliate`, data, {
    headers: getAuthHeader(token),
  });
}
export async function assignToTrader(token, data) {
  return axios.post(`${base}/action/assign-to-trader`, data, {
    headers: getAuthHeader(token),
  });
}
export async function deassignTrader(token, data) {
  return axios.post(`${base}/action/deassign-trader`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getWorkersOptions(token, data = {}) {
  return axios.post(`${base}/worker/get-workers-options`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getLeadsSelectionDropouts(token, data) {
  return axios.post(`${base}/injection/leads-selection-dropouts`, data, {
    headers: getAuthHeader(token),
  });
}
export async function getSentToday(token, data) {
  return axios.post(`${base}/injection/sent-today`, data, {
    headers: getAuthHeader(token),
  });
}
export async function getFullInjection(token, data) {
  return axios.post(`${base}/injection/`, data, {
    headers: getAuthHeader(token),
  });
}

export async function chengeMStatus(token, options) {
  return axios.post(`${base}/action/changemstatus`, options, { headers: getAuthHeader(token) });
}

export async function chengeApproveStatus(token, options) {
  return axios.post(`${base}/action/approve`, options, { headers: getAuthHeader(token) });
}

export async function chengeRequestToCheck(token, options) {
  return axios.post(`${base}/action/changerequesttocheck`, options, {
    headers: getAuthHeader(token),
  });
}
export async function changeWithdrawFlag(token, options) {
  return axios.post(`${base}/action/change-withdraw-flag`, options, {
    headers: getAuthHeader(token),
  });
}
export async function changeRenewFlag(token, options) {
  return axios.post(`${base}/action/change-renew-flag`, options, {
    headers: getAuthHeader(token),
  });
}
export async function checkActionSaleStatus(token, options) {
  return axios.post(`${base}/action/check-action-sales-status`, options, {
    headers: getAuthHeader(token),
  });
}
export async function clearAllRenewLeads(token, options) {
  return axios.post(`${base}/action/clear-all-renew-leads`, options, {
    headers: getAuthHeader(token),
  });
}
export async function changeWithdrawDoneFlag(token, options) {
  return axios.post(`${base}/action/change-withdraw-done-flag`, options, {
    headers: getAuthHeader(token),
  });
}
export async function changeTraderStatus(token, options) {
  return axios.post(`${base}/action/change-trader-status`, options, {
    headers: getAuthHeader(token),
  });
}
export async function testNewInjAlgorithm(token, data) {
  return axios.post(`${base}/action/find_batch_connected_urls`, data, {
    headers: getAuthHeader(token),
  });
}

export async function chengeFinalReceiver(token, options) {
  return axios.post(`${base}/action/changefinalreceiver`, options, {
    headers: getAuthHeader(token),
  });
}
export async function changeDailyFtdsLimit(token, options) {
  return axios.post(`${base}/campaign/change-daily-ftds-limit`, options, {
    headers: getAuthHeader(token),
  });
}

export async function getAffiliateIntegrationParams(token, options) {
  return axios.post(`${base}/user/affiliate-integration`, options, {
    headers: getAuthHeader(token),
  });
}

export async function checkFinalReceiverUrl(token, options) {
  return axios.post(`${base}/action/checkfinalreceiverurl`, options, {
    headers: getAuthHeader(token),
  });
}
export async function requestFinalReceiverComment(token, finalReceiver) {
  return axios.post(
    `${base}/action/get-final-receiver-comment`,
    { finalReceiver },
    {
      headers: getAuthHeader(token),
    }
  );
}

export async function checkHowManyTimesCanSendLeadAsM(token, options) {
  return axios.post(`${base}/action/how-many-times-can-send`, options, {
    headers: getAuthHeader(token),
  });
}
export async function sendMLead(token, options) {
  return axios.post(`${base}/action/maff-send`, options, {
    headers: getAuthHeader(token),
  });
}
export async function checkMaffSendInProgress(token, options) {
  return axios.post(`${base}/action/check-maff-send-in-progress`, options, {
    headers: getAuthHeader(token),
  });
}

export async function requestSTCIList(token, options) {
  return axios.post(`${base}/action/checkstcis`, options, {
    headers: getAuthHeader(token),
  });
}

export async function getWorkHistory(token, options) {
  const defaultOptions = {
    from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
    to: DateTime.utc().toISO(),
    itemsPerPage: 100,
  };

  return axios.post(`${base}/workhistory`, _.merge(defaultOptions, options), {
    headers: getAuthHeader(token),
  });
}
export async function getAction(token, _id, type) {
  return axios.get(`${base}/action/`, { headers: getAuthHeader(token), params: { _id, type } });
}

function prepareActionListData(options) {
  const data = _.cloneDeep(options);
  const defaultData = {
    type: 'lead',
    from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
    to: DateTime.utc().toISO(),
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return data;
}

export async function getActions(token, options = {}, endpoint = '/action/list') {
  /**
   *
   * @param options = {
   *   // Фильтрация по этим свойствам осуществляется на уровне БД
   *   type: "impression" | "click" | "lead" | "ftd";    // обязательный параметр, дефолтное значение -- lead
   *   from: string;    // DateTime.toISO(fromDateTime), // обязательный параметр, дефолтное значение -- сегодня 00:00:00 по utc
   *   to: string;      // DateTime.toISO(toDateTime),   // обязательный параметр, дефолтное значение -- сегодня, сейчас по utc
   *   affiliates?: string[];
   *   campaigns?: string[];
   *   boxes?: string[];
   *
   *   // Фильтрация по этим свойствам осуществляется на уровне сервера
   *   countries?: string[];               // поиск по локации
   *   email?: string;                     // поиск по regexp в action.leadContactInfo.email
   *   _id?: string;                        // поиск по regexp в action._id
   *   salesStatus?: string;               // статусы или их части через разделитель ', ' (запятая с пробелом)
   *   ip?: string;                        // поиск по regexp в action.leadContactInfo.ip
   *   showPositiveStatuses?: boolean;
   *   showPositiveToNegatives?: boolean;
   *
   *   // Свойства для пагинации
   *   page?: number;                      // default = 1
   *   itemsPerPage?: number;              // default = 10
   *   sortBy?: string;                    // default = actionDate
   *   sortOrder?: 'asc' | 'desc';         // default = 'desc'
   * }
   * */
  const data = prepareActionListData(options);
  return axios.post(`${base}${endpoint}`, data, { headers: getAuthHeader(token) });
}

export async function getSalesStatusClientsList(token, options = {}) {
  const data = prepareActionListData(options);
  const endpoint = '/action/get-sales-status-clients-list';
  return axios.post(`${base}${endpoint}`, data, { headers: getAuthHeader(token) });
}
export async function getSalesStatusReportForClient(token, options = {}) {
  const data = prepareActionListData(options);
  const endpoint = '/action/get-sales-status-report-for-client';
  return axios.post(`${base}${endpoint}`, data, { headers: getAuthHeader(token) });
}

export async function getAffiliates(token) {
  const params = { type: 'affiliates' };
  return axios.get(`${base}/user/list`, { headers: getAuthHeader(token), params });
}

export async function getAllResources(token) {
  return axios.get(`${base}/user/resources`, { headers: getAuthHeader(token) });
}

export async function getBox(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/box`, { headers: getAuthHeader(token), params });
}

export async function operateDaughterAPI(url, data) {
  return axios.post(url, data);
}
export async function makeSendMMetaCamnpaign(token, data) {
  return axios.post(`${base}/campaign/make-send-m-meta-campaign`, data, {
    headers: getAuthHeader(token),
  });
}
export async function makeActiveMetaCampaign(token, data) {
  return axios.post(`${base}/campaign/make-active-meta-campaign`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getBoxes(token) {
  return axios.get(`${base}/box/list`, { headers: getAuthHeader(token) });
}
export async function calculateBoxDemand(token, data) {
  return axios.post(`${base}/box/calculate-demand`, data, {
    headers: getAuthHeader(token),
  });
}
export async function calculateActiveHours(token, data) {
  return axios.post(`${base}/box/calculate-active-hours`, data, {
    headers: getAuthHeader(token),
  });
}
export async function copyBoxSettings(token, data) {
  return axios.post(`${base}/box/copy-box-settings`, data, {
    headers: getAuthHeader(token),
  });
}
export async function createBoxFullCopy(token, data) {
  return axios.post(`${base}/box/create-box-full-copy`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getCampaign(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/campaign`, { headers: getAuthHeader(token), params });
}

export async function getPSPs(token, options = {}) {
  /**
   *
   * @param options = {
   *   // Фильтрация по этим свойствам осуществляется на уровне БД
   *   type: "all" | "card" | "crypto";    // обязательный параметр, дефолтное значение -- all
   *   НЕ ИСПОЛЬЗУЕТСЯ from: string;    // DateTime.toISO(fromDateTime), // дефолтное значение -- начало года по utc
   *   НЕ ИСПОЛЬЗУЕТСЯ to: string;      // DateTime.toISO(toDateTime),   // дефолтное значение -- сегодня, сейчас по utc
   *   affiliates?: string[];
   *   lead.email?: string; // ищет нестрогое соответствие по regexp
   *   lead.phone?: string; // ищет нестрогое соответствие по regexp
   *   lead._id?: string; // ищет нестрогое соответствие по regexp
   *   _id?: string; // ищет нестрогое соответствие по regexp
   *   number?: string; // только цифры, ищет нестрогое соответствие по regexp
   *
   *   // Фильтрация по этим свойствам осуществляется на уровне сервера
   *
   *   // Свойства для пагинации
   *   page?: number;                      // default = 1
   *   itemsPerPage?: number;              // default = 10
   *   sortBy?: string;                    // default = actionDate
   *   sortOrder?: 'asc' | 'desc';         // default = 'desc'
   * }
   * */
  const data = _.cloneDeep(options);
  const defaultData = {
    type: 'all',
    // from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
    // to: DateTime.utc().toISO(),
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return axios.post(`${base}/psp/list`, data, { headers: getAuthHeader(token) });
}

export async function getInjectionSendResults(token, options = {}) {
  /**
   *
   * @param options = {
   *   // Фильтрация по этим свойствам осуществляется на уровне БД
   *   type: "all" | "card" | "crypto";    // обязательный параметр, дефолтное значение -- all
   *   НЕ ИСПОЛЬЗУЕТСЯ from: string;    // DateTime.toISO(fromDateTime), // дефолтное значение -- начало года по utc
   *   НЕ ИСПОЛЬЗУЕТСЯ to: string;      // DateTime.toISO(toDateTime),   // дефолтное значение -- сегодня, сейчас по utc
   *   affiliates?: string[];
   *   lead.email?: string; // ищет нестрогое соответствие по regexp
   *   lead.phone?: string; // ищет нестрогое соответствие по regexp
   *   lead._id?: string; // ищет нестрогое соответствие по regexp
   *   _id?: string; // ищет нестрогое соответствие по regexp
   *   number?: string; // только цифры, ищет нестрогое соответствие по regexp
   *
   *   // Фильтрация по этим свойствам осуществляется на уровне сервера
   *
   *   // Свойства для пагинации
   *   page?: number;                      // default = 1
   *   itemsPerPage?: number;              // default = 10
   *   sortBy?: string;                    // default = actionDate
   *   sortOrder?: 'asc' | 'desc';         // default = 'desc'
   * }
   * */
  const data = _.cloneDeep(options);
  const defaultData = {
    // from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
    // to: DateTime.utc().toISO(),
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return axios.post(`${base}/injection-send-result/list`, data, { headers: getAuthHeader(token) });
}

export async function getPSP(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/psp`, { headers: getAuthHeader(token), params });
}
export async function deletePSP(token, _id) {
  const params = { id: _id };
  return axios.delete(`${base}/psp`, { headers: getAuthHeader(token), params });
}

export async function getFinancialTransactions(token, options = {}) {
  /**
   *
   * @param options = {
   *   // Фильтрация по этим свойствам осуществляется на уровне БД
   *   НЕ ИСПОЛЬЗУЕТСЯ type: "all" | "postpay" | "prepay" | "refund" | "adjustment";    // обязательный параметр, дефолтное значение -- all
   *   from: string;    // DateTime.toISO(fromDateTime), // дефолтное значение -- начало года по utc
   *   to: string;      // DateTime.toISO(toDateTime),   // дефолтное значение -- сегодня, сейчас по utc
   *   campaigns?: string[];
   *   metaCampaigns?: string[];

   *
   *   // Фильтрация по этим свойствам осуществляется на уровне сервера
   *
   *   // Свойства для пагинации
   *   page?: number;                      // default = 1
   *   itemsPerPage?: number;              // default = 10
   *   sortBy?: string;                    // default = actionDate
   *   sortOrder?: 'asc' | 'desc';         // default = 'desc'
   * }
   * */
  const data = _.cloneDeep(options);
  const defaultData = {
    type: 'all',
    from: DateTime.utc()
      .set({ month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISO(),
    to: DateTime.utc().toISO(),
    campaigns: [],
    metaCampaigns: [],
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return axios.post(`${base}/financial-transaction/list`, data, { headers: getAuthHeader(token) });
}
export async function getBalances(token, options = {}) {
  /**
   *
   * @param options = {
   *   // Фильтрация по этим свойствам осуществляется на уровне БД
   *   НЕ ИСПОЛЬЗУЕТСЯ type: "all" | "postpay" | "prepay" | "refund" | "adjustment";    // обязательный параметр, дефолтное значение -- all
   *   from: string;    // DateTime.toISO(fromDateTime), // дефолтное значение -- начало года по utc
   *   to: string;      // DateTime.toISO(toDateTime),   // дефолтное значение -- сегодня, сейчас по utc
   *   campaigns?: string[];
   *   metaCampaigns?: string[];

   *
   *   // Фильтрация по этим свойствам осуществляется на уровне сервера
   *
   *   // Свойства для пагинации
   *   page?: number;                      // default = 1
   *   itemsPerPage?: number;              // default = 10
   *   sortBy?: string;                    // default = actionDate
   *   sortOrder?: 'asc' | 'desc';         // default = 'desc'
   * }
   * */
  const data = _.cloneDeep(options);
  const defaultData = {
    // from: DateTime.utc()
    //   .set({ month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
    //   .toISO(),
    // to: DateTime.utc().toISO(),
    campaigns: [],
    metaCampaigns: [],
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return axios.post(`${base}/financial-transaction/aggregate-balances`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getFinancialTransaction(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/financial-transaction`, { headers: getAuthHeader(token), params });
}
export async function deleteFinancialTransaction(token, _id) {
  const params = { id: _id };
  return axios.delete(`${base}/financial-transaction`, { headers: getAuthHeader(token), params });
}

export async function getFinalReceivers(token) {
  return axios.post(
    `${base}/campaign/get_final_receivers`,
    {},
    {
      headers: getAuthHeader(token),
    }
  );
}
export async function getFinalReceiversHostnames(token) {
  return axios.post(
    `${base}/campaign/get_final_receivers_hostnames`,
    {},
    {
      headers: getAuthHeader(token),
    }
  );
}
export async function alertlistFinalReceiver(token, data) {
  return axios.post(`${base}/campaign/alertlist_final_receiver`, data, {
    headers: getAuthHeader(token),
  });
}
export async function setFinalReceiverAlertLevel(token, data) {
  return axios.post(`${base}/campaign/set_final_receiver_alert_level`, data, {
    headers: getAuthHeader(token),
  });
}
export async function toggleRegulatedFinalReceiver(token, data) {
  return axios.post(`${base}/campaign/toggle_regulated_final_receiver`, data, {
    headers: getAuthHeader(token),
  });
}
export async function commentFinalReceiver(token, data) {
  return axios.post(`${base}/campaign/comment_final_receiver`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getCampaignUrlsByFinalReceiver(token, data) {
  return axios.post(`${base}/campaign/test_final_receivers_urls_list`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getCampaignUrlsByActionDetails(token, data) {
  return axios.post(`${base}/action/test_campaign_urls_list_by_action_details`, data, {
    headers: getAuthHeader(token),
  });
}
export async function addCampaignsToBoxes(token, data) {
  return axios.post(`${base}/box/add-campaigns-to-boxes`, data, {
    headers: getAuthHeader(token),
  });
}
export async function removeCampaignsFromBoxes(token, data) {
  return axios.post(`${base}/box/remove-campaigns-from-boxes`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getCampaignUrlsByActionId(token, data) {
  return axios.post(`${base}/action/test_campaign_urls_list_by_action_id`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getCampaignUrlsNotConnectedByActionDetails(token, data) {
  return axios.post(`${base}/action/find_campaign_urls_not_connected_by_action_details`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getCampaignUrlsNotConnectedByActionId(token, data) {
  return axios.post(`${base}/action/find_campaign_urls_not_connected_by_action_id`, data, {
    headers: getAuthHeader(token),
  });
}
export async function getMetaCampaignsShaved(token, data = {}) {
  return axios.post(`${base}/campaign/get-metacampaigns-shaved`, data, {
    headers: getAuthHeader(token),
  });
}
export async function getFinalReceiversByURL(token, data) {
  return axios.post(`${base}/campaign/get-finalreceivers-by-url`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getUseHistory(token, data) {
  return axios.post(`${base}/psp/get-use-history`, data, {
    headers: getAuthHeader(token),
  });
}
export async function changeFtdsAvailableAmount(token, data) {
  return axios.post(`${base}/psp/change-ftds-available-amount`, data, {
    headers: getAuthHeader(token),
  });
}
export async function togglePSPAvailable(token, data) {
  return axios.post(`${base}/psp/toggle-available`, data, {
    headers: getAuthHeader(token),
  });
}
export async function toggleWorkerActive(token, data) {
  return axios.post(`${base}/worker/toggle-active`, data, {
    headers: getAuthHeader(token),
  });
}
export async function toggleWorkerCallback(token, data) {
  return axios.post(`${base}/worker/toggle-callback`, data, {
    headers: getAuthHeader(token),
  });
}
export async function toggleBusinessOpen(token, data) {
  return axios.post(`${base}/worker/toggle-business-open`, data, {
    headers: getAuthHeader(token),
  });
}
export async function toggleBusinessOpenAll(token, data) {
  return axios.post(`${base}/worker/toggle-business-open-all`, data, {
    headers: getAuthHeader(token),
  });
}
export async function requestWorkerAvailability(token, data) {
  return axios.post(`${base}/worker/request-availability`, data, {
    headers: getAuthHeader(token),
  });
}
export async function requestAllWorkersAvailability(token) {
  return axios.post(
    `${base}/worker/request-availability-all`,
    {},
    {
      headers: getAuthHeader(token),
    }
  );
}

export async function getCampaigns(token) {
  return axios.get(`${base}/campaign/list`, { headers: getAuthHeader(token) });
}

export async function getInjections(token) {
  return axios.get(`${base}/injection/list`, { headers: getAuthHeader(token) });
}

export async function getManagers(token) {
  const params = { type: 'managers' };
  return axios.get(`${base}/user/list`, { headers: getAuthHeader(token), params });
}

export async function getMe(token) {
  return axios.get(`${base}/user/me`, { headers: getAuthHeader(token) });
}

export async function getUser(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/user`, { headers: getAuthHeader(token), params });
}

export async function getUsers(token) {
  const params = { type: 'all' };
  return axios.get(`${base}/user/list`, { headers: getAuthHeader(token), params });
}

export async function killInjection(token, _id) {
  return axios.post(`${base}/injection/kill`, { _id }, { headers: getAuthHeader(token) });
}
export async function playInjection(token, _id, play) {
  return axios.post(`${base}/injection/play`, { _id, play }, { headers: getAuthHeader(token) });
}

export async function toggleParanoidInjection(token, _id, paranoid) {
  return axios.post(
    `${base}/injection/toggle-paranoid`,
    { _id, paranoid },
    { headers: getAuthHeader(token) }
  );
}
export async function toggleConsiderRMRatioInjection(token, _id, considerRMRatio) {
  return axios.post(
    `${base}/injection/toggle-consider-rm-ratio`,
    { _id, considerRMRatio },
    { headers: getAuthHeader(token) }
  );
}
export async function toggleConsiderWorkingAffiliatesInjection(
  token,
  _id,
  considerWorkingAffiliates
) {
  return axios.post(
    `${base}/injection/toggle-consider-working-affiliates`,
    { _id, considerWorkingAffiliates },
    { headers: getAuthHeader(token) }
  );
}
export async function toggleUseNewAlgorithmInjection(token, _id, useNewAlgorithm) {
  return axios.post(
    `${base}/injection/toggle-use-new-algorithm`,
    { _id, useNewAlgorithm },
    { headers: getAuthHeader(token) }
  );
}

export async function makeUserActive(token, userToSave) {
  return axios.post(`${base}/user/active`, userToSave, { headers: getAuthHeader(token) });
}
export async function changeRTCMandatoryFlow(token, userToSave) {
  return axios.post(`${base}/user/change-rtc-mandatory-flow`, userToSave, {
    headers: getAuthHeader(token),
  });
}
export async function changeRTCMandatoryFlowPSP(token, userToSave) {
  return axios.post(`${base}/user/change-rtc-mandatory-flow-psp`, userToSave, {
    headers: getAuthHeader(token),
  });
}

export async function makeTestFtd(token, _id) {
  return axios.post(`${base}/action/makeTestFtd`, { _id }, { headers: getAuthHeader(token) });
}

export async function orderPullingFullCheck(token) {
  return axios.get(`${base}/track/orderfullcheck`, { headers: getAuthHeader(token) });
}

export async function runDistributionSimulation(token, simulationRequest) {
  return axios.post(`${base}/box/run_distribution_simulation`, simulationRequest, {
    headers: getAuthHeader(token),
  });
}

export async function saveBox(token, boxToSave) {
  return axios.post(`${base}/box/save`, boxToSave, { headers: getAuthHeader(token) });
}

export async function saveCampaign(token, campaignToSave) {
  return axios.post(`${base}/campaign/save`, campaignToSave, { headers: getAuthHeader(token) });
}
export async function editCampaignsCountries(token, data) {
  return axios.post(`${base}/campaign/edit-campaigns-countries`, data, {
    headers: getAuthHeader(token),
  });
}
export async function checkBrokersDetails(token, data) {
  return axios.post(`${base}/campaign/check-brokers-details`, data, {
    headers: getAuthHeader(token),
  });
}
export async function savePSP(token, pspToSave) {
  return axios.post(`${base}/psp/save`, pspToSave, { headers: getAuthHeader(token) });
}
export async function saveFinancialTransaction(token, financialTransactionToSave) {
  return axios.post(`${base}/financial-transaction/save`, financialTransactionToSave, {
    headers: getAuthHeader(token),
  });
}

export async function getAvailablePSPsForAction(token, data) {
  return axios.post(`${base}/psp/get-available-psps-for-action`, data, {
    headers: getAuthHeader(token),
  });
}
export async function assignPSPToAction(token, data) {
  return axios.post(`${base}/psp/assign-psp-to-action`, data, {
    headers: getAuthHeader(token),
  });
}
export async function accessDetailsEdit(token, data) {
  return axios.post(`${base}/action/changeaccessdata`, data, {
    headers: getAuthHeader(token),
  });
}
export async function deassignPSPFromAction(token, data) {
  return axios.post(`${base}/psp/deassign-psp-from-action`, data, {
    headers: getAuthHeader(token),
  });
}

export async function saveInjection(token, injectionToSave) {
  const headers = getAuthHeader(token);
  headers['Content-Type'] = 'multipart/form-data';
  return axios.post(`${base}/injection/save`, injectionToSave, { headers });
}

export async function aggregateStats(token) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/action/stats`, {}, { headers });
}

export async function getSmallCardsData(token, data, signal) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/action/get-small-cards-data`, data, { headers, signal });
}
export async function getActionsByPeriodChartData(token, data, signal) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/action/get-actions-by-period-chart-data`, data, { headers, signal });
}
export async function getTreemapTotalByEntityChartData(token, data, signal) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/action/get-treemap-total-by-entity-chart-data`, data, {
    headers,
    signal,
  });
}
export async function testFileDownload(token) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/box/testfile`, {}, { headers, responseType: 'blob' });
}

export async function toggleUserReadyToWorkForCountries(token, data) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/user/toggle-ready-to-work`, data, { headers });
}
export async function getMaffAvailableCountries(token) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/user/get-maff-available-countries`, {}, { headers });
}

export async function restartInjection(token, data) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/injection/restart`, data, { headers });
}
export async function restartPulling(token, data) {
  const headers = getAuthHeader(token);
  return axios.post(`${base}/action/restart-pulling`, data, { headers });
}
export async function countActionsForInj(token, data) {
  console.log('data inside api function', data);
  const headers = getAuthHeader(token);
  headers['Content-Type'] = 'application/json';
  return axios.post(`${base}/box/count_actions_for_inj`, data, { headers });
}

export async function savePulling(token, pullingToSave) {
  return axios.post(`${base}/pulling/save`, pullingToSave, { headers: getAuthHeader(token) });
}

export async function saveUser(token, userToSave) {
  return axios.post(`${base}/user/save`, userToSave, { headers: getAuthHeader(token) });
}

export async function saveSelectedFields(token, data) {
  return axios.post(`${base}/user/save_selected_fields`, data, { headers: getAuthHeader(token) });
}

export async function hideBox(token, data) {
  return axios.post(`${base}/user/hide_box`, data, { headers: getAuthHeader(token) });
}

export async function hideCampaign(token, data) {
  return axios.post(`${base}/user/hide_campaign`, data, { headers: getAuthHeader(token) });
}

export async function editHiddenCampaigns(token, data) {
  return axios.post(`${base}/user/edit-hidden-campaigns`, data, { headers: getAuthHeader(token) });
}
export async function makeCampaignActive(token, data) {
  return axios.post(`${base}/campaign/make-active`, data, { headers: getAuthHeader(token) });
}
export async function makeCampaignSendM(token, data) {
  return axios.post(`${base}/campaign/make-send-m`, data, { headers: getAuthHeader(token) });
}
export async function makeCampaignLimit1MPerGeo(token, data) {
  return axios.post(`${base}/campaign/make-limit-1-m-per-geo`, data, {
    headers: getAuthHeader(token),
  });
}
export async function makeCampaignBlockedByAdmin(token, data) {
  return axios.post(`${base}/campaign/make-blocked-by-admin`, data, {
    headers: getAuthHeader(token),
  });
}

export async function editHiddenBoxes(token, data) {
  return axios.post(`${base}/user/edit-hidden-boxes`, data, { headers: getAuthHeader(token) });
}

export async function sendTest(token, test) {
  return axios.post(`${base}/track/leadtest`, test, { headers: getAuthHeader(token) });
}
export async function sendTestPulling(token, test) {
  return axios.post(`${base}/campaign/test-pulling`, test, { headers: getAuthHeader(token) });
}

export async function sendTestGMLevelDistribution(token, test) {
  return axios.post(`${base}/action/check-gm-level-distribution`, test, {
    headers: getAuthHeader(token),
  });
}
export async function sendTestCheckAllGMLevelDistributions(token) {
  return axios.post(
    `${base}/action/check-all-injs-gm-level-distribution`,
    {},
    {
      headers: getAuthHeader(token),
    }
  );
}

export async function getFtds(token, data = {}) {
  return axios.post(`${base}/action/ftds`, data, { headers: getAuthHeader(token) });
}

export async function getMetaCampaigns(token, data = {}) {
  return axios.post(`${base}/campaign/get_meta_campaigns`, data, { headers: getAuthHeader(token) });
}
export async function pullMeta(token) {
  return axios.post(`${base}/campaign/initilize_pullmeta`, {}, { headers: getAuthHeader(token) });
}

export async function changeCrgRatesBackwards(token, data = {}) {
  return axios.post(`${base}/campaign/change_crg_backwards`, data, {
    headers: getAuthHeader(token),
  });
}

export async function uploadAvatar(formData) {
  const headers = getAuthHeader();
  headers['Content-Type'] = 'multipart/form-data';
  return axios.post(`${base}/user/uploadAvatar`, formData, { headers });
}

export async function deleteAction(token, data) {
  return axios.post(`${base}/action/delete_action`, data, { headers: getAuthHeader(token) });
}
export async function clearJunk(token, data) {
  return axios.post(`${base}/box/clear-junk`, data, { headers: getAuthHeader(token) });
}
export async function getWorkers(token, options = {}) {
  /**
   *
   * @param options = {
   *   // Фильтрация по этим свойствам осуществляется на уровне БД
   *   НЕ ИСПОЛЬЗУЕТСЯ from: string;    // DateTime.toISO(fromDateTime), // дефолтное значение -- начало года по utc
   *   НЕ ИСПОЛЬЗУЕТСЯ to: string;      // DateTime.toISO(toDateTime),   // дефолтное значение -- сегодня, сейчас по utc
   *   affiliates?: string[];
   *   lead.email?: string; // ищет нестрогое соответствие по regexp
   *   lead.phone?: string; // ищет нестрогое соответствие по regexp
   *   lead._id?: string; // ищет нестрогое соответствие по regexp
   *   _id?: string; // ищет нестрогое соответствие по regexp
   *   name?: string; // ищет нестрогое соответствие по regexp
   *
   *   // Фильтрация по этим свойствам осуществляется на уровне сервера
   *
   *   // Свойства для пагинации
   *   page?: number;                      // default = 1
   *   itemsPerPage?: number;              // default = 10
   *   sortBy?: string;                    // default = actionDate
   *   sortOrder?: 'asc' | 'desc';         // default = 'desc'
   * }
   * */
  const data = _.cloneDeep(options);
  const defaultData = {
    // from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
    // to: DateTime.utc().toISO(),
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return axios.post(`${base}/worker/list`, data, { headers: getAuthHeader(token) });
}
export async function deleteWorker(token, _id) {
  const params = { id: _id };
  return axios.delete(`${base}/worker`, { headers: getAuthHeader(token), params });
}
export async function deleteTransaction(token, _id) {
  const params = { id: _id };
  return axios.delete(`${base}/financial-transaction`, { headers: getAuthHeader(token), params });
}
export async function getWorker(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/worker`, { headers: getAuthHeader(token), params });
}
export async function getWorkerByLeadId(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/worker/by-lead-id`, { headers: getAuthHeader(token), params });
}
export async function saveWorker(token, workerToSave) {
  return axios.post(`${base}/worker/save`, workerToSave, { headers: getAuthHeader(token) });
}
export async function getAvailableWorkersForAction(token, data) {
  return axios.post(`${base}/worker/get-available-workers-for-action`, data, {
    headers: getAuthHeader(token),
  });
}
export async function assignWorkerToAction(token, data) {
  return axios.post(`${base}/worker/assign-worker-to-action`, data, {
    headers: getAuthHeader(token),
  });
}
export async function deassignWorkerFromAction(token, data) {
  return axios.post(`${base}/worker/deassign-worker-from-action`, data, {
    headers: getAuthHeader(token),
  });
}

export async function getCalls(token, options = {}) {
  const data = _.cloneDeep(options);
  const defaultData = {
    // from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
    // to: DateTime.utc().toISO(),
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return axios.post(`${base}/call/list`, data, { headers: getAuthHeader(token) });
}

export async function saveBCEPreset(token, bcePresetToSave) {
  return axios.post(`${base}/bce-settings-preset/save`, bcePresetToSave, {
    headers: getAuthHeader(token),
  });
}

export async function getBCEPresets(token, options = {}) {
  /**
   *
   * @param options = {
   *   // Фильтрация по этим свойствам осуществляется на уровне БД
   *   _id?: string; // ищет нестрогое соответствие по regexp
   *   geo?: string; // ищет строгое соответствие по странам
   *
   *   // Фильтрация по этим свойствам осуществляется на уровне сервера
   *
   *   // Свойства для пагинации
   *   page?: number;                      // default = 1
   *   itemsPerPage?: number;              // default = 10
   *   sortBy?: string;                    // default = actionDate
   *   sortOrder?: 'asc' | 'desc';         // default = 'desc'
   * }
   * */
  const data = _.cloneDeep(options);
  const defaultData = {
    type: 'all',
    // from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
    // to: DateTime.utc().toISO(),
    page: 1,
    itemsPerPage: 100,
  };
  for (const i of Object.keys(defaultData)) {
    if (isNotSet(data[i])) data[i] = defaultData[i];
  }
  for (const i of Object.keys(data)) {
    if (isNotSet(data[i])) delete data[i];
  }
  return axios.post(`${base}/bce-settings-preset/list`, data, { headers: getAuthHeader(token) });
}

export async function getBCEPreset(token, _id) {
  const params = { id: _id };
  return axios.get(`${base}/bce-settings-preset`, { headers: getAuthHeader(token), params });
}
export async function deleteBCEPreset(token, _id) {
  const params = { id: _id };
  return axios.delete(`${base}/bce-settings-preset`, { headers: getAuthHeader(token), params });
}
