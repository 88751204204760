<template>
  <div>
    <h3 class="m-2">Edit Access Details for</h3>
    <h3 class="mt-2">
      Lead
      <b>{{ email }}</b>
    </h3>
    <h3 class="mb-2">
      Broker
      <b>{{ finalReceiver }}</b>
      <b-icon icon="question-circle" id="header-question-circle" class="mx-1"></b-icon>
      <b-popover target="header-question-circle" triggers="hover" placement="right">
        <template #title>What does it mean?</template>
        <p>Add URL, Username and Password for the Broker's Cabinet where FTD was made</p>
      </b-popover>
    </h3>
    <label for="url">URL</label>
    <b-form-input
      id="url"
      class="my-2"
      v-model="accessDetailsForm.url"
      :state="isValid.fields.url"
      placeholder="Enter cabinet URL"
    ></b-form-input>
    <label for="username">Username</label>
    <b-form-input
      id="username"
      class="my-2"
      v-model="accessDetailsForm.username"
      :state="isValid.fields.username"
      placeholder="Enter Username"
    ></b-form-input>
    <label for="password">Password</label>
    <b-form-input
      id="password"
      class="my-2"
      v-model="accessDetailsForm.password"
      :state="isValid.fields.password"
      placeholder="Enter Password"
    ></b-form-input>
    <b-button
      class="my-2"
      variant="primary"
      @click="accessDetailsEdit()"
      :disabled="requesting || !isValid.form"
    >
      Save
    </b-button>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex';
import { handleRequestError, showCard } from '@/utils';
import { cloneDeep } from 'lodash';
import { accessDetailsEdit } from '@/utils/api';
import { formValid, isValid } from '@/validators';

const initialAccessDetailsForm = { url: undefined, username: undefined, password: undefined };

export default {
  name: 'AccessDetailsEdit',
  components: {},
  props: {
    action_id: { type: String, default: '' },
    email: { type: String, default: '' },
    finalReceiver: { type: String, default: '' },
    accessDetails: { type: Object, default: () => {} },
  },
  data() {
    return {
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      accessDetailsForm: cloneDeep(initialAccessDetailsForm),
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'token', 'requesting']),
    isValid() {
      this.backdoor;
      const fields = isValid('access_details', this.accessDetailsForm, this.accessDetailsForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
  },
  methods: {
    castAccessDetailsToForm(accessDetails) {
      if (accessDetails) {
        this.accessDetailsForm.url = accessDetails.url
          ? accessDetails.url
          : initialAccessDetailsForm.url;
        this.accessDetailsForm.username = accessDetails.username
          ? accessDetails.username
          : initialAccessDetailsForm.username;
        this.accessDetailsForm.password = accessDetails.password
          ? accessDetails.password
          : initialAccessDetailsForm.password;
      }
      this.backdoor += 1;
    },
    accessDetailsEdit() {
      this.$store.commit('make_requesting', { req: true });
      const { action_id } = this;
      const data = { ...this.accessDetailsForm, action_id };
      accessDetailsEdit(this.token, data)
        .then((r) => {
          if (r.data.success) {
            showCard(this, 'Access Details edited');
            this.$emit('accessDetailsEdited', {
              action_id,
              accessDetails: r.data.accessDetails,
            });
            this.castAccessDetailsToForm(r.data.accessDetails);
          } else {
            handleRequestError(this, r.data, 'Error occured while trying to assign PSP');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error occured while trying to assign PSP');
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  mounted() {
    this.castAccessDetailsToForm(this.accessDetails);
  },
  watch: {},
};
</script>

<style scoped>
.centered {
  align-content: center;
}
</style>
