<template>
  <div>
    <!--      v-b-toggle="`collapse-breakdown-${groupName.toLowerCase()}`"-->
    <b-button
      v-b-modal="`collapse-breakdown-${groupName.toLowerCase()}`"
      variant="primary"
      :style="cssVars"
    >
      <!--      Stats Breakdown by -->
      {{ groupName }}
    </b-button>
    <b-modal
      :id="`collapse-breakdown-${groupName.toLowerCase()}`"
      class="mt-2"
      centered
      hide-footer
      hide-header
      size="giant"
    >
      <b-card :title="`Stats by ${groupName}`" sub-title="Base, Mix, Total">
        <b-card-group columns>
          <b-card v-for="(item, index) in sortedItems" :key="index">
            <b-card-title>
              {{ item.item ? (item.item.name ? item.item.name : item.item) : 'unknown' }}
            </b-card-title>
            <b-card-sub-title>{{ item.item ? item.item._id : 'unknown' }}</b-card-sub-title>
            <div class="my-2">
              <div class="mx-2 my-1 dataspan orangetext">Base: {{ item.base }}</div>
              <div class="mx-2 my-1 dataspan orangetext">Mix: {{ item.mix }}</div>
              <div class="mx-2 my-1 dataspan orangetext">Total: {{ item.total }}</div>
              <div class="mx-2 my-1 dataspan orangetext">Base / Mix: {{ item.bm }}</div>
              <div class="mx-2 my-1 dataspan orangetext">Total / Mix: {{ item.tm }}</div>
            </div>
            <div class="my-2">
              <div class="mx-2 my-1 dataspan bluetext">Ftds: {{ item.ftds }}</div>
              <div class="mx-2 my-1 dataspan bluetext" v-if="!$rent">Shaved: {{ item.shaved }}</div>
              <div class="mx-2 my-1 dataspan bluetext">Positive: {{ item.positive }}</div>
              <div class="mx-2 my-1 dataspan bluetext">
                Positive-to-negative changers: {{ item.p2nchangers }}
              </div>
              <div class="mx-2 my-1 dataspan bluetext">Not accepted: {{ item.not_accepted }}</div>
            </div>
            <div class="my-2 forestgreen" v-if="item.acceptedByCountry">
              <h5>✅ by geo</h5>
              <div
                class="mx-2 my-1 dataspan"
                v-for="(country, index) in item.acceptedByCountry"
                v-bind:key="index"
              >
                {{ index }}: ✍️ {{ country.leads }}, 💵 {{ country.ftds }}, 🪒 {{ country.shaved }}
              </div>
            </div>
            <div class="my-2 tomatotext" v-if="item.notAcceptedByCountry">
              <h5>✖️ by geo</h5>
              <div
                class="mx-2 my-1 dataspan"
                v-for="(country, index) in item.notAcceptedByCountry"
                v-bind:key="index"
              >
                {{ index }}: ✍️ {{ country.leads }}, 💵 {{ country.ftds }}
              </div>
            </div>
            <div class="my-2">
              <div class="mx-2 my-1 dataspan greentext">Payout: ${{ item.payout }}</div>
              <div class="mx-2 my-1 dataspan greentext">Aff payout: ${{ item.aff_payout }}</div>
              <div class="mx-2 my-1 dataspan greentext">Profit: ${{ item.profit }}</div>
            </div>
          </b-card>
        </b-card-group>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'StatsBreakdownByItems',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    groupName: { type: String, default: '' },
    minBtnWidth: { type: Number, default: 0 },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['token', 'boxes']),
    cssVars() {
      return {
        'min-width': `${this.minBtnWidth}px`,
        '--min-width': `${this.minBtnWidth}px`,
      };
    },
    sortedItems() {
      return _.sortBy(this.items, [
        function (item) {
          return -Number(item.total);
          // return item.item ? (item.item.name ? item.item.name : item.item) : 'unknown';
        },
      ]);
    },
  },
  methods: {},
};
</script>

<style scoped>
.togglebutton {
  min-width: var(--min-width);
}
</style>
