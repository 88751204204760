<template>
  <div>
    <b-modal
      id="modal-create-worker"
      ref="modal-create-worker"
      centered
      hide-footer
      no-close-on-esc
    >
      <!--      @hide="addNewWorkerToList()"-->
      <WorkerCreate
        :checkAvailability="true"
        :action_id="action_id"
        @workerSaved="addNewWorkerToList"
      />
      <!--      <h3>Create Worker</h3>-->
    </b-modal>
    <h3 class="m-2">Workers that can be assigned to this Lead and Broker</h3>
    <h3 class="m-2">
      Lead
      <b>{{ email }}</b>
      , Broker
      <b>{{ finalReceiver }}</b>
      <b-icon icon="question-circle" id="header-question-circle" class="mx-1"></b-icon>
      <b-popover target="header-question-circle" triggers="hover" placement="right">
        <template #title>What does it mean?</template>
        <p>
          If you don't see Worker that you need, try to Add it via
          <b-button class="m-2" @click="addNewWorker()" variant="primary" :disabled="requesting">
            <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
            Add New
          </b-button>
          button.
        </p>
        <p>
          Workers are sorted based on their
          <b><i>preferrability</i></b>
          rate - this means, based on how good they are for this Lead and Broker.
        </p>
        <p>
          If some Worker's
          <b><i>preferrability</i></b>
          is lower than 5, you
          <b>can not use it</b>
          for this Lead and Broker.
        </p>
      </b-popover>
    </h3>
    <h3 v-if="worker && worker.name" class="m-2">
      Worker Assigned now:
      <b>{{ worker.name }}</b>
    </h3>
    <!--    <div style="display: block; align-items: center">-->
    <!--      <b-button class="m-2" @click="addNewWorker()" variant="primary" :disabled="requesting">-->
    <!--        <b-spinner small v-if="requesting" class="mx-1"></b-spinner>-->
    <!--        Add New-->
    <!--      </b-button>-->
    <!--    </div>-->
    <b-row class="my-2" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        lg="3"
        xl="3"
        cols="12"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        lg="3"
        xl="3"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="4"
        lg="2"
        xl="2"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="4"
        lg="2"
        xl="2"
        cols="12"
      >
        <b-button class="mx-2" variant="primary" :disabled="requesting" @click="updateData()">
          <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="4"
        lg="2"
        xl="2"
        cols="12"
      >
        <b-button class="m-2" @click="addNewWorker()" variant="primary" :disabled="requesting">
          <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
          Add New
        </b-button>
      </b-col>
    </b-row>
    <b-table
      class="table my-2"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else-if="data.field.key === 'preferrability'">
          {{ data.label }}
          <b-icon
            icon="question-circle"
            :id="`${data.field.key}-header-question-icon`"
            class="mx-1"
          />
          <b-popover
            :target="`${data.field.key}-header-question-icon`"
            triggers="hover"
            placement="right"
          >
            <template #title>What does it mean?</template>
            <p>
              This value shows how good is each
              <b>Worker</b>
              for this
              <b>Lead</b>
              and
              <b>Broker</b>
              combined.
            </p>
            <p>
              If some Worker's
              <b><i>preferrability</i></b>
              is lower than 5, you
              <b>can not use it</b>
              for this Lead and Broker.
            </p>
          </b-popover>
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(_id)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>
      <template #cell(name)="data">
        <span v-b-popover.hover.top="data.unformatted">
          {{ data.item.name }}
        </span>
      </template>
      <template #cell(active)="data">
        <h5 class="mb-0">
          <b-badge :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'">
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(preferrability)="data">
        <h5 v-if="worker && worker._id && worker._id === data.item._id">
          <b-badge variant="success">Assigned</b-badge>
        </h5>
        <b-badge
          v-else
          :variant="
            data.value > 7
              ? 'success'
              : data.value > 5
              ? 'warning'
              : data.value === 5
              ? 'warning'
              : 'danger'
          "
        >
          <span>
            <!--            :style="
              data.value > 7
                ? 'color: green;'
                : data.value > 5
                ? 'color: yellow;'
                : data.value === 5
                ? 'color: orange;'
                : 'color: red;'
            "-->
            {{ data.value }}
            <b-icon
              icon="info-circle"
              :id="`preferrability-info-icon-${data.item._id}`"
              class="mx-1"
            />
          </span>
        </b-badge>
        <b-popover
          :target="`preferrability-info-icon-${data.item._id}`"
          triggers="hover"
          placement="right"
        >
          <template #title>Why this rate?</template>
          <p>
            Preferrability rate for this Worker is {{ data.item.preferrability }}. Maximum is 10
          </p>
          <div v-if="Array.isArray(data.item.reasonsToDrop)">
            <p>Resons to lower rate</p>
            <ul>
              <li v-for="(reason, index) in data.item.reasonsToDrop" v-bind:key="index">
                {{ reason }}
              </li>
            </ul>
          </div>
          <p v-else>No reasons to lower preferrability rate</p>
          <p>
            If some Worker's
            <b><i>preferrability</i></b>
            is lower than 5, you
            <b>can not use it</b>
            for this Lead and Broker.
          </p>
        </b-popover>
      </template>
      <template #cell(assign)="data">
        <b-button
          v-if="worker && worker._id && worker._id === data.item._id"
          variant="danger"
          v-b-modal="`modal-cancel-worker-${data.item._id}`"
          size="sm"
        >
          Cancel
        </b-button>
        <b-button
          v-else
          size="sm"
          variant="primary"
          v-b-modal="`modal-assign-worker-${data.item._id}`"
          :disabled="!data.item.preferrability || data.item.preferrability < 5"
          v-b-popover:hover:bottom="data.item.reasonsToDrop.join('; ')"
        >
          Assign
        </b-button>
        <b-modal
          :id="`modal-assign-worker-${data.item._id}`"
          v-if="data.item.preferrability && data.item.preferrability >= 5"
          centered
          @ok="assignWorker(data.item._id)"
          ok-variant="success"
          ok-title="Yes, Assign!"
          cancel-title="No, Cancel"
          cancel-variant="danger"
          footer-class="centered"
          hide-header
          lazy
          :ok-disabled="requesting"
        >
          <h5>
            Are you sure you want to
            <b>Assign</b>
            Worker
            <b>
              {{ data.item.name }}
            </b>
            to Broker
            <b>{{ finalReceiver }}</b>
            for Lead
            <b>{{ email }}</b>
            ?
          </h5>
        </b-modal>
        <b-modal
          :id="`modal-cancel-worker-${data.item._id}`"
          v-if="worker && worker._id && worker._id === data.item._id"
          centered
          @ok="deassignWorker(data.item._id)"
          ok-variant="danger"
          ok-title="Yes, Cancel!"
          cancel-title="No, Go Back To Workers"
          cancel-variant="primary"
          footer-class="centered"
          hide-header
          lazy
          :ok-disabled="requesting"
        >
          <h5>
            Are you sure you want to
            <b>Cancel</b>
            Worker
            <b>{{ data.item.name }}</b>
            from Lead
            <b>{{ email }}</b>
            for Broker
            <b>{{ finalReceiver }}</b>
            ?
          </h5>
        </b-modal>
      </template>
    </b-table>
    <b-row class="my-2" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import { mapState } from 'vuex';
import { handleRequestError, showCard, tableFormatterId } from '@/utils';
import { cloneDeep } from 'lodash';
import {
  getAvailableWorkersForAction,
  assignWorkerToAction,
  deassignWorkerFromAction,
} from '@/utils/api';
import { DateTime } from 'luxon';
import WorkerCreate from './WorkerCreate.vue';

export default {
  name: 'WorkerAssign',
  components: { WorkerCreate },
  props: {
    action_id: { type: String, default: '' },
    email: { type: String, default: '' },
    finalReceiver: { type: String, default: '' },
    worker: { type: Object, default: () => {} },
  },
  data() {
    return {
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        // { value: 500, text: '500' },
        // { value: 1000, text: '1000' },
      ],
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      fields: [
        { key: '_id', sortable: false, formatter: (v) => tableFormatterId(v) },
        { key: 'name', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'active', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'preferrability', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'assign', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
      ],
      filter: null,
      filterIncludedFields: ['_id', 'name'],
      requesting: false,
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'token']), // 'requesting'
  },
  methods: {
    addNewWorker() {
      this.$refs['modal-create-worker'].show();
    },
    addNewWorkerToList(newWorker) {
      this.items = cloneDeep([
        newWorker,
        ...this.items.filter((item) => item._id !== newWorker._id),
      ]);
    },
    updateData() {
      // this.$store.commit('make_requesting', { req: true });
      this.requesting = true;
      const { totalRows, currentPage, perPage, action_id } = this;
      const data = { totalRows, currentPage, perPage, _id: action_id };
      getAvailableWorkersForAction(this.token, data)
        .then((r) => {
          this.items = cloneDeep(r.data);
          // this.$store.commit('make_requesting', { req: false });
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error getting Worker available for this action');
          // this.$store.commit('make_requesting', { req: false });
          this.requesting = false;
        });
    },
    assignWorker(worker_id) {
      // this.$store.commit('make_requesting', { req: true });
      this.requesting = true;
      const { action_id } = this;
      const data = { worker_id, action_id };
      assignWorkerToAction(this.token, data)
        .then((r) => {
          if (r.data.result) {
            showCard(this, 'Worker assigned');
            const worker = this.items.find((item) => item._id === worker_id);
            this.updateData();
            this.$emit('workerAssigned', {
              action_id,
              worker: { _id: worker || worker_id, assigned: DateTime.utc().toISO() },
            });
          } else {
            handleRequestError(this, r.data, 'Error occured while trying to assign Worker');
          }
          // this.$store.commit('make_requesting', { req: false });
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error occured while trying to assign Worker');
          // this.$store.commit('make_requesting', { req: false });
          this.requesting = false;
        });
    },
    deassignWorker(worker_id) {
      // this.$store.commit('make_requesting', { req: true });
      this.requesting = true;
      const { action_id } = this;
      const data = { worker_id, action_id };
      deassignWorkerFromAction(this.token, data)
        .then((r) => {
          if (r.data.result) {
            showCard(this, 'Worker cancelled from Lead');
            // const worker = this.items.find((item) => item._id === worker_id);
            this.updateData();
            this.$emit('workerAssigned', {
              action_id,
              worker: undefined,
              // worker: { _id: worker || worker_id, assigned: DateTime.utc().toISO() },
            });
          } else {
            handleRequestError(
              this,
              r.data,
              'Error occured while trying to cancel Worker from Lead'
            );
          }
          // this.$store.commit('make_requesting', { req: false });
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error occured while trying to cancel Worker from Lead');
          // this.$store.commit('make_requesting', { req: false });
          this.requesting = false;
        });
    },
  },
  mounted() {
    this.updateData();
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
  },
};
</script>

<style scoped>
.centered {
  align-content: center;
}
</style>
