import { validateRefArray } from '@/validators/_utils';
import { DateTime } from 'luxon';
import { datepickerFormat } from '@/consts';

function validatePullingDate(c, h) {
  // console.log('validate start date', c, h.startTime);
  const startDateTime = DateTime.fromFormat(`${String(h.fromDate)} 00:00:00`, 'yyyy-L-d TT');
  const endDateTime = DateTime.fromFormat(`${String(h.toDate)} 23:59:59`, 'yyyy-L-d TT');
  return (
    (!c || c === '' || (!!c && DateTime.fromFormat(c, datepickerFormat).isValid)) &&
    (startDateTime.isValid ? startDateTime : -Infinity) <
      (endDateTime.isValid ? endDateTime : Infinity)
  );
}

export default {
  campaigns: (c) => validateRefArray(c.map((ce) => ce._id)),
  fromDate: (c, h) => validatePullingDate(c, h),
  toDate: (c, h) => validatePullingDate(c, h),
  days: (c) => !Number.isNaN(Number(c)) && Number(c) > 0,
};
