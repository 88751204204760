import { capCheck, oidRegex } from '@/validators/_utils';
import { validateHourLimit } from '@/validators/box';

export default {
  _id: (c) => !!(!c || oidRegex.test(c)),
  geo: (c) => {
    const k = c && c.value ? c.value : c;
    return typeof k === 'string';
  },
  dailyCap: (c) => capCheck(c),
  defaultPayment: (c) => !c || Number(c) >= 0,
  hourLimit: (c) => validateHourLimit(c),
  days: (c) => Array.isArray(c) && c.every((d) => Number.isInteger(d)),
  priority: (c) => !!c && !Number.isNaN(Number(c)) && Number(c) > 0,
  weight: (c) => !!c && !Number.isNaN(Number(c)) && Number(c) > 0,
  active: (c) => typeof c === 'boolean',
};
