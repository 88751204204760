<template>
  <b-container fluid>
    <b-row class="my-3">
      <b-col cols="12" lg="1" class="my-lg-0 my-1"><h2>Users</h2></b-col>
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="3"
      >
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <!--        </div>-->
      </b-col>

      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <b-button @click="toggleInactive()" class="mx-2">
          {{ hideInactive ? 'Show inactive' : 'Hide inactive' }}
        </b-button>
      </b-col>

      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
        <!--        </div>-->
      </b-col>
      <b-col cols="12" lg="4" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
        <b-button to="/create-user" variant="primary">Create</b-button>
        <!--        </div>-->
        <div>
          <b-button v-b-toggle.sidebar-right-table @click="closeSidebarDetails()" class="mx-2">
            Edit Table
          </b-button>
          <b-button v-b-toggle.sidebar-right-details @click="closeSidebarColumns()" class="mx-2">
            Edit Details
          </b-button>
          <b-sidebar
            id="sidebar-right-table"
            v-model="sidebarColumnsOpen"
            title="Table Fields"
            right
            shadow
          >
            <div class="px-3 py-2">
              <b-form-checkbox-group
                v-model="preSelectedColumns"
                :options="tableFieldsOptions"
                class="mb-3"
                @input="logSelectedColumns()"
                stacked
              ></b-form-checkbox-group>
              <b-button
                variant="primary"
                @click="applyFields()"
                :disabled="!canApplyFields"
                class="my-2 sidebar-button"
              >
                Apply
              </b-button>
              <b-button
                variant="success"
                @click="saveSelectedFields('users_table')"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="warning"
                @click="restoreTablePreference()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
          <b-sidebar
            id="sidebar-right-details"
            v-model="sidebarDetailsOpen"
            title="Show Details"
            right
            shadow
          >
            <div class="px-3 py-2">
              <b-form-checkbox-group
                v-model="preSelectedDetails"
                :options="detailsOptions"
                class="mb-3"
                @input="logSelectedDetails()"
                stacked
              ></b-form-checkbox-group>
              <b-button
                variant="primary"
                @click="applyDetails()"
                :disabled="!canApplyDetails"
                class="my-2 sidebar-button"
              >
                Apply
              </b-button>
              <b-button
                variant="success"
                @click="saveSelectedFields('users_details')"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="warning"
                @click="restoreDetailsPreference()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
        </div>
      </b-col>
    </b-row>
    <b-table
      class="mb-3"
      :fields="sortedSelectedFields"
      :items="filteredItems"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :busy="requesting"
      @filtered="onFiltered"
      responsive
      show-empty
      striped
      hover
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <!--            style="white-space: nowrap"
-->
      <!--      <template #cell(id)="data">-->
      <!--        <b-link @click="data.toggleDetails" style="color: inherit">-->
      <!--          {{ data.item.id }}-->
      <!--        </b-link>-->
      <!--      </template>-->
      <!--      <template #cell(name)="data">-->
      <!--        <b-link @click="data.toggleDetails" style="color: inherit">-->
      <!--          {{ data.item.name }}-->
      <!--        </b-link>-->
      <!--        &lt;!&ndash;        <b-button size="sm" variant="primary">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <b-icon icon="pencil-square"></b-icon>&ndash;&gt;-->
      <!--        &lt;!&ndash;          Rename&ndash;&gt;-->
      <!--        &lt;!&ndash;        </b-button>&ndash;&gt;-->
      <!--      </template>-->

      <template #cell(active)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'danger'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(rtcMandatoryFlow)="data">
        <h5>
          <b-badge
            :variant="data.value === true ? 'success' : 'danger'"
            @click="changeRTCMandatoryFlow(data.item._id)"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(rtcMandatoryFlowPSP)="data">
        <h5>
          <b-badge
            :variant="data.value === true ? 'success' : 'danger'"
            @click="changeRTCMandatoryFlowPSP(data.item._id)"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(online)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'danger'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(extendedFieldsAccess)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'warning' : 'info'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(maff)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'warning' : 'info'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template
        #cell(workingOnCountries)="data"
        v-b-popover:hover.bottom="
          Array.isArray(data.unformatted) ? data.unformatted.join(', ') : 'none'
        "
      >
        {{ data.value }}
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button
            variant="success"
            :disabled="data.item.boxes === 0"
            v-if="data.item.type !== 'admin' && isManager && !data.item.active"
            @click="makeUserActive(data.item._id, true)"
          >
            <b-icon icon="play-fill"></b-icon>
            Play
          </b-button>
          <b-button
            variant="warning"
            :disabled="data.item.boxes === 0"
            v-if="data.item.type !== 'admin' && isManager && data.item.active"
            @click="makeUserActive(data.item._id, false)"
          >
            <b-icon icon="pause-fill"></b-icon>
            Pause
          </b-button>
          <b-button variant="primary" :to="`/user/edit/${data.item._id}`">
            <b-icon icon="pencil-square"></b-icon>
            Edit
          </b-button>
          <b-button
            v-if="data.item._id"
            v-b-modal="`modal-affiliate-api-docs-${data.item._id}`"
            style="display: inline; vertical-align: middle"
            variant="info"
            v-b-popover.hover.top="'Affiliate API Docs'"
          >
            <i :class="[`bi-gear-wide-connected`]"></i>
            API
          </b-button>
          <b-modal
            v-if="data.item._id"
            :id="`modal-affiliate-api-docs-${data.item._id}`"
            :ref="`modal-affiliate-api-docs-${data.item._id}`"
            centered
            ok-only
            ok-title="Close"
            size="giant"
            scrollable
            :title="`Affiliate API Integration Docs`"
          >
            <affiliate-api-docs :aff-id="data.item._id"></affiliate-api-docs>
          </b-modal>
          <!--          <b-button @click="data.toggleDetails">-->
          <!--            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details-->
          <!--          </b-button>-->
        </b-button-group>
      </template>
      <!-- TODO: Render all needed details properly -->
      <template #row-details="row">
        <!--        {{ row.item }} -->
        <b-card-group columns>
          <b-card v-if="detailAvailable('manager')">
            <b-card-title>
              Manager
              <router-link
                v-if="row.item.managerToShow"
                :to="`/user/edit/${row.item.managerToShow._id}`"
                class="mx-1"
              >
                <b-badge variant="primary">{{ row.item.managerToShow.name }}</b-badge>
              </router-link>
              <b-badge class="mx-1" variant="primary" v-else>-</b-badge>
            </b-card-title>
          </b-card>

          <b-card v-if="detailAvailable('campaigns')">
            <b-card-title>
              Campaigns
              <span v-for="(c, index) in row.item.campaignsToShow" v-bind:key="index">
                <router-link
                  v-bind:key="index"
                  v-if="c._id !== 'all'"
                  :to="`/campaign/edit/${c._id}`"
                >
                  <b-badge variant="primary" class="mx-1">{{ c.name }}</b-badge>
                </router-link>
                <b-badge variant="primary" v-else>{{ c.name }}</b-badge>
              </span>
            </b-card-title>
          </b-card>

          <b-card v-if="detailAvailable('boxes')">
            <b-card-title>
              Boxes
              <span v-for="(b, index) in row.item.boxesToShow" v-bind:key="index">
                <router-link v-bind:key="index" v-if="b._id !== 'all'" :to="`/box/edit/${b._id}`">
                  <b-badge variant="primary" class="mx-1">{{ b.name }}</b-badge>
                </router-link>
                <b-badge variant="primary" v-else>{{ b.name }}</b-badge>
              </span>
            </b-card-title>
          </b-card>

          <b-card v-if="detailAvailable('affiliates')">
            <b-card-title>
              Affiliates
              <span v-for="(a, index) in row.item.affiliatesToShow" v-bind:key="index">
                <router-link v-bind:key="index" v-if="a._id !== 'all'" :to="`/user/edit/${a._id}`">
                  <b-badge variant="primary" class="mx-1">{{ a.name }}</b-badge>
                </router-link>
                <b-badge variant="primary" v-else>{{ a.name }}</b-badge>
              </span>
            </b-card-title>
          </b-card>
        </b-card-group>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row class="mb-3">
      <!--      align-v="left"-->
      <!--      <b-col ></b-col>-->
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
        <!--        </div>-->
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
        <!--        </div>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import {
  isAffiliate,
  isManager,
  isAdmin,
  tableFormatterId,
  tableFormatterNameAndId,
  // tableFormatterArrayValues,
  showCard,
  handleRequestError,
} from '@/utils';
import store from '@/store';
import { makeUserActive, changeRTCMandatoryFlow, changeRTCMandatoryFlowPSP } from '@/utils/api';
// import { isAdmin } from '@/utils';
import { userAvailableFields, userAvailableDetails } from '@/consts/userAvailableFields';
import {
  tableFieldsOptions,
  sortedSelectedFields,
  detailsOptions,
  sortedSelectedDetails,
  saveSelectedFieldsMethod,
  takeDefaultTableFields,
  takeDefaultDetailsOptions,
  applyFields,
  applyDetails,
  canApplyFields,
  canApplyDetails,
} from '@/utils/fieldsAndDetailsOptionsHelpers';
import { DateTime } from 'luxon';
import AffiliateApiDocs from '@/components/shared/AffiliateApiDocs/AffiliateApiDocs.vue';

export default {
  name: 'UsersTable',
  components: { AffiliateApiDocs },
  data() {
    return {
      hideInactive: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      selectedColumns: [],
      preSelectedColumns: [],
      selectedDetails: [],
      preSelectedDetails: [],
      sidebarColumnsOpen: false,
      sidebarDetailsOpen: false,
    };
  },
  computed: {
    ...mapState([
      'user',
      'token',
      'users',
      'actions',
      'boxes',
      'requesting',
      'campaigns',
      'isAdmin',
      'isManager',
      'isMasterAffiliate',
      'store_backdoor',
    ]),
    canApplyFields() {
      return canApplyFields(this);
    },
    canApplyDetails() {
      return canApplyDetails(this);
    },
    userAvailableFields() {
      console.time('userAvailableFields');
      let r;
      if (this.isAdmin) r = userAvailableFields.users.admin();
      else if (this.isManager) r = userAvailableFields.users.manager();
      else if (this.isMasterAffiliate) r = userAvailableFields.users.masteraffiliate();
      else r = userAvailableFields.users.affiliate();
      console.timeEnd('userAvailableFields');
      return r;
    },
    tableFieldsOptions() {
      return tableFieldsOptions(this);
    },
    sortedSelectedFields() {
      return sortedSelectedFields(this);
    },
    userAvailableDetails() {
      if (this.isAdmin) return userAvailableDetails.users.admin();
      if (this.isManager) return userAvailableDetails.users.manager();
      if (this.isMasterAffiliate) return userAvailableDetails.users.masteraffiliate();
      return userAvailableDetails.users.affiliate();
    },
    detailsOptions() {
      return detailsOptions(this);
    },
    sortedSelectedDetails() {
      return sortedSelectedDetails(this);
    },
    realitems() {
      this.store_backdoor;
      return this.users.map((c) => {
        console.log(c.workingOnCountries);
        // const userActionsToday = this.actions.filter(
        //   (a) =>
        //     (isAffiliate(c) &&
        //       a.affiliate &&
        //       (a.affiliate === c._id || a.affiliate._id === c._id)) ||
        //     (isManager(c) && a.manager && (a.manager === c._id || a.manager._id === c._id))
        // );
        return {
          _id: c._id,
          email: c.email,
          name: c.name,
          active: c.active,
          rtcMandatoryFlow: c.rtcMandatoryFlow,
          rtcMandatoryFlowPSP: c.rtcMandatoryFlowPSP,
          lastTimeSeen: c.lastTimeSeen,
          online: DateTime.utc() - DateTime.fromISO(c.lastTimeSeen) < 3 * 60 * 1000,
          isAdmin: isAdmin(c),
          isManager: isManager(c),
          isAffiliate: isAffiliate(c),
          // actionsToday: boxActionsToday.length,
          campaigns: isAdmin(c)
            ? 'All'
            : isManager(c)
            ? c.campaigns
              ? c.campaigns.length
              : 0
            : '-',
          campaignsToShow: isAdmin(c)
            ? [{ name: 'All', _id: 'all' }]
            : isManager(c)
            ? c.campaigns
            : [],
          boxes: isAdmin(c) ? 'All' : c.boxes ? c.boxes.length : 0, // isManager(c) ?  : '-'
          boxesToShow: isAdmin(c)
            ? [{ name: 'All', _id: 'all' }]
            : // : isManager(c)
            c.boxes
            ? c.boxes
            : [],
          // : [],
          affiliates: isAdmin(c)
            ? 'All'
            : isManager(c)
            ? c.affiliates
              ? c.affiliates.map((a) => tableFormatterNameAndId(a))
              : []
            : '-',
          affiliatesToShow: isAdmin(c)
            ? [{ name: 'All', _id: 'all' }]
            : isManager(c)
            ? c.affiliates
              ? c.affiliates
              : []
            : [], //
          manager: c.manager
            ? c.manager.name
              ? c.manager.name
              : tableFormatterId(c.manager._id ? c.manager._id : c.manager)
            : '-',
          managerToShow: c.manager,
          type: c.type,
          messenger: c.messenger ? c.messenger : {},
          workingOnCountries: c.workingOnCountries ? c.workingOnCountries : [],
          mother: c.mother ? c.mother : false,
          maff: c.maff ? c.maff : false,
          cs: c.cs ? c.cs : false,
          extendedFieldsAccess: c.extendedFieldsAccess ? c.extendedFieldsAccess : false,
          canUploadLeads: c.canUploadLeads ? c.canUploadLeads : false,
          campaignsAccess: c.campaignsAccess ? c.campaignsAccess : false,
          // impressionsToday: userActionsToday.filter((a) => a.type === 'impression').length,
          // clicksToday: userActionsToday.filter((a) => a.type === 'click').length,
          // leadsToday: userActionsToday.filter((a) => a.type === 'lead').length,
          // ftdsToday: userActionsToday.filter((a) => a.type === 'ftd').length,
          // payoutsToday: userActionsToday.reduce((s, a) => (a.payout ? s + a.payout : s), 0),
          // revenueToday: '$1215423412', // need broker payout
          // profitToday: '$1212298889', // need broker payout
        };
      });
    },
    filteredItems() {
      if (!this.hideInactive) return this.realitems;
      return this.realitems.filter(({ active }) => active);
    },
  },
  mounted() {
    this.updateData();
    // await this.$store.dispatch('get_users', { vm: this });
    // this.backdoor += 1;
    // this.totalRows = this.realitems.length;
    // this.totalRows = this.items.length;
  },
  methods: {
    applyFields() {
      applyFields(this);
    },
    applyDetails() {
      applyDetails(this);
    },
    saveSelectedFields(type) {
      saveSelectedFieldsMethod(this, type);
    },
    takeDefaultTableFields() {
      takeDefaultTableFields(this, 'usersTableSelectedFields');
    },
    takeDefaultDetailsOptions() {
      takeDefaultDetailsOptions(this, 'usersTableSelectedDetails');
    },
    restoreTablePreference() {
      this.takeDefaultTableFields();
      this.saveSelectedFields('users_table');
    },
    restoreDetailsPreference() {
      this.takeDefaultDetailsOptions();
      this.saveSelectedFields('users_details');
    },
    closeSidebarColumns() {
      this.sidebarColumnsOpen = false;
    },
    closeSidebarDetails() {
      this.sidebarDetailsOpen = false;
    },
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    logSelectedColumns() {
      console.log(this.selectedColumns);
    },
    logSelectedDetails() {
      console.log(this.selectedDetails);
    },
    toggleInactive() {
      this.hideInactive = !this.hideInactive;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async updateData() {
      // await this.$store.dispatch('get_all_resources', { vm: this });
      // await this.$store.dispatch('get_users', { vm: this });
      // await this.$store.dispatch('get_actions', { vm: this });
      // await this.$store.dispatch('get_boxes', { vm: this });
      // this.backdoor += 1;
      await store.dispatch('get_all_resources_one_request', { vm: this });
      this.totalRows = this.realitems.length;
    },
    makeUserActive(_id, active) {
      const userToSave = {
        _id,
        active,
      };
      makeUserActive(this.token, userToSave)
        .then(async (r) => {
          if (r.status === 200) {
            showCard(
              this,
              `${r.data.name} user set ${active ? 'Active' : 'Inactive'}`,
              active ? 'success' : 'warning',
              'Success! 🥳'
            );
            if (r.data._id)
              this.$store.commit('update_item_by_id', { index: 'users', item: r.data });
            else showCard(this, `User not found after update`, 'warning');
          } else {
            const cardError =
              r.data.error ||
              `${r.status}: Unknown error on setting box ${active ? 'Active' : 'Inactive'}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
        })
        .catch((e) => {
          handleRequestError(
            e,
            `Unknown server error on setting box ${active ? 'Active' : 'Inactive'}`,
            'Error making box active'
          );
        });
    },
    changeRTCMandatoryFlow(_id) {
      const userToSave = { _id };
      changeRTCMandatoryFlow(this.token, userToSave)
        .then(async (r) => {
          if (r.status === 200) {
            showCard(
              this,
              `${r.data.name} user FTD Made Flow set ${
                r.data.rtcMandatoryFlow ? 'MANDATORY' : 'OPTIONAL'
              }`,
              r.data.rtcMandatoryFlow ? 'warning' : 'success',
              'Success! 🥳'
            );
            if (r.data._id)
              this.$store.commit('update_item_by_id', { index: 'users', item: r.data });
            else showCard(this, `User not found after update`, 'warning');
          } else {
            const cardError =
              r.data.error || `${r.status}: Unknown error on changing user's FTD Mandatory Flow`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
        })
        .catch((e) => {
          handleRequestError(
            e,
            `Unknown server error on changing user's FTD Mandatory Flow`,
            "Error changing user's FTD Mandatory Flow"
          );
        });
    },
    changeRTCMandatoryFlowPSP(_id) {
      const userToSave = { _id };
      changeRTCMandatoryFlowPSP(this.token, userToSave)
        .then(async (r) => {
          if (r.status === 200) {
            showCard(
              this,
              `${r.data.name} user FTD Made Flow PSP set ${
                r.data.rtcMandatoryFlowPSP ? 'MANDATORY' : 'OPTIONAL'
              }`,
              r.data.rtcMandatoryFlowPSP ? 'warning' : 'success',
              'Success! 🥳'
            );
            if (r.data._id)
              this.$store.commit('update_item_by_id', { index: 'users', item: r.data });
            else showCard(this, `User not found after update`, 'warning');
          } else {
            const cardError =
              r.data.error ||
              `${r.status}: Unknown error on changing user's FTD Mandatory Flow PSP`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
        })
        .catch((e) => {
          handleRequestError(
            e,
            `Unknown server error on changing user's FTD Mandatory Flow PSP`,
            "Error changing user's FTD Mandatory Flow PSP"
          );
        });
    },
  },
};
</script>
<style scoped>
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
