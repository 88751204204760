<template>
  <!--  <b-container fluid>-->
  <div>
    <!--    <label class="mx-1 my-2">{{ name }}</label>-->
    <b-form-group
      class="my-3"
      label="Request Type"
      label-for="requestparameters-requesttype"
      :state="isValid.fields.RequestType"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"-->
      <b-form-select
        id="requestparameters-requesttype"
        v-model="form.RequestType"
        :options="RequestTypeOptions"
        @input="emitEditEvent"
        :state="isValid.fields.RequestType"
      ></b-form-select>
    </b-form-group>
    <b-form-group
      class="my-3"
      label="Request Data Transfer Form"
      label-for="requestparameters-requestdatatransferform"
      :state="isValid.fields.RequestDataTransferForm"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
    -->
      <b-form-select
        id="requestparameters-requestdatatransferform"
        v-model="form.RequestDataTransferForm"
        :options="RequestDataTransferFormOptions"
        @input="emitEditEvent"
        :state="isValid.fields.RequestDataTransferForm"
      ></b-form-select>
      <!--      :state="isValid.fields.name"-->
    </b-form-group>
    <!--    <b-form-group class="my-3" label-for="requestparameters-encodedata">-->
    <!--      id="requestparameters-encodedata"-->
    <b-form-checkbox class="my-3" v-model="form.EncodeData" @input="emitEditEvent">
      Encode Data
    </b-form-checkbox>
    <p>Encode Data: {{ form.EncodeData }}</p>
    <!--    </b-form-group>-->
    <ObjectKeyValueForm
      :object="form.RequestHeaders"
      @edit="handleEditEvent($event, 'RequestHeaders')"
      @validate="handleValidateEvent($event, 'RequestHeaders')"
      disallowEmpty
    ></ObjectKeyValueForm>
    <ObjectKeyValueForm
      :object="form.RequestBody"
      name="Request Body"
      @edit="handleEditEvent($event, 'RequestBody')"
      @validate="handleValidateEvent($event, 'RequestBody')"
    ></ObjectKeyValueForm>
    <AuthParameters
      v-if="!noAuth"
      :auth="form.Auth"
      @edit="handleEditEvent($event, 'Auth')"
      @validate="handleValidateEvent($event, 'Auth')"
    ></AuthParameters>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep, omit } from 'lodash';
// import { castAuthArrayToObject, castAuthObjectToArray } from '@/validators';
import ObjectKeyValueForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/ObjectKeyValueForm.vue';
import AuthParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/AuthParameters.vue';
import handleEditEvent from '@/utils/handleEditEvent';
import handleValidateEvent from '@/utils/handleValidateEvent';
import { mapPropsToValid } from '@/utils';
import { formValid, isValid } from '@/validators';

export default {
  name: 'RequestParameters',
  components: { ObjectKeyValueForm, AuthParameters },
  props: {
    name: { type: String, default: 'Request Parameters' },
    noAuth: { type: Boolean, default: false },
    RequestType: { type: String, default: '' },
    RequestDataTransferForm: { type: String, default: '' },
    EncodeData: { type: Boolean, default: true },
    RequestHeaders: {
      type: Object,
      default: () => {
        return {};
      },
    },
    RequestBody: {
      type: Object,
      default: () => {
        return {};
      },
    },
    Auth: {
      type: Object,
      default: () => {
        return { method: 'direct' }; // TODO Auth with separateRequest
      },
    },
  },
  data() {
    return {
      form: (() => {
        const props = omit(cloneDeep(this.$props), ['name']);
        if (this.noAuth) props.Auth = { method: 'direct' };
        if (props.EncodeData === undefined) props.EncodeData = true;
        // props.RequestHeaders = castAuthObjectToArray(props.RequestHeaders);
        return props;
      })(),
      valid: cloneDeep(mapPropsToValid(this.$props)),
      RequestTypeOptions: ['get', 'post'],
      RequestDataTransferFormOptions: [
        'json',
        'x-www-form-urlencoded',
        'formdata',
        'querystring',
        'querystring-array-serialized',
        'endpoint',
        'endpoint-array-serialized',
      ],
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      // console.log('backdoor', this.backdoor);
      const fields = {
        ...this.valid,
        ...isValid('integration_RequestParameters', this.form, this.form),
      };
      fields.Auth = fields.Auth || (!this.noAuth && this.valid.Auth);
      const form = formValid(fields);
      // this.$emit('validate', fields);
      // console.log(`RequestParameters ${this.noAuth ? 'noAuth' : ''} validated`, { fields, form });
      return { fields, form }; // : formValid(fields)
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    castFormToEmit() {
      const form = cloneDeep(this.form);
      // form.RequestHeaders = castAuthArrayToObject(form.RequestHeaders);
      return form;
    },
    emitEditEvent() {
      this.$emit('edit', this.castFormToEmit());
    },
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.fields);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
