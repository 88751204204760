<template>
  <div v-if="requesting" :class="divClass">
    <b-spinner class="align-middle" :small="small" :variant="variant"></b-spinner>
    <strong v-if="showText">Loading...</strong>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    requesting: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    variant: { type: String, default: 'primary' },
    textPosition: { type: String, default: 'center' },
    marginY: { type: Number, default: 0 },
    marginX: { type: Number, default: 0 },
    showText: { type: Boolean, default: true },
  },
  computed: {
    textVariant() {
      return `text-${this.variant}`;
    },
    positionVariant() {
      return `text-${this.textPosition}`;
    },
    my() {
      return this.marginY > 0 && this.marginY <= 5 ? `my-${this.marginY}` : '';
    },
    mx() {
      return this.marginX > 0 && this.marginX <= 5 ? `mx-${this.marginX}` : '';
    },
    divClass() {
      return `${this.positionVariant} ${this.textVariant} ${this.my} ${this.mx}`;
    },
  },
};
</script>

<style scoped></style>
