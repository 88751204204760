<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h4 class="mx-1 my-2">{{ name }}</h4>
    <b-form-group
      class="my-3"
      label="Auth Method"
      label-for="authparameters-method"
      :state="isValid.fields.method"
    >
      <b-form-select
        id="authparameters-method"
        v-model="form.method"
        :options="authMethodOptions"
        @input="emitEditEvent"
        :state="isValid.fields.method"
      ></b-form-select>
    </b-form-group>
    <div v-if="form.method === 'separateRequest'">
      <AuthAttachParameters
        :attach="form.attach"
        @edit="handleEditEvent($event, 'attach')"
        @validate="handleValidateEvent($event, 'attach')"
      ></AuthAttachParameters>
      <SendLeadParameters
        id="auth-request-parameters"
        name="Auth Request Parameters"
        :parameters="form.requestParameters"
        :noAuth="true"
        @edit="handleEditEvent($event, 'requestParameters')"
        @validate="handleValidateEvent($event, 'requestParameters')"
      ></SendLeadParameters>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import '@/assets/form.scss';
import { cloneDeep } from 'lodash'; // omit
import { mapPropsToValid } from '@/utils';
import { authAttachToOptions, authMethodOptions } from '@/consts';
// import SendLeadParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/SendLeadParameters.vue';
import AuthAttachParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/AuthAttachParameters.vue';
import handleEditEvent from '@/utils/handleEditEvent';
import handleMultipleKeysEditEvent from '@/utils/handleMultipleKeysEditEvent';
import handleValidateEvent from '@/utils/handleValidateEvent';
import handleMultipleKeysValidateEvent from '@/utils/handleMultipleKeysValidateEvent';
import { formValid, isValid } from '@/validators';
// import PathArrayForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PathArrayForm.vue';

const initial = { method: 'direct' };

export default {
  name: 'AuthParameters',
  components: {
    // AuthRequestParameters: SendLeadParameters,
    // SendLeadParameters,
    AuthAttachParameters,
  },
  props: {
    name: { type: String, default: 'Auth Parameters' },
    auth: {
      method: { type: String, default: 'direct' },
      attach: {
        type: Object,
        default: () => {
          return {};
        },
      },
      requestParameters: {
        type: Object,
        default: () => {
          return {};
        },
      },
      dataPreprocessing: {
        type: Object,
        default: () => {
          return { type: 'plain' };
        },
      },
    },
  },
  data() {
    return {
      form: cloneDeep(this.$props.auth ? this.$props.auth : initial),
      valid: cloneDeep(mapPropsToValid(this.$props.auth, initial)),
      backdoor: 0,
      authMethodOptions,
      authAttachToOptions,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      // console.log('backdoor', this.backdoor);
      const attach = this.method === 'direct' || this.valid.attach;
      const requestParameters = this.method === 'direct' || this.valid.requestParameters;
      const dataPreprocessing = this.method === 'direct' || this.valid.dataPreprocessing;
      const fields = {
        // ...this.valid,
        ...isValid('integration_AuthParameters', this.form, this.form),
        attach,
        requestParameters,
        dataPreprocessing,
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      // console.log('AuthParameters validated', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    handleMultipleKeysEditEvent,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleValidateEvent,
    handleMultipleKeysValidateEvent,
    // handleMultipleKeysValidateEvent(data, keys, formKey) {
    //   handleMultipleKeysValidateEvent(this, data, keys, formKey);
    // },
    emitValidateEvent() {
      // console.log(`this.$emit('validate', ${this.isValid.form});`);
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
  beforeCreate() {
    if (this.$options.components) {
      // eslint-disable-next-line global-require
      const SendLeadParameters_noAuth = require('./SendLeadParameters.vue').default;
      SendLeadParameters_noAuth.name = 'SendLeadParameters_noAuth';
      this.$options.components.SendLeadParameters = SendLeadParameters_noAuth;
    }
  },
};
</script>

<style scoped></style>
