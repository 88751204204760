<template>
  <div>
    <b-row class="my-1">
      <b-col cols="5" sm="12" md="12" lg="5" xl="5">
        <h5>Send lead</h5>
        <p>
          Email
          <b>{{ lead.email }}</b>
        </p>
        <p>
          Phone
          <b>{{ lead.phone }}</b>
        </p>
        <p>
          Geo
          <b>{{ lead.country }}</b>
          <!--      <b-form-checkbox v-model="copyForm.m" class="mx-2 my-1">Send as M</b-form-checkbox>-->
        </p>
        <b-form-checkbox v-model="urlsRequestParams.useTTL" class="mx-2 my-1">
          Use FinalReceiver TimeToLive
        </b-form-checkbox>
        <b-form-checkbox v-model="urlsRequestParams.usePartialPhoneFilter" class="mx-2 my-1">
          Filter By Similar Phones Sent
        </b-form-checkbox>
        <b-form-checkbox
          v-model="urlsRequestParams.filterByWorkersConnectedFinalReceivers"
          class="mx-2 my-1"
        >
          Filter By Action's Worker Sent To FinalReceivers
        </b-form-checkbox>
        <b-button
          @click="prepareData()"
          :disabled="requestingLocal || requesting"
          variant="primary"
        >
          <b-spinner small v-if="requestingLocal || requesting"></b-spinner>
          <span v-else>🔄 Refresh List</span>
        </b-button>
        <b-form-group label="M status" v-slot="{ ariaDescribedby1 }" class="mx-2 my-1">
          <b-form-radio-group
            id="radio-group-2-m"
            v-model="copyForm.m"
            :aria-describedby="ariaDescribedby1"
            name="radio-group-2-m"
          >
            <b-form-radio :value="true">M</b-form-radio>
            <b-form-radio :value="false">NOT M</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group label="FTD/Callback status" v-slot="{ ariaDescribedby2 }" class="mx-2 my-1">
          <b-form-radio-group
            id="radio-group-2-ftdCallbackStatus"
            v-model="copyForm.ftdCallbackStatus"
            :aria-describedby="ariaDescribedby2"
            name="radio-group-2-ftdCallbackStatus"
          >
            <b-form-radio value="ftd">FTD</b-form-radio>
            <b-form-radio value="callback">CALLBACK</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-checkbox v-model="showOnlyWhoHaveLeads" class="mx-2 my-1">
          Show Only Clients who have received Leads
        </b-form-checkbox>
        <b-form-checkbox
          v-model="considerOnlyWhoCanAcceptNow"
          v-if="iAmTrustedUser"
          class="mx-2 my-1"
        >
          Consider Only Who Can Accept Now
        </b-form-checkbox>
        <b-form-checkbox v-model="useLinkCheckService" v-if="iAmTrustedUser" class="mx-2 my-1">
          Show Only Using New Linkcheck
        </b-form-checkbox>
      </b-col>
      <b-col cols="7" sm="12" md="12" lg="7" xl="7" v-if="simpleView">
        <SendContextWorkerInfo :lead_id="_id" />
      </b-col>
    </b-row>
    <b-dd-text variant="danger" v-if="errorGettingAllowedUrls">
      Error Getting MetaCampaigns Allowed To Send
    </b-dd-text>
    <p class="dataspan forestgreen mx-2 my-2" v-if="sentSuccess">{{ successSentMessage }}</p>
    <p class="dataspan tomatotext mx-2 my-2" v-if="sentError">{{ errorSentMessage }}</p>
    <!--    <b-button variant="primary" v-if="sentSuccess || sentError" class="mx-2 my-2">Close</b-button>-->
    <div v-if="!sentSuccess">
      <h5>
        NOTE: If you don't see some Client here, it means that this Lead is DUPLICATE for this
        Client
      </h5>
      <b-list-group v-for="campaign in campaignOptions" :key="campaign._id">
        <b-list-group-item
          v-for="metaCampaign in campaign.metaCampaignOptions"
          :key="metaCampaign._id"
        >
          <b-form inline>
            <b-button
              class="mx-2 my-1"
              variant="primary"
              size="sm"
              @click="sendCopyRequest(campaign, metaCampaign)"
              :disabled="requesting"
            >
              <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
              Send
            </b-button>
            <span class="ml-2 mr-1 my-1 dataspan">
              Sending as:
              <b-badge :variant="copyForm.m ? 'warning' : 'success'">
                {{ copyForm.m ? 'M' : 'NON M' }}
              </b-badge>
              <b-badge :variant="copyForm.ftdCallbackStatus === 'ftd' ? 'warning' : 'success'">
                {{ copyForm.ftdCallbackStatus === 'ftd' ? 'FTD' : 'CALLBACK' }}
              </b-badge>
            </span>
            <b-form-checkbox
              v-model="copyForm.bypassFRCheck"
              v-if="iAmTrustedUser"
              class="ml-1 mr-2 my-1"
            >
              Bypass FR Check
            </b-form-checkbox>
            <span class="dataspan ml-2 mr-1 my-1">Copy {{ campaign.name }}</span>
            <span class="dataspan ml-1 mr-2 my-1">Client {{ metaCampaign.name }}</span>
            <b-button
              small
              v-b-modal="`sendmanual-finalreceivers-modal-${metaCampaign._id}`"
              variant="success"
              display="inline"
              class="mx-1"
            >
              FinalReceivers
            </b-button>
            <b-modal
              :id="`sendmanual-finalreceivers-modal-${metaCampaign._id}`"
              centered
              ok-only
              ok-title="Close"
              size="m"
            >
              <SendManualNodeFinalReceiversList
                :client="metaCampaign"
              ></SendManualNodeFinalReceiversList>
            </b-modal>
            <!--            <span class="dataspan orangetext mx-2 my-1">R/M ratio: {{ metaCampaign.RMRatio }}</span>-->
            <span class="dataspan bluetext ml-2 mr-1 my-1">
              Injected {{ lead.country }}: {{ metaCampaign.rCount }}
            </span>
            <LoadingSpinner
              class="m-1"
              :requesting="requesting_shaved"
              v-if="requesting_shaved"
              small
            />
            <span class="dataspan forestgreen ml-2 mr-1 my-1">
              Callbacks {{ lead.country }}: {{ requesting_shaved ? '' : metaCampaign.callback_geo }}
            </span>
            <span class="dataspan orangetext ml-1 mr-2 my-1">
              FTDs {{ lead.country }}: {{ requesting_shaved ? '' : metaCampaign.toftd_geo }}
            </span>
            <span class="dataspan forestgreen ml-2 mr-1 my-1" v-if="simpleView">
              Callbacks All Geos: {{ requesting_shaved ? '' : metaCampaign.callback_total }}
            </span>
            <span class="dataspan orangetext ml-1 mr-2 my-1" v-if="simpleView">
              FTDs All Geos: {{ requesting_shaved ? '' : metaCampaign.toftd_total }}
            </span>
            <span class="dataspan redtext ml-1 mr-2 my-1 d-flex" v-if="simpleView">
              Pending/Shaved {{ lead.country }}:
              {{ requesting_shaved ? '' : metaCampaign.pending_geo }}
            </span>
            <span class="dataspan redtext ml-1 mr-2 my-1 d-flex" v-if="simpleView">
              Pending/Shaved All Geos: {{ requesting_shaved ? '' : metaCampaign.pending_total }}
            </span>
            <span class="dataspan greentext ml-1 mr-2 my-1 d-flex">
              FTDs Received {{ lead.country }}: {{ requesting_shaved ? '' : metaCampaign.ftds_geo }}
            </span>
            <span class="dataspan greentext ml-1 mr-2 my-1 d-flex">
              FTDs Received All Geos: {{ requesting_shaved ? '' : metaCampaign.ftds_total }}
            </span>
          </b-form>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import { formValid, isValid } from '@/validators';
import _ from 'lodash';
import {
  copyLead,
  getCampaignUrlsNotConnectedByActionId,
  getMetaCampaignsShaved,
} from '@/utils/api';
import { handleRequestError, showCard, assembleMetaCampaignOptions } from '@/utils';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import SendManualNodeFinalReceiversList from '@/components/pages/Actions/ActionsTable/components/SendManualNodeFinalReceiversList.vue';
import SendContextWorkerInfo from '@/components/pages/Actions/ActionsTable/components/SendContextWorkerInfo.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

export default {
  name: 'SendLeadManualMenu',
  components: { SendContextWorkerInfo, SendManualNodeFinalReceiversList, LoadingSpinner },
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    _id: { type: String, default: '' },
    box: { type: String, default: '' },
    createdAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      backdoor: 0,
      copyForm: {
        count: 1,
        m: true,
        bypassFRCheck: false,
        lead: this.lead,
        box: this.box,
        ftdCallbackStatus: 'callback',
      },
      errorGettingAllowedUrls: false,
      notConnectedCampaignUrlsByActionId: [],
      sentSuccess: false,
      sentError: false,
      successSentMessage: '',
      errorSentMessage: '',
      showOnlyWhoHaveLeads: false,
      considerOnlyWhoCanAcceptNow: true,
      useLinkCheckService: true,
      urlsRequestParams: {
        useTTL: false,
        usePartialPhoneFilter: true,
        filterByWorkersConnectedFinalReceivers: true,
      },
      requestingLocal: false,
      metaCampaignsFtdsAndShaved: [],
      requesting_shaved: false,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'boxes', 'campaigns', 'affiliates', 'requesting']),
    simpleView() {
      return this.user.email !== 'zerothreethree@default.gg';
    },
    iAmTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    isValid() {
      const fields = isValid('copylead', this.copyForm, this.copyForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    campaignOptions() {
      this.backdoor;
      console.log('recalculating campaignOptions');
      return this.campaigns
        .filter(
          (c) =>
            c.active &&
            (!this.copyForm.m || c.sendM) &&
            c.countries &&
            c.countries.includes(this.lead.country) &&
            (!this.copyForm.useLinkCheckService ||
              (this.lead &&
                c.metaCampaigns &&
                c.metaCampaigns.some(
                  (mc) =>
                    mc.useLinkCheckService &&
                    mc.useLinkCheckService.some((country) => country === this.lead.country)
                )))
          // Logic caveat here because Campaign model does not have useLinkCheckService flag: therefore non-Daughter cannot be filtered as useLinkCheckService
        )
        .map((c) => {
          const { metaCampaigns } = c;
          if (!metaCampaigns) return [];
          // console.log('metaCampaigns', metaCampaigns);
          // console.log(
          //   'notConnectedCampaignUrlsByActionId',
          //   this.notConnectedCampaignUrlsByActionId
          // );
          function getRCount(mc, country) {
            if (country === 'all') {
              return mc.rByCountry
                ? Object.keys(mc.rByCountry).reduce((sum, key) => sum + mc.rByCountry[key], 0)
                : 0;
            }
            return mc.rByCountry && mc.rByCountry[country] ? mc.rByCountry[country] : 0;
          }
          function getMCount(mc, country) {
            if (country === 'all') {
              return mc.mByCountry
                ? Object.keys(mc.mByCountry).reduce((sum, key) => sum + mc.mByCountry[key], 0)
                : 0;
            }
            return mc.mByCountry && mc.mByCountry[country] ? mc.mByCountry[country] : 0;
          }
          const metaCampaignOptions = assembleMetaCampaignOptions(
            this,
            c,
            false,
            this.considerOnlyWhoCanAcceptNow,
            this.useLinkCheckService
          )
            .map((metacampaign) => {
              const mc = _.cloneDeep(metacampaign);
              mc.rCount = getRCount(mc, this.lead.country);
              mc.mCount = getMCount(mc, this.lead.country);
              mc.mCountTotal = getMCount(mc, 'all');
              mc.RMRatio = Number((mc.rCount / Math.max(mc.mCount, 1)).toFixed(2));
              const metadata = this.metaCampaignsFtdsAndShaved.find(
                (node) => node._id.campaign_id === c._id && node._id.mc_id === mc._id
              );

              mc.callback_geo = metadata ? metadata.callback_geo : 'N/A';
              mc.callback_total = metadata ? metadata.callback_total : 'N/A';
              mc.toftd_geo = metadata ? metadata.toftd_geo : 'N/A';
              mc.toftd_total = metadata ? metadata.toftd_total : 'N/A';
              // mc.pending_total = metadata ? metadata.pending_total : 'N/A';
              // mc.pending_total = metadata ? metadata.pending_total : 'N/A';

              mc.pending_total = metadata ? metadata.pending_total : 'N/A';
              mc.pending_geo = metadata ? metadata.pending_geo : 'N/A';
              mc.ftds_total = metadata ? metadata.ftds_total : 'N/A';
              mc.ftds_geo = metadata ? metadata.ftds_geo : 'N/A';
              return mc;
            })
            .filter((mc) => !this.showOnlyWhoHaveLeads || mc.rCount > 0);
          // console.log('filter result', { name: c.name, _id: c._id, metaCampaignOptions });
          return { name: c.name, _id: c._id, metaCampaignOptions };
        });
    },
    // box() {
    //   // const box = this.user.boxes.length > 0 ? this.user.boxes[0] : undefined;
    //   // return box ? (box._id ? box._id : box) : undefined;
    //   // thi
    // },
    // metaCampaignOptions() {
    //   this.backdoor;
    //   if (!this.copyForm.campaign) return [];
    //   if (this.errorGettingAllowedUrls) return [];
    //   const campaign = this.campaigns.find(
    //     (c) => c._id === this.copyForm.campaign || c._id === this.copyForm.campaign._id
    //   );
    //   return assembleMetaCampaignOptions(this, campaign,false);
    // },
  },
  methods: {
    async prepareData() {
      this.requestingLocal = true;
      await this.$store.dispatch('get_campaigns', { vm: this });
      this.getCampaignUrlsNotConnectedByActionId();
      this.getMetaCampaignsShaved();
    },
    getMetaCampaignsShaved() {
      this.requesting_shaved = true;
      getMetaCampaignsShaved(this.token, { geo: this.lead.country })
        .then((r) => {
          this.metaCampaignsFtdsAndShaved = Array.isArray(r.data) ? r.data : [];
          this.requesting_shaved = false;
          this.backdoor += 1;
        })
        .catch((e) => {
          this.metaCampaignsFtdsAndShaved = [];
          this.requesting_shaved = false;
          handleRequestError(this, e, 'Error getting MetaCampaigns FTDs and Shaved metadata');
          this.backdoor += 1;
        });
    },
    getCampaignUrlsNotConnectedByActionId() {
      this.notConnectedCampaignUrlsByActionId = [];
      if (!this.copyForm.bypassFRCheck) {
        getCampaignUrlsNotConnectedByActionId(this.token, {
          _id: this._id,
          ...this.urlsRequestParams,
        })
          .then((r) => {
            this.errorGettingAllowedUrls = false;
            this.notConnectedCampaignUrlsByActionId = r.data.urls;
            this.backdoor += 1;
            this.requestingLocal = false;
          })
          .catch((e) => {
            this.errorGettingAllowedUrls = true;
            handleRequestError(this, e, 'Server error on getting campaign urls connected to given');
            this.backdoor += 1;
            this.requestingLocal = false;
          });
      } else {
        this.requestingLocal = false;
      }
    },
    getWorker() {},
    findBoxName(boxId) {
      const box = this.boxes.find((b) => b._id === boxId);
      return box && box.name ? box.name : boxId;
    },
    sendCopyRequest(campaign, metaCampaign) {
      if (this.isValid.form) {
        const toSave = _.cloneDeep(this.copyForm);
        toSave.box = this.box;
        if (!toSave.lead || !toSave.box || toSave.box === '') {
          console.log('toSave dont have lead or box', toSave);
          return;
        }
        if (!campaign || !campaign._id || !metaCampaign || !metaCampaign._id) {
          console.log('toSave dont have campaign or metaCampaign', campaign, metaCampaign);
          return;
        }
        toSave.campaign = campaign._id;
        toSave.metaCampaign = metaCampaign._id;
        this.$store.commit('make_requesting', { req: true });
        toSave._id = this._id;
        if (!toSave.lead.regDate) toSave.lead.regDate = this.createdAt;
        if (!toSave.lead.regDate || toSave.lead.regDate === '') toSave.lead.regDate = new Date();
        console.log('toSave is requesting', toSave);
        copyLead(this.token, toSave)
          .then((r) => {
            if (r.data.successful > 0) {
              this.successSentMessage = `${r.data.email} sent to Copy ${campaign.name}, Client ${metaCampaign.name}. You can close this popup.`;
              this.sentSuccess = true;
              showCard(this, this.successSentMessage, 'success');
              this.prepareData();
            } else {
              this.sentError = true;
              this.errorSentMessage = `Error on sending lead ${this.lead.email} to to Copy ${
                campaign.name
              }, Client ${
                metaCampaign.name
              }. Please try again for another copy or contact the administrtator
              ${r.data.message ? `\nError Message: ${r.data.message}` : ''}`;
            }
          })
          .catch((e) => {
            this.sentError = true;
            this.errorSentMessage = `Error on sending lead ${this.lead.email} to to Copy ${
              campaign.name
            }, Client ${
              metaCampaign.name
            }. Please try again for another copy or contact the administrtator
            \n${e.message ? `Error Message: ${e.message}` : ''}
            \n${e.response ? `Error Data: ${e.response.data}` : ''}`;
            // handleRequestError(this, e, this.errorSentMessage);
          })
          .finally(() => {
            // this.getCampaignUrlsNotConnectedByActionId();
            this.$store.commit('make_requesting', { req: false });
            this.$emit('copied');
          });
      }
    },
  },
  created() {
    console.log(`SendLeadManualMenu for action ${this._id} created`);
    this.prepareData();
  },
  mounted() {
    console.log(`SendLeadManualMenu for action ${this._id} mounted`);
  },
  destroyed() {
    console.log(`SendLeadManualMenu for action ${this._id} destroyed`);
  },
  watch: {
    iAmTrustedUser() {
      if (!this.iAmTrustedUser) this.useLinkCheckService = false;
    },
  },
};
</script>

<style scoped></style>
