<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h5 class="mx-1 my-2">{{ name }}</h5>
    <b-form-checkbox
      class="my-2"
      v-model="form.use"
      @input="emitEditEvent"
      :state="isValid.fields.use"
    >
      Use Pagination
    </b-form-checkbox>
    <div v-if="form.use">
      <b-form-group
        class="my-3"
        label="Type"
        label-for="paginationparameters-type"
        :state="isValid.fields.type1"
      >
        <!--      :invalid-feedback="invalidFeedback.campaign.name"
     -->
        <b-form-select
          id="paginationparameters-type"
          v-model="form.type1"
          :options="typeOptions"
          @input="emitEditEvent"
          :state="isValid.fields.type1"
        ></b-form-select>
      </b-form-group>
      <div v-if="form.type1 === 'page'">
        <b-form-group
          class="my-3"
          label="'pageNumber' Field"
          label-for="paginationparameters-pagenumberfield"
          :state="isValid.fields.pageNumberField"
        >
          <!--      :invalid-feedback="invalidFeedback.campaign.name"
     -->
          <b-form-input
            type="text"
            id="paginationparameters-pagenumberfield"
            v-model="form.pageNumberField"
            @input="emitEditEvent"
            :state="isValid.fields.pageNumberField"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          class="my-3"
          label="'itemsPerPage' Field"
          label-for="paginationparameters-itemsperpagefield"
          :state="isValid.fields.itemsPerPageField"
        >
          <!--      :invalid-feedback="invalidFeedback.campaign.name"
     -->
          <b-form-input
            type="text"
            id="paginationparameters-itemsperpagefield"
            v-model="form.itemsPerPageField"
            @input="emitEditEvent"
            :state="isValid.fields.itemsPerPageField"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="my-3"
          label="Items Per Page"
          label-for="paginationparameters-itemsperpage"
          :state="isValid.fields.itemsPerPage"
        >
          <!--      :invalid-feedback="invalidFeedback.campaign.name"
        -->
          <b-form-input
            type="number"
            id="paginationparameters-itemsperpage"
            v-model="form.itemsPerPage"
            @input="emitEditEvent"
            :state="isValid.fields.itemsPerPage"
          ></b-form-input>
        </b-form-group>
      </div>
      <div v-if="form.type1 === 'offset'">
        <b-form-group
          class="my-3"
          label="'offset' Field"
          label-for="paginationparameters-offsetfield"
          :state="isValid.fields.offsetField"
        >
          <!--      :invalid-feedback="invalidFeedback.campaign.name"
      -->
          <b-form-input
            type="text"
            id="paginationparameters-offsetfield"
            v-model="form.offsetField"
            @input="emitEditEvent"
            :state="isValid.fields.offsetField"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          class="my-3"
          label="'offsetStep' Field"
          label-for="paginationparameters-offsetstepfield"
          :state="isValid.fields.offsetStepField"
        >
          <!--      :invalid-feedback="invalidFeedback.campaign.name"
          -->
          <b-form-input
            type="text"
            id="paginationparameters-offsetstepfield"
            v-model="form.offsetStepField"
            @input="emitEditEvent"
            :state="isValid.fields.offsetStepField"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="my-3"
          label="Offset Step"
          label-for="paginationparameters-offsetstep"
          :state="isValid.fields.offsetStepField"
        >
          <!--      :invalid-feedback="invalidFeedback.campaign.name"
          -->
          <b-form-input
            type="number"
            id="paginationparameters-offsetstep"
            v-model="form.offsetStep"
            @input="emitEditEvent"
            :state="isValid.fields.offsetStepField"
          ></b-form-input>
        </b-form-group>
      </div>

      <b-form-group
        class="my-3"
        label="'totalItems' Field"
        label-for="paginationparameters-totalitemsfield"
        :state="isValid.fields.totalItemsField"
      >
        <!--      :invalid-feedback="invalidFeedback.campaign.name"
     -->
        <b-form-input
          type="text"
          id="paginationparameters-totalitemsfield"
          v-model="form.totalItemsField"
          @input="emitEditEvent"
          :state="isValid.fields.totalItemsField"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        class="my-3"
        label="'totalPages' Field"
        label-for="paginationparameters-totalpagesfield"
        :state="isValid.fields.totalPagesField"
      >
        <!--      :invalid-feedback="invalidFeedback.campaign.name"
     -->
        <b-form-input
          type="text"
          id="paginationparameters-totalpagesfield"
          v-model="form.totalPagesField"
          @input="emitEditEvent"
          :state="isValid.fields.totalPagesField"
        ></b-form-input>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
// import handleValidateEvent from '@/utils/handleValidateEvent';
import { formValid, isValid } from '@/validators';

const initial = {
  use: false,
  type1: '',
  pageNumberField: '',
  itemsPerPageField: '',
  itemsPerPage: 100,
  offsetField: '',
  offsetStep: 100,
  offsetStepField: '',
  totalItemsField: '',
  totalPagesField: '',
};
export default {
  name: 'PullingPaginationParameters',
  props: {
    name: { type: String, default: 'Pagination Settings' },
    Pagination: {
      use: { type: Boolean, default: false },
      type1: { type: String, default: '' },
      pageNumberField: { type: String, default: '' },
      itemsPerPageField: { type: String, default: '' },
      itemsPerPage: { type: Number, default: 100 },
      offsetField: { type: String, default: '' },
      offsetStep: { type: Number, default: 100 },
      offsetStepField: { type: String, default: '' },
      totalItemsField: { type: String, default: '' },
      totalPagesField: { type: String, default: '' },
    },
  },
  data() {
    return {
      form: cloneDeep(this.$props.Pagination ? this.$props.Pagination : initial),
      typeOptions: ['page', 'offset'],
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = isValid('integration_PullingPaginationParameters', this.form, this.form);
      const form = formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    // handleEditEvent,
    // handleMultipleKeysEditEvent,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    // handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
