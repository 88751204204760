<template>
  <b-card no-body class="h-100 mb-0 border-0" :class="[colorClass]">
    <!--  <div class="card h-100 mb-0">-->
    <b-card-header class="db-header border-0 mb-0" :class="[colorClass, textClass]">
      <h5 class="mx-auto mx-md-0 mx-lg-auto mx-xl-0 mb-0" :class="[textClass]">
        {{ header }}
      </h5>
      <div class="d-flex">
        <LoadingSpinner
          v-if="requesting"
          :requesting="requesting"
          small
          class="mx-2 mb-0"
          :show-text="false"
          variant="light"
        />
        <b-form-radio-group
          v-if="allowSelectBy"
          id="btn-radios-select-by-1"
          size="sm"
          :button-variant="buttonVariant"
          v-model="selectBy"
          :options="selectByOptions"
          name="btn-radios-period-1"
          buttons
          class="mx-auto mx-md-0 mx-lg-auto mx-xl-0 my-1 mb-0"
        ></b-form-radio-group>
      </div>
    </b-card-header>
    <!--    <div class="card-body">-->
    <b-card-body class="p-0">
      <apexchart type="treemap" :options="chartOptions" :series="series" class="_chart" />
    </b-card-body>
    <!--    </div>-->
    <!--  </div>-->
  </b-card>
</template>

<script>
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import VueApexCharts from 'vue-apexcharts';
import { mapState } from 'vuex';
import { getTreemapTotalByEntityChartData } from '@/utils/api';
import { dataUpdateTimeoutCondition, handleRequestError } from '@/utils';
import { startCase } from '@/utils/string';
import { datepickerFormat, selectByOptions } from '@/consts';
import { colorVariants } from '@/consts/theme/colors';
import { DateTime } from 'luxon';

export default {
  name: 'TreemapTotalByEntityChart',
  components: {
    LoadingSpinner,
    apexchart: VueApexCharts,
  },
  props: {
    period: { type: String, default: 'week' },
    entity: { type: String, default: 'FTD' },
    // colorClass: { type: String, default: 'bg-gradient-primary' },
    variant: { type: String, default: 'transparent' },
    chartVariant: { type: String, default: undefined },
    text: { type: String, default: 'default' },
    buttonVariant: { type: String, default: 'outline-primary' },
    gradient: { type: Boolean, default: false },
    // selectBy: { type: String, default: 'country' },
    allowSelectBy: { type: Boolean, default: true },
    customDate: {
      type: Object,
      default: () => {
        return {
          from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
          to: DateTime.utc().toISO(),
        };
      },
    },
  },
  data() {
    return {
      selectBy: 'geo',
      selectByOptions,
      timeout: undefined,
      controller: new AbortController(),
      chartOptions: {
        chart: {
          parentHeightOffset: 12,
          height: 350,
          width: '100%',
          type: 'treemap',
          // zoom: {
          //   enabled: false,
          // },
          toolbar: { show: false },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
          },
          // eslint-disable-next-line object-shorthand
          formatter: (text, op) => {
            // return [text, op.value];
            return this.total > 0 && op.value / this.total > 0.05 ? `${text}: ${op.value}` : text;
          },
          // offsetY: -4,
        },
        // tooltip: { shared: false, x: { show: true, formatter: (x) => `${x}` } },
        legend: {
          show: false,
        },
        plotOptions: {
          treemap: {
            enableShades: true,
            shadeIntensity: 0.5,
            reverseNegativeShade: true,
            colorScale: {
              ranges: [
                { from: -10000000, to: -0.01, color: '#dc3545' },
                {
                  from: 0,
                  to: this.total,
                  color: colorVariants[this.chartVariant ? this.chartVariant : this.variant]
                    ? colorVariants[this.chartVariant ? this.chartVariant : this.variant]
                    : colorVariants.default,
                  // this.entity.toLowerCase() === 'ftd' ? 'success' : 'primary', // '#3a8e0a' : '#7B68EE',
                },
              ],
            },
          },
        },
      },
      // series: [{ data: [{ x: 'KEK', y: 1 }] }],
      series: [],
      total: 0,
      requesting: false,
      updateTimeout: 120000,
    };
  },
  computed: {
    ...mapState(['token']),
    colorClass() {
      return colorVariants[this.variant]
        ? `bg${this.gradient ? '-gradient' : ''}-${this.variant}`
        : '';
    },
    textClass() {
      return `text-${this.text}`;
    },
    header() {
      const { entity, period, selectBy } = this;
      return startCase(`${entity}s
        ${
          // period === 'year'
          //   ? 'This Year'
          //   : period === 'month'
          //   ? 'This Month'
          //   : period === 'week'
          //   ? 'This Week'
          //   : period === 'day'
          //   ? 'Today'
          //   :
          period === 'custom'
            ? `${DateTime.fromFormat(this.from, datepickerFormat).toFormat(
                'yyyy-LL-dd'
              )} - ${DateTime.fromFormat(this.to, datepickerFormat).toFormat('yyyy-LL-dd')}`
            : startCase(period) // 'This Week'
        }
        by ${selectBy}`);
    },
  },
  methods: {
    getTreemapTotalByEntityChartData() {
      clearTimeout(this.timeout);
      this.requesting = true;
      getTreemapTotalByEntityChartData(
        this.token,
        {
          period: this.period,
          type: this.entity,
          selectBy: this.selectBy,
          from: this.period === 'custom' ? this.customDate.from : undefined,
          to: this.period === 'custom' ? this.customDate.to : undefined,
        },
        this.controller.signal
      )
        .then((r) => {
          this.total = r.data.total;
          // this.chartOptions = {
          //   ...this.chartOptions,
          //   plotOptions: {
          //     ...this.chartOptions.plotOptions,
          //     treemap: {
          //       ...this.chartOptions.plotOptions.treemap,
          //       colorScale: {
          //         ranges: [
          //           { from: -10000000, to: -0.01, color: '#dc3545' },
          //           {
          //             from: 0,
          //             to: r.data.max,
          //             color: this.entity.toLowerCase() === 'ftd' ? '#3a8e0a' : '#7B68EE',
          //           },
          //         ],
          //       },
          //     },
          //   },
          // };
          this.chartOptions.plotOptions.treemap.colorScale.ranges[1].to = r.data.max;
          this.series = r.data.series;
          // this.updateChartOptions(r.data.categories, this.series);
          this.requesting = false;
          if (dataUpdateTimeoutCondition(this.period, this.customDate.to)) {
            this.timeout = setTimeout(
              () => this.getTreemapTotalByEntityChartData(),
              this.updateTimeout
            );
          }
        })
        .catch((e) => {
          handleRequestError(this, e, `Error getting FTDs by Month chart`);
          this.requesting = false;
          if (dataUpdateTimeoutCondition(this.period, this.customDate.to)) {
            this.timeout = setTimeout(
              () => this.getTreemapTotalByEntityChartData(),
              this.updateTimeout
            );
          }
        });
    },
  },
  mounted() {
    this.getTreemapTotalByEntityChartData();
  },
  watch: {
    period() {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getTreemapTotalByEntityChartData();
    },
    selectBy() {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getTreemapTotalByEntityChartData();
    },
    'customDate.from': function () {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getTreemapTotalByEntityChartData();
    },
    'customDate.to': function () {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getTreemapTotalByEntityChartData();
    },
  },
};
</script>

<style scoped>
.db-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
._chart {
  margin-top: -16px;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
