<template>
  <b-container fluid>
    <b-row class="my-3" align-v="center">
      <b-col cols="12" lg="2" class="my-lg-0 my-1"><h2>Injections</h2></b-col>
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="4"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="3" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <!--        <div class="d-flex align-items-center justify-content-end">-->
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="3" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
        <b-button to="/create-injection" variant="primary" class="mx-2">Create</b-button>
        <b-button
          @click="restartInjection()"
          variant="warning"
          v-if="iAmOtherLevelTrustedUser"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>Restart</span>
        </b-button>
        <b-button v-b-toggle.sidebar-right-table @click="closeSidebarDetails()" class="mx-2">
          <!--                    class="mb-4 h5 font-weight-normal mt-5 mx-2"
-->
          Edit Table
        </b-button>
        <!--        <b-button-->
        <!--            v-b-toggle.sidebar-right-details-->
        <!--            @click="closeSidebarColumns()"-->
        <!--            class="mb-4 h5 font-weight-normal mt-5 mx-2"-->
        <!--        >-->
        <!--          Edit Details-->
        <!--        </b-button>-->
        <b-sidebar
          id="sidebar-right-table"
          v-model="sidebarColumnsOpen"
          title="Table Fields"
          right
          shadow
        >
          <div class="px-3 py-2">
            <b-form-checkbox-group
              v-model="preSelectedColumns"
              :options="tableFieldsOptions"
              class="mb-3"
              @input="logSelectedColumns()"
              stacked
            ></b-form-checkbox-group>
            <b-button
              variant="primary"
              @click="applyFields()"
              :disabled="!canApplyFields"
              class="my-2 sidebar-button"
            >
              Apply
            </b-button>
            <b-button
              variant="success"
              @click="saveSelectedFields('injections_table')"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Save
            </b-button>
            <b-button
              variant="warning"
              @click="restoreTablePreference()"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Restore
            </b-button>
          </div>
        </b-sidebar>
        <!--        <b-sidebar-->
        <!--            id="sidebar-right-details"-->
        <!--            v-model="sidebarDetailsOpen"-->
        <!--            title="Show Details"-->
        <!--            right-->
        <!--            shadow-->
        <!--        >-->
        <!--          <div class="px-3 py-2">-->
        <!--            <b-form-checkbox-group-->
        <!--                v-model="preSelectedDetails"-->
        <!--                :options="detailsOptions"-->
        <!--                class="mb-3"-->
        <!--                @input="logSelectedDetails()"-->
        <!--                stacked-->
        <!--            ></b-form-checkbox-group>-->
        <!--            <b-button-->
        <!--                variant="primary"-->
        <!--                @click="saveSelectedFields('actions_details')"-->
        <!--                :disabled="requesting"-->
        <!--                class="my-2"-->
        <!--            >-->
        <!--              <b-spinner small v-if="requesting"></b-spinner>-->
        <!--              Save Preferences-->
        <!--            </b-button>-->
        <!--            <b-button-->
        <!--                variant="warning"-->
        <!--                @click="restoreDetailsPreference()"-->
        <!--                :disabled="requesting"-->
        <!--                class="my-2"-->
        <!--            >-->
        <!--              <b-spinner small v-if="requesting"></b-spinner>-->
        <!--              Restore Preferences-->
        <!--            </b-button>-->
        <!--          </div>-->
        <!--        </b-sidebar>-->
        <!--        </div>-->
      </b-col>
    </b-row>
    <b-table
      class="mb-3"
      sort-by="active"
      :sort-desc="true"
      style="white-space: nowrap"
      :fields="sortedSelectedFields"
      :items="realitems"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      :busy="requesting"
      @filtered="onFiltered"
      responsive
      show-empty
      striped
      hover
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(_id)="data">
        <span v-b-popover.hover="data.unformatted">{{ data.value }}</span>
      </template>
      <template #cell(active)="data">
        <h5>
          <b-badge
            class="mx-1"
            :variant="data.value === true ? 'success' : 'danger'"
            v-b-popover.hover="
              data.item.activeHours ? data.item.activeHours.join(', ') : 'no active hours'
            "
            title="Active Hours"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
          <b-badge
            class="mx-1"
            :variant="
              data.item.canSendActions
                ? data.item.canSendActions.result === true
                  ? 'success'
                  : 'danger'
                : 'secondary'
            "
            v-b-popover.hover="data.item.canSendActions ? data.item.canSendActions.reason : ''"
            :title="
              data.item.canSendActions
                ? data.item.canSendActions.result
                  ? 'Injection is sending leads now'
                  : 'Reason'
                : ''
            "
          >
            {{
              data.item.canSendActions
                ? data.item.canSendActions.result === true
                  ? 'Sending'
                  : 'Idle'
                : 'Unknown'
            }}
          </b-badge>
        </h5>
      </template>
      <!--      -->
      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button
            variant="success"
            v-if="data.item.active && !data.item.play"
            :disabled="requesting || requestingItemsIncludeThis(data.item._id)"
            @click="playInjection(data.item._id, true)"
          >
            <b-icon icon="play-fill" v-if="!requestingItemsIncludeThis(data.item._id)"></b-icon>
            <b-spinner small v-else></b-spinner>
          </b-button>
          <b-button
            variant="warning"
            v-if="data.item.active && data.item.play"
            :disabled="requesting || requestingItemsIncludeThis(data.item._id)"
            @click="playInjection(data.item._id, false)"
          >
            <b-icon icon="pause-fill" v-if="!requestingItemsIncludeThis(data.item._id)"></b-icon>
            <b-spinner small v-else></b-spinner>
          </b-button>
          <b-button
            :variant="data.item.active ? 'danger' : 'secondary'"
            @click="killInjection(data.item._id)"
            v-b-popover.hover="
              data.item.active ? 'Are you sure about this? This cannot be undone.' : ''
            "
            :title="data.item.active ? 'Caution!' : ''"
            :disabled="requestingItemsIncludeThis(data.item._id)"
          >
            <!--            :disabled="!data.item.active"-->
            <b-icon icon="stop-fill" v-if="!requestingItemsIncludeThis(data.item._id)"></b-icon>
            <b-spinner small v-else></b-spinner>
            Kill
          </b-button>
        </b-button-group>
      </template>

      <template #cell(leadsToSendLength)="data">
        <h5>
          <b-badge :variant="data.item.leadsToSendLengthVariant">
            {{ data.value }}
          </b-badge>
        </h5>
      </template>

      <template #cell(leadsSent)="data">
        <h5>
          <b-badge
            :variant="data.value > 0 ? 'info' : 'secondary'"
            v-b-popover.hover="
              data.item.lastActionDate
                ? data.item.lastActionDate
                : 'No actions by this injection found'
            "
            title="Last Lead Sent Date"
          >
            {{ data.value }}
          </b-badge>
        </h5>
      </template>

      <template #cell(sentToday)="data">
        <b-button
          v-b-modal="`modal-sent-today-${data.item._id}`"
          variant="info"
          class="mx-1"
          size="sm"
        >
          <b>{{ data.value && data.value.total ? data.value.total : 0 }}</b>
        </b-button>
        <b-modal :id="`modal-sent-today-${data.item._id}`" centered size="xl" hide-footer>
          <sentTodayPopup
            :name="data.item.name"
            :_id="data.item._id"
            :countries="data.item.countries"
          ></sentTodayPopup>
        </b-modal>
      </template>

      <template #cell(paranoid)="data">
        <h5>
          <b-badge
            :class="!requestingItemsIncludeThis(data.item._id) ? 'badge-toggle' : 'disabled'"
            :variant="data.value === true ? 'info' : 'secondary'"
            @click="toggleParanoidInjection(data.item._id, !(data.value === true))"
          >
            <b-spinner
              small
              v-if="requestingItemsIncludeThis(data.item._id)"
              class="mr-1"
            ></b-spinner>
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
          <!--          v-if="!requestingItemsIncludeThis(data.item._id)"-->
          <!--          <b-spinner small v-else></b-spinner>-->
        </h5>
      </template>
      <template #cell(considerRMRatio)="data">
        <h5>
          <b-badge
            :class="!requestingItemsIncludeThis(data.item._id) ? 'badge-toggle' : 'disabled'"
            :variant="data.value === true ? 'info' : 'secondary'"
            @click="toggleConsiderRMRatioInjection(data.item._id, !(data.value === true))"
          >
            <b-spinner
              small
              v-if="requestingItemsIncludeThis(data.item._id)"
              class="mr-1"
            ></b-spinner>
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
          <!--          v-if="!requestingItemsIncludeThis(data.item._id)"-->
          <!--          <b-spinner small v-else></b-spinner>-->
        </h5>
      </template>
      <template #cell(considerWorkingAffiliates)="data">
        <h5>
          <b-badge
            :class="!requestingItemsIncludeThis(data.item._id) ? 'badge-toggle' : 'disabled'"
            :variant="data.value === true ? 'info' : 'secondary'"
            @click="toggleConsiderWorkingAffiliatesInjection(data.item._id, !(data.value === true))"
          >
            <b-spinner
              small
              v-if="requestingItemsIncludeThis(data.item._id)"
              class="mr-1"
            ></b-spinner>
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
          <!--          v-if="!requestingItemsIncludeThis(data.item._id)"-->
          <!--          <b-spinner small v-else></b-spinner>-->
        </h5>
      </template>
      <template #cell(useNewAlgorithm)="data">
        <h5>
          <b-badge
            :class="!requestingItemsIncludeThis(data.item._id) ? 'badge-toggle' : 'disabled'"
            :variant="data.value === true ? 'warning' : 'info'"
            @click="toggleUseNewAlgorithmInjection(data.item._id, !(data.value === true))"
          >
            <b-spinner
              small
              v-if="requestingItemsIncludeThis(data.item._id)"
              class="mr-1"
            ></b-spinner>

            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
          <!--          v-if="!requestingItemsIncludeThis(data.item._id)"-->
          <!--          <b-spinner small v-else></b-spinner>-->
        </h5>
      </template>
      <template #cell(automated)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'info' : 'secondary'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(sendAll)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'info' : 'secondary'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(preparing)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'warning' : 'info'">
            <b-spinner v-if="data.value" class="align-middle"></b-spinner>
            <span v-else>-</span>
          </b-badge>
        </h5>
      </template>

      <template #cell(m)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'info' : 'secondary'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(makeSalat)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'info' : 'secondary'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(countAllActions)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'info' : 'secondary'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(leadsSelectionDropouts)="data">
        <b-button
          v-b-modal="`modal-leads-selection-dropouts-${data.item._id}`"
          variant="info"
          class="mx-1"
          size="sm"
        >
          <b-icon icon="eye"></b-icon>
        </b-button>
        <b-modal
          :id="`modal-leads-selection-dropouts-${data.item._id}`"
          centered
          size="xl"
          hide-footer
        >
          <leadsSelectionDropoutsPopup
            :name="data.item.name"
            :_id="data.item._id"
            :countries="data.item.countries"
          ></leadsSelectionDropoutsPopup>
        </b-modal>
      </template>

      <template #cell(inspect)="data">
        <b-button
          v-b-modal="`modal-inspect-${data.item._id}`"
          variant="info"
          class="mx-1"
          size="sm"
        >
          <b-icon icon="eye"></b-icon>
        </b-button>
        <b-modal :id="`modal-inspect-${data.item._id}`" centered size="xl" hide-footer>
          <InjectionInspect :_id="data.item._id"></InjectionInspect>
        </b-modal>
      </template>
    </b-table>
    <b-row class="mb-3">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import '@/assets/breakdown-data.css';
import '@/assets/badge-toogle.css';
import { mapState } from 'vuex';
// import VueJsonPretty from 'vue-json-pretty';
// import 'vue-json-pretty/lib/styles.css';
// import { DateTime } from 'luxon';
import {
  // castCountriesFromServer,
  handleRequestError,
  showCard,
  // tableFormatterArrayValues,
  tableFormatterDateTime,
  tableFormatterId,
  tableFormatterValueToText,
} from '@/utils';
import {
  tableFieldsOptions,
  sortedSelectedFields,
  saveSelectedFieldsMethod,
  takeDefaultTableFields,
  takeDefaultDetailsOptions,
  applyFields,
  canApplyFields,
} from '@/utils/fieldsAndDetailsOptionsHelpers';
import store from '@/store';
import $ from 'jquery';
import { oidRegex } from '@/validators/_utils';
import {
  killInjection,
  restartInjection,
  playInjection,
  toggleParanoidInjection,
  toggleConsiderRMRatioInjection,
  toggleConsiderWorkingAffiliatesInjection,
  toggleUseNewAlgorithmInjection,
} from '@/utils/api';
import { OTHER_LEVEL_TRUSTED_USERS, statusGroupsOptions, TRUSTED_USERS } from '@/consts';
import { userAvailableFields } from '@/consts/userAvailableFields';
import leadsSelectionDropoutsPopup from './components/leadsSelectionDropoutsPopup.vue';
import sentTodayPopup from './components/sentTodayPopup.vue';
import InjectionInspect from './components/InjectionInspect.vue';

export default {
  name: 'InjectionsTable',
  components: {
    // VueJsonPretty,
    leadsSelectionDropoutsPopup,
    sentTodayPopup,
    InjectionInspect,
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      selectedColumns: [],
      preSelectedColumns: [],
      // selectedDetails: [],
      sidebarColumnsOpen: false,
      // sidebarDetailsOpen: false,
      filterIncludedFields: ['_id', 'name', 'boxTo', 'countries'],
      backdoor: 0,
      requestingItems: [],
    };
  },
  computed: {
    ...mapState([
      'user',
      'token',
      'injections',
      'requesting',
      'isAdmin',
      'isManager',
      'isMasterAffiliate',
    ]),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    canApplyFields() {
      return canApplyFields(this);
    },
    userAvailableFields() {
      console.time('userAvailableFields');
      let r;
      if (this.isAdmin) r = userAvailableFields.injections.admin();
      else if (this.isManager) r = userAvailableFields.injections.manager();
      else if (this.isMasterAffiliate) r = userAvailableFields.injections.masteraffiliate();
      else r = userAvailableFields.injections.affiliate();
      console.timeEnd('userAvailableFields');
      console.log(r);
      return r;
    },
    tableFieldsOptions() {
      return tableFieldsOptions(this);
    },
    sortedSelectedFields() {
      return sortedSelectedFields(this);
    },
    realitems() {
      this.backdoor;
      return this.injections.map((i) => {
        return {
          _id: i._id,
          name: i.name,
          active: i.active,
          canSendActions: i.canSendActions ? i.canSendActions : undefined,
          play: i.play,
          sendAll: i.sendAll ? i.sendAll : false,
          // preparing: i.preparing ? i.preparing : false,
          paranoid: i.paranoid ? i.paranoid : false,
          manager:
            i.manager && i.manager.name
              ? i.manager.name
              : i.manager._id
              ? tableFormatterId(i.manager._id)
              : i.manager,
          boxFrom: i.boxFrom.length,
          //     i.boxFrom.map((ibf) =>
          //   ibf.name ? ibf.name : ibf._id ? tableFormatterId(ibf._id) : ibf
          // ),
          boxAvoid: i.boxAvoid.length,
          //     i.boxAvoid.map((ibf) =>
          //   ibf.name ? ibf.name : ibf._id ? tableFormatterId(ibf._id) : ibf
          // ),
          campaignsFrom: i.campaignsFrom.length,
          //     i.campaignsFrom.map((ibf) =>
          //   ibf.name ? ibf.name : ibf._id ? tableFormatterId(ibf._id) : ibf
          // ),
          campaignsAvoid: i.campaignsAvoid.length,
          // i.campaignsAvoid.map((ibf) =>
          // ibf.name ? ibf.name : ibf._id ? tableFormatterId(ibf._id) : ibf
          // ),
          affiliatesFrom: i.affiliatesFrom.length,
          //     i.affiliatesFrom.map((ibf) =>
          //   ibf.name ? ibf.name : ibf._id ? tableFormatterId(ibf._id) : ibf
          // ),
          affiliatesAvoid: i.affiliatesAvoid.length,
          //     i.affiliatesAvoid.map((ibf) =>
          //   ibf.name ? ibf.name : ibf._id ? tableFormatterId(ibf._id) : ibf
          // ),
          assignToAffiliate: i.assignToAffiliate
            ? i.assignToAffiliate.name
              ? i.assignToAffiliate.name
              : tableFormatterId(i._id ? i._id : i)
            : '-',
          boxTo: i.boxTo.name
            ? i.boxTo.name
            : i.boxTo._id
            ? tableFormatterId(i.boxTo._id)
            : i.boxTo,
          m: i.m && i.m.m ? i.m.m : false,
          x: i.m && i.m.x ? i.m.x : '-',
          weight: i.m && i.m.x ? `${(1 / i.m.x) * 100}%` : '-',
          makeSalat: i.m && i.makeSalat ? i.makeSalat : false,
          countAllActions: i.m && i.countAllActions ? i.countAllActions : false,
          countries: i.countries, // castCountriesFromServer(i.countries),
          statusGroups: i.statusGroups
            ? i.statusGroups.map((sg) => tableFormatterValueToText(sg, statusGroupsOptions))
            : [],
          leadsToSendLength: i.leadsToSendLength ? i.leadsToSendLength : 0,
          leadsToSendLengthVariant:
            i.leadsToSendLength &&
            !Number.isNaN(Number(i.leadsToSendLength)) &&
            i.leadsToSendLength > 0
              ? !i.leadsSent ||
                Number.isNaN(Number(i.leadsSent)) ||
                i.leadsToSendLength > i.leadsSent
                ? 'info'
                : 'danger'
              : 'secondary',
          // i.leadsToSend && Array.isArray(i.leadsToSend) ? i.leadsToSend.length : 0,
          // i.leadsToSendChecked && Array.isArray(i.leadsToSendChecked)
          //   ? i.leadsToSendChecked.length
          //   : 0,
          amountLeadsToSend: i.amountLeadsToSend ? i.amountLeadsToSend : '-',
          orderToSelect: i.orderToSelect ? i.orderToSelect : '-',

          hourLimit: i.hourLimit,
          days: i.days.map((d, j) => (d ? j : false)).filter((d) => d !== false),

          leadsSent: i.leadsSent ? i.leadsSent : '?',
          lastActionDate: i.lastActionDate ? tableFormatterDateTime(i.lastActionDate) : undefined,
          createdDate: tableFormatterDateTime(i.createdDate),
          // i.createdDate && DateTime.fromISO(i.createdDate, { setZone: true }).isValid
          //   ? DateTime.fromISO(i.createdDate, { setZone: true }).toFormat('TT dd.LL.yyyy')
          //   : i.createdDate
          //   ? 'Invalid Date'
          //   : 'none',
          startDateTime: tableFormatterDateTime(i.startDateTime, true),
          // i.startDateTime && DateTime.fromISO(i.startDateTime, { setZone: true }).isValid
          //   ? DateTime.fromISO(i.startDateTime, { setZone: true }).toFormat('TT dd.LL.yyyy')
          //   : 'Invalid Date',
          endDateTime: tableFormatterDateTime(i.endDateTime),
          //   i.endDateTime
          // ? DateTime.fromISO(i.endDateTime, { setZone: true }).isValid
          //   ? DateTime.fromISO(i.endDateTime, { setZone: true }).toFormat('TT dd.LL.yyyy')
          //   : 'Invalid Date'
          // : 'none',
          startSelectDateTime: tableFormatterDateTime(i.startSelectDateTime),

          endSelectDateTime: tableFormatterDateTime(i.endSelectDateTime),

          batchSize: i.batchSize,
          timeBetweenBatches:
            i.timeBetweenBatches / 60000 > 1
              ? `${Math.floor(i.timeBetweenBatches / 60000)} min`
              : `${Math.floor(i.timeBetweenBatches / 1000)} sec`,
          timeBetweenBatches1:
            i.timeBetweenBatches1 / 60000 > 1
              ? `${Math.floor(i.timeBetweenBatches1 / 60000)} min`
              : `${Math.floor(i.timeBetweenBatches1 / 1000)} sec`,
          timeBetweenBatches2:
            i.timeBetweenBatches2 / 60000 > 1
              ? `${Math.floor(i.timeBetweenBatches2 / 60000)} min`
              : `${Math.floor(i.timeBetweenBatches2 / 1000)} sec`,
          boxDemandThisHour: i.boxDemandThisHour ? i.boxDemandThisHour : 0,
          leftToSendThisHour: i.leftToSendThisHour ? i.leftToSendThisHour : 0,
          actionsSentThisHour: i.actionsSentThisHour ? i.actionsSentThisHour : 0,
          avgTimeBetweenBatches: i.avgTimeBetweenBatches
            ? i.avgTimeBetweenBatches / 60000 > 1
              ? `${Math.floor(i.avgTimeBetweenBatches / 60000)} min`
              : `${Math.floor(i.avgTimeBetweenBatches / 1000)} sec`
            : 'unknown',
          automated: i.automated ? i.automated : false,
          considerRMRatio: i.considerRMRatio ? i.considerRMRatio : false,
          considerWorkingAffiliates: i.considerWorkingAffiliates
            ? i.considerWorkingAffiliates
            : false,
          useNewAlgorithm: i.useNewAlgorithm ? i.useNewAlgorithm : false,
          so: i.so ? i.so : 'none',
          leadsSelectionDropouts: 'requesting', // i.leadsSelectionDropouts,
          workingAffiliates: i.workingAffiliates ? i.workingAffiliates : [],
          nextSendAt: i.nextSendAt,
          activeHours: i.activeHours ? i.activeHours : ['none'],
          sentToday: i.sentToday
            ? i.sentToday
            : {
                _id: null,
                total: 0,
                accepted: 0,
                not_accepted: 0,
                tech: 0,
                r: 0,
                m: 0,
                byBroker: [],
              },
        };
      });
    },
  },
  methods: {
    logSelectedColumns() {
      console.log(this.selectedColumns);
    },
    logSelectedDetails() {
      console.log(this.selectedDetails);
    },
    applyFields() {
      applyFields(this);
    },
    saveSelectedFields(type) {
      saveSelectedFieldsMethod(this, type);
    },
    takeDefaultTableFields() {
      takeDefaultTableFields(this, 'injectionsTableSelectedFields');
    },
    takeDefaultDetailsOptions() {
      takeDefaultDetailsOptions(this, 'injectionsTableSelectedDetails');
    },
    restoreTablePreference() {
      this.takeDefaultTableFields();
      this.saveSelectedFields('injections_table');
    },
    restoreDetailsPreference() {
      this.takeDefaultDetailsOptions();
      this.saveSelectedFields('injections_details');
    },
    closeSidebarColumns() {
      this.sidebarColumnsOpen = false;
    },
    closeSidebarDetails() {
      this.sidebarDetailsOpen = false;
    },
    requestingItemsIncludeThis(item) {
      const id = item._id ? item._id : item;
      return this.requestingItems.includes(id);
    },
    makeItemRequesting(item, requesting) {
      const id = item._id ? item._id : item;
      if (requesting) {
        this.requestingItems.push(id);
      } else {
        this.requestingItems = this.requestingItems.filter((i) => i !== id);
      }
    },
    playInjection(_id, play) {
      if (this.requestingItemsIncludeThis(_id)) return;
      if (oidRegex.test(_id)) {
        // this.$store.commit('make_requesting', { req: true });
        this.makeItemRequesting(_id, true);
        playInjection(this.token, _id, play)
          .then(async (r) => {
            if (r.status === 200 && r.data.success === true) {
              showCard(this, r.data.message, 'success');
              try {
                // await this.$store.dispatch('get_injections', { vm: this });
                this.updateInjection(r.data.injection);
                // eslint-disable-next-line no-empty
              } catch (e) {
                console.error('error getting back injections from play/pause request', e);
              }
            } else {
              handleRequestError(this, r.data, `Unknown error on play/pause injection ${_id}`);
            }
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on play/pause injection ${_id}`);
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          });
      }
    },
    toggleParanoidInjection(_id, paranoid) {
      if (this.requestingItemsIncludeThis(_id)) return;
      if (oidRegex.test(_id)) {
        // this.$store.commit('make_requesting', { req: true });
        this.makeItemRequesting(_id, true);
        toggleParanoidInjection(this.token, _id, paranoid)
          .then(async (r) => {
            this.makeItemRequesting(_id, false);
            if (r.status === 200 && r.data.success === true) {
              showCard(this, r.data.message, 'success');
              try {
                // await this.$store.dispatch('get_injections', { vm: this });
                this.updateInjection(r.data.injection);
                // eslint-disable-next-line no-empty
              } catch (e) {
                console.error('Error getting back injections from toggle paranoid request', e);
              }
            } else {
              handleRequestError(
                this,
                r.data,
                `Unknown error on toggling paranoid for injection ${_id}`
              );
            }
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          })
          .catch((e) => {
            handleRequestError(
              this,
              e,
              `Unknown server error on toggling paranoid for injection ${_id}`
            );
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          });
      }
    },
    toggleConsiderRMRatioInjection(_id, considerRMRatio) {
      if (this.requestingItemsIncludeThis(_id)) return;
      if (oidRegex.test(_id)) {
        // this.$store.commit('make_requesting', { req: true });
        this.makeItemRequesting(_id, true);
        toggleConsiderRMRatioInjection(this.token, _id, considerRMRatio)
          .then(async (r) => {
            if (r.status === 200 && r.data.success === true) {
              showCard(this, r.data.message, 'success');
              try {
                // await this.$store.dispatch('get_injections', { vm: this });
                this.updateInjection(r.data.injection);
                // eslint-disable-next-line no-empty
              } catch (e) {
                console.error(
                  'Error getting back injections from toggle considerRMRatio request',
                  e
                );
              }
            } else {
              handleRequestError(
                this,
                r.data,
                `Unknown error on toggling considerRMRatio for injection ${_id}`
              );
            }
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          })
          .catch((e) => {
            handleRequestError(
              this,
              e,
              `Unknown server error on toggling considerRMRatio for injection ${_id}`
            );
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          });
      }
    },
    toggleConsiderWorkingAffiliatesInjection(_id, considerWorkingAffiliates) {
      if (this.requestingItemsIncludeThis(_id)) return;
      if (oidRegex.test(_id)) {
        // this.$store.commit('make_requesting', { req: true });
        this.makeItemRequesting(_id, true);
        toggleConsiderWorkingAffiliatesInjection(this.token, _id, considerWorkingAffiliates)
          .then(async (r) => {
            if (r.status === 200 && r.data.success === true) {
              showCard(this, r.data.message, 'success');
              try {
                // await this.$store.dispatch('get_injections', { vm: this });
                this.updateInjection(r.data.injection);
                // eslint-disable-next-line no-empty
              } catch (e) {
                console.error(
                  'Error getting back injections from toggle considerWorkingAffiliates request',
                  e
                );
              }
            } else {
              handleRequestError(
                this,
                r.data,
                `Unknown error on toggling considerWorkingAffiliates for injection ${_id}`
              );
            }
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          })
          .catch((e) => {
            handleRequestError(
              this,
              e,
              `Unknown server error on toggling considerWorkingAffiliates for injection ${_id}`
            );
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          });
      }
    },
    toggleUseNewAlgorithmInjection(_id, useNewAlgorithm) {
      if (this.requestingItemsIncludeThis(_id)) return;
      if (oidRegex.test(_id)) {
        // this.$store.commit('make_requesting', { req: true });
        this.makeItemRequesting(_id, true);
        toggleUseNewAlgorithmInjection(this.token, _id, useNewAlgorithm)
          .then(async (r) => {
            if (r.status === 200 && r.data.success === true) {
              showCard(this, r.data.message, 'success');
              try {
                // await this.$store.dispatch('get_injections', { vm: this });
                this.updateInjection(r.data.injection);
                // eslint-disable-next-line no-empty
              } catch (e) {
                console.error(
                  'Error getting back injections from toggle useNewAlgorithm request',
                  e
                );
              }
            } else {
              handleRequestError(
                this,
                r.data,
                `Unknown error on toggling useNewAlgorithm for injection ${_id}`
              );
            }
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          })
          .catch((e) => {
            handleRequestError(
              this,
              e,
              `Unknown server error on toggling useNewAlgorithm for injection ${_id}`
            );
            // this.$store.commit('make_requesting', { req: false });
            this.makeItemRequesting(_id, false);
          });
      }
    },
    killInjection(_id) {
      if (this.requestingItemsIncludeThis(_id)) return;
      if (oidRegex.test(_id)) {
        this.makeItemRequesting(_id, true);
        killInjection(this.token, _id)
          .then(async (r) => {
            if (r.status === 200 && r.data.success === true) {
              showCard(this, `${_id} injection killed`, 'success');
              try {
                // await this.$store.dispatch('get_injections', { vm: this });
                this.updateInjection(r.data.injection);
                // eslint-disable-next-line no-empty
              } catch (e) {
                console.error('error getting back injections from kill request', e);
              }
            } else {
              const cardError =
                r.status === 200 && r.data.success === false
                  ? `Error killing injection`
                  : `${r.status}: Unknown error on killing injection ${_id}`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.makeItemRequesting(_id, false);
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on killing injection ${_id}`);
            this.makeItemRequesting(_id, false);
          });
      } else {
        console.error('invalid injection id to kill', _id);
        showCard(this, `Invalid injection id to kill: ${_id}`, 'warning');
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    restartInjection() {
      this.$store.commit('make_requesting', { req: true });
      restartInjection(this.token, {})
        .then((r) => {
          showCard(this, r.data && r.data.message ? r.data.message : undefined, 'success', '');
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on restarting Injection service`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    updateInjection(injection) {
      if (injection)
        this.$store.commit('update_item_by_id', {
          index: 'injections',
          item: injection,
        });
      this.backdoor += 1;
    },
    async updateData() {
      // await this.$store.dispatch('get_all_resources', { vm: this });
      // await this.$store.dispatch('get_campaigns', { vm: this });
      // await this.$store.dispatch('get_actions', { vm: this });
      // await this.$store.dispatch('get_boxes', { vm: this });
      if (this.$route.params.id) {
        console.log('id to filter:', this.$route.params.id);
        this.filter = this.$route.params.id;
        console.log(this.filter);
      }
      await store.dispatch('get_injections', { vm: this });
      this.totalRows = this.realitems.length;
    },
    selectJsonNode(path, data) {
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
    },
  },
  mounted() {
    this.updateData();
  },
  watch: {
    $route() {
      // this will be called any time the route changes
      console.log(this.$route.path);
      this.updateData();
    },
  },
};
</script>

<style scoped>
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
