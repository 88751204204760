import { DateTime } from 'luxon';

export default function assembleMetaCampaignOptions(
  ctx,
  campaign,
  includeTech = true,
  considerOnlyWhoAcceptNow = false,
  useLinkCheckService = false
) {
  if (!campaign) return [];
  const { metaCampaigns } = campaign;
  if (!metaCampaigns) return [];
  // console.log('metaCampaigns', metaCampaigns);
  // console.log('notConnectedCampaignUrlsByActionId', ctx.notConnectedCampaignUrlsByActionId);
  const dt = DateTime.utc();
  const weekday = dt.toJSDate().getDay();
  const { hour } = dt;

  return metaCampaigns.filter((mc) => {
    const hourLimits = mc.mothersBoxHourLimitsByCountry;
    const { country } = ctx.lead;
    const applyingHourLimits = considerOnlyWhoAcceptNow
      ? (hourLimits && hourLimits[country] ? hourLimits[country] : [])
          .filter(
            (l) =>
              !!l &&
              !!l.days &&
              !!l.limit &&
              !!l.dailyCap &&
              l.days[weekday] &&
              l.limit[0] <= hour &&
              hour <= l.limit[1]
          )
          .reduce((a, l) => a + l.dailyCap.amount, 0)
      : 1;
    return (
      mc.active &&
      ((ctx.copyForm && !ctx.copyForm.m) || mc.sendM) &&
      !!mc.countries &&
      mc.countries.includes(country) &&
      (!useLinkCheckService ||
        (mc.useLinkCheckService && mc.useLinkCheckService.some((c) => c === country))) &&
      mc.brokerApiURL &&
      (!considerOnlyWhoAcceptNow || applyingHourLimits > 0) &&
      ((ctx.copyForm && ctx.copyForm.bypassFRCheck) ||
        ctx.bypassFRCheckForList ||
        ctx.notConnectedCampaignUrlsByActionId.some(
          (nc) => nc.includes(mc.brokerApiURL) || mc.brokerApiURL.includes(nc)
        )) &&
      (includeTech || mc.name !== 'TECH_CAMPAIGN')
    );
  });
}
