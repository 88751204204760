<template>
  <b-container fluid>
    <b-row class="my-3" align-v="center">
      <b-col cols="12" lg="2" class="my-lg-0 my-1"><h2>Campaigns</h2></b-col>
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="4"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="4" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
        <b-button
          @click="pullMeta()"
          variant="warning"
          v-if="iAmOtherLevelTrustedUser"
          :disabled="requesting"
          v-b-popover.hover.bottom="!requesting ? `Don't wait for Pulling` : ''"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>Request Info Now</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2 mb-3">
      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3">
        <span class="d-block h5 font-weight-normal">Geos</span>
        <multiselect
          v-model="filterForm.countries"
          :options="countryOptions"
          track-by="value"
          :custom-label="countryWithCode"
          @search-change="countryMultiSelectSearch"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
        <span class="d-block h5 font-weight-normal">Campaigns</span>
        <multiselect
          v-model="filterForm.campaigns"
          :options="campaignsOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
        <span class="d-block h5 font-weight-normal mt-1">Name</span>
        <b-form-input v-model="filterForm.name"></b-form-input>
      </b-col>
      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
        <span class="d-block h5 font-weight-normal mt-1">Weekday</span>
        <b-form-input v-model="filterForm.weekday"></b-form-input>
      </b-col>
      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <b-button
          @click="applyFilter()"
          :disabled="!canApplyFilter"
          variant="primary"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Apply filter
        </b-button>
        <b-button
          @click="clearFilterForm()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Clear filter
        </b-button>
      </b-col>
    </b-row>
    <h4 class="my-2 dataspan bluetext">
      Countries: {{ appliedFilterForm.countries.map((c) => c.text).join(',') }}
    </h4>
    <div class="my-2">
      <div class="mx-2 my-1 dataspan bluetext">Daughters: {{ stats.daughters }}</div>
      <div class="mx-2 my-1 dataspan bluetext">MetaCampaigns: {{ stats.metaCampaigns }}</div>
      <div class="mx-2 my-1 dataspan bluetext">Uniq: {{ stats.uniq }}</div>
      <div class="mx-2 my-1 dataspan py-1">
        <span class="text-nowrap mr-3">Send leads per meta campaign</span>
        <b-form-input
          type="number"
          size="sm"
          class="w-auto mr-3"
          v-model="sendPerMetaCampaign"
          style="display: inline-block"
        ></b-form-input>
      </div>
      <div class="mx-2 my-1 dataspan">Total leads to send: {{ totalLeadstoSend }}</div>
    </div>
    <div class="my-2">
      <div v-if="Array.isArray(leadsToSendPerDaughter)">
        <div
          v-for="(d, i) in leadsToSendPerDaughter"
          :key="i"
          :class="`mx-2 my-1 dataspan ${d.colorClass}`"
        >
          <h6>{{ d.name }}</h6>
          Intend: {{ d.intendToSend }}; Will: {{ d.willSend }}
          <h6 class="mt-2">Brokers and Caps</h6>
          <ul>
            <li v-for="(m, j) in d.daughtersMetaCampaigns" :key="j">
              {{ m.name }}: {{ m.dailyCap }}
            </li>
          </ul>
        </div>
      </div>
      <div class="mx-2 my-1 dataspan orangetext" v-else>{{ leadsToSendPerDaughter }}</div>
    </div>
    <div class="my-2">
      <MCSBreakdowns
        class="my-2"
        :items="full_mcs_breakdown.total"
        group-name="Total"
        total
        :min-btn-width="260"
      ></MCSBreakdowns>
      <MCSBreakdowns
        class="my-2"
        :items="full_mcs_breakdown.by_copies"
        group-name="Copies"
        :min-btn-width="260"
      ></MCSBreakdowns>
    </div>

    <b-table
      class="table mb-3"
      :fields="fields"
      :items="metaCampaigns"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      responsive
      show-empty
      striped
      hover
    >
      <!--            primary-key="meta_id"
-->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(campaign_active)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'danger'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(active)="data">
        <h5>
          <b-badge
            class="badge-toggle"
            :variant="data.value === true ? 'success' : 'danger'"
            @click="makeActiveMetaCampaign(!data.value, data.item.campaign_id, data.item.meta_id)"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(sendM)="data">
        <h5>
          <b-badge
            class="badge-toggle"
            :variant="data.value === true ? 'success' : 'danger'"
            @click="makeSendMMetaCamnpaign(!data.value, data.item.campaign_id, data.item.meta_id)"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
    </b-table>
  </b-container>
</template>
<script>
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import {
  countryMultiSelectSearch,
  countryWithCode,
  handleRequestError,
  showCard,
  tableFormatterArrayValues,
  // tableFormatterId,
} from '@/utils';
import _ from 'lodash';
import { countryOptions, OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import {
  getMetaCampaigns,
  makeSendMMetaCamnpaign,
  makeActiveMetaCampaign,
  pullMeta,
} from '@/utils/api';
import MCSBreakdowns from './components/MCSBreakdowns.vue';
import '@/assets/badge-toogle.css';

const initialFilterForm = {
  campaigns: [],
  name: '',
  countries: [],
  weekday: undefined,
};

export default {
  name: 'MetaCampaigns',
  components: { MCSBreakdowns },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      countryOptions,
      metaCampaigns: [],
      daughters: [],
      filterForm: _.cloneDeep(initialFilterForm),
      appliedFilterForm: _.cloneDeep(initialFilterForm),
      stats: { daughters: 0, metaCampaigns: 0, uniq: 0 },
      sendPerMetaCampaign: undefined,
      full_mcs_breakdown: { total: {}, by_copies: [] },
      filterIncludedFields: ['_id', 'name', 'campaign_name'],
    };
  },
  computed: {
    ...mapState(['user', 'token', 'requesting', 'campaigns', 'store_backdoor', 'isAdmin']),
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    fields() {
      return [
        // { key: 'campaign_id', sortable: true, formatter: tableFormatterId },
        { key: 'campaign_name', label: 'Daughter', sortable: true },
        // { key: 'campaign_active', label: 'Campaign Active', sortable: true },
        // { key: 'meta_id', sortable: true, formatter: tableFormatterId },
        { key: 'name', sortable: true },
        { key: 'active', sortable: true },
        { key: 'sendM', sortable: true },
        { key: 'm', sortable: true },
        { key: 'r', sortable: true },
        {
          key: 'countries',
          label: 'Geos',
          sortable: true,
          formatter: (value) => tableFormatterArrayValues(value, 14),
        },
        this.appliedFilterForm.countries.length === 1
          ? { key: 'dailyCap', sortable: true }
          : undefined,
      ].filter((f) => !!f);
    },
    campaignsOptions() {
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    canApplyFilter() {
      return (
        !this.requesting && !_.isEqual(this.appliedFilterForm, this.filterForm) // &&
      );
    },
    totalLeadstoSend() {
      if (!this.sendPerMetaCampaign) return 0;
      return this.sendPerMetaCampaign * this.stats.metaCampaigns;
    },
    leadsToSendPerDaughter() {
      const { countries } = this.appliedFilterForm;
      if (countries.length === 0)
        return 'Cannot calculate LTS per Daughter. Reason: No country selected';
      if (countries.length > 1)
        return 'Cannot calculate LTS per Daughter. Reason: Multiple countries selected';
      const countLtsPerDaughter = (daughter) => {
        const daughtersMetaCampaigns = this.metaCampaigns.filter(
          (mc) => mc.campaign_id === daughter._id
        );
        const intendToSend = this.sendPerMetaCampaign
          ? this.sendPerMetaCampaign * daughtersMetaCampaigns.length
          : 0;
        const minMothersBoxDailyCap = daughtersMetaCampaigns
          .map((mc) => mc.dailyCap)
          .filter((dc) => typeof dc === 'number')
          .reduce((a, b) => a + b, 0);
        const maxMothersBoxDailyCap = Infinity;
        const colorClass =
          intendToSend <= minMothersBoxDailyCap
            ? 'greentext'
            : intendToSend <= maxMothersBoxDailyCap
            ? 'orangetext'
            : 'redtext';
        const willSend = Math.min(intendToSend, minMothersBoxDailyCap, maxMothersBoxDailyCap);
        return {
          ...daughter,
          daughtersMetaCampaigns,
          intendToSend,
          willSend,
          colorClass,
          minMothersBoxDailyCap,
          maxMothersBoxDailyCap,
        };
      };
      return this.daughters.map((d) => countLtsPerDaughter(d));
    },
  },
  methods: {
    countryMultiSelectSearch,
    makeUrl(url, endpoint) {
      return `${url[url.length - 1] === '/' ? url.slice(1) : url}:2053${
        endpoint[0] === '/' ? endpoint : `/${endpoint}`
      }`;
    },
    changeTableFieldValue(_id, metaId, field, value) {
      const index = this.metaCampaigns.findIndex(
        (mc) => mc.campaign_id === _id && mc.meta_id === metaId
      );
      if (index < 0) return;
      this.metaCampaigns[index][field] = value;
    },
    makeSendMMetaCamnpaign(sendM, _id, metaId) {
      makeSendMMetaCamnpaign(this.token, {
        sendM,
        _id,
        metaId,
      })
        .then((r) => {
          if (r.status === 200) {
            showCard(this, r.data.message, 'success');
            this.changeTableFieldValue(_id, metaId, r.data.flag, r.data.value);
          } else {
            const cardError =
              r.data.error || `${r.status}: Unknown error on changing sendM for metaCampaign`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on changing sendM for metaCampaign`);
          this.requesting = false;
        });
    },
    makeActiveMetaCampaign(active, _id, metaId) {
      makeActiveMetaCampaign(this.token, {
        active,
        _id,
        metaId,
      })
        .then((r) => {
          if (r.status === 200) {
            showCard(this, r.data.message, 'success');
            this.changeTableFieldValue(_id, metaId, r.data.flag, r.data.value);
          } else {
            const cardError =
              r.data.error || `${r.status}: Unknown error on changing Active for metaCampaign`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on changing Active for metaCampaign`);
          this.requesting = false;
        });
    },
    updateData() {
      this.$store.commit('make_requesting', { req: true });
      getMetaCampaigns(this.token, this.appliedFilterForm)
        .then((r) => {
          // console.log('r.data', r.data);
          const { data } = r;
          this.metaCampaigns = _.cloneDeep(data.metaCampaigns);
          this.stats = _.cloneDeep(data.stats);
          this.daughters = _.cloneDeep(data.daughters);
          this.full_mcs_breakdown = _.cloneDeep(data.full_mcs_breakdown);
          // console.log('this.metaCampaigns', this.metaCampaigns);
          const { countries } = this.appliedFilterForm;
          for (let i = 0; i < this.metaCampaigns.length; i += 1) {
            if (countries.length === 1) {
              const country = countries[0].value ? countries[0].value : countries[0];
              const dailyCaps = this.metaCampaigns[i].mothersBoxDailyCapsByCountry;
              this.metaCampaigns[i].dailyCap =
                dailyCaps && dailyCaps[country] && dailyCaps[country].amount
                  ? dailyCaps[country].amount
                  : 0;
            } else {
              this.metaCampaigns[i].dailyCap = 'unknown';
            }
            if (countries.length > 0) {
              this.metaCampaigns[i].countries = this.metaCampaigns[i].countries.filter((c) =>
                countries.map((cc) => (cc.value ? cc.value : cc)).includes(c)
              );
            }
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on getting metaCampaigns`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    applyFilter() {
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    clearFilterForm() {
      this.filter = null;
      this.filterForm = _.cloneDeep(initialFilterForm);
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    countryWithCode(c) {
      return countryWithCode(c);
    },
    pullMeta() {
      if (!this.isAdmin) return;
      this.$store.commit('make_requesting', { req: true });
      pullMeta(this.token)
        .then((r) => {
          r.data.success
            ? showCard(this, 'PullMeta requested')
            : handleRequestError(this, r.data, 'Error pulling Meta');
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error pulling Meta');
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  mounted() {
    this.$store.dispatch('get_all_resources_one_request', { vm: this });
    this.updateData();
  },
};
</script>

<style scoped>
.table >>> th,
.table >>> th > *,
.table >>> td,
.table >>> td > * {
  white-space: nowrap !important;
}
.table >>> .card-columns {
  white-space: initial !important;
}
.table >>> .with-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.dataspan {
  /*padding: 3px 7px;*/
  padding: 0.47rem 7px;
  /*padding-bottom: 0.25rem !important;*/
  /*padding-top: 0.25rem !important;*/
  border: 1px solid;
  border-radius: 4px;
  display: inline-block;
}
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
