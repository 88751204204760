<template>
  <CampaignsTable />
</template>

<script>
import CampaignsTable from '@/components/pages/Campaigns/CampaignsTable/CampaignsTable.vue';

export default {
  name: 'CampaignsView',
  components: {
    CampaignsTable,
  },
};
</script>
