<template>
  <div>
    <div class="input-group">
      <input
        :type="type"
        class="form-control"
        :placeholder="placeholder"
        :value="password"
        id="password-field"
        :disabled="nomanual"
      />
      <span class="input-group-btn">
        <button
          type="button"
          class="btnp btn-password btn btn-primary"
          @click="generate()"
          :disabled="type === 'password'"
        >
          <font-awesome-icon icon="sync-alt"></font-awesome-icon>
        </button>
      </span>
      <!--      <span class="input-group-btn"-->
      <!--        >-->
      <!--        <button-->
      <!--          type="button"-->
      <!--          class="btnp btn-password btn btn-primary"-->
      <!--          @click="copyToClipboard()"-->
      <!--          :disabled="copied"-->
      <!--        >-->
      <!--&lt;!&ndash;          <font-awesome-icon icon="clipboard"></font-awesome-icon></button>&ndash;&gt;-->
      <!--      </span>-->
    </div>
    <!--    <span v-if="copied">Copied</span>-->
  </div>
</template>

<script>
// https://codepen.io/CSWApps/pen/QvYNMM
// https://codepen.io/nourabusoud/pen/YeMOVv
import '@/assets/password.scss';
import { isStrongPassword } from 'validator';

export default {
  name: 'password',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: '32',
    },
    characters: {
      type: String,
      default: 'a-z,A-Z,0-9',
    },
    placeholder: {
      type: String,
      default: 'Password',
    },
    auto: [String, Boolean],
    nomanual: [String, Boolean],
    value: { type: String, default: '' },
  },
  data() {
    return {
      password: this.value,
      copied: false,
    };
  },
  mounted() {
    if (this.auto === 'true' || this.auto === 1 || this.auto === true) {
      this.generate();
    }
  },
  methods: {
    generate() {
      const charactersArray = this.characters.split(',');
      let CharacterSet = '';
      let password = '';
      const options = {
        minLength: this.size, // 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
        returnScore: false,
        pointsPerUnique: 1,
        pointsPerRepeat: 1,
        pointsForContainingLower: 10,
        pointsForContainingUpper: 10,
        pointsForContainingNumber: 10,
        pointsForContainingSymbol: 0,
      };
      if (charactersArray.indexOf('a-z') >= 0) {
        options.minLowercase = 1;
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        options.minUppercase = 1;
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        options.minNumbers = 1;
        CharacterSet += '0123456789';
      }
      if (charactersArray.indexOf('#') >= 0) {
        options.minSymbols = 1;
        CharacterSet += '![]{}()%&*$#^<>~@|';
      }
      let strong = isStrongPassword(password, options);

      while (!strong) {
        password = '';
        for (let i = 0; i < this.size; i += 1) {
          password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
        }
        strong = isStrongPassword(password, options);
      }

      this.password = password;
      this.$emit('input', this.password);
    },
    copyToClipboard() {
      // we should create a textarea, put the password inside it, select it and finally copy it
      // const copyElement = document.createElement('textarea');
      // copyElement.style.opacity = '0';
      // copyElement.style.position = 'fixed';
      // copyElement.textContent = this.password;
      // const body = document.getElementsByTagName('body')[0];
      // body.appendChild(copyElement);
      const copyElement = document.getElementById('password-field');
      copyElement.select();
      document.execCommand('copy');
      // body.removeChild(copyElement);

      this.copied = true;
      // reset this.copied
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
};
</script>

<style scoped></style>
