<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h5 class="mx-1 my-2">{{ name }}</h5>
    <b-form-group
      class="my-3"
      label="Endpoint"
      label-for="sendlead-endpoint"
      :state="isValid.fields.endpoint"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
      -->
      <b-form-input
        type="text"
        id="sendlead-endpoint"
        v-model="form.endpoint"
        @input="emitEditEvent"
        :state="isValid.fields.endpoint"
      ></b-form-input>
      <!--      :state="isValid.fields.name"-->
    </b-form-group>
    <PullingPaginationParameters
      :Pagination="form.Pagination"
      @edit="handleEditEvent($event, 'Pagination')"
      @validate="handleValidateEvent($event, 'Pagination')"
    ></PullingPaginationParameters>
    <PathArrayForm
      :pathArray="form.ResultsArrayPath"
      name="ResultsArray Paths"
      @edit="handleEditEvent($event, 'ResultsArrayPath')"
      @validate="handleValidateEvent($event, 'ResultsArrayPath')"
    ></PathArrayForm>
    <!--          disallowEmpty -->
    <PathArrayForm
      :pathArray="form.LeadIdPath"
      name="LeadId Paths"
      @edit="handleEditEvent($event, 'LeadIdPath')"
      @validate="handleValidateEvent($event, 'LeadIdPath')"
      disallowEmpty
    ></PathArrayForm>
    <PathArrayForm
      :pathArray="form.SalesStatusPath"
      name="SalesStatus Paths"
      @edit="handleEditEvent($event, 'SalesStatusPath')"
      @validate="handleValidateEvent($event, 'SalesStatusPath')"
      disallowEmpty
    ></PathArrayForm>
    <b-form-checkbox
      class="my-2"
      v-model="form.SalesStatusEncoded"
      @input="emitEditEvent"
      :state="isValid.fields.SalesStatusEncoded"
    >
      Sales Status Encoded
    </b-form-checkbox>
    <ObjectKeyValueForm
      v-if="form.SalesStatusEncoded"
      name="SalesStatus Codes"
      :object="form.StatusCodes"
      @edit="handleEditEvent($event, 'StatusCodes')"
      @validate="handleValidateEvent($event, 'StatusCodes')"
      disallowEmpty
    ></ObjectKeyValueForm>
    <dataPreprocessingParameters
      :dataPreprocessing="form.dataPreprocessing"
      @edit="handleEditEvent($event, 'dataPreprocessing')"
      @validate="handleValidateEvent($event, 'dataPreprocessing')"
    ></dataPreprocessingParameters>
    <dataPreprocessingParameters
      name="Data Postprocessing"
      :dataPreprocessing="form.dataPostprocessing"
      @edit="handleEditEvent($event, 'dataPostprocessing')"
      @validate="handleValidateEvent($event, 'dataPostprocessing')"
    ></dataPreprocessingParameters>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
import handleEditEvent from '@/utils/handleEditEvent';
import PathArrayForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PathArrayForm.vue';
// import { castAuthArrayToObject, castAuthObjectToArray } from '@/validators';
import ObjectKeyValueForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/ObjectKeyValueForm.vue';
import dataPreprocessingParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/dataPreprocessingParameters.vue';
import handleValidateEvent from '@/utils/handleValidateEvent';
import { formValid, isValid } from '@/validators';
import { mapPropFieldToValid } from '@/utils';
import PullingPaginationParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PullingPaginationParameters.vue';

const initial = {
  endpoint: '',
  // ResultsArrayPath: [],
  // LeadIdPath: [],
  // SalesStatusPath: [],
  SalesStatusEncoded: false,
  // StatusCodes: {},
  // dataPreprocessing: {},
};
export default {
  name: 'PullingLeadsSettings',
  components: {
    ObjectKeyValueForm,
    PathArrayForm,
    dataPreprocessingParameters,
    PullingPaginationParameters,
  },
  props: {
    name: { type: String, default: 'Pulling Leads Settings' },
    settings: {
      endpoint: { type: String, default: '' },
      Pagination: {
        type: Object,
        default: () => {
          return {};
        },
      },
      ResultsArrayPath: { type: Array, default: () => [] },
      LeadIdPath: { type: Array, default: () => [] },
      SalesStatusPath: { type: Array, default: () => [] },
      SalesStatusEncoded: { type: Boolean, default: false },
      StatusCodes: {
        type: Object,
        default: () => {
          return {};
        },
      },
      dataPreprocessing: {
        type: Object,
        default: () => {
          return {};
        },
      },
      dataPostprocessing: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  },
  data() {
    return {
      form: (() => {
        const props = cloneDeep(this.$props.settings ? this.$props.settings : initial);
        // props.StatusCodes = castAuthObjectToArray(props.settings.StatusCodes);
        return props;
      })(),
      valid: mapPropFieldToValid(this.$props.settings, initial),
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = {
        ...this.valid,
        ...isValid('integration_PullingLeadsSettings', this.form, this.form),
        StatusCodes: !this.form.SalesStatusEncoded || this.valid.StatusCodes,
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      // console.log('PullingLeadsSettings validated', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    // handleMultipleKeysEditEvent,
    castFormToEmit() {
      const form = cloneDeep(this.form);
      // if (form.StatusCodes) form.StatusCodes = castAuthArrayToObject(form.StatusCodes);
      return form;
    },
    emitEditEvent() {
      this.$emit('edit', this.castFormToEmit());
    },
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
