<template>
  <div>
    <b-modal id="modal-create-psp" ref="modal-create-psp" centered hide-footer no-close-on-esc>
      <!--      @hide="addNewPSPToList()"-->
      <PSPCreate :checkAvailability="true" :action_id="action_id" @pspSaved="addNewPSPToList" />
      <!--      <h3>Create PSP</h3>-->
    </b-modal>
    <h3 class="m-2">PSPs that can be assigned to this Lead and Broker</h3>
    <h3 class="m-2">
      Lead
      <b>{{ email }}</b>
      , Broker
      <b>{{ finalReceiver }}</b>
      <b-icon icon="question-circle" id="header-question-circle" class="mx-1"></b-icon>
      <b-popover target="header-question-circle" triggers="hover" placement="right">
        <template #title>What does it mean?</template>
        <p>
          If you don't see PSP that you need, try to Add it via
          <b-button class="m-2" @click="addNewPsp()" variant="primary" :disabled="requesting">
            <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
            Add New
          </b-button>
          button.
        </p>
        <p>
          PSPs are sorted based on their
          <b><i>preferrability</i></b>
          rate - this means, based on how good they are for this Lead and Broker.
        </p>
        <p>
          If some PSP's
          <b><i>preferrability</i></b>
          is lower than 5, you
          <b>must not use it</b>
          for this Lead and Broker.
        </p>
      </b-popover>
    </h3>
    <h3 v-if="psp && psp.number" class="m-2">
      PSP Assigned now: type:
      <b>{{ psp.type }}</b>
      , number:
      <b>{{ pspFormatterFullCardNumber(psp.type, psp.number) }}</b>
    </h3>
    <!--    <div style="display: block; align-items: center">-->
    <!--      <b-button class="m-2" @click="addNewPsp()" variant="primary" :disabled="requesting">-->
    <!--        <b-spinner small v-if="requesting" class="mx-1"></b-spinner>-->
    <!--        Add New-->
    <!--      </b-button>-->
    <!--    </div>-->
    <b-row class="my-2" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        lg="3"
        xl="3"
        cols="12"
      >
        <b-form-radio-group
          id="btn-radios-1"
          size="md"
          button-variant="outline-primary"
          v-model="pspType"
          :options="pspTypeOptions"
          name="radios-btn-default"
          buttons
          :disabled="requesting"
          class="m-2"
        ></b-form-radio-group>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        lg="3"
        xl="3"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="4"
        lg="2"
        xl="2"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="4"
        lg="2"
        xl="2"
        cols="12"
      >
        <b-button class="m-2" @click="addNewPsp()" variant="primary" :disabled="requesting">
          <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
          Add New
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="4"
        lg="4"
        xl="4"
        cols="12"
      >
        <span class="d-block h5 font-weight-normal mt-1 mx-1">Number</span>
        <b-form-input v-model="filterForm.number"></b-form-input>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        lg="4"
        xl="4"
        cols="12"
      >
        <span class="d-block h5 font-weight-normal mt-1 mx-1">Comment</span>
        <b-form-input v-model="filterForm.comment"></b-form-input>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        lg="4"
        xl="4"
        cols="12"
      >
        <b-form-checkbox v-model="filterForm.showOnlyAvailable">
          Show Only Available
        </b-form-checkbox>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        lg="4"
        xl="4"
        cols="12"
      >
        <b-button class="mx-2" variant="primary" :disabled="requesting" @click="updateData()">
          <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
      </b-col>
    </b-row>
    <b-table
      class="table my-2"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else-if="data.field.key === 'preferrability'">
          {{ data.label }}
          <b-icon
            icon="question-circle"
            :id="`${data.field.key}-header-question-icon`"
            class="mx-1"
          />
          <b-popover
            :target="`${data.field.key}-header-question-icon`"
            triggers="hover"
            placement="right"
          >
            <template #title>What does it mean?</template>
            <p>
              This value shows how good is each
              <b>PSP</b>
              for this
              <b>Lead</b>
              and
              <b>Broker</b>
              combined.
            </p>
            <p>
              If some PSP's
              <b><i>preferrability</i></b>
              is lower than 5, you
              <b>must not use it</b>
              for this Lead and Broker.
            </p>
          </b-popover>
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(_id)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>
      <template #cell(number)="data">
        <span v-b-popover.hover.top="data.unformatted">
          {{ pspFormatterFullCardNumber(data.item.type, data.item.number) }}
        </span>
      </template>
      <template #cell(history)="data">
        <b-button variant="primary" v-b-modal="`show-use-history-${data.item._id}`">
          History
        </b-button>
        <b-modal :id="`show-use-history-${data.item._id}`" centered size="lg" hide-footer>
          <PSPUseHistory :psp="data.item" />
        </b-modal>
      </template>
      <template #cell(available)="data">
        <h5 class="mb-0">
          <b-badge :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'">
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(preferrability)="data">
        <h5 v-if="psp && psp.number && psp.number === data.item.number">
          <b-badge variant="success">Assigned</b-badge>
        </h5>
        <b-badge
          v-else
          :variant="
            data.value > 7
              ? 'success'
              : data.value > 5
              ? 'warning'
              : data.value === 5
              ? 'warning'
              : 'danger'
          "
        >
          <span>
            <!--            :style="
              data.value > 7
                ? 'color: green;'
                : data.value > 5
                ? 'color: yellow;'
                : data.value === 5
                ? 'color: orange;'
                : 'color: red;'
            "-->
            {{ data.value }}
            <b-icon
              icon="info-circle"
              :id="`preferrability-info-icon-${data.item._id}`"
              class="mx-1"
            />
          </span>
        </b-badge>
        <b-popover
          :target="`preferrability-info-icon-${data.item._id}`"
          triggers="hover"
          placement="right"
        >
          <template #title>Why this rate?</template>
          <p>Preferrability rate for this PSP is {{ data.item.preferrability }}. Maximum is 10</p>
          <div v-if="Array.isArray(data.item.reasonsToDrop)">
            <p>Resons to lower rate</p>
            <ul>
              <li v-for="(reason, index) in data.item.reasonsToDrop" v-bind:key="index">
                {{ reason }}
              </li>
            </ul>
          </div>
          <p v-else>No reasons to lower preferrability rate</p>
          <p>
            If some PSP's
            <b><i>preferrability</i></b>
            is lower than 5, you
            <b>must not use it</b>
            for this Lead and Broker.
          </p>
        </b-popover>
      </template>
      <template #cell(assign)="data">
        <b-button
          v-if="psp && psp.number && psp.number === data.item.number"
          variant="danger"
          v-b-modal="`modal-cancel-psp-${data.item._id}`"
          size="sm"
        >
          Cancel
        </b-button>
        <b-button
          v-else
          size="sm"
          variant="primary"
          v-b-modal="`modal-assign-psp-${data.item._id}`"
          v-b-popover:hover:bottom="data.item.reasonsToDrop.join('; ')"
        >
          <!-- :disabled="!data.item.preferrability || data.item.preferrability < 5" -->
          Assign
        </b-button>
        <b-modal
          :id="`modal-assign-psp-${data.item._id}`"
          centered
          @ok="assignPSP(data.item._id)"
          ok-variant="success"
          ok-title="Yes, Assign!"
          cancel-title="No, Cancel"
          cancel-variant="danger"
          footer-class="centered"
          hide-header
          lazy
          :ok-disabled="requesting"
        >
          <!-- v-if="data.item.preferrability && data.item.preferrability >= 5 " -->
          <h5>
            Are you sure you want to
            <b>Assign</b>
            PSP
            <b>
              {{ pspFormatterFullCardNumber(data.item.type, data.item.number) }}
            </b>
            to Broker
            <b>{{ finalReceiver }}</b>
            for Lead
            <b>{{ email }}</b>
            ?
          </h5>
        </b-modal>
        <b-modal
          :id="`modal-cancel-psp-${data.item._id}`"
          v-if="psp && psp.number && psp.number === data.item.number"
          centered
          @ok="deassignPSP(data.item._id)"
          ok-variant="danger"
          ok-title="Yes, Cancel!"
          cancel-title="No, Go Back To PSPs"
          cancel-variant="primary"
          footer-class="centered"
          hide-header
          lazy
          :ok-disabled="requesting"
        >
          <h5>
            Are you sure you want to
            <b>Cancel</b>
            PSP
            <b>{{ pspFormatterFullCardNumber(data.item.type, data.item.number) }}</b>
            from Lead
            <b>{{ email }}</b>
            for Broker
            <b>{{ finalReceiver }}</b>
            ?
          </h5>
        </b-modal>
      </template>
    </b-table>
    <b-row class="my-2" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import { mapState } from 'vuex';
import { pspTypeOptions } from '@/consts';
import {
  handleRequestError,
  showCard,
  tableFormatterId,
  tableFormatterFullCardNumber,
} from '@/utils';
import { cloneDeep } from 'lodash';
import { getAvailablePSPsForAction, assignPSPToAction, deassignPSPFromAction } from '@/utils/api';
import { DateTime } from 'luxon';
import PSPUseHistory from '@/components/pages/PSP/PSPUseHistory.vue';
import PSPCreate from './PSPCreate.vue';

export default {
  name: 'PSPAssign',
  components: { PSPUseHistory, PSPCreate },
  props: {
    action_id: { type: String, default: '' },
    email: { type: String, default: '' },
    finalReceiver: { type: String, default: '' },
    psp: { type: Object, default: () => {} },
  },
  data() {
    return {
      pspType: 'all',
      pspTypeOptions,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        // { value: 500, text: '500' },
        // { value: 1000, text: '1000' },
      ],
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      fields: [
        { key: '_id', sortable: false, formatter: (v) => tableFormatterId(v) },
        { key: 'type', sortable: false },
        { key: 'number', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'available', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'ftds', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'comment', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'history', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'preferrability', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
        { key: 'assign', sortable: false }, // , formatter: (v) => tableFormatterId(v, 4)
      ],
      filter: null,
      filterForm: { number: undefined, comment: undefined, showOnlyAvailable: true },
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'token', 'requesting']),
  },
  methods: {
    pspFormatterFullCardNumber(type, number) {
      return type === 'card' ? tableFormatterFullCardNumber(number) : number;
    },
    addNewPsp() {
      this.$refs['modal-create-psp'].show();
    },
    addNewPSPToList(newPSP) {
      this.items = cloneDeep([newPSP, ...this.items.filter((item) => item._id !== newPSP._id)]);
    },
    updateData() {
      this.$store.commit('make_requesting', { req: true });
      const { totalRows, currentPage, perPage, action_id } = this;
      const data = {
        type: this.pspType,
        totalRows,
        currentPage,
        perPage,
        _id: action_id,
        filter: this.filterForm,
      };
      getAvailablePSPsForAction(this.token, data)
        .then((r) => {
          this.items = cloneDeep(r.data);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error getting PSP available for this action');
          this.$store.commit('make_requesting', { req: false });
        });
    },
    assignPSP(psp_id) {
      this.$store.commit('make_requesting', { req: true });
      const { action_id } = this;
      const data = { psp_id, action_id };
      assignPSPToAction(this.token, data)
        .then((r) => {
          if (r.data.result) {
            showCard(this, 'PSP assigned');
            const psp = this.items.find((item) => item._id === psp_id);
            this.updateData();
            this.$emit('pspAssigned', {
              action_id,
              psp: { _id: psp || psp_id, assigned: DateTime.utc().toISO() },
            });
          } else {
            handleRequestError(this, r.data, 'Error occured while trying to assign PSP');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error occured while trying to assign PSP');
          this.$store.commit('make_requesting', { req: false });
        });
    },
    deassignPSP(psp_id) {
      this.$store.commit('make_requesting', { req: true });
      const { action_id } = this;
      const data = { psp_id, action_id };
      deassignPSPFromAction(this.token, data)
        .then((r) => {
          if (r.data.result) {
            showCard(this, 'PSP cancelled from Lead');
            // const psp = this.items.find((item) => item._id === psp_id);
            this.updateData();
            this.$emit('pspAssigned', {
              action_id,
              psp: undefined,
              // psp: { _id: psp || psp_id, assigned: DateTime.utc().toISO() },
            });
          } else {
            handleRequestError(this, r.data, 'Error occured while trying to cancel PSP from Lead');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error occured while trying to cancel PSP from Lead');
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  mounted() {
    this.updateData();
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    pspType() {
      if (!this.$store.state.requesting) this.updateData();
    },
  },
};
</script>

<style scoped>
.centered {
  align-content: center;
}
</style>
