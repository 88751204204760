<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">
        {{ _id ? 'Edit' : 'Add' }} Box Campaign Setting
      </h2>
    </div>

    <b-checkbox v-model="bcePresetForm.active" class="my-2">Active</b-checkbox>

    <b-form-group class="my-2" label="Geo" label-for="preset-geo" :state="isValid.fields.geo">
      <multiselect
        id="preset-geo"
        v-model="bcePresetForm.geo"
        :options="countryOptions"
        track-by="value"
        :custom-label="countryWithCode"
        :closeOnSelect="false"
        :multiple="false"
        :state="isValid.fields.geo"
        :style="isValid.fields.geo ? 'border-color: red;' : ''"
        @search-change="countryMultiSelectSearch"
      ></multiselect>
    </b-form-group>
    <b-row>
      <b-col cols="12" lg="4" md="4">
        <b-form-group
          class="my-2"
          label="Daily Cap"
          :invalid-feedback="invalidFeedback.dailyCap.type"
          :state="isValid.fields.dailyCap.type"
          :label-for="`preset-dailycap-type`"
        >
          <b-form-select
            :id="`preset-dailycap-type`"
            :state="isValid.fields.dailyCap.type"
            v-model="bcePresetForm.dailyCap.type"
            :options="actionTypeOptions"
            @input="backdoor += 1"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="4" md="4">
        <b-form-group
          class="my-2"
          label="Amount"
          :label-for="`preset-dailycap-amount`"
          :invalid-feedback="invalidFeedback.dailyCap.amount"
          :state="isValid.fields.dailyCap.amount"
        >
          <b-form-input
            :id="`preset-dailycap-amount`"
            :state="isValid.fields.dailyCap.amount"
            v-model="bcePresetForm.dailyCap.amount"
            @input="backdoor += 1"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      label="Default payment"
      class="my-2"
      label-class="mr-2"
      label-for="default-payment"
      :invalid-feedback="invalidFeedback.box.campaigns.defaultPayment"
      :state="isValid.fields.defaultPayment"
    >
      <b-form-input
        id="default-payment"
        v-model="bcePresetForm.defaultPayment"
        :state="isValid.fields.defaultPayment"
      ></b-form-input>
    </b-form-group>

    <div class="my-2">
      <h6 class="mb-2">Hour Limit</h6>
      <h6 class="mr-1">From: {{ bcePresetForm.hourLimit[0] }}:00</h6>
      <b-form-input
        v-model="bcePresetForm.hourLimit[0]"
        type="range"
        min="0"
        :max="23"
        @input="backdoor += 1"
      ></b-form-input>
      <h6 class="mx-1 my-2">to: {{ bcePresetForm.hourLimit[1] }}:59</h6>
      <b-form-input
        v-model="bcePresetForm.hourLimit[1]"
        type="range"
        :min="0"
        max="23"
        @input="backdoor += 1"
      ></b-form-input>
    </div>
    <b-form-group class="my-2" label="Days" :label-for="`preset-days`">
      <b-form-checkbox-group
        :id="`preset-days`"
        v-model="bcePresetForm.days"
        :options="daysOfWeek"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        size="lg"
        stacked
        @input="backdoor += 1"
      ></b-form-checkbox-group>
      <b-button
        class="mr-2"
        variant="primary"
        @click="
          bcePresetForm.days = [0, 1, 2, 3, 4, 5, 6];
          backdoor += 1;
        "
      >
        All
      </b-button>
      <b-button
        @click="
          bcePresetForm.days = [];
          backdoor += 1;
        "
      >
        None
      </b-button>
    </b-form-group>
    <b-form-group
      class="my-2"
      label="Priority"
      :label-for="`preset-priority`"
      :invalid-feedback="invalidFeedback.box.campaigns.priority"
      :state="isValid.fields.priority"
    >
      <b-form-input
        :id="`preset-priority`"
        :state="isValid.fields.priority"
        v-model="bcePresetForm.priority"
      ></b-form-input>
      <!--      @input="backdoor += 1"-->
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Weight"
      :label-for="`preset-weight`"
      :invalid-feedback="invalidFeedback.box.campaigns.weight"
      :state="isValid.fields.weight"
    >
      <b-form-input
        :id="`preset-weight`"
        :state="isValid.fields.weight"
        v-model="bcePresetForm.weight"
        @input="backdoor += 1"
      ></b-form-input>
    </b-form-group>

    <div class="d-flex justify-content-between mb-3">
      <b-button @click="saveBCEPreset()" variant="primary" :disabled="!isValid.form || requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        {{ bcePresetForm._id ? 'Save BCEPreset' : 'Create BCEPreset' }}
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import '@/assets/form.scss';
import { getBCEPreset, saveBCEPreset } from '@/utils/api';
import {
  countryMultiSelectSearch,
  countryWithCode,
  deleteUnchangedFields,
  handleRequestError,
  isAdmin,
  showCard,
  tableFormatterFullCardNumber,
} from '@/utils';
import {
  validFeedback,
  invalidFeedback,
  countryOptions,
  daysOfWeek,
  actionTypeOptions,
} from '@/consts';
import { isValid, formValid } from '@/validators';
import _ from 'lodash';
import { noCap } from '@/validators/_utils';

const itemInitialValues = {
  _id: undefined,
  geo: undefined,
  dailyCap: { type: undefined, amount: undefined },
  defaultPayment: undefined,
  hourLimit: [0, 23],
  days: daysOfWeek.map((d) => d.item),
  priority: '',
  weight: 100,
  active: true,
};

export default {
  name: 'BCEPresetCreate',
  components: {},
  props: {
    _id: { type: String, default: '' },
  },
  data() {
    return {
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      validFeedback,
      invalidFeedback,
      backdoor: 0,
      bcePresetForm: _.cloneDeep(itemInitialValues),
      initialForm: {},
      countryOptions: [...countryOptions, { text: 'default', value: 'default' }],
      actionTypeOptions,
      daysOfWeek,
    };
  },
  computed: {
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    ...mapState([
      'token',
      'campaigns',
      'affiliates',
      'managers',
      'requesting',
      'user',
      'isAffiliate',
    ]),
    isValid() {
      this.backdoor;
      const fields = isValid('bcePreset', this.bcePresetForm, this.bcePresetForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
  },
  methods: {
    countryWithCode(c) {
      return countryWithCode(c);
    },
    countryMultiSelectSearch,
    numberFormatter(value) {
      return this.bcePresetForm.type === 'card' ? tableFormatterFullCardNumber(value) : value;
    },
    saveBCEPreset() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        let toSave = _.cloneDeep(this.bcePresetForm);
        const days = [true, true, true, true, true, true, true];
        toSave.days = days.map((d, j) => this.bcePresetForm.days.includes(j));
        if (toSave.dailyCap && noCap(toSave.dailyCap)) toSave.dailyCap = false;
        toSave = deleteUnchangedFields(this.initialForm, this.bcePresetForm, toSave);
        if (toSave.geo) toSave.geo = toSave.geo.value ? toSave.geo.value : toSave.geo;
        saveBCEPreset(this.token, toSave)
          .then(async (r) => {
            if (r.status === 200) {
              showCard(this, `${r.data.geo} BCEPreset saved`, 'success');
              this.castBCEPresetToForm(r.data);
              // if (this.checkAvailability && this.action_id && this.action_id !== '') {
              this.$emit('bcePresetSaved', _.cloneDeep(this.castBCEPresetToForm(r.data)));
              // }
              this.backdoor += 1;
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving BCEPreset`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.$store.commit('make_requesting', { req: false });
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving BCEPreset`);
            this.$store.commit('make_requesting', { req: false });
          });
      }
    },
    getBCEPreset(_id) {
      console.log('Getting psp', _id);
      getBCEPreset(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castBCEPresetToForm(r.data);
            this.backdoor += 1;
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting psp`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning', 'Error getting psp');
          }
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error on getting psp', 'Error getting psp');
        });
    },
    castBCEPresetToForm(bcePreset) {
      const initial = _.cloneDeep(itemInitialValues);
      // cast campaign to edit form
      // prettier-ignore
      this.bcePresetForm = {
        _id: bcePreset._id ? bcePreset._id : initial._id,
        geo:bcePreset.geo ? this.countryOptions.find(c=>c.value===bcePreset.geo) : initial.geo,
        dailyCap:bcePreset.dailyCap ? bcePreset.dailyCap : initial.dailyCap,
        defaultPayment:bcePreset.defaultPayment ? bcePreset.defaultPayment : initial.defaultPayment,
        hourLimit:bcePreset.hourLimit ? bcePreset.hourLimit : initial.hourLimit,
        days: bcePreset.days ? bcePreset.days.map((d, i) => (d ? i : false)).filter((d) => d !== false) : initial.days,
        priority:bcePreset.priority ? bcePreset.priority : initial.priority,
        weight:bcePreset.weight ? bcePreset.weight : initial.weight,
        active:bcePreset.active ? bcePreset.active : initial.active,
      };
      this.initialForm = _.cloneDeep(this.bcePresetForm);
    },
    async updateData() {
      // await store.dispatch('get_all_resources_one_request', { vm: this });
      if (!this.bcePresetForm._id && !isAdmin(this.user)) {
        this.bcePresetForm.affiliates = [{ name: this.user.name, _id: this.user._id }];
      }
    },
  },
  mounted() {
    this.updateData();
    if (this._id && this._id !== '') this.getBCEPreset(this._id);
    else {
      this.bcePresetForm = _.cloneDeep(itemInitialValues);
      if (!isAdmin(this.user))
        this.bcePresetForm.affiliates = [{ name: this.user.name, _id: this.user._id }];
      this.initialForm = {};
    }
  },
  watch: {},
};
</script>
