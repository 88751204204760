<template>
  <TestBox />
</template>

<script>
import TestBox from '@/components/pages/TestLead/TestLead/TestLead.vue';

export default {
  name: 'TestBoxView',
  components: {
    TestBox,
  },
};
</script>
