import { oidRegex } from '@/validators/_utils';
// ipRegex,
import { countryCodes } from '@/consts';
import codes from 'iso-lang-codes';
// import PhoneNumber from 'awesome-phonenumber';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const { isEmail, isStrongPassword } = require('validator');

export default {
  b: (c) => oidRegex.test(c) || oidRegex.test(c._id),
  box: (c) => oidRegex.test(c) || oidRegex.test(c._id),
  campaign: () => true,
  metaCampaign: () => true,
  email: (c) => !!c && typeof c === 'string' && c.length > 5 && isEmail(c),
  password: (c) =>
    !!c &&
    typeof c === 'string' &&
    c.length >= 8 &&
    isStrongPassword(c, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
      returnScore: false,
      pointsPerUnique: 1,
      pointsPerRepeat: 1,
      pointsForContainingLower: 10,
      pointsForContainingUpper: 10,
      pointsForContainingNumber: 10,
      pointsForContainingSymbol: 0,
    }), // true,
  firstname: (c) => !!c && c.length > 0,
  lastname: (c) => !!c && c.length > 0,
  phone: (c, h) => {
    if (!c || !c.length || c.length === 0 || ['0', '+', '-'].includes(c[0])) return false;
    // const pn = new PhoneNumber(`${c[0] === '+' ? '' : '+'}${c}`, f.country.toUpperCase());
    // return pn.isValid();
    try {
      const numberIsValid = phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(c, h.country),
        h.country
      );
      return !!c && numberIsValid;
    } catch (e) {
      return false;
    }
  },
  language: (c) => !!c && typeof c === 'string' && codes.validateLocaleCode(c),
  country: (c) => !!c && countryCodes.includes(c.toUpperCase()),
};
