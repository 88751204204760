<template>
  <div>
    <b-card header="Broker API answer">
      <vue-json-pretty
        v-if="brokerApiAnswer"
        :data="brokerApiAnswer"
        :show-line="true"
        :select-on-click-node="true"
        :highlight-selected-node="true"
        :selectable-type="'single'"
        :show-select-controller="false"
        @click="(path, data) => selectJsonNode(path, data)"
        style="word-wrap: break-word"
      ></vue-json-pretty>
    </b-card>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import $ from 'jquery';
import { showCard } from '@/utils';

export default {
  name: 'BrokerApiAnswerView',
  components: { VueJsonPretty },
  props: { brokerApiAnswer: { type: Object, default: () => {} } },
  data() {
    return {};
  },
  methods: {
    selectJsonNode(path, data) {
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      // if (typeof data !== 'object') {
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
      // }
    },
  },
};
</script>

<style scoped></style>
