<template>
  <b-container fluid>
    <div :class="isValid.form ? 'tab' : 'tab-invalid'">
      <b-form-group label="Campaign" label-for="Campaign" class="my-1">
        <!--    :invalid-feedback="invalidFeedback.test.c"-->
        <!--    :state="isValid.fields.c"-->
        <multiselect
          v-model="testForm.campaign"
          :options="campaignsOptions"
          track-by="name"
          label="name"
          :multiple="false"
          :closeOnSelect="true"
        ></multiselect>
      </b-form-group>
      <b-radio-group v-model="testForm.type" :options="typeOptions" class="my-1"></b-radio-group>
      <b-checkbox v-model="testForm.log" class="my-1">Log</b-checkbox>
      <div class="d-flex justify-content-between my-3">
        <b-button @click="sendTest()" variant="primary" :disabled="!isValid.form || requesting">
          <b-spinner small v-if="requesting"></b-spinner>
          Send Test
        </b-button>
      </div>
    </div>

    <div v-if="lastTestResult !== undefined" class="my-2">
      <h4>Last test result</h4>
      <b-card header="Test Result">
        <vue-json-pretty :data="lastTestResult"></vue-json-pretty>
        <!--        v-if="!!lastTestResult && lastTestResult.brokerApiAnswer"-->
      </b-card>
      <b-card header="Test Data">
        <vue-json-pretty v-if="!!lastTestData" :data="lastTestData"></vue-json-pretty>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import { sendTestPulling } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';
import store from '@/store';
import { formValid, isValid } from '@/validators';
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'TestPulling',
  components: { VueJsonPretty },
  data() {
    return {
      testForm: {
        campaign: undefined,
        type: 'leads',
        log: false,
      },
      typeOptions: ['leads', 'deposits'],
      lastTestResult: undefined,
      lastTestData: undefined,
      backdoor: 0,
    };
  },
  computed: {
    ...mapState(['campaigns', 'requesting', 'token']),
    isValid() {
      this.backdoor;
      const fields = isValid('testpulling', this.testForm, this.testForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    campaignsOptions() {
      return this.campaigns
        .filter(
          (c) => c.brokerApiType === 'UniversalIntegration' && !!c.universalIntegrationSettings
        )
        .map((c) => {
          return { name: c.name, _id: c._id };
        });
    },
  },
  methods: {
    sendTest() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        const testForm = _.cloneDeep(this.testForm);
        testForm.campaign = testForm.campaign._id;
        sendTestPulling(this.token, testForm)
          .then((r) => {
            if (r.status === 200 && !r.data.error) {
              showCard(this, `Test sent. Result: ${r.data}`, 'success');
              this.lastTestResult = r.data;
              this.lastTestData = _.cloneDeep(testForm);
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on sending test`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.$store.commit('make_requesting', { req: false });
          })
          .catch((e) => {
            handleRequestError(this, e, 'Unknown server error on sending test');
            this.$store.commit('make_requesting', { req: false });
          });
      }
    },
    async updateData() {
      await store.dispatch('get_all_resources_one_request', { vm: this });
    },
  },
  mounted() {
    this.updateData();
    // if (this.$route.params.id) this.getBox(this.$route.params.id);
  },
};
</script>

<style scoped></style>
