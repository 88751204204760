<template>
  <div>
    <h5>
      Leads Selection Dropouts for injection {{ name }}
      <b-button @click="getSentToday()" :disabled="requesting" class="m-2">
        <b-spinner small v-if="requesting"></b-spinner>
        <span v-else>🔄</span>
      </b-button>
    </h5>
    <p>Geo {{ countries.join(', ') }}</p>
    <p v-if="requesting" class="my-2">
      <b-spinner small class="mx-1"></b-spinner>
      Requesting data...
    </p>
    <p v-if="error" class="my-2">
      {{ errorText }}
    </p>
    <div v-if="!requesting && !error">
      <!--            style="width: 600px">-->
      <span class="dataspan bluetext mx-1 my-1">Accepted: {{ sentToday.accepted }}</span>
      <span class="dataspan greentext mx-1 my-1">R: {{ sentToday.r }}</span>
      <span class="dataspan orangetext mx-1 my-1">M: {{ sentToday.m }}</span>
      <span class="dataspan redtext mx-1 my-1">Not Accepted: {{ sentToday.not_accepted }}</span>
      <span class="dataspan redtext mx-1 my-1">TECH: {{ sentToday.tech }}</span>
      <span class="dataspan bluetext mx-1 my-1">Demand Now: {{ sentToday.demandThisHour }}</span>
      <span class="dataspan bluetext mx-1 my-1">
        Demand Today: {{ sentToday.demandTodayTotal }}
      </span>
      <p>By Broker</p>
      <ul>
        <li v-for="(b, key) in sentToday.byBroker" :key="key">
          <span>
            <b>{{ b.name }}</b>
            , Copy
            {{ b.campaign ? (b.campaign.name ? b.campaign.name : b.campaign) : 'unknown' }}
          </span>
          <br />
          <span class="dataspan bluetext mx-1 my-1">Accepted: {{ b.accepted }}</span>
          <span class="dataspan greentext mx-1 my-1">R: {{ b.r }}</span>
          <span class="dataspan orangetext mx-1 my-1">M: {{ b.m }}</span>
          <span class="dataspan redtext mx-1 my-1">Not Accepted: {{ b.not_accepted }}</span>
          <span class="dataspan redtext mx-1 my-1">TECH: {{ b.tech }}</span>
          <span class="dataspan bluetext mx-1 my-1">Demand Now: {{ b.demandThisHour }}</span>
          <span class="dataspan bluetext mx-1 my-1">Demand Today: {{ b.demandTodayTotal }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getSentToday } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';
import $ from 'jquery';
import { mapState } from 'vuex';

export default {
  name: 'sentTodayPopup',
  props: {
    _id: { type: String, default: 'unknown' },
    name: { type: String, default: 'unknown' },
    countries: { typs: Array, default: [] },
  },
  data() {
    return {
      requesting: false,
      error: false,
      errorText: 'Unknown error',
      sentToday: {
        _id: null,
        total: 0,
        accepted: 0,
        not_accepted: 0,
        tech: 0,
        r: 0,
        m: 0,
        demandThisHour: 0,
        demandTodayTotal: 0,
        byBroker: [],
      },
    };
  },
  computed: {
    ...mapState(['user', 'token']),
  },
  methods: {
    getSentToday() {
      this.requesting = true;
      this.error = false;
      console.log(this.$props._id);
      if (this.$props._id === 'unknown') return;
      getSentToday(this.token, { _id: this.$props._id })
        .then((r) => {
          this.sentToday = r.data;
          this.error = false;
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown error', 'Error getting Sent Today details');
          this.error = true;
          this.errorText = 'Error getting Sent Today details';
          this.requesting = false;
        });
    },
    selectJsonNode(path, data) {
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
    },
  },
  mounted() {
    this.getSentToday();
  },
};
</script>

<style scoped></style>
