import { cloneDeep } from 'lodash';

export default function handleValidateEvent(
  data,
  key,
  formKey = 'valid',
  avoidBackdoor = false,
  backdoorKey = 'backdoor'
) {
  this[formKey][key] = cloneDeep(data);
  // eslint-disable-next-line no-param-reassign
  if (
    !avoidBackdoor &&
    backdoorKey &&
    backdoorKey !== '' &&
    (this[backdoorKey] || this[backdoorKey] === 0)
  )
    this[backdoorKey] += 1;
  // if (this.emitValidateEvent) this.emitValidateEvent();
}
