<template>
  <div>
    <h5>
      Inspect injection {{ injection && injection.name ? injection.name : 'unknown' }} id {{ _id }}
      <b-button @click="getFullInjection()" :disabled="requesting" class="m-2">
        <b-spinner small v-if="requesting"></b-spinner>
        <span v-else>🔄</span>
      </b-button>
    </h5>
    <b-radio-group v-model="showMode" :options="showModeOptions">Show Mode</b-radio-group>
    <p>
      Geo
      {{
        injection && Array.isArray(injection.countries) ? injection.countries.join(', ') : 'unknown'
      }}
    </p>

    <!--    <div style="width: 506px; heigth: 506px; padding: 10px; overflow: scroll">-->
    <vue-json-pretty
      v-if="!requesting"
      class="my-2"
      :data="injectionToShow"
      :show-line="true"
      :select-on-click-node="true"
      :highlight-selected-node="true"
      :selectable-type="'single'"
      :show-select-controller="false"
      @click="(path, data) => selectJsonNode(path, data)"
      style="max-width: 506px; word-wrap: break-word"
    ></vue-json-pretty>
    <p v-else>
      <b-spinner small class="mx-1"></b-spinner>
      Requesting data...
    </p>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import { mapState } from 'vuex';
import $ from 'jquery';
import { cloneDeep } from 'lodash';
import { getFullInjection } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';

export default {
  name: 'InjectionInspect',
  components: { VueJsonPretty },
  props: {
    _id: { type: String, default: 'unknown' },
  },
  data() {
    return {
      injection: undefined,
      requesting: false,
      showMode: 'full',
      showModeOptions: ['full', 'array length'],
    };
  },
  computed: {
    ...mapState(['user', 'token']),
    injectionToShow() {
      const inj = cloneDeep(this.injection);
      if (!inj) return inj;
      if (this.showMode === 'array length') {
        const keysToReplace = [
          'boxFrom',
          'boxAvoid',
          'campaignsFrom',
          'campaignsAvoid',
          'affiliatesFrom',
          'affiliatesAvoid',
          'statusGroups',
          // 'leadsToSend',
          // 'leadsToSendDraft',
          // 'leadsToSendChecked',
          // 'leadsSentArray',
          // 'leadsDeniedToSend',
          // 'leadsFailedToSend',
          'roundRobinOrderedAmounts',
        ];
        keysToReplace.forEach((key) => {
          inj[key] = Array.isArray(inj[key]) ? inj[key].length : inj[key];
        });
      }
      if (inj.boxTo && Array.isArray(inj.boxTo.campaigns)) {
        for (let i = 0; i < inj.boxTo.campaigns.length; i += 1) {
          inj.boxTo.campaigns[i].countries = Array.isArray(inj.boxTo.campaigns[i].countries)
            ? inj.boxTo.campaigns[i].countries.length
            : inj.boxTo.campaigns[i].countries;
        }
      }
      return inj;
    },
  },
  methods: {
    getFullInjection() {
      this.requesting = true;
      if (this.$props._id === 'unknown') return;
      getFullInjection(this.token, { _id: this.$props._id })
        .then((r) => {
          this.injection = r.data;
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            'Unknown error',
            `Error getting injection ${this.$props._id}`
          );
          this.requesting = false;
          this.injection = [`Error getting injection ${this.$props._id}`];
        });
    },
    selectJsonNode(path, data) {
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
    },
  },
  mounted() {
    this.getFullInjection();
  },
};
</script>

<style scoped></style>
