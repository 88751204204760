<template>
  <InjectionsTable />
</template>

<script>
import InjectionsTable from '@/components/pages/Injections/InjectionsTable/InjectionsTable.vue';

export default {
  name: 'InjectionsView',
  components: {
    InjectionsTable,
  },
};
</script>

<style scoped></style>
