import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { sync } from 'vuex-router-sync';
import { Fragment } from 'vue-fragment';
import Multiselect from 'vue-multiselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import ZoomOnHover from 'vue-zoom-on-hover';
// import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import {
  faLock,
  faClipboard,
  faSyncAlt,
  faTrashAlt,
  faPlus,
  faPlusCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import VueCompositionAPI from '@vue/composition-api';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SideBar from '@/plugins/SidebarPlugin';
import {
  // validateBoolean,
  config,
  parseQuery,
} from '@/utils';
import App from './App.vue';
import store from './store';

import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '@/assets/vue-multiselect.scss';
import '@/assets/media-sizes.scss';
import '@/assets/sass/argon.scss';
// import '@/assets/card-columns-modifier.scss';

// usage: v-click-outside="{ method: 'methodName', args: [bce.field.key, bce.item.bce_index] }"
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign,func-names
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.value.method](...binding.value.args);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.directive('hover-without-child', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign,func-names
    el.hoverWithoutChildEvent = function (event) {
      // console.log('el', el);
      // here I check that click was outside the el and his children
      if (
        el === event.target ||
        (!event.target.classList.contains('hover-without-child-target') &&
          el.contains(event.target) &&
          !Array.from(el.children).some(
            (c) =>
              (c.contains(event.target) && c.classList.contains('hover-without-child-target')) ||
              (Array.from(c.children).length > 0 &&
                Array.from(c.children).some(
                  (cc) =>
                    cc.contains(event.target) && cc.classList.contains('hover-without-child-target')
                ))
          ))
      ) {
        vnode.context[binding.value.method](true);
      }
      // || el.contains(event.target)
      // and if it did, call method provided in attribute value
      else {
        vnode.context[binding.value.method](false);
      }
    };
    // eslint-disable-next-line no-param-reassign
    // el.hoverMouseOutEvent = function (event) {
    //   // here I check that click was outside the el and his children
    //   if (el === event.target) {
    //     vnode.context[binding.value.method](false);
    //   }
    //   // || el.contains(event.target)
    //   // and if it did, call method provided in attribute value
    // };
    document.body.addEventListener('mouseover', el.hoverWithoutChildEvent);
    // document.body.addEventListener('mouseout', el.hoverMouseOutEvent);
  },
  unbind(el) {
    document.body.removeEventListener('mouseover', el.hoverWithoutChildEvent);
    // document.body.removeEventListener('mouseout', el.hoverMouseOutEvent);
  },
});

library.add(faLock, faClipboard, faSyncAlt, faTrashAlt, faPlusCircle, faPlus, faTimes);
Vue.component('font-awesome-icon', FontAwesomeIcon);

require('../node_modules/bootstrap/dist/css/bootstrap.css');

const usync = sync(store, router);
usync();

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.component('fragment', Fragment);
Vue.component('multiselect', Multiselect);
Vue.use(ZoomOnHover);
Vue.use(SideBar);
Vue.prototype.$rent = config.rent;
Vue.prototype.getCellRouterLink = (
  ctx,
  field,
  value,
  isArray = false,
  initial,
  preserveQuery = false
) => {
  const query = parseQuery(ctx, false);
  Object.keys(query).forEach((key) => {
    if (initial[key] === query[key]) {
      delete query[key];
    }
    if (!preserveQuery) {
      if (key !== 'from' && key !== 'to') {
        delete query[key];
      }
    }
  });
  query[field] = isArray ? [value] : value;
  if (query.metaCampaigns) {
    query.metaCampaigns = JSON.stringify(query.metaCampaigns);
  }
  if (query.workers) {
    query.workers = JSON.stringify(query.workers);
  }
  return { path: '/crm', query };
};

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
