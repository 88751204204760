<template>
  <PSPTable />
</template>

<script>
import PSPTable from '@/components/pages/PSP/PSPTable.vue';

export default {
  name: 'PSPTableView',
  components: {
    PSPTable,
  },
};
</script>
