<template>
  <b-container fluid>
    <b-row class="my-3" no-gutters>
      <b-col cols="12" lg="2" class="my-lg-0 my-1">
        <h2>Calls</h2>
      </b-col>
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="4"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="3" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <JsonExcel
          class="m-2"
          :data="items"
          :fields="fieldsToExport"
          type="csv"
          :stringifyLongNum="true"
          v-if="iAmOtherLevelTrustedUser"
        >
          <b-button variant="outline-primary">📥CSV</b-button>
        </JsonExcel>
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
      </b-col>
    </b-row>
    <b-table
      class="table mb-3"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(_id)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>
      <template #cell(workerID)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </b-button-group>
      </template>

      <template #cell(ftdMade)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'secondary'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #row-details="row">
        <b-card-group columns>
          <b-card>
            <b-card-title>Description</b-card-title>
            <b-card-text>{{ row.item.description }}</b-card-text>
          </b-card>
        </b-card-group>
        <p>_id: {{ row.item._id }}</p>
      </template>
    </b-table>
    <b-row class="mb-3" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import JsonExcel from 'vue-json-excel';
import {
  countryOptions,
  dateFormatOptions,
  OTHER_LEVEL_TRUSTED_USERS,
  TRUSTED_USERS,
} from '@/consts';
// import _ from 'lodash';
import { mapState } from 'vuex';
import { handleRequestError, tableFormatterDate, tableFormatterId } from '@/utils';
import _ from 'lodash';
import { getCalls } from '@/utils/api';

export default {
  name: 'ActionsCallsTable',
  components: { JsonExcel },
  props: {
    action_id: { type: String, default: '' },
  },
  data() {
    return {
      dateFormatOptions,
      countryOptions,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      filterIncludedFields: [
        '_id',
        'workerID',
        'workerName',
        'actionEmail',
        'actionPhone',
        'pspNumber',
        'description',
      ],
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 500, text: '500' },
        { value: 1000, text: '1000' },
      ],
      lastTimeToProcess: 0,
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      fields: [
        { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
        { key: 'date', sortable: true, formatter: (v) => tableFormatterDate(v) },
        { key: 'controls' },
        { key: 'workerID', sortable: true, formatter: (v) => tableFormatterId(v) },
        { key: 'workerName', sortable: true },
        { key: 'actionEmail', sortable: true },
        { key: 'actionPhone', sortable: true },
        { key: 'ftdMade', sortable: true },
        { key: 'pspNumber', sortable: true },
        { key: 'description', sortable: true },
      ],
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'token', 'requesting']),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    fieldsToExport() {
      return this.iAmOtherLevelTrustedUser
        ? {
            id: '_id',
            Date: 'date',
            'Worker ID': 'worker._id',
            'Worker Name': 'worker.name',
            'Action Email': 'action.leadContactInfo.email',
            'Action Phone': 'action.leadContactInfo.phone',
            'FTD Made': 'ftdMade',
            Description: 'description',
          }
        : {};
    },
  },
  methods: {
    updateData() {
      if (this.action_id.length === 0) return;
      this.$store.commit('make_requesting', { req: true });
      const options = {
        action: this.action_id,
        page: this.currentPage,
        itemsPerPage: this.perPage,
      };
      getCalls(this.token, options)
        .then((r) => {
          this.$store.commit('make_requesting', { req: true });
          const { data } = r;
          console.log('got data; items length', data.items.length);
          this.items = _.cloneDeep(data.items);
          console.log('put items in table; table items length', this.items.length);
          if (this.currentPage !== data.page) this.currentPage = data.page;
          if (this.perPage !== data.itemsPerPage) this.perPage = data.itemsPerPage;
          this.totalRows = data.totalRows;
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on retrieving Calls for action ${this.action_id}`
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>

<style scoped></style>
