<template>
  <b-container>
    <b-form>
      <div class="my-4" style="display: block">
        <h2 class="mr-3" style="display: inline; vertical-align: middle">
          {{ $route.path === '/create-user' ? 'Create User' : 'Edit User' }}
        </h2>
        <b-button
          v-if="$route.path !== '/create-user'"
          @click="createNewUser()"
          style="display: inline; vertical-align: middle"
        >
          Create new
        </b-button>
        <b-button
          v-if="userFormAffiliate && userForm._id"
          v-b-modal="`modal-affiliate-api-docs-${userForm._id}`"
          style="display: inline; vertical-align: middle"
          variant="info"
        >
          Affiliate API Docs
        </b-button>
        <b-modal
          v-if="userFormAffiliate && userForm._id"
          :id="`modal-affiliate-api-docs-${userForm._id}`"
          :ref="`modal-affiliate-api-docs-${userForm._id}`"
          centered
          ok-only
          ok-title="Close"
          size="giant"
          scrollable
          :title="`Affiliate API Integration Docs`"
        >
          <affiliate-api-docs :aff-id="userForm._id"></affiliate-api-docs>
        </b-modal>
      </div>
      <input-with-help help-text="Name is just a short name of the user">
        <b-form-group
          class="my-2"
          label="Name"
          label-for="user-name"
          :invalid-feedback="invalidFeedback.user.name"
          :state="isValid.fields.name"
        >
          <b-form-input
            id="user-name"
            type="text"
            v-model="userForm.name"
            :state="isValid.fields.name"
          ></b-form-input>
        </b-form-group>
      </input-with-help>

      <input-with-help help-text="User's email that will be used for authorization">
        <b-form-group
          class="my-2"
          label="Email"
          label-for="user-email"
          :invalid-feedback="invalidFeedback.user.email"
          :state="isValid.fields.email"
        >
          <!--            <h6 class="my-2">Email</h6>-->
          <b-form-input
            id="user-email"
            :state="isValid.fields.email"
            type="text"
            :disabled="userEmail && userEmail !== '' && userForm.email === userEmail"
            v-model="userForm.email"
          ></b-form-input>
        </b-form-group>
      </input-with-help>
      <input-with-help
        help-text="Strong password is generated and will be hidden after user created"
      >
        <b-form-group
          class="my-2"
          label="Password"
          label-for="user-password"
          :invalid-feedback="invalidFeedback.user.password"
          :state="isValid.fields.password"
        >
          <password
            v-on:input="
              userForm.password = $event;
              backdoor += 1;
            "
            :size="Math.round(32 + Math.random() * 32)"
            :auto="!$route.params.id"
            :type="passwordFieldInactive ? 'password' : 'text'"
            :placeholder="passwordFieldInactive ? '********************' : 'Password'"
            class=""
            nomanual
          ></password>
        </b-form-group>
      </input-with-help>
      <h6 class="mt-4">Contacts</h6>
      <input-with-help
        help-text="User's contact information will be shown to the manager. Skype username and/or telegram id"
      >
        <b-form-group
          v-if="
            user._id === userForm._id ||
            !(initialForm && initialForm.messenger && initialForm.messenger.skype)
          "
          class="my-2"
          label="Skype"
          label-for="user-messenger-skype"
          :invalid-feedback="invalidFeedback.user.messenger.skype"
          :state="isValid.fields.messenger.skype"
        >
          <!--            <h6 class="mb-2">Skype</h6>-->
          <b-form-input
            id="user-messenger-skype"
            :state="isValid.fields.messenger.skype"
            type="text"
            v-model="userForm.messenger.skype"
          ></b-form-input>
        </b-form-group>
        <p v-else>Skype: {{ userForm.messenger.skype }}</p>
        <b-form-group
          v-if="
            user._id === userForm._id ||
            !(initialForm && initialForm.messenger && initialForm.messenger.telegram)
          "
          class="my-2"
          label="Telegram"
          label-for="user-messenger-telegram"
          :invalid-feedback="invalidFeedback.user.messenger.telegram"
          :state="isValid.fields.messenger.telegram"
        >
          <!--            <h6 class="mb-2">Telegram</h6>-->
          <b-form-input
            id="user-messenger-telegram"
            :state="isValid.fields.messenger.telegram"
            type="text"
            v-model="userForm.messenger.telegram"
          ></b-form-input>
        </b-form-group>
        <p v-else>Telegram: {{ userForm.messenger.telegram }}</p>
      </input-with-help>
      <!--        </b-col>-->
      <!--        <b-col class="d-flex flex-column align-items-center">-->
      <!--          user && user.role && user.role === 'admin'-->
      <input-with-help
        v-if="isAdmin(user)"
        class="mt-4"
        help-text="The type of the user. Affiliate brings leads and can only see stats. Managers control traffic flows from Affiliates. Admins have access to all the system's functions including Injections, Users management etc."
      >
        <b-form-group
          class="my-2"
          label="Type"
          label-for="user-type"
          :invalid-feedback="invalidFeedback.user.type"
          :state="isValid.fields.type"
        >
          <!--          :valid-feedback="validFeedback"-->
          <!--            <h6 class="my-2">Type</h6>-->
          <b-form-radio-group
            id="user-type"
            v-model="userForm.type"
            :options="userTypeOptions"
            name="type-radio-options"
            :state="isValid.fields.type"
            stacked
          ></b-form-radio-group>
          <!--          :disabled="userFormAdmin && userForm._id"-->
        </b-form-group>
      </input-with-help>
      <div v-if="isAdmin(user) && userForm && userForm.type">
        <!--          user && user.role && user.role === 'admin'-->
        <h4 v-if="userFormManager">Resources</h4>
        <h6 class="my-2" v-if="userFormAdmin">User is admin and has acces to all resources</h6>
        <input-with-help
          v-if="userFormManager && !userFormAdmin"
          help-text="Campaigns that User manages or can bring traffic from the affiliates"
        >
          <b-form-group
            v-if="userFormManager && !userFormAdmin"
            class="my-2"
            label="Assigned Campaigns"
            label-for="user-campaigns"
            :invalid-feedback="invalidFeedback.user.campaigns"
            :state="isValid.fields.campaigns"
          >
            <!--            <h6 class="my-2" v-if="userFormManager && !userFormAdmin">-->
            <!--              Campaigns that user manages-->
            <!--            </h6>-->
            <multiselect
              v-model="userForm.campaigns"
              :options="campaignsOptions"
              track-by="name"
              label="name"
              :closeOnSelect="false"
              :multiple="true"
            ></multiselect>
          </b-form-group>
        </input-with-help>
        <input-with-help
          v-if="userFormManager && !userFormAdmin"
          help-text="Boxes that the User manages"
        >
          <b-form-group
            v-if="userFormManager && !userFormAdmin"
            class="my-2"
            label="Assigned Boxes"
            label-for="user-boxes"
            :invalid-feedback="invalidFeedback.user.boxes"
            :state="isValid.fields.boxes"
          >
            <!--            <h6 class="my-2" v-if="userFormManager && !userFormAdmin">Boxes</h6>-->
            <multiselect
              id="user-boxes"
              :state="isValid.fields.boxes"
              v-model="userForm.boxes"
              :options="boxesOptions"
              track-by="name"
              label="name"
              :closeOnSelect="false"
              :multiple="true"
            ></multiselect>
          </b-form-group>
        </input-with-help>

        <input-with-help
          v-if="(userFormManager || userFormMasterAffiliate) && !userFormAdmin"
          help-text="Affiliates that this User manages"
        >
          <b-form-group
            v-if="(userFormManager || userFormMasterAffiliate) && !userFormAdmin"
            class="my-2"
            label="Assigned Affiliates"
            label-for="user-affiliates"
            :invalid-feedback="invalidFeedback.user.affiliates"
            :state="isValid.fields.affiliates"
          >
            <!--            <h6 class="my-2" v-if="userFormManager && !userFormAdmin">Affiliates</h6>-->
            <multiselect
              id="user-affiliates"
              :state="isValid.fields.affiliates"
              v-model="userForm.affiliates"
              :options="affiliatesOptions"
              track-by="name"
              label="name"
              :closeOnSelect="false"
              :multiple="true"
            ></multiselect>
          </b-form-group>
        </input-with-help>

        <input-with-help
          v-if="userFormAffiliate"
          help-text="Manager who controls this Affiliate's traffic flows"
        >
          <b-form-group
            v-if="userFormAffiliate"
            class="my-2"
            label="Manager"
            label-for="user-manager"
            :invalid-feedback="invalidFeedback.manager"
            :state="isValid.fields.manager"
          >
            <!--            <h6 class="my-2" v-if="userFormAffiliate">Manager</h6>-->
            <b-form-select
              id="user-manager"
              :state="isValid.fields.manager"
              v-if="userFormAffiliate"
              v-model="userForm.manager"
              :options="managersOptions"
              text-field="name"
              value-field="_id"
            ></b-form-select>
          </b-form-group>
        </input-with-help>
      </div>
      <!--      </b-row>-->

      <b-form-checkbox
        v-if="user.email === 'blacklight@default.gg' && !$rent"
        v-model="userForm.mother"
        class="my-2"
      >
        Mother
      </b-form-checkbox>

      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.seeLeadsBasedOnCampaigns"
        class="my-2"
      >
        See Leads Based On Campaigns
      </b-form-checkbox>

      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.ba"
        class="my-2"
        :disabled="userForm.cs || userForm.bs"
      >
        Can See Broker Accepted in Lead and Pulling Responses
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.cs"
        class="my-2"
        :disabled="userForm.ba"
      >
        Care Status Good
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.bs"
        class="my-2"
        :disabled="userForm.ba"
      >
        Care Status Bad
      </b-form-checkbox>
      <input-with-help
        v-if="!userFormAdmin && !$rent"
        help-text="Whether the user has access to the info about Final Receivers of the leads"
        size="0.9"
      >
        <b-form-checkbox v-if="isAdmin(user)" v-model="userForm.extendedFieldsAccess" class="my-2">
          Access to Final Receiver
        </b-form-checkbox>
      </input-with-help>
      <b-form-checkbox
        v-if="isAdmin(user) && iAmTrustedUser && !$rent"
        v-model="userForm.maff"
        class="my-2"
      >
        MAffiliate
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && iAmOtherLevelTrustedUser && !$rent"
        v-model="userForm.canSendMAutomatically"
        class="my-2"
      >
        Can Send Ms Automatically
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && iAmOtherLevelTrustedUser && !$rent"
        v-model="userForm.canSendCAutomatically"
        class="my-2"
      >
        Can Send Callback Automatically
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.canUploadLeads"
        class="my-2"
      >
        Can Upload Leads
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.campaignsAccess"
        class="my-2"
      >
        Campaigns Access: Can Send Ms Manually
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.rtcMandatoryFlow"
        class="my-2"
      >
        RTC Mandatory Flow
      </b-form-checkbox>
      <b-form-checkbox
        v-if="isAdmin(user) && !$rent"
        v-model="userForm.rtcMandatoryFlowPSP"
        class="my-2"
      >
        RTC Mandatory Flow PSP
      </b-form-checkbox>
      <b-form-checkbox v-if="isAdmin(user) && !$rent" v-model="userForm.hasWorkers" class="my-2">
        Has Workers
      </b-form-checkbox>
      <b-form-checkbox v-if="isAdmin(user) && !$rent" v-model="userForm.trader" class="my-2">
        Trader
      </b-form-checkbox>
      <input-with-help
        v-if="
          isAdmin(user) &&
          user.email === 'blacklight@default.gg' &&
          userFormManager &&
          !userFormAdmin
        "
        help-text="If this User has access to the Integration tools"
        size="0.9"
      >
        <b-form-checkbox
          v-if="
            isAdmin(user) &&
            user.email === 'blacklight@default.gg' &&
            userFormManager &&
            !userFormAdmin
          "
          v-model="userForm.integrator"
          class="my-2"
        >
          Integrator
        </b-form-checkbox>
      </input-with-help>

      <input-with-help
        v-if="!userFormAffiliate"
        help-text="Which Resources will be hidden for the User. The User will still have access to them, but will not see them in the corresponding tables"
      >
        <b-form-group
          class="my-2"
          label="Hidden Campaigns"
          label-for="user-hidden-campaigns"
          :invalid-feedback="invalidFeedback.user.hiddenCampaigns"
          :state="isValid.fields.hiddenCampaigns"
        >
          <!--            <h6 class="my-2" v-if="userFormManager && !userFormAdmin">Affiliates</h6>-->
          <multiselect
            id="user-hidden-campaigns"
            :state="isValid.fields.hiddenCampaigns"
            v-model="userForm.hiddenCampaigns"
            :options="campaignsOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
        </b-form-group>

        <b-form-group
          class="my-2"
          label="Hidden Boxes"
          label-for="user-hidden-boxes"
          :invalid-feedback="invalidFeedback.user.hiddenBoxes"
          :state="isValid.fields.hiddenBoxes"
        >
          <!--            <h6 class="my-2" v-if="userFormManager && !userFormAdmin">Affiliates</h6>-->
          <multiselect
            id="user-hidden-boxes"
            :state="isValid.fields.hiddenBoxes"
            v-model="userForm.hiddenBoxes"
            :options="boxesOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
        </b-form-group>
      </input-with-help>

      <div v-if="!$rent">
        <b-form-checkbox
          v-if="isAdmin(user)"
          v-model="userForm.restrictSendMAutomaticallyOnlyToSelectedCampaigns"
          class="my-2"
        >
          Send Ms Automatically ONLY to Selected Campaigns
        </b-form-checkbox>
        <b-form-group
          class="my-2"
          label="Select Send Ms Automatically only to Campaigns"
          label-for="user-hidden-boxes"
          :invalid-feedback="invalidFeedback.user.hiddenBoxes"
          :state="isValid.fields.hiddenBoxes"
        >
          <!--            <h6 class="my-2" v-if="userFormManager && !userFormAdmin">Affiliates</h6>-->
          <multiselect
            id="user-campaigns-to-restrict-send-ms-automatically"
            :state="isValid.fields.hiddenBoxes"
            v-model="userForm.campaignsToRestrictSendMsAutomatically"
            :options="campaignsToSendMsOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
        </b-form-group>
        <div v-if="iAmOtherLevelTrustedUser">
          <b-form-group
            class="my-2"
            label="Can select GEOs to work"
            label-for="campaign-broker"
            :state="isValid.fields.m_allowedCountries"
            :invalid-feedback="invalidFeedback.campaign.countries"
          >
            <multiselect
              v-model="userForm.m_allowedCountries"
              :options="countryOptions"
              :closeOnSelect="false"
              track-by="value"
              @search-change="countryMultiSelectSearch"
              :multiple="true"
              :custom-label="countryWithCode"
              :state="isValid.fields.m_allowedCountries"
            ></multiselect>
          </b-form-group>
          <b-button class="mx-2" @click="selectAllCountries()">Select all geos</b-button>
          <b-button class="mx-2" @click="clearCountries()">Clear geos</b-button>
        </div>
      </div>

      <div class="d-flex justify-content-between my-2">
        <b-button @click="saveUser()" variant="primary" :disabled="!isValid.form || requesting">
          <b-spinner small v-if="requesting"></b-spinner>
          {{ $route.params.id ? 'Save User' : 'Create User' }}
        </b-button>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import Password from '@/components/shared/Password.vue';
import { getUser, saveUser } from '@/utils/api';
import {
  showCard,
  isAffiliate,
  isMasterAffiliate,
  isManager,
  isAdmin,
  handleRequestError,
  deleteUnchangedFields,
  countryMultiSelectSearch,
  castCountriesFromServer,
} from '@/utils';
import {
  actionTypeOptions,
  brokerApiTypes,
  userTypeOptions,
  validFeedback,
  invalidFeedback,
  TRUSTED_USERS,
  OTHER_LEVEL_TRUSTED_USERS,
  countryOptions,
} from '@/consts';
import { isValid, formValid } from '@/validators';
import _ from 'lodash';
import store from '@/store';
import InputWithHelp from '@/components/shared/InputWithHelp.vue';
import AffiliateApiDocs from '@/components/shared/AffiliateApiDocs/AffiliateApiDocs.vue';

const itemInitialValues = {
  _id: undefined,
  email: undefined,
  password: undefined,
  name: undefined,
  campaigns: [],
  boxes: [],
  affiliates: [],
  manager: undefined,
  type: 'affiliate',
  messenger: { skype: undefined, telegram: undefined },
  mother: undefined,
  seeLeadsBasedOnCampaigns: false,
  ba: undefined,
  cs: undefined,
  bs: undefined,
  extendedFieldsAccess: false,
  campaignsAccess: false,
  maff: false,
  canSendMAutomatically: false,
  canSendCAutomatically: false,
  restrictSendMAutomaticallyOnlyToSelectedCampaigns: false,
  canUploadLeads: false,
  hasWorkers: false,
  hiddenBoxes: [],
  hiddenCampaigns: [],
  campaignsToRestrictSendMsAutomatically: [],
  rtcMandatoryFlow: false,
  rtcMandatoryFlowPSP: false,
  m_allowedCountries: [],
  trader: false,
  integrator: false,
};

// const itemInitialValues = {
//   affiliate: 'Affilaite 1',
//   countries: [],
//   hourLimitFrom: '',
//   hourLimitTo: '',
//   days: [],
//   priority: '',
//   weight: '',
//   capType: 'impression',
//   dailyCap: '',
// };

export default {
  name: 'CreateUser',
  components: { InputWithHelp, Password, AffiliateApiDocs },
  data() {
    return {
      userHasPassword: false,
      validFeedback,
      countryOptions,
      invalidFeedback,
      backdoor: 0,
      brokerApiTypes,
      actionTypeOptions,
      userTypeOptions,
      affiliate: 'Affiliate 1',
      userForm: _.cloneDeep(itemInitialValues),
      initialForm: {},
      userEmail: undefined,
      // items: [itemInitialValues],
      // affiliatesOptions: [
      //   { value: 'Affilaite 1', text: 'Affilaite 1' },
      //   { value: 'Affilaite 2', text: 'Affilaite 2' },
      //   { value: 'Affilaite 3', text: 'Affilaite 3' },
      //   { value: 'Affilaite 4', text: 'Affilaite 4' },
      //   { value: 'Affilaite 5', text: 'Affilaite 5' },
      //   { value: 'Affilaite 6', text: 'Affilaite 6' },
      // ],
    };
  },
  computed: {
    ...mapState(['token', 'user', 'affiliates', 'managers', 'boxes', 'campaigns', 'requesting']),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    passwordFieldInactive() {
      return (
        this.$route.params.id &&
        this.userHasPassword &&
        !((isManager(this.user) && isAffiliate(this.userForm)) || isAdmin(this.user))
      );
    },
    isValid() {
      this.backdoor;
      // console.log(this.userForm.password);
      const fields = isValid('user', this.userForm, {
        form: this.userForm,
        hasPassword: this.userHasPassword,
      });
      // console.log('Fields:', fields);
      // console.log('Form valid:', formValid(fields));
      // console.log(this.userForm);
      return {
        fields,
        form: formValid(fields),
      };
      // return isValid('user', this.userForm);
    },
    userFormAffiliate() {
      this.backdoor;
      // console.log('userFormAffiliate');
      return isAffiliate(this.userForm);
    },
    userFormManager() {
      this.backdoor;
      // console.log('userFormManager');
      return isManager(this.userForm);
    },
    userFormMasterAffiliate() {
      this.backdoor;
      // console.log('userFormMasterAffiliate');
      return isMasterAffiliate(this.userForm);
    },
    userFormAdmin() {
      this.backdoor;
      // console.log('userFormAdmin');
      return isAdmin(this.userForm);
    },
    affiliatesOptions() {
      this.backdoor;
      // console.log('affiliatesOptions');
      return (
        this.affiliates // users
          // .filter((u) => isAffiliate(u))
          .map((a) => {
            return { name: a.name, _id: a._id };
          })
      );
    },
    managersOptions() {
      this.backdoor;
      // console.log('managersOptions');
      return this.managers.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    campaignsOptions() {
      this.backdoor;
      // console.log('campaignsOptions');
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    campaignsToSendMsOptions() {
      this.backdoor;
      return this.campaigns
        .filter((c) => c.active && c.pulling)
        .map((c) => {
          return { name: c.name, _id: c._id };
        });
    },
    boxesOptions() {
      this.backdoor;
      // console.log('boxesOptions');
      return this.boxes.map((b) => {
        return { name: b.name, _id: b._id };
      });
    },
  },
  methods: {
    countryMultiSelectSearch,
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    selectAllCountries() {
      this.userForm.m_allowedCountries = _.cloneDeep(countryOptions);
    },
    clearCountries() {
      this.userForm.m_allowedCountries = _.cloneDeep(itemInitialValues.m_allowedCountries);
    },
    createNewUser() {
      // this.userForm = _.cloneDeep(itemInitialValues);
      // this.initialForm = {};
      // this.updateData();
      this.$router.push('/create-user');
    },
    saveUser() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        let toSave = _.cloneDeep(this.userForm);
        toSave.affiliates = toSave.affiliates.map((c) => c._id);
        toSave.boxes = toSave.boxes.map((c) => c._id);
        toSave.campaigns = toSave.campaigns.map((c) => c._id);
        toSave.hiddenBoxes = toSave.hiddenBoxes.map((c) => c._id);
        toSave.hiddenCampaigns = toSave.hiddenCampaigns.map((c) => c._id);
        toSave.campaignsToRestrictSendMsAutomatically = toSave.campaignsToRestrictSendMsAutomatically.map(
          (c) => c._id
        );
        toSave.m_allowedCountries = toSave.m_allowedCountries.map((c) => c.value);
        toSave = deleteUnchangedFields(this.initialForm, this.userForm, toSave);
        saveUser(this.token, toSave)
          .then(async (r) => {
            if (r.status === 200) {
              showCard(this, `${r.data.name} user saved`, 'success');
              // try {
              // await this.$store.dispatch('get_users', { vm: this }); // getUsers();
              // eslint-disable-next-line no-empty
              // } catch (e) {}
              try {
                await this.$router.push(`/user/edit/${r.data._id}`);
                // eslint-disable-next-line no-empty
              } catch (e) {}
              // this.castUserToForm(r.data);
              // this.backdoor += 1;
              // this.$router.push('/users');
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving user`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving user`);
          });
        this.$store.commit('make_requesting', { req: false });
      }
    },
    getUser(_id) {
      console.log('Getting user', _id);
      // const self = this;
      // this.userEmail = 'ghjfkhgkjh@mail.cc';
      getUser(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castUserToForm(r.data);
            this.backdoor += 1;
            // const casted = castUserToForm(r.data);
            // this.userForm = casted.userForm;
            // this.userEmail = casted.userEmail;
            // this.userHasPassword = casted.userHasPassword;
            // }
            // else {
            //   const cardError = r.data.error || `${r.status}: Unknown error on getting user`;
            //   showCard(
            //     this,
            //     cardError,
            //     r.status === 500 ? 'danger' : 'warning',
            //     'Error getting user'
            //   );
            // }
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting campaign`;
            showCard(
              this,
              cardError,
              r.status === 500 ? 'danger' : 'warning',
              'Error getting campaign'
            );
          }
        })
        .catch((e) => {
          console.error(e);
          const cardError = e || 'Unknown server error on getting user';
          showCard(this, cardError.message, 'danger', 'Error getting user');
        });
    },
    castUserToForm(user) {
      // console.log('Casting user to form:', user);
      const initial = _.cloneDeep(itemInitialValues);
      // cast user to edit form
      // prettier-ignore
      this.userEmail = user.email;
      this.userForm = {
        _id: user._id ? user._id : initial._id,
        email: user.email ? user.email : initial.email,
        password: initial.password,
        name: user.name,
        campaigns: user.campaigns ? user.campaigns : initial.campaigns,
        boxes: user.boxes ? user.boxes : initial.boxes,
        affiliates: user.affiliates ? user.affiliates : initial.affiliates,
        manager: user.manager
          ? user.manager._id
            ? user.manager._id
            : user.manager
          : initial.manager,
        type: user.type ? user.type : initial.type,
        messenger: user.messenger
          ? {
              skype: user.messenger.skype ? user.messenger.skype : initial.messenger.skype,
              telegram: user.messenger.telegram
                ? user.messenger.telegram
                : initial.messenger.telegram,
            }
          : initial.messenger,
        mother: user.mother,
        ba: user.ba ? user.ba : initial.ba,
        cs: user.cs ? user.cs : initial.cs,
        bs: user.bs ? user.bs : initial.bs,
        extendedFieldsAccess: user.extendedFieldsAccess
          ? user.extendedFieldsAccess
          : initial.extendedFieldsAccess,
        maff: user.maff ? user.maff : initial.maff,
        canSendMAutomatically: user.canSendMAutomatically
          ? user.canSendMAutomatically
          : initial.canSendMAutomatically,
        canSendCAutomatically: user.canSendCAutomatically
          ? user.canSendCAutomatically
          : initial.canSendCAutomatically,
        restrictSendMAutomaticallyOnlyToSelectedCampaigns: user.restrictSendMAutomaticallyOnlyToSelectedCampaigns
          ? user.restrictSendMAutomaticallyOnlyToSelectedCampaigns
          : initial.restrictSendMAutomaticallyOnlyToSelectedCampaigns,
        canUploadLeads: user.canUploadLeads ? user.canUploadLeads : initial.canUploadLeads,
        campaignsAccess: user.campaignsAccess ? user.campaignsAccess : initial.campaignsAccess,
        hasWorkers: user.hasWorkers ? user.hasWorkers : initial.hasWorkers,
        trader: user.trader ? user.trader : initial.trader,
        integrator: user.integrator ? user.integrator : initial.integrator,
        hiddenBoxes: user.hiddenBoxes ? user.hiddenBoxes : initial.hiddenBoxes,
        hiddenCampaigns: user.hiddenCampaigns ? user.hiddenCampaigns : initial.hiddenCampaigns,
        campaignsToRestrictSendMsAutomatically: user.campaignsToRestrictSendMsAutomatically
          ? user.campaignsToRestrictSendMsAutomatically
          : initial.campaignsToRestrictSendMsAutomatically,
        rtcMandatoryFlow: user.rtcMandatoryFlow ? user.rtcMandatoryFlow : initial.rtcMandatoryFlow,
        rtcMandatoryFlowPSP: user.rtcMandatoryFlowPSP
          ? user.rtcMandatoryFlowPSP
          : initial.rtcMandatoryFlowPSP,
        m_allowedCountries: castCountriesFromServer(user.m_allowedCountries),
      };
      this.userHasPassword = user.password;
      this.initialForm = _.cloneDeep(this.userForm);
    },
    async updateData() {
      // await this.$store.dispatch('get_all_resources', { vm: this });
      await store.dispatch('get_all_resources_one_request', { vm: this });
      if (
        this.$route.path === '/create-user' &&
        isManager(this.user) &&
        !isAdmin(this.user) &&
        !this.$route.params.id
      ) {
        this.userForm.manager = this.user._id;
      }
      // await this.$store.dispatch('get_boxes', { vm: this });
      // await this.$store.dispatch('get_affiliates', { vm: this });
      // await this.$store.dispatch('get_managers', { vm: this });
      // this.backdoor += 1;
    },
    isAdmin(u) {
      return isAdmin(u);
    },
    isManager(u) {
      return isManager(u);
    },
    isAffiliate(u) {
      return isAffiliate(u);
    },
  },
  mounted() {
    // console.log('Params id:', this.$route.params.id);
    // console.log(this.userForm);
    this.updateData();
    if (this.$route.params.id) this.getUser(this.$route.params.id);
    // console.log('User id:', this.user._id);
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log(to);
  //   console.log(from);
  //   this.updateData();
  //   if (this.$route.params.id) this.getUser(this.$route.params.id);
  //   next();
  // },
  watch: {
    $route() {
      // this will be called any time the route changes
      console.log(this.$route.path);
      this.updateData();
      if (this.$route.params.id) this.getUser(this.$route.params.id);
      else {
        this.userForm = _.cloneDeep(itemInitialValues);
        this.initialForm = {};
      }
    },
  },
};
</script>
