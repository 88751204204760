<template>
  <b-form inline class="my-4" v-if="form.length > 0">
    <div v-for="(e, i2) in form" :key="i2" class="array-item-inline">
      <b-form inline>
        <b-input v-model="form[i2]" @input="handleInput" :state="isValid.fields[i2]"></b-input>
        <b-button @click="removePathElement(i2)" variant="danger">
          <font-awesome-icon icon="times"></font-awesome-icon>
        </b-button>
        <b-button @click="addPathElement(i2)" variant="info">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </b-button>
      </b-form>
    </div>
  </b-form>
  <div v-else class="my-4">
    <b-button @click="addPathElement(1)" variant="info">
      <font-awesome-icon icon="plus"></font-awesome-icon>
    </b-button>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import '@/assets/form.scss';
// import handleValidateEvent from '@/utils/handleValidateEvent';
import { formValid } from '@/validators';

export default {
  name: 'PathForm',
  props: {
    arr: { type: Array, default: () => [] },
    disallowEmpty: { type: Boolean, default: false },
  },
  data() {
    return { form: cloneDeep(this.$props.arr ? this.$props.arr : []), backdoor: 0 };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = this.form.map(
        (e) => typeof e === 'string' && e.length > 0 && /^[a-zA-Z0-9\-_]*$/.test(e)
      );
      const form = (!this.disallowEmpty || fields.length > 0) && formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
  },
  methods: {
    addPathElement(i2) {
      console.log('addPathElement', i2);
      if (this.form) {
        if (i2 < this.form.length) this.form.splice(i2 + 1, 0, '');
        else this.form.push('');
      }
      this.backdoor += 1;
      console.log('addPathElement result', this.form);
      this.emitEditEvent();
    },
    removePathElement(i2) {
      console.log('removePathElement', i2);
      if (this.form) this.form.splice(i2, 1);
      console.log('removePathElement result', this.form);
      this.backdoor += 1;
      this.emitEditEvent();
    },
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    handleInput() {
      this.backdoor += 1;
      this.emitEditEvent();
    },
    // handleValidateEvent,
    emitValidateEvent() {
      // console.log(`this.$emit('validate', ${this.isValid.form});`);
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
