/* eslint-disable no-param-reassign */
import { startCase } from '@/utils/string';
import _, { cloneDeep, isEqual } from 'lodash';
import { saveSelectedFields } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils/index';

// eslint-disable-next-line import/prefer-default-export
export function tableFieldsOptions(ctx) {
  console.time('tableFieldsOptions');
  const tfo = ctx.userAvailableFields.map((f) => {
    const key = f.key ? f.key : f;
    return {
      value: key,
      text: f.label ? f.label : startCase(key),
      disabled: key === '_id',
    };
  });
  ctx.takeDefaultTableFields();
  console.timeEnd('tableFieldsOptions');
  return tfo;
}

export function sortedSelectedFields(ctx) {
  // console.log(ctx.selectedColumns);
  console.time('sortedSelectedFields');
  const tfo = ctx.userAvailableFields;
  const sortedSelectedFieldsNames = _.cloneDeep(ctx.selectedColumns).filter(
    (f) => tfo.findIndex((s) => s.key === f) >= 0
  );
  sortedSelectedFieldsNames.sort(
    (a, b) => tfo.findIndex((s) => s.key === a) - tfo.findIndex((s) => s.key === b)
  );
  const ssf = [];
  for (let i = 0; i < sortedSelectedFieldsNames.length; i += 1) {
    const fi = tfo.findIndex((s) => s.key === sortedSelectedFieldsNames[i]);
    if (fi >= 0) ssf.push(tfo[fi]);
  }
  // console.log(sortedSelectedFields);
  console.timeEnd('sortedSelectedFields');
  return ssf;
}

export function detailsOptions(ctx) {
  const tfo = ctx.userAvailableDetails.map((f) => {
    return {
      value: f,
      text: startCase(f),
    };
  });
  ctx.takeDefaultDetailsOptions();
  return tfo;
}

export function sortedSelectedDetails(ctx) {
  console.log(ctx.selectedColumns);
  const tfo = ctx.userAvailableDetails;
  const sortedSelectedFieldsNames = _.cloneDeep(ctx.selectedDetails).filter(
    (f) => tfo.findIndex((s) => s === f) >= 0
  );
  sortedSelectedFieldsNames.sort(
    (a, b) => tfo.findIndex((s) => s === a) - tfo.findIndex((s) => s === b)
  );
  const ssd = [];
  for (let i = 0; i < sortedSelectedFieldsNames.length; i += 1) {
    const fi = tfo.findIndex((s) => s === sortedSelectedFieldsNames[i]);
    if (fi >= 0) ssd.push(tfo[fi]);
  }
  // console.log(sortedSelectedFields);
  return ssd;
}

export function applyFields(ctx) {
  ctx.selectedColumns = cloneDeep(ctx.preSelectedColumns);
}

export function applyDetails(ctx) {
  ctx.selectedDetails = cloneDeep(ctx.preSelectedDetails);
}

export function canApplyFields(ctx) {
  return !isEqual(ctx.selectedColumns.sort(), ctx.preSelectedColumns.sort());
}

export function canApplyDetails(ctx) {
  return !isEqual(ctx.selectedDetails.sort(), ctx.preSelectedDetails.sort());
}

export function saveSelectedFieldsMethod(ctx, type) {
  const data = {};
  if (/table/.test(type)) {
    applyFields(ctx);
  }
  if (/details/.test(type)) {
    applyDetails(ctx);
  }
  switch (type) {
    case 'actions_table': {
      data.actionTableSelectedFields = ctx.selectedColumns;
      // if (
      //   ctx.user &&
      //   ctx.user.type === 'affiliate' &&
      //   ctx.user.maff &&
      //   !data.actionTableSelectedFields.includes('timesCanSendM')
      // ) {
      //   data.actionTableSelectedFields.push('timesCanSendM');
      // }
      break;
    }
    case 'actions_details': {
      data.actionTableSelectedDetails = ctx.selectedDetails;
      break;
    }
    case 'injections_table': {
      data.injectionsTableSelectedFields = ctx.selectedColumns;
      break;
    }
    case 'injections_details': {
      data.injectionsTableSelectedDetails = ctx.selectedDetails;
      break;
    }
    case 'campaigns_table': {
      data.campaignsTableSelectedFields = ctx.selectedColumns;
      break;
    }
    case 'campaigns_details': {
      data.campaignsTableSelectedDetails = ctx.selectedDetails;
      break;
    }
    case 'boxes_table': {
      data.boxesTableSelectedFields = ctx.selectedColumns;
      break;
    }
    case 'boxes_details': {
      data.boxesTableSelectedDetails = ctx.selectedDetails;
      break;
    }
    case 'users_table': {
      data.usersTableSelectedFields = ctx.selectedColumns;
      break;
    }
    case 'users_details': {
      data.usersTableSelectedDetails = ctx.selectedDetails;
      break;
    }
    case 'psp_table': {
      data.pspTableSelectedFields = ctx.selectedColumns;
      break;
    }
    case 'psp_details': {
      data.pspTableSelectedDetails = ctx.selectedDetails;
      break;
    }
    case 'worker_table': {
      data.workerTableSelectedFields = ctx.selectedColumns;
      break;
    }
    case 'worker_details': {
      data.workerTableSelectedDetails = ctx.selectedDetails;
      break;
    }
    default:
  }
  ctx.$store.commit('make_requesting', { req: true });
  saveSelectedFields(ctx.token, data)
    .then((r) => {
      if (r.status === 200 && r.data.success) {
        showCard(ctx, 'Preferences saved', 'success', undefined, 'b-toaster-bottom-right');
      } else {
        handleRequestError(
          ctx,
          r.data,
          'Error saving preferences',
          undefined,
          'b-toaster-bottom-right'
        );
      }
      ctx.$store.commit('make_requesting', { req: false });
    })
    .catch((e) => {
      handleRequestError(ctx, e, 'Error saving preferences', undefined, 'b-toaster-bottom-right');
      ctx.$store.commit('make_requesting', { req: false });
    });
}

export function takeDefaultTableFields(ctx, location) {
  console.time('takeDefaultTableFields');
  const tfo = ctx.userAvailableFields.map((f) => (f.key ? f.key : f));
  const columns = ctx.user && ctx.user[location] ? ctx.user[location] : tfo;
  ctx.preSelectedColumns = _.cloneDeep(columns); // _.cloneDeep(tfo.map((f) => f.value));
  ctx.selectedColumns = _.cloneDeep(columns); // _.cloneDeep(tfo.map((f) => f.value));
  console.timeEnd('takeDefaultTableFields'); //
}

export function takeDefaultDetailsOptions(ctx, location) {
  const details = ctx.user && ctx.user[location] ? ctx.user[location] : ctx.userAvailableDetails;
  // eslint-disable-next-line no-param-reassign
  ctx.preSelectedDetails = _.cloneDeep(details);
  ctx.selectedDetails = _.cloneDeep(details);
}

export function takeDefaultHidden(ctx, location) {
  ctx.hidden = ctx.user && ctx.user[location] ? _.cloneDeep(ctx.user[location]) : [];
}
