import { cloneDeep } from 'lodash';

export default function handleMultipleKeysValidateEvent(data, keys, formKey = 'valid') {
  // console.log('handleMultipleKeysValidateEvent', data, keys);
  if (!Array.isArray(keys)) this[formKey][keys] = cloneDeep(data);
  else {
    for (let i = 0; i < keys.length; i += 1) {
      // console.log(keys[i], data[keys[i]], this[formKey]);
      if (data[keys[i]] !== undefined) this[formKey][keys[i]] = data[keys[i]]; // cloneDeep(data[keys[i]]);
    }
  }
  // eslint-disable-next-line no-param-reassign
  if (this.backdoor || this.backdoor === 0) this.backdoor += 1;
  // if (this.emitValidateEvent) this.emitValidateEvent();
}
