import { datepickerFormat, initialFilterForm } from '@/consts';
import _ from 'lodash';
import { DateTime } from 'luxon';

export default function parseQuery(ctx, mapValuesToObjects = true) {
  const result = {};
  const keys = Object.keys(initialFilterForm);
  function findObjectsByIdArray(objects, ids, idField = '_id', fieldsToPick = ['name', '_id']) {
    return Array.isArray(ids)
      ? ids
          .map((id) => {
            const o = objects.find((object) => object[idField] === id);
            return o ? _.pick(o, fieldsToPick) : o;
          })
          .filter((o) => !!o)
      : [objects.find((object) => object[idField] === ids)].filter((o) => !!o);
  }
  function findValueInArray(array, values) {
    return array
      ? Array.isArray(values)
        ? values.filter((value) => array.includes(value))
        : [array.find((a) => a === values)].filter((o) => !!o)
      : [];
  }
  // function jsonParseObjects(objects) {
  //   return Array.isArray ? objects.map((object) => JSON.parse(object)) : [JSON.parse(objects)];
  // }
  // // console.log('ctx.$route', ctx.$route);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    try {
      if (
        ctx.$route.query[key] !== undefined &&
        ctx.$route.query[key] !== null &&
        ctx.$route.query[key] !== 'undefined'
      ) {
        result[key] = _.cloneDeep(ctx.$route.query[key]);
        if (key === 'from') {
          result[key] = DateTime.fromFormat(result[key], datepickerFormat, { zone: 'utc' })
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toFormat(datepickerFormat);
        }
        if (key === 'to') {
          result[key] = DateTime.fromFormat(result[key], datepickerFormat, { zone: 'utc' })
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .toFormat(datepickerFormat);
        }
        if (
          result[key] &&
          !Array.isArray(result[key]) &&
          (key === 'affiliates' ||
            key === 'campaigns' ||
            // key === 'metaCampaigns' ||
            key === 'campaignsInSelectionDropouts' ||
            key === 'boxes' ||
            // key === 'workers' ||
            key === 'locations' ||
            key === 'finalReceivers')
          // key === 'finalReceivers'
        ) {
          result[key] = [result[key]];
        }
        if (key === 'metaCampaigns' || key === 'workers') {
          // result[key] = findObjectsByIdArray(ctx.metaCampaignsOptions, result[key]);
          result[key] = JSON.parse(result[key]);
          // console.log(key, result[key]);
        }
        if (mapValuesToObjects) {
          if (key === 'affiliates') {
            result[key] = findObjectsByIdArray(ctx.affiliatesOptions, result[key]);
          }
          if (key === 'campaigns') {
            result[key] = findObjectsByIdArray(ctx.campaignsOptions, result[key]);
          }
          if (key === 'campaignsInSelectionDropouts') {
            result[key] = findObjectsByIdArray(ctx.campaignsOptions, result[key]);
          }
          if (key === 'boxes') {
            result[key] = findObjectsByIdArray(ctx.boxesOptions, result[key]);
          }
          // if (key === 'workers') {
          //   result[key] = findObjectsByIdArray(ctx.workersOptions, result[key]);
          // }
          if (key === 'locations') {
            result[key] = findObjectsByIdArray(ctx.countryOptions, result[key], 'value', [
              'text',
              'value',
            ]);
          }
          if (key === 'finalReceivers') {
            result[key] = findValueInArray(ctx.finalReceiversOptions, result[key]);
          }
        }
      } else result[key] = initialFilterForm[key];
    } catch (e) {
      result[key] = initialFilterForm[key];
    }
  }
  // eslint-disable-next-line no-param-reassign
  return result;
}
