<template>
  <CreateUser />
</template>

<script>
import CreateUser from '@/components/pages/CreateUser/CreateUser/CreateUser.vue';

export default {
  name: 'CreateUserView',
  components: {
    CreateUser,
  },
};
</script>
