<template>
  <b-row class="dashboard-block dashboard-row">
    <b-col xl="3" md="6">
      <b-card no-body class="bg-gradient-primary border-0 mb-0">
        <!-- Card body -->
        <b-card-body>
          <b-row>
            <b-col>
              <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">
                Leads
              </b-card-title>
              <LoadingSpinner
                variant="white"
                :show-text="false"
                :requesting="requesting"
                v-if="requesting"
              ></LoadingSpinner>
              <span v-else class="h2 font-weight-bold mb-0 text-white">
                {{ data.leads !== undefined ? format(data.leads) : '?' }}
              </span>
            </b-col>
            <b-col md="auto">
              <div
                class="icon icon-shape text-white rounded-circle shadow"
                :class="[`bg-gradient-info`, '']"
              >
                <i class="bi-person-lines-fill"></i>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col xl="3" md="6">
      <b-card no-body class="bg-gradient-info border-0 mb-0">
        <!-- Card body -->
        <b-card-body>
          <b-row>
            <b-col>
              <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">
                FTDs
              </b-card-title>
              <LoadingSpinner
                variant="white"
                :show-text="false"
                :requesting="requesting"
                v-if="requesting"
              ></LoadingSpinner>
              <span v-else class="h2 font-weight-bold mb-0 text-white">
                {{ data.ftds !== undefined ? format(data.ftds) : '?' }}
              </span>
            </b-col>
            <b-col md="auto">
              <div
                class="icon icon-shape text-white rounded-circle shadow"
                :class="[`bg-gradient-green`, '']"
              >
                <i class="bi-cash-coin"></i>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col xl="3" md="6">
      <b-card no-body class="bg-gradient-success border-0 mb-0">
        <!-- Card body -->
        <b-card-body>
          <b-row>
            <b-col>
              <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">
                Revenue
              </b-card-title>
              <LoadingSpinner
                variant="white"
                :show-text="false"
                :requesting="requesting"
                v-if="requesting"
              ></LoadingSpinner>
              <span v-else class="h2 font-weight-bold mb-0 text-white">
                {{ data.revenue !== undefined ? format(data.revenue) : '?' }}
              </span>
            </b-col>
            <b-col md="auto">
              <div
                class="icon icon-shape text-white rounded-circle shadow"
                :class="[`bg-gradient-default`, '']"
              >
                <i class="bi-bank"></i>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col xl="3" md="6">
      <b-card no-body class="bg-gradient-danger border-0 mb-0">
        <!-- Card body -->
        <b-card-body>
          <b-row>
            <b-col>
              <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">
                Rejected Leads
              </b-card-title>
              <LoadingSpinner
                variant="white"
                :show-text="false"
                :requesting="requesting"
                v-if="requesting"
              ></LoadingSpinner>
              <span v-else class="h2 font-weight-bold mb-0 text-white">
                {{ data.rejected !== undefined ? format(data.rejected) : '?' }}
              </span>
            </b-col>
            <b-col md="auto">
              <div
                class="icon icon-shape text-white rounded-circle shadow"
                :class="[`bg-gradient-primary`, '']"
              >
                <i class="bi-x-circle"></i>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import '@/assets/dashboard.css';
import { getSmallCardsData } from '@/utils/api';
import { mapState } from 'vuex';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { DateTime } from 'luxon';
import { dataUpdateTimeoutCondition } from '@/utils';

export default {
  name: 'DashboardSmallCardsRow',
  components: { LoadingSpinner },
  props: {
    period: { type: String, default: 'this month' },
    customDate: {
      type: Object,
      default: () => {
        return {
          from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
          to: DateTime.utc().toISO(),
        };
      },
    },
  },
  data() {
    return {
      controller: new AbortController(),
      timeout: undefined,
      updateTimeout: 120000,
      requesting: false,
      data: { leads: undefined, ftds: undefined, revenue: undefined, rejected: undefined },
      mockData: { leads: 7936, ftds: 909, revenue: 909 * 1200, rejected: Math.floor(7936 * 0.037) },
    };
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    getSmallCardsData() {
      clearTimeout(this.timeout);
      this.requesting = true;
      getSmallCardsData(
        this.token,
        {
          period: this.period,
          from: this.period === 'custom' ? this.customDate.from : undefined,
          to: this.period === 'custom' ? this.customDate.to : undefined,
        },
        this.controller.signal
      )
        .then((r) => {
          this.data = r.data;
          this.requesting = false;
          if (dataUpdateTimeoutCondition(this.period, this.customDate.to)) {
            this.timeout = setTimeout(() => this.getSmallCardsData(), this.updateTimeout);
          }
        })
        .catch(() => {
          this.requesting = false;
          if (dataUpdateTimeoutCondition(this.period, this.customDate.to)) {
            this.timeout = setTimeout(() => this.getSmallCardsData(), this.updateTimeout);
          }
        });
    },
    format(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  mounted() {
    this.getSmallCardsData();
  },
  watch: {
    period() {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getSmallCardsData();
    },
    'customDate.from': function () {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getSmallCardsData();
    },
    'customDate.to': function () {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getSmallCardsData();
    },
  },
};
</script>

<style scoped></style>
