<template>
  <b-container fluid>
    <div class="d-flex align-items-center justify-content-between my-3">
      <h2>Logs</h2>

      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <span class="mr-2">From</span>
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="date-from"
            today-button
            reset-button
            close-button
            :max="filterValues.to"
            v-model="filterValues.from"
          ></b-form-datepicker>
        </div>
        <div class="d-flex align-items-center">
          <span class="mx-2">To</span>
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="date-to"
            today-button
            reset-button
            close-button
            :min="filterValues.from"
            v-model="filterValues.to"
          ></b-form-datepicker>
        </div>
        <b-button
          class="h5 font-weight-normal mx-2 mb-0"
          variant="primary"
          :disabled="requesting"
          @click="updateData()"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>Get data</span>
        </b-button>
      </div>

      <div class="d-flex align-items-center">
        <b-spinner class="mr-3" small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </div>

      <div class="d-flex align-items-center">
        <b-spinner class="mr-3" small v-if="requesting"></b-spinner>
        <b-pagination
          class="mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </div>
    </div>

    <b-table
      class="table mb-3"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(details)="data">
        <b-button size="sm" @click="toggleDetailsForItem(data.index)">
          <b-icon
            :icon="
              typeof itemIndexesWithVisibleDetails.find((index) => index === data.index) !==
              'undefined'
                ? 'eye-slash'
                : 'eye'
            "
          ></b-icon>
        </b-button>

        <b-card
          class="mt-1"
          v-if="
            typeof itemIndexesWithVisibleDetails.find((index) => index === data.index) !==
            'undefined'
          "
        >
          <vue-json-pretty
            :data="data.item"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
          ></vue-json-pretty>
        </b-card>
      </template>
    </b-table>

    <div class="d-flex align-items-center justify-content-around mb-3">
      <div class="d-flex align-items-center">
        <b-spinner class="mr-3" small v-if="requesting"></b-spinner>
        <b-pagination
          class="mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </div>

      <div class="d-flex align-items-center">
        <b-spinner class="mr-3" small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import { DateTime } from 'luxon';
import VueJsonPretty from 'vue-json-pretty';
import { tableFormatterDate, handleRequestError } from '@/utils';
import { getWorkHistory } from '@/utils/api';
import { datepickerFormat, dateFormatOptions } from '@/consts';
import 'vue-json-pretty/lib/styles.css';

const initialFilterValues = {
  from: DateTime.utc()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toFormat(datepickerFormat),
  to: DateTime.utc().toFormat(datepickerFormat),
};

export default {
  name: 'WorkHistoryTable',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      dateFormatOptions,
      filterValues: _.cloneDeep(initialFilterValues),
      fields: [
        {
          key: 'when',
          sortable: true,
          formatter: tableFormatterDate,
        },
        'who',
        'what',
        {
          key: 'details',
          thStyle: { width: '100%' },
        },
      ],
      items: null,
      itemIndexesWithVisibleDetails: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('get_all_resources_one_request', { vm: this });
    this.updateData();
  },
  computed: {
    ...mapState(['requesting']),
  },
  methods: {
    updateData() {
      this.$store.commit('make_requesting', { req: true });

      const options = {
        page: this.currentPage,
        itemsPerPage: this.perPage,
      };

      if (this.filterValues.from) {
        options.from = DateTime.fromFormat(this.filterValues.from, datepickerFormat, {
          zone: 'utc',
        });
        options.from = options.from.isValid
          ? options.from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
          : undefined;
      }

      if (this.filterValues.to) {
        options.to = DateTime.fromFormat(this.filterValues.to, datepickerFormat, { zone: 'utc' });
        options.to = options.to.isValid ? options.to.toISO() : undefined;
      }

      getWorkHistory(this.token, options)
        .then(({ data: { items, page, itemsPerPage, totalRows } }) => {
          this.items = items;
          this.itemIndexesWithVisibleDetails = [];
          this.currentPage = page;
          this.perPage = itemsPerPage;
          this.totalRows = totalRows;

          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving work history`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    toggleDetailsForItem(itemIndex) {
      if (this.itemIndexesWithVisibleDetails.includes(itemIndex)) {
        this.itemIndexesWithVisibleDetails.splice(
          this.itemIndexesWithVisibleDetails.findIndex((index) => index === itemIndex),
          1
        );
      } else {
        this.itemIndexesWithVisibleDetails.push(itemIndex);
      }
    },
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
  },
};
</script>

<style scoped>
.table >>> th,
.table >>> th > *,
.table >>> td,
.table >>> td > * {
  white-space: nowrap !important;
}
</style>
