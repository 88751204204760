import { actionTypeValues } from '@/consts';

export const nameRegex = /^[-+&a-zA-Z0-9_ ]{1,256}$/;
export const oidRegex = /^[a-fA-F0-9]*$/;
export const urlRegex = /^(https?:\/\/)([-a-zA-Z0-9@:%._+~#=]{1,256}\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,64}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*$)/;
export const twoDotsRegex = /\.\./;
// export const dateRegex = /^202\d-[0-1]?[$/;
export const ipRegex = /^[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$/;

export function validateRefArray(c) {
  return Array.isArray(c) && c.every((d) => oidRegex.test(d));
}
export function capCheck(c) {
  // console.log(c);
  return {
    type: !c || !c.type || c.type === '' || actionTypeValues.includes(c.type),
    amount: !c || !c.amount || (Number.isInteger(Number(c.amount)) && Number(c.amount) > 0),
  };
}
export function noCap(cap) {
  return !cap || !cap.type || !cap.amount || cap.amount < 0;
}

export const nameTest = (c) => !!c && nameRegex.test(c);

export function validateAuthParams(c, limitContentType = false) {
  return Array.isArray(c)
    ? c.map((d) =>
        Array.isArray(d) && d.length === 2
          ? limitContentType && d[0] === 'Content-Type'
            ? [
                'application/json',
                'application/json; charset=utf-8',
                'application/x-www-form-urlencoded',
                'multipart/formdata',
              ].includes(d[1])
            : d.map((e) => typeof e === 'string' && e.length >= 1)
          : false
      )
    : [[false, false]];
}
