<template>
  <UsersTable />
</template>

<script>
import UsersTable from '@/components/pages/Users/UsersTable/UsersTable.vue';

export default {
  name: 'UserssView',
  components: {
    UsersTable,
  },
};
</script>
