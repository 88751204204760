<template>
  <b-card>
    <b-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <b-icon icon="file-earmark-arrow-down" font-scale="1.5"></b-icon>
          <h5 class="mb-0 ml-2">Sending Lead</h5>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center">
        <span class="font-weight-bold">API URL:</span>
        <a href="#" class="ml-2" @click.prevent="copyApiUrl">{{ fullApiUrl }}</a>
        <b-button variant="outline-secondary" @click="copyApiUrl" class="mx-3">
          <i class="bi bi-clipboard"></i>
          Copy URL
        </b-button>
      </div>
      <p class="mt-3"><em>*Make sure you send to port 2053</em></p>
      <p class="font-weight-bold">Request type: POST</p>
      <span class="font-weight-bold">Headers:</span>
      <pre><code>"content-type: application/json; charset=utf-8"</code></pre>
      <p class="font-weight-bold">Data format: JSON</p>
      <span class="font-weight-bold">Body:</span>
      <pre><code>
{
  "b": "{{ apiKey }}",
  "email": "test@test.com",
  "password": "Qwerty@123",
  "firstname": "Test",
  "lastname": "Testing",
  "phone": "980123456789",
  "language": "en-GB",
  "country": "GB",
  "ip": "123.45.67.89",
  "so": "Test"
}
      </code></pre>
      <p class="font-weight-bold">Notes:</p>
      <ul>
        <li>"b" - Your API key</li>
        <li>"phone" - with country code</li>
        <li>"country" - 2-letters country code</li>
        <li>"so" - name of the source of leads</li>
      </ul>
      <div v-if="live">
        <p>The autologin URL will be now available in "autologin_url" field in the api answer.</p>
        <p>
          Some of our clients don't give the autologin, for them this field will have value "none".
        </p>
        <p>It will also be "none" in case of error api responses from clients</p>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import copyToClipboard from '@/utils/clipboard';

export default {
  name: 'SendingLeadDocs',
  props: {
    apiUrl: String,
    apiKey: String,
    live: Boolean,
  },
  computed: {
    fullApiUrl() {
      return `${this.apiUrl}:2053/track/lead`;
    },
  },
  methods: {
    copyApiUrl() {
      copyToClipboard(this.fullApiUrl);
    },
  },
};
</script>

<style scoped></style>
