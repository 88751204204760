<template>
  <div>
    <!--    <h5>Remove Campaigns From Boxe</h5>-->
    <b-form-group class="my-1" label="Campaign" label-for="add-campaign">
      <multiselect
        id="add-campaign"
        v-model="removeForm.campaigns"
        track-by="name"
        label="name"
        placeholder="Select one"
        :options="campaignsOptions"
        :allow-empty="true"
        :multiple="true"
        :closeOnSelect="false"
        :disabled="requesting || (campaign && campaign._id)"
        :state="isValid.fields.campaigns"
      ></multiselect>
    </b-form-group>
    <b-form-group class="my-1" label="Box" label-for="add-box">
      <multiselect
        id="add-box"
        v-model="removeForm.boxes"
        track-by="name"
        label="name"
        placeholder="Select one"
        :options="boxesOptions"
        :allow-empty="true"
        :multiple="true"
        :closeOnSelect="false"
        :disabled="requesting || (box && box._id)"
        :state="isValid.fields.boxes"
      ></multiselect>
    </b-form-group>
    <b-form-group class="my-1" label="Geos" label-for="add-countries">
      <multiselect
        id="add-countries"
        v-model="removeForm.countries"
        label="text"
        :options="campaignsCountryOptions"
        track-by="value"
        @search-change="countryMultiSelectSearch"
        :multiple="true"
        :custom-label="countryWithCode"
        :state="isValid.fields.countries"
        :allow-empty="true"
        :closeOnSelect="false"
        :disabled="requesting"
      ></multiselect>
    </b-form-group>
    <b-button
      @click="removeCampaignsFromBoxes()"
      class="m-2"
      variant="danger"
      :disabled="requesting || !isValid.form"
    >
      <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
      Remove Campaigns from Boxes for selected GEOs
    </b-button>
  </div>
</template>

<script>
import _ from 'lodash';
import { countryOptions } from '@/consts';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { removeCampaignsFromBoxes } from '@/utils/api';
import { countryMultiSelectSearch, handleRequestError, showCard } from '@/utils';
import { formValid, isValid } from '@/validators';

export default {
  name: 'RemoveCampaignsFromBoxes',
  components: {
    Multiselect,
  },
  props: {
    box: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      backdoor: 0,
      removeForm: {
        boxes: [],
        campaigns: [],
        countries: [],
      },
      countryOptions,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'boxes', 'campaigns', 'requesting']),
    isValid() {
      const fields = isValid('add_campaigns_to_boxes', this.removeForm, this.removeForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    campaignsOptions() {
      this.backdoor;
      const boxesInForm = _.cloneDeep(this.boxes).filter(
        (box) =>
          this.removeForm.boxes.findIndex(
            (b) => (b._id ? b._id : b) === (box._id ? box._id : box)
          ) >= 0
      );
      return _.cloneDeep(this.campaigns)
        .filter(
          (c) =>
            boxesInForm.length === 0 ||
            boxesInForm.some(
              (b) =>
                b.campaigns &&
                b.campaigns.findIndex(
                  (bce) =>
                    (bce.campaign._id ? bce.campaign._id : bce.campaign) === (c._id ? c._id : c) &&
                    bce.countries.length === 1
                ) >= 0
            )
        )
        .map((c) => {
          return { name: c.name, _id: c._id };
        })
        .filter((c) => !this.campaign || !this.campaign._id || this.campaign._id === c._id);
    },
    boxesOptions() {
      this.backdoor;
      return _.cloneDeep(this.boxes)
        .filter(
          (b) =>
            this.removeForm.campaigns.length === 0 ||
            this.removeForm.campaigns.some(
              (c) =>
                b.campaigns &&
                b.campaigns.findIndex(
                  (bce) =>
                    (bce.campaign._id ? bce.campaign._id : bce.campaign) === (c._id ? c._id : c) &&
                    bce.countries.length === 1
                ) >= 0
            )
        )
        .map((b) => {
          return { name: b.name, _id: b._id };
        });
      // .filter((b) => !this.box || !this.box._id || this.box._id === b._id);
    },
    campaignsCountryOptions() {
      this.backdoor;
      const campaigns = _.cloneDeep(this.campaigns);
      //     .filter((c) =>
      //   this.removeForm.campaigns.some((campaign) => c._id === campaign || c._id === campaign._id)
      // );
      const boxes = _.cloneDeep(this.boxes);
      //     .filter((b) =>
      //   this.removeForm.boxes.some((box) => b._id === box || b._id === box._id)
      // );
      return this.countryOptions.filter((country) =>
        campaigns.some(
          (campaign) =>
            campaign.countries.findIndex((cc) => (cc.value ? cc.value : cc) === country.value) >=
              0 &&
            boxes.some(
              (box) =>
                box.campaigns &&
                box.campaigns.findIndex(
                  (bce) =>
                    bce.countries.length === 1 &&
                    (bce.countries[0].value ? bce.countries[0].value : bce.countries[0]) ===
                      country.value &&
                    (bce.campaign._id ? bce.campaign._id : bce.campaign) === campaign._id
                ) >= 0
            )
        )
      );
    },
  },
  methods: {
    countryMultiSelectSearch,
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    removeCampaignsFromBoxes() {
      console.log('removeCampaignsFromBoxes', this.removeForm);
      if (!this.isValid.form) {
        return;
      }
      this.$store.commit('make_requesting', { req: true });
      const data = {
        boxes: this.removeForm.boxes.map((b) => b._id),
        campaigns: this.removeForm.campaigns.map((c) => c._id),
        countries: this.removeForm.countries.map((c) => c.value),
      };
      removeCampaignsFromBoxes(this.token, data)
        .then((r) => {
          if (r.data.success) {
            showCard(this, `Removed successfully`, 'success');
          } else {
            handleRequestError(this, r.data, `Unknown error on removing Campaigns from Boxes`);
          }
          this.$store.commit('make_requesting', { req: false });
          this.$emit('removeCampaignsFromBoxes', true);
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on removing Campaigns from Boxes`);
          this.$store.commit('make_requesting', { req: false });
          this.$emit('removeCampaignsFromBoxes', true);
        });
    },
  },
  created() {
    if (this.box && this.box._id) this.removeForm.boxes.push(this.box);
    if (this.campaign && this.campaign._id) this.removeForm.campaigns.push(this.campaign);
    this.backdoor += 1;
  },
};
</script>

<style scoped></style>
