<template v-slot="{ selectBy }">
  <!--  period, // this is to make period choosable from inside ChartCard component -->
  <b-col class="dashboard-card" :class="[colClass, 'my-3']">
    <!--    <b-card no-body class="mb-0">-->
    <!--      <b-card-header v-if="allowSelectBy">-->
    <!--        style="display: flex; justify-content: space-between"-->
    <!--        <span>{{ startCase(entity) }}s by</span>-->
    <!--        <b-form-radio-group-->
    <!--          id="btn-radios-period-1"-->
    <!--          size="sm"-->
    <!--          button-variant="outline-primary"-->
    <!--          v-model="period"-->
    <!--          :options="timePeriodOptions"-->
    <!--          name="btn-radios-period-1"-->
    <!--          buttons-->
    <!--          class="mx-3 my-1"-->
    <!--        ></b-form-radio-group>-->
    <!--        <b-form-radio-group-->
    <!--          v-if="allowSelectBy"-->
    <!--          id="btn-radios-select-by-1"-->
    <!--          size="sm"-->
    <!--          button-variant="outline-primary"-->
    <!--          v-model="selectBy"-->
    <!--          :options="selectByOptions"-->
    <!--          name="btn-radios-period-1"-->
    <!--          buttons-->
    <!--          class="mx-3 my-1"-->
    <!--        ></b-form-radio-group>-->
    <!--      </b-card-header>-->
    <slot :entity="entity" :selectBy="selectBy"></slot>
    <!--      :period="period" // this is to make period choosable from inside ChartCard component -->
    <!--    </b-card>-->
  </b-col>
</template>

<script>
import { startCase } from '@/utils/string';
import { timePeriodOptions, selectByOptions } from '@/consts';

export default {
  name: 'ChartCard',
  props: {
    expand: { type: Boolean, default: false },
    allowSelectBy: { type: Boolean, default: false },
    entity: { type: String, default: 'FTD' },
    colLg: { type: Number, default: 6 },
    startPeriod: { type: String, default: 'this month' },
  },
  data() {
    return { period: this.startPeriod, selectBy: 'geo', timePeriodOptions, selectByOptions };
  },
  computed: {
    colClass() {
      return `col-lg-${this.colLg} col-12`;
      // return this.expand && this.period === 'year' ? 'col-lg-12' : 'col-lg-6';
    },
  },
  methods: {
    startCase,
  },
};
</script>

<style scoped>
.dashboard-card {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
