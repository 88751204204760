<template>
  <b-container fluid>
    <b-row class="my-3" no-gutters>
      <b-col cols="12" lg="2" class="my-lg-0 my-1">
        <h2>InjectionSendResults</h2>
      </b-col>
      <!--lg="2"-->
      <!--      <b-col-->
      <!--        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <b-input-group>-->
      <!--          <b-input-group-prepend is-text>-->
      <!--            <b-icon icon="search"></b-icon>-->
      <!--          </b-input-group-prepend>-->
      <!--          <b-form-input-->
      <!--            v-model="filter"-->
      <!--            type="search"-->
      <!--            id="filterInput"-->
      <!--            placeholder="Type to Search"-->
      <!--          ></b-form-input>-->
      <!--          <b-input-group-append>-->
      <!--            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
      <!--          </b-input-group-append>-->
      <!--        </b-input-group>-->
      <!--      </b-col>-->
      <b-col cols="12" lg="3" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <JsonExcel
          class="m-2"
          :data="items"
          :fields="fieldsToExport"
          type="csv"
          :stringifyLongNum="true"
          v-if="iAmOtherLevelTrustedUser"
        >
          <b-button variant="outline-primary">📥CSV</b-button>
        </JsonExcel>
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2 mb-3">
      <!--      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3">-->
      <!--        <span class="d-block h5 font-weight-normal">Date</span>-->
      <!--        <div class="d-sm-flex align-items-center">-->
      <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
      <!--            <span class="mr-sm-2">From</span>-->
      <!--            <b-form-datepicker-->
      <!--              :date-format-options="dateFormatOptions"-->
      <!--              id="date-from"-->
      <!--              today-button-->
      <!--              reset-button-->
      <!--              close-button-->
      <!--              :max="filterForm.to"-->
      <!--              v-model="filterForm.from"-->
      <!--            ></b-form-datepicker>-->
      <!--            &lt;!&ndash;                          @input="updateBackdoor()"-->
      <!--&ndash;&gt;-->
      <!--          </div>-->
      <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
      <!--            <span class="mx-sm-2">To</span>-->
      <!--            <b-form-datepicker-->
      <!--              :date-format-options="dateFormatOptions"-->
      <!--              id="date-to"-->
      <!--              today-button-->
      <!--              reset-button-->
      <!--              close-button-->
      <!--              :min="filterForm.from"-->
      <!--              v-model="filterForm.to"-->
      <!--            ></b-form-datepicker>-->
      <!--            &lt;!&ndash;                          @input="updateBackdoor()"-->
      <!--&ndash;&gt;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">ID</span>
        <b-form-input v-model="filterForm._id"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>

      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">Injection ID</span>
        <b-form-input v-model="filterForm.injection_id"></b-form-input>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <b-button
          @click="applyFilter()"
          :disabled="!canApplyFilter"
          variant="primary"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Apply filter
        </b-button>
        <b-button
          @click="clearFilterForm()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Clear filter
        </b-button>
      </b-col>
    </b-row>

    <b-table
      class="table mb-3"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(_id)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>
      <template #cell(sendingAction)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>
      <template #cell(injection)="data">
        <span v-b-popover.hover.top="data.value ? data.value._id : data.value">
          {{ data.value ? data.value.name : data.value }}
        </span>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </b-button-group>
      </template>

      <template #cell(brokerAccepted)="data">
        <h5 class="mb-0">
          <b-badge
            :variant="
              data.value === 'true' ? 'success' : data.value === 'false' ? 'danger' : 'warning'
            "
          >
            {{ data.value === 'true' ? 'Yes' : data.value === 'false' ? 'No' : 'unknown' }}
          </b-badge>
        </h5>
      </template>

      <template #row-details="row">
        <b-card-group columns>
          <b-card>
            <b-card-title>Sending Action</b-card-title>
            <b-card-text>{{ row.item.sendingAction }}</b-card-text>
          </b-card>

          <b-card header="Result">
            <vue-json-pretty
              v-if="row.item.result"
              :data="row.item.result"
              :show-line="true"
              :select-on-click-node="true"
              :highlight-selected-node="true"
              :selectable-type="'single'"
              :show-select-controller="false"
              @click="(path, data) => selectJsonNode(path, data)"
              style="max-width: 506px; word-wrap: break-word"
            ></vue-json-pretty>
          </b-card>

          <b-card>
            <b-card-title>Broker Accepted</b-card-title>
            <b-card-text>{{ row.item.brokerAccepted }}</b-card-text>
          </b-card>
          <b-card header="Injection">
            <vue-json-pretty
              v-if="row.item.injection"
              :data="row.item.injection"
              :show-line="true"
              :select-on-click-node="true"
              :highlight-selected-node="true"
              :selectable-type="'single'"
              :show-select-controller="false"
              @click="(path, data) => selectJsonNode(path, data)"
              style="max-width: 506px; word-wrap: break-word"
            ></vue-json-pretty>
          </b-card>

          <b-card header="Tried Send">
            <vue-json-pretty
              v-if="row.item.triedSend"
              :data="row.item.triedSend"
              :show-line="true"
              :select-on-click-node="true"
              :highlight-selected-node="true"
              :selectable-type="'single'"
              :show-select-controller="false"
              @click="(path, data) => selectJsonNode(path, data)"
              style="max-width: 506px; word-wrap: break-word"
            ></vue-json-pretty>
          </b-card>
        </b-card-group>
        <p>_id: {{ row.item._id }}</p>
      </template>
    </b-table>
    <b-row class="mb-3" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable no-nested-ternary */
import { mapState } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';

import _ from 'lodash';
// import { DateTime } from 'luxon';
import {
  countryOptions,
  // datepickerFormat,
  TRUSTED_USERS,
  dateFormatOptions,
  OTHER_LEVEL_TRUSTED_USERS,
} from '@/consts';
import {
  handleRequestError,
  showCard,
  tableFormatterId,
  // showCard,
  tableFormatterNameAndId,
} from '@/utils';
// import { startCase } from '@/utils/string';
import { getInjectionSendResults } from '@/utils/api';
import '@/assets/badge-toogle.css';
import '@/assets/breakdown-data.css';
import JsonExcel from 'vue-json-excel';
import $ from 'jquery';

const initialFilterForm = {
  // from: DateTime.utc().startOf('year').toFormat(datepickerFormat),
  // to: DateTime.utc().toFormat(datepickerFormat),
  _id: undefined,
  injection_id: undefined,
  sortBy: '_id',
  sortOrder: 'desc',
};

export default {
  name: 'InjectionSendResultTable',
  components: { JsonExcel, VueJsonPretty },
  data() {
    return {
      fields: [
        { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
        { key: 'sendingAction', sortable: true, formatter: (v) => tableFormatterId(v) },
        { key: 'brokerAccepted', sortable: true },
        { key: 'controls', sortable: true },
        { key: 'GMMethodUsed', label: 'GMMethodUsed', sortable: true },
        { key: 'injection', sortable: true },
      ],
      dateFormatOptions,
      countryOptions,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 500, text: '500' },
        { value: 1000, text: '1000' },
      ],
      filterForm: _.cloneDeep(initialFilterForm),
      appliedFilterForm: _.cloneDeep(initialFilterForm),
      lastTimeToProcess: 0,
      sortOrderOptions: [
        { text: 'Asc', value: 'asc' },
        { text: 'Desc', value: 'desc' },
      ],
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
    };
  },
  computed: {
    ...mapState([
      'user',
      // 'isAdmin',
      // 'isManager',
      // 'isAffiliate',
      'token',
      // 'affiliates',
      // 'campaigns',
      // 'boxes',
      'requesting',
    ]),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    fieldsToExport() {
      return this.iAmOtherLevelTrustedUser
        ? {
            id: '_id',
            Type: 'type',
            Number: 'number',
            Affiliates: 'affiliates.length',
            Leads: 'leads.length',
          }
        : {};
    },
    // sortByOptions() {
    //   return this.userAvailableFields.map((field) => {
    //     return {
    //       text: field.label ? field.label : startCase(field.key ? field.key : String(field)),
    //       value: field.key,
    //     };
    //   });
    // },
    canApplyFilter() {
      return !this.requesting && !_.isEqual(this.appliedFilterForm, this.filterForm);
    },
  },
  mounted() {
    this.$store.dispatch('get_all_resources_one_request', { vm: this });
    this.updateData();
  },
  methods: {
    selectJsonNode(path, data) {
      console.log('selectJsonNode', path, data);
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      // if (typeof data !== 'object') {
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
      // }
    },

    updateItem(id, newAction) {
      if (newAction) {
        const clonedItems = _.cloneDeep(this.items);
        const itemIndex = clonedItems.findIndex(({ _id }) => _id === id);
        const { _showDetails } = clonedItems[itemIndex];
        clonedItems[itemIndex] = newAction;
        if (_showDetails) clonedItems[itemIndex]._showDetails = _showDetails;
        this.items = clonedItems;
        this.$store.commit('make_requesting', { req: false });
      } else {
        this.updateData();
      }
    },
    applyFilter() {
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    clearFilterForm() {
      this.filter = null;
      this.filterForm = _.cloneDeep(initialFilterForm);
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    updateBackdoor() {
      console.log('backdoor updated');
      this.backdoor += 1;
    },
    _updateLastTimeToProcess(t) {
      this.lastTimeToProcess = t;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateData() {
      this.$store.commit('make_requesting', { req: true });
      const appliedFilterForm = _.cloneDeep(this.appliedFilterForm);
      const options = {
        ..._.cloneDeep(appliedFilterForm),
        page: this.currentPage,
        itemsPerPage: this.perPage,
      };
      console.log('options', options);
      // if (options.from) {
      //   options.from = DateTime.fromFormat(options.from, datepickerFormat, { zone: 'utc' });
      //   options.from = options.from.isValid
      //     ? options.from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
      //     : undefined;
      // }

      // if (options.to) {
      //   options.to = DateTime.fromFormat(options.to, datepickerFormat, { zone: 'utc' });
      //   options.to = options.to.isValid ? options.to.toISO() : undefined;
      // }

      getInjectionSendResults(this.token, options)
        .then((r) => {
          this.$store.commit('make_requesting', { req: true });
          const { data } = r;
          console.log('got data; items length', data.items.length);
          this.items = _.cloneDeep(data.items);
          console.log('put items in table; table items length', this.items.length);
          if (this.currentPage !== data.page) this.currentPage = _.cloneDeep(data.page);
          if (this.perPage !== data.itemsPerPage) this.perPage = _.cloneDeep(data.itemsPerPage);
          this.totalRows = _.cloneDeep(data.totalRows);
          this.filterForm.sortBy = _.cloneDeep(data.sortBy);
          this.appliedFilterForm.sortBy = _.cloneDeep(data.sortBy);
          this.filterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.appliedFilterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    tableFormatterNameAndId(value) {
      return tableFormatterNameAndId(value);
    },
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    pspType() {
      if (!this.$store.state.requesting) this.updateData();
    },
    $route() {
      this.$store.dispatch('get_all_resources_one_request', { vm: this });
      this.updateData();
    },
  },
};
</script>

<style scoped>
.table >>> th,
.table >>> th > *,
.table >>> td,
.table >>> td > * {
  white-space: nowrap !important;
}
.table >>> .card-columns {
  white-space: initial !important;
}
.table >>> .with-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
