<template>
  <div>
    <LoadingSpinner v-if="requesting" :requesting="requesting" />
    <div v-else>
      <b-card-group columns>
        <b-card v-if="detailAvailable('changeLog')">
          <template #header>
            <h6 class="d-flex align-items-center justify-content-between mb-0">
              Change Log
              <b-dropdown
                class="text-nowrap"
                variant="outline-secondary"
                size="sm"
                right
                no-caret
                v-if="
                  tableFormatterNameAndId(item.campaign) === 'TECH_CAMPAIGN' ||
                  tableFormatterNameAndId(item.campaign) === 'TECH'
                "
              >
                <template #button-content>
                  <b-icon icon="three-dots"></b-icon>
                </template>
                <b-dropdown-form>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="makeTestFtd(item._id)"
                    :disabled="requesting"
                  >
                    <b-spinner small v-if="requesting"></b-spinner>
                    Make test FTD
                  </b-button>
                </b-dropdown-form>
              </b-dropdown>
            </h6>
          </template>
          <b-list-group v-if="item.changeLog">
            <b-list-group-item v-for="(clr, index) in item.changeLog" v-bind:key="index">
              <b-badge variant="primary">{{ clr.type }}</b-badge>
              {{ actionDateTime(clr) }}
              <b-badge v-if="clr.location" variant="info">
                {{ clr.location ? clr.location : '' }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card header="Sale Status history" v-if="detailAvailable('saleStatusChangeLog')">
          <b-list-group v-if="item.saleStatusChangeLog">
            <b-list-group-item v-for="(clr, index) in item.saleStatusChangeLog" v-bind:key="index">
              {{ actionDateTime(clr) }}
              <b-badge variant="info">{{ clr.status }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card v-if="detailAvailable('leadContactInfo')">
          <template #header>
            <h6 class="d-flex align-items-center justify-content-between mb-0">
              Lead Contact Info
              <b-dropdown
                variant="outline-secondary"
                right
                no-caret
                size="sm"
                v-if="!isAffiliate"
                lazy
              >
                <template #button-content>
                  <b-icon icon="three-dots"></b-icon>
                </template>
                <b-dropdown-form style="width: 300px">
                  <CopyLeadMenu
                    :lead="item.leadContactInfo"
                    :_id="item._id"
                    :created_at="item.createdAt"
                  ></CopyLeadMenu>
                </b-dropdown-form>
              </b-dropdown>
            </h6>
          </template>
          <b-list-group>
            <b-list-group-item>
              <b-badge variant="primary">email</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.email : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">password</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.password : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">firstname</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.firstname : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">lastname</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.lastname : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">phone</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.phone : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">language</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.language : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">country</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.country : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">ip</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.ip : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">geoip_country</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.geoip_country : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">so</b-badge>
              {{ item.leadContactInfo ? item.leadContactInfo.so : '' }}
            </b-list-group-item>
            <b-list-group-item>
              <b-badge variant="primary">regDate</b-badge>
              {{ item.leadContactInfo.regDate ? item.leadContactInfo.regDate : '' }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card v-if="detailAvailable('sentToCampaigns')">
          <template #header>
            <h6 class="d-flex align-items-center justify-content-between mb-0">
              Sent To Campaigns
              <b-button variant="primary" @click="requestSTCIList(item._id)">Request Data</b-button>
            </h6>
          </template>
          <vue-json-pretty
            v-if="item.STCIList"
            :data="item.STCIList"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>
        <b-card header="Send Lead Request Config" v-if="detailAvailable('sendLeadRequestConfig')">
          <vue-json-pretty
            v-if="item.sendLeadRequestConfig"
            :data="item.sendLeadRequestConfig"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>

        <b-card header="Broker API answer" v-if="detailAvailable('brokerApiAnswer')">
          <vue-json-pretty
            v-if="item.brokerApiAnswer"
            :data="item.brokerApiAnswer"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>

        <b-card header="Error On Send" v-if="detailAvailable('errorOnSend')">
          <vue-json-pretty
            v-if="item.errorOnSend"
            :data="item.errorOnSend"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>

        <b-card
          header="Broker last leads pulling API answer"
          v-if="detailAvailable('brokerLastLeadsPullingAnswer')"
        >
          <vue-json-pretty
            v-if="
              item.brokerLastLeadsPullingAnswer &&
              Array.isArray(item.brokerLastLeadsPullingAnswer) &&
              item.brokerLastLeadsPullingAnswer.length > 0
            "
            :data="item.brokerLastLeadsPullingAnswer[item.brokerLastLeadsPullingAnswer.length - 1]"
            :deep="0"
            :showLength="true"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
          <!--            :virtual="true"-->
          <!--            :height="30"-->
          <!--            :itemHeight="10"-->
        </b-card>
        <b-card
          header="Broker last ftds pulling API answer"
          v-if="detailAvailable('brokerLastFtdsPullingAnswer')"
        >
          <vue-json-pretty
            v-if="
              item.brokerLastFtdsPullingAnswer &&
              Array.isArray(item.brokerLastFtdsPullingAnswer) &&
              item.brokerLastFtdsPullingAnswer.length > 0
            "
            :data="item.brokerLastFtdsPullingAnswer[item.brokerLastFtdsPullingAnswer.length - 1]"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>
        <b-card
          header="Campaign Selection Dropouts"
          v-if="detailAvailable('campaignSelectionDropouts')"
        >
          <vue-json-pretty
            v-if="item.campaignSelectionDropouts"
            :data="item.campaignSelectionDropouts"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>
        <b-card header="Mix Status Change History" v-if="detailAvailable('mChangeHistory')">
          <b-list-group v-if="item.mChangeHistory">
            <b-list-group-item v-for="(clr, index) in item.mChangeHistory" v-bind:key="index">
              <b-badge :variant="clr.to ? 'info' : 'secondary'">{{ clr.to }}</b-badge>
              {{ actionDateTime(clr) }}
              <b-badge variant="primary">{{ clr.by }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card header="Check Status Change History" v-if="detailAvailable('rtcChangeHistory')">
          <b-list-group v-if="item.rtcChangeHistory">
            <b-list-group-item v-for="(clr, index) in item.rtcChangeHistory" v-bind:key="index">
              <b-badge :variant="clr.to ? 'info' : 'secondary'">{{ clr.to }}</b-badge>
              {{ actionDateTime(clr) }}
              <b-badge variant="primary">{{ clr.by }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card header="Sent to Instance Campaign" v-if="detailAvailable('sentToInstanceCampaign')">
          <vue-json-pretty
            v-if="item.sentToInstanceCampaign"
            :data="item.sentToInstanceCampaign"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>
        <b-card
          header="Final Receiver Check Result"
          v-if="detailAvailable('finalReceiverCheckResult')"
        >
          <vue-json-pretty
            v-if="item.finalReceiverCheckResult"
            :data="item.finalReceiverCheckResult"
            :show-line="true"
            :select-on-click-node="true"
            :highlight-selected-node="true"
            :selectable-type="'single'"
            :show-select-controller="false"
            @click="(path, data) => selectJsonNode(path, data)"
            style="max-width: 506px; word-wrap: break-word"
          ></vue-json-pretty>
        </b-card>
      </b-card-group>
      <p v-if="detailAvailable('actionId')">Action _id: {{ item._id }}</p>
      <p v-if="item.brokerActionId && detailAvailable('brokerActionId')">
        Broker UniqueID: {{ item.brokerActionId }}
      </p>
      <p v-if="user.email === 'blacklight@default.gg'">
        motherMetaActionId: {{ item.motherMetaActionId }}
      </p>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { handleRequestError, showCard, tableFormatterNameAndId, actionDateTime } from '@/utils';
import { mapState } from 'vuex';
import { makeTestFtd, requestSTCIList, getAction } from '@/utils/api';
import VueJsonPretty from 'vue-json-pretty';
// import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import CopyLeadMenu from './CopyLeadMenu.vue';

export default {
  // TODO: make handlers for events updateActionKey, updateAction
  name: 'ActionDetails',
  components: {
    LoadingSpinner,
    VueJsonPretty,
    CopyLeadMenu,
  },
  props: {
    _id: { type: String, default: '' },
    type: { type: String, default: 'lead' },
    initItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sortedSelectedDetails: { type: Array, default: () => [] },
  },
  data() {
    return {
      requesting: !this.$props.initItem || !this.$props.initItem._id,
      item: cloneDeep(this.$props.initItem),
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'token']),
  },
  methods: {
    getAction() {
      if (this._id === '') return;
      this.requesting = true;
      getAction(this.token, this._id, this.type)
        .then((r) => {
          this.item = cloneDeep(r.data);
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, `Error getting lead by _id ${this._id}`);
          this.requesting = false;
        });
    },
    actionDateTime(clr) {
      return actionDateTime(clr);
    },
    requestSTCIList(_id) {
      this.$store.commit('make_requesting', { req: true });
      requestSTCIList(this.token, { _id })
        .then((r) => {
          this.$emit('updateActionKey', { _id, value: r.data, key: 'STCIList' });
          this.item.STCIList = cloneDeep(r.data);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    makeTestFtd(_id) {
      this.$store.commit('make_requesting', { req: true });
      makeTestFtd(this.token, _id)
        .then((res) => {
          const r = res.data;
          if (!r || !r._id) {
            showCard(
              this,
              'Failed to make test ftd. No such action, or no access to it',
              'warning'
            );
          } else {
            this.$emit('updateAction', { _id, item: r });
            this.getAction();
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    tableFormatterNameAndId(value) {
      return tableFormatterNameAndId(value);
    },
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    selectJsonNode(path, data) {
      console.log('selectJsonNode', path, data);
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      // if (typeof data !== 'object') {
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
      // }
    },
  },
  mounted() {
    if (!this.item || !this.item._id) {
      this.getAction();
    }
  },
};
</script>

<style scoped></style>
