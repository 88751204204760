<template>
  <b-card no-body class="h-100 mb-0 chart-body" :class="[colorClass, textClass]">
    <!--  <div class="card h-100 mb-0">-->
    <b-card-header class="db-header chart-body" :class="[colorClass, textClass]">
      <h5 class="mx-auto mx-md-0 mx-lg-auto mx-xl-0" :class="[textClass]">
        <!--        text-light -->
        <!--        {{ entity }}s-->
        {{ header }}
      </h5>
      <LoadingSpinner :requesting="requesting" small class="mx-2" :show-text="false" />
    </b-card-header>
    <!--    <div class="card-body">-->
    <b-card-body class="p-0 chart-body text-light">
      <apexchart type="line" :options="chartOptions" :series="series" class="_chart" />
      <!--    </div>-->
    </b-card-body>
    <!--  </div>-->
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { getActionsByPeriodChartData } from '@/utils/api';
import { mapState } from 'vuex';
import { dataUpdateTimeoutCondition, handleRequestError } from '@/utils';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
// eslint-disable-next-line no-unused-vars
import { colorVariants, toRGB, contrast } from '@/consts/theme/colors'; // mapColorsNaturalExtended
import tinycolor from 'tinycolor2';
import { DateTime } from 'luxon';
import { startCase } from '@/utils/string';
import { datepickerFormat } from '@/consts';
// import { cloneDeep } from 'lodash';

export default {
  name: 'ActionsByPeriodChart',
  components: {
    LoadingSpinner,
    apexchart: VueApexCharts,
  },
  props: {
    period: { type: String, default: 'week' },
    entity: { type: String, default: 'FTD' },
    variant: { type: String, default: 'transparent' },
    // chartVariant: { type: String, default: undefined },
    text: { type: String, default: 'default' },
    // buttonVariant: { type: String, default: 'outline-primary' },
    gradient: { type: Boolean, default: false },
    generateChartColor: { type: Boolean, default: false },
    lineVariant: { type: String, default: undefined },
    customDate: {
      type: Object,
      default: () => {
        return {
          from: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
          to: DateTime.utc().toISO(),
        };
      },
    },
  },
  data() {
    return {
      controller: new AbortController(),
      timeout: undefined,
      chartOptions: {
        chart: {
          parentHeightOffset: 12,
          height: 350,
          width: '100%',
          type: 'line',
          zoom: {
            enabled: false,
          },
          foreColor: colorVariants[this.text] ? colorVariants[this.text] : colorVariants.default, // '#32325d', // '#373d3f', // 'green',
          toolbar: { show: false },
        },
        colors:
          this.generateChartColor || !this.lineVariant
            ? (() => {
                const variantColor = colorVariants[this.variant]
                  ? colorVariants[this.variant]
                  : colorVariants.default;
                // const variantsRotated = []; // [variantColor];
                const tc = tinycolor(variantColor).saturate(100);
                console.log(tc, 'isDark', tc.isDark(), 'isLight', tc.isLight());
                if (tc.isDark()) tc.brighten(40);
                else if (tc.isLight()) {
                  tc.darken(40);
                }
                tc.spin(137.508); // 180
                return [`#${tc.toHex()}`];
                // for (let n = 0; n <= 10; n += 1) {
                //   // const c = colors[n];
                //   // tc = tc.spin(240 / 10);
                //
                //   variantsRotated.push(`#${tc.toHex()}`);
                //   console.log('tc', tc.toHex());
                // }
                // return variantsRotated;
                // const readabilities = [];
                // for (let i = 0; i < variantsRotated.length; i += 1) {
                //   readabilities.push({
                //     c: variantsRotated[i],
                //     r: tinycolor.readability(variantColor, variantsRotated[i]),
                //   });
                // }
                // readabilities.sort((a, b) => b.r - a.r);
                // const rdblts = readabilities.splice(0, 9);
                // console.log('readabilities', rdblts);
                // return rdblts.map((r) => r.c);
              })()
            : [
                colorVariants[this.lineVariant]
                  ? colorVariants[this.lineVariant]
                  : colorVariants.default,
              ], // ['#F44336', '#E91E63', '#9C27B0'],
        // colors: [colorVariants[this.text] ? colorVariants[this.text] : colorVariants.default],
        dataLabels: {
          enabled: false,
        },
        tooltip: { shared: false, x: { show: true, formatter: (x) => `${x}` } },
        // colors: ['#9a55ff'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: { size: 4, strokeOpacity: 100, strokeWidth: 0 },
        // title: {
        //   text: `FTDs by ${''}`,
        //   align: 'left',
        // },
        grid: {
          // borderColor: 'transparent', // '#32325d', // '#f3f3f3', // 'red', // '#f8f9fa',
          borderColor: colorVariants[this.text] ? colorVariants[this.text] : colorVariants.default,
          strokeDashArray: 5,
          row: {
            // colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            // colors: ['#32325d', 'transparent'], // takes an array which will be repeated on columns
            colors: ['transparent'],
            opacity: 0.1,
          },
        },
        xaxis: {
          categories: [
            // 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'
          ],
          axisBorder: { show: false },
        },
        yaxis: {
          axisBorder: { show: false },
        },
        legend: {
          show: false,
        },
      },

      series: [],
      requesting: false,
      updateTimeout: 120000,
    };
  },
  computed: {
    ...mapState(['token']),
    colorClass() {
      return colorVariants[this.variant]
        ? `bg${this.gradient ? '-gradient' : ''}-${this.variant}`
        : '';
    },
    textClass() {
      return `text-${this.text}`;
    },
    header() {
      const { entity, period } = this;
      return startCase(`${entity}s
        ${
          // period === 'year'
          //   ? 'This Year'
          //   : period === 'month'
          //   ? 'This Month'
          //   : period === 'week'
          //   ? 'This Week'
          //   : period === 'day'
          //   ? 'Today'
          //   :
          period === 'custom'
            ? `${DateTime.fromFormat(this.from, datepickerFormat).toFormat(
                'yyyy-LL-dd'
              )} - ${DateTime.fromFormat(this.to, datepickerFormat).toFormat('yyyy-LL-dd')}`
            : startCase(period) // 'This Week'
        }`);
    },
  },
  methods: {
    updateChartOptions(categories, series) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{ xaxis: { ...this.chartOptions.xaxis, categories } },
        ...{ tooltip: { ...this.chartOptions.tooltip, shared: series.length <= 5 } },
      };
    },
    getActionsByPeriodChartData() {
      clearTimeout(this.timeout);
      this.requesting = true;
      getActionsByPeriodChartData(
        this.token,
        {
          period: this.period,
          type: this.entity,
          from: this.period === 'custom' ? this.customDate.from : undefined,
          to: this.period === 'custom' ? this.customDate.to : undefined,
        },
        this.controller.signal
      )
        .then((r) => {
          this.series = r.data.series;
          this.updateChartOptions(r.data.categories, this.series);
          this.requesting = false;
          if (dataUpdateTimeoutCondition(this.period, this.customDate.to)) {
            this.timeout = setTimeout(() => this.getActionsByPeriodChartData(), this.updateTimeout);
          }
        })
        .catch((e) => {
          handleRequestError(this, e, `Error getting FTDs by Month chart`);
          this.requesting = false;
          if (dataUpdateTimeoutCondition(this.period, this.customDate.to)) {
            this.timeout = setTimeout(() => this.getActionsByPeriodChartData(), this.updateTimeout);
          }
        });
    },
  },
  mounted() {
    this.getActionsByPeriodChartData();
  },
  watch: {
    period() {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getActionsByPeriodChartData();
    },
    'customDate.from': function () {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getActionsByPeriodChartData();
    },
    'customDate.to': function () {
      clearTimeout(this.timeout);
      this.controller.abort();
      this.getActionsByPeriodChartData();
    },
  },
};
</script>

<style scoped>
.db-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.chart-body {
  /*background-color: midnightblue;*/
  /*color: white !important;*/
}
._chart {
  margin-top: -16px;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
