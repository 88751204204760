<template>
  <WorkersTable />
</template>

<script>
import WorkersTable from '@/components/pages/Workers/WorkersTable.vue';

export default {
  name: 'WorkersTableView',
  components: {
    WorkersTable,
  },
};
</script>
