<template>
  <b-container fluid>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">
        {{ $route.path === '/create-box' ? 'Create Box' : 'Edit Box' }}
      </h2>
      <b-button
        v-if="$route.path !== '/create-box'"
        @click="createNewBox()"
        style="display: inline; vertical-align: middle"
      >
        Create new
      </b-button>
      <b-button
        v-if="boxForm._id"
        v-b-modal="`modal-affiliate-api-docs-${boxForm._id}`"
        style="display: inline; vertical-align: middle"
        variant="info"
      >
        Affiliate API Docs
      </b-button>
      <b-modal
        v-if="boxForm._id"
        :id="`modal-affiliate-api-docs-${boxForm._id}`"
        :ref="`modal-affiliate-api-docs-${boxForm._id}`"
        centered
        ok-only
        ok-title="Close"
        size="giant"
        scrollable
        :title="`Affiliate API Integration Docs`"
      >
        <affiliate-api-docs
          :aff-id="boxForm.affiliate._id"
          :box-id="boxForm._id"
        ></affiliate-api-docs>
      </b-modal>
      <b-dropdown
        v-if="boxForm._id && iAmOtherLevelTrustedUser"
        class="text-nowrap mr-2"
        variant="primary"
        right
        no-caret
        style="display: inline; vertical-align: middle"
      >
        <template #button-content>Clone Box Options</template>
        <b-dropdown-item v-b-modal="`modal-copy-campaign-settings-${boxForm._id}`">
          Copy Campaign Settings to Other Boxes
        </b-dropdown-item>
        <b-dropdown-item v-b-modal="`modal-create-box-full-copy-${boxForm._id}`">
          Create This Box's Full Copy
        </b-dropdown-item>
        <b-dropdown-item v-b-modal="`modal-this-box-to-follow-another-${boxForm._id}`">
          Make This Box Follow Another
        </b-dropdown-item>
        <b-dropdown-item v-b-modal="`modal-another-boxes-to-follow-this-${boxForm._id}`">
          Make Other Boxes Follow This
        </b-dropdown-item>

        <b-modal
          :id="`modal-copy-campaign-settings-${boxForm._id}`"
          :ref="`modal-copy-campaign-settings-${boxForm._id}`"
          centered
          ok-title="Copy"
          @ok="copyBoxSettings()"
          size="lg"
          :title="`Select Boxes To Where Copy Settings`"
        >
          <multiselect
            v-model="boxTargetsToCopy"
            :options="boxesOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
        </b-modal>
        <b-modal
          :id="`copy-box-settings-result-${boxForm._id}`"
          :ref="`copy-box-settings-result-${boxForm._id}`"
          centered
          ok-only
          size="md"
          scrollable
          :title="boxCopyResult"
        >
          <p v-if="boxCopyResultMessage && boxCopyResultMessage.length > 0">
            {{ boxCopyResultMessage }}
          </p>
          <div v-if="boxCopyResults.successful && boxCopyResults.successful.length > 0">
            <h6>Successfuly Copied</h6>
            <ul>
              <li v-for="(b, i) in boxCopyResults.successful" :key="i">
                {{ b ? (b.name ? b.name : b._id) : b }}
              </li>
            </ul>
          </div>
          <div v-if="boxCopyResults.failed && boxCopyResults.failed.length > 0">
            <h6>Failed To Copy</h6>
            <ul>
              <li v-for="(b, i) in boxCopyResults.failed" :key="i">
                {{ b ? (b.name ? b.name : b._id) : b }}
              </li>
            </ul>
          </div>
        </b-modal>
        <b-modal
          :id="`modal-create-box-full-copy-${boxForm._id}`"
          :ref="`modal-create-box-full-copy-${boxForm._id}`"
          centered
          ok-title="Create"
          @ok="createBoxFullCopy()"
          size="lg"
          :title="`Create This Box Full Copy`"
        ></b-modal>
        <b-modal
          :id="`create-box-full-copy-result-${boxForm._id}`"
          :ref="`create-box-full-copy-result-${boxForm._id}`"
          centered
          ok-only
          size="md"
          title="Creating Full Copy Result"
        >
          <p>{{ boxFullCopyResult }}</p>
        </b-modal>
        <b-modal
          :id="`modal-this-box-to-follow-another-${boxForm._id}`"
          :ref="`modal-this-box-to-follow-another-${boxForm._id}`"
          centered
          ok-title="Follow"
          size="lg"
          :title="`Select Box Which To Follow`"
        >
          <multiselect
            v-model="boxForm.followBox"
            :options="boxesOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="false"
          ></multiselect>
          <p>After clicking Follow click Save button to save changes</p>
        </b-modal>
        <b-modal
          :id="`modal-another-boxes-to-follow-this-${boxForm._id}`"
          :ref="`modal-another-boxes-to-follow-this-${boxForm._id}`"
          centered
          ok-title="Assign Followers"
          size="lg"
          :title="`Select Boxes That Must Follow This One`"
        >
          <multiselect
            v-model="boxForm.boxFollowers"
            :options="boxesOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
          <p>After clicking Follow click Save button to save changes</p>
        </b-modal>
      </b-dropdown>

      <b-button
        @click="saveBox()"
        variant="primary"
        :disabled="!isValid.form || requesting || boxForm.defaultCampaignsBox"
        style="display: inline; vertical-align: middle"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        {{ $route.params.id ? 'Save Box' : 'Create Box' }}
      </b-button>
      <b-button
        v-if="boxForm._id && iAmOtherLevelTrustedUser && !$rent"
        v-b-modal="`box-demand-calculator-${boxForm._id}`"
        style="display: inline; vertical-align: middle"
        variant="info"
      >
        Demand Calculator
      </b-button>
    </div>
    <h4 class="my-2" v-if="boxForm._id">ID: {{ boxForm._id }}</h4>
    <b-form-group
      class="my-2"
      label="Name"
      label-for="box-name"
      :invalid-feedback="invalidFeedback.box.name"
      :state="isValid.fields.name"
    >
      <b-form-input
        id="box-dailycap-amount"
        :state="isValid.fields.name"
        v-model="boxForm.name"
      ></b-form-input>
    </b-form-group>
    <b-form-checkbox class="my-2" v-model="boxForm.active">Active</b-form-checkbox>
    <b-row>
      <b-col cols="12" lg="4" md="4">
        <b-form-group
          class="my-2"
          label="Affiliate"
          label-for="box-affiliate"
          :invalid-feedback="invalidFeedback.affiliate"
          :state="isValid.fields.affiliate"
        >
          <b-form-select
            id="box-affiliate"
            v-model="boxForm.affiliate"
            :options="affiliatesOptions"
            :state="isValid.fields.affiliate"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="user.type === 'admin'"
          class="my-2"
          label="Manager"
          label-for="box-manager"
          :invalid-feedback="invalidFeedback.manager"
          :state="isValid.fields.manager"
        >
          <b-form-select
            id="box-manager"
            :state="isValid.fields.manager"
            v-model="boxForm.manager"
            :options="managersOptions"
            text-field="name"
            value-field="_id"
          ></b-form-select>
        </b-form-group>
        <p v-else>Manager: {{ user.name }}</p>
      </b-col>
      <b-col cols="12" lg="4" md="4">
        <b-form-group
          class="my-2"
          label="Daily Cap"
          label-for="box-dailycap-type"
          :invalid-feedback="invalidFeedback.dailyCap.type"
          :state="isValid.fields.dailyCap.type"
        >
          <b-form-select
            id="box-daily-cap"
            :state="isValid.fields.dailyCap.type"
            v-model="boxForm.dailyCap.type"
            :options="actionTypeOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          class="my-2"
          label="Total Cap"
          label-for="box-totalcap-type"
          :invalid-feedback="invalidFeedback.totalCap.type"
          :state="isValid.fields.totalCap.type"
        >
          <b-form-select
            id="box-totalcap-type"
            :state="isValid.fields.totalCap.type"
            v-model="boxForm.totalCap.type"
            :options="actionTypeOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="4" md="4">
        <b-form-group
          class="my-2"
          label="Amount"
          label-for="box-dailycap-amount"
          :invalid-feedback="invalidFeedback.dailyCap.amount"
          :state="isValid.fields.dailyCap.amount"
        >
          <b-form-input
            id="box-dailycap-amount"
            :state="isValid.fields.dailyCap.amount"
            v-model="boxForm.dailyCap.amount"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          class="my-2"
          label="Amount"
          label-for="box-totalcap-amount"
          :invalid-feedback="invalidFeedback.totalCap.amount"
          :state="isValid.fields.totalCap.amount"
        >
          <b-form-input
            id="box-totalcap-amount"
            :state="isValid.fields.totalCap.amount"
            v-model="boxForm.totalCap.amount"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <input-with-help
      cols-main="2"
      cols-help="10"
      size="0.8"
      class="mb-4 mb-sm-4 mb-md-0 mt-4 mt-sm-4 mt-md-0"
      help-text="If True, the ip can be selected randomly corresponding to Lead's geo if the Affiliate sets 'si' or 'lf' flags to True when sending lead. This also allowes Affiliates to send leads without IP."
      :show-help="false"
    >
      <b-checkbox v-if="isAdmin" v-model="boxForm.csi">Override Lead's IP</b-checkbox>
    </input-with-help>
    <input-with-help
      cols-main="2"
      cols-help="10"
      size="0.8"
      class="mb-4 mb-sm-4 mb-md-0"
      :show-help="false"
      help-text="If True, the 'so' (source) of the lead will be changed to the one set in this Box if the Affiliate sets 'ls' flag to True. Warning: if the sources are set up in the Campaigns, including TECH campaign, the final source name will be taken from there."
    >
      <b-checkbox v-if="isAdmin" v-model="boxForm.cso">Override Lead's Source</b-checkbox>
    </input-with-help>

    <input-with-help
      cols-main="2"
      cols-help="10"
      size="0.8"
      class="mb-4 mb-sm-4 mb-md-0"
      v-if="isAdmin && boxForm.cso"
      :show-help="false"
      help-text="The 'so' (source) of the lead will be changed to this if the Affiliate sets 'ls' flag to True. Warning: if the sources are set up in the Campaigns, including TECH campaign, the final source name will be taken from there."
    >
      <b-form-group
        v-if="isAdmin && boxForm.cso"
        class="my-2"
        label="Source To Override"
        label-for="box-dso"
        :state="isValid.fields.dso"
      >
        <b-form-input
          v-if="isAdmin"
          id="box-dso"
          :state="isValid.fields.dso"
          v-model="boxForm.dso"
        ></b-form-input>
      </b-form-group>
    </input-with-help>
    <input-with-help
      cols-main="2"
      cols-help="10"
      size="0.8"
      class="mb-4 mb-sm-4 mb-md-0"
      :show-help="false"
      help-text="If True, this Box will be used only to send TEST leads. Test leads can be sent only to the box marked as Test."
    >
      <b-checkbox v-model="boxForm.isTest">Is Test box</b-checkbox>
    </input-with-help>
    <input-with-help
      cols-main="2"
      cols-help="10"
      size="0.8"
      class="mb-4 mb-sm-4 mb-md-0"
      :show-help="false"
      help-text="If True, the system will use more balanced algorithm to distribute leads more equally between Campaigns in this Box according to their Weights.
      If False, leads will be distributed more randomly, but still with regard to the Campaigns' Weights."
    >
      <b-checkbox v-model="boxForm.loadBalancer">Use Load Balancer</b-checkbox>
    </input-with-help>
    <input-with-help
      v-if="!$rent"
      cols-main="2"
      cols-help="10"
      size="0.8"
      class="mb-4 mb-sm-4 mb-md-0"
      :show-help="false"
      help-text="If True, all the leads sent to this Box will be marked as M"
    >
      <b-checkbox v-if="!$rent" v-model="boxForm.isMBox">MBox</b-checkbox>
    </input-with-help>
    <input-with-help
      cols-main="2"
      cols-help="10"
      size="0.8"
      class="mb-4 mb-sm-4 mb-md-0"
      :show-help="false"
      help-text="If True, the system will allow send leads to all the Campaigns in this Box with no regard of the interval between leads.
      If False, leads will be sent to each Campaign in this Box with time interval not less than 20 min."
    >
      <b-checkbox v-model="boxForm.liveOmitTimeIntervalCheck">
        Live (Omit BCE Time Interval Check)
      </b-checkbox>
    </input-with-help>
    <!--    <b-row>-->
    <!--      <b-col cols="12" lg="4" md="4">-->
    <!--        <b-form-group-->
    <!--          class="my-2"-->
    <!--          label="Load Balancer Coefficient"-->
    <!--          label-for="load-balancer-coefficient"-->
    <!--          :invalid-feedback="invalidFeedback.box.loadBalancerCoefficient"-->
    <!--          :state="isValid.fields.loadBalancerCoefficient"-->
    <!--        >-->
    <!--          <b-form-input-->
    <!--            id="load-balancer-coefficient"-->
    <!--            type="number"-->
    <!--            :state="isValid.fields.loadBalancerCoefficient"-->
    <!--            v-model="boxForm.loadBalancerCoefficient"-->
    <!--          ></b-form-input>-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <h3 class="pt-3 mb-3">Campaigns</h3>
    <!--    <b-input-group class="mb-3">-->
    <!--      <b-input-group-prepend is-text>-->
    <!--        <b-icon icon="search"></b-icon>-->
    <!--      </b-input-group-prepend>-->
    <!--      <b-form-input-->
    <!--        v-model="filter"-->
    <!--        type="search"-->
    <!--        id="filterInput"-->
    <!--        placeholder="Type to Search"-->
    <!--      ></b-form-input>-->
    <!--      <b-input-group-append>-->
    <!--        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
    <!--      </b-input-group-append>-->
    <!--    </b-input-group>-->
    <b-row class="my-3">
      <b-col class="col-12 col-md-12 col-lg-4 mb-lg-0">
        <span class="d-block h5 font-weight-normal">Campaigns</span>
        <b-input-group>
          <!--          <template #default>-->
          <multiselect
            v-model="filter.campaigns"
            :options="filterCampaignOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
          <!--          </template>-->
          <b-input-group-append>
            <b-button @click="selectAllFilterCampaigns()" variant="primary">Select All</b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button @click="clearFilterCampaigns()" variant="warning">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <!--       used to be in next col -->
        <span class="d-block h5 font-weight-normal">Geos</span>
        <b-input-group>
          <!--          <template #default>-->
          <multiselect
            v-model="filter.countries"
            :options="filterCountryOptions"
            track-by="value"
            :custom-label="countryWithCode"
            @search-change="countryMultiSelectSearch"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
          <!--          </template>-->
          <b-input-group-append>
            <b-button @click="selectAllFilterCountries()" variant="primary">Select All</b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button @click="clearFilterCountries()" variant="warning">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <!--        /used to be in next col -->
      </b-col>
      <b-col class="col-12 col-md-12 col-lg-4" v-if="boxForm._id">
        <b-card header="Add Multiple Campaigns to this Box for Multiple GEOs">
          <AddCampaignToBox
            :box="thisBox"
            @addCampaignsToBoxes="
              updateData();
              getBox(boxForm._id);
            "
          />
          <!--          getThisBox(boxForm) -->
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-12 col-lg-4" v-if="boxForm._id">
        <b-card header="Remove Multiple Campaigns from this Box for Multiple GEOs">
          <RemoveCampaignsFromBoxes
            :box="thisBox"
            @removeCampaignsFromBoxes="
              updateData();
              getBox(boxForm._id);
            "
          />
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-if="boxForm._id && iAmOtherLevelTrustedUser && !$rent"
      :id="`box-demand-calculator-${boxForm._id}`"
      :ref="`box-demand-calculator-${boxForm._id}`"
      centered
      ok-only
      ok-title="Close"
      size="giant"
      scrollable
      :title="`Box Demand Calculator`"
    >
      <b-row class="my-3" v-if="iAmOtherLevelTrustedUser && !$rent">
        <b-col class="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0 d-md-flex">
          <span class="d-block h5 font-weight-normal">Hour</span>
          <b-input-group>
            <b-form-input v-model="demandForm.hour"></b-form-input>
            <b-input-group-append>
              <b-button @click="selectHourNow()" variant="primary">Select Now</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="col-12 col-md-6 col-lg-4 mb-3 d-md-flex">
          <span class="d-block h5 font-weight-normal">Geo</span>
          <b-input-group>
            <!--          <template #default>-->
            <multiselect
              v-model="demandForm.country"
              :options="filterCountryOptions"
              track-by="value"
              :custom-label="countryWithCode"
              @search-change="countryMultiSelectSearch"
              :closeOnSelect="false"
              :multiple="false"
            ></multiselect>
          </b-input-group>
        </b-col>
        <b-col class="col-12 col-md-6 col-lg-4 mb-3">
          <b-button @click="calculateDemand()" class="mx-2" variant="primary">
            Calculate Demand
          </b-button>
          <span>
            Demand
            {{
              demandResult !== undefined
                ? `${demandResult}
          leads per hour`
                : 'unknown'
            }}
          </span>
        </b-col>
      </b-row>
      <b-row
        class="my-3"
        v-if="iAmOtherLevelTrustedUser && user.email === 'blacklight@default.gg' && !$rent"
      >
        <b-col class="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0 d-md-flex">
          <span class="d-block h5 font-weight-normal">Day</span>
          <b-input-group>
            <b-form-input v-model="activeHoursForm.day"></b-form-input>
            <b-input-group-append>
              <b-button @click="selectActiveDayNow()" variant="primary">Select Now</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="col-12 col-md-6 col-lg-4 mb-3 d-md-flex">
          <span class="d-block h5 font-weight-normal">Geo</span>
          <b-input-group>
            <!--          <template #default>-->
            <multiselect
              v-model="activeHoursForm.country"
              :options="filterCountryOptions"
              track-by="value"
              :custom-label="countryWithCode"
              @search-change="countryMultiSelectSearch"
              :closeOnSelect="false"
              :multiple="false"
            ></multiselect>
          </b-input-group>
        </b-col>
        <b-col class="col-12 col-md-6 col-lg-4 mb-3">
          <b-button @click="calculateActiveHours()" class="mx-2" variant="primary">
            Calculate Active Hours
          </b-button>
          <span>
            Active Hours
            {{
              activeHoursResult !== undefined
                ? `${activeHoursResult ? activeHoursResult.join(', ') : 'no active hours'}`
                : 'unknown'
            }}
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <b-table
      class="mb-3"
      style="white-space: nowrap"
      sort-by="active"
      :sort-desc="true"
      :fields="fields"
      :items="bceTableItems"
      :filter-function="bceFilterFunction"
      :filter="filter"
      :busy="requesting"
      @filtered="onFiltered"
      @sort-changed="changeSorting"
      show-empty
      striped
      responsive
      hover
      small
    >
      <!--            :items="boxForm.campaigns"
-->

      <!--      <template #cell()="data">-->
      <!--        <div @click="data.toggleDetails">{{ data.value }}</div>-->
      <!--      </template>-->

      <!--      <template #cell(campaign)="data">-->
      <!--        &lt;!&ndash;        <b-link @click="data.toggleDetails" style="color: inherit">&ndash;&gt;-->
      <!--        <div @click="data.toggleDetails">{{ data.value }}</div>-->
      <!--        &lt;!&ndash;        </b-link>&ndash;&gt;-->
      <!--      </template>-->
      <template
        #cell(campaign)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div
          @click="editFieldOn($event, bce.field.key, bce.item.bce_index)"
          style="overflow: visible"
        >
          {{ bce.value ? bce.value : 'none' }}

          <b-form
            style="width: 270px; overflow: visible"
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <multiselect
              :value="boxForm.campaigns[bce.item.bce_index].campaign_Multiselect"
              @input="($event) => handleCampaignsMultiselect($event, bce.item.bce_index)"
              :options="campaignsMultiselectOptions"
              track-by="name"
              label="name"
              :closeOnSelect="true"
              :multiple="false"
              :state="isValid.fields.campaigns[bce.item.bce_index].campaign"
              class="mb-2"
              :allow-empty="false"
              :maxHeight="600"
            ></multiselect>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <!--          <b-button variant="success" @on:click="makeBoxActive(data.item._id, true)">-->
          <!--            <b-icon icon="play-fill"></b-icon>-->
          <!--            Play-->
          <!--          </b-button>-->
          <!--          <b-button variant="warning" @on:click="makeBoxActive(data.item._id, false)">-->
          <!--            <b-icon icon="pause-fill"></b-icon>-->
          <!--            Pause-->
          <!--          </b-button>-->
          <b-button variant="primary" @click="data.toggleDetails">
            <!--             @on:click="startEditBox(data.item._id)"-->
            <b-icon icon="pencil-square"></b-icon>
            {{ data.detailsShowing ? 'Editing' : 'Edit' }}
          </b-button>
          <b-dropdown variant="danger" right no-caret size="sm">
            <template #button-content>
              <b-icon icon="trash-fill"></b-icon>
            </template>
            <b-dropdown-item-button
              variant="danger"
              v-on:click="deleteRow(data.item.bce_index)"
              :disabled="requesting"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Delete campaign
            </b-dropdown-item-button>
          </b-dropdown>
          <!--          </b-button>-->
          <!--          <b-button variant="danger">-->
          <!--            <b-icon icon="trash-fill"></b-icon>-->
          <!--            Delete-->
          <!--          </b-button>-->
        </b-button-group>
      </template>

      <template #cell(active)="bce">
        <h4 :id="`bce-cell-${bce.field.key}-${bce.item.bce_index}`">
          <b-badge
            :variant="bce.value === true ? 'success' : 'danger'"
            @click="toggleBCEActive(bce.item)"
            class="badge-toggle"
          >
            {{ bce.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h4>
        <b-popover
          :target="`bce-cell-${bce.field.key}-${bce.item.bce_index}`"
          triggers="hover"
          placement="top"
          :delay="{ show: 50, hide: 300 }"
        >
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
          >
            Apply To All Visible
          </b-button>
        </b-popover>
      </template>
      <template #cell(useLimitMPerBCE)="bce">
        <h4 :id="`bce-cell-${bce.field.key}-${bce.item.bce_index}`">
          <b-badge
            :variant="bce.value === true ? 'success' : 'info'"
            @click="toggleBCEUseLimitMPerBCE(bce.item)"
            class="badge-toggle"
          >
            {{ bce.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h4>
        <b-popover
          :target="`bce-cell-${bce.field.key}-${bce.item.bce_index}`"
          triggers="hover"
          placement="top"
          :delay="{ show: 50, hide: 300 }"
        >
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
          >
            Apply To All Visible
          </b-button>
        </b-popover>
      </template>
      <template #cell(useLinkCheckService)="bce">
        <h4 :id="`bce-cell-${bce.field.key}-${bce.item.bce_index}`">
          <b-badge
            :variant="bce.value === true ? 'warning' : 'info'"
            @click="
              iAmOtherLevelTrustedUser ? toggleBCEUseLinkCheckService(bce.item) : (() => {})()
            "
            :class="iAmOtherLevelTrustedUser ? 'badge-toggle' : ''"
          >
            {{ bce.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h4>
        <b-popover
          v-if="iAmOtherLevelTrustedUser"
          :target="`bce-cell-${bce.field.key}-${bce.item.bce_index}`"
          triggers="hover"
          placement="top"
          :delay="{ show: 50, hide: 300 }"
        >
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
          >
            Apply To All Visible
          </b-button>
        </b-popover>
      </template>
      <template
        #cell(days)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div @click="editFieldOn($event, bce.field.key, bce.item.bce_index)">
          <h4 :id="`bce-intable-span-${bce.item.bce_index}-days`">
            <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].days ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
          <b-form v-if="editingField === bce.field.key && editingItem === bce.item.bce_index">
            <b-form-checkbox-group
              :id="`box-campaign${bce.item.bce_index}-days`"
              v-model="boxForm.campaigns[bce.item.bce_index].days"
              :options="daysOfWeek"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              size="lg"
              stacked
            ></b-form-checkbox-group>
            <!--            @input="backdoor += 1"-->
            <b-button
              class="mr-2"
              variant="primary"
              @click="boxForm.campaigns[bce.item.bce_index].days = [0, 1, 2, 3, 4, 5, 6]"
            >
              <!--              backdoor += 1;-->
              All
            </b-button>
            <b-button @click="boxForm.campaigns[bce.item.bce_index].days = []">
              <!--               backdoor += 1;-->
              None
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template
        #cell(hourLimit)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div @click="editFieldOn($event, bce.field.key, bce.item.bce_index)">
          <h4 :id="`bce-intable-span-${bce.item.bce_index}-hourLimit`">
            <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
            <b-badge variant="info" class="badge-toggle">
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
          <b-form v-if="editingField === bce.field.key && editingItem === bce.item.bce_index">
            <div>
              <h6 class="mr-1">
                From: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[0] }}:00
              </h6>
              <b-form-input
                v-model="
                  boxForm.campaigns[
                    bce.item.bce_index // boxForm.campaigns.findIndex((c) => c.campaign === bce.item.campaign)
                  ].hourLimit[0]
                "
                type="range"
                min="0"
                :max="23"
              ></b-form-input>
              <h6 class="mx-1 my-2">
                to: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[1] }}:59
              </h6>
              <b-form-input
                v-model="boxForm.campaigns[bce.item.bce_index].hourLimit[1]"
                type="range"
                :min="0"
                max="23"
              ></b-form-input>
            </div>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template
        #cell(countries)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div @click="editFieldOn($event, bce.field.key, bce.item.bce_index)">
          <h4 :id="`bce-intable-span-${bce.item.bce_index}-countries`">
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].countries ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
          <b-form v-if="editingField === bce.field.key && editingItem === bce.item.bce_index">
            <multiselect
              v-model="boxForm.campaigns[bce.item.bce_index].countries"
              :options="countryOptions"
              track-by="value"
              :custom-label="countryWithCode"
              :closeOnSelect="false"
              :multiple="true"
              :state="isValid.fields.campaigns[bce.item.bce_index].countries"
              :style="
                isValid.fields.campaigns[bce.item.bce_index].countries ? 'border-color: red;' : ''
              "
              @input="
                applyDefaultBceSettings(bce.item.bce_index);
                backdoor += 1;
              "
              @search-change="countryMultiSelectSearch"
            >
              <!--                :showLabels="false"-->
            </multiselect>
            <b-button
              @click="selectAllCountries(bce.item.bce_index)"
              variant="info"
              class="my-2 d-block"
            >
              Select all accepted geos
            </b-button>
            <b-button
              @click="clearCountries(bce.item.bce_index)"
              variant="danger"
              class="my-2 d-block"
            >
              Clear geos
            </b-button>

            <div
              v-if="selectedBCECountries.selected[bce.item.bce_index]"
              style="white-space: normal"
            >
              <b-card
                class="my-2 text-center"
                style="display: block; max-width: 100%; max-height: 88px; overflow: auto"
              >
                <b-card-text>
                  Campaign accepts geos:
                  <b-badge
                    class="mx-1"
                    v-for="(c, index) in selectedBCECountries.campaignsAccept[
                      bce.item.bce_index
                    ][1]"
                    v-bind:key="index"
                  >
                    {{ c }}
                  </b-badge>
                </b-card-text>
              </b-card>
              <b-card
                style="display: block; max-width: 100%"
                v-if="selectedBCECountries.dontMatch[bce.item.bce_index][1].length > 0"
                border-variant="warning"
                header="Warning"
                header-bg-variant="warning"
                footer-bg-variant="transparent"
                class="my-2 text-center"
                no-body
              >
                <b-card-body style="max-height: 66px; overflow: auto">
                  <b-card-text style="word-wrap: break-word">
                    This campaign doesn't accept selected countries:
                    <b-badge
                      class="mx-1"
                      variant="warning"
                      v-for="(c, index) in selectedBCECountries.dontMatch[bce.item.bce_index][1]"
                      v-bind:key="index"
                    >
                      {{ c.value }}
                    </b-badge>
                  </b-card-text>
                </b-card-body>
                <template #footer>
                  <b-card-text>
                    Consider
                    <router-link
                      @click.native="goToEditCampaign()"
                      :to="`/campaign/edit/${
                        selectedBCECountries.dontMatch[bce.item.bce_index][0]
                      }?b=${$route.params.id ? $route.params.id : 'new'}`"
                    >
                      edit campaign
                    </router-link>
                    .
                  </b-card-text>
                </template>
              </b-card>
            </div>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template
        #cell(dailyCap)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div
          class="badge-toggle"
          :id="`bce-dailycap-intable-form-${bce.item.bce_index}`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-dailycap-intable-edit-${bce.item.bce_index}-value`
            )
          "
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              :id="`bce-dailycap-intable-edit-${bce.item.bce_index}-value`"
              :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount"
              v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.amount"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
              style="width: 120px"
              class="m-1"
            ></b-form-input>
            <b-form-select
              :id="`bce-dailycap-intable-edit-${bce.item.bce_index}-type`"
              :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.type"
              v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.type"
              :options="actionTypeOptions"
              style="width: 120px"
              class="m-1"
            ></b-form-select>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-dailycap-intable-span-${bce.item.bce_index}`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-dailycap-intable-span-${bce.item.bce_index}`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].dailyCap.type &&
                isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount
                  ? bce.value === '-'
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template
        #cell(mFtdsDailyCap)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div
          class="badge-toggle"
          :id="`bce-mftdsdailycap-intable-form-${bce.item.bce_index}`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-mftdsdailycap-intable-edit-${bce.item.bce_index}-value`
            )
          "
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-mftdsdailycap-intable-edit-${bce.item.bce_index}-value`"
              v-model="boxForm.campaigns[bce.item.bce_index].mFtdsDailyCap"
              :state="isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <h4
            :id="`bce-mftdsdailycap-intable-span-${bce.item.bce_index}`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap
                  ? bce.value === 0
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(totalCap)="bce">
        <div
          class="badge-toggle"
          :id="`bce-dailycap-intable-form-${bce.item.bce_index}`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-totalCap-intable-edit-${bce.item.bce_index}-value`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              :id="`bce-totalCap-intable-edit-${bce.item.bce_index}-value`"
              :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.amount"
              v-model="boxForm.campaigns[bce.item.bce_index].totalCap.amount"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
              style="width: 120px"
              class="m-1"
            ></b-form-input>
            <b-form-select
              :id="`bce-totalCap-intable-edit-${bce.item.bce_index}-type`"
              :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.type"
              v-model="boxForm.campaigns[bce.item.bce_index].totalCap.type"
              :options="actionTypeOptions"
              style="width: 120px"
              class="m-1"
            ></b-form-select>

            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-totalCap-intable-span-${bce.item.bce_index}`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-totalCap-intable-span-${bce.item.bce_index}`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].totalCap.type &&
                isValid.fields.campaigns[bce.item.bce_index].totalCap.amount
                  ? bce.value === '-'
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(limitMPerBCE)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-limitmperbce`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-limitmperbce`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-limitmperbce`"
              v-model="boxForm.campaigns[bce.item.bce_index].limitMPerBCE"
              :state="isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-limitmperbce`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value ? bce.value : 'unlimited' }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-limitmperbce`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE
                  ? !bce.value
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'unlimited' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(weight)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-weight`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-weight`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-weight`"
              v-model="boxForm.campaigns[bce.item.bce_index].weight"
              :state="isValid.fields.campaigns[bce.item.bce_index].weight"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-weight`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-weight`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].weight ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(priority)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-priority`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-priority`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-priority`"
              v-model="boxForm.campaigns[bce.item.bce_index].priority"
              :state="isValid.fields.campaigns[bce.item.bce_index].priority"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-priority`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-priority`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].priority ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(defaultPayment)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-defaultPayment`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-defaultPayment`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-defaultPayment`"
              v-model="boxForm.campaigns[bce.item.bce_index].defaultPayment"
              :state="isValid.fields.campaigns[bce.item.bce_index].defaultPayment"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-defaultPayment`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-defaultPayment`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].defaultPayment ? 'info' : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>
      <template #cell(aff_defaultPayment)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-aff_defaultPayment`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-aff_defaultPayment`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-aff_defaultPayment`"
              v-model="boxForm.campaigns[bce.item.bce_index].aff_defaultPayment"
              :state="isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-aff_defaultPayment`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-aff_defaultPayment`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment ? 'info' : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #row-details="bce">
        <b-list-group-item
          :variant="
            isValid.campaigns[bce.item.bce_index] && bce.item.campaignActive
              ? selectedBCECountries.dontMatch[bce.item.bce_index][1].length === 0
                ? 'success'
                : 'warning'
              : 'danger'
          "
        >
          <h3 v-if="!bce.item.campaignActive">Attention: Campaign Inactive</h3>
          <!--          <h6>{{ bce.item.bce_index + 1 }}</h6>-->
          <b-row>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Campaign"
                :label-for="`box-campaign${bce.item.bce_index}-campaign`"
                :invalid-feedback="invalidFeedback.box.campaigns.campaign"
                :state="isValid.fields.campaigns[bce.item.bce_index].campaign"
              >
                <!--                <b-form-select-->
                <!--                  :id="`box-campaign${bce.item.bce_index}-campaign`"-->
                <!--                  class="mb-2"-->
                <!--                  v-model="boxForm.campaigns[bce.item.bce_index].campaign"-->
                <!--                  :options="campaignOptions"-->
                <!--                  @input="backdoor += 1"-->
                <!--                  :state="isValid.fields.campaigns[bce.item.bce_index].campaign"-->
                <!--                ></b-form-select>-->
                <multiselect
                  :value="boxForm.campaigns[bce.item.bce_index].campaign_Multiselect"
                  @input="($event) => handleCampaignsMultiselect($event, bce.item.bce_index)"
                  :options="campaignsMultiselectOptions"
                  track-by="name"
                  label="name"
                  :closeOnSelect="true"
                  :multiple="false"
                  :state="isValid.fields.campaigns[bce.item.bce_index].campaign"
                  class="mb-2"
                  :allow-empty="false"
                  :maxHeight="600"
                ></multiselect>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Geos"
                label-for=""
                :state="isValid.fields.campaigns[bce.item.bce_index].countries"
              >
                <multiselect
                  v-model="boxForm.campaigns[bce.item.bce_index].countries"
                  :options="countryOptions"
                  track-by="value"
                  :custom-label="countryWithCode"
                  :closeOnSelect="false"
                  :multiple="true"
                  :state="isValid.fields.campaigns[bce.item.bce_index].countries"
                  :style="
                    isValid.fields.campaigns[bce.item.bce_index].countries
                      ? 'border-color: red;'
                      : ''
                  "
                  @input="
                    applyDefaultBceSettings(bce.item.bce_index);
                    backdoor += 1;
                  "
                  @search-change="countryMultiSelectSearch"
                >
                  <!--                :showLabels="false"-->
                </multiselect>
              </b-form-group>
              <b-button
                @click="selectAllCountries(bce.item.bce_index)"
                variant="info"
                class="my-2 d-block"
              >
                Select all accepted geos
              </b-button>
              <b-button
                @click="clearCountries(bce.item.bce_index)"
                variant="danger"
                class="my-2 d-block"
              >
                Clear geos
              </b-button>

              <div
                v-if="selectedBCECountries.selected[bce.item.bce_index]"
                style="white-space: normal"
              >
                <b-card
                  class="my-2 text-center"
                  style="display: block; max-width: 100%; max-height: 88px; overflow: auto"
                >
                  <b-card-text>
                    Campaign accepts geos:
                    <b-badge
                      class="mx-1"
                      v-for="(c, index) in selectedBCECountries.campaignsAccept[
                        bce.item.bce_index
                      ][1]"
                      v-bind:key="index"
                    >
                      {{ c }}
                    </b-badge>
                  </b-card-text>
                </b-card>
                <b-card
                  style="display: block; max-width: 100%"
                  v-if="selectedBCECountries.dontMatch[bce.item.bce_index][1].length > 0"
                  border-variant="warning"
                  header="Warning"
                  header-bg-variant="warning"
                  footer-bg-variant="transparent"
                  class="my-2 text-center"
                  no-body
                >
                  <b-card-body style="max-height: 66px; overflow: auto">
                    <b-card-text style="word-wrap: break-word">
                      This campaign doesn't accept selected countries:
                      <b-badge
                        class="mx-1"
                        variant="warning"
                        v-for="(c, index) in selectedBCECountries.dontMatch[bce.item.bce_index][1]"
                        v-bind:key="index"
                      >
                        {{ c.value }}
                      </b-badge>
                    </b-card-text>
                  </b-card-body>
                  <template #footer>
                    <b-card-text>
                      Consider
                      <router-link
                        @click.native="goToEditCampaign()"
                        :to="`/campaign/edit/${
                          selectedBCECountries.dontMatch[bce.item.bce_index][0]
                        }?b=${$route.params.id ? $route.params.id : 'new'}`"
                      >
                        edit campaign
                      </router-link>
                      .
                    </b-card-text>
                  </template>
                </b-card>
              </div>

              <b-form-checkbox
                class="my-2"
                v-model="boxForm.campaigns[bce.item.bce_index].active"
                :disabled="
                  Object.values(boxForm.campaigns[bce.item.bce_index].campaign).includes(
                    'TECH_CAMPAIGN'
                  ) ||
                  (campaigns.find(
                    (c) => c._id === boxForm.campaigns[bce.item.bce_index].campaign
                  ) &&
                    campaigns.find((c) => c._id === boxForm.campaigns[bce.item.bce_index].campaign)
                      .brokerApiType === 'TECH_CAMPAIGN')
                "
              >
                Active
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <h6 class="mb-2">Hour Limit</h6>
              <h6 class="mr-1">
                From: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[0] }}:00
              </h6>
              <b-form-input
                v-model="
                  boxForm.campaigns[
                    bce.item.bce_index // boxForm.campaigns.findIndex((c) => c.campaign === bce.item.campaign)
                  ].hourLimit[0]
                "
                type="range"
                min="0"
                :max="23"
                @input="backdoor += 1"
              ></b-form-input>
              <h6 class="mx-1 my-2">
                to: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[1] }}:59
              </h6>
              <b-form-input
                v-model="boxForm.campaigns[bce.item.bce_index].hourLimit[1]"
                type="range"
                :min="0"
                max="23"
                @input="backdoor += 1"
              ></b-form-input>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Days"
                :label-for="`box-campaign${bce.item.bce_index}-days`"
              >
                <b-form-checkbox-group
                  :id="`box-campaign${bce.item.bce_index}-days`"
                  v-model="boxForm.campaigns[bce.item.bce_index].days"
                  :options="daysOfWeek"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  size="lg"
                  stacked
                  @input="backdoor += 1"
                ></b-form-checkbox-group>
                <b-button
                  class="mr-2"
                  variant="primary"
                  @click="
                    boxForm.campaigns[bce.item.bce_index].days = [0, 1, 2, 3, 4, 5, 6];
                    backdoor += 1;
                  "
                >
                  All
                </b-button>
                <b-button
                  @click="
                    boxForm.campaigns[bce.item.bce_index].days = [];
                    backdoor += 1;
                  "
                >
                  None
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Priority"
                :label-for="`box-campaign${bce.item.bce_index}-priority`"
                :invalid-feedback="invalidFeedback.box.campaigns.priority"
                :state="isValid.fields.campaigns[bce.item.bce_index].priority"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-priority`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].priority"
                  v-model="boxForm.campaigns[bce.item.bce_index].priority"
                  @input="backdoor += 1"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Weight"
                :label-for="`box-campaign${bce.item.bce_index}-weight`"
                :invalid-feedback="invalidFeedback.box.campaigns.weight"
                :state="isValid.fields.campaigns[bce.item.bce_index].weight"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-weight`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].weight"
                  v-model="boxForm.campaigns[bce.item.bce_index].weight"
                  @input="backdoor += 1"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Daily Cap"
                :label-for="`box-campaign${bce.item.bce_index}-dailycap-type`"
                :invalid-feedback="invalidFeedback.dailyCap.type"
                :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.type"
              >
                <b-form-select
                  :id="`box-campaign${bce.item.bce_index}-dailycap-type`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.type"
                  v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.type"
                  :options="actionTypeOptions"
                  @input="backdoor += 1"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Total Cap"
                label-for="`box-campaign${bce.item.bce_index}-totalcap-type`"
                :invalid-feedback="invalidFeedback.totalCap.type"
                :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.type"
              >
                <b-form-select
                  :id="`box-campaign${bce.item.bce_index}-totalcap-type`"
                  v-model="boxForm.campaigns[bce.item.bce_index].totalCap.type"
                  :options="actionTypeOptions"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.type"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Amount"
                :label-for="`box-campaign${bce.item.bce_index}-dailycap-amount`"
                :invalid-feedback="invalidFeedback.dailyCap.amount"
                :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-dailycap-amount`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount"
                  v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.amount"
                  @input="backdoor += 1"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Amount"
                :label-for="`box-campaign${bce.item.bce_index}-totalcap-amount`"
                :invalid-feedback="invalidFeedback.totalCap.amount"
                :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.amount"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-totalcap-amount`"
                  v-model="boxForm.campaigns[bce.item.bce_index].totalCap.amount"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.amount"
                ></b-form-input>
              </b-form-group>
              <b-form-checkbox
                v-if="!$rent"
                class="mt-3 my-1"
                v-model="boxForm.campaigns[bce.item.bce_index].useLimitMPerBCE"
              >
                Use Limit M per BCE
              </b-form-checkbox>
              <b-form-group
                v-if="!$rent"
                class="mt-1 mb-3"
                label="Amount"
                :label-for="`box-campaign${bce.item.bce_index}-limitmperbce`"
                :invalid-feedback="invalidFeedback.box.campaigns.limitMPerBCE"
                :state="isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE"
              >
                <!--                :invalid-feedback="invalidFeedback.totalCap.amount"-->
                <b-form-input
                  v-if="!$rent"
                  :id="`box-campaign${bce.item.bce_index}-limitmperbce`"
                  v-model="boxForm.campaigns[bce.item.bce_index].limitMPerBCE"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="!$rent"
                class="mt-1 mb-3"
                label="M-Ftds Daily Cap"
                :label-for="`box-campaign${bce.item.bce_index}-mFtdsDailyCap`"
                :invalid-feedback="invalidFeedback.box.campaigns.mFtdsDailyCap"
                :state="isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap"
              >
                <!--                :invalid-feedback="invalidFeedback.totalCap.amount"-->
                <b-form-input
                  v-if="!$rent"
                  :id="`box-campaign${bce.item.bce_index}-mFtdsDailyCap`"
                  v-model="boxForm.campaigns[bce.item.bce_index].mFtdsDailyCap"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-row>
                <b-col cols="10" lg="5" md="10">
                  <b-form-group
                    label="Default Client's CPA"
                    class="my-2"
                    label-class="mr-2"
                    label-for="default-payment"
                    :invalid-feedback="invalidFeedback.box.campaigns.defaultPayment"
                    :state="isValid.fields.campaigns[bce.item.bce_index].defaultPayment"
                  >
                    <!--        :valid-feedback="validFeedback"-->
                    <!--            <h6 class="my-2">Amount</h6>-->
                    <b-form-input
                      id="default-payment"
                      v-model="boxForm.campaigns[bce.item.bce_index].defaultPayment"
                      :state="isValid.fields.campaigns[bce.item.bce_index].defaultPayment"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <CBFinanceSettings
                :countries="boxForm.campaigns[bce.item.bce_index].countries"
                v-model="boxForm.campaigns[bce.item.bce_index].countryPayments"
              ></CBFinanceSettings>
            </b-col>
            <b-col cols="12" lg="6">
              <b-row>
                <b-col cols="10" lg="5" md="10">
                  <b-form-group
                    label="Affiliate Default CPA"
                    class="my-2"
                    label-class="mr-2"
                    label-for="aff-default-payment"
                    :invalid-feedback="invalidFeedback.box.campaigns.aff_defaultPayment"
                    :state="isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment"
                  >
                    <!--        :valid-feedback="validFeedback"-->
                    <!--            <h6 class="my-2">Amount</h6>-->
                    <b-form-input
                      id="aff-default-payment"
                      v-model="boxForm.campaigns[bce.item.bce_index].aff_defaultPayment"
                      :state="isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <CBFinanceSettings
                :countries="boxForm.campaigns[bce.item.bce_index].countries"
                v-model="boxForm.campaigns[bce.item.bce_index].aff_countryPayments"
                cid="aff"
              ></CBFinanceSettings>
            </b-col>
          </b-row>
          <h6 class="invisible mb-2" aria-hidden>.</h6>
          <b-dropdown variant="outline-secondary" right no-caret>
            <template #button-content>
              <!--              <b-icon icon="three-dots-vertical"></b-icon>-->
              Delete Campaign From Box
            </template>
            <b-dropdown-item-button
              variant="danger"
              v-on:click="deleteRow(bce.item.bce_index)"
              :disabled="requesting"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Delete Campaign
            </b-dropdown-item-button>
          </b-dropdown>
          <p>index {{ bce.item.bce_index }}</p>
        </b-list-group-item>
      </template>
    </b-table>
    <div class="d-flex justify-content-between mb-3">
      <b-button variant="outline-primary" @click="addRow" :disabled="requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        <b-icon v-else icon="plus-circle"></b-icon>
        Add Campaign
      </b-button>
      <b-button
        @click="saveBox()"
        variant="primary"
        :disabled="!isValid.form || requesting || boxForm.defaultCampaignsBox"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        {{ $route.params.id ? 'Save Box' : 'Create Box' }}
      </b-button>
    </div>
    <!--    </b-form>-->
  </b-container>
</template>

<script>
/* eslint-disable camelcase */

import '@/assets/badge-toogle.css';
import '@/assets/single-field-editable-cell.css';
import { mapState } from 'vuex';
import {
  getBox,
  saveBox,
  calculateBoxDemand,
  calculateActiveHours,
  copyBoxSettings,
  createBoxFullCopy,
} from '@/utils/api';
import {
  castCountriesFromServer,
  deleteUnchangedFields,
  handleRequestError,
  isAdmin,
  isManager,
  showCard,
  tableFormatterCapOneCell,
  tableFormatterArrayValues,
  tableFormatterDays,
  tableFormatterHourLimit,
  countryMultiSelectSearch,
  bceFilterFunction,
} from '@/utils';
import {
  actionTypeOptions,
  daysOfWeek,
  countryOptions,
  validFeedback,
  invalidFeedback,
  initialBoxForm,
  OTHER_LEVEL_TRUSTED_USERS,
  campaignInitialValue,
} from '@/consts';
import { isValid, formValid } from '@/validators';
import { noCap } from '@/validators/_utils';
import _ from 'lodash';
import store from '@/store';
import CBFinanceSettings from '@/components/shared/CBFinanceSettings.vue';
import { DateTime } from 'luxon';
import { defaultBCESettings } from '@/consts/defaultBCESettings';
import AffiliateApiDocs from '@/components/shared/AffiliateApiDocs/AffiliateApiDocs.vue';
import InputWithHelp from '@/components/shared/InputWithHelp.vue';
import AddCampaignToBox from '@/components/shared/AddCampaignToBox.vue';
import RemoveCampaignsFromBoxes from '@/components/shared/RemoveCampaignsFromBoxes.vue';

const itemInitialValues = initialBoxForm;

export default {
  name: 'CreateBox',
  components: {
    InputWithHelp,
    AffiliateApiDocs,
    AddCampaignToBox,
    RemoveCampaignsFromBoxes,
    CBFinanceSettings,
  },
  data() {
    return {
      totalRows: 1,
      // filter: '',
      validFeedback,
      invalidFeedback,
      backdoor: 0,
      actionTypeOptions: [actionTypeOptions[2], actionTypeOptions[3]],
      affiliate: 'Affiliate 1',
      boxForm: _.cloneDeep(itemInitialValues),
      initialForm: {},
      filter: {
        campaigns: [],
        countries: [],
      },
      editingField: '',
      editingItem: -1,
      countryOptions,
      daysOfWeek,
      fields: [
        {
          key: 'campaign',
          sortable: true,
          formatter: (value) => {
            const cof = this.campaignOptions.find((co) => co.value === value);
            return cof ? cof.text : 'not selected';
          },
        },
        'controls',
        { key: 'active', sortable: true },
        this.$rent ? undefined : { key: 'useLinkCheckService', label: 'LinkCheck', sortable: true },
        {
          key: 'countries',
          label: 'Geos',
          sortable: true,
          formatter: (value) => tableFormatterArrayValues(value),
        },
        {
          key: 'hourLimit',
          sortable: true,
          formatter: (value) => tableFormatterHourLimit(value),
        },
        {
          key: 'days',
          sortable: true,
          formatter: (value) => tableFormatterDays(value),
        },
        { key: 'priority', sortable: true },
        { key: 'weight', sortable: true },
        this.$rent ? undefined : { key: 'useLimitMPerBCE', label: 'UseLimitM', sortable: true },
        this.$rent ? undefined : { key: 'limitMPerBCE', label: 'LimitM', sortable: true },
        {
          key: 'dailyCap',
          sortable: true,
          formatter: (value) => tableFormatterCapOneCell(value),
        },
        this.$rent ? undefined : { key: 'mFtdsDailyCap', label: 'mFtdsDailyCap', sortable: true },
        {
          key: 'totalCap',
          sortable: true,
          formatter: (value) => tableFormatterCapOneCell(value),
        },
        { key: 'defaultPayment', label: "Client's CPA", sortable: true },
        { key: 'aff_defaultPayment', label: 'Aff CPA', sortable: true },
        // { key: 'currency', sortable: true },
      ].filter((f) => !!f),
      demandForm: { hour: undefined, country: undefined },
      activeHoursForm: { day: undefined, country: undefined },
      demandResult: 0,
      activeHoursResult: [],
      boxTargetsToCopy: [],
      boxCopyResult: '',
      boxCopyResultMessage: '',
      boxCopyResults: { successful: [], failed: [] },
      boxFullCopyResult: '',
    };
  },
  computed: {
    ...mapState([
      'token',
      'campaigns',
      'boxes',
      'affiliates',
      'managers',
      'requesting',
      'user',
      'isAdmin',
      'db_defaultBCESettings',
      // 'savedBoxForm',
    ]),
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    filterCampaignOptions() {
      return _.uniqBy(
        this.boxForm.campaigns
          .map((bc) => {
            const c = this.campaigns.find((cc) => cc._id === bc.campaign);
            if (!c) return false;
            return { name: c.name, _id: c._id };
          })
          .filter((c) => !!c),
        '_id'
      );
    },
    filterCountryOptions() {
      let boxCountries = [];
      for (let i = 0; i < this.boxForm.campaigns.length; i += 1) {
        boxCountries = [
          ...boxCountries,
          ...this.boxForm.campaigns[i].countries.map((c) => (c.value ? c.value : c)),
        ];
      }
      return this.countryOptions.filter((c) => {
        const cc = c.value ? c.value : c;
        return boxCountries.includes(cc);
      });
    },
    boxesOptions() {
      this.backdoor;
      return this.boxes.map((b) => {
        return { name: b.name, _id: b._id };
      });
    },
    bceTableItems() {
      // console.log('Recalculating table items');
      const bces = this.boxForm.campaigns; // without _.cloneDeep() implicitly changing this.boxForm.campaigns, но так, что компилятор не замечает. надо иметь ввиду
      for (let i = 0; i < bces.length; i += 1) {
        // console.log(bces[i]);
        const campaignMatch = this.campaigns.find(
          (c) =>
            bces[i].campaign &&
            c._id === (bces[i].campaign._id ? bces[i].campaign._id : bces[i].campaign)
        );
        bces[i].campaignActive = campaignMatch && campaignMatch.active;
        bces[i]._rowVariant = bces[i].active
          ? this.isValid.campaigns[i] && bces[i].campaignActive
            ? this.selectedBCECountries.dontMatch[i][1].length === 0
              ? 'success'
              : 'warning'
            : 'danger'
          : '';
        // bces[i]._showDetails = i <= showDetailsArray.length ? showDetailsArray[i] : false;
        bces[i].bce_index = i;

        const cof = this.campaignOptions.find((co) => co.value === bces[i].campaign);
        bces[i].campaignNameForSearch = cof ? cof.text : 'not selected';
      }
      return bces;
    },
    // unchanged() {
    //   const unchanged = _.isEqual(this.boxForm, this.initialForm);
    //   console.log('unchanged', unchanged);
    //   if (!unchanged) {
    //     console.log('boxForm', this.boxForm);
    //     console.log('initialForm', this.initialForm);
    //   }
    //   return unchanged;
    // },
    isValid() {
      this.backdoor;
      // console.log('Campaigns length,', this.boxForm.campaigns.length);
      const fields = isValid('box', this.boxForm, this.boxForm);
      return {
        fields,
        form: formValid(fields),
        campaigns: fields.campaigns ? fields.campaigns.map((c) => formValid(c)) : [],
      }; // isValid('box', this.boxForm);
    },
    // formValid() {
    //   this.backdoor;
    //   return formValid(this.isValid);
    // },
    managersOptions() {
      // this.backdoor;
      return this.managers.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    affiliatesOptions() {
      // this.backdoor;
      return (
        this.affiliates // users
          // .filter((u) => isAffiliate(u))
          .map((a) => {
            return { text: a.name, value: a._id };
          })
      );
    },
    campaignOptions() {
      // this.backdoor;
      // const campaignsSelected = this.boxForm.campaigns.map((c) => c.campaign);
      const campaigns = this.filter.campaigns.map((c) => (c && c._id ? c._id : c));
      return this.campaigns
        .filter(
          (c) =>
            campaigns.length === 0 || campaigns.includes(c) || (c._id && campaigns.includes(c._id))
        )
        .map((c) => {
          return { text: c.name, value: c._id };
        });
    },
    campaignsMultiselectOptions() {
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    selectedBCECountries() {
      this.backdoor;
      const result = { selected: [], boxAccept: [], campaignsAccept: [], dontMatch: [] };
      const b = _.cloneDeep(this.boxForm);
      if (!b) return result;
      const selected = (b.campaigns ? b.campaigns : []).map((c) => !!c.campaign);
      const bcc = (b.campaigns ? b.campaigns : []).map((c) => {
        return [c.campaign, c.countries];
      });
      const cc = (b.campaigns ? b.campaigns : []).map((c) => {
        const cf = c.campaign ? this.campaigns.find((ci) => ci._id === c.campaign) : undefined;
        return cf ? [cf._id, cf.countries] : [c.campaign, []];
      });
      const countriesDontMatch = bcc.map((bcce, i) => {
        return [bcce[0], bcce[1].filter((c) => !cc[i] || !cc[i][1] || !cc[i][1].includes(c.value))];
      });
      result.selected = selected;
      result.boxAccept = bcc;
      result.campaignsAccept = cc;
      result.dontMatch = countriesDontMatch;
      return result;
    },
    thisBox() {
      return this.getThisBox(this.boxForm);
    },
  },
  methods: {
    getThisBox(box) {
      return { _id: box._id, name: box.name };
    },
    editFieldOn(e, field, i, elementIdToSelect) {
      e.stopPropagation();
      if (this.editingField === field && this.editingItem === i) return;
      this.editingField = field;
      this.editingItem = i;
      if (field === 'dailyCap' || field === 'totalCap') {
        if (noCap(this.boxForm.campaigns[i][field]))
          this.boxForm.campaigns[i][field] = { type: 'lead', amount: undefined };
      }
      this.$nextTick(() => {
        if (elementIdToSelect) {
          const el = document.getElementById(elementIdToSelect);
          if (el) el.select();
        }
      });
    },
    editFieldOff() {
      this.editingField = '';
      this.editingItem = -1;
    },
    canApplyDefaultSettings(index) {
      return (
        this.boxForm.campaigns[index].countries.length === 1 // &&
        // Object.keys(defaultBCESettings).includes(this.boxForm.campaigns[index].countries[0])
      );
    },
    applyDefaultBceSettings(index) {
      console.log('applyDefaultBceSettings');
      if (!this.canApplyDefaultSettings(index)) return;
      const country = this.boxForm.campaigns[index].countries[0];
      const { db_defaultBCESettings } = this;
      const c = country.value ? country.value : country;
      const db_defaultSettings =
        db_defaultBCESettings && Array.isArray(db_defaultBCESettings)
          ? db_defaultBCESettings.find((p) => p.geo === c)
          : undefined;
      if (db_defaultSettings && db_defaultSettings.days)
        db_defaultSettings.days = db_defaultSettings.days
          .map((d, i) => (d ? i : false))
          .filter((d) => d !== false);
      const defaultSettings =
        db_defaultSettings ||
        (defaultBCESettings[c] ? defaultBCESettings[c] : defaultBCESettings.default); // Object.keys(defaultBCESettings).includes(
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(defaultSettings)) {
        this.boxForm.campaigns[index][key] = _.cloneDeep(defaultSettings[key]);
      }
    },
    toggleBCEActive(item) {
      this.boxForm.campaigns[item.bce_index].active = !this.boxForm.campaigns[item.bce_index]
        .active;
    },
    toggleBCEUseLimitMPerBCE(item) {
      this.boxForm.campaigns[item.bce_index].useLimitMPerBCE = !this.boxForm.campaigns[
        item.bce_index
      ].useLimitMPerBCE;
    },
    toggleBCEUseLinkCheckService(item) {
      if (!this.iAmOtherLevelTrustedUser) return;
      this.boxForm.campaigns[item.bce_index].useLinkCheckService = !this.boxForm.campaigns[
        item.bce_index
      ].useLinkCheckService;
    },
    selectHourNow() {
      this.demandForm.hour = DateTime.utc().hour;
    },
    selectActiveHourNow() {
      this.activeHoursForm.hour = DateTime.utc().hour;
    },
    selectActiveDayNow() {
      this.activeHoursForm.day = DateTime.utc().weekday % 7;
    },
    selectAllFilterCampaigns() {
      this.filter.campaigns = _.cloneDeep(this.filterCampaignOptions);
    },
    clearFilterCampaigns() {
      this.filter.campaigns = [];
    },
    selectAllFilterCountries() {
      this.filter.countries = _.cloneDeep(this.filterCountryOptions);
    },
    clearFilterCountries() {
      this.filter.countries = [];
    },
    bceFilterFunction(item, filter) {
      return bceFilterFunction(item, filter);
    },
    getItemsOnScreen() {
      return this.bceTableItems.filter((item) => bceFilterFunction(item, this.filter));
    },
    applyFieldToAllVisibleCampaigns(field, idToTakeFrom) {
      const visibleItems = this.getItemsOnScreen();
      const ids = visibleItems.map((i) => i.bce_index);
      for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i];
        if (id !== idToTakeFrom)
          this.boxForm.campaigns[id][field] = _.cloneDeep(
            this.boxForm.campaigns[idToTakeFrom][field]
          );
      }
    },
    countryMultiSelectSearch,
    selectAllCountries(index) {
      // console.log('selecting all countries for campaign', this.boxForm.campaigns[index].campaign);
      if (
        index >= 0 &&
        index < this.boxForm.campaigns.length &&
        this.boxForm.campaigns[index].campaign
      ) {
        // console.log('searching in campaigns', this.campaigns);
        const campaign = this.campaigns.find(
          (c) => c._id === this.boxForm.campaigns[index].campaign
        );
        // if (campaign) console.log('campaign available countries', campaign.countries);
        // else console.log('campaign', campaign);
        this.boxForm.campaigns[index].countries = campaign
          ? campaign.countries
              .map((c) => countryOptions.find((co) => co.value === c))
              .filter((c) => !!c)
          : [];
      }
    },
    clearCountries(index) {
      if (index >= 0 && index < this.boxForm.campaigns.length) {
        this.boxForm.campaigns[index].countries = [];
      }
    },
    handleCampaignsMultiselect(e, bceId) {
      this.boxForm.campaigns[bceId].campaign_Multiselect = _.cloneDeep(e);
      if (Array.isArray(e)) {
        this.boxForm.campaigns[bceId].campaign = _.cloneDeep(e).map((ee) => ee._id);
      } else {
        this.boxForm.campaigns[bceId].campaign = _.cloneDeep(e)._id;
      }
      // console.log('handleCampaignsMultiselect campaign', this.boxForm.campaigns[bceId].campaign);
      // console.log(
      //   'handleCampaignsMultiselect campaign_Multiselect',
      //   this.boxForm.campaigns[bceId].campaign_Multiselect
      // );
      // console.log('handleCampaignsMultiselect isValid', this.isValid.fields.campaigns[bceId]);
      // console.log('handleCampaignsMultiselect isValid', this.isValid.campaigns[bceId]);
      this.backdoor += 1;
    },
    goToEditCampaign() {
      // this.$store.commit('save_box_form', this.boxForm);
    },
    createNewBox() {
      // this.boxForm = _.cloneDeep(itemInitialValues);
      // this.initialForm = {};
      // this.updateData();
      this.$router.push('/create-box');
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    addRow() {
      if (Array.isArray(this.boxForm.campaigns)) {
        this.boxForm.campaigns.push(_.cloneDeep(campaignInitialValue));
      } else {
        this.boxForm.campaigns = [_.cloneDeep(campaignInitialValue)];
      }
      this.backdoor += 1;
    },
    deleteRow(index) {
      this.boxForm.campaigns.splice(index, 1);
      if (!(Array.isArray(this.boxForm.campaigns) && this.boxForm.campaigns.length > 0)) {
        this.boxForm.campaigns = [_.cloneDeep(campaignInitialValue)];
      }
      this.backdoor += 1;
    },
    prepareBoxToSave() {
      this.$store.commit('make_requesting', { req: true });
      let boxToSave = _.cloneDeep(this.boxForm);
      boxToSave.campaigns = boxToSave.campaigns.map((bceform) => {
        const bce = bceform; // _.cloneDeep(bceform);
        bce.countries = bceform.countries.map((c) => c.value);
        const days = [true, true, true, true, true, true, true];
        bce.days = days.map((d, j) => bceform.days.includes(j));
        if (bce.dailyCap && noCap(bce.dailyCap)) bce.dailyCap = false;
        if (bce.totalCap && noCap(bce.totalCap)) bce.totalCap = false;
        return bce;
      });
      if (!boxToSave.name || boxToSave.name === '') {
        boxToSave.name = this.affiliates.find((a) => a._id === boxToSave.affiliate);
      }
      if (boxToSave.dailyCap && noCap(boxToSave.dailyCap)) boxToSave.dailyCap = false;
      if (boxToSave.totalCap && noCap(boxToSave.totalCap)) boxToSave.totalCap = false;
      boxToSave.followBox =
        boxToSave.followBox && boxToSave.followBox._id
          ? boxToSave.followBox._id
          : boxToSave.followBox;
      boxToSave.boxFollowers = boxToSave.boxFollowers.map((b) => b._id);
      boxToSave = deleteUnchangedFields(this.initialForm, this.boxForm, boxToSave);
      return boxToSave;
    },
    saveBox() {
      if (!this.isValid.form) return;
      const boxToSave = this.prepareBoxToSave();
      saveBox(this.token, boxToSave)
        .then(async (r) => {
          if (r.status === 200) {
            showCard(
              this,
              `${r.data.affiliate.name ? r.data.affiliate.name : r.data._id} box saved`,
              'success'
            );
            // try {
            //   await this.$store.dispatch('get_boxes', { vm: this }); // getBoxes();
            //   // eslint-disable-next-line no-empty
            // } catch (e) {}
            // this.castBoxToForm(r.data);
            // this.backdoor += 1;
            try {
              await this.$router.push(`/box/edit/${r.data._id}`);
            } catch (e) {
              console.log(e);
            }
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on saving box`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on saving box`);
        });
      this.$store.commit('make_requesting', { req: false });
    },
    getBox(_id) {
      // this.backdoor += 1;
      console.log('Getting box', _id);
      getBox(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castBoxToForm(r.data);
            this.backdoor += 1;
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting box`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning', 'Error getting box');
          }
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error on getting box', 'Error getting box');
        });
    },
    castBoxToForm(box) {
      const initial = _.cloneDeep(itemInitialValues);
      const initialCampaign = _.cloneDeep(campaignInitialValue);
      // cast box to edit form
      const campaigns = box.campaigns
        ? box.campaigns.map((c) => {
            const nc = _.cloneDeep(c);
            nc.countries = castCountriesFromServer(c.countries);
            nc.campaign = c.campaign._id ? c.campaign._id : c.campaign ? c.campaign : ''; // { text: c.campaign.name, value: c.campaign._id };
            nc.campaign_Multiselect = _.cloneDeep(c.campaign); // { text: c.campaign.name, value: c.campaign._id };
            nc.days = c.days.map((d, i) => (d ? i : false)).filter((d) => d !== false); // daysOfWeek.filter((d, i) => nc.days[i]);
            nc.hourLimit = c.hourLimit ? c.hourLimit : initialCampaign.hourLimit;
            nc.dailyCap = c.dailyCap ? c.dailyCap : initialCampaign.dailyCap;
            nc.totalCap = c.totalCap ? c.totalCap : initialCampaign.totalCap;
            nc.defaultPayment = c.defaultPayment;
            nc.aff_defaultPayment = c.aff_defaultPayment;
            nc.useLimitMPerBCE = c.useLimitMPerBCE
              ? c.useLimitMPerBCE
              : initialCampaign.useLimitMPerBCE;
            nc.limitMPerBCE = c.limitMPerBCE ? c.limitMPerBCE : initialCampaign.limitMPerBCE;
            nc.mFtdsDailyCap = c.mFtdsDailyCap ? c.mFtdsDailyCap : initialCampaign.mFtdsDailyCap;
            nc.useLinkCheckService = c.useLinkCheckService
              ? c.useLinkCheckService
              : initialCampaign.useLinkCheckService;
            // console.log('nc.campaign_Multiselect', nc.campaign_Multiselect);
            return nc;
          })
        : initial.campaigns;
      // prettier-ignore
      this.boxForm = {
        _id: box._id ? box._id : initial._id,
        name: box.name ? box.name : initial.name,
        active: box.active,
        campaigns,
        affiliate: box.affiliate ? box.affiliate._id ? box.affiliate._id :  box.affiliate : initial.affiliate,
        manager:  box.manager ? box.manager._id ? box.manager._id : box.manager : initial.manager,
        dailyCap: box.dailyCap ? box.dailyCap : initial.dailyCap,
        totalCap: box.totalCap ? box.totalCap : initial.totalCap,
        loadBalancer: box.loadBalancer ? box.loadBalancer : initial.loadBalancer,
        loadBalancerCoefficient: box.loadBalancerCoefficient ? box.loadBalancerCoefficient : initial.loadBalancerCoefficient,
        isMBox: box.isMBox ? box.isMBox : initial.isMBox,
        liveOmitTimeIntervalCheck: box.liveOmitTimeIntervalCheck ? box.liveOmitTimeIntervalCheck : initial.liveOmitTimeIntervalCheck,
        defaultCampaignsBox: box.defaultCampaignsBox ? box.defaultCampaignsBox : initial.defaultCampaignsBox,
        followBox: box.followBox ? box.followBox : initial.followBox,
        boxFollowers: box.boxFollowers ? box.boxFollowers : initial.boxFollowers,
      };
      if (this.isAdmin) {
        if (box.csi) this.boxForm.csi = box.csi;
        if (box.cso) this.boxForm.cso = box.cso;
        if (box.dso) this.boxForm.dso = box.dso;
        if (box.isTest) this.boxForm.isTest = box.isTest;
      }
      this.initialForm = _.cloneDeep(this.boxForm);
    },
    async updateData() {
      await store.dispatch('get_all_resources_one_request', { vm: this });
      if (this.$route.path === '/create-box' && !isAdmin(this.user) && isManager(this.user)) {
        this.boxForm.manager = this.user._id;
      }
      // await this.$store.dispatch('get_all_resources', { vm: this });
      // await this.$store.dispatch('get_campaigns', { vm: this });
      // await this.$store.dispatch('get_affiliates', { vm: this });
      // await this.$store.dispatch('get_managers', { vm: this });
      this.backdoor += 1;
      // this.takeDefaultHidden();
    },
    changeSorting() {
      const actualBceItems = this.bceTableItems.map((bcei) => bcei.campaign);
      console.log('Sorting changed');
      console.log(actualBceItems);
    },
    findBceIndex(bce) {
      return this.boxForm.campaigns.findIndex((c) => c.campaign === bce.item.campaign);
    },
    calculateDemand() {
      const { _id } = this.boxForm;
      const data = {
        _id,
        hour: Number(this.demandForm.hour),
        country: this.demandForm.country
          ? this.demandForm.country.value
            ? this.demandForm.country.value
            : this.demandForm.country
          : undefined,
      };
      if (!Number.isNaN(data.number) && data.country) {
        calculateBoxDemand(this.token, data)
          .then((r) => {
            if (r.data.success === true) this.demandResult = r.data.result;
            else handleRequestError(this, r.data, 'Error calculating box demand');
          })
          .catch((e) => handleRequestError(this, e, 'Error calculating box demand'));
      }
    },
    calculateActiveHours() {
      const { _id } = this.boxForm;
      const data = {
        _id,
        day: Number(this.activeHoursForm.day),
        country: this.activeHoursForm.country
          ? this.activeHoursForm.country.value
            ? this.activeHoursForm.country.value
            : this.activeHoursForm.country
          : undefined,
      };
      if (!Number.isNaN(data.number) && data.country) {
        calculateActiveHours(this.token, data)
          .then((r) => {
            if (r.data.success === true) this.activeHoursResult = r.data.result;
            else handleRequestError(this, r.data, 'Error calculating box Active Hours');
          })
          .catch((e) => handleRequestError(this, e, 'Error calculating box Active Hours'));
      }
    },
    copyBoxSettings() {
      if (this.boxTargetsToCopy.length === 0) {
        showCard(this, 'No boxes selected to copy settings to', 'danger');
        return;
      }
      this.$store.commit('make_requesting', { req: true });
      const boxesTo = this.boxTargetsToCopy.map((t) => t._id);
      const { _id } = this.boxForm;
      copyBoxSettings(this.token, { _id, boxesTo })
        .then((r) => {
          if (r.data.success) {
            this.boxCopyResult = `Copied Box Settings To Selected Boxes`;
            this.boxCopyResultMessage = '';
            const { successful, failed } = r.data;
            this.boxCopyResults = { successful, failed };
          } else {
            this.boxCopyResult = `Could Not Copy Settings to Selected Boxes`;
            this.boxCopyResultMessage = r.data.message;
            this.boxCopyResults = { successful: [], failed: [] };
          }
          this.$refs[`copy-box-settings-result-${this.boxForm._id}`].show();
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          this.boxCopyResult = `Error While Copy Settings to Selected Boxes`;
          this.boxCopyResultMessage = e.message;
          this.boxCopyResults = { successful: [], failed: [] };
          this.$refs[`copy-box-settings-result-${this.boxForm._id}`].show();
          this.$store.commit('make_requesting', { req: false });
        });
    },
    createBoxFullCopy() {
      this.$store.commit('make_requesting', { req: true });
      createBoxFullCopy(this.token, { _id: this.boxForm._id })
        .then((r) => {
          if (r.data.success) {
            this.boxFullCopyResult = 'Box Copy Created Successfully';
            this.$store.dispatch('get_boxes', { vm: this });
            //     .then(() => {
            //   this.backdoor += 1;
            // });
          } else {
            this.boxFullCopyResult = `Could Not Create Box Copy: ${r.data.message}`;
          }
          this.$refs[`create-box-full-copy-result-${this.boxForm._id}`].show();
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          this.boxFullCopyResult = `Could Not Create Box Copy: ${e.message}`;
          this.$refs[`create-box-full-copy-result-${this.boxForm._id}`].show();
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  mounted() {
    this.updateData();
    if (this.$route.params.id && !this.$route.query.b) this.getBox(this.$route.params.id);
    // if (this.$route.query.b) {
    //   console.log('getting saved box form', this.savedBoxForm);
    //   console.log('countries', this.savedBoxForm.countries);
    //   this.boxForm = _.cloneDeep(this.savedBoxForm);
    // }
    // this.backdoor += 1;
  },
  watch: {
    $route() {
      // this will be called any time the route changes
      console.log(this.$route.path);
      this.updateData();
      if (this.$route.params.id) this.getBox(this.$route.params.id);
      // if (this.$route.query.b) {
      //   this.boxForm = _.cloneDeep(this.savedBoxForm);
      //   this.$store.commit('save_box_form', itemInitialValues);
      // } else {
      //   this.boxForm = _.cloneDeep(itemInitialValues);
      //   this.initialForm = {};
      // }
      // this.backdoor += 1;
    },
  },
};
</script>

<style>
.multiselect__tags {
  max-height: 94px;
  overflow: auto;
}
.multiselect__content-wrapper {
  border-color: #1fa2ff !important;
  border-width: 2px !important;
}

tr {
  overflow: visible;
}
</style>
