<template>
  <b-container fluid>
    <b-row class="my-3" no-gutters>
      <b-col cols="12" lg="2" class="my-lg-0 my-1">
        <h2>Workers</h2>
      </b-col>
      <b-col cols="12" lg="1" class="my-lg-0 my-1">
        <b-button variant="primary" v-b-modal="'modal-create-worker'">Create</b-button>
        <b-modal id="modal-create-worker" centered hide-footer no-close-on-esc @hide="updateData()">
          <WorkerCreate />
        </b-modal>
      </b-col>
      <!--lg="2"-->
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="4"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="3" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <JsonExcel
          class="m-2"
          :data="items"
          :fields="fieldsToExport"
          type="csv"
          :stringifyLongNum="true"
          v-if="iAmOtherLevelTrustedUser"
        >
          <b-button variant="outline-primary">📥CSV</b-button>
        </JsonExcel>
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2 mb-3">
      <b-col
        class="col-12 col-12 col-md-6 col-lg-4 mb-3"
        v-if="affiliates && affiliates.length > 0 && !isAffiliate"
      >
        <span class="d-block h5 font-weight-normal">Affiliates</span>
        <multiselect
          id="affiliates"
          v-model="filterForm.affiliates"
          :options="affiliatesOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-col>

      <!--      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3">-->
      <!--        <span class="d-block h5 font-weight-normal">Date</span>-->
      <!--        <div class="d-sm-flex align-items-center">-->
      <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
      <!--            <span class="mr-sm-2">From</span>-->
      <!--            <b-form-datepicker-->
      <!--              :date-format-options="dateFormatOptions"-->
      <!--              id="date-from"-->
      <!--              today-button-->
      <!--              reset-button-->
      <!--              close-button-->
      <!--              :max="filterForm.to"-->
      <!--              v-model="filterForm.from"-->
      <!--            ></b-form-datepicker>-->
      <!--            &lt;!&ndash;                          @input="updateBackdoor()"-->
      <!--&ndash;&gt;-->
      <!--          </div>-->
      <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
      <!--            <span class="mx-sm-2">To</span>-->
      <!--            <b-form-datepicker-->
      <!--              :date-format-options="dateFormatOptions"-->
      <!--              id="date-to"-->
      <!--              today-button-->
      <!--              reset-button-->
      <!--              close-button-->
      <!--              :min="filterForm.from"-->
      <!--              v-model="filterForm.to"-->
      <!--            ></b-form-datepicker>-->
      <!--            &lt;!&ndash;                          @input="updateBackdoor()"-->
      <!--&ndash;&gt;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">ID</span>
        <b-form-input v-model="filterForm._id"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">Name</span>
        <b-form-input v-model="filterForm.name"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>

      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">Lead Email</span>
        <b-form-input v-model="filterForm.lead.email"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">Lead Phone</span>
        <b-form-input v-model="filterForm.lead.phone"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>

      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">Lead ID</span>
        <b-form-input v-model="filterForm.lead._id"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>

      <b-col class="col-12 col-12 col-md-8 col-lg-4 mb-3" v-if="iAmOtherLevelTrustedUser">
        <span class="d-block h5 font-weight-normal">Count Working Hours For Period</span>
        <div class="d-sm-flex align-items-center">
          <div class="d-sm-flex align-items-sm-center flex-sm-fill">
            <span class="mr-sm-2">From</span>
            <b-form-datepicker
              :date-format-options="dateFormatOptions"
              id="date-from"
              today-button
              reset-button
              close-button
              :max="filterForm.workingHoursTo"
              v-model="filterForm.workingHoursFrom"
            ></b-form-datepicker>
          </div>
          <div class="d-sm-flex align-items-sm-center flex-sm-fill">
            <span class="mx-sm-2">To</span>
            <b-form-datepicker
              :date-format-options="dateFormatOptions"
              id="date-to"
              today-button
              reset-button
              close-button
              :min="filterForm.workingHoursFrom"
              v-model="filterForm.workingHoursTo"
            ></b-form-datepicker>
          </div>
        </div>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <span class="d-block h5 font-weight-normal">Sort by</span>
        <b-form-select
          v-model="filterForm.sortBy"
          :options="sortByOptions"
          class="mb-2 d-block h5 font-weight-normal"
        ></b-form-select>
        <b-form-radio-group
          id="btn-radios-1"
          size="md"
          button-variant="outline-primary"
          v-model="filterForm.sortOrder"
          :options="sortOrderOptions"
          name="radios-btn-default"
          buttons
        ></b-form-radio-group>
      </b-col>
      <b-col class="col-12 col-12 col-md-7 col-lg-5">
        <b-button
          @click="applyFilter()"
          :disabled="!canApplyFilter"
          variant="primary"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Apply filter
        </b-button>
        <b-button
          @click="clearFilterForm()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Clear filter
        </b-button>
        <b-button
          @click="toggleBusinessOpenAll(true)"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          variant="outline-success"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Open Business
        </b-button>
        <b-button
          @click="toggleBusinessOpenAll(false)"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          variant="outline-danger"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Close Business
        </b-button>
      </b-col>
      <b-col class="col-12 col-12 col-md-5 col-lg-4">
        <b-button
          v-b-toggle.sidebar-right-table
          @click="closeSidebarDetails()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          Edit Table
        </b-button>
        <b-button
          v-b-toggle.sidebar-right-details
          @click="closeSidebarColumns()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          Edit Details
        </b-button>
        <b-sidebar
          id="sidebar-right-table"
          v-model="sidebarColumnsOpen"
          title="Table Fields"
          right
          shadow
        >
          <div class="px-3 py-2">
            <b-form-checkbox-group
              v-model="preSelectedColumns"
              :options="tableFieldsOptions"
              class="mb-3"
              @input="logSelectedColumns()"
              stacked
            ></b-form-checkbox-group>
            <b-button
              variant="primary"
              @click="applyFields()"
              :disabled="!canApplyFields"
              class="my-2 sidebar-button"
            >
              Apply
            </b-button>
            <b-button
              variant="success"
              @click="saveSelectedFields('worker_table')"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Save
            </b-button>
            <b-button
              variant="warning"
              @click="restoreTablePreference()"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Restore
            </b-button>
          </div>
        </b-sidebar>
        <b-sidebar
          id="sidebar-right-details"
          v-model="sidebarDetailsOpen"
          title="Show Details"
          right
          shadow
        >
          <div class="px-3 py-2">
            <b-form-checkbox-group
              v-model="preSelectedDetails"
              :options="detailsOptions"
              class="mb-3"
              @input="logSelectedDetails()"
              stacked
            ></b-form-checkbox-group>
            <b-button
              variant="primary"
              @click="applyDetails()"
              :disabled="!canApplyDetails"
              class="my-2 sidebar-button"
            >
              Apply
            </b-button>
            <b-button
              variant="success"
              @click="saveSelectedFields('worker_details')"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Save
            </b-button>
            <b-button
              variant="warning"
              @click="restoreDetailsPreference()"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Restore
            </b-button>
          </div>
        </b-sidebar>
      </b-col>
      <!--      :disabled="!canApplyFilter"-->
    </b-row>

    <b-table
      class="table mb-3"
      :fields="sortedSelectedFields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(_id)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
          <b-button variant="primary" v-b-modal="`modal-edit-worker-${data.item._id}`">✍️</b-button>
          <b-modal :id="`modal-edit-worker-${data.item._id}`" centered hide-footer no-close-on-esc>
            <!--            @hide="updateData()"-->
            <WorkerCreate :_id="data.item._id" @workerSaved="updateData()" />
          </b-modal>
          <b-button
            variant="danger"
            v-b-modal="`modal-remove-worker-${data.item._id}`"
            v-if="isAdmin"
          >
            <b-icon icon="trash-fill"></b-icon>
          </b-button>
          <b-modal
            v-if="isAdmin"
            :id="`modal-remove-worker-${data.item._id}`"
            centered
            @ok="deleteWorker(data.item._id, data.item.name)"
            ok-variant="danger"
            cancel-variant="success"
            ok-title="Yes, Delete!"
            cancel-title="No, Go Back To Workers"
            footer-class="centered"
            hide-header
            lazy
            :ok-disabled="requesting"
          >
            Are you sure you want to
            <b>DELETE</b>
            Worker
            <b>{{ data.item.name }}</b>
          </b-modal>
        </b-button-group>
      </template>

      <template #cell(name)="data">
        <span v-b-popover.hover.top="data.unformatted">
          {{ data.item.name }}
        </span>
      </template>

      <template #cell(affiliates)="data">
        <span v-b-popover.hover.top="getAffiliatesForPopover(data)">
          {{ data.value }}
        </span>
      </template>

      <template #cell(leads)="data">
        <!--        <span v-b-popover.hover.top="getEmailsForPopover(data)">-->
        {{ data.value }}
        <!--        </span>-->
      </template>
      <template #cell(workingHours)="data">{{ data.value }}h</template>

      <template #cell(active)="data">
        <h5 class="mb-0">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'"
            @click="toggleWorkerActive(data.item._id)"
          >
            {{ data.value === 'true' || data.value === true ? 'Working' : 'Not Working' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(callback)="data">
        <h5 class="mb-0">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'"
            @click="toggleWorkerCallback(data.item._id)"
          >
            {{ data.value === 'true' || data.value === true ? 'Callback' : 'FTD' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(available)="data">
        <h5 class="mb-0">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'"
          >
            <!--            @click="toggleWorkerCallback(data.item._id)" -->
            {{ data.value === 'true' || data.value === true ? 'Available' : 'Unavailable' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(businessOpen)="data">
        <h5 class="mb-0">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'"
            @click="toggleBusinessOpen(data.item._id)"
          >
            {{ data.value === 'true' || data.value === true ? 'Open' : 'Closed' }}
          </b-badge>
        </h5>
      </template>

      <template #row-details="row">
        <WorkersTableDetails :_id="row.item._id" :sorted-selected-details="sortedSelectedDetails" />
      </template>
    </b-table>
    <b-row class="mb-3" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable no-nested-ternary */
import { mapState } from 'vuex';
import _ from 'lodash';
// import { DateTime } from 'luxon';
import {
  countryOptions,
  // datepickerFormat,
  TRUSTED_USERS,
  dateFormatOptions,
  OTHER_LEVEL_TRUSTED_USERS,
  datepickerFormat,
} from '@/consts';
import { userAvailableFields, userAvailableDetails } from '@/consts/userAvailableFields';
import {
  handleRequestError,
  showCard,
  // showCard,
  tableFormatterNameAndId,
} from '@/utils';
import {
  tableFieldsOptions,
  sortedSelectedFields,
  detailsOptions,
  sortedSelectedDetails,
  saveSelectedFieldsMethod,
  takeDefaultTableFields,
  takeDefaultDetailsOptions,
  applyFields,
  applyDetails,
  canApplyFields,
  canApplyDetails,
} from '@/utils/fieldsAndDetailsOptionsHelpers';
import { startCase } from '@/utils/string';
import {
  getWorkers,
  deleteWorker,
  toggleWorkerActive,
  toggleWorkerCallback,
  toggleBusinessOpen,
  toggleBusinessOpenAll,
} from '@/utils/api';
import '@/assets/badge-toogle.css';
import '@/assets/breakdown-data.css';
import JsonExcel from 'vue-json-excel';
import WorkersTableDetails from '@/components/pages/Workers/WorkersTableDetails.vue';
import { DateTime } from 'luxon';
import WorkerCreate from './WorkerCreate.vue';

const initialFilterForm = {
  // from: DateTime.utc().startOf('year').toFormat(datepickerFormat),
  // to: DateTime.utc().toFormat(datepickerFormat),
  _id: undefined,
  affiliates: undefined,
  name: undefined,
  lead: { email: undefined, phone: undefined, _id: undefined },
  sortBy: '_id',
  sortOrder: 'desc',
  workingHoursFrom: DateTime.utc()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toFormat(datepickerFormat),
  workingHoursTo: DateTime.utc().toFormat(datepickerFormat),
};

export default {
  name: 'WorkersTable',
  components: { WorkersTableDetails, JsonExcel, WorkerCreate },
  data() {
    return {
      dateFormatOptions,
      countryOptions,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      filterIncludedFields: ['_id', 'name'],
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 500, text: '500' },
        { value: 1000, text: '1000' },
      ],
      filterForm: _.cloneDeep(initialFilterForm),
      appliedFilterForm: _.cloneDeep(initialFilterForm),
      lastTimeToProcess: 0,
      sortOrderOptions: [
        { text: 'Asc', value: 'asc' },
        { text: 'Desc', value: 'desc' },
      ],
      selectedColumns: [],
      preSelectedColumns: [],
      selectedDetails: [],
      preSelectedDetails: [],
      sidebarColumnsOpen: false,
      sidebarDetailsOpen: false,
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
    };
  },
  computed: {
    ...mapState([
      'user',
      'isAdmin',
      'isManager',
      'isAffiliate',
      'isMasterAffiliate',
      'token',
      'affiliates',
      'campaigns',
      'boxes',
      'requesting',
    ]),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    fieldsToExport() {
      return this.iAmOtherLevelTrustedUser
        ? {
            id: '_id',
            Name: 'name',
            Affiliates: 'affiliates.length',
            Leads: 'leads.length',
          }
        : {};
    },
    canApplyFields() {
      return canApplyFields(this);
    },
    canApplyDetails() {
      return canApplyDetails(this);
    },
    userAvailableFields() {
      if (this.isAdmin) return userAvailableFields.worker.admin();
      if (this.isManager) return userAvailableFields.worker.manager();
      if (this.isMasterAffiliate) return userAvailableFields.worker.masteraffiliate();
      return userAvailableFields.worker.affiliate();
    },
    tableFieldsOptions() {
      return tableFieldsOptions(this);
    },
    sortedSelectedFields() {
      return sortedSelectedFields(this);
    },
    userAvailableDetails() {
      if (this.isAdmin) return userAvailableDetails.worker.admin();
      if (this.isManager) return userAvailableDetails.worker.manager();
      if (this.isMasterAffiliate) return userAvailableDetails.worker.masteraffiliate();
      return userAvailableDetails.worker.affiliate();
    },
    detailsOptions() {
      return detailsOptions(this);
    },
    sortedSelectedDetails() {
      return sortedSelectedDetails(this);
    },
    sortByOptions() {
      return this.userAvailableFields.map((field) => {
        return {
          text: field.label ? field.label : startCase(field.key ? field.key : String(field)),
          value: field.key,
        };
      });
    },
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    canApplyFilter() {
      return !this.requesting && !_.isEqual(this.appliedFilterForm, this.filterForm);
    },
  },
  mounted() {
    this.$store.dispatch('get_all_resources_one_request', { vm: this });
    this.updateData();
  },
  methods: {
    deleteWorker(_id, name) {
      this.$store.commit('make_requesting', { req: true });
      deleteWorker(this.token, _id)
        .then(() => {
          showCard(this, `Worker ${name} deleted`, 'success');
          this.$store.commit('make_requesting', { req: false });
          this.updateData();
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on deleting Worker ${name}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    getEmailsForPopover(data) {
      function getEmail(d) {
        return d && d.leadContactInfo && d.leadContactInfo.email && d.leadContactInfo.email !== ''
          ? d.leadContactInfo.email
          : undefined;
      }
      const unformatted = _.cloneDeep(data.unformatted);
      const dots = unformatted.length > 7 ? '...' : '';
      return Array.isArray(unformatted)
        ? unformatted
            .slice(0, 7)
            .map((d) => getEmail(d))
            .filter((e, i) => !!e && unformatted.findIndex((d) => getEmail(d) === e) === i)
            .join(', ') + dots
          ? '...'
          : ''
        : '';
    },
    getAffiliatesForPopover(data) {
      console.log('getAffiliatesForPopover', data.unformatted);
      function getAffiliate(d) {
        return d && d.name && d.name !== '' ? d.name : undefined;
      }
      const unformatted = _.cloneDeep(data.unformatted);
      const dots = unformatted.length > 7 ? '...' : '';
      return Array.isArray(unformatted)
        ? _.uniq(
            unformatted.slice(0, 7).map((d) => getAffiliate(d))
            // .filter((e, i) => !!e && unformatted.findIndex((d) => getAffiliate(d) === e) === i)
          ).join(', ') + dots
        : '';
    },
    applyFields() {
      applyFields(this);
    },
    applyDetails() {
      applyDetails(this);
    },
    saveSelectedFields(type) {
      saveSelectedFieldsMethod(this, type);
    },
    takeDefaultTableFields() {
      takeDefaultTableFields(this, 'workerTableSelectedFields');
    },
    takeDefaultDetailsOptions() {
      takeDefaultDetailsOptions(this, 'workerTableSelectedDetails');
    },
    restoreTablePreference() {
      this.takeDefaultTableFields();
      this.saveSelectedFields('worker_table');
    },
    restoreDetailsPreference() {
      this.takeDefaultDetailsOptions();
      this.saveSelectedFields('worker_details');
    },
    closeSidebarColumns() {
      this.sidebarColumnsOpen = false;
    },
    closeSidebarDetails() {
      this.sidebarDetailsOpen = false;
    },
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    logSelectedColumns() {
      console.log(this.selectedColumns);
    },
    logSelectedDetails() {
      console.log(this.selectedDetails);
    },
    updateItem(id, newAction, fields = []) {
      if (newAction) {
        const clonedItems = _.cloneDeep(this.items);
        const itemIndex = clonedItems.findIndex(({ _id }) => _id === id);
        const { _showDetails } = clonedItems[itemIndex];
        if (fields.length > 0) {
          Object.keys(newAction).forEach((k) => {
            if (fields.includes(k)) clonedItems[itemIndex][k] = newAction[k];
          });
        } else {
          clonedItems[itemIndex] = newAction;
        }
        if (_showDetails) clonedItems[itemIndex]._showDetails = _showDetails;
        this.items = clonedItems;
        this.$store.commit('make_requesting', { req: false });
      } else {
        this.updateData();
      }
    },
    applyFilter() {
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    clearFilterForm() {
      this.filter = null;
      this.filterForm = _.cloneDeep(initialFilterForm);
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    updateBackdoor() {
      console.log('backdoor updated');
      this.backdoor += 1;
    },
    _updateLastTimeToProcess(t) {
      this.lastTimeToProcess = t;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateData() {
      this.$store.commit('make_requesting', { req: true });
      const appliedFilterForm = _.cloneDeep(this.appliedFilterForm);
      const options = {
        ..._.cloneDeep(appliedFilterForm),
        page: this.currentPage,
        itemsPerPage: this.perPage,
      };
      if (options.workingHoursFrom) {
        options.workingHoursFrom = DateTime.fromFormat(options.workingHoursFrom, datepickerFormat, {
          zone: 'utc',
        });
        options.workingHoursFrom = options.workingHoursFrom.isValid
          ? options.workingHoursFrom.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
          : undefined;
      }
      if (options.workingHoursTo) {
        options.workingHoursTo = DateTime.fromFormat(options.workingHoursTo, datepickerFormat, {
          zone: 'utc',
        });
        options.workingHoursTo = options.workingHoursTo.isValid
          ? options.workingHoursTo.toISO()
          : undefined;
      }
      console.log('options', options);
      if (options.affiliates)
        options.affiliates = options.affiliates.map((c) => (c._id ? c._id : c));

      // if (options.from) {
      //   options.from = DateTime.fromFormat(options.from, datepickerFormat, { zone: 'utc' });
      //   options.from = options.from.isValid
      //     ? options.from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
      //     : undefined;
      // }

      // if (options.to) {
      //   options.to = DateTime.fromFormat(options.to, datepickerFormat, { zone: 'utc' });
      //   options.to = options.to.isValid ? options.to.toISO() : undefined;
      // }

      getWorkers(this.token, options)
        .then((r) => {
          this.$store.commit('make_requesting', { req: true });
          const { data } = r;
          console.log('got data; items length', data.items.length);
          this.items = _.cloneDeep(data.items);
          console.log('put items in table; table items length', this.items.length);
          if (this.currentPage !== data.page) this.currentPage = _.cloneDeep(data.page);
          if (this.perPage !== data.itemsPerPage) this.perPage = _.cloneDeep(data.itemsPerPage);
          this.totalRows = _.cloneDeep(data.totalRows);
          this.filterForm.sortBy = _.cloneDeep(data.sortBy);
          this.appliedFilterForm.sortBy = _.cloneDeep(data.sortBy);
          this.filterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.appliedFilterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    tableFormatterNameAndId(value) {
      return tableFormatterNameAndId(value);
    },
    toggleWorkerActive(_id) {
      this.$store.commit('make_requesting', { req: true });
      toggleWorkerActive(this.token, { _id })
        .then((r) => {
          if (r.data.success) {
            this.updateItem(_id, r.data, ['active']);
          } else {
            const cardError =
              r.data.message ||
              `${r.status}: Unknown error on toggling Active for Worker ${this._id}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown error on toggling Active for Worker ${this._id}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    toggleWorkerCallback(_id) {
      this.$store.commit('make_requesting', { req: true });
      toggleWorkerCallback(this.token, { _id })
        .then((r) => {
          if (r.data.success) {
            this.updateItem(_id, r.data, ['callback']);
          } else {
            const cardError =
              r.data.message ||
              `${r.status}: Unknown error on toggling Callback for Worker ${this._id}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown error on toggling Callback for Worker ${this._id}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    toggleBusinessOpen(_id) {
      this.$store.commit('make_requesting', { req: true });
      toggleBusinessOpen(this.token, { _id })
        .then((r) => {
          if (r.data.success) {
            this.updateItem(_id, r.data, ['businessOpen']);
            if (r.data.businessOpen === false) {
              this.updateItem(_id, { active: false, available: false }, ['active', 'available']);
            }
          } else {
            const cardError =
              r.data.message ||
              `${r.status}: Unknown error on toggling BusinessOpen for Worker ${this._id}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown error on toggling BusinessOpen for Worker ${this._id}`
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
    toggleBusinessOpenAll(businessOpen) {
      this.$store.commit('make_requesting', { req: true });
      toggleBusinessOpenAll(this.token, { businessOpen })
        .then((r) => {
          if (r.data.success) {
            showCard(
              this,
              `Business ${businessOpen ? 'Opened' : 'Closed'} for all your Workers. Updating...`,
              'success'
            );
            this.updateData();
          } else {
            const cardError =
              r.data.message ||
              `${r.status}: Unknown error on toggling BusinessOpen for Worker ${this._id}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown error on toggling BusinessOpen for Worker ${this._id}`
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    $route() {
      this.$store.dispatch('get_all_resources_one_request', { vm: this });
      this.updateData();
    },
  },
};
</script>

<style scoped>
.table >>> th,
.table >>> th > *,
.table >>> td,
.table >>> td > * {
  white-space: nowrap !important;
}
.table >>> .card-columns {
  white-space: initial !important;
}
.table >>> .with-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
