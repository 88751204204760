<template>
  <div>
    <LoadingSpinner :requesting="requesting" v-if="requesting" />
    <div id="textarea" v-else>
      <ul>
        <li v-for="(item, index) in items" :key="index">
          <div class="client-item">
            Email: {{ item.email }}
            <br />
            Name: {{ item.firstname }}{{ item.lastname }}
          </div>
          <ul>
            <li v-for="ssclr in item.saleStatusChangeLog" :key="ssclr">
              {{ actionDateTime(ssclr.timestamp) }} {{ ssclr.status }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { actionDateTime, handleRequestError } from '@/utils';
import _, { cloneDeep } from 'lodash';
import { initialFilterForm } from '@/consts';
import { getSalesStatusReportForClient } from '@/utils/api';
import { mapState } from 'vuex';

export default {
  name: 'SalesStatusReportForClient',
  props: {
    type: {
      type: String,
      validator(value) {
        // Define the allowed values for the prop
        const allowedValues = ['positive', 'positive-to-negative'];

        // Check if the prop value is included in the allowed values
        return allowedValues.includes(value);
      },
      required: true,
    },
    appliedFilterForm: {
      type: Object,
      default: () => {
        return cloneDeep(initialFilterForm);
      },
      required: true,
    },
    campaign: { type: String, required: true },
    client: { type: String, required: true },
  },
  data() {
    return {
      items: [],
      requesting: false,
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'isMasterAffiliate', 'token']),
  },
  methods: {
    copyToClipboard() {
      const textarea = document.getElementById('textarea');
      if (textarea) {
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
      }
    },
    actionDateTime(clr) {
      return actionDateTime(clr);
    },
    getSalesStatusClientsList() {
      this.requesting = true;
      const options = {
        ...this.appliedFilterForm,
        statusType: this.type,
        campaign: this.campaign,
        client: this.client,
      };
      getSalesStatusReportForClient(this.token, options)
        .then((r) => {
          if (r.data.success) {
            this.items = _.sortBy(r.data.items, (o) => {
              const i =
                o.saleStatusChangeLog && o.saleStatusChangeLog.length > 0
                  ? o.saleStatusChangeLog[o.saleStatusChangeLog.length - 1]
                  : new Date(0);
              return i ? -i : 0;
            });
          } else {
            handleRequestError(this, r.data, 'Error getting SalesStatusReportForClient');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error getting SalesStatusReportForClient');
          this.requesting = false;
        });
    },
  },
  mounted() {
    this.getSalesStatusClientsList();
  },
};
</script>

<style scoped></style>
