<template>
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <hr />
    <!--    :class="`${isValid.form ? 'tab' : 'tab-invalid'}`" -->
    <h4 class="mx-1 my-2">{{ name }}</h4>
    <!--    class="tab"-->
    <!--    <p>valid:{{ valid }}</p>-->
    <SendLeadParameters
      :parameters="form.SendLead"
      @edit="handleEditEvent($event, 'SendLead')"
      @validate="handleValidateEvent($event, 'SendLead')"
    ></SendLeadParameters>
    <FinalReceiverParameters
      :FinalReceiverIs="form.FinalReceiverIs"
      :FinalReceiverPath="form.FinalReceiverPath"
      :FinalReceiverSource="form.FinalReceiverSource"
      :FinalReceiverConst="form.FinalReceiverConst"
      @edit="
        handleMultipleKeysEditEvent($event, [
          'FinalReceiverIs',
          'FinalReceiverConst',
          'FinalReceiverPath',
          'FinalReceiverSource',
        ])
      "
      @validate="
        handleMultipleKeysValidateEvent($event, [
          'FinalReceiverIs',
          'FinalReceiverConst',
          'FinalReceiverPath',
          'FinalReceiverSource',
        ])
      "
    ></FinalReceiverParameters>
    <BrokerAcceptanceParameters
      :BrokerAcceptedORConditions="form.BrokerAcceptedORConditions"
      :BrokerNotAcceptedORConditions="form.BrokerNotAcceptedORConditions"
      @edit="
        handleMultipleKeysEditEvent($event, [
          'BrokerAcceptedORConditions',
          'BrokerNotAcceptedORConditions',
        ])
      "
      @validate="
        handleMultipleKeysValidateEvent($event, [
          'BrokerAcceptedORConditions',
          'BrokerNotAcceptedORConditions',
        ])
      "
    ></BrokerAcceptanceParameters>
    <PullingParameters
      :parameters="form.Pulling"
      @edit="handleEditEvent($event, 'Pulling')"
      @validate="handleValidateEvent($event, 'Pulling')"
    ></PullingParameters>
    <hr />
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
import handleEditEvent from '@/utils/handleEditEvent';
import handleMultipleKeysEditEvent from '@/utils/handleMultipleKeysEditEvent';
import SendLeadParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/SendLeadParameters.vue';
import FinalReceiverParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/FinalReceiverParameters.vue';
import BrokerAcceptanceParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/BrokerAcceptanceParameters.vue';
import PullingParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PullingParameters.vue';
import handleValidateEvent from '@/utils/handleValidateEvent';
import handleMultipleKeysValidateEvent from '@/utils/handleMultipleKeysValidateEvent';
import { formValid } from '@/validators'; // , isValid
import { mapPropFieldToValid } from '@/utils';

export default {
  name: 'UniversalIntegration',
  components: {
    PullingParameters,
    BrokerAcceptanceParameters,
    FinalReceiverParameters,
    SendLeadParameters,
  },
  props: {
    name: { type: String, default: 'Universal Integration Settings' },
    settings: {
      SendLead: {
        type: Object,
        default: () => {
          return {};
        },
      },
      FinalReceiverIs: { type: String, default: '' },
      FinalReceiverConst: { type: String, default: '' },
      FinalReceiverPath: { type: Array, default: () => [] },
      FinalReceiverSource: { type: String, default: '' },
      BrokerAcceptedORConditions: { type: Array, default: () => [] },
      BrokerNotAcceptedORConditions: { type: Array, default: () => [] },
      Pulling: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  },
  data() {
    return {
      form: this.$props.settings ? cloneDeep(this.$props.settings) : {},
      valid: mapPropFieldToValid(this.$props.settings, {}),
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = {
        ...this.valid,
        // ...isValid('integration_SendLeadParameters', this.form, this.form),
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      // console.log('universalIntegrationSettings validated', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    handleMultipleKeysEditEvent,
    handleValidateEvent,
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleMultipleKeysValidateEvent,
    // handleMultipleKeysValidateEvent(data, keys, formKey) {
    //   handleMultipleKeysValidateEvent(this, data, keys, formKey);
    // },
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
