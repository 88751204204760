<template>
  <b-form>
    <b-form-input
      class="mb-2"
      v-model="currentValue"
      :state="isValid"
      placeholder="Enter Final Receiver"
    ></b-form-input>
    <b-button block @click="handleSave">Save</b-button>
  </b-form>
</template>

<script>
export default {
  name: 'ChangeFinalReceiver',
  props: {
    id: {
      type: String,
      required: true,
    },
    initialValue: {
      type: String,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentValue: this.initialValue,
    };
  },
  computed: {
    isValid() {
      return this.currentValue.length >= 3;
    },
  },
  methods: {
    handleSave() {
      if (this.isValid) {
        this.onSave(this.id, this.currentValue);
      }
    },
  },
};
</script>
