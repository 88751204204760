<template>
  <div>
    <LoadingSpinner :requesting="requesting" v-if="requesting" />
    <div v-else>
      <b-card-group columns>
        <b-card v-if="detailAvailable('affiliates') && !isAffiliate">
          <b-card-title>Affiliates</b-card-title>
          <b-list-group v-if="worker.affiliates">
            <b-list-group-item v-for="(b, index) in worker.affiliates" v-bind:key="index">
              <!--                <b-badge variant="info" class="mx-1">-->
              {{ b.name }} {{ b.email }}
              <!--                </b-badge>-->
              <!--                <b-badge variant="info" class="mx-1">{{ b.email }}</b-badge>-->
              <router-link :to="`/user/edit/${b._id}`">
                Edit
                <!--                  <b-badge variant="outline-primary" class="mx-1"></b-badge>-->
              </router-link>
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <b-card v-if="detailAvailable('leads')">
          <b-card-title>Leads</b-card-title>
          <b-list-group v-if="worker.actions">
            <b-list-group-item v-for="(b, index) in worker.actions" v-bind:key="index">
              <b-badge variant="info" class="mx-1" v-if="b.leadContactInfo">
                {{ b.leadContactInfo.email }}
              </b-badge>
              <b-badge variant="info" class="mx-1" v-if="b.leadContactInfo">
                {{ b.leadContactInfo.phone }}
              </b-badge>
              <b-badge :variant="b.leadContactInfo ? 'info' : 'warning'" class="mx-1">
                {{ b._id }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card v-if="detailAvailable('details')">
          <b-card-title>Details</b-card-title>
          <b-list-group>
            <b-list-group-item>ID: {{ worker._id }}</b-list-group-item>
            <b-list-group-item>Name: {{ worker.name }}</b-list-group-item>
          </b-list-group>
        </b-card>
      </b-card-group>
      <p v-if="detailAvailable('_id')">_id: {{ worker._id }}</p>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { mapState } from 'vuex';
import { getWorker } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';

export default {
  name: 'WorkersTableDetails',
  components: { LoadingSpinner },
  props: {
    _id: { type: String, default: '' },
    sortedSelectedDetails: { type: Array, default: () => [] },
  },
  data() {
    return { requesting: false, worker: {} };
  },
  computed: {
    ...mapState(['token', 'isAffiliate']),
  },
  methods: {
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    getWorker() {
      this.requesting = true;
      const { _id } = this;
      console.log(`Getting worker ${_id} for details`);
      getWorker(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castWorkerToForm(r.data);
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting worker`;
            showCard(
              this,
              cardError,
              r.status === 500 ? 'danger' : 'warning',
              'Error getting worker'
            );
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            'Unknown server error on getting worker',
            'Error getting worker'
          );
          this.requesting = false;
        });
    },
    castWorkerToForm(worker) {
      this.worker = {
        _id: worker._id ? worker._id : undefined,
        affiliates: worker.affiliates
          .filter((a) => a)
          .map((a) => {
            return { _id: a._id ? a._id : a, name: a.name ? a.name : 'unknown' };
          }),
        actions: worker.actions ? worker.actions : [],
        name: worker.name ? worker.name : undefined,
      };
    },
  },
  mounted() {
    this.getWorker();
  },
};
</script>

<style scoped></style>
