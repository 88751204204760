<template>
  <div>
    <div v-if="isAffiliate && user && user.maff" class="px-5">
      <b-row class="my-5">
        <h3 class="my-2">Hello and welcome back {{ user.name }}</h3>
      </b-row>
      <b-row class="my-2">
        <!--        <b-col class="col-12 col-12 col-md-12 col-lg-8 mb-3">-->
        <span class="d-block h5 font-weight-normal mx-2">
          Please choose your preference GEO target countries you are about to use today on your aff
          system. Use the drop-down to pick all your relevant geos and click select.
        </span>
        <b-input-group class="mx-2">
          <multiselect
            v-model="countriesToStartWorkingOn"
            :options="countriesToStartOptions"
            track-by="value"
            :custom-label="countryWithCode"
            @search-change="countryMultiSelectSearch"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
          <!--          <b-input-group-append>-->
          <!--            <b-button @click="selectAllCountriesToStart()" variant="primary">Select All</b-button>-->
          <!--          </b-input-group-append>-->
          <b-input-group-append>
            <b-button @click="clearCountriesToStart()" variant="warning">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-row>
      <!--        </b-col>-->
      <!--        <b-col class="col-12 col-12 col-md-12 col-lg-4 mb-3">-->
      <!--          <h3>Press this when you are ready to start</h3>-->
      <b-row class="my-5">
        <b-button class="mx-2 h-100" variant="primary" v-b-modal="`modal-start-working`">
          Click to confirm your chosen countries and click to open traffic and start to work
        </b-button>
        <b-modal id="modal-start-working" centered hide-footer hide-header>
          <div class="d-block text-center">
            <h3>
              Are you sure you are ready to upload leads or prepare to Push? If yes please click you
              are ready or cancel
            </h3>
          </div>
          <b-button class="mt-3" block variant="warning" @click="startWorkingOnSelectedCountries()">
            YES, START WORKING
          </b-button>
          <b-button
            class="mt-3"
            block
            variant="primary"
            @click="$bvModal.hide('modal-start-working')"
          >
            NO, CANCEL
          </b-button>
        </b-modal>
        <!--        </b-col>-->
      </b-row>
      <b-row class="my-5">
        <h3>Countries that you are working on at the moment:</h3>
        <div>
          <span
            class="m-2 h3 font-weight-normal"
            v-for="(country, index) in user && user.workingOnCountries
              ? user.workingOnCountries
              : []"
            :key="index"
          >
            <b-badge variant="success">{{ country }}</b-badge>
          </span>
        </div>
      </b-row>

      <!--        <b-col class="col-12 col-12 col-md-12 col-lg-8 mb-3">-->
      <b-button v-b-toggle="`collapse-stopwork`" variant="danger">STOP WORKING</b-button>
      <b-collapse :id="`collapse-stopwork`" class="mt-2">
        <b-row class="my-4">
          <span class="d-block h5 font-weight-normal mx-2">
            Dear {{ user.name }} Please choose any geo you like to remove from your working flow.
          </span>
          <b-input-group class="mx-2">
            <multiselect
              v-model="countriesToStopWorkingOn"
              :options="countriesToStopOptions"
              track-by="value"
              :custom-label="countryWithCode"
              @search-change="countryMultiSelectSearch"
              :closeOnSelect="false"
              :multiple="true"
            ></multiselect>
            <b-input-group-append>
              <b-button @click="selectAllCountriesToStop()" variant="primary">Select All</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click="clearCountriesToStop()" variant="warning">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-row>
        <!--        </b-col>-->
        <!--        <b-col class="col-12 col-12 col-md-12 col-lg-4 mb-3">-->
        <b-row class="my-5">
          <b-button class="mx-2 h-100" variant="warning" @click="stopWorkingOnSelectedCountries()">
            Yes please cancel my chosen geos
          </b-button>
        </b-row>
      </b-collapse>

      <!--        </b-col>-->
      <!--      </b-row>-->
    </div>

    <div v-if="isAdmin">
      <DashboardCharts />
    </div>
    <div v-if="user.email === 'blacklight@default.gg'" class="my-2 my-lg-3 mx-2 mx-lg-3">
      <p class="my-1">Delete Action By Id</p>
      <b-form-input v-model="actionIdToDelete" class="my-1"></b-form-input>
      <b-button @click="deleteAction()" variant="danger" class="my-1">Delete</b-button>
    </div>
    <div v-if="user.email === 'blacklight@default.gg'" class="my-2 my-lg-3 mx-2 mx-lg-3">
      <b-button @click="clearJunk()" variant="danger" class="my-1">Clear Junk Box</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  aggregateStats,
  deleteAction,
  clearJunk,
  toggleUserReadyToWorkForCountries,
  getMaffAvailableCountries,
  makeSocketUrl,
} from '@/utils/api';
import $ from 'jquery';
import { showCard, handleRequestError, countryWithCode, countryMultiSelectSearch } from '@/utils';
import 'vue-json-pretty/lib/styles.css';
// import HomepageStatsBreakdowns from '@/components/pages/Stats/components/HomepageStatsBreakdowns.vue';
import { countryOptions } from '@/consts';
import _ from 'lodash';
import DashboardCharts from '@/components/shared/DashboardCharts.vue';

export default {
  name: 'HomeView',
  data() {
    return {
      countryOptions,
      stats: [],
      requesting: false,
      actionIdToDelete: '',
      countriesToStartWorkingOn: [],
      countriesToStopWorkingOn: [],
      availableCountries: [],
      backdoor: 0,
    };
  },
  components: {
    DashboardCharts,
  },
  computed: {
    ...mapState(['token', 'user', 'isAdmin', 'isAffiliate', 'requesting']),
    countriesToStartOptions() {
      // this.backdoor;
      const countries = this.availableCountries;
      return this.countryOptions.filter((country) => countries.includes(country.value));
    },
    countriesToStopOptions() {
      // this.backdoor;
      const countries =
        this.user && this.user.workingOnCountries ? this.user.workingOnCountries : [];
      return this.countryOptions.filter((country) => countries.includes(country.value));
    },
  },
  methods: {
    getMaffAvailableCountries() {
      if (!this.user || !this.user.maff) return;
      getMaffAvailableCountries(this.token)
        .then((r) => {
          if (r.data.success) {
            this.availableCountries = r.data.countries;
            console.log('got available countries');
          } else {
            this.availableCountries = [];
            handleRequestError(this, r.data, 'Unknown error getting countries available for work');
          }
          // this.backdoor += 1;
        })
        .catch((e) =>
          handleRequestError(this, e, 'Unknown server error getting countries available for work')
        );
    },
    selectAllCountriesToStop() {
      this.countriesToStopWorkingOn = _.cloneDeep(this.countriesToStopOptions);
    },
    clearCountriesToStop() {
      this.countriesToStopWorkingOn = [];
    },
    selectAllCountriesToStart() {
      this.countriesToStartWorkingOn = _.cloneDeep(this.countriesToStartOptions);
    },
    clearCountriesToStart() {
      this.countriesToStartWorkingOn = [];
    },
    startWorkingOnSelectedCountries() {
      // showCard(this, 'Start test');
      // return;
      // eslint-disable-next-line no-unreachable
      if (this.countriesToStartWorkingOn.length === 0) return;
      const data = {
        countries: this.countriesToStartWorkingOn,
        ready: true,
      };
      this.$store.commit('make_requesting', { req: true });
      toggleUserReadyToWorkForCountries(this.token, data)
        .then((r) => {
          if (r.data.success) {
            showCard(
              this,
              `You started working on countries: ${this.countriesToStartWorkingOn
                .map((c) => (c.value ? c.value : c))
                .join(',')}`,
              'success'
            );
            this.clearCountriesToStart();
          } else {
            handleRequestError(
              this,
              r.data,
              'Unknown error while starting working on countries. Please ask admin to check'
            );
          }
          this.$bvModal.hide('modal-start-working');
          this.$store.commit('make_requesting', { req: false });
          this.$store.dispatch('get_me');
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            'Unknown error while starting working on countries. Please ask admin to check'
          );
          this.$bvModal.hide('modal-start-working');
          this.$store.commit('make_requesting', { req: false });
        });
    },
    stopWorkingOnSelectedCountries() {
      // showCard(this, 'Stop test');
      // return;
      // eslint-disable-next-line no-unreachable
      if (this.countriesToStopWorkingOn.length === 0) return;
      const data = {
        countries: this.countriesToStopWorkingOn,
        ready: false,
      };
      this.$store.commit('make_requesting', { req: true });
      toggleUserReadyToWorkForCountries(this.token, data)
        .then((r) => {
          if (r.data.success) {
            showCard(
              this,
              `You stopped working on countries: ${this.countriesToStopWorkingOn
                .map((c) => (c.value ? c.value : c))
                .join(',')}`,
              'success'
            );
            this.clearCountriesToStop();
          } else {
            handleRequestError(
              this,
              r.data,
              'Unknown error while stopping working on countries. Please ask the admin to check'
            );
          }
          this.$bvModal.hide('modal-start-working');
          this.$store.commit('make_requesting', { req: false });
          this.$store.dispatch('get_me');
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            'Unknown error while stopping working on countries. Please ask the admin to check'
          );
          this.$bvModal.hide('modal-start-working');
          this.$store.commit('make_requesting', { req: false });
        });
    },
    countryMultiSelectSearch,
    countryWithCode(c) {
      return countryWithCode(c);
    },
    aggregateStats() {
      if (!this.isAdmin) return;
      this.requesting = true;
      aggregateStats(this.token)
        .then((r) => {
          this.requesting = false;
          console.log(r.data);
          this.stats = r.data;
        })
        .catch((e) => {
          console.error(e);
          this.requesting = false;
        });
    },
    selectJsonNode(path, data) {
      console.log('selectJsonNode', path, data);
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      // if (typeof data !== 'object') {
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
      // }
    },
    deleteAction() {
      if (this.user.email === 'blacklight@default.gg') {
        deleteAction(this.token, { _id: this.actionIdToDelete })
          .then(() => showCard(this, 'Deleted', 'success'))
          .catch((e) => handleRequestError(this, e, 'Error deleting action'));
      }
    },
    clearJunk() {
      if (this.user.email === 'blacklight@default.gg') {
        clearJunk(this.token)
          .then((r) => {
            if (r.data.success) {
              showCard(this, 'Cleared junk', 'success');
            } else {
              showCard(this, r.data.message, 'danger');
            }
          })
          .catch((e) => handleRequestError(this, e, 'Error deleting action'));
      }
    },
    createWebsocket() {
      if (this.user.email !== 'blacklight@default.gg') return;
      const socketUrl = makeSocketUrl(`testa/`);

      /** Initialize our websocket connection */
      const socket = new WebSocket(socketUrl);
      socket.onopen = () => {
        /** Send a new message */
        socket.onmessage = async (event) => {
          // const data = JSON.parse(event.data);
          console.log('websocket response', event.data);
          if (event.data === 'authorized') {
            socket.send('shto');
          } else {
            try {
              const data = JSON.parse(event.data);
              if (data.message === 'You are authorized') {
                showCard(this, data.message);
              } else {
                showCard(this, data.message, 'warning');
              }
            } catch (e) {
              showCard(this, 'Error parsing websocket message', 'danger');
            }
          }
        };
        socket.send('shto');
        socket.send(`Bearer ${this.token}`);
        // socket.send('shto');
      };
      socket.onerror = async (event) => {
        console.error('Error in createWebsocket', event);
      };
      socket.onclose = async (event) => {
        console.log(`Socket closed with code ${event.code}`);
      };
    },
  },
  created() {
    // this.aggregateStats();
    // this.interval = setInterval(() => {
    //   if (this.$route.path === '/') {
    //     this.aggregateStats();
    //   }
    // }, 15000);
    this.getMaffAvailableCountries();
    this.maffCountriesInterval = setInterval(() => {
      if (this.$route.path === '/') {
        this.getMaffAvailableCountries();
      }
    }, 15000);
    this.createWebsocket();
  },
  beforeDestory() {
    clearInterval(this.interval);
  },
};
</script>
