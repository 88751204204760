<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h5 class="mx-1 my-2">{{ name }}</h5>
    <b-form-group
      class="my-3"
      label="Attach result to:"
      label-for="authparameters-attach"
      :state="isValid.fields.to"
    >
      <b-form-select
        id="authparameters-attach"
        v-model="form.to"
        :options="authAttachToOptions"
        @input="emitEditEvent"
        :state="isValid.fields.to"
      ></b-form-select>
    </b-form-group>
    <b-form-group
      class="my-3"
      label="Field"
      label-for="authparameters-field"
      :state="isValid.fields.field"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name" -->
      <b-form-input
        type="text"
        id="authparameters-field"
        v-model="form.field"
        @input="emitEditEvent"
        :state="isValid.fields.field"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
// import handleValidateEvent from '@/utils/handleValidateEvent';
// import handleEditEvent from '@/utils/handleEditEvent';
// import handleMultipleKeysEditEvent from '@/utils/handleMultipleKeysEditEvent';
import { mapPropFieldToValid } from '@/utils';
import { formValid, isValid } from '@/validators';
import { authAttachToOptions } from '@/consts';

const initial = {
  to: '',
  field: '',
};

export default {
  name: 'AuthAttachParameters',
  props: {
    name: { type: String, default: 'Auth Attach' },
    attach: {
      to: { type: String, default: '' },
      field: { type: String, default: '' },
    },
  },
  data() {
    return {
      form: cloneDeep(this.$props.attach ? this.$props.attach : initial),
      valid: mapPropFieldToValid(this.$props.attach, initial),
      backdoor: 0,
      authAttachToOptions,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = isValid('integration_AuthAttachParameters', this.form, this.form);
      const form = formValid(fields);
      // this.$emit('validate', form);
      // console.log('AuthAttachParameters validated', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    // handleEditEvent,
    // handleMultipleKeysEditEvent,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    // handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
