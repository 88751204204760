<template>
  <div class="wrapper">
    <rent-sidebar v-if="$rent"></rent-sidebar>
    <main-sidebar v-else></main-sidebar>

    <div class="main-content">
      <!--      <Header></Header>-->
      <MainHeader></MainHeader>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { FadeTransition } from 'vue2-transitions';
// import Header from '@/components/shared/Header.vue';
import MainSidebar from '@/views/MainSidebar.vue';
import MainHeader from '@/components/shared/MainHeader.vue';
import RentSidebar from '@/views/RentSidebar.vue';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  name: 'MainLayout',
  components: {
    RentSidebar,
    MainHeader,
    MainSidebar,
    // Header,
    FadeTransition,
  },
  methods: {
    initScrollbar() {
      const isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>

<style scoped></style>
