<template>
  <div>
    <b-form>
      <p>Run Distribution Simulation</p>
      <p>Box {{ findBoxName(_id) }}</p>
      <b-form-group class="my-1" label="Geo" label-for="simulation-location">
        <b-form-select
          id="simulation-location"
          :options="boxCountryOptions"
          v-model="simulationForm.location"
          :state="simulationForm.location ? isValid.fields.location : undefined"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        class="my-1"
        label="Amount"
        label-for="simulation-amount"
        invalid-feedback="Amount must be between 0 and 1000"
      >
        <b-form-input
          id="simulation-amount"
          type="number"
          v-model="simulationForm.amount"
          :state="simulationForm.amount ? isValid.fields.amount : undefined"
          invalid-feedback="Amount must be between 0 and 1000"
        ></b-form-input>
      </b-form-group>
      <b-checkbox v-model="simulationForm.useCapsAsWeights" class="m-2">
        Automated Flow From Mother
      </b-checkbox>
      <b-button
        class="my-2"
        variant="primary"
        size="sm"
        @click="sendSimulationRequest()"
        :disabled="requesting || !isValid.form"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        Run{{ requesting ? 'ning' : '' }} Simulation
      </b-button>
    </b-form>
    <!--    <div v-if="result._id !== undefined">-->
    <h5>Result</h5>
    <div class="my-2">
      <div
        class="mx-2 my-1 dataspan orangetext"
        v-for="(item, index) in sendingResults"
        :key="index"
      >
        {{ item.campaign ? item.campaign.name : 'unknown' }}: {{ item.number }}
      </div>
    </div>
    <div class="my-2">
      <b-card header="Aggregated Campaign Selection Dropouts">
        <vue-json-pretty
          :data="aggregatedDropouts"
          :show-line="true"
          :select-on-click-node="true"
          :highlight-selected-node="true"
          :selectable-type="'single'"
          :show-select-controller="false"
          @click="(path, data) => selectJsonNode(path, data)"
          style="max-width: 506px; word-wrap: break-word"
        ></vue-json-pretty>
      </b-card>
    </div>
    <!--    </div>-->
  </div>
</template>

<script>
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import { formValid, isValid } from '@/validators';
import _ from 'lodash';
import { runDistributionSimulation } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';
import { countryOptions } from '@/consts';
import $ from 'jquery';
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'ActionsDistributionSimulator',
  components: {
    VueJsonPretty,
  },
  props: {
    _id: { type: String, default: '' },
    countries: { type: Array, default: () => [] },
  },
  data() {
    return {
      requesting: false,
      backdoor: 0,
      simulationForm: {
        _id: this._id,
        location: undefined,
        amount: undefined,
        useCapsAsWeights: false,
      },
      countryOptions,
      result: { _id: undefined, sendingResults: [], aggregatedDropouts: [] },
    };
  },
  computed: {
    ...mapState(['token', 'boxes']),
    isValid() {
      const fields = isValid('distribution_simulation', this.simulationForm, this.simulationForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    sendingResults() {
      this.backdoor;
      return _.cloneDeep(this.result.sendingResults);
    },
    aggregatedDropouts() {
      this.backdoor;
      return _.cloneDeep(this.result.aggregatedDropouts);
    },
    boxCountryOptions() {
      this.backdoor;
      return this.countries.map((country) => countryOptions.find((co) => co.value === country));
    },
  },
  methods: {
    selectJsonNode(path, data) {
      console.log('selectJsonNode', path, data);
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      // if (typeof data !== 'object') {
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
      // }
    },
    findBoxName(boxId) {
      const box = this.boxes.find((b) => b._id === boxId);
      return box && box.name ? box.name : boxId;
    },
    sendSimulationRequest() {
      if (this.isValid.form) {
        this.requesting = true;
        const simulationRequest = _.cloneDeep(this.simulationForm);
        simulationRequest._id = this._id;
        runDistributionSimulation(this.token, simulationRequest)
          .then((r) => {
            console.log('r.data', r.data);
            const { _id, sendingResults, aggregatedDropouts } = r.data;
            this.result = {
              _id,
              sendingResults,
              aggregatedDropouts,
            };
            console.log('this.result', this.result);
            showCard(this, `Simulation ran successful, please see the result`, 'success');
            this.backdoor += 1;
            this.requesting = false;
          })
          .catch((e) => {
            handleRequestError(
              this,
              e,
              `Unknown server error on running simulation request for box
              } times to box ${this.findBoxName(simulationRequest._id)}`
            );
            this.requesting = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
