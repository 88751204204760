<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h5 class="mx-1 my-2">{{ name }}</h5>
    <b-form-group
      class="my-3"
      label="Endpoint"
      label-for="pullingftds-endpoint"
      :state="isValid.fields.endpoint"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
      -->
      <b-form-input
        type="text"
        id="pullingftds-endpoint"
        v-model="form.endpoint"
        @input="emitEditEvent"
        :state="isValid.fields.endpoint"
      ></b-form-input>
    </b-form-group>
    <PullingPaginationParameters
      :Pagination="form.Pagination"
      @edit="handleEditEvent($event, 'Pagination')"
      @validate="handleValidateEvent($event, 'Pagination')"
    ></PullingPaginationParameters>
    <PathArrayForm
      :pathArray="form.ResultsArrayPath"
      name="ResultsArray Path"
      @edit="handleEditEvent($event, 'ResultsArrayPath')"
      @validate="handleValidateEvent($event, 'ResultsArrayPath')"
    ></PathArrayForm>
    <PathArrayForm
      :pathArray="form.LeadIdPath"
      name="LeadId Path"
      @edit="handleEditEvent($event, 'LeadIdPath')"
      @validate="handleValidateEvent($event, 'LeadIdPath')"
      disallowEmpty
    ></PathArrayForm>
    <div :class="`${dateValid ? 'tab' : 'tab-invalid'}`">
      <b-form-checkbox class="my-2" v-model="form.HaveDate" @input="emitEditEvent">
        Have FTD Date
      </b-form-checkbox>
      <b-form-group class="my-3" label="FTD Date Path" label-for="pullingftds-datepath">
        <PathForm
          v-if="form.HaveDate"
          :arr="form.DatePath"
          id="pullingftds-datepath"
          @edit="handleEditEvent($event, 'DatePath')"
          @validate="handleValidateEvent($event, 'DatePath')"
          disallowEmpty
        ></PathForm>
        <b-form-group
          v-if="form.HaveDate"
          class="my-3"
          label="FTD Date Format"
          label-for="pullingftds-dateformat"
          :state="isValid.fields.DateFormat"
        >
          <!--      :invalid-feedback="invalidFeedback.campaign.name"
          -->
          <b-form-input
            type="text"
            id="pullingftds-dateformat"
            v-model="form.DateFormat"
            @input="emitEditEvent"
            :state="isValid.fields.DateFormat"
          ></b-form-input>
        </b-form-group>
        <!--        <p>valid.DatePath: {{ valid.DatePath }}</p>-->
        <!--        <p>isValid.fields.DatePath: {{ isValid.fields.DatePath }}</p>-->
        <!--        <p>isValid.fields: {{ isValid.fields }}</p>-->
        <!--        <p>isValid.form: {{ isValid.form }}</p>-->
      </b-form-group>
    </div>
    <dataPreprocessingParameters
      :dataPreprocessing="form.dataPreprocessing"
      @edit="handleEditEvent($event, 'dataPreprocessing')"
      @validate="handleValidateEvent($event, 'dataPreprocessing')"
    ></dataPreprocessingParameters>
    <dataPreprocessingParameters
      name="Data Postprocessing"
      :dataPreprocessing="form.dataPostprocessing"
      @edit="handleEditEvent($event, 'dataPostprocessing')"
      @validate="handleValidateEvent($event, 'dataPostprocessing')"
    ></dataPreprocessingParameters>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
import handleEditEvent from '@/utils/handleEditEvent';
import PathArrayForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PathArrayForm.vue';
import { formValid, isValid } from '@/validators'; // castAuthArrayToObject, castAuthObjectToArray,
import dataPreprocessingParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/dataPreprocessingParameters.vue';
import PathForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PathForm.vue';
import handleValidateEvent from '@/utils/handleValidateEvent';
import { mapPropFieldToValid } from '@/utils';
import PullingPaginationParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PullingPaginationParameters.vue';

const initial = {
  endpoint: '',
  // ResultsArrayPath: [],
  // LeadIdPath: [],
  HaveDate: false,
  DatePath: [],
  DateFormat: '',
  // dataPreprocessing: {},
};
export default {
  name: 'PullingFTDsSettings',
  components: { PathForm, PathArrayForm, dataPreprocessingParameters, PullingPaginationParameters },
  props: {
    name: { type: String, default: 'Pulling FTDs Settings' },
    settings: {
      endpoint: { type: String, default: '' },
      Pagination: {
        type: Object,
        default: () => {
          return {};
        },
      },
      ResultsArrayPath: { type: Array, default: () => [] },
      LeadIdPath: { type: Array, default: () => [] },
      HaveDate: { type: Boolean, default: false },
      DatePath: { type: [String, Array], default: [] },
      DateFormat: { type: String, default: '' },
      dataPreprocessing: {
        type: Object,
        default: () => {
          return {};
        },
      },
      dataPostprocessing: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  },
  data() {
    return {
      form: (() => {
        const props = cloneDeep(this.$props.settings ? this.$props.settings : initial);
        // props.StatusCodes = castAuthObjectToArray(props.StatusCodes);
        props.DatePath = props.DatePath
          ? typeof props.DatePath === 'string'
            ? [props.DatePath]
            : props.DatePath
          : [];
        return props;
      })(),
      valid: mapPropFieldToValid(this.$props.settings, initial),
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const DatePath = !this.form.HaveDate || this.valid.DatePath;
      const fields = {
        ...this.valid,
        ...isValid('integration_PullingFTDsSettings', this.form, this.form),
        DatePath,
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
    dateValid() {
      const { HaveDate, DatePath } = this.isValid.fields;
      return HaveDate && DatePath;
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    castFormToEmit() {
      const form = cloneDeep(this.form);
      // if (form.StatusCodes) form.StatusCodes = castAuthArrayToObject(form.StatusCodes);
      return form;
    },
    emitEditEvent() {
      this.$emit('edit', this.castFormToEmit());
    },
    handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
