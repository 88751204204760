<template>
  <div>
    <h5>Change Trader Status</h5>
    <b-form-radio-group
      v-model="status"
      :options="options"
      class="mb-3"
      stacked
    ></b-form-radio-group>
    <b-button
      vatiant="primary"
      display="block"
      :disabled="requesting"
      @click="changeTraderStatus()"
    >
      <LoadingSpinner :requesting="requesting" v-if="requesting" />
      Change
    </b-button>
  </div>
</template>

<script>
import { handleRequestError } from '@/utils';
import { changeTraderStatus } from '@/utils/api';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

export default {
  name: 'ChangeTraderStatus',
  components: { LoadingSpinner },

  props: { _id: { type: String, default: '' }, initStatus: { type: String, default: '' } },
  data() {
    return {
      requesting: false,
      status: 'new',
      options: ['new', 'in progress', 'done', 'denied', 'requested info', 'no access to cabinet'],
    };
  },
  methods: {
    changeTraderStatus() {
      this.requesting = true;
      changeTraderStatus(this.token, { _id: this._id, status: this.status })
        .then((r) => {
          this.$emit('changed', r.data.status);
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error changing trader status');
          this.requesting = false;
        });
    },
  },
  mounted() {
    this.status = this.initStatus;
  },
};
</script>

<style scoped></style>
