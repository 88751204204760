import { oidRegex } from '@/validators/_utils';
import { LeadContactInfoFields } from '@/consts';

export default {
  box: (c) => oidRegex.test(c),
  count: (c) => !!c && Number.isInteger(Number(c)) && Number(c) > 0,
  m: (c) => typeof c === 'boolean',
  lead: (c) =>
    !!c && typeof c === 'object' && LeadContactInfoFields.every((k) => Object.keys(c).includes(k)),
};
