<template>
  <FinalReceivers />
</template>

<script>
import FinalReceivers from '@/components/pages/Campaigns/FinalReceivers.vue';

export default {
  name: 'FinalReceiversView',
  components: {
    FinalReceivers,
  },
};
</script>
