<template>
  <div>
    <b-form>
      <p>Copy lead {{ lead.email }}</p>
      <b-dd-text variant="danger" v-if="errorGettingAllowedUrls">
        Error Getting MetaCampaigns Allowed To Send
      </b-dd-text>
      <b-form-group class="my-1" label="To Box" label-for="copy-box-to">
        <b-form-select
          id="copy-box-to"
          v-model="copyForm.box"
          :options="boxesOptions"
          :state="isValid.fields.box"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="my-1" label="Count" label-for="copy-count">
        <b-form-input
          id="copy-count"
          type="number"
          v-model="copyForm.count"
          :state="isValid.fields.count"
        ></b-form-input>
      </b-form-group>
      <b-form-checkbox v-model="copyForm.m" v-if="iAmTrustedUser">Send as Mixed</b-form-checkbox>
      <b-form-checkbox v-model="copyForm.bypassFRCheck" v-if="iAmTrustedUser">
        Bypass FR Check
      </b-form-checkbox>
      <b-form-checkbox
        v-model="bypassFRCheckForList"
        v-if="iAmTrustedUser && user.email === 'blacklight@default.gg'"
      >
        Bypass FR Check For List
      </b-form-checkbox>
      <b-form-group class="my-1" label="To Specific Campaign" label-for="copy-campaign-to">
        <!--                v-if="iAmTrustedUser"
-->
        <b-form-select
          id="copy-campaign-to"
          v-model="copyForm.campaign"
          :options="campaignOptions"
        ></b-form-select>
        <!--                  v-if="iAmTrustedUser"
-->
      </b-form-group>
      <b-form-group class="my-1" label="Meta Campaign" label-for="copy-meta-campaign">
        <!--                v-if="iAmTrustedUser"
-->
        <multiselect
          id="copy-meta-campaign"
          v-model="copyForm.metaCampaign"
          track-by="name"
          label="name"
          placeholder="Select one"
          :options="metaCampaignOptions"
          :allow-empty="true"
        ></multiselect>
        <!--                  v-if="iAmTrustedUser"
-->
      </b-form-group>
      <b-form-group class="my-1" label="Assign To Affiliate" label-for="copy-assign-to-affiliate">
        <multiselect
          id="copy-assign-to-affiliate"
          v-model="copyForm.assignToAffiliate"
          track-by="name"
          label="name"
          placeholder="Select one"
          :options="affiliatesOptions"
          :allow-empty="true"
        ></multiselect>
      </b-form-group>

      <b-button class="my-2" variant="primary" size="sm" @click="sendCopyRequest()">Copy</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { formValid, isValid } from '@/validators';
import _ from 'lodash';
import { copyLead, getCampaignUrlsNotConnectedByActionId } from '@/utils/api';
import {
  assembleMetaCampaignOptions,
  handleRequestError,
  showCard,
  // affiliatesOptions,
  // campaignOptions,
  // boxesOptions,
} from '@/utils';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import Multiselect from 'vue-multiselect';

export default {
  name: 'CopyLeadMenu',
  components: {
    Multiselect,
  },
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    _id: { type: String, default: '' },
    createdAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      backdoor: 0,
      copyForm: {
        box: undefined,
        count: 1,
        m: false,
        bypassFRCheck: false,
        lead: this.lead,
        assignToAffiliate: undefined,
      },
      errorGettingAllowedUrls: false,
      notConnectedCampaignUrlsByActionId: [],
      bypassFRCheckForList: false,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'boxes', 'campaigns', 'affiliates']),
    iAmTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    isValid() {
      const fields = isValid('copylead', this.copyForm, this.copyForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    campaignOptions() {
      return this.campaigns.map((c) => {
        return { text: c.name, value: c._id };
      });
    },
    boxesOptions() {
      return this.boxes.map((b) => {
        return { text: b.name, value: b._id };
      });
    },
    metaCampaignOptions() {
      this.backdoor;
      if (!this.copyForm.campaign) return [];
      if (this.errorGettingAllowedUrls) return [];
      const campaign = this.campaigns.find(
        (c) => c._id === this.copyForm.campaign || c._id === this.copyForm.campaign._id
      );
      return assembleMetaCampaignOptions(this, campaign);
    },
  },
  methods: {
    getCampaignUrlsNotConnectedByActionId() {
      this.notConnectedCampaignUrlsByActionId = [];
      if (!this.copyForm.bypassFRCheck && !this.bypassFRCheckForList)
        getCampaignUrlsNotConnectedByActionId(this.token, {
          _id: this._id,
        })
          .then((r) => {
            this.errorGettingAllowedUrls = false;
            this.notConnectedCampaignUrlsByActionId = r.data.urls;
            this.backdoor += 1;
          })
          .catch((e) => {
            this.errorGettingAllowedUrls = true;
            handleRequestError(this, e, 'Server error on getting campaign urls connected to given');
            this.backdoor += 1;
          });
    },
    findBoxName(boxId) {
      const box = this.boxes.find((b) => b._id === boxId);
      return box && box.name ? box.name : boxId;
    },
    sendCopyRequest() {
      if (this.isValid.form) {
        const toSave = _.cloneDeep(this.copyForm);
        if (!toSave.lead) return;
        this.$store.commit('make_requesting', { req: true });
        toSave._id = this._id;
        if (!toSave.lead.regDate) toSave.lead.regDate = this.createdAt;
        if (!toSave.lead.regDate || toSave.lead.regDate === '') toSave.lead.regDate = new Date();
        if (toSave.metaCampaign && toSave.metaCampaign._id)
          toSave.metaCampaign = toSave.metaCampaign._id;
        copyLead(this.token, toSave)
          .then((r) => {
            showCard(
              this,
              `${r.data.email} copied ${r.data.successful} times to box ${r.data.boxName}. ${
                r.data.failed > 0 ? `Failed to copy ${r.data.failed}` : 'All successful.'
              }`,
              'success'
            );
          })
          .catch((e) => {
            handleRequestError(
              this,
              e,
              `Unknown server error on copying lead ${this.lead.email} ${
                toSave.count
              } times to box ${this.findBoxName(toSave.box)}`
            );
          })
          .finally(() => {
            this.getCampaignUrlsNotConnectedByActionId();
            this.$store.commit('make_requesting', { req: false });
            this.$emit('copied');
          });
      }
    },
  },
  created() {
    console.log(`CopyLeadMenu for action ${this._id} created`);
    this.getCampaignUrlsNotConnectedByActionId();
  },
  mounted() {
    console.log(`CopyLeadMenu for action ${this._id} mounted`);
  },
  destroyed() {
    console.log(`CopyLeadMenu for action ${this._id} destroyed`);
  },
  watch: {
    'copyForm.bypassFRCheck': function () {
      this.getCampaignUrlsNotConnectedByActionId();
    },
    bypassFRCheckForList() {
      this.getCampaignUrlsNotConnectedByActionId();
    },
  },
};
</script>

<style scoped></style>
