<template>
  <TransactionsTable />
</template>

<script>
import TransactionsTable from '@/components/pages/FinancialTransactions/TransactionsTable.vue';

export default {
  name: 'FinancialTransactionsView',
  components: {
    TransactionsTable,
  },
};
</script>
