<template>
  <b-container fluid>
    <!--    <b-form @submit="onSubmit">-->
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">Edit Box</h2>
    </div>
    <h4 class="my-2" v-if="boxForm._id">
      Daughter
      <b>{{ daughter.name }}</b>
      ; Grandmaster box ID: {{ boxForm._id }}
    </h4>

    <!--    <b-row class="d-flex align-items-center mb-3">-->
    <!--      <b-col class="d-flex flex-column align-items-center">-->
    <b-form-group
      class="my-2"
      label="Name"
      label-for="box-name"
      :invalid-feedback="invalidFeedback.box.name"
      :state="isValid.fields.name"
    >
      <b-form-input
        id="box-dailycap-amount"
        :state="isValid.fields.name"
        v-model="boxForm.name"
      ></b-form-input>
    </b-form-group>
    <b-form-checkbox class="my-2" v-model="boxForm.active">Active</b-form-checkbox>
    <b-row>
      <b-col cols="12" lg="4" md="4" class="d-flex my-2">
        <span class="mx-3 dataspan" v-if="boxForm && boxForm.affiliate">
          Affiliate: {{ boxForm && boxForm.affiliate ? boxForm.affiliate.name : 'undefined' }}
        </span>
        <span class="mx-3 dataspan">
          Manager: {{ boxForm && boxForm.manager ? boxForm.manager.name : 'undefined' }}
        </span>
      </b-col>
    </b-row>
    <b-checkbox v-if="isAdmin" :state="isValid.fields.csi" v-model="boxForm.csi">csi</b-checkbox>
    <b-checkbox v-if="isAdmin" :state="isValid.fields.cso" v-model="boxForm.cso">cso</b-checkbox>

    <b-form-group
      v-if="isAdmin && boxForm.cso"
      class="my-2"
      label="dso"
      label-for="box-dso"
      :state="isValid.fields.dso"
    >
      <b-form-input
        v-if="isAdmin"
        id="box-dso"
        :state="isValid.fields.dso"
        v-model="boxForm.dso"
      ></b-form-input>
    </b-form-group>
    <b-checkbox :state="isValid.fields.isTest" v-model="boxForm.isTest">Is Test box</b-checkbox>
    <b-checkbox :state="isValid.fields.loadBalancer" v-model="boxForm.loadBalancer">
      Use Load Balancer
    </b-checkbox>
    <b-checkbox :state="isValid.fields.isMBox" v-model="boxForm.isMBox">MBox</b-checkbox>
    <b-checkbox
      :state="isValid.fields.liveOmitTimeIntervalCheck"
      v-model="boxForm.liveOmitTimeIntervalCheck"
    >
      Live (Omit BCE Time Interval Check)
    </b-checkbox>
    <b-row>
      <b-col cols="12" lg="4" md="4">
        <b-form-group
          class="my-2"
          label="Load Balancer Coefficient"
          label-for="load-balancer-coefficient"
          :invalid-feedback="invalidFeedback.box.loadBalancerCoefficient"
          :state="isValid.fields.loadBalancerCoefficient"
        >
          <b-form-input
            id="load-balancer-coefficient"
            type="number"
            :state="isValid.fields.loadBalancerCoefficient"
            v-model="boxForm.loadBalancerCoefficient"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <h3 class="pt-3 mb-3">Campaigns</h3>
    <b-row class="my-3">
      <b-col class="col-12 col-md-6 col-lg-4 mb-lg-0">
        <span class="d-block h5 font-weight-normal">Campaigns</span>
        <b-input-group>
          <multiselect
            v-model="filter.campaigns"
            :options="filterCampaignOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
          <b-input-group-append>
            <b-button @click="selectAllFilterCampaigns()" variant="primary">Select All</b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button @click="clearFilterCampaigns()" variant="warning">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col class="col-12 col-md-6 col-lg-4">
        <span class="d-block h5 font-weight-normal">Geos</span>
        <b-input-group>
          <!--          <template #default>-->
          <multiselect
            v-model="filter.countries"
            :options="filterCountryOptions"
            track-by="value"
            :custom-label="countryWithCode"
            @search-change="countryMultiSelectSearch"
            :closeOnSelect="false"
            :multiple="true"
          ></multiselect>
          <!--          </template>-->
          <b-input-group-append>
            <b-button @click="selectAllFilterCountries()" variant="primary">Select All</b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button @click="clearFilterCountries()" variant="warning">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <!--    <div style="overflow: hidden">-->
    <b-table
      class="mb-3"
      style="white-space: nowrap"
      sort-by="active"
      :sort-desc="true"
      :fields="fields"
      :items="bceTableItems"
      :filter-function="bceFilterFunction"
      :filter="filter"
      :busy="requesting"
      @filtered="onFiltered"
      @sort-changed="changeSorting"
      show-empty
      striped
      responsive
      hover
      small
    >
      <!--            :items="boxForm.campaigns"
  -->

      <!--      <template #cell()="data">-->
      <!--        <div @click="data.toggleDetails">{{ data.value }}</div>-->
      <!--      </template>-->

      <!--      <template #cell(campaign)="data">-->
      <!--        &lt;!&ndash;        <b-link @click="data.toggleDetails" style="color: inherit">&ndash;&gt;-->
      <!--        <div @click="data.toggleDetails">{{ data.value }}</div>-->
      <!--        &lt;!&ndash;        </b-link>&ndash;&gt;-->
      <!--      </template>-->
      <template
        #cell(campaign)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div
          @click="editFieldOn($event, bce.field.key, bce.item.bce_index)"
          style="overflow: visible"
        >
          {{ bce.value ? bce.value : 'none' }}

          <b-form
            style="width: 270px; overflow: visible"
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <multiselect
              :value="boxForm.campaigns[bce.item.bce_index].campaign_Multiselect"
              @input="($event) => handleCampaignsMultiselect($event, bce.item.bce_index)"
              :options="campaignsMultiselectOptions"
              track-by="name"
              label="name"
              :closeOnSelect="true"
              :multiple="false"
              :state="isValid.fields.campaigns[bce.item.bce_index].campaign"
              class="mb-2"
              :allow-empty="false"
              :maxHeight="600"
            ></multiselect>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <!--          <b-button variant="success" @on:click="makeBoxActive(data.item._id, true)">-->
          <!--            <b-icon icon="play-fill"></b-icon>-->
          <!--            Play-->
          <!--          </b-button>-->
          <!--          <b-button variant="warning" @on:click="makeBoxActive(data.item._id, false)">-->
          <!--            <b-icon icon="pause-fill"></b-icon>-->
          <!--            Pause-->
          <!--          </b-button>-->
          <b-button variant="primary" @click="data.toggleDetails">
            <!--             @on:click="startEditBox(data.item._id)"-->
            <b-icon icon="pencil-square"></b-icon>
            {{ data.detailsShowing ? 'Editing' : 'Edit' }}
          </b-button>
          <b-dropdown variant="danger" right no-caret size="sm">
            <template #button-content>
              <b-icon icon="trash-fill"></b-icon>
            </template>
            <b-dropdown-item-button
              variant="danger"
              v-on:click="deleteRow(data.item.bce_index)"
              :disabled="requesting"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Delete campaign
            </b-dropdown-item-button>
          </b-dropdown>
          <!--          </b-button>-->
          <!--          <b-button variant="danger">-->
          <!--            <b-icon icon="trash-fill"></b-icon>-->
          <!--            Delete-->
          <!--          </b-button>-->
        </b-button-group>
      </template>

      <template #cell(active)="bce">
        <h4 :id="`bce-cell-${bce.field.key}-${bce.item.bce_index}`">
          <b-badge
            :variant="bce.value === true ? 'success' : 'danger'"
            @click="toggleBCEActive(bce.item)"
            class="badge-toggle"
          >
            {{ bce.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h4>
        <b-popover
          :target="`bce-cell-${bce.field.key}-${bce.item.bce_index}`"
          triggers="hover"
          placement="top"
          :delay="{ show: 50, hide: 300 }"
        >
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
          >
            Apply To All Visible
          </b-button>
        </b-popover>
      </template>
      <template #cell(useLimitMPerBCE)="bce">
        <h4 :id="`bce-cell-${bce.field.key}-${bce.item.bce_index}`">
          <b-badge
            :variant="bce.value === true ? 'success' : 'info'"
            @click="toggleBCEUseLimitMPerBCE(bce.item)"
            class="badge-toggle"
          >
            {{ bce.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h4>
        <b-popover
          :target="`bce-cell-${bce.field.key}-${bce.item.bce_index}`"
          triggers="hover"
          placement="top"
          :delay="{ show: 50, hide: 300 }"
        >
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
          >
            Apply To All Visible
          </b-button>
        </b-popover>
      </template>
      <template #cell(useLinkCheckService)="bce">
        <h4 :id="`bce-cell-${bce.field.key}-${bce.item.bce_index}`">
          <b-badge
            :variant="bce.value === true ? 'warning' : 'info'"
            @click="
              iAmOtherLevelTrustedUser ? toggleBCEUseLinkCheckService(bce.item) : (() => {})()
            "
            :class="iAmOtherLevelTrustedUser ? 'badge-toggle' : ''"
          >
            {{ bce.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h4>
        <b-popover
          v-if="iAmOtherLevelTrustedUser"
          :target="`bce-cell-${bce.field.key}-${bce.item.bce_index}`"
          triggers="hover"
          placement="top"
          :delay="{ show: 50, hide: 300 }"
        >
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
          >
            Apply To All Visible
          </b-button>
        </b-popover>
      </template>
      <template
        #cell(days)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div @click="editFieldOn($event, bce.field.key, bce.item.bce_index)">
          <h4 :id="`bce-intable-span-${bce.item.bce_index}-days`">
            <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].days ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
          <b-form v-if="editingField === bce.field.key && editingItem === bce.item.bce_index">
            <b-form-checkbox-group
              :id="`box-campaign${bce.item.bce_index}-days`"
              v-model="boxForm.campaigns[bce.item.bce_index].days"
              :options="daysOfWeek"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              size="lg"
              stacked
            ></b-form-checkbox-group>
            <!--            @input="backdoor += 1"-->
            <b-button
              class="mr-2"
              variant="primary"
              @click="boxForm.campaigns[bce.item.bce_index].days = [0, 1, 2, 3, 4, 5, 6]"
            >
              <!--              backdoor += 1;-->
              All
            </b-button>
            <b-button @click="boxForm.campaigns[bce.item.bce_index].days = []">
              <!--               backdoor += 1;-->
              None
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template
        #cell(hourLimit)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div @click="editFieldOn($event, bce.field.key, bce.item.bce_index)">
          <h4 :id="`bce-intable-span-${bce.item.bce_index}-hourLimit`">
            <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
            <b-badge variant="info" class="badge-toggle">
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
          <b-form v-if="editingField === bce.field.key && editingItem === bce.item.bce_index">
            <div>
              <h6 class="mr-1">
                From: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[0] }}:00
              </h6>
              <b-form-input
                v-model="
                  boxForm.campaigns[
                    bce.item.bce_index // boxForm.campaigns.findIndex((c) => c.campaign === bce.item.campaign)
                  ].hourLimit[0]
                "
                type="range"
                min="0"
                :max="23"
              ></b-form-input>
              <h6 class="mx-1 my-2">
                to: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[1] }}:59
              </h6>
              <b-form-input
                v-model="boxForm.campaigns[bce.item.bce_index].hourLimit[1]"
                type="range"
                :min="0"
                max="23"
              ></b-form-input>
            </div>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template
        #cell(countries)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div @click="editFieldOn($event, bce.field.key, bce.item.bce_index)">
          <h4 :id="`bce-intable-span-${bce.item.bce_index}-countries`">
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].countries ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
          <b-form v-if="editingField === bce.field.key && editingItem === bce.item.bce_index">
            <multiselect
              v-model="boxForm.campaigns[bce.item.bce_index].countries"
              :options="countryOptions"
              track-by="value"
              :custom-label="countryWithCode"
              :closeOnSelect="false"
              :multiple="true"
              :state="isValid.fields.campaigns[bce.item.bce_index].countries"
              :style="
                isValid.fields.campaigns[bce.item.bce_index].countries ? 'border-color: red;' : ''
              "
              @input="
                applyDefaultBceSettings(bce.item.bce_index);
                backdoor += 1;
              "
              @search-change="countryMultiSelectSearch"
            >
              <!--                :showLabels="false"-->
            </multiselect>
            <b-button
              @click="selectAllCountries(bce.item.bce_index)"
              variant="info"
              class="my-2 d-block"
            >
              Select all accepted geos
            </b-button>
            <b-button
              @click="clearCountries(bce.item.bce_index)"
              variant="danger"
              class="my-2 d-block"
            >
              Clear geos
            </b-button>

            <div
              v-if="selectedBCECountries.selected[bce.item.bce_index]"
              style="white-space: normal"
            >
              <b-card
                class="my-2 text-center"
                style="display: block; max-width: 100%; max-height: 88px; overflow: auto"
              >
                <b-card-text>
                  Campaign accepts geos:
                  <b-badge
                    class="mx-1"
                    v-for="(c, index) in selectedBCECountries.campaignsAccept[
                      bce.item.bce_index
                    ][1]"
                    v-bind:key="index"
                  >
                    {{ c }}
                  </b-badge>
                </b-card-text>
              </b-card>
              <b-card
                style="display: block; max-width: 100%"
                v-if="selectedBCECountries.dontMatch[bce.item.bce_index][1].length > 0"
                border-variant="warning"
                header="Warning"
                header-bg-variant="warning"
                footer-bg-variant="transparent"
                class="my-2 text-center"
                no-body
              >
                <b-card-body style="max-height: 66px; overflow: auto">
                  <b-card-text style="word-wrap: break-word">
                    This campaign doesn't accept selected countries:
                    <b-badge
                      class="mx-1"
                      variant="warning"
                      v-for="(c, index) in selectedBCECountries.dontMatch[bce.item.bce_index][1]"
                      v-bind:key="index"
                    >
                      {{ c.value }}
                    </b-badge>
                  </b-card-text>
                </b-card-body>
                <template #footer>
                  <b-card-text>
                    Consider
                    <router-link
                      @click.native="goToEditCampaign()"
                      :to="`/campaign/edit/${
                        selectedBCECountries.dontMatch[bce.item.bce_index][0]
                      }?b=${$route.params.id ? $route.params.id : 'new'}`"
                    >
                      edit campaign
                    </router-link>
                    .
                  </b-card-text>
                </template>
              </b-card>
            </div>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
        </div>
      </template>

      <template
        #cell(dailyCap)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div
          class="badge-toggle"
          :id="`bce-dailycap-intable-form-${bce.item.bce_index}`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-dailycap-intable-edit-${bce.item.bce_index}-value`
            )
          "
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              :id="`bce-dailycap-intable-edit-${bce.item.bce_index}-value`"
              :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount"
              v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.amount"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
              style="width: 120px"
              class="m-1"
            ></b-form-input>
            <b-form-select
              :id="`bce-dailycap-intable-edit-${bce.item.bce_index}-type`"
              :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.type"
              v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.type"
              :options="actionTypeOptions"
              style="width: 120px"
              class="m-1"
            ></b-form-select>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-dailycap-intable-span-${bce.item.bce_index}`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-dailycap-intable-span-${bce.item.bce_index}`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].dailyCap.type &&
                isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount
                  ? bce.value === '-'
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template
        #cell(mFtdsDailyCap)="bce"
        v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
      >
        <div
          class="badge-toggle"
          :id="`bce-mftdsdailycap-intable-form-${bce.item.bce_index}`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-mftdsdailycap-intable-edit-${bce.item.bce_index}-value`
            )
          "
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-mftdsdailycap-intable-edit-${bce.item.bce_index}-value`"
              v-model="boxForm.campaigns[bce.item.bce_index].mFtdsDailyCap"
              :state="isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <h4
            :id="`bce-mftdsdailycap-intable-span-${bce.item.bce_index}`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap
                  ? bce.value === 0
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(totalCap)="bce">
        <div
          class="badge-toggle"
          :id="`bce-dailycap-intable-form-${bce.item.bce_index}`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-totalCap-intable-edit-${bce.item.bce_index}-value`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              :id="`bce-totalCap-intable-edit-${bce.item.bce_index}-value`"
              :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.amount"
              v-model="boxForm.campaigns[bce.item.bce_index].totalCap.amount"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
              style="width: 120px"
              class="m-1"
            ></b-form-input>
            <b-form-select
              :id="`bce-totalCap-intable-edit-${bce.item.bce_index}-type`"
              :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.type"
              v-model="boxForm.campaigns[bce.item.bce_index].totalCap.type"
              :options="actionTypeOptions"
              style="width: 120px"
              class="m-1"
            ></b-form-select>

            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-totalCap-intable-span-${bce.item.bce_index}`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-totalCap-intable-span-${bce.item.bce_index}`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].totalCap.type &&
                isValid.fields.campaigns[bce.item.bce_index].totalCap.amount
                  ? bce.value === '-'
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(limitMPerBCE)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-limitmperbce`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-limitmperbce`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-limitmperbce`"
              v-model="boxForm.campaigns[bce.item.bce_index].limitMPerBCE"
              :state="isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-limitmperbce`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value ? bce.value : 'unlimited' }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-limitmperbce`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE
                  ? !bce.value
                    ? 'warning'
                    : 'info'
                  : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'unlimited' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(weight)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-weight`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-weight`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-weight`"
              v-model="boxForm.campaigns[bce.item.bce_index].weight"
              :state="isValid.fields.campaigns[bce.item.bce_index].weight"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-weight`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-weight`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].weight ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(priority)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-priority`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-priority`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-priority`"
              v-model="boxForm.campaigns[bce.item.bce_index].priority"
              :state="isValid.fields.campaigns[bce.item.bce_index].priority"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-priority`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-priority`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="isValid.fields.campaigns[bce.item.bce_index].priority ? 'info' : 'danger'"
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #cell(defaultPayment)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-defaultPayment`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-defaultPayment`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-defaultPayment`"
              v-model="boxForm.campaigns[bce.item.bce_index].defaultPayment"
              :state="isValid.fields.campaigns[bce.item.bce_index].defaultPayment"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-defaultPayment`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-defaultPayment`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].defaultPayment ? 'info' : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>
      <template #cell(aff_defaultPayment)="bce">
        <div
          class="badge-toggle single-field-editable-div"
          :id="`bce-intable-form-${bce.item.bce_index}-aff_defaultPayment`"
          @click="
            editFieldOn(
              $event,
              bce.field.key,
              bce.item.bce_index,
              `bce-intable-edit-${bce.item.bce_index}-aff_defaultPayment`
            )
          "
          v-click-outside="{ method: 'editFieldOff', args: [bce.field.key, bce.item.bce_index] }"
        >
          <b-form
            inline
            v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
          >
            <b-form-input
              class="single-field-editable-cell"
              :id="`bce-intable-edit-${bce.item.bce_index}-aff_defaultPayment`"
              v-model="boxForm.campaigns[bce.item.bce_index].aff_defaultPayment"
              :state="isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment"
              v-if="editingField === bce.field.key && editingItem === bce.item.bce_index"
            ></b-form-input>
            <b-button
              variant="primary"
              size="sm"
              class="m-1"
              @click="applyFieldToAllVisibleCampaigns(bce.field.key, bce.item.bce_index)"
            >
              Apply To All Visible
            </b-button>
          </b-form>
          <!--          <span-->
          <!--            :id="`bce-intable-span-${bce.item.bce_index}-aff_defaultPayment`"-->
          <!--            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"-->
          <!--          >-->
          <!--            {{ bce.value }}-->
          <!--          </span>-->
          <h4
            :id="`bce-intable-span-${bce.item.bce_index}-aff_defaultPayment`"
            v-if="!(editingField === bce.field.key && editingItem === bce.item.bce_index)"
          >
            <b-badge
              :variant="
                isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment ? 'info' : 'danger'
              "
              class="badge-toggle"
            >
              {{ bce.value ? bce.value : 'none' }}
            </b-badge>
          </h4>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #row-details="bce">
        <b-list-group-item
          :variant="
            isValid.campaigns[bce.item.bce_index] && bce.item.campaignActive
              ? selectedBCECountries.dontMatch[bce.item.bce_index][1].length === 0
                ? 'success'
                : 'warning'
              : 'danger'
          "
        >
          <h3 v-if="!bce.item.campaignActive">Attention: Campaign Inactive</h3>
          <!--          <h6>{{ bce.item.bce_index + 1 }}</h6>-->
          <b-row>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Campaign"
                :label-for="`box-campaign${bce.item.bce_index}-campaign`"
                :invalid-feedback="invalidFeedback.box.campaigns.campaign"
                :state="isValid.fields.campaigns[bce.item.bce_index].campaign"
              >
                <!--                <b-form-select-->
                <!--                  :id="`box-campaign${bce.item.bce_index}-campaign`"-->
                <!--                  class="mb-2"-->
                <!--                  v-model="boxForm.campaigns[bce.item.bce_index].campaign"-->
                <!--                  :options="campaignOptions"-->
                <!--                  @input="backdoor += 1"-->
                <!--                  :state="isValid.fields.campaigns[bce.item.bce_index].campaign"-->
                <!--                ></b-form-select>-->
                <multiselect
                  :value="boxForm.campaigns[bce.item.bce_index].campaign_Multiselect"
                  @input="($event) => handleCampaignsMultiselect($event, bce.item.bce_index)"
                  :options="campaignsMultiselectOptions"
                  track-by="name"
                  label="name"
                  :closeOnSelect="true"
                  :multiple="false"
                  :state="isValid.fields.campaigns[bce.item.bce_index].campaign"
                  class="mb-2"
                  :allow-empty="false"
                  :maxHeight="600"
                ></multiselect>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Geos"
                label-for=""
                :state="isValid.fields.campaigns[bce.item.bce_index].countries"
              >
                <multiselect
                  v-model="boxForm.campaigns[bce.item.bce_index].countries"
                  :options="countryOptions"
                  track-by="value"
                  :custom-label="countryWithCode"
                  :closeOnSelect="false"
                  :multiple="true"
                  :state="isValid.fields.campaigns[bce.item.bce_index].countries"
                  :style="
                    isValid.fields.campaigns[bce.item.bce_index].countries
                      ? 'border-color: red;'
                      : ''
                  "
                  @input="
                    applyDefaultBceSettings(bce.item.bce_index);
                    backdoor += 1;
                  "
                  @search-change="countryMultiSelectSearch"
                >
                  <!--                :showLabels="false"-->
                </multiselect>
              </b-form-group>
              <b-button
                @click="selectAllCountries(bce.item.bce_index)"
                variant="info"
                class="my-2 d-block"
              >
                Select all accepted geos
              </b-button>
              <b-button
                @click="clearCountries(bce.item.bce_index)"
                variant="danger"
                class="my-2 d-block"
              >
                Clear geos
              </b-button>

              <div
                v-if="selectedBCECountries.selected[bce.item.bce_index]"
                style="white-space: normal"
              >
                <b-card
                  class="my-2 text-center"
                  style="display: block; max-width: 100%; max-height: 88px; overflow: auto"
                >
                  <b-card-text>
                    Campaign accepts geos:
                    <b-badge
                      class="mx-1"
                      v-for="(c, index) in selectedBCECountries.campaignsAccept[
                        bce.item.bce_index
                      ][1]"
                      v-bind:key="index"
                    >
                      {{ c }}
                    </b-badge>
                  </b-card-text>
                </b-card>
                <b-card
                  style="display: block; max-width: 100%"
                  v-if="selectedBCECountries.dontMatch[bce.item.bce_index][1].length > 0"
                  border-variant="warning"
                  header="Warning"
                  header-bg-variant="warning"
                  footer-bg-variant="transparent"
                  class="my-2 text-center"
                  no-body
                >
                  <b-card-body style="max-height: 66px; overflow: auto">
                    <b-card-text style="word-wrap: break-word">
                      This campaign doesn't accept selected countries:
                      <b-badge
                        class="mx-1"
                        variant="warning"
                        v-for="(c, index) in selectedBCECountries.dontMatch[bce.item.bce_index][1]"
                        v-bind:key="index"
                      >
                        {{ c.value }}
                      </b-badge>
                    </b-card-text>
                  </b-card-body>
                  <template #footer>
                    <b-card-text>
                      Consider
                      <router-link
                        @click.native="goToEditCampaign()"
                        :to="`/campaign/edit/${
                          selectedBCECountries.dontMatch[bce.item.bce_index][0]
                        }?b=${$route.params.id ? $route.params.id : 'new'}`"
                      >
                        edit campaign
                      </router-link>
                      .
                    </b-card-text>
                  </template>
                </b-card>
              </div>

              <b-form-checkbox
                class="my-2"
                v-model="boxForm.campaigns[bce.item.bce_index].active"
                :disabled="
                  Object.values(boxForm.campaigns[bce.item.bce_index].campaign).includes(
                    'TECH_CAMPAIGN'
                  ) ||
                  (campaigns.find(
                    (c) => c._id === boxForm.campaigns[bce.item.bce_index].campaign
                  ) &&
                    campaigns.find((c) => c._id === boxForm.campaigns[bce.item.bce_index].campaign)
                      .brokerApiType === 'TECH_CAMPAIGN')
                "
              >
                Active
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <h6 class="mb-2">Hour Limit</h6>
              <h6 class="mr-1">
                From: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[0] }}:00
              </h6>
              <b-form-input
                v-model="
                  boxForm.campaigns[
                    bce.item.bce_index // boxForm.campaigns.findIndex((c) => c.campaign === bce.item.campaign)
                  ].hourLimit[0]
                "
                type="range"
                min="0"
                :max="23"
                @input="backdoor += 1"
              ></b-form-input>
              <h6 class="mx-1 my-2">
                to: {{ boxForm.campaigns[bce.item.bce_index].hourLimit[1] }}:59
              </h6>
              <b-form-input
                v-model="boxForm.campaigns[bce.item.bce_index].hourLimit[1]"
                type="range"
                :min="0"
                max="23"
                @input="backdoor += 1"
              ></b-form-input>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Days"
                :label-for="`box-campaign${bce.item.bce_index}-days`"
              >
                <b-form-checkbox-group
                  :id="`box-campaign${bce.item.bce_index}-days`"
                  v-model="boxForm.campaigns[bce.item.bce_index].days"
                  :options="daysOfWeek"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  size="lg"
                  stacked
                  @input="backdoor += 1"
                ></b-form-checkbox-group>
                <b-button
                  class="mr-2"
                  variant="primary"
                  @click="
                    boxForm.campaigns[bce.item.bce_index].days = [0, 1, 2, 3, 4, 5, 6];
                    backdoor += 1;
                  "
                >
                  All
                </b-button>
                <b-button
                  @click="
                    boxForm.campaigns[bce.item.bce_index].days = [];
                    backdoor += 1;
                  "
                >
                  None
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Priority"
                :label-for="`box-campaign${bce.item.bce_index}-priority`"
                :invalid-feedback="invalidFeedback.box.campaigns.priority"
                :state="isValid.fields.campaigns[bce.item.bce_index].priority"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-priority`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].priority"
                  v-model="boxForm.campaigns[bce.item.bce_index].priority"
                  @input="backdoor += 1"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Weight"
                :label-for="`box-campaign${bce.item.bce_index}-weight`"
                :invalid-feedback="invalidFeedback.box.campaigns.weight"
                :state="isValid.fields.campaigns[bce.item.bce_index].weight"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-weight`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].weight"
                  v-model="boxForm.campaigns[bce.item.bce_index].weight"
                  @input="backdoor += 1"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Daily Cap"
                :label-for="`box-campaign${bce.item.bce_index}-dailycap-type`"
                :invalid-feedback="invalidFeedback.dailyCap.type"
                :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.type"
              >
                <b-form-select
                  :id="`box-campaign${bce.item.bce_index}-dailycap-type`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.type"
                  v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.type"
                  :options="actionTypeOptions"
                  @input="backdoor += 1"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Total Cap"
                label-for="`box-campaign${bce.item.bce_index}-totalcap-type`"
                :invalid-feedback="invalidFeedback.totalCap.type"
                :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.type"
              >
                <b-form-select
                  :id="`box-campaign${bce.item.bce_index}-totalcap-type`"
                  v-model="boxForm.campaigns[bce.item.bce_index].totalCap.type"
                  :options="actionTypeOptions"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.type"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4" lg="2" md="4">
              <b-form-group
                class="my-2"
                label="Amount"
                :label-for="`box-campaign${bce.item.bce_index}-dailycap-amount`"
                :invalid-feedback="invalidFeedback.dailyCap.amount"
                :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-dailycap-amount`"
                  :state="isValid.fields.campaigns[bce.item.bce_index].dailyCap.amount"
                  v-model="boxForm.campaigns[bce.item.bce_index].dailyCap.amount"
                  @input="backdoor += 1"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="Amount"
                :label-for="`box-campaign${bce.item.bce_index}-totalcap-amount`"
                :invalid-feedback="invalidFeedback.totalCap.amount"
                :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.amount"
              >
                <b-form-input
                  :id="`box-campaign${bce.item.bce_index}-totalcap-amount`"
                  v-model="boxForm.campaigns[bce.item.bce_index].totalCap.amount"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].totalCap.amount"
                ></b-form-input>
              </b-form-group>
              <b-form-checkbox
                v-if="!$rent"
                class="mt-3 my-1"
                v-model="boxForm.campaigns[bce.item.bce_index].useLimitMPerBCE"
              >
                Use Limit M per BCE
              </b-form-checkbox>
              <b-form-group
                v-if="!$rent"
                class="mt-1 mb-3"
                label="Amount"
                :label-for="`box-campaign${bce.item.bce_index}-limitmperbce`"
                :invalid-feedback="invalidFeedback.box.campaigns.limitMPerBCE"
                :state="isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE"
              >
                <!--                :invalid-feedback="invalidFeedback.totalCap.amount"-->
                <b-form-input
                  v-if="!$rent"
                  :id="`box-campaign${bce.item.bce_index}-limitmperbce`"
                  v-model="boxForm.campaigns[bce.item.bce_index].limitMPerBCE"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].limitMPerBCE"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="!$rent"
                class="mt-1 mb-3"
                label="M-Ftds Daily Cap"
                :label-for="`box-campaign${bce.item.bce_index}-mFtdsDailyCap`"
                :invalid-feedback="invalidFeedback.box.campaigns.mFtdsDailyCap"
                :state="isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap"
              >
                <!--                :invalid-feedback="invalidFeedback.totalCap.amount"-->
                <b-form-input
                  v-if="!$rent"
                  :id="`box-campaign${bce.item.bce_index}-mFtdsDailyCap`"
                  v-model="boxForm.campaigns[bce.item.bce_index].mFtdsDailyCap"
                  @input="backdoor += 1"
                  :state="isValid.fields.campaigns[bce.item.bce_index].mFtdsDailyCap"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-row>
                <b-col cols="10" lg="5" md="10">
                  <b-form-group
                    label="Default Client's CPA"
                    class="my-2"
                    label-class="mr-2"
                    label-for="default-payment"
                    :invalid-feedback="invalidFeedback.box.campaigns.defaultPayment"
                    :state="isValid.fields.campaigns[bce.item.bce_index].defaultPayment"
                  >
                    <!--        :valid-feedback="validFeedback"-->
                    <!--            <h6 class="my-2">Amount</h6>-->
                    <b-form-input
                      id="default-payment"
                      v-model="boxForm.campaigns[bce.item.bce_index].defaultPayment"
                      :state="isValid.fields.campaigns[bce.item.bce_index].defaultPayment"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <CBFinanceSettings
                :countries="boxForm.campaigns[bce.item.bce_index].countries"
                v-model="boxForm.campaigns[bce.item.bce_index].countryPayments"
              ></CBFinanceSettings>
            </b-col>
            <b-col cols="12" lg="6">
              <b-row>
                <b-col cols="10" lg="5" md="10">
                  <b-form-group
                    label="Affiliate Default CPA"
                    class="my-2"
                    label-class="mr-2"
                    label-for="aff-default-payment"
                    :invalid-feedback="invalidFeedback.box.campaigns.aff_defaultPayment"
                    :state="isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment"
                  >
                    <!--        :valid-feedback="validFeedback"-->
                    <!--            <h6 class="my-2">Amount</h6>-->
                    <b-form-input
                      id="aff-default-payment"
                      v-model="boxForm.campaigns[bce.item.bce_index].aff_defaultPayment"
                      :state="isValid.fields.campaigns[bce.item.bce_index].aff_defaultPayment"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <CBFinanceSettings
                :countries="boxForm.campaigns[bce.item.bce_index].countries"
                v-model="boxForm.campaigns[bce.item.bce_index].aff_countryPayments"
                cid="aff"
              ></CBFinanceSettings>
            </b-col>
          </b-row>
          <h6 class="invisible mb-2" aria-hidden>.</h6>
          <b-dropdown variant="outline-secondary" right no-caret>
            <template #button-content>
              <!--              <b-icon icon="three-dots-vertical"></b-icon>-->
              Delete Campaign From Box
            </template>
            <b-dropdown-item-button
              variant="danger"
              v-on:click="deleteRow(bce.item.bce_index)"
              :disabled="requesting"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Delete Campaign
            </b-dropdown-item-button>
          </b-dropdown>
          <p>index {{ bce.item.bce_index }}</p>
        </b-list-group-item>
      </template>
    </b-table>
    <!--    </div>-->
    <div class="d-flex justify-content-between mb-3">
      <b-button variant="outline-primary" @click="addRow" :disabled="requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        <b-icon v-else icon="plus-circle"></b-icon>
        Add Campaign
      </b-button>
      <b-button
        @click="saveDaughterBox()"
        variant="primary"
        :disabled="!isValid.form || requesting"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        Save Box
      </b-button>
    </div>
    <!--    </b-form>-->
  </b-container>
</template>

<script>
/* eslint-disable camelcase */
import '@/assets/form.scss';
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import { getCampaign, operateDaughterAPI } from '@/utils/api';
import {
  actionTypeOptions,
  campaignInitialValue,
  countryOptions,
  daysOfWeek,
  initialBoxForm,
  invalidFeedback,
  OTHER_LEVEL_TRUSTED_USERS,
  validFeedback,
} from '@/consts';
import _ from 'lodash';
import {
  bceFilterFunction,
  castCountriesFromServer,
  countryMultiSelectSearch,
  deleteUnchangedFields,
  handleRequestError,
  showCard,
  tableFormatterArrayValues,
  tableFormatterCapOneCell,
  tableFormatterDays,
  tableFormatterHourLimit,
} from '@/utils';
import { formValid, isValid } from '@/validators';
import { noCap } from '@/validators/_utils';
import { defaultBCESettings } from '@/consts/defaultBCESettings';
import CBFinanceSettings from '@/components/shared/CBFinanceSettings.vue';

const itemInitialValues = initialBoxForm;

export default {
  name: 'DaughterBoxEdit',
  props: {
    daughter_id: { type: String, default: '' },
  },
  components: { CBFinanceSettings },
  data() {
    return {
      daughter: {},
      requesting: false,
      totalRows: 1,
      validFeedback,
      invalidFeedback,
      backdoor: 0,
      actionTypeOptions,
      boxForm: _.cloneDeep(itemInitialValues),
      initialForm: {},
      filter: {
        campaigns: [],
        countries: [],
      },
      editingField: '',
      editingItem: -1,
      countryOptions,
      daysOfWeek,
      fields: [
        {
          key: 'campaign',
          sortable: true,
          formatter: (value) => {
            const cof = this.campaignOptions.find((co) => co.value === value);
            return cof ? cof.text : 'not selected';
          },
        },
        'controls',
        { key: 'active', sortable: true },
        this.$rent ? undefined : { key: 'useLinkCheckService', label: 'LinkCheck', sortable: true },
        {
          key: 'countries',
          label: 'Geos',
          sortable: true,
          formatter: (value) => tableFormatterArrayValues(value),
        },
        {
          key: 'dailyCap',
          sortable: true,
          formatter: (value) => tableFormatterCapOneCell(value),
        },
        { key: 'mFtdsDailyCap', label: 'mFtdsDailyCap', sortable: true },
        {
          key: 'hourLimit',
          sortable: true,
          formatter: (value) => tableFormatterHourLimit(value),
        },
        {
          key: 'days',
          sortable: true,
          formatter: (value) => tableFormatterDays(value),
        },
        { key: 'priority', sortable: true },
        { key: 'weight', sortable: true },
        { key: 'useLimitMPerBCE', label: 'UseLimitM', sortable: true },
        { key: 'limitMPerBCE', label: 'LimitM', sortable: true },
        {
          key: 'totalCap',
          sortable: true,
          formatter: (value) => tableFormatterCapOneCell(value),
        },
        { key: 'defaultPayment', sortable: true },
        { key: 'aff_defaultPayment', sortable: true },
        // { key: 'currency', sortable: true },
      ].filter((f) => !!f),
    };
  },
  computed: {
    ...mapState(['user', 'token', 'isAdmin', 'db_defaultBCESettings']),
    campaigns() {
      this.backdoor;
      if (!this.daughter || !this.daughter.metaCampaigns) {
        console.log(
          `filterCampaignOptions params not found; this.daughter: ${!!this
            .daughter}; this.daughter.metaCampaigns: ${!!this.daughter.metaCampaigns}`
        );
        return [];
      }
      return this.daughter.metaCampaigns;
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    filterCampaignOptions() {
      if (!this.daughter || !this.daughter.metaCampaigns) {
        console.log(
          `filterCampaignOptions params not found; this.daughter: ${!!this
            .daughter}; this.daughter.metaCampaigns: ${!!this.daughter.metaCampaigns}`
        );
        return [];
      }
      const campaigns = this.daughter.metaCampaigns;
      return _.uniqBy(
        this.boxForm.campaigns
          .map((bc) => {
            const c = campaigns.find((cc) => cc._id === bc.campaign);
            if (!c) return false;
            return { name: c.name, _id: c._id };
          })
          .filter((c) => !!c),
        '_id'
      );
    },
    filterCountryOptions() {
      let boxCountries = [];
      for (let i = 0; i < this.boxForm.campaigns.length; i += 1) {
        boxCountries = [
          ...boxCountries,
          ...this.boxForm.campaigns[i].countries.map((c) => (c.value ? c.value : c)),
        ];
      }
      return this.countryOptions.filter((c) => {
        const cc = c.value ? c.value : c;
        return boxCountries.includes(cc);
      });
    },
    bceTableItems() {
      if (!this.daughter || !this.daughter.metaCampaigns) {
        console.log(
          `bceTableItems params not found; this.daughter: ${!!this
            .daughter}; this.daughter.metaCampaigns: ${!!this.daughter.metaCampaigns}`
        );
        return [];
      }
      const campaigns = this.daughter.metaCampaigns;
      // console.log('Recalculating table items');
      const bces = this.boxForm.campaigns; // without _.cloneDeep() implicitly changing this.boxForm.campaigns, но так, что компилятор не замечает. надо иметь ввиду
      for (let i = 0; i < bces.length; i += 1) {
        // console.log(bces[i]);
        const campaignMatch = campaigns.find(
          (c) =>
            bces[i].campaign &&
            c._id === (bces[i].campaign._id ? bces[i].campaign._id : bces[i].campaign)
        );
        bces[i].campaignActive = campaignMatch && campaignMatch.active;
        bces[i]._rowVariant = bces[i].active
          ? this.isValid.campaigns[i] && bces[i].campaignActive
            ? this.selectedBCECountries.dontMatch[i][1].length === 0
              ? 'success'
              : 'warning'
            : 'danger'
          : '';
        // bces[i]._showDetails = i <= showDetailsArray.length ? showDetailsArray[i] : false;
        bces[i].bce_index = i;

        const cof = this.campaignOptions.find((co) => co.value === bces[i].campaign);
        bces[i].campaignNameForSearch = cof ? cof.text : 'not selected';
      }
      return bces;
    },
    isValid() {
      this.backdoor;
      // console.log('Campaigns length,', this.boxForm.campaigns.length);
      const fields = isValid('box', this.boxForm, this.boxForm);
      return {
        fields,
        form: formValid(fields),
        campaigns: fields.campaigns ? fields.campaigns.map((c) => formValid(c)) : [],
      }; // isValid('box', this.boxForm);
    },
    campaignOptions() {
      if (!this.daughter || !this.daughter.metaCampaigns) {
        console.log(
          `campaignOptions params not found; this.daughter: ${!!this
            .daughter}; this.daughter.metaCampaigns: ${!!this.daughter.metaCampaigns}`
        );
        return [];
      }
      const campaigns = this.filter.campaigns.map((c) => (c && c._id ? c._id : c));
      return this.daughter.metaCampaigns
        .filter(
          (c) =>
            campaigns.length === 0 || campaigns.includes(c) || (c._id && campaigns.includes(c._id))
        )
        .map((c) => {
          return { text: c.name, value: c._id };
        });
    },
    campaignsMultiselectOptions() {
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    selectedBCECountries() {
      this.backdoor;
      if (!this.daughter || !this.daughter.metaCampaigns) {
        console.log(
          `filterCampaignOptions params not found; this.daughter: ${!!this
            .daughter}; this.daughter.metaCampaigns: ${!!this.daughter.metaCampaigns}`
        );
        return [];
      }
      const campaigns = this.daughter.metaCampaigns;
      const result = { selected: [], boxAccept: [], campaignsAccept: [], dontMatch: [] };
      const b = _.cloneDeep(this.boxForm);
      if (!b) return result;
      const selected = (b.campaigns ? b.campaigns : []).map((c) => !!c.campaign);
      const bcc = (b.campaigns ? b.campaigns : []).map((c) => {
        return [c.campaign, c.countries];
      });
      const cc = (b.campaigns ? b.campaigns : []).map((c) => {
        const cf = c.campaign ? campaigns.find((ci) => ci._id === c.campaign) : undefined;
        return cf ? [cf._id, cf.countries] : [c.campaign, []];
      });
      const countriesDontMatch = bcc.map((bcce, i) => {
        return [bcce[0], bcce[1].filter((c) => !cc[i] || !cc[i][1] || !cc[i][1].includes(c.value))];
      });
      result.selected = selected;
      result.boxAccept = bcc;
      result.campaignsAccept = cc;
      result.dontMatch = countriesDontMatch;
      return result;
    },
  },
  methods: {
    makeUrl(url, endpoint) {
      return `${url[url.length - 1] === '/' ? url.slice(1) : url}:2053${
        endpoint[0] === '/' ? endpoint : `/${endpoint}`
      }`;
    },
    getDaughterBox() {
      if (!this.daughter.brokerPullURL || !this.daughter.brokerAuthBody) {
        console.log(
          `getDaughterBox params not found; brokerPullURL: ${this.daughter.brokerPullURL}; brokerAuthBody: ${this.daughter.brokerAuthBody}`
        );
        return;
      }
      this.requesting = true;
      const url = this.makeUrl(this.daughter.brokerPullURL, `/box/`);
      operateDaughterAPI(url, this.daughter.brokerAuthBody)
        .then((r) => {
          if (r.status === 200) {
            this.castBoxToForm(r.data);
            this.backdoor += 1;
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting box`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning', 'Error getting box');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error on getting box', 'Error getting box');
          this.requesting = false;
        });
    },
    prepareBoxToSave() {
      this.requesting = true;
      let boxToSave = _.cloneDeep(this.boxForm);
      boxToSave.campaigns = boxToSave.campaigns.map((bceform) => {
        const bce = bceform; // _.cloneDeep(bceform);
        bce.countries = bceform.countries.map((c) => c.value);
        const days = [true, true, true, true, true, true, true];
        bce.days = days.map((d, j) => bceform.days.includes(j));
        if (bce.dailyCap && noCap(bce.dailyCap)) bce.dailyCap = false;
        if (bce.totalCap && noCap(bce.totalCap)) bce.totalCap = false;
        return bce;
      });
      if (!boxToSave.name || boxToSave.name === '') {
        boxToSave.name = this.affiliates.find((a) => a._id === boxToSave.affiliate);
      }
      if (boxToSave.dailyCap && noCap(boxToSave.dailyCap)) boxToSave.dailyCap = false;
      if (boxToSave.totalCap && noCap(boxToSave.totalCap)) boxToSave.totalCap = false;
      boxToSave = deleteUnchangedFields(this.initialForm, this.boxForm, boxToSave);
      return boxToSave;
    },
    saveDaughterBox() {
      const url = this.makeUrl(this.daughter.brokerPullURL, `/box/save-daughter`);
      operateDaughterAPI(url, {
        ...this.daughter.brokerAuthBody,
        ...this.prepareBoxToSave(),
      })
        .then((r) => {
          if (r.status === 200) {
            this.castBoxToForm(r.data);
            this.backdoor += 1;
            showCard(this, `${this.daughter.name} box saved`, 'success');
          } else {
            const cardError =
              r.data.error || `${r.status}: Unknown error on saving ${this.daughter.name} box`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on saving ${this.daughter.name} box`);
          this.requesting = false;
        });
    },

    editFieldOn(e, field, i, elementIdToSelect) {
      e.stopPropagation();
      if (this.editingField === field && this.editingItem === i) return;
      this.editingField = field;
      this.editingItem = i;
      if (field === 'dailyCap' || field === 'totalCap') {
        if (noCap(this.boxForm.campaigns[i][field]))
          this.boxForm.campaigns[i][field] = { type: 'lead', amount: undefined };
      }
      this.$nextTick(() => {
        const el = document.getElementById(elementIdToSelect);
        if (el) el.select();
      });
    },
    editFieldOff() {
      this.editingField = '';
      this.editingItem = -1;
    },
    canApplyDefaultSettings(index) {
      return (
        this.boxForm.campaigns[index].countries.length === 1 // &&
        // Object.keys(defaultBCESettings).includes(this.boxForm.campaigns[index].countries[0])
      );
    },
    applyDefaultBceSettings(index) {
      console.log('applyDefaultBceSettings');
      if (!this.canApplyDefaultSettings(index)) return;
      const country = this.boxForm.campaigns[index].countries[0];
      const { db_defaultBCESettings } = this;
      const c = country.value ? country.value : country;
      const db_defaultSettings =
        db_defaultBCESettings && Array.isArray(db_defaultBCESettings)
          ? db_defaultBCESettings.find((p) => p.geo === c)
          : undefined;
      if (db_defaultSettings && db_defaultSettings.days)
        db_defaultSettings.days = db_defaultSettings.days
          .map((d, i) => (d ? i : false))
          .filter((d) => d !== false);
      const defaultSettings =
        db_defaultSettings ||
        (defaultBCESettings[c] ? defaultBCESettings[c] : defaultBCESettings.default); // Object.keys(defaultBCESettings).includes(
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(defaultSettings)) {
        this.boxForm.campaigns[index][key] = _.cloneDeep(defaultSettings[key]);
      }
    },
    toggleBCEActive(item) {
      this.boxForm.campaigns[item.bce_index].active = !this.boxForm.campaigns[item.bce_index]
        .active;
    },
    toggleBCEUseLimitMPerBCE(item) {
      this.boxForm.campaigns[item.bce_index].useLimitMPerBCE = !this.boxForm.campaigns[
        item.bce_index
      ].useLimitMPerBCE;
    },
    toggleBCEUseLinkCheckService(item) {
      if (!this.iAmOtherLevelTrustedUser) return;
      this.boxForm.campaigns[item.bce_index].useLinkCheckService = !this.boxForm.campaigns[
        item.bce_index
      ].useLinkCheckService;
    },
    // selectHourNow() {
    //   this.demandForm.hour = DateTime.utc().hour;
    // },
    selectAllFilterCampaigns() {
      this.filter.campaigns = _.cloneDeep(this.filterCampaignOptions);
    },
    clearFilterCampaigns() {
      this.filter.campaigns = [];
    },
    selectAllFilterCountries() {
      this.filter.countries = _.cloneDeep(this.filterCountryOptions);
    },
    clearFilterCountries() {
      this.filter.countries = [];
    },
    bceFilterFunction(item, filter) {
      return bceFilterFunction(item, filter);
    },
    getItemsOnScreen() {
      return this.bceTableItems.filter((item) => bceFilterFunction(item, this.filter));
    },
    applyFieldToAllVisibleCampaigns(field, idToTakeFrom) {
      const visibleItems = this.getItemsOnScreen();
      const ids = visibleItems.map((i) => i.bce_index);
      for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i];
        if (id !== idToTakeFrom)
          this.boxForm.campaigns[id][field] = _.cloneDeep(
            this.boxForm.campaigns[idToTakeFrom][field]
          );
      }
    },
    countryMultiSelectSearch,
    selectAllCountries(index) {
      if (!this.daughter || !this.daughter.metaCampaigns) {
        console.log(
          `filterCampaignOptions params not found; this.daughter: ${!!this
            .daughter}; this.daughter.metaCampaigns: ${!!this.daughter.metaCampaigns}`
        );
        return;
      }
      const campaigns = this.daughter.metaCampaigns;
      // console.log('selecting all countries for campaign', this.boxForm.campaigns[index].campaign);
      if (
        index >= 0 &&
        index < this.boxForm.campaigns.length &&
        this.boxForm.campaigns[index].campaign
      ) {
        // console.log('searching in campaigns', campaigns);
        const campaign = campaigns.find((c) => c._id === this.boxForm.campaigns[index].campaign);
        // if (campaign) console.log('campaign available countries', campaign.countries);
        // else console.log('campaign', campaign);
        this.boxForm.campaigns[index].countries = campaign
          ? campaign.countries
              .map((c) => countryOptions.find((co) => co.value === c))
              .filter((c) => !!c)
          : [];
      }
    },
    clearCountries(index) {
      if (index >= 0 && index < this.boxForm.campaigns.length) {
        this.boxForm.campaigns[index].countries = [];
      }
    },
    handleCampaignsMultiselect(e, bceId) {
      this.boxForm.campaigns[bceId].campaign_Multiselect = _.cloneDeep(e);
      if (Array.isArray(e)) {
        this.boxForm.campaigns[bceId].campaign = _.cloneDeep(e).map((ee) => ee._id);
      } else {
        this.boxForm.campaigns[bceId].campaign = _.cloneDeep(e)._id;
      }
      // console.log('handleCampaignsMultiselect campaign', this.boxForm.campaigns[bceId].campaign);
      // console.log(
      //   'handleCampaignsMultiselect campaign_Multiselect',
      //   this.boxForm.campaigns[bceId].campaign_Multiselect
      // );
      // console.log('handleCampaignsMultiselect isValid', this.isValid.fields.campaigns[bceId]);
      // console.log('handleCampaignsMultiselect isValid', this.isValid.campaigns[bceId]);
      this.backdoor += 1;
    },
    goToEditCampaign() {
      // this.$store.commit('save_box_form', this.boxForm);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    addRow() {
      if (Array.isArray(this.boxForm.campaigns)) {
        this.boxForm.campaigns.push(_.cloneDeep(campaignInitialValue));
      } else {
        this.boxForm.campaigns = [_.cloneDeep(campaignInitialValue)];
      }
      this.backdoor += 1;
    },
    deleteRow(index) {
      this.boxForm.campaigns.splice(index, 1);
      if (!(Array.isArray(this.boxForm.campaigns) && this.boxForm.campaigns.length > 0)) {
        this.boxForm.campaigns = [_.cloneDeep(campaignInitialValue)];
      }
      this.backdoor += 1;
    },

    castBoxToForm(box) {
      const initial = _.cloneDeep(itemInitialValues);
      const initialCampaign = _.cloneDeep(campaignInitialValue);
      // cast box to edit form
      const campaigns = box.campaigns
        ? box.campaigns.map((c) => {
            const nc = c;
            nc.countries = castCountriesFromServer(c.countries);
            nc.campaign = c.campaign._id ? c.campaign._id : c.campaign ? c.campaign : ''; // { text: c.campaign.name, value: c.campaign._id };
            nc.campaign_Multiselect = _.cloneDeep(c.campaign); // { text: c.campaign.name, value: c.campaign._id };
            nc.days = c.days.map((d, i) => (d ? i : false)).filter((d) => d !== false); // daysOfWeek.filter((d, i) => nc.days[i]);
            nc.hourLimit = c.hourLimit ? c.hourLimit : initialCampaign.hourLimit;
            nc.dailyCap = c.dailyCap ? c.dailyCap : initialCampaign.dailyCap;
            nc.totalCap = c.totalCap ? c.totalCap : initialCampaign.totalCap;
            nc.defaultPayment = c.defaultPayment;
            nc.aff_defaultPayment = c.aff_defaultPayment;
            nc.useLimitMPerBCE = c.useLimitMPerBCE
              ? c.useLimitMPerBCE
              : initialCampaign.useLimitMPerBCE;
            nc.limitMPerBCE = c.limitMPerBCE ? c.limitMPerBCE : initialCampaign.limitMPerBCE;
            nc.mFtdsDailyCap = c.mFtdsDailyCap ? c.mFtdsDailyCap : initialCampaign.mFtdsDailyCap;
            nc.useLinkCheckService = c.useLinkCheckService
              ? c.useLinkCheckService
              : initialCampaign.useLinkCheckService;
            return nc;
          })
        : initial.campaigns;
      // prettier-ignore
      this.boxForm = {
        _id: box._id ? box._id : initial._id,
        name: box.name ? box.name : initial.name,
        active: box.active,
        campaigns,
        affiliate: box.affiliate ? box.affiliate._id ? box.affiliate._id :  box.affiliate : initial.affiliate,
        manager:  box.manager ? box.manager._id ? box.manager._id : box.manager : initial.manager,
        dailyCap: box.dailyCap ? box.dailyCap : initial.dailyCap,
        totalCap: box.totalCap ? box.totalCap : initial.totalCap,
        loadBalancer: box.loadBalancer ? box.loadBalancer : initial.loadBalancer,
        loadBalancerCoefficient: box.loadBalancerCoefficient ? box.loadBalancerCoefficient : initial.loadBalancerCoefficient,
        isMBox: box.isMBox ? box.isMBox : initial.isMBox,
        liveOmitTimeIntervalCheck: box.liveOmitTimeIntervalCheck ? box.liveOmitTimeIntervalCheck : initial.liveOmitTimeIntervalCheck,
      };
      if (this.isAdmin) {
        if (box.csi) this.boxForm.csi = box.csi;
        if (box.cso) this.boxForm.cso = box.cso;
        if (box.dso) this.boxForm.dso = box.dso;
        if (box.isTest) this.boxForm.isTest = box.isTest;
      }
      this.initialForm = _.cloneDeep(this.boxForm);
    },
    changeSorting() {
      const actualBceItems = this.bceTableItems.map((bcei) => bcei.campaign);
      console.log('Sorting changed');
      console.log(actualBceItems);
    },
    findBceIndex(bce) {
      return this.boxForm.campaigns.findIndex((c) => c.campaign === bce.item.campaign);
    },
    async getDaughterCampaign() {
      try {
        const r = await getCampaign(this.token, this.daughter_id);
        if (r.status === 200) {
          this.daughter = _.cloneDeep(r.data);
        } else {
          showCard(this, 'Error getting Daughter campaign', 'danger');
        }
      } catch (e) {
        handleRequestError(this, e, 'Error getting Daughter campaign');
      }
    },
    async updateData() {
      await this.getDaughterCampaign();
      this.getDaughterBox();
    },
  },
  mounted() {
    console.log('DaughterBoxEdit daughter', this.daughter);
    this.updateData();
  },
};
</script>

<style scoped></style>
