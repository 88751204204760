export default {
  use: (c) => typeof c === 'boolean',
  type1: (c, k) => !k.use || (k.use && !!c && ['page', 'offset'].includes(c)),
  pageNumberField: (c, k) =>
    !k.use || k.type1 === 'offset' || (k.type1 === 'page' && typeof c === 'string' && c.length > 0),
  itemsPerPageField: (c, k) =>
    !k.use || k.type1 === 'offset' || (k.type1 === 'page' && typeof c === 'string' && c.length > 0),
  itemsPerPage: (c, k) =>
    !k.use ||
    k.type1 === 'offset' ||
    (k.type1 === 'page' && !Number.isNaN(Number(c)) && Number(c) > 0),
  offsetField: (c, k) =>
    !k.use || k.type1 === 'page' || (k.type1 === 'offset' && typeof c === 'string' && c.length > 0),
  offsetStep: (c, k) =>
    !k.use ||
    k.type1 === 'page' ||
    (k.type1 === 'offset' && !Number.isNaN(Number(c)) && Number(c) > 0),
  offsetStepField: (c, k) =>
    !k.use || k.type1 === 'page' || (k.type1 === 'offset' && typeof c === 'string' && c.length > 0),
  totalItemsField: () => true, //! k.use || (typeof c === 'string' && c.length > 0),
  totalPagesField: () => true, //! k.use || (typeof c === 'string' && c.length > 0),
};
