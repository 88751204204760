<template>
  <div>
    <div v-if="user.email === 'blacklight@default.gg'" class="my-4">
      <span class="d-block h5 font-weight-normal">Box</span>
      <multiselect
        v-model="newInjAlgorithmTestForm.box"
        :options="boxesOptions"
        track-by="name"
        label="name"
        :closeOnSelect="true"
        :multiple="false"
      ></multiselect>
      <b-form-checkbox
        v-model="newInjAlgorithmTestForm.includeMeta"
        class="mb-2 d-block h5 font-weight-normal mt-2"
      >
        Include Meta
      </b-form-checkbox>
      <b-form-checkbox
        v-model="newInjAlgorithmTestForm.naive"
        class="mb-2 d-block h5 font-weight-normal mt-2"
      >
        Naive
      </b-form-checkbox>
      <b-form-checkbox
        v-model="newInjAlgorithmTestForm.useIds"
        class="mb-2 d-block h5 font-weight-normal mt-2"
      >
        Use Ids
      </b-form-checkbox>
      <span class="d-block h5 font-weight-normal">Limit</span>
      <b-input v-model="newInjAlgorithmTestForm.limit" type="number" class="my-2"></b-input>
      <span class="d-block h5 font-weight-normal">Pipeline stages</span>
      <b-input
        v-model="newInjAlgorithmTestForm.pipelineStages"
        type="number"
        class="my-2"
      ></b-input>
      <b-button @click="testNewInjAlgorithm()" class="my-2">Test</b-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { testNewInjAlgorithm } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';
import { mapState } from 'vuex';

export default {
  name: 'NewInjAlgorithmTest',
  props: { items: { type: Array, default: () => [] } },
  data() {
    return {
      newInjAlgorithmTestForm: {
        box: undefined,
        pipelineStages: 0,
        includeMeta: false,
        naive: false,
        limit: 2000,
        useIds: false,
      },
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'token', 'boxes', 'requesting']),
    boxesOptions() {
      return this.boxes.map((b) => {
        return { name: b.name, _id: b._id };
      });
    },
  },
  methods: {
    testNewInjAlgorithm() {
      const data = _.cloneDeep(this.newInjAlgorithmTestForm);
      data._ids = this.items.map((i) => i._id);
      data.boxId = data.box._id ? data.box._id : data.box;
      testNewInjAlgorithm(this.token, data)
        .then((r) => showCard(this, r.message, r.success ? 'success' : 'warning'))
        .catch((e) => handleRequestError(this, e, 'Unknown error testing new inj algorithm'));
    },
  },
};
</script>

<style scoped></style>
