<template>
  <div>
    <b-row class="align-items-center">
      <!-- Custom Content Column -->
      <b-col :md="colsMain">
        <slot>
          <div>Default custom content</div>
        </slot>
      </b-col>

      <!-- Helping Section Column -->
      <b-col
        :md="colsHelp"
        class="helping-section bg-lighter text-dark"
        :style="helpSectionStyles"
        v-if="sh"
      >
        <div class="d-flex align-items-center">
          <div class="icon-wrapper mr-3" :style="iconWrapperStyles" @click="sh = !sh">
            <b-icon-question font-scale="1.4"></b-icon-question>
          </div>
          <p class="mb-0" :style="helpTextStyles">{{ helpText }}</p>
        </div>
      </b-col>
      <b-col :cols="1" :style="helpSectionStyles" v-else>
        <div class="d-flex align-items-center" style="width: auto">
          <div class="icon-wrapper mr-3 bg-lighter" :style="iconWrapperStyles" @click="sh = !sh">
            <b-icon-question font-scale="1.4"></b-icon-question>
          </div>
          <!--          <p class="mb-0" :style="helpTextStyles">Help</p>-->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'InputWithHelp',

  props: {
    helpText: {
      type: String,
      default: 'Helping text goes here.',
    },
    size: {
      type: Number,
      default: 1,
    },
    colsMain: {
      type: Number,
      default: 6,
    },
    colsHelp: {
      type: Number,
      default: 6,
    },
    showHelp: { type: Boolean, default: true },
  },
  data() {
    return { sh: this.$props.showHelp };
  },
  computed: {
    helpTextStyles() {
      return {
        fontSize: `${this.size}rem !important`,
      };
    },
    helpSectionStyles() {
      return {
        ...this.helpTextStyles,
        padding: `${this.size}rem`,
        borderRadius: `${this.size}rem`,
      };
    },
    iconWrapperStyles() {
      const circleSize = this.size * 1.5;
      return {
        // fontSize: `${this.size}rem; !important`,
        width: `${circleSize}rem !important`,
        height: `${circleSize}rem !important`,
        lineHeight: `${circleSize}rem`,
        cursor: 'pointer',
      };
    },
  },
};
</script>

<style scoped>
.helping-section {
  background-color: #f8f9fa;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
}
.icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /*background-color: #f8f9fa;*/
  border: 2px solid currentColor;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
