<template>
  <div>
    <h3>
      PSP {{ psp.number }} Use History
      <b-button display="inline-block" class="mx-2" :disable="requesting" @click="updateData()">
        <b-spinner small v-if="requesting"></b-spinner>
        <span v-else>🔄</span>
      </b-button>
    </h3>
    <ul class="my-2">
      <li v-for="(u, i) in uselist" :key="i" class="my-1">
        <div class="dataspan mx-1">{{ u.finalReceiver }}</div>
        <div class="dataspan bluetext mx-1">{{ u.actionDate }}</div>
        <div class="datapiece mx-1">
          <b-badge class="databadge" :variant="u.ftd ? 'success' : 'danger'">
            {{ u.ftd ? 'FTD' : 'No ftd' }}
          </b-badge>
        </div>
        <div class="datapiece mx-1">
          <b-badge class="databadge" :variant="u.shaved ? 'danger' : 'success'">
            {{ u.shaved ? 'Shaved' : 'Ok' }}
          </b-badge>
        </div>
        <div class="datapiece mx-1">
          <b-badge class="databadge" :variant="u.brokerAccepted ? 'success' : 'danger'">
            {{ u.brokerAccepted ? 'Accepted' : 'Not Accepted' }}
          </b-badge>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getUseHistory } from '@/utils/api';
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import { handleRequestError, formatDatesInKey } from '@/utils';

export default {
  name: 'PSPUseHistory',
  props: {
    psp: { type: Object, default: () => {} },
  },

  data() {
    return {
      uselist: [],
      requesting: false,
    };
  },
  computed: { ...mapState(['token']) },
  methods: {
    updateData() {
      this.requesting = true;
      getUseHistory(this.token, { _id: this.psp._id })
        .then((r) => {
          this.uselist = formatDatesInKey(r.data, 'actionDate');
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on getUseHistory for PSP ${this.psp ? this.psp._id : undefined}`
          );
          this.requesting = false;
        });
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>

<style scoped></style>
