<template>
  <AffiliateApiDocs />
</template>

<script>
import AffiliateApiDocs from '@/components/shared/AffiliateApiDocs/AffiliateApiDocs.vue';

export default {
  name: 'AffiliateApiDocsView',
  components: {
    AffiliateApiDocs,
  },
};
</script>
