<template>
  <BalancesTable />
</template>

<script>
import BalancesTable from '@/components/pages/FinancialTransactions/BalancesTable.vue';

export default {
  name: 'BalancesTableView',
  components: {
    BalancesTable,
  },
};
</script>
