<template>
  <ActionsTable />
</template>

<script>
import ActionsTable from '@/components/pages/Actions/ActionsTable/ActionsTable.vue';

export default {
  name: 'ActionsView',
  components: {
    ActionsTable,
  },
};
</script>
