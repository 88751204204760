/* eslint-disable */
export const defaultBCESettings = {
  IT: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1000,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  ES: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 900,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  CA: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1100,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  UK: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1100,
    hourLimit: [10, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  GB: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1100,
    hourLimit: [10, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  SE: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1200,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  NO: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1200,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  FI: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1200,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  DK: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1200,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  IE: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1100,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  AE: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1000,
    hourLimit: [8, 15],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  NZ: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1150,
    hourLimit: [3, 10],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  AU: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1150,
    hourLimit: [3, 10],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  ZA: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 850,
    hourLimit: [10, 16],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  NG: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 750,
    hourLimit: [9, 15],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  DE: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1150,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  AT: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1150,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  CH: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1250,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  HK: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1150,
    hourLimit: [6, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  SG: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1150,
    hourLimit: [6, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  MY: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1000,
    hourLimit: [6, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  PH: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 850,
    hourLimit: [6, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  TW: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 850,
    hourLimit: [6, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  NL: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1250,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  TR: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 700,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  PL: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 900,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  PT: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 900,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  MX: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 650,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  PE: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 650,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  CO: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 650,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  PA: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 650,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  AR: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 650,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  CL: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 750,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  BR: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 750,
    hourLimit: [15, 20],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  IN: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 650,
    hourLimit: [8, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  PK: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 600,
    hourLimit: [8, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  FR: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1000,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  RU: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 700,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  BE: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1100,
    hourLimit: [9, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  JP: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 1000,
    hourLimit: [6, 12],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
  default: {
    dailyCap: { type: 'lead', amount: 12 },
    defaultPayment: 700,
    hourLimit: [10, 17],
    days: [1, 2, 3, 4, 5],
    priority: 1,
  },
};
