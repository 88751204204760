<template>
  <b-container fluid>
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon icon="search"></b-icon>
      </b-input-group-prepend>
      <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Type to Search"
      ></b-form-input>
      <b-input-group-append>
        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-card-group
      columns
      v-if="daughters.length > 0 && OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
    >
      <!--        :key="daughter.name"-->
      <a v-for="(daughter, i) in daughters" :key="i" target="_blank" :href="daughter.brokerApiURL">
        <b-card img-left :img-src="makeUrl(daughter.brokerApiURL, '/favicon.ico')">
          <!--                :title="daughter.name"
-->
          <b-card-title>
            <!--            <a :href="daughter.brokerApiURL" class="card-link" target="_blank">-->
            {{ daughter.brokerApiURL }}
            <!--            </a>-->
          </b-card-title>
        </b-card>
      </a>
    </b-card-group>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import { makeUrl } from '@/utils';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import { uniqBy } from 'lodash';

export default {
  name: 'Daughters',
  data() {
    return { filter: '', cardHovered: -1, OTHER_LEVEL_TRUSTED_USERS };
  },
  computed: {
    ...mapState([
      'user',
      'isAdmin',
      'isManager',
      'isAffiliate',
      'token',
      'campaigns',
      'boxes',
      'requesting',
    ]),
    daughters() {
      return uniqBy(
        this.campaigns.filter(
          (c) =>
            // c.active &&
            c.brokerApiType === 'Daughter' &&
            c.brokerApiURL &&
            (this.filter === '' || c.brokerApiURL.includes(this.filter))
        ),
        'brokerApiURL'
      );
    },
  },
  methods: {
    makeUrl(url, endpoint) {
      return makeUrl(String(url), endpoint);
    },
  },
  mounted() {
    this.$store.dispatch('get_all_resources_one_request', { vm: this });
  },
};
</script>

<style scoped></style>
