<template>
  <b-container fluid>
    <b-row class="my-3" no-gutters>
      <b-col cols="12" :xl="$rent ? 8 : 3" class="my-lg-0 my-1">
        <h2>{{ onDeposits ? 'Deposits' : $rent ? 'CRM' : 'Actions' }}</h2>
      </b-col>
      <b-col
        v-if="!$rent"
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="6"
        xl="3"
      >
        <b-form-radio-group
          v-if="!onDeposits"
          id="btn-radios-1"
          size="md"
          button-variant="outline-primary"
          v-model="showTypeMoreThan"
          :options="statsActionTypeOptions"
          name="radios-btn-default"
          buttons
          class="mx-2"
        ></b-form-radio-group>
      </b-col>
      <b-col
        v-if="!$rent"
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="6"
        xl="2"
      >
        <b-form-checkbox
          v-if="iAmOtherLevelTrustedUser"
          v-model="makeFtdsMode"
          name="make-ftds-mode-switch"
          switch
          class="mx-2"
        >
          MakeFtds Mode
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        xl="2"
        class="my-lg-0 my-1 d-flex align-items-center justify-content-center"
      >
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        xl="2"
        class="my-lg-0 my-1 d-flex align-items-center justify-content-center"
      >
        <JsonExcel
          class="m-2"
          :data="items"
          :fields="fieldsToExport"
          type="csv"
          :stringifyLongNum="true"
          v-if="
            !isAffiliate &&
            [
              'blacklight@default.gg',
              'thehighestadmin@defaulted.gg',
              'thehighestadmin@default.gg',
              'zerothreethree@default.gg',
            ].includes(user.email)
          "
        >
          <b-button variant="outline-primary">📥CSV</b-button>
        </JsonExcel>
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
        <b-button
          @click="restartPulling()"
          variant="warning"
          v-if="
            iAmTrustedUser &&
            [
              'blacklight@default.gg',
              'thehighestadmin@defaulted.gg',
              'thehighestadmin@default.gg',
              'zerothreethree@default.gg',
            ].includes(user.email)
          "
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>Restart</span>
        </b-button>
        <!--        </div>-->
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-12 col-md-6 col-lg-6">
        <b-button
          v-b-toggle.sidebar-right-table
          @click="closeSidebarDetails()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          :disabled="makeFtdsMode"
        >
          Edit Table
        </b-button>
        <b-button
          v-b-toggle.sidebar-right-details
          @click="closeSidebarColumns()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          Edit Details
        </b-button>

        <b-button
          v-b-modal:clear-all-renew-leads-modal
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          v-if="iAmOtherLevelTrustedUser && !$rent"
          variant="warning"
        >
          Clear All Renew Leads
        </b-button>
        <b-modal
          id="clear-all-renew-leads-modal"
          ref="clear-all-renew-leads-modal"
          title="Clear All Renew Leads Flags?"
          @ok="clearAllRenewLeads()"
          variant="warning"
        ></b-modal>

        <b-sidebar
          id="sidebar-right-table"
          v-model="sidebarColumnsOpen"
          title="Table Fields"
          right
          shadow
        >
          <div class="px-3 py-2">
            <b-form-checkbox-group
              v-model="preSelectedColumns"
              :options="tableFieldsOptions"
              class="mb-3"
              stacked
            ></b-form-checkbox-group>
            <b-button
              variant="primary"
              @click="applyFields()"
              :disabled="!canApplyFields"
              class="my-2 sidebar-button"
            >
              Apply
            </b-button>
            <b-button
              variant="success"
              @click="saveSelectedFields('actions_table')"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Save
            </b-button>
            <b-button
              variant="warning"
              @click="restoreTablePreference()"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Restore
            </b-button>
          </div>
        </b-sidebar>
        <b-sidebar
          id="sidebar-right-details"
          v-model="sidebarDetailsOpen"
          title="Show Details"
          right
          shadow
        >
          <div class="px-3 py-2">
            <b-form-checkbox-group
              v-model="preSelectedDetails"
              :options="detailsOptions"
              class="mb-3"
              stacked
            ></b-form-checkbox-group>
            <b-button
              variant="primary"
              @click="applyDetails()"
              :disabled="!canApplyDetails"
              class="my-2 sidebar-button"
            >
              Apply
            </b-button>
            <b-button
              variant="success"
              @click="saveSelectedFields('actions_details')"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Save
            </b-button>
            <b-button
              variant="warning"
              @click="restoreDetailsPreference()"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Restore
            </b-button>
          </div>
        </b-sidebar>
      </b-col>
    </b-row>

    <ActionsFilterForm
      class="datapiece"
      :checkingTimesCanSendInProgress="checkingTimesCanSendInProgress"
      :applied-filter-form="appliedFilterForm"
      :form="filterForm"
      :key="formKey"
      :userAvailableFields="userAvailableFields"
      @applyFilter="applyFilter($event)"
      @clearFilterForm="clearFilterForm($event)"
      @checkListedActionsCanSendAsM="checkListedActionsCanSendAsM($event)"
      @abortTimesCanSendCheck="abortTimesCanSendCheck($event)"
      @updateData="updateData($event)"
      @updateWorkersOptions="updateWorkersOptions($event)"
    ></ActionsFilterForm>

    <div class="my-2" v-if="!user.trader">
      <div class="mx-2 my-1 dataspan bluetext" v-if="!isAffiliate && !onDeposits">
        Accepted: {{ stats.leadsBrokerAcceptAmount }} leads
      </div>
      <div class="mx-2 my-1 dataspan bluetext">FTDs: {{ stats.ftdsAmount }}</div>
      <div class="mx-2 my-1 dataspan bluetext" v-if="!$rent">Shaved: {{ stats.shaved }}</div>
      <div class="mx-2 my-1 dataspan bluetext" v-if="!isAffiliate && !onDeposits">
        CR (accepted):
        {{
          stats.ftdsAmount && stats.leadsBrokerAcceptAmount
            ? Number((stats.ftdsAmount / stats.leadsBrokerAcceptAmount) * 100).toFixed(2)
            : 0
        }}%
      </div>
      <div class="mx-2 my-1 dataspan bluetext" v-if="!isAffiliate && !onDeposits">
        NotAccepted: {{ stats.leadsBrokerNoAcceptAmount }} leads
      </div>
      <div class="mx-2 my-1 dataspan bluetext" v-if="!isAffiliate && !onDeposits">
        Leads before 1st FTD: {{ stats.leadsBeforeFirstFTD }} leads
      </div>
      <div
        class="mx-2 my-1 dataspan bluetext"
        v-if="!isAffiliate && !onDeposits"
        @click="getSalesStatusClientsList('positive')"
      >
        Positive sales status: {{ stats.leadsPositiveStatus }} leads
      </div>
      <div
        class="mx-2 my-1 dataspan bluetext"
        v-if="!isAffiliate && !onDeposits"
        @click="getSalesStatusClientsList('positive-to-negative')"
      >
        Positive-to-negative status changers: {{ stats.leadsPositiveToNegativeChangers }} leads
      </div>
      <b-modal
        :id="`sales-status-clients-list-modal`"
        ref="sales-status-clients-list-modal"
        centered
        ok-only
        ok-title="Close"
        size="lg"
        scrollable
        :title="
          salesStatusClientsListType === 'positive'
            ? `Positive Sales Status Clients List`
            : `Positive-To-Negative Sales Status Clients List`
        "
      >
        <SalesStatusClientsList
          :appliedFilterForm="getFilterFormForRequest()"
          :type="salesStatusClientsListType"
        ></SalesStatusClientsList>
      </b-modal>
    </div>
    <div class="my-2" v-if="!isAffiliate && !onDeposits">
      <h3>Total CRG</h3>
      <div class="mx-2 my-1 dataspan orangetext">
        CRG Guaranteed:
        {{
          Array.isArray(stats.crg) && stats.crg[0] && stats.crg[0].guaranteed
            ? stats.crg[0].guaranteed
            : 0
        }}
      </div>
      <div class="mx-2 my-1 dataspan orangetext">
        CRG FTDs Received:
        {{
          Array.isArray(stats.crg) && stats.crg[0] && stats.crg[0].crgReceived
            ? stats.crg[0].crgReceived
            : 0
        }}
      </div>
      <div class="mx-2 my-1 dataspan orangetext">
        CRG FTDs Left:
        {{ Array.isArray(stats.crg) && stats.crg[0] && stats.crg[0].left ? stats.crg[0].left : 0 }}
      </div>
      <b-button
        style="display: inline-block"
        variant="primary"
        v-if="Array.isArray(stats.crg) && stats.crg.length > 1"
        v-b-toggle="'crg-by-campaigns'"
      >
        Show by Campaigns
      </b-button>
      <b-collapse
        id="crg-by-campaigns"
        class="mt-2"
        v-if="Array.isArray(stats.crg) && stats.crg.length > 1"
      >
        <h3>CRGs by campaign</h3>
        <b-card-group columns>
          <b-card
            v-for="(item, index) in stats.crg.filter((c) => c._id !== 'total')"
            :key="index"
            class="card-limited"
          >
            <b-card-title>
              <div v-if="item">
                <h4>{{ item._id ? (item._id.name ? item._id.name : item._id) : item }}</h4>
                <div class="mx-2 my-1 dataspan orangetext">
                  Guaranteed: {{ item.guaranteed ? item.guaranteed : 0 }}
                </div>
                <div class="mx-2 my-1 dataspan orangetext">
                  Received: {{ item.crgReceived ? item.crgReceived : 0 }}
                </div>
                <div class="mx-2 my-1 dataspan orangetext">
                  Left: {{ item.left ? item.left : 0 }}
                </div>
              </div>
              <div v-else class="mx-2 my-1 dataspan orangetext">unknown</div>
            </b-card-title>
          </b-card>
        </b-card-group>
      </b-collapse>
    </div>
    <div class="my-2" v-if="!user.trader">
      <h3>Payouts</h3>
      <div class="mx-2 my-1 dataspan greentext" v-if="!isAffiliate">
        Payout: ${{ stats.payout }}
      </div>
      <div class="mx-2 my-1 dataspan greentext">Aff payout: ${{ stats.aff_payout }}</div>
      <div class="mx-2 my-1 dataspan greentext" v-if="!isAffiliate">
        Profit: ${{ stats.profit }}
      </div>
    </div>
    <h3 class="mt-2">Stats Breakdown by</h3>
    <div class="mb-2" v-if="!isAffiliate">
      <StatsBreakdownByItems
        class="mx-2 datapiece"
        :items="breakdownByAffiliates"
        group-name="Affiliates"
        :min-btn-width="260"
      ></StatsBreakdownByItems>
      <StatsBreakdownByItems
        class="my-2 mx-2 datapiece"
        :items="breakdownByCampaigns"
        group-name="Campaigns"
        :min-btn-width="260"
      ></StatsBreakdownByItems>
      <StatsBreakdownByItems
        class="my-2 mx-2 datapiece"
        :items="breakdownByBrokers"
        group-name="Brokers"
        :min-btn-width="260"
      ></StatsBreakdownByItems>
      <StatsBreakdownByItems
        class="mx-2 datapiece"
        :items="breakdownByBoxes"
        group-name="Boxes"
        :min-btn-width="260"
      ></StatsBreakdownByItems>
    </div>
    <b-table
      class="table mb-3"
      :fields="sortedSelectedFields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
      v-on:refreshed="tableRefreshedHandler()"
      v-on:context-changed="tableContextChangeHandler()"
      ref="itemsTable"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button v-b-modal="`action-details-modal-${data.item._id}`" variant="outline-warning">
            <b-icon icon="eye" v-if="!data.detailsShowing"></b-icon>
            <b-icon icon="eye-slash" v-else></b-icon>
          </b-button>
          <b-modal
            :id="`action-details-modal-${data.item._id}`"
            centered
            ok-only
            ok-title="Close"
            size="giant"
            scrollable
            :title="`${data.item.email} lead details`"
          >
            <ActionDetails
              :_id="data.item._id"
              :sorted-selected-details="sortedSelectedDetails"
              @updateAction="handle_updateAction"
              @updateActionKey="handle_updateActionKey"
            />
          </b-modal>
          <!--          <b-button @click="data.toggleDetails">-->
          <!--            <b-icon icon="eye" v-if="!data.detailsShowing"></b-icon>-->
          <!--            <b-icon icon="eye-slash" v-else></b-icon>-->
          <!--          </b-button>-->
          <b-button
            v-b-modal="`leadstatus-modal-${data.item._id}`"
            variant="primary"
            v-if="
              !$rent &&
              (!isAffiliate ||
                (user.canSendMAutomatically &&
                  (user.rtcMandatoryFlow || user.rtcMandatoryFlowPSP)) ||
                user.canSendCAutomatically) &&
              !(user && user.seeLeadsBasedOnCampaigns)
            "
          >
            🤖
          </b-button>
          <b-modal
            :id="`leadstatus-modal-${data.item._id}`"
            centered
            no-close-on-backdrop
            no-close-on-esc
            ok-only
            ok-title="Close"
            v-if="!$rent && !(user && user.seeLeadsBasedOnCampaigns)"
          >
            <SendMLeadMenu
              :_id="data.item._id"
              :email="data.item.email"
              :geo="
                data.item.leadContactInfo ? data.item.leadContactInfo.country : data.item.location
              "
            ></SendMLeadMenu>
          </b-modal>

          <b-button
            v-b-modal="`sendmanual-modal-${data.item._id}`"
            :variant="
              (data.item.worker && data.item.worker._id && !data.item.worker._id.available) ||
              (user.hasWorkers && !(data.item.worker && data.item.worker._id))
                ? 'warning'
                : 'success'
            "
            v-if="
              !$rent &&
              (isAdmin || (user && user.campaignsAccess)) &&
              !(user && user.seeLeadsBasedOnCampaigns) &&
              // || (user && user.seeLeadsBasedOnCampaigns)) &&
              (servicename === 'grandmaster' ||
                servicename === 'localgm' ||
                servicename === 'leaddim')
            "
          >
            💪
          </b-button>
          <b-modal
            :id="`sendmanual-modal-${data.item._id}`"
            centered
            ok-only
            ok-title="Close"
            v-if="
              !$rent &&
              (isAdmin || (user && user.campaignsAccess)) &&
              !(user && user.seeLeadsBasedOnCampaigns) &&
              // || (user && user.seeLeadsBasedOnCampaigns)) &&
              (servicename === 'grandmaster' ||
                servicename === 'localgm' ||
                servicename === 'leaddim')
            "
            size="giant"
          >
            <SendLeadManualMenu
              :_id="data.item._id"
              :lead="data.item.leadContactInfo"
              :box="data.item.box._id"
            ></SendLeadManualMenu>
          </b-modal>
        </b-button-group>
      </template>

      <template #cell(affiliate)="data">
        <b-button
          v-if="iAmOtherLevelTrustedUser || affiliates.length > 0"
          v-b-modal="`modal-assign-to-affiliate-${data.item._id}`"
          variant="info"
          class="mx-1"
          size="sm"
        >
          ✍️
        </b-button>
        <b-modal
          v-if="iAmOtherLevelTrustedUser || affiliates.length > 0"
          :id="`modal-assign-to-affiliate-${data.item._id}`"
          centered
        >
          <AssignToAffiliate
            :email="data.item.email"
            :_id="data.item._id"
            :created_at="data.item.createdAt"
            @assigned="handleAffiliateAssigned($event, data.item._id)"
          ></AssignToAffiliate>
        </b-modal>
        <span v-b-popover.hover.top="data.value">
          <!--        <router-link-->
          <!--          :to="getRouterLink('affiliates', data.item.affiliate._id, true)"-->
          <!--          target="_blank"-->
          <!--        >-->
          {{ data.value }}
          <!--        </router-link>-->
        </span>
      </template>

      <template #cell(trader)="data">
        <b-button
          v-if="iAmOtherLevelTrustedUser"
          v-b-modal="`modal-assign-to-trader-${data.item._id}`"
          variant="info"
          class="mx-1"
          size="sm"
        >
          ✍️
        </b-button>
        <b-button
          v-if="iAmOtherLevelTrustedUser && data.item.trader"
          @click="deassignTrader(data.item._id, data.item.email)"
          variant="danger"
          class="mx-1"
          size="sm"
        >
          ✖️
        </b-button>
        <b-modal
          v-if="iAmOtherLevelTrustedUser"
          :id="`modal-assign-to-trader-${data.item._id}`"
          centered
        >
          <AssignToAffiliate
            :email="data.item.email"
            :_id="data.item._id"
            :created_at="data.item.createdAt"
            assign="trader"
            @assigned="handleTraderAssigned($event, data.item._id)"
          ></AssignToAffiliate>
        </b-modal>
        <span v-b-popover.hover.top="data.value">{{ data.value }}</span>
      </template>

      <template #cell(traderStatus)="data">
        <div class="d-flex">
          <h5 class="mx-2">
            <b-badge variant="primary">{{ data.value }}</b-badge>
          </h5>
          <b-dropdown dropright no-caret size="sm" boundary="window" lazy class="mx-2">
            <template #button-content size="sm">
              <b-icon icon="pencil-square"></b-icon>
            </template>
            <div style="width: 300px">
              <ChangeTraderStatus
                :_id="data.item._id"
                :init-status="data.item.traderStatus"
                @changed="handleTraderStatusChanged($event, data.item._id)"
              />
            </div>
          </b-dropdown>
        </div>
      </template>

      <template #cell(location)="data">
        <span v-b-popover.hover.top="data.value">{{ data.value }}</span>
        <!--        <router-link :to="getRouterLink('locations', data.value, true)" target="_blank">-->
        <!--          {{ data.value }}-->
        <!--        </router-link>-->
      </template>
      <template #cell(sentByInj)="data">
        <span v-b-popover.hover.top="data.value">{{ data.value }}</span>
        <!--        <router-link :to="getRouterLink('sentByInj', data.item.sentByInj, true)" target="_blank">-->
        <!--          {{ data.value }}-->
        <!--        </router-link>-->
      </template>
      <template #cell(url)="data">
        <a :href="data.value">{{ data.value }}</a>
      </template>

      <template #cell(campaign)="data">
        <span v-b-popover.hover.top="data.value">{{ data.value }}</span>
        <!--        <router-link :to="getRouterLink('campaigns', data.item.campaign._id, true)" target="_blank">-->
        <!--          {{ data.value }}-->
        <!--        </router-link>-->
      </template>
      <template #cell(url)="data">
        <a :href="data.value">{{ data.value }}</a>
      </template>

      <template #cell(box)="data">
        <span v-b-popover.hover.top="data.value">{{ data.value }}</span>
        <!--        <router-link :to="getRouterLink('boxes', data.item.box._id, true)" target="_blank">-->
        <!--          {{ data.value }}-->
        <!--        </router-link>-->
      </template>

      <template #cell(salesStatus)="data">
        <div class="d-flex">
          <span
            class="mx-1"
            v-b-popover.hover.top="
              data.value === 'unknown' &&
              data.item.brokerApiAnswer &&
              data.item.brokerApiAnswer.error
                ? data.item.brokerApiAnswer.error
                : data.value
            "
          >
            <!--          <router-link :to="getRouterLink('salesStatus', data.value, false)" target="_blank">-->
            {{ data.value }}
            <!--          </router-link>-->
          </span>
          <LoadingSpinner
            small
            :show-text="false"
            :requesting="data.item.saleStatusRequesting"
            v-if="data.item.saleStatusRequesting"
          />
          <h5
            class="flicker-animation"
            v-if="data.item.salesStatusJustChanged && !data.item.saleStatusRequesting"
          >
            <b-badge variant="warning">
              <b-icon icon="arrow-clockwise"></b-icon>
            </b-badge>
          </h5>
        </div>
      </template>

      <template #cell(sentToInstanceCampaign)="data">
        <span v-b-popover.hover.top="data.value">
          <!--        <router-link-->
          <!--          :to="-->
          <!--            getRouterLink(-->
          <!--              'metaCampaigns',-->
          <!--              {-->
          <!--                name: data.item.sentToInstanceCampaign.name,-->
          <!--                _id: data.item.sentToInstanceCampaign._id,-->
          <!--              },-->
          <!--              true-->
          <!--            )-->
          <!--          "-->
          <!--          target="_blank"-->
          <!--        >-->
          {{ data.value }}
          <!--        </router-link>-->
        </span>
      </template>

      <template #cell(type)="data">
        <div v-b-popover.hover.top="data.value">
          <b v-if="data.value === 'ftd'">{{ data.value.toUpperCase() }}</b>
          <span v-else>{{ data.value }}</span>
        </div>
      </template>

      <template #cell(email)="data">
        <span v-b-popover.hover.top="data.value">
          <!--        <router-link :to="getRouterLink('email', data.item.email, false)" target="_blank">-->
          {{ data.value }}
          <!--        </router-link>-->
        </span>
      </template>
      <template #cell(phone)="data">
        <span v-b-popover.hover.top="data.value">
          <!--        <router-link :to="getRouterLink('phone', data.item.phone, false)" target="_blank">-->
          {{ data.value }}
          <!--        </router-link>-->
        </span>
      </template>

      <template #cell(brokerAccepted)="data">
        <div class="d-flex">
          <h5 class="">
            <b-badge
              :variant="
                data.value === 'true' ? 'success' : data.value === 'false' ? 'danger' : 'warning'
              "
            >
              {{ data.value === 'true' ? 'Yes' : data.value === 'false' ? 'No' : 'unknown' }}
            </b-badge>
          </h5>
          <b-button
            v-if="
              (iAmOtherLevelTrustedUser || (user && user.seeLeadsBasedOnCampaigns)) &&
              data.value !== 'true' &&
              data.item.campaign !== 'TECH' &&
              data.item.campaign.name !== 'TECH'
            "
            v-b-modal="`broker-api-answer-modal-${data.item._id}`"
            size="sm"
            class="mx-1"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <b-modal
            v-if="iAmOtherLevelTrustedUser || (user && user.seeLeadsBasedOnCampaigns)"
            :id="`broker-api-answer-modal-${data.item._id}`"
            centered
            size="xl"
            hide-footer
          >
            <BrokerApiAnswerView :broker-api-answer="data.item.brokerApiAnswer" />
          </b-modal>
        </div>
      </template>

      <template #cell(m)="data">
        <h5 class="">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'info' : 'secondary'"
            @click="changeMStatus(data.item._id, data.value)"
          >
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(cb)="data">
        <h5 class="">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'info' : 'secondary'"
          >
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(ftdApproved)="data">
        <h5 class="">
          <b-badge
            class="badge-toggle"
            v-if="data.item.type === 'ftd'"
            :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'"
            @click="changeApproveStatus(data.item._id, data.value)"
          >
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
          <b-badge variant="secondary" v-else>-</b-badge>
        </h5>
      </template>

      <template #cell(calls)="data">
        <b-button
          variant="info"
          v-b-modal="`modal-action-calls-table-${data.item._id}`"
          size="sm"
          class="mx-1"
        >
          <b-icon icon="eye"></b-icon>
        </b-button>
        <b-modal
          v-if="iAmOtherLevelTrustedUser && data.value > 0"
          :id="`modal-action-calls-table-${data.item._id}`"
          centered
          size="xl"
          hide-footer
        >
          <ActionsCallsTable :action_id="data.item._id"></ActionsCallsTable>
        </b-modal>
        <span class="">
          <b-badge class="badge-toggle" :variant="data.value > 0 ? 'info' : 'secondary'">
            {{ data.value > 0 ? data.value : '-' }}
          </b-badge>
        </span>
      </template>

      <template #row-details="row">
        <ActionDetails
          :_id="row.item._id"
          :sorted-selected-details="sortedSelectedDetails"
          @updateAction="handle_updateAction"
          @updateActionKey="handle_updateActionKey"
        />
      </template>
      <template #cell(psp)="data">
        <b-button
          v-if="!isAffiliate || user.maff || user.canUploadLeads"
          v-b-modal="`modal-assign-psp-${data.item._id}`"
          variant="info"
          class="mx-1"
          size="sm"
        >
          ✍️
        </b-button>
        <b-modal
          v-if="!isAffiliate || user.maff || user.canUploadLeads"
          :id="`modal-assign-psp-${data.item._id}`"
          centered
          size="xl"
          hide-footer
        >
          <PSPAssign
            :email="data.item.email"
            :action_id="data.item._id"
            :finalReceiver="data.item.finalReceiver"
            :psp="
              data.item.psp && data.item.psp._id && data.item.psp._id.number
                ? data.item.psp._id
                : {}
            "
            @pspAssigned="handlePSPAssigned"
          ></PSPAssign>
        </b-modal>
        <span
          v-b-popover.hover.top="
            data.unformatted && data.unformatted._id && data.unformatted._id._id
              ? data.unformatted._id._id
              : data.unformatted._id
              ? data.unformatted._id
              : data.unformatted
          "
        >
          {{ data.value }}
        </span>
      </template>
      <template #cell(accessDetails)="data">
        <b-button
          v-if="!isAffiliate || user.maff || user.canUploadLeads"
          v-b-modal="`modal-access-details-${data.item._id}`"
          variant="info"
          class="mx-1"
          size="sm"
        >
          ✍️
        </b-button>
        <b-modal
          v-if="!isAffiliate || user.maff || user.canUploadLeads"
          :id="`modal-access-details-${data.item._id}`"
          centered
          size="md"
          hide-footer
        >
          <AccessDetailsEdit
            :email="data.item.email"
            :action_id="data.item._id"
            :finalReceiver="data.item.finalReceiver"
            :accessDetails="data.item.accessDetails ? data.item.accessDetails : {}"
            @accessDetailsEdited="handleAccessDetailsEdited"
          ></AccessDetailsEdit>
        </b-modal>

        <span v-if="data.item.accessDetails" :id="`access-details-icon-${data.item._id}`">
          <b-badge variant="primary"><b-icon icon="eye" class="mx-1"></b-icon></b-badge>
        </span>
        <b-popover
          :target="`access-details-icon-${data.item._id}`"
          triggers="hover"
          placement="left"
          v-if="data.item.accessDetails"
        >
          <template #title>Access Details</template>
          <p>
            URL:
            <b-link :href="data.item.accessDetails.url ? data.item.accessDetails.url : '#'">
              {{ data.item.accessDetails.url }}
            </b-link>
          </p>
          <p>Username: {{ data.item.accessDetails.username }}</p>
          <p>Password: {{ data.item.accessDetails.password }}</p>
        </b-popover>
      </template>
      <template #head(worker)="data">
        <div class="d-flex">
          <span class="mr-2">{{ data.label.toUpperCase() }}</span>
          <b-button size="sm" @click="requestAllWorkersAvailability()" variant="info">
            Check All Who Is Available
          </b-button>
        </div>
      </template>
      <template #cell(worker)="data">
        <div class="d-flex">
          <b-button
            v-if="!isAffiliate || user.hasWorkers"
            v-b-modal="`modal-assign-worker-${data.item._id}`"
            variant="info"
            class="mx-1"
            size="sm"
          >
            ✍️
          </b-button>
          <b-modal
            v-if="!isAffiliate || user.hasWorkers"
            :id="`modal-assign-worker-${data.item._id}`"
            centered
            size="xl"
            hide-footer
          >
            <WorkerAssign
              :email="data.item.email"
              :action_id="data.item._id"
              :finalReceiver="data.item.finalReceiver"
              :worker="
                data.item.worker && data.item.worker._id && data.item.worker._id.name
                  ? data.item.worker._id
                  : {}
              "
              @workerAssigned="handleWorkerAssigned"
            ></WorkerAssign>
          </b-modal>

          <!--          <router-link-->
          <!--            :to="-->
          <!--              getRouterLink(-->
          <!--                'workers',-->
          <!--                {-->
          <!--                  _id:-->
          <!--                    data.unformatted && data.unformatted._id && data.unformatted._id._id-->
          <!--                      ? data.unformatted._id._id-->
          <!--                      : data.unformatted._id-->
          <!--                      ? data.unformatted._id-->
          <!--                      : data.unformatted,-->
          <!--                  name: data.value,-->
          <!--                },-->
          <!--                true-->
          <!--              )-->
          <!--            "-->
          <!--            target="_blank"-->
          <!--          >-->
          <h5
            class="mx-1"
            v-if="data.value"
            v-b-popover.hover.top="
              data.unformatted && data.unformatted._id && data.unformatted._id._id
                ? data.unformatted._id._id
                : data.unformatted._id
                ? data.unformatted._id
                : data.unformatted
            "
          >
            <b-badge
              :variant="
                data.item.workerAssignResult
                  ? data.item.workerAssignResult.preferrability > 7
                    ? 'success'
                    : data.item.workerAssignResult.preferrability >= 5
                    ? 'warning'
                    : 'danger'
                  : 'secondary'
              "
            >
              {{ data.value }}
            </b-badge>
          </h5>
          <!--          </router-link>-->
          <div v-if="!makeFtdsMode" class="d-flex">
            <h5
              class="mx-1"
              v-if="
                data.item.worker &&
                data.item.worker._id &&
                typeof data.item.worker._id.active === 'boolean'
              "
            >
              <b-badge
                :variant="
                  data.item.worker._id.active === 'true' || data.item.worker._id.active === true
                    ? 'success'
                    : 'danger'
                "
              >
                {{
                  data.item.worker._id.active === 'true' || data.item.worker._id.active === true
                    ? 'Active'
                    : 'Inactive'
                }}
              </b-badge>
            </h5>
            <h5
              class="mx-1"
              v-if="
                data.item.worker &&
                data.item.worker._id &&
                typeof data.item.worker._id.available === 'boolean'
              "
            >
              <b-badge
                :variant="
                  data.item.worker._id.available === 'true' ||
                  data.item.worker._id.available === true
                    ? 'success'
                    : 'danger'
                "
                class="badge-toggle"
                @click="requestWorkerAvailability(data.item.worker._id)"
              >
                {{
                  data.item.worker._id.available === 'true' ||
                  data.item.worker._id.available === true
                    ? 'Available'
                    : 'Unavailable'
                }}
              </b-badge>
            </h5>
          </div>
        </div>
      </template>

      <template #cell(ftdCallbackStatus)="data">
        <h5 v-if="data.value && data.value !== ''">
          <b-badge :variant="data.value === 'ftd' ? 'warning' : 'success'">
            Make {{ data.value.toUpperCase() }}
          </b-badge>
        </h5>
      </template>

      <template #cell(workerAssignResult)="data">
        <h5
          v-if="data.value || data.value === 0"
          v-b-popover.hover.top="`Preferrability rate: ${data.value}`"
        >
          <b-badge :variant="data.value > 7 ? 'success' : data.value >= 5 ? 'warning' : 'danger'">
            Make {{ data.value >= 5 ? 'FTD' : 'CALLBACK' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(requestToCheck)="data">
        <h5 class="">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'info' : 'secondary'"
            @click="changeRequestToCheck(data.item._id, data.value)"
          >
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(withdraw)="data">
        <h5 class="">
          <b-badge
            :class="isAdmin ? 'badge-toggle' : ''"
            :variant="data.value === 'true' || data.value === true ? 'info' : 'secondary'"
            @click="changeWithdrawFlag(data.item._id, data.value)"
          >
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(renew)="data">
        <h5
          class=""
          v-if="
            data.item.m &&
            (iAmOtherLevelTrustedUser ||
              servicename === 'grandmaster' ||
              servicename === 'localgm' ||
              servicename === 'leaddim')
          "
        >
          <b-badge
            :class="data.item.campaign.name === 'TECH' ? '' : 'badge-toggle'"
            :variant="
              data.value === 'true' || data.value === true
                ? 'info'
                : data.item.campaign.name === 'TECH'
                ? 'success'
                : 'warning'
            "
            @click="
              data.item.campaign.name === 'TECH'
                ? (() => {})()
                : changeRenewFlag(data.item._id, data.value)
            "
          >
            {{
              data.value === 'true' || data.value === true
                ? 'Renew'
                : data.item.campaign.name === 'TECH'
                ? 'New'
                : 'Used'
            }}
          </b-badge>
        </h5>
      </template>

      <template #cell(withdrawDone)="data">
        <h5 class="">
          <b-badge
            :class="user.trader ? 'badge-toggle' : ''"
            :variant="data.value === 'true' || data.value === true ? 'info' : 'secondary'"
            @click="changeWithdrawDoneFlag(data.item._id, data.value)"
          >
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(isTest)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'warning' : 'info'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(finalReceiver)="data" v-if="!isAffiliate || user.extendedFieldsAccess">
        <div class="d-flex">
          <b-dropdown
            dropleft
            no-caret
            size="sm"
            boundary="window"
            lazy
            class="mr-2"
            v-if="!makeFtdsMode"
          >
            <template #button-content size="sm">
              <b-icon icon="three-dots"></b-icon>
            </template>

            <div class="d-flex">
              <b-button
                class="mr-2 ml-3"
                size="sm"
                :href="data.item.finalReceiverUrl"
                :disabled="!data.item.finalReceiverUrl"
                v-if="!makeFtdsMode"
              >
                <b-icon icon="link-45deg"></b-icon>
              </b-button>
              <div v-if="!makeFtdsMode" class="d-flex">
                <div v-if="!isAffiliate">
                  <b-button
                    class="mr-2"
                    size="sm"
                    v-b-modal="`check-confirmation-modal-${data.item._id}`"
                    :disabled="checkingActions.find((actionId) => actionId === data.item._id)"
                    v-if="data.item.finalReceiverImage"
                  >
                    <b-spinner
                      small
                      v-if="checkingActions.find((actionId) => actionId === data.item._id)"
                    ></b-spinner>
                    <b-icon v-else icon="camera" />
                  </b-button>
                  <b-button
                    class="mr-2"
                    size="sm"
                    @click="checkFinalReceiverUrl(data.item._id)"
                    :disabled="checkingActions.find((actionId) => actionId === data.item._id)"
                    v-else
                  >
                    <b-spinner
                      small
                      v-if="checkingActions.find((actionId) => actionId === data.item._id)"
                    ></b-spinner>
                    <b-icon v-else icon="camera" />
                  </b-button>
                </div>
              </div>
              <b-dropdown
                dropleft
                no-caret
                size="sm"
                boundary="window"
                lazy
                class="mr-2"
                v-if="!makeFtdsMode"
              >
                <template #button-content size="sm">
                  <b-icon icon="eye"></b-icon>
                </template>
                <div style="width: 900px">
                  <zoom-on-hover
                    :img-normal="data.item.finalReceiverImage"
                    :scale="2"
                  ></zoom-on-hover>
                </div>
              </b-dropdown>
            </div>
          </b-dropdown>
          <b-dropdown
            dropleft
            no-caret
            size="sm"
            class="mr-2"
            lazy
            :disabled="
              !data.item.finalReceiver ||
              data.item.finalReceiver.toLowerCase() === 'unknown' ||
              !data.item.finalReceiverComment ||
              data.item.finalReceiverComment === ''
            "
            :variant="
              !(!data.item.finalReceiverComment || data.item.finalReceiverComment === '')
                ? 'danger'
                : undefined
            "
          >
            <template #button-content size="sm">
              <b-icon icon="chat-right"></b-icon>
            </template>
            <b-dropdown-header>Comment</b-dropdown-header>
            <b-dropdown-text>{{ data.item.finalReceiverComment }}</b-dropdown-text>
          </b-dropdown>
          <b-dropdown
            dropleft
            no-caret
            size="sm"
            lazy
            :disabled="
              !data.item.finalReceiver || data.item.finalReceiver.toLowerCase() === 'unknown'
            "
          >
            <template #button-content size="sm">
              <b-icon icon="exclamation-triangle"></b-icon>
            </template>
            <b-dropdown-header>Alert Level</b-dropdown-header>
            <b-form-group>
              <b-form-radio-group
                v-model="data.item.finalReceiverAlertLevel"
                @change="setFinalReceiverAlertLevel(data.item._id, $event)"
              >
                <b-form-radio :value="0">OK</b-form-radio>
                <b-form-radio :value="1">Warning</b-form-radio>
                <b-form-radio :value="2">Danger</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-dropdown>
          <h5 v-if="data.value && (!isAffiliate || user.extendedFieldsAccess)" class="mx-2">
            <!--            <b-badge :variant="data.item.finalReceiverAlert ? 'danger' : 'info'">-->
            <b-badge
              :variant="
                data.item.finalReceiverAlertLevel === 2
                  ? 'danger'
                  : data.item.finalReceiverAlertLevel === 1
                  ? 'warning'
                  : 'success'
              "
              v-b-popover.hover.top="
                data.item.finalReceiverComment ? data.item.finalReceiverComment : ''
              "
            >
              <!--              <router-link :to="getRouterLink('finalReceivers', data.value, true)" target="_blank">-->
              {{ data.value }}
              <!--              </router-link>-->
            </b-badge>
          </h5>
          <h5 class="mx-2" v-if="!data.value && (!isAffiliate || user.extendedFieldsAccess)">
            <b-badge variant="warning">unknown</b-badge>
          </h5>
          <h5 v-if="!makeFtdsMode">
            <FinalReceiverRegulatedIndicator
              _id=""
              :hostname="data.value"
              :regulated="data.item.finalReceiverRegulated"
              @changed="handle_toggle_final_receiver_regulated"
            />
          </h5>
          <div v-if="data.item.ftdsMapping">
            <h5 :id="`fr-ftds-done-${data.item._id}`">
              <b-badge variant="info" class="mx-1 badge-toggle">
                Ftds Sent: {{ data.item.ftdsMapping.ftdsSent }}
              </b-badge>
            </h5>
            <b-popover :target="`fr-ftds-done-${data.item._id}`" triggers="hover" placement="top">
              <template #title>Info on FTDs Sent</template>
              <p>
                Last FTD Sent:
                {{
                  data.item.ftdsMapping.lastFtdSent
                    ? tableFormatterDate(data.item.ftdsMapping.lastFtdSent)
                    : 'More than 180 days ago'
                }}
              </p>
              <p v-if="data.item.ftdsMapping.lastFtdSentAgo > 0">
                Last FTD Sent {{ getDaysNumber(data.item.ftdsMapping.lastFtdSentAgo) }} Days Ago
              </p>
            </b-popover>
          </div>
          <div v-if="data.item.ftdsMapping">
            <h5 :id="`fr-ftds-received-${data.item._id}`">
              <b-badge variant="success" class="mx-1 badge-toggle">
                Ftds Received: {{ data.item.ftdsMapping.ftdsReceived }}
              </b-badge>
            </h5>
            <b-popover
              :target="`fr-ftds-received-${data.item._id}`"
              triggers="hover"
              placement="top"
            >
              <template #title>Info on FTDs Received</template>
              <p>
                Last FTD Received:
                {{
                  data.item.ftdsMapping.lastFtdReceived
                    ? tableFormatterDate(data.item.ftdsMapping.lastFtdReceived)
                    : 'More than 180 days ago'
                }}
              </p>
              <p v-if="data.item.ftdsMapping.lastFtdReceivedAgo > 0">
                Last FTD Received {{ getDaysNumber(data.item.ftdsMapping.lastFtdReceivedAgo) }} Days
                Ago
              </p>
            </b-popover>
          </div>
          <div v-if="data.item.ftdsMapping">
            <h5 :id="`fr-ftds-shaved-${data.item._id}`">
              <b-badge variant="warning" class="mx-1 badge-toggle">
                Ftds Shaved: {{ data.item.ftdsMapping.ftdsShaved }}
              </b-badge>
            </h5>
            <b-popover :target="`fr-ftds-shaved-${data.item._id}`" triggers="hover" placement="top">
              <template #title>Info on FTDs Shaved</template>
              <p>
                Last FTD Shaved:
                {{
                  data.item.ftdsMapping.lastFtdShaved
                    ? tableFormatterDate(data.item.ftdsMapping.lastFtdShaved)
                    : 'More than 180 days ago'
                }}
              </p>
              <p v-if="data.item.ftdsMapping.lastFtdShavedAgo > 0">
                Last FTD Shaved {{ getDaysNumber(data.item.ftdsMapping.lastFtdShavedAgo) }} Days Ago
              </p>
            </b-popover>
          </div>
          <div v-if="!makeFtdsMode" class="d-flex">
            <b-dropdown right no-caret size="sm" v-if="!isAffiliate">
              <template #button-content>
                <b-icon icon="pencil-square"></b-icon>
              </template>
              <b-dropdown-form style="width: 240px">
                <ChangeFinalReceiver
                  :id="data.item._id"
                  :initialValue="data.value"
                  :onSave="changeFinalReceiver"
                ></ChangeFinalReceiver>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>

        <b-modal
          :id="`check-confirmation-modal-${data.item._id}`"
          centered
          size="lg"
          lazy
          header-class="position-relative"
          ok-title="☠️ Do it, I understand the risk"
          ok-variant="danger"
          @ok="checkFinalReceiverUrl(data.item._id)"
          cancel-title="😳 This is frightening... Cancel operation!"
          cancel-variant="info"
          @shown="playWarning"
        >
          <template #modal-title>
            <h5
              class="modal-title font-weight-bold"
              style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
            >
              ARE YOU SURE ABOUT THAT?
            </h5>
          </template>
          <p class="text-center mb-2">You already have Link Checking result for this action</p>
          <img
            :src="data.item.finalReceiverImage"
            alt=""
            style="display: block; max-width: 100%; max-height: 550px; margin: 0 auto"
          />
          <p class="text-center mt-2">
            If you proceed, this data will be
            <strong>DESTROYED</strong>
          </p>
        </b-modal>
      </template>
    </b-table>
    <b-row class="mb-3" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
    <!--    <b-button-->
    <!--      variant="primary"-->
    <!--      v-if="user.email === 'blacklight@default.gg'"-->
    <!--      @click="orderPullingFullCheck()"-->
    <!--    >-->
    <!--      Order pulling full check-->
    <!--    </b-button>-->
    <p>Debug: {{ this.lastTimeToProcess }}</p>
    <NewInjAlgorithmTest
      v-if="user.email === 'blacklight@default.gg'"
      :items="items"
    ></NewInjAlgorithmTest>
    <b-modal ref="mandatory-psp-modal" hide-footer centered title="FTD Made Mark Blocked">
      <div class="d-block text-center">
        <h3>Please add PSP before you mark FTD Made</h3>
      </div>
    </b-modal>
    <b-modal
      ref="mandatory-access-details-modal"
      hide-footer
      centered
      title="FTD Made Mark Blocked"
    >
      <div class="d-block text-center">
        <h3>Please add Cabinet Access Details before you mark FTD Made</h3>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
/* eslint-disable no-nested-ternary,camelcase,no-await-in-loop */
import { mapState } from 'vuex';
import _ from 'lodash';
import { DateTime } from 'luxon';
import 'vue-json-pretty/lib/styles.css';
import {
  countryOptions,
  datepickerFormat,
  statsActionTypeOptions,
  TRUSTED_USERS,
  dateFormatOptions,
  OTHER_LEVEL_TRUSTED_USERS,
  initialFilterForm,
  sortOrderOptions,
} from '@/consts';
import { userAvailableFields, userAvailableDetails } from '@/consts/userAvailableFields';
import {
  countryMultiSelectSearch,
  handleRequestError,
  showCard,
  tableFormatterNameAndId,
  tableFormatterDate,
  delay,
  parseQuery,
  metaCampaignsOptions,
  finalReceiversOptions,
  actionDateTime,
} from '@/utils';
import {
  tableFieldsOptions,
  sortedSelectedFields,
  detailsOptions,
  sortedSelectedDetails,
  saveSelectedFieldsMethod,
  takeDefaultTableFields,
  takeDefaultDetailsOptions,
  applyFields,
  applyDetails,
  canApplyFields,
  canApplyDetails,
} from '@/utils/fieldsAndDetailsOptionsHelpers';
import {
  checkFinalReceiverUrl,
  chengeApproveStatus,
  chengeFinalReceiver,
  chengeMStatus,
  chengeRequestToCheck,
  getActions,
  makeTestFtd,
  orderPullingFullCheck,
  requestSTCIList,
  restartPulling,
  checkHowManyTimesCanSendLeadAsM,
  changeWithdrawFlag,
  changeWithdrawDoneFlag,
  changeRenewFlag,
  deassignTrader,
  clearAllRenewLeads,
  checkActionSaleStatus,
  setFinalReceiverAlertLevel,
  requestWorkerAvailability,
  requestAllWorkersAvailability,
} from '@/utils/api';
import areYouSureAudio from '@/assets/are-you-sure.mp3';
import $ from 'jquery';
import JsonExcel from 'vue-json-excel';
import PSPAssign from '@/components/pages/PSP/PSPAssign.vue';
import WorkerAssign from '@/components/pages/Workers/WorkerAssign.vue';
import FinalReceiverRegulatedIndicator from '@/components/shared/FinalReceiverRegulatedIndicator.vue';
import ChangeTraderStatus from '@/components/pages/Actions/ActionsTable/components/ChangeTraderStatus.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import BrokerApiAnswerView from '@/components/pages/Actions/ActionsTable/components/BrokerApiAnswerView.vue';
import ActionDetails from '@/components/pages/Actions/ActionsTable/components/ActionDetails.vue';
import ActionsFilterForm from '@/components/pages/Actions/ActionsTable/components/ActionsFilterForm.vue';
import NewInjAlgorithmTest from '@/components/pages/Actions/ActionsTable/components/NewInjAlgorithmTest.vue';
import SalesStatusClientsList from '@/components/pages/Actions/ActionsTable/components/SalesStatusClientsList.vue';
import StatsBreakdownByItems from './components/StatsBreakdownByItems.vue';
import ChangeFinalReceiver from './components/ChangeFinalReceiver.vue';
import SendMLeadMenu from './components/SendMLeadMenu.vue';
import SendLeadManualMenu from './components/SendLeadManualMenu.vue';
import AssignToAffiliate from './components/AssignToAffiliate.vue';
import AccessDetailsEdit from './components/AccessDetailsEdit.vue';
import ActionsCallsTable from './components/ActionsCallsTable.vue';
import '@/assets/badge-toogle.css';
import '@/assets/breakdown-data.css';
import '@/assets/flicker-animation.css';

export default {
  name: 'ActionsTable',
  components: {
    NewInjAlgorithmTest,
    ActionsFilterForm,
    ActionDetails,
    BrokerApiAnswerView,
    LoadingSpinner,
    ChangeTraderStatus,
    FinalReceiverRegulatedIndicator,
    WorkerAssign,
    ChangeFinalReceiver,
    StatsBreakdownByItems,
    JsonExcel,
    SendMLeadMenu,
    AssignToAffiliate,
    SendLeadManualMenu,
    PSPAssign,
    AccessDetailsEdit,
    ActionsCallsTable,
    SalesStatusClientsList,
  },
  data() {
    return {
      makeFtdsMode: false,
      useNewFilterAlgorithm: true,
      blockRouterChangeWatch: false,

      dateFormatOptions,
      checkingActions: [],
      actions: [],
      countryOptions,
      requesting_workersOptions: false,
      workersOptions: [],
      showTypeMoreThan: 'lead',

      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 500, text: '500' },
        { value: 1000, text: '1000' },
      ],
      filterForm: _.cloneDeep(initialFilterForm),
      appliedFilterForm: _.cloneDeep(initialFilterForm),
      lastTimeToProcess: 0,
      sortOrderOptions,
      stats: {
        leadsBrokerAcceptAmount: 0,
        leadsBrokerNoAcceptAmount: 0,
        ftdsAmount: 0,
        shaved: 0,
        leadsBeforeFirstFTD: 0,
        leadsPositiveStatus: 0,
        leadsPositiveToNegativeChangers: 0,
        payout: 0,
        aff_payout: 0,
        profit: 0,
        crg: [{ _id: 'total', guaranteed: 0, left: 0, crgReceived: 0 }],
      },
      breakdownByCampaigns: [],
      breakdownByBrokers: [],
      breakdownByBoxes: [],
      breakdownByAffiliates: [],
      selectedColumns: [],
      preSelectedColumns: [],
      selectedDetails: [],
      preSelectedDetails: [],
      sidebarColumnsOpen: false,
      sidebarDetailsOpen: false,
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      abortTimesCanSendCheckFlag: false,
      checkingTimesCanSendInProgress: false,
      MCheckSalesStatusesCycleInterval: undefined,
      simultaneousMSalesStatusCheck: 0,
      formKey: 0,
      salesStatusClientsListType: 'positive',
      initialFilterForm: _.cloneDeep(initialFilterForm),
      workerOptions: [],
    };
  },
  computed: {
    ...mapState([
      'user',
      'isAdmin',
      'isManager',
      'isAffiliate',
      'isMasterAffiliate',
      'token',
      'affiliates',
      'campaigns',
      'boxes',
      'requesting',
      'finalReceivers',
    ]),
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    campaignsOptions() {
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    metaCampaignsOptions() {
      return metaCampaignsOptions(this);
    },
    finalReceiversOptions() {
      // return this.finalReceivers ? this.finalReceivers : [];
      return finalReceiversOptions(this);
    },
    boxesOptions() {
      return this.boxes.map((b) => {
        return { name: b.name, _id: b._id };
      });
    },
    simpleView() {
      return this.user.email !== 'zerothreethree@default.gg';
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    canSendMsManually() {
      return this.iAmOtherLevelTrustedUser || this.user.campaignsAccess;
    },
    fieldsToExport() {
      return [
        'blacklight@default.gg',
        'thehighestadmin@defaulted.gg',
        'thehighestadmin@default.gg',
        'zerothreethree@default.gg',
      ].includes(this.user.email)
        ? this.appliedFilterForm.showPositiveStatuses ||
          this.appliedFilterForm.showPositiveToNegatives
          ? {
              Geo: 'location',
              Type: 'type',
              'Sales status': 'salesStatus',
              email: 'email',
            }
          : {
              'Action date': 'actionDate',
              Geo: 'location',
              Campaign: 'campaign.name',
              'Sent to broker': 'sentToBroker',
              Type: 'type',
              'Sales status': 'salesStatus',
              email: 'email',
              password: 'leadContactInfo.password',
              firstname: 'leadContactInfo.firstname',
              lastname: 'leadContactInfo.lastname',
              phone: 'leadContactInfo.phone',
              language: 'leadContactInfo.language',
              country: 'leadContactInfo.country',
              ip: 'leadContactInfo.ip',
            }
        : {};
    },
    onDeposits() {
      return this.$route.path === '/deposits';
    },
    onPreparedToPush() {
      return this.$route.path === '/prepared-to-push';
    },
    canPush() {
      return this.user.maff;
    },
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    canApplyFields() {
      if (this.makeFtdsMode) return false;
      return canApplyFields(this);
    },
    canApplyDetails() {
      return canApplyDetails(this);
    },
    userAvailableFields() {
      const isFTDApprovedAvailable = OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
      const extendedFieldsAccess = this.user && this.user.extendedFieldsAccess;
      const maff = this.user && this.user.maff;
      const canUploadLeads = this.user && this.user.canUploadLeads;
      const campaignsAccess = this.user && this.user.campaignsAccess;
      const hasWorkers = this.user && this.user.hasWorkers;
      const trader = this.user && this.user.trader;
      let r;
      if (this.iAmOtherLevelTrustedUser && this.makeFtdsMode) {
        r = userAvailableFields.actions.makeFtds(isFTDApprovedAvailable);
      } else if (this.isAdmin) {
        r = userAvailableFields.actions.admin(isFTDApprovedAvailable);
      } else if (this.isManager) {
        r = userAvailableFields.actions.manager(
          isFTDApprovedAvailable,
          this.user.integrator,
          this.user.seeLeadsBasedOnCampaigns
        );
      } else if (this.isMasterAffiliate) {
        r = userAvailableFields.actions.affiliate(
          extendedFieldsAccess,
          campaignsAccess,
          maff || canUploadLeads,
          hasWorkers,
          trader
        );
      } else {
        r = userAvailableFields.actions.affiliate(
          extendedFieldsAccess,
          campaignsAccess,
          maff || canUploadLeads,
          hasWorkers,
          trader
        );
      }
      return r;
    },
    tableFieldsOptions() {
      return tableFieldsOptions(this);
    },
    sortedSelectedFields() {
      if (this.makeFtdsMode) return this.userAvailableFields;
      return sortedSelectedFields(this);
    },
    userAvailableDetails() {
      if (this.isAdmin) return userAvailableDetails.actions.admin(this.iAmTrustedUser);
      if (this.isManager) return userAvailableDetails.actions.manager(this.iAmTrustedUser);
      if (this.isMasterAffiliate)
        return userAvailableDetails.actions.masteraffiliate(this.iAmTrustedUser);
      return userAvailableDetails.actions.affiliate();
    },
    detailsOptions() {
      return detailsOptions(this);
    },
    sortedSelectedDetails() {
      return sortedSelectedDetails(this);
    },

    statsActionTypeOptions() {
      return statsActionTypeOptions.map((o) => {
        const oc = _.cloneDeep(o);
        if (oc.value !== this.showTypeMoreThan && this.requesting) oc.disabled = true;
        return oc;
      });
    },
  },
  async mounted() {
    await this.$store.dispatch('get_all_resources_one_request', { vm: this });
    await this.applyDeposits();
    await this.updateData();
  },
  methods: {
    getSalesStatusClientsList(type) {
      this.salesStatusClientsListType = type;
      this.$refs['sales-status-clients-list-modal'].show();
    },
    updateWorkersOptions(event) {
      this.workerOptions = _.cloneDeep(event);
    },
    getRouterLink(field, value, isArray = false) {
      return this.getCellRouterLink(this, field, value, isArray, initialFilterForm);
    },
    requestWorkerAvailability(worker) {
      requestWorkerAvailability(this.token, { _id: worker._id })
        .then((r) => {
          if (r.data.success) {
            showCard(this, `Worker Availability requested for worker ${worker.name}`);
          } else {
            handleRequestError(this, r.data, 'Error requesting worker availability');
          }
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error requesting worker availability');
        });
    },
    requestAllWorkersAvailability() {
      requestAllWorkersAvailability(this.token)
        .then((r) => {
          if (r.data.success) {
            showCard(this, `Sent Availability Requests To All Workers`);
          } else {
            handleRequestError(this, r.data, 'Error requesting all workers availability');
          }
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error requesting all workers availability');
        });
    },
    setFinalReceiverAlertLevel(action_id, alertlevel) {
      setFinalReceiverAlertLevel(this.token, { action_id, alertlevel })
        .then((r) => {
          if (r.data.success) {
            this.updateItemField(action_id, 'finalReceiverAlertLevel', r.data.alertlevel);
            const action = this.items.find((i) => i._id === action_id);
            if (action) {
              const { finalReceiver } = action;
              for (let i = 0; i < this.items.length; i += 1) {
                if (this.items[i].finalReceiver === finalReceiver) {
                  this.items[i].finalReceiverAlertLevel = r.data.alertlevel;
                }
              }
            }
          } else {
            handleRequestError(this, r.data, 'Error changing FinalReceiver alert level');
          }
        })
        .catch((e) => handleRequestError(this, e, 'Error changing FinalReceiver alert level'));
    },
    async checkActionSaleStatus(_id, itemIndex) {
      if (this.requesting) return;
      if (itemIndex < 0 || this.items.length <= itemIndex || this.items[itemIndex]._id !== _id)
        return;
      if (!this.items[itemIndex].m) return;
      if (this.items[itemIndex].saleStatusRequesting) return;
      this.updateItemFieldByIndex(_id, itemIndex, 'saleStatusRequesting', true);
      const oldStatus =
        this.items[itemIndex].saleStatusChangeLog &&
        this.items[itemIndex].saleStatusChangeLog.lenght > 0
          ? this.items[itemIndex].saleStatusChangeLog[
              this.items[itemIndex].saleStatusChangeLog.length - 1
            ]
          : undefined;
      try {
        const r = await checkActionSaleStatus(this.token, { _id });
        const { saleStatusChangeLog, lastStatus } = r.data;
        if (saleStatusChangeLog && lastStatus) {
          this.updateItemFieldByIndex(_id, itemIndex, 'saleStatusChangeLog', saleStatusChangeLog);
          this.updateItemFieldByIndex(_id, itemIndex, 'salesStatus', lastStatus.status);
          if (oldStatus.status !== lastStatus.status) {
            this.updateItemFieldByIndex(_id, itemIndex, 'salesStatusJustChanged', true);
            setTimeout(() => {
              this.updateItemFieldByIndex(_id, itemIndex, 'salesStatusJustChanged', false);
            }, 7000);
          }
        }
        this.updateItemFieldByIndex(_id, itemIndex, 'saleStatusRequesting', false);
      } catch (e) {
        this.updateItemFieldByIndex(_id, itemIndex, 'saleStatusRequesting', false);
      }
      if (itemIndex === 0) this.setMCheckSalesStatusesCycleInterval();
    },
    async checkMSalesStatusesCycle() {
      this.simultaneousMSalesStatusCheck += 1;
      if (!this.canSendMsManually) return;
      if (this.requesting) return;
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items.length <= i) return;
        if (this.items[i].m) {
          // promises.push(this.checkActionSaleStatus(this.items[i]._id, i));
          // await delay(10);
          await this.checkActionSaleStatus(this.items[i]._id, i);
          await delay(100);
        }
      }
      this.simultaneousMSalesStatusCheck -= 1;
      // await Promise.all(promises);
      // console.log('end checkMSalesStatusesCycle', Date.now());
      // this.setMCheckSalesStatusesCycleInterval();
    },
    setMCheckSalesStatusesCycleInterval() {
      if (this.simultaneousMSalesStatusCheck < 3) {
        if (this.MCheckSalesStatusesCycleInterval)
          clearTimeout(this.MCheckSalesStatusesCycleInterval);
        if (
          this.$route.path === '/deposits' ||
          this.$route.path === '/crm' ||
          this.$route.path === '/actions'
        )
          this.MCheckSalesStatusesCycleInterval = setTimeout(
            async () => this.checkMSalesStatusesCycle(),
            20000
          );
      }
    },
    handle_toggle_final_receiver_regulated(data) {
      if (data.affectedByChange.length > 0) {
        const frg = data.affectedByChange[0];
        for (let j = 0; j < this.items.length; j += 1) {
          if (this.items[j].finalReceiver === frg.finalReceiverHostname) {
            this.items[j].finalReceiverRegulated = frg.regulated;
          }
        }
        this.$refs.itemsTable.refresh();
      }
    },
    handleAffiliateAssigned(data, _id) {
      this.updateItemField(_id, 'affiliate', data);
    },
    handleTraderAssigned(data, _id) {
      this.updateItemField(_id, 'trader', data);
    },
    handleTraderStatusChanged(data, _id) {
      this.updateItemField(_id, 'traderStatus', data);
    },
    handlePSPAssigned(data) {
      const { psp, action_id } = data;
      const itemIndex = this.items.findIndex((item) => item._id === action_id);
      if (itemIndex > -1) {
        this.items[itemIndex].psp = _.cloneDeep(psp);
      }
    },
    handleWorkerAssigned(data) {
      const { worker, action_id } = data;
      const itemIndex = this.items.findIndex((item) => item._id === action_id);
      if (itemIndex > -1) {
        this.items[itemIndex].worker = _.cloneDeep(worker);
      }
    },
    handleAccessDetailsEdited(data) {
      const { accessDetails, action_id } = data;
      const itemIndex = this.items.findIndex((item) => item._id === action_id);
      if (itemIndex > -1) {
        this.items[itemIndex].accessDetails = _.cloneDeep(accessDetails);
      }
    },
    handle_updateAction(event) {
      const { _id, item } = event;
      // console.log('handle_updateAction', _id, item);
      this.updateAction(_id, item);
    },
    handle_updateActionKey(event) {
      const { _id, value, key } = event;
      this.updateItemField(_id, key, value);
    },
    tableContextChangeHandler() {
      // console.log('table context changed');
    },
    tableRefreshedHandler() {
      // console.log('table refreshed');
    },
    applyFields() {
      applyFields(this);
    },
    applyDetails() {
      applyDetails(this);
    },
    saveSelectedFields(type) {
      saveSelectedFieldsMethod(this, type);
    },
    takeDefaultTableFields() {
      takeDefaultTableFields(this, 'actionTableSelectedFields');
    },
    takeDefaultDetailsOptions() {
      takeDefaultDetailsOptions(this, 'actionTableSelectedDetails');
    },
    restoreTablePreference() {
      this.takeDefaultTableFields();
      this.saveSelectedFields('actions_table');
    },
    restoreDetailsPreference() {
      this.takeDefaultDetailsOptions();
      this.saveSelectedFields('actions_details');
    },
    closeSidebarColumns() {
      this.sidebarColumnsOpen = false;
    },
    closeSidebarDetails() {
      this.sidebarDetailsOpen = false;
    },
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    restartPulling() {
      this.$store.commit('make_requesting', { req: true });
      restartPulling(this.token, {})
        .then((r) => {
          this.$store.commit('make_requesting', { req: false });
          showCard(this, r.data && r.data.message ? r.data.message : undefined, 'success', '');
        })
        .catch((e) => {
          this.$store.commit('make_requesting', { req: false });
          handleRequestError(this, e, `Unknown server error on restarting Pulling service`);
        });
    },
    updateAction(actionId, newAction) {
      if (newAction) {
        const clonedItems = _.cloneDeep(this.items);
        const itemIndex = clonedItems.findIndex(({ _id }) => _id === actionId);
        const { STCIList, _showDetails } = clonedItems[itemIndex];
        clonedItems[itemIndex] = newAction;
        if (STCIList) clonedItems[itemIndex].STCIList = STCIList;
        if (_showDetails) clonedItems[itemIndex]._showDetails = _showDetails;
        this.items = clonedItems;
        this.$store.commit('make_requesting', { req: false });
      } else {
        this.updateData();
      }
    },
    playWarning() {
      const audio = new Audio(areYouSureAudio);
      audio.play();
    },
    countryMultiSelectSearch,
    getFinalReceiverImageUrl(url) {
      return process.env.VUE_APP_BASE_URL + url;
    },
    requestSTCIList(_id) {
      this.$store.commit('make_requesting', { req: true });
      requestSTCIList(this.token, { _id })
        .then((r) => {
          const items = _.cloneDeep(this.items);
          const index = items.findIndex((i) => i._id === _id);
          items[index].STCIList = r.data;
          this.items = _.cloneDeep(items);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    changeMStatus(_id, m) {
      this.$store.commit('make_requesting', { req: true });
      chengeMStatus(this.token, { _id, m: !m })
        .then((r) => {
          showCard(
            this,
            `Changed Mix status of action ${r.data.action} to ${r.data.m}`,
            'success',
            ''
          );

          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    changeApproveStatus(_id, ftdApproved) {
      this.$store.commit('make_requesting', { req: true });
      chengeApproveStatus(this.token, { _id, ftdApproved: !ftdApproved })
        .then((r) => {
          showCard(
            this,
            `Changed FTD approved status of action ${r.data.action} to ${r.data.ftdApproved}`,
            'success',
            ''
          );

          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    changeWithdrawFlag(_id, withdraw) {
      if (!this.isAdmin) return;
      this.$store.commit('make_requesting', { req: true });
      changeWithdrawFlag(this.token, { _id, withdraw: !withdraw })
        .then((r) => {
          showCard(
            this,
            `Changed Withdraw status of action ${r.data.action} to ${r.data.withdraw}`,
            'success',
            ''
          );
          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on changing Withdraw status of action ${_id} to ${!withdraw}`
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
    changeRenewFlag(_id, value) {
      const renew = !!value;
      const action = this.items.find((item) => item._id === _id);
      if (action.campaign.name === 'TECH') {
        return;
      }
      changeRenewFlag(this.token, { _id, renew: !renew })
        .then((r) => {
          showCard(
            this,
            `Changed Renew status of action ${r.data.action} to ${r.data.renew}`,
            'success',
            ''
          );
          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on changing Withdraw status of action ${_id} to ${!renew}`
          );
        });
    },
    changeWithdrawDoneFlag(_id, withdrawDone) {
      if (!this.user.trader) return;
      this.$store.commit('make_requesting', { req: true });
      changeWithdrawDoneFlag(this.token, { _id, withdrawDone: !withdrawDone })
        .then((r) => {
          showCard(
            this,
            `Changed WithdrawDone status of action ${r.data.action} to ${r.data.withdrawDone}`,
            'success',
            ''
          );
          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on changing WithdrawDone status of action ${_id} to ${!withdrawDone}`
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
    async changeRequestToCheck(_id, requestToCheck) {
      await this.$store.dispatch('get_me');
      const item = this.items.find((i) => i._id === _id);
      if (!item) return;
      if (this.user.rtcMandatoryFlowPSP) {
        if (!(item.psp && item.psp._id && item.psp._id.number && item.psp._id.number.length > 0)) {
          // console.log('not found items psp', item.psp);
          this.$refs['mandatory-psp-modal'].show();
          return;
        }
      }
      if (this.user.rtcMandatoryFlow) {
        if (
          !(
            item.accessDetails &&
            item.accessDetails.url &&
            item.accessDetails.username &&
            item.accessDetails.password &&
            item.accessDetails.url.length > 0 &&
            item.accessDetails.username.length > 0 &&
            item.accessDetails.password.length > 0
          )
        ) {
          // console.log('not found items accessDetails', item.accessDetails);
          this.$refs['mandatory-access-details-modal'].show();
          return;
        }
      }
      this.$store.commit('make_requesting', { req: true });
      chengeRequestToCheck(this.token, { _id, requestToCheck: !requestToCheck })
        .then((r) => {
          showCard(
            this,
            `Changed Check status of action ${r.data.action} to ${r.data.requestToCheck}`,
            'success',
            ''
          );

          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on changing Check status of action ${_id} to ${!requestToCheck}`
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
    changeFinalReceiver(_id, finalReceiver) {
      this.$store.commit('make_requesting', { req: true });
      chengeFinalReceiver(this.token, { _id, finalReceiver })
        .then((r) => {
          showCard(
            this,
            `Changed Final Receiver of action ${r.data.action} to ${r.data.finalReceiver}`,
            'success',
            ''
          );

          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    checkFinalReceiverUrl(_id) {
      if (this.isAffiliate) return;
      this.$store.commit('make_requesting', { req: true });
      this.checkingActions.push(_id);

      checkFinalReceiverUrl(this.token, { _id })
        .then((r) => {
          this.checkingActions = this.checkingActions.filter((actionId) => actionId !== _id);
          showCard(this, `Final Receiver URL checked for action ${r.data.action}`, 'success', '');

          this.updateAction(_id, r.data.fullAction);
        })
        .catch((e) => {
          this.checkingActions = this.checkingActions.filter((actionId) => actionId !== _id);
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    checkHowManyTimesCanSendLeadAsM(_id) {
      this.$store.commit('make_requesting', { req: true });
      checkHowManyTimesCanSendLeadAsM(this.token, { _id })
        .then((r) => {
          showCard(this, `Can send this lead ${r.data.canSend} times`, 'success', '');
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on checking how many times can send`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    applyFilter(event) {
      // console.log('ActionsTable applyFilter', event);
      this.filterForm = _.cloneDeep(event);
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    clearFilterForm() {
      this.filter = null;
      this.filterForm = _.cloneDeep(initialFilterForm);
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    updateBackdoor() {
      // console.log('backdoor updated');
      this.backdoor += 1;
    },
    _updateLastTimeToProcess(t) {
      this.lastTimeToProcess = t;
    },
    actionDateTime(clr) {
      return actionDateTime(clr);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectJsonNode(path, data) {
      // console.log('selectJsonNode', path, data);
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied ${path.includes('.') ? path.substring(path.indexOf('.') + 1) : path}`,
        'success',
        ''
      );
    },
    getFilterFormForRequest() {
      const sf = [
        'affiliates',
        'campaigns',
        'campaignsInSelectionDropouts',
        'boxes',
        'salesStatusInHistory',
        'showPositiveToNegatives',
        'showOnlyM',
        'showOnlyChecked',
        'showOnlyShaved',
        'errorOnSend',
        'hideTestFtds',
        'showOnlyParanoidUniq',
        'showPositiveStatuses',
        'sentByInj',
      ];
      const appliedFilterForm = _.cloneDeep(this.appliedFilterForm);
      if (this.isAffiliate) {
        for (let i = 0; i < sf.length; i += 1) {
          delete appliedFilterForm[sf[i]];
        }
      }
      const options = {
        type: this.showTypeMoreThan,
        ..._.cloneDeep(appliedFilterForm),
        page: this.currentPage,
        itemsPerPage: this.perPage,
      };
      if (this.$route.path === '/prepared-to-push') {
        options.showOnlyPreparedToPush = true;
        options.hidePreparedToPush = false;
      }
      // console.log('options', options);
      if (options.locations)
        options.locations = options.locations.map((c) => (c.value ? c.value : c));
      if (options.campaigns) options.campaigns = options.campaigns.map((c) => (c._id ? c._id : c));
      // if (options.metaCampaigns) options.metaCampaigns = options.campaigns.map((c) => (c._id ? c._id : c));
      if (options.campaignsInSelectionDropouts)
        options.campaignsInSelectionDropouts = options.campaignsInSelectionDropouts.map((c) =>
          c._id ? c._id : c
        );
      if (options.boxes) options.boxes = options.boxes.map((c) => (c._id ? c._id : c));
      if (options.avoidBoxes)
        options.avoidBoxes = options.avoidBoxes.map((c) => (c._id ? c._id : c));
      if (options.affiliates)
        options.affiliates = options.affiliates.map((c) => (c._id ? c._id : c));
      if (options.managers) options.managers = options.managers.map((c) => (c._id ? c._id : c));
      if (options.workers) options.workers = options.workers.map((c) => (c._id ? c._id : c));

      if (options.from) {
        options.from = DateTime.fromFormat(options.from, datepickerFormat, { zone: 'utc' });
        options.from = options.from.isValid
          ? options.from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
          : undefined;
      }

      if (options.to) {
        options.to = DateTime.fromFormat(options.to, datepickerFormat, { zone: 'utc' });
        options.to = options.to.isValid ? options.to.toISO() : undefined;
      }
      return options;
    },
    updateData() {
      this.$store.commit('make_requesting', { req: true });
      const options = this.getFilterFormForRequest();
      function getPromises() {
        return window.actionsRequestPromises && Array.isArray(window.actionsRequestPromises);
      }
      const promiseId = Date.now();
      getPromises()
        ? window.actionsRequestPromises.push(promiseId)
        : (window.actionsRequestPromises = [promiseId]);
      getActions(
        this.token,
        options,
        this.useNewFilterAlgorithm ? '/action/list_new' : '/action/list'
      )
        .then(async (r) => {
          if (getPromises() && Math.max(...window.actionsRequestPromises) !== promiseId) {
            return;
          }
          window.actionsRequestPromises = [];
          this.$store.commit('make_requesting', { req: true });
          const { data } = r;
          if (this.showTypeMoreThan !== data.type) this.showTypeMoreThan = _.cloneDeep(data.type);
          this.stats = _.cloneDeep(data.stats);
          if (!this.isAffiliate) {
            this.breakdownByCampaigns = _.cloneDeep(data.breakdownByCampaigns);
            this.breakdownByBrokers = _.cloneDeep(data.breakdownByBrokers);
            this.breakdownByBoxes = _.cloneDeep(data.breakdownByBoxes);
            this.breakdownByAffiliates = _.cloneDeep(data.breakdownByAffiliates);
          }
          if (data.items) {
            for (let i = 0; i < data.items.length; i += 1) {
              if (!data.items[i].timesCanSendM) data.items[i].timesCanSendM = '';
            }
          }
          this.items = _.cloneDeep(data.items);
          if (this.currentPage !== data.page) this.currentPage = _.cloneDeep(data.page);
          if (this.perPage !== data.itemsPerPage) this.perPage = _.cloneDeep(data.itemsPerPage);
          this.totalRows = _.cloneDeep(data.totalRows);
          this.filterForm.sortBy = _.cloneDeep(data.sortBy);
          this.appliedFilterForm.sortBy = _.cloneDeep(data.sortBy);
          this.filterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.appliedFilterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.blockRouterChangeWatch = true;
          try {
            await this.$router.replace({
              query: this.getFieldsNotMatchingInitial(this.appliedFilterForm),
            });
            // eslint-disable-next-line no-empty
          } catch (e) {}
          this.blockRouterChangeWatch = false;
          this.$store.commit('make_requesting', { req: false });
          this.setMCheckSalesStatusesCycleInterval();
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
          this.setMCheckSalesStatusesCycleInterval();
        });
    },
    makeTestFtd(_id) {
      this.$store.commit('make_requesting', { req: true });
      makeTestFtd(this.token, _id)
        .then((res) => {
          const r = res.data;
          if (!r || !r._id) {
            showCard(
              this,
              'Failed to make test ftd. No such action, or no access to it',
              'warning'
            );
          } else {
            this.updateAction(_id, r.data);
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
        })
        .finally(() => this.$store.commit('make_requesting', { req: false }));
    },
    orderPullingFullCheck() {
      orderPullingFullCheck(this.token)
        .then((r) => {
          showCard(this, JSON.stringify(r), 'success', 'Ordered full check');
        })
        .catch((e) =>
          handleRequestError(this, e, 'Unknown server error on ordering pulling full check')
        );
    },
    tableFormatterNameAndId(value) {
      return tableFormatterNameAndId(value);
    },
    tableFormatterDate(value) {
      return tableFormatterDate(value);
    },
    getDaysNumber(value) {
      return Math.round(value / (1000 * 60 * 60 * 24));
    },
    getFieldsNotMatchingInitial(obj) {
      const result = {};
      const keys = Object.keys(initialFilterForm);
      function mapObjectsArrayToIds(objects, idField) {
        return objects.map((o) => o[idField]).filter((o) => !!o);
      }
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (obj[key] !== initialFilterForm[key]) {
          result[key] = obj[key]; // : initialFilterForm[key];
          if (
            key === 'affiliates' ||
            key === 'campaigns' ||
            // key === 'metaCampaigns' ||
            key === 'campaignsInSelectionDropouts' ||
            key === 'boxes'
            // key === 'workers'
          ) {
            result[key] = mapObjectsArrayToIds(result[key], '_id');
          }
          if (key === 'locations') {
            result[key] = mapObjectsArrayToIds(result[key], 'value');
          }
          if (key === 'metaCampaigns' || key === 'workers') {
            result[key] = JSON.stringify(result[key]);
          }
        }
      }
      return result;
    },
    applyQueryToForm(prop) {
      this[prop] = _.cloneDeep(parseQuery(this));
    },
    applyDeposits() {
      clearInterval(this.interval);
      if (this.$route.path === '/deposits') {
        this.showTypeMoreThan = 'ftd';
        this.applyQueryToForm('filterForm');
        this.applyQueryToForm('appliedFilterForm');
        if (_.isEmpty(this.$route.query)) {
          const f = DateTime.utc()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toFormat(datepickerFormat);
          const t = DateTime.utc().toFormat(datepickerFormat);
          this.filterForm.to = t;
          this.filterForm.from = f;
          this.filterForm.hideTestActions = true;
          this.appliedFilterForm.to = t;
          this.appliedFilterForm.from = f;
          this.appliedFilterForm.hideTestActions = true;
        }
        this.interval = setInterval(() => {
          if (
            this.$route.path === '/deposits' ||
            this.$route.path === '/crm' ||
            this.$route.path === '/actions'
          ) {
            this.updateData();
          }
        }, 30000);
      } else {
        this.applyQueryToForm('filterForm');
        this.applyQueryToForm('appliedFilterForm');
        this.showTypeMoreThan = 'lead';
      }
      this.formKey += 1;
    },

    updateItemField(_id, field, value) {
      const itemIndex = this.items.findIndex((i) => i._id === _id);
      if (itemIndex < 0) {
        return;
      }
      this.items[itemIndex][field] = value;
    },
    updateItemFieldByIndex(_id, itemIndex, field, value) {
      if (itemIndex < 0 || this.items.length <= itemIndex) {
        return;
      }
      if (this.items[itemIndex]._id !== _id) {
        return;
      }
      this.items[itemIndex][field] = value;
    },
    clearAllRenewLeads() {
      if (!this.iAmOtherLevelTrustedUser) return;
      this.requesting_clearRenew = true;
      clearAllRenewLeads()
        .then((r) => {
          this.requesting_clearRenew = false;
          if (r.data.success) {
            showCard(this, 'All Renew Flags Removed', 'success');
          } else {
            showCard(this, 'Error while removing Renew flags', 'warning');
          }
          this.$refs['clear-all-renew-leads-modal'].hide();
          this.updateData();
        })
        .catch((e) => {
          this.requesting_clearRenew = false;
          handleRequestError(this, e, 'Error while removing Renew flags');
        });
    },
    async checkListedActionsCanSendAsM(urlsRequestParams) {
      if (
        !(
          this.isAdmin ||
          (this.user.maff && this.user.canSendMAutomatically) ||
          this.user.canSendCAutomatically
        )
      ) {
        // console.log('checkListedActionsCanSendAsM aborted: No access to m');
        return;
      }
      if (!this.sortedSelectedFields.map((f) => f.key).includes('timesCanSendM')) {
        // console.log(
        //   'checkListedActionsCanSendAsM aborted: No timesCanSendM in sortedSelectedFields'
        // );
        return;
      }
      this.checkingTimesCanSendInProgress = true;
      showCard(this, 'TimesCanSendM check started', 'info');
      const actions = this.items.filter((i) => i.m);
      for (let i = 0; i < actions.length; i += 1) {
        if (this.abortTimesCanSendCheckFlag) {
          this.abortTimesCanSendCheckFlag = false;
          // console.log('TimesCanSendM check aborted');
          showCard(this, 'TimesCanSendM check aborted', 'warning');
          this.checkingTimesCanSendInProgress = false;
          return;
        }
        const action = actions[i];
        const { _id } = action;
        try {
          this.updateItemField(_id, 'timesCanSendM', `checking...`);
          // eslint-disable-next-line no-await-in-loop
          const options = {
            _id,
            ...urlsRequestParams,
          };
          if (this.user.canSendCAutomatically) options.sendCallback = true;
          const r = await checkHowManyTimesCanSendLeadAsM(this.token, options);
          this.updateItemField(
            _id,
            'timesCanSendM',
            r.data.canSend || r.data.canSend === 0 ? r.data.canSend : 'check failed'
          );
        } catch (e) {
          // console.log(`Error checking how many times can send actoin ${_id}`);
          console.error(e);
          this.updateItemField(_id, 'timesCanSendM', `check failed`);
        }
      }
      this.checkingTimesCanSendInProgress = false;
      showCard(this, 'TimesCanSendM check finished', 'success');
    },
    abortTimesCanSendCheck() {
      if (this.checkingTimesCanSendInProgress) {
        this.abortTimesCanSendCheckFlag = true;
      }
    },
    deassignTrader(_id, email) {
      this.$store.commit('make_requesting', { req: true });
      deassignTrader(this.token, { _id })
        .then((r) => {
          if (r.data.success) {
            this.updateItemField(_id, 'trader', '');
            showCard(this, `Trader deassigned from lead ${email}`, 'success');
          } else showCard(this, `Failed to deassign trader from lead ${email}`, 'warning');
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on deassigning trader from lead ${email}`
          );
        });
    },
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    showTypeMoreThan() {
      if (!this.$store.state.requesting) this.updateData();
    },
    $route() {
      if (this.blockRouterChangeWatch) return;
      this.applyDeposits();
      this.applyQueryToForm('appliedFilterForm');
      this.applyQueryToForm('filterForm');
      this.$store.dispatch('get_all_resources_one_request', { vm: this });
      this.updateData();
    },
  },
};
</script>

<style scoped>
.table >>> th,
.table >>> th > *,
.table >>> td,
.table >>> td > * {
  white-space: nowrap !important;
}
.table >>> .card-columns {
  white-space: initial !important;
}
.table >>> .with-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
