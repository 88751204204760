<template>
  <div>
    <b-form>
      <p>Assign to {{ assign === 'trader' ? 'Trader' : 'Affiliate' }} lead {{ email }}</p>
      <b-form-group class="my-1" label="Affiliate" :label-for="`${email}-assign-to-affiliate`">
        <multiselect
          :id="`${email}-assign-to-affiliate`"
          v-model="assignForm.assignToAffiliate"
          track-by="name"
          label="name"
          placeholder="Select one"
          :options="affiliatesOptions"
          :allow-empty="true"
        ></multiselect>
      </b-form-group>

      <b-button class="my-2" variant="primary" size="sm" @click="sendAssignRequest()">
        Assign
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { formValid, isValid } from '@/validators';
import _ from 'lodash';
import { assignToAffiliate, assignToTrader } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import Multiselect from 'vue-multiselect';

export default {
  name: 'AssignToAffiliate',
  components: {
    Multiselect,
  },
  props: {
    _id: { type: String, default: '' },
    email: { type: String, default: '' },
    createdAt: {
      type: String,
      default: '',
    },
    assign: { type: String, default: 'affiliate' }, // 'affiliate'|'trader'
  },
  data() {
    return {
      backdoor: 0,
      assignForm: {
        assignToAffiliate: undefined,
      },
    };
  },
  computed: {
    ...mapState(['user', 'token', 'boxes', 'campaigns', 'affiliates']),
    iAmTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    isValid() {
      const fields = isValid('assignToAffiliate', this.assignForm, this.assignForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    affiliatesOptions() {
      const affs =
        this.assign === 'trader' ? this.affiliates.filter((a) => a.trader) : this.affiliates;
      return affs.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
  },
  methods: {
    sendAssignRequest() {
      if (this.isValid.form) {
        const toSave = _.cloneDeep(this.assignForm);
        this.$store.commit('make_requesting', { req: true });
        toSave.action_id = this._id;
        const assignFunction = this.assign === 'trader' ? assignToTrader : assignToAffiliate;
        assignFunction(this.token, toSave)
          .then((r) => {
            if (r.data.success) {
              showCard(
                this,
                `${this.email} assigned to ${this.assignForm.assignToAffiliate.name}`,
                'success'
              );
              this.$emit('assigned', r.data.assigned);
            } else
              showCard(
                this,
                `Failed to assign ${this.email} to ${this.assignForm.assignToAffiliate.name}`,
                'warning'
              );
          })
          .catch((e) => {
            handleRequestError(
              this,
              e,
              `Unknown server error on assigning lead ${this.email} to ${this.assignForm.assignToAffiliate.name}`
            );
          })
          .finally(() => {
            this.$store.commit('make_requesting', { req: false });
            // this.$emit('assigned');
          });
      }
    },
  },
  created() {
    console.log(`AssignToAffiliate for action ${this._id} created`);
  },
  mounted() {
    console.log(`AssignToAffiliate for action ${this._id} mounted`);
  },
  destroyed() {
    console.log(`AssignToAffiliate for action ${this._id} destroyed`);
  },
};
</script>

<style scoped></style>
