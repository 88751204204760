<template>
  <CreateInjection />
  <!--  </fragment>-->
</template>

<script>
import CreateInjection from '@/components/pages/CreateInjection/CreateInjection/CreateInjection.vue';

export default {
  name: 'CreateInjectionView',
  components: {
    CreateInjection,
  },
};
</script>
