<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">
        {{ _id ? 'Edit' : 'Add' }} PSP
      </h2>
    </div>
    <b-form-group
      class="my-2"
      label="Type"
      label-for="pspform-type"
      :invalid-feedback="invalidFeedback.psp.type"
      :state="isValid.fields.type"
    >
      <b-form-radio-group
        id="pspform-type"
        size="md"
        button-variant="outline-primary"
        v-model="pspForm.type"
        :options="pspTypeClosedOptions"
        name="radios-btn-default"
        buttons
        :disabled="requesting"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      class="my-2"
      label="Number"
      label-for="pspform-number"
      :invalid-feedback="invalidFeedback.psp.number"
      :state="isValid.fields.number"
    >
      <b-form-input
        v-if="pspForm.type === 'card'"
        type="text"
        id="pspform-number"
        v-model="pspForm.number"
        :state="isValid.fields.number"
        :disabled="requesting"
        :formatter="numberFormatter"
      ></b-form-input>
      <multiselect
        v-else
        v-model="pspForm.number"
        :options="pspCryptoProviderOptions"
        :multiple="false"
        :closeOnSelect="true"
      ></multiselect>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Comment"
      label-for="pspform-comment"
      :invalid-feedback="invalidFeedback.psp.comment"
      :state="isValid.fields.comment"
    >
      <b-form-input
        type="text"
        id="pspform-comment"
        v-model="pspForm.comment"
        :state="isValid.fields.comment"
        :disabled="requesting"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="!isAffiliate"
      class="my-2"
      label="Affiliates"
      label-for="pspform-affiliates"
      :state="isValid.fields.affiliates"
    >
      <multiselect
        id="pspform-affiliates"
        v-model="pspForm.affiliates"
        :options="affiliatesOptions"
        track-by="name"
        label="name"
        :closeOnSelect="false"
        :multiple="true"
        :disabled="requesting"
      ></multiselect>
    </b-form-group>

    <b-form-checkbox v-model="pspForm.available" class="my-2">Available</b-form-checkbox>
    <b-form-group
      class="my-2"
      label="FTDs Available"
      label-for="pspform-ftds"
      :state="isValid.fields.ftds"
    >
      <b-form-input id="pspform-ftds" v-model="pspForm.ftds" type="number" min="0"></b-form-input>
    </b-form-group>

    <div class="d-flex justify-content-between mb-3">
      <b-button @click="savePSP()" variant="primary" :disabled="!isValid.form || requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        {{ pspForm._id ? 'Save PSP' : 'Create PSP' }}
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import '@/assets/form.scss';
import { getPSP, savePSP } from '@/utils/api';
import {
  deleteUnchangedFields,
  handleRequestError,
  isAdmin,
  showCard,
  tableFormatterFullCardNumber,
} from '@/utils';
import { validFeedback, invalidFeedback, pspTypeClosedOptions } from '@/consts';
import { isValid, formValid } from '@/validators';
import _ from 'lodash';

const itemInitialValues = {
  _id: undefined,
  type: 'card',
  number: '',
  comment: undefined,
  affiliates: [],
  actions: [],
  available: false,
  ftds: 0,
};

export default {
  name: 'PSPCreate',
  components: {},
  props: {
    _id: { type: String, default: '' },
    action_id: { type: String, default: '' },
    checkAvailability: { type: Boolean, default: false },
  },
  data() {
    return {
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      validFeedback,
      invalidFeedback,
      backdoor: 0,
      pspTypeClosedOptions,
      pspForm: _.cloneDeep(itemInitialValues),
      initialForm: {},
      pspCryptoProviderOptions: [
        'kraken',
        'coinex',
        'kucoin',
        'binance',
        'bitpay',
        'xt',
        'hotbit',
        'gate',
        'huobi',
      ],
    };
  },
  computed: {
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    ...mapState([
      'token',
      'campaigns',
      'affiliates',
      'managers',
      'requesting',
      'user',
      'isAffiliate',
    ]),
    isValid() {
      this.backdoor;
      const fields = isValid('psp', this.pspForm, this.pspForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
  },
  methods: {
    numberFormatter(value) {
      return this.pspForm.type === 'card' ? tableFormatterFullCardNumber(value) : value;
    },
    savePSP() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        let toSave = _.cloneDeep(this.pspForm);
        if (toSave.type === 'card') toSave.number = toSave.number.replace(/\D/g, '');
        toSave.affiliates = toSave.affiliates.map((c) => c._id);
        toSave = deleteUnchangedFields(this.initialForm, this.pspForm, toSave);
        if (this.checkAvailability && this.action_id && this.action_id !== '') {
          toSave.mods = { checkAvailability: this.checkAvailability, action_id: this.action_id };
        }
        savePSP(this.token, toSave)
          .then(async (r) => {
            if (r.status === 200) {
              showCard(this, `${r.data.number} PSP saved`, 'success');
              this.castPSPToForm(r.data);
              if (
                (this.checkAvailability && this.action_id && this.action_id !== '') ||
                (this._id && this._id !== '')
              ) {
                this.$emit('pspSaved', _.cloneDeep(r.data));
              }
              this.backdoor += 1;
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving PSP`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.$store.commit('make_requesting', { req: false });
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving PSP`);
            this.$store.commit('make_requesting', { req: false });
          });
      }
    },
    getPSP(_id) {
      console.log('Getting psp', _id);
      getPSP(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castPSPToForm(r.data);
            this.backdoor += 1;
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting psp`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning', 'Error getting psp');
          }
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error on getting psp', 'Error getting psp');
        });
    },
    castPSPToForm(psp) {
      const initial = _.cloneDeep(itemInitialValues);
      // cast campaign to edit form
      // prettier-ignore
      this.pspForm = {
        _id: psp._id ? psp._id : initial.id,
        affiliates: psp.affiliates.filter(a => a).map((a) => {
          return {_id: a._id ? a._id : a, name: a.name ? a.name : 'unknown' };
        }),
        number:psp.number? psp.number : initial.number,
        comment:psp.comment? psp.comment : initial.comment,
        type:psp.type? psp.type : initial.type,
        available:psp.available? psp.available : initial.available,
      };
      this.pspForm.number = this.numberFormatter(this.pspForm.number);
      this.initialForm = _.cloneDeep(this.pspForm);
    },
    async updateData() {
      // await store.dispatch('get_all_resources_one_request', { vm: this });
      if (!this.pspForm._id && !isAdmin(this.user)) {
        this.pspForm.affiliates = [{ name: this.user.name, _id: this.user._id }];
      }
    },
  },
  mounted() {
    this.updateData();
    if (this._id && this._id !== '') this.getPSP(this._id);
    else {
      this.pspForm = _.cloneDeep(itemInitialValues);
      if (!isAdmin(this.user))
        this.pspForm.affiliates = [{ name: this.user.name, _id: this.user._id }];
      this.initialForm = {};
    }
  },
  watch: {},
};
</script>
