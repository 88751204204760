<template>
  <b-container fluid>
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon icon="search"></b-icon>
      </b-input-group-prepend>
      <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Type to Search"
      ></b-form-input>
      <b-input-group-append>
        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <p>FinalReceivers: {{ filteredFinalReceiverGroups.length }}</p>
    <div class="my-2">
      <h4>Showmode</h4>
      <b-form-radio-group v-model="showmode" :options="showmodeOptions"></b-form-radio-group>
    </div>
    <ol
      v-if="
        filteredFinalReceiverGroups.length > 0 &&
        OTHER_LEVEL_TRUSTED_USERS.includes(user.email) &&
        showmode === 'list'
      "
    >
      <li v-for="(frg, i) in filteredFinalReceiverGroups.filter((frg) => !frg.alertlist)" :key="i">
        {{ frg.finalReceiverHostname }}
      </li>
    </ol>
    <b-card-group
      columns
      v-if="
        filteredFinalReceiverGroups.length > 0 &&
        OTHER_LEVEL_TRUSTED_USERS.includes(user.email) &&
        showmode === 'dashboard'
      "
    >
      <b-card
        v-for="(frg, i) in filteredFinalReceiverGroups"
        :key="i"
        target="_blank"
        :bg-variant="
          frg.campaignApiHostnames &&
          frg.campaignApiHostnames.map((cah) => cah.hostname).includes(campaignUrl.trim())
            ? 'success'
            : ''
        "
      >
        <b-card-title>
          {{ frg.finalReceiverHostname }}
          <span class="alertlist_area">
            <!--            <b-badge-->
            <!--              :variant="frg.alertlist ? 'danger' : 'primary'"-->
            <!--              class="mx-1 badge-toogle"-->
            <!--              @click="alertlist_final_receiver(frg._id)"-->
            <!--            >-->
            <b-badge
              :variant="
                frg.alertlevel === 2 ? 'danger' : frg.alertlevel === 1 ? 'warning' : 'success'
              "
              class="mx-1"
            >
              <!--              {{ frg.alertlist ? 'ALERT' : 'OK' }}-->
              {{ frg.alertlevel === 2 ? 'DANGER' : frg.alertlevel === 1 ? 'WARNING' : 'OK' }}
            </b-badge>

            <b-dropdown dropleft no-caret size="sm" lazy>
              <template #button-content size="sm">
                <b-icon icon="alert-circle-fill"></b-icon>
              </template>
              <b-dropdown-header>Alert Level</b-dropdown-header>
              <b-form-group>
                <b-form-radio-group
                  v-model="frg.alertlevel"
                  @change="setFinalReceiverAlertLevel(frg._id, $event)"
                >
                  <b-form-radio :value="0">OK</b-form-radio>
                  <b-form-radio :value="1">Warning</b-form-radio>
                  <b-form-radio :value="2">Danger</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-dropdown>
          </span>
          <span>
            <FinalReceiverRegulatedIndicator
              :_id="frg.id"
              :hostname="frg.finalReceiverHostname"
              :regulated="frg.regulated"
              @changed="handle_toggle_final_receiver_regulated"
            />
          </span>
        </b-card-title>
        <b-card-text>
          <ul>
            <li v-for="(cah, j) in frg.campaignApiHostnames" :key="j">
              {{ cah.hostname }}: {{ cah.lastTimeSeen }}
            </li>
          </ul>
          <p>{{ frg.geos ? frg.geos.join(', ') : 'no geos' }}</p>
          <div v-if="editingComment === frg._id">
            <b-form-textarea
              class="my-2"
              :id="`textarea-${frg._id}`"
              v-model="frg.comment"
              placeholder="Comment"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-button @click="comment_final_receiver(frg._id, frg.comment)" variant="success">
              Save Comment
            </b-button>
            <b-button @click="start_edit_comment('')" variant="danger">Cancel</b-button>
          </div>
          <div v-else>
            <h5>Comment</h5>
            <p>{{ frg.comment }}</p>
            <b-button @click="start_edit_comment(frg._id)" variant="primary">Edit Comment</b-button>
          </div>
        </b-card-text>
      </b-card>
    </b-card-group>
    <div class="m-3">
      <p>Get all campaign urls connected to given campaignURL</p>
      <b-input v-model="campaignUrl"></b-input>
      <b-checkbox v-model="useTTL" class="m-2">Use TTL</b-checkbox>
      <b-button @click="getCampaignUrlsConnectedToGiven()" variant="primary" class="mx-1">
        Get CampaignUrls
      </b-button>
      <b-button @click="clearCampaignUrlsConnectedToGiven()" class="mx-1">
        Clear Campaign Urls Connected To Given
      </b-button>
      <ul>
        <li v-for="(url, k) in connectedCampaignUrls" :key="k">{{ url }}</li>
      </ul>
    </div>
    <div class="m-3">
      <p class="mt-3">Get all campaign urls connected to given action details</p>
      <b-form-group class="my-1" label="email" label-for="email">
        <b-input v-model="actionDetails.email" id="email"></b-input>
      </b-form-group>
      <b-form-group class="my-1" label="phone" label-for="phone">
        <b-input v-model="actionDetails.phone" id="phone"></b-input>
      </b-form-group>
      <b-form-group class="my-1" label="ip" label-for="ip">
        <b-input v-model="actionDetails.ip" id="ip"></b-input>
      </b-form-group>
      <b-checkbox v-model="useTTL" class="m-2">Use TTL</b-checkbox>
      <b-button @click="getCampaignUrlsByActionDetails()" variant="primary" class="mx-1">
        Get Connected by Action Details
      </b-button>
      <b-button
        @click="getCampaignUrlsNotConnectedByActionDetails()"
        variant="success"
        class="mx-1"
      >
        Get NOT Connected by Action Details
      </b-button>
      <b-button @click="clearCampaignUrlsByActionDetails()" class="mx-1">
        Clear Campaign Urls By Action Details
      </b-button>
      <p class="my-1">Connected</p>
      <ul>
        <li v-for="(url, k) in connectedCampaignUrlsByActionDetails" :key="k">{{ url }}</li>
      </ul>
      <p class="my-1">NOT Connected</p>
      <ul>
        <li v-for="(url, k) in notConnectedCampaignUrlsByActionDetails" :key="k">{{ url }}</li>
      </ul>
    </div>
    <div class="m-3">
      <p class="mt-3">Get all campaign urls connected to given action id</p>
      <b-form-group class="my-1" label="actionId" label-for="actionId">
        <b-input v-model="actionId"></b-input>
      </b-form-group>
      <b-button @click="getCampaignUrlsByActionId()" variant="primary" class="mx-1">
        Get by Action Id
      </b-button>
      <b-button @click="getCampaignUrlsNotConnectedByActionId()" variant="success" class="mx-1">
        Get NOT Connected by Action Id
      </b-button>
      <b-button @click="clearCampaignUrlsByActionId()" class="mx-1">
        Clear Campaign Urls By Action Id
      </b-button>
      <p class="my-1">Connected</p>
      <ul>
        <li v-for="(url, k) in connectedCampaignUrlsByActionId" :key="k">{{ url }}</li>
      </ul>
      <p class="my-1">NOT Connected</p>
      <ul>
        <li v-for="(url, k) in notConnectedCampaignUrlsByActionId" :key="k">{{ url }}</li>
      </ul>
    </div>
  </b-container>
</template>

<script>
import '@/assets/badge-toogle.css';
import { mapState } from 'vuex';
import {
  getFinalReceivers,
  alertlistFinalReceiver,
  commentFinalReceiver,
  getCampaignUrlsByFinalReceiver,
  getCampaignUrlsByActionDetails,
  getCampaignUrlsByActionId,
  getCampaignUrlsNotConnectedByActionDetails,
  getCampaignUrlsNotConnectedByActionId,
  setFinalReceiverAlertLevel,
} from '@/utils/api';
import { handleRequestError, showCard } from '@/utils/index';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import FinalReceiverRegulatedIndicator from '@/components/shared/FinalReceiverRegulatedIndicator.vue';

export default {
  name: 'FinalReceivers',
  components: { FinalReceiverRegulatedIndicator },
  data() {
    return {
      editingComment: '',
      filter: '',
      finalReceiverGroups: [],
      OTHER_LEVEL_TRUSTED_USERS,
      campaignUrl: '',
      connectedCampaignUrls: [],
      connectedCampaignUrlsByActionDetails: [],
      connectedCampaignUrlsByActionId: [],
      notConnectedCampaignUrlsByActionDetails: [],
      notConnectedCampaignUrlsByActionId: [],
      backdoor: 0,
      actionDetails: { email: '', ip: '', phone: '' },
      actionId: '',
      useTTL: false,
      showmode: 'dashboard',
      showmodeOptions: ['dashboard', 'list'],
    };
  },
  computed: {
    ...mapState([
      'user',
      'token',
      'campaigns',
      'actions',
      'boxes',
      'requesting',
      'managers',
      'isAdmin',
      'isManager',
    ]),
    filteredFinalReceiverGroups() {
      this.backdoor;
      this.campaignUrl;
      return this.finalReceiverGroups
        .map((frg) => {
          return frg
            ? {
                ...frg,
                variant:
                  frg.campaignApiHostnames &&
                  (frg.campaignApiHostnames
                    .map((cah) => cah.hostname)
                    .includes(this.campaignUrl.trim()) ||
                    this.connectedCampaignUrlsByActionDetails.some((url) =>
                      frg.campaignApiHostnames.map((cah) => cah.hostname).includes(url)
                    ) ||
                    this.connectedCampaignUrlsByActionId.some((url) =>
                      frg.campaignApiHostnames.map((cah) => cah.hostname).includes(url)
                    ))
                    ? 'success'
                    : '',
              }
            : {};
        })
        .filter((frg) => {
          const formatFilter = this.filter.trim().toLowerCase();
          return (
            formatFilter === '' ||
            frg.finalReceiverHostname.toLowerCase().includes(formatFilter) ||
            frg.campaignApiHostnames
              .map((cah) => cah.hostname)
              .some((c) => c.toLowerCase().includes(formatFilter)) ||
            frg.geos.some((g) => g.toLowerCase().includes(formatFilter))
          );
        });
    },
  },
  methods: {
    setFinalReceiverAlertLevel(_id, alertlevel) {
      setFinalReceiverAlertLevel(this.token, { _id, alertlevel })
        .then((r) => {
          if (r.data.success) {
            const i = this.finalReceiverGroups.findIndex((frg) => frg._id === _id);
            this.finalReceiverGroups[i].alertlevel = r.data.alertlevel;
          } else {
            handleRequestError(this, r.data, 'Error changing FinalReceiver alert level');
          }
        })
        .catch((e) => handleRequestError(this, e, 'Error changing FinalReceiver alert level'));
    },
    handle_toggle_final_receiver_regulated(data) {
      for (let i = 0; i < data.affectedByChange.length; i += 1) {
        const frg = data.affectedByChange[i];
        const afrg = this.finalReceiverGroups.find((g) => g._id === frg._id);
        if (afrg) {
          afrg.regulated = frg.regulated;
        }
      }
      this.backdoor += 1;
    },
    alertlist_final_receiver(_id) {
      alertlistFinalReceiver(this.token, { _id })
        .then((r) => {
          if (r.data.success) {
            const i = this.finalReceiverGroups.findIndex((frg) => frg._id === _id);
            console.log('alertlist i', i);
            if (i >= 0) {
              this.finalReceiverGroups[i].alertlist = r.data.alertlist;
              this.backdoor += 1;
            }
          } else {
            handleRequestError(
              this,
              r.data,
              `Error changing Alert status of FinalReceiverGroup ${_id}`
            );
          }
        })
        .catch((e) => {
          handleRequestError(this, e, `Error changing Alert status of FinalReceiverGroup ${_id}`);
        });
    },
    comment_final_receiver(_id, comment) {
      commentFinalReceiver(this.token, { _id, comment })
        .then((r) => {
          if (r.data.success) {
            const i = this.finalReceiverGroups.findIndex((frg) => frg._id === _id);
            console.log('alertlist i', i);
            if (i >= 0) {
              this.finalReceiverGroups[i].comment = r.data.comment;
              this.backdoor += 1;
            }
          } else {
            handleRequestError(this, r.data, `Error changing comment of FinalReceiverGroup ${_id}`);
          }
          this.start_edit_comment('');
        })
        .catch((e) => {
          handleRequestError(this, e, `Error changing comment of FinalReceiverGroup ${_id}`);
        });
    },
    start_edit_comment(_id) {
      this.editingComment = _id;
    },
    getFinalReceivers() {
      getFinalReceivers(this.token)
        .then((r) => {
          this.finalReceiverGroups = r.data;
          showCard(
            this,
            'Got final receiver groups',
            'success',
            undefined,
            'b-toaster-bottom-right'
          );
        })
        .catch((e) => handleRequestError(this, e, 'Server error on getting final receiver groups'));
    },
    getCampaignUrlsConnectedToGiven() {
      getCampaignUrlsByFinalReceiver(this.token, {
        campaignUrl: this.campaignUrl.trim(),
        useTTL: this.useTTL,
      })
        .then((r) => {
          this.connectedCampaignUrls = r.data.urls;
        })
        .catch((e) =>
          handleRequestError(this, e, 'Server error on getting campaign urls connected to given')
        );
    },
    clearCampaignUrlsConnectedToGiven() {
      this.connectedCampaignUrls = [];
    },
    getCampaignUrlsByActionDetails() {
      const { email, phone, ip } = this.actionDetails;
      getCampaignUrlsByActionDetails(this.token, {
        email: email.trim(),
        phone: phone.trim(),
        ip: ip.trim(),
        useTTL: this.useTTL,
      })
        .then((r) => {
          this.connectedCampaignUrlsByActionDetails = r.data.urls;
        })
        .catch((e) =>
          handleRequestError(this, e, 'Server error on getting campaign urls connected to given')
        );
    },
    getCampaignUrlsNotConnectedByActionDetails() {
      const { email, phone, ip } = this.actionDetails;
      getCampaignUrlsNotConnectedByActionDetails(this.token, {
        email: email.trim(),
        phone: phone.trim(),
        ip: ip.trim(),
        useTTL: this.useTTL,
      })
        .then((r) => {
          this.notConnectedCampaignUrlsByActionDetails = r.data.urls;
        })
        .catch((e) =>
          handleRequestError(this, e, 'Server error on getting campaign urls connected to given')
        );
    },
    clearCampaignUrlsByActionDetails() {
      this.connectedCampaignUrlsByActionDetails = [];
    },
    getCampaignUrlsByActionId() {
      getCampaignUrlsByActionId(this.token, {
        _id: this.actionId,
        useTTL: this.useTTL,
      })
        .then((r) => {
          this.connectedCampaignUrlsByActionId = r.data.urls;
        })
        .catch((e) =>
          handleRequestError(this, e, 'Server error on getting campaign urls connected to given')
        );
    },
    getCampaignUrlsNotConnectedByActionId() {
      getCampaignUrlsNotConnectedByActionId(this.token, {
        _id: this.actionId,
        useTTL: this.useTTL,
      })
        .then((r) => {
          this.notConnectedCampaignUrlsByActionId = r.data.urls;
        })
        .catch((e) =>
          handleRequestError(this, e, 'Server error on getting campaign urls connected to given')
        );
    },
    clearCampaignUrlsByActionId() {
      this.connectedCampaignUrlsByActionId = [];
    },
  },
  mounted() {
    this.getFinalReceivers();
  },
  watch: {
    campaignUrl() {
      // this.backdoor += 1;
    },
  },
};
</script>

<style scoped>
.alertlist_area {
  margin-left: auto;
  margin-right: 0;
}
</style>
