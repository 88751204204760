<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h5 class="mr-3" style="display: inline; vertical-align: middle">
        Edit FTDs amount for PSP
        <br />
        {{ number }}
      </h5>
    </div>
    <b-form-group class="my-2" label="FTDs Available" label-for="pspftds-amount">
      <b-form-input
        type="number"
        min="0"
        id="pspftds-amount"
        v-model="ftds"
        :disabled="requesting"
        :formatter="numberFormatter"
      ></b-form-input>
    </b-form-group>

    <div class="d-flex justify-content-between mb-3">
      <b-button @click="changeFtdsAvailableAmount()" variant="primary" :disabled="requesting">
        <b-spinner small v-if="requesting" class="mx-1"></b-spinner>
        Save
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { changeFtdsAvailableAmount, getPSP } from '@/utils/api';
import { handleRequestError, showCard, tableFormatterFullCardNumber } from '@/utils';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  name: 'PSPEditFtdsAmount',
  props: {
    _id: { type: String, default: '' },
  },
  data() {
    return { requesting: false, available: false, ftds: 0, number: '' };
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    changeFtdsAvailableAmount() {
      this.requesting = true;
      changeFtdsAvailableAmount(this.token, { ftds: this.ftds, psp_id: this._id })
        .then((r) => {
          if (r.status === 200 && r.data.success) {
            showCard(
              this,
              `${this.number} PSP saved Available FTDs amount: ${r.data.ftds};
            ${
              r.data.available
                ? 'PSP is Available'
                : 'PSP is not available. Mark it as Available if this is a mistake'
            }`,
              'success'
            );
            this.castDataToForm(r.data);
            this.$emit('pspFtdsSaved', _.cloneDeep(r.data));
          } else {
            const cardError =
              r.data.message ||
              `${r.status}: Unknown error on saving FTDs Available for PSP ${this._id}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown error on saving FTDs Available for PSP ${this._id}`);
          this.requesting = false;
        });
    },
    numberFormatter(type, value) {
      return type === 'card' ? tableFormatterFullCardNumber(value) : value;
    },
    castDataToForm(data) {
      this.ftds = data.ftds;
      this.available = data.available;
      if (data.number && data.type) {
        this.number = this.numberFormatter(data.type, data.number);
      }
    },
    getPSP(_id) {
      console.log('Getting psp', _id);
      getPSP(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castDataToForm(r.data);
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting psp`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning', 'Error getting psp');
          }
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error on getting psp', 'Error getting psp');
        });
    },
  },
  mounted() {
    this.getPSP(this._id);
  },
};
</script>

<style scoped></style>
