import { USER_TOKEN } from '@/consts';

export default function getAuthHeader(t = undefined) {
  const token = t ?? localStorage.getItem(USER_TOKEN);
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }

  return {};
}
