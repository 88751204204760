import Vue from 'vue';
import Vuex from 'vuex';
import { initialBoxForm, USER_TOKEN, RENT_ONLY_EMAILS, OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import {
  getDecodedToken,
  isAdmin,
  isAffiliate,
  isMasterAffiliate,
  isManager,
  showCard,
  // showCard,
  // getAuthHeader,
  // showCard,
  config,
  validateBoolean,
} from '@/utils';
import {
  getMe,
  getAllResources,
  getInjections,
  getCampaigns,
  getUsers,
  getActions,
  getBoxes,
  getManagers,
  getAffiliates,
  getFinalReceiversHostnames,
} from '@/utils/api';
import _ from 'lodash';
import { getItems } from './utils';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    store_backdoor: 0,
    serverUrl: process.env.VUE_APP_API_URL,
    // serverUrl: 'http://localhost:5000',
    user: undefined,
    isAdmin: false,
    isMasterAffiliate: false,
    isAffiliate: false,
    isManager: false,
    token: undefined,
    loggedIn: false,
    identity: {},
    requesting: false,
    // requesting_resources: {
    //   campaigns: false,
    //   boxes: false,
    //   managers: false,
    //   affiliates: false,
    //   users: false,
    //   actions: false,
    // },
    campaigns: [],
    users: [],
    actions: [],
    boxes: [],
    managers: [],
    affiliates: [],
    injections: [],
    db_defaultBCESettings: [],
    savedBoxForm: _.cloneDeep(initialBoxForm),
  },
  mutations: {
    checkLogin(state) {
      console.log('Store checking login');
      if (!state.token) {
        state.token = localStorage.getItem(USER_TOKEN);
      }
      // console.log(USER_TOKEN, state.token);
      const tokenDecoded = getDecodedToken(state.token);
      // console.log(tokenDecoded);
      const tokenExpired = Date.now() >= tokenDecoded.exp * 1000;
      // console.log(`Token expired: ${tokenExpired}`);
      if (tokenExpired || !state.token) {
        state.user = undefined;
        state.token = undefined;
        state.loggedIn = false;
        state.identity = {};
        state.isAdmin = false;
        state.isMasterAffiliate = false;
        state.isAffiliate = false;
        state.isManager = false;
        // console.log('No login');
      } else {
        state.loggedIn = true;
        state.identity = tokenDecoded;
        // console.log(tokenDecoded);
        // console.log('Identity: ', tokenDecoded);
      }
      // eslint-disable-next-line
      // console.log(`Store finished checking login. state.loggedIn: ${state.loggedIn}; state.identity: ${state.identity}`);
    },
    authenticate(state, { user }) {
      console.log('Authenticating user');
      // console.log(user, USER_TOKEN, user.token);
      localStorage.setItem(USER_TOKEN, user.token);
      // console.log('Storage item set: ', USER_TOKEN, localStorage.getItem(USER_TOKEN));
      // console.log(user);
      state.user = user;
      state.isAdmin = isAdmin(user);
      state.isMasterAffiliate = isMasterAffiliate(user);
      state.isAffiliate = isAffiliate(user);
      state.isManager = isManager(user);
      state.loggedIn = true;
      state.token = user.token;
      state.identity = getDecodedToken(state.token).identity;
      if (RENT_ONLY_EMAILS.includes(user.email) && !validateBoolean(process.env.VUE_APP_RENT)) {
        // console.log('Vue.prototype.$rent before', Vue.prototype.$rent);
        config.rent = true;
        Vue.prototype.$rent = true;
        // console.log('Vue.prototype.$rent after', Vue.prototype.$rent);
      } else {
        config.rent = validateBoolean(process.env.VUE_APP_RENT);
        Vue.prototype.$rent = validateBoolean(process.env.VUE_APP_RENT);
      }
      console.log('Authenticated');
      // console.log(state.user, state.loggedIn, state.token, state.identity);
    },
    logout(state) {
      localStorage.removeItem(USER_TOKEN);
      state.user = undefined;
      state.isAdmin = false;
      state.isAffiliate = false;
      state.isManager = false;
      state.token = undefined;
      state.loggedIn = false;
      state.identity = {};
    },
    make_requesting(state, { req }) {
      if (state.requesting !== req) state.requesting = req;
    },
    // make_requesting_resources(state, { res, req }) {
    //   if (Object.keys(state.requesting_resources).includes(res)) {
    //     state.requesting_resources[res] = req;
    //   }
    // },
    put_user(state, { user }) {
      // console.log('Commiting user', user);
      state.user = user;
      state.token = user.token;
      state.isAdmin = isAdmin(user);
      state.isMasterAffiliate = isMasterAffiliate(user);
      state.isAffiliate = isAffiliate(user);
      state.isManager = isManager(user);
      if (RENT_ONLY_EMAILS.includes(user.email) && !validateBoolean(process.env.VUE_APP_RENT)) {
        // console.log('Vue.prototype.$rent before', Vue.prototype.$rent);
        config.rent = true;
        Vue.prototype.$rent = true;
        // console.log('Vue.prototype.$rent after', Vue.prototype.$rent);
      } else {
        config.rent = validateBoolean(process.env.VUE_APP_RENT);
        Vue.prototype.$rent = validateBoolean(process.env.VUE_APP_RENT);
      }
      // console.log(state.isAffiliate, state.isManager, state.isAdmin);
    },
    put_items(state, { index, items }) {
      // console.log(`Commiting ${index}`, items);
      state[index] = items;
    },
    update_item_by_id(state, { index, item }) {
      if (Array.isArray(state[index])) {
        let ita = state[index].findIndex((i) => i._id && i._id === item._id);
        if (ita >= 0) state[index][ita] = _.cloneDeep(item);
        else {
          state[index].push(_.cloneDeep(item));
          ita = state[index].length - 1;
        }
      }
      state.store_backdoor += 1;
    },
    // save_box_form(state, boxForm) {
    //   console.log('saving box form', boxForm);
    //   state.savedBoxForm = _.cloneDeep(boxForm);
    // },
  },
  actions: {
    clearStorage() {
      localStorage.clear();
    },
    checkLogin({ commit }) {
      commit('checkLogin');
    },
    authenticate({ commit }, user) {
      // console.log(user);
      commit('authenticate', { user });
    },
    logout({ commit }) {
      commit('logout');
    },
    make_requesting({ commit }, r) {
      commit('make_requesting', { req: r });
    },
    put_user({ commit }, user) {
      // console.log('Dispatching user', user);
      // commit('make_requesting', { req: true });
      commit('put_user', { user });
    },
    put_items({ commit }, index, items) {
      // console.log(`Dispatching ${index}`, items);
      // commit('make_requesting', { req: true });
      commit('put_items', { index, items });
    },
    async get_me({ commit, state }) {
      if (!state.token) {
        state.token = localStorage.getItem(USER_TOKEN);
      }
      try {
        const r = await getMe(state.token);
        console.log('Got me');
        // console.log(r);
        if (r.status === 200 && !r.data.error) {
          const user = r.data;
          commit('put_user', { user });
        } else {
          console.error('Error getting me: ', r.status, r.data);
          commit('logout');
        }
      } catch (e) {
        console.error('Error getting me in store: ', e);
        commit('logout');
        throw e;
      }
    },
    async get_injections({ commit, state }, { vm }) {
      await getItems(commit, state, vm, 'injections', getInjections);
    },

    async get_campaigns({ commit, state }, { vm }) {
      await getItems(commit, state, vm, 'campaigns', getCampaigns);

      // commit('make_requesting', { req: true });
      // if (!state.token) {
      //   state.token = localStorage.getItem(USER_TOKEN);
      // }
      // try {
      //   const r = await getCampaigns(state.token);
      //   console.log('Got campaigns, ');
      //   console.log(r);
      //   if (r.status === 200 && !r.data.error) {
      //     const campaigns = r.data;
      //     commit('put_items', { index: 'campaigns', items: campaigns });
      //   } else {
      //     commit('put_items', { index: 'campaigns', items: [] });
      //     console.error('Error getting campaigns: ', r.status, r.data);
      //
      //     const cardError = r.data.error || `${r.status}: Unknown error on getting campaigns`;
      //     showCard(vm, cardError, r.status === 500 ? 'danger' : 'warning');
      //
      //     // commit('logout');
      //   }
      //   commit('make_requesting', { req: false });
      // } catch (e) {
      //   commit('put_items', { index: 'campaigns', items: [] });
      //   commit('make_requesting', { req: false });
      //   console.error('Error getting campaigns in store: ', e);
      //   // commit('logout');
      //   throw e;
      // }
    },
    async get_users({ commit, state }, { vm }) {
      await getItems(commit, state, vm, 'users', getUsers);
      // commit('make_requesting', { req: true });
      // if (!state.token) {
      //   state.token = localStorage.getItem(USER_TOKEN);
      // }
      // try {
      //   const r = await getAffiliates(state.token);
      //   console.log('Got campaigns, ');
      //   console.log(r);
      //   if (r.status === 200 && !r.data.error) {
      //     const affiliates = r.data;
      //     commit('put_items', { index: 'affiliates', items: affiliates });
      //   } else {
      //     commit('put_items', { index: 'affiliates', items: [] });
      //     console.error('Error getting affiliates: ', r.status, r.data);
      //
      //     const cardError = r.data.error || `${r.status}: Unknown error on getting affiliates`;
      //     showCard(vm, cardError, r.status === 500 ? 'danger' : 'warning');
      //     // commit('logout');
      //   }
      //   commit('make_requesting', { req: false });
      // } catch (e) {
      //   commit('put_items', { index: 'affiliates', items: [] });
      //   console.error('Error getting affiliates in store: ', e);
      //
      //   const cardError = e || { message: 'Unknown server error on getting affiliates' };
      //   showCard(this, cardError.message, 'danger');
      //   // commit('logout');
      //   throw e;
      // }
    },
    async get_actions({ commit, state }, { vm }) {
      await getItems(commit, state, vm, 'actions', getActions);
      // commit('make_requesting', { req: true });
      // if (!state.token) {
      //   state.token = localStorage.getItem(USER_TOKEN);
      // }
      // try {
      //   const r = await getActions(state.token);
      //   console.log('Got campaigns, ');
      //   console.log(r);
      //   if (r.status === 200 && !r.data.error) {
      //     const actions = r.data;
      //     commit('put_items', { index: 'actions', items: actions });
      //   } else {
      //     commit('put_items', { index: 'actions', items: [] });
      //     console.error('Error getting campaigns: ', r.status, r.data);
      //
      //     const cardError = r.data.error || `${r.status}: Unknown error on getting actions`;
      //     showCard(vm, cardError, r.status === 500 ? 'danger' : 'warning');
      //     // commit('logout');
      //   }
      //   commit('make_requesting', { req: false });
      // } catch (e) {
      //   commit('put_items', { index: 'actions', items: [] });
      //   commit('make_requesting', { req: false });
      //   console.error('Error getting actions in store: ', e);
      //
      //   const cardError = e || { message: 'Unknown server error on getting actions' };
      //   showCard(this, cardError.message, 'danger');
      //   // commit('logout');
      //   throw e;
      // }
    },
    async get_boxes({ commit, state }, { vm }) {
      await getItems(commit, state, vm, 'boxes', getBoxes);
    },
    async get_managers({ commit, state }, { vm }) {
      await getItems(commit, state, vm, 'managers', getManagers);
    },
    async get_affiliates({ commit, state }, { vm }) {
      await getItems(commit, state, vm, 'affiliates', getAffiliates);
    },
    async get_all_resources({ commit, dispatch }, { vm }) {
      commit('make_requesting', { req: true });
      await dispatch('get_users', { vm }, false);
      await dispatch('get_managers', { vm }, false);
      await dispatch('get_affiliates', { vm }, false);
      await dispatch('get_campaigns', { vm }, false);
      await dispatch('get_boxes', { vm }, false);
      await dispatch('get_actions', { vm }, false);
      await dispatch('get_injections', { vm }, false);
      commit('make_requesting', { req: false });
    },
    async get_all_resources_one_request({ commit, state }, { vm }) {
      commit('make_requesting', { req: true });
      if (!state.token) {
        state.token = localStorage.getItem(USER_TOKEN);
      }
      try {
        console.log('state.user.email', state.user.email);
        let frRequest;
        if (OTHER_LEVEL_TRUSTED_USERS.includes(state.user.email)) {
          frRequest = getFinalReceiversHostnames(state.token)
            .then((r) => {
              const items = r.data;
              // console.log('getFinalReceiversHostnames results', items.length, items);
              commit('put_items', { index: 'finalReceivers', items: items || [] });
            })
            // eslint-disable-next-line no-unused-vars
            .catch((e) => {
              // console.log('getFinalReceiversHostnames error');
              // console.error(e);
              commit('put_items', { index: 'finalReceivers', items: [] });
            });
        }
        const r = await getAllResources(state.token);
        console.log('Got resources');
        // console.log(r);
        // prettier-ignore
        if (r.status === 200 && !r.data.error) {
          const items = r.data;
          // commit('put_user', { user: items.me });
          commit('put_items', { index: 'users', items: items.users ? items.users : [] });
          commit('put_items', { index: 'affiliates', items: items.affiliates ? items.affiliates : [] });
          commit('put_items', { index: 'managers', items: items.managers ? items.managers : [] });
          commit('put_items', { index: 'campaigns', items: items.campaigns ? items.campaigns : [] });
          commit('put_items', { index: 'boxes', items: items.boxes ? items.boxes : [] });
          // commit('put_items', { index: 'actions', items: items.actions ? items.actions : [] });
          commit('put_items', { index: 'injections', items: items.injections ? items.injections : [] });
          commit('put_items', { index: 'db_defaultBCESettings', items: items.db_defaultBCESettings ? items.db_defaultBCESettings : [] });
        } else {
          commit('put_items', { index: 'users', items: [] });
          commit('put_items', { index: 'affiliates', items: [] });
          commit('put_items', { index: 'managers', items: [] });
          commit('put_items', { index: 'campaigns', items: [] });
          commit('put_items', { index: 'boxes', items: [] });
          // commit('put_items', { index: 'actions', items: [] });
          commit('put_items', { index: 'injections', items: [] });
          commit('put_items', { index: 'db_defaultBCESettings', items: [] });
          console.error(`Error getting resources: `, r.status, r.data);

          const cardError = r.data.error || `${r.status}: Unknown error on getting resources`;
          showCard(vm, cardError, r.status === 500 ? 'danger' : 'warning');
          // commit('logout');
        }
        if (frRequest) {
          await frRequest;
        }
      } catch (e) {
        commit('put_items', { index: 'users', items: [] });
        commit('put_items', { index: 'affiliates', items: [] });
        commit('put_items', { index: 'managers', items: [] });
        commit('put_items', { index: 'campaigns', items: [] });
        commit('put_items', { index: 'boxes', items: [] });
        // commit('put_items', { index: 'actions', items: [] });
        commit('put_items', { index: 'injections', items: [] });
        commit('put_items', { index: 'db_defaultBCESettings', items: [] });
        console.error(`Error getting resources in store: `, e);

        const cardError = e || { message: `Unknown server error on getting resources` };
        showCard(vm, cardError.message, 'danger', `Error getting resources in store`);
        // commit('logout');
        throw e;
      }
      commit('make_requesting', { req: false });
    },
  },
  modules: {},
});

export default store;
