<template>
  <CreateCampaign />
</template>

<script>
import CreateCampaign from '@/components/pages/CreateCampaign/CreateCampaign/CreateCampaign.vue';

export default {
  name: 'CreateCampaignView',
  components: {
    CreateCampaign,
  },
};
</script>
