<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h5 class="mx-1 my-2">{{ name }}</h5>
    <b-form-group
      class="my-3"
      label="Format"
      label-for="sendlead-endpoint"
      :state="isValid.fields.Format"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"-->
      <b-form-input
        type="text"
        id="datesettings-format"
        v-model="form.Format"
        @input="emitEditEvent"
        :state="isValid.fields.Format"
      ></b-form-input>
    </b-form-group>
    <b-form-checkbox
      class="my-2"
      v-model="form.DateToIsNowPlus1Day"
      @input="emitEditEvent"
      :state="isValid.fields.DateToIsNowPlus1Day"
    >
      DateTo Is Now Plus 1 Day
    </b-form-checkbox>
    <b-form-group
      class="my-3"
      label="Days Interval"
      label-for="datesettings-daysinterval"
      :state="isValid.fields.DaysInterval"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name" -->
      <b-form-input
        type="number"
        id="datesettings-daysinterval"
        v-model="form.DaysInterval"
        @input="emitEditEvent"
        :state="isValid.fields.DaysInterval"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="my-3"
      label="'From' Field"
      label-for="datesettings-fromfield"
      :state="isValid.fields.fromField"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name" -->
      <b-form-input
        type="text"
        id="datesettings-fromfield"
        v-model="form.fromField"
        @input="emitEditEvent"
        :state="isValid.fields.fromField"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      class="my-3"
      label="'To' Field"
      label-for="datesettings-tofield"
      :state="isValid.fields.toField"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"-->
      <b-form-input
        type="text"
        id="datesettings-tofield"
        v-model="form.toField"
        @input="emitEditEvent"
        :state="isValid.fields.toField"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
// import handleValidateEvent from '@/utils/handleValidateEvent';
// import handleEditEvent from '@/utils/handleEditEvent';
// import handleMultipleKeysEditEvent from '@/utils/handleMultipleKeysEditEvent';
import { mapPropFieldToValid } from '@/utils';
import { formValid, isValid } from '@/validators';

const initial = {
  Format: '',
  DateToIsNowPlus1Day: false,
  DaysInterval: 15,
  fromField: '',
  toField: '',
};

export default {
  name: 'PullingFromToDateSettings',
  props: {
    name: { type: String, default: 'FromTo Date Settings' },
    FromToDate: {
      Format: { type: String, default: '' },
      DateToIsNowPlus1Day: { type: Boolean, default: false },
      DaysInterval: { type: Number, default: 15 },
      fromField: { type: String, default: '' },
      toField: { type: String, default: '' },
    },
  },
  data() {
    return {
      form: cloneDeep(this.$props.FromToDate ? this.$props.FromToDate : initial),
      valid: mapPropFieldToValid(this.$props.FromToDate, initial),
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = isValid('integration_PullingFromToDateSettings', this.form, this.form);
      const form = formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    // handleEditEvent,
    // handleMultipleKeysEditEvent,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    // handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
