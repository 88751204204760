import _ from 'lodash';
import { countryOptions } from '@/consts';
import { oidRegex, capCheck, nameTest } from '@/validators/_utils';

export function validateHourLimit(c) {
  return Array.isArray(c) && c.length === 2
    ? c.map((d) => {
        return (
          !d ||
          d === '' ||
          d === 0 ||
          (Number(d) < 24 && Number(d) >= 0 && Number.isInteger(Number(d)))
        );
      })
    : [false, false];
}

export default {
  _id: (c) => !c || oidRegex.test(c),
  name: (c) => !c || nameTest(c),
  campaigns: (ce) =>
    ce.map((c) => {
      // console.log('c.campaign', c.campaign);
      // console.log('campaign is empty:', c.campaign === '');
      return {
        campaign: !!c.campaign && c.campaign !== '' && oidRegex.test(c.campaign),
        active: typeof c.active === 'boolean',
        hourLimit: validateHourLimit(c.hourLimit),
        days: Array.isArray(c.days) && c.days.every((d) => Number.isInteger(d)),
        // Array.isArray(c.days) && c.days.length === 7 && c.days.every((d) => typeof d === 'boolean'),
        countries:
          Array.isArray(c.countries) &&
          c.countries.length > 0 &&
          c.countries.every((d) => countryOptions.some((co) => _.isEqual(co, d))),
        priority: !!c.priority && !Number.isNaN(Number(c.priority)) && Number(c.priority) > 0,
        weight: !!c.weight && !Number.isNaN(Number(c.weight)) && Number(c.weight) > 0,
        dailyCap: capCheck(c.dailyCap),
        totalCap: capCheck(c.totalCap),
        cap: capCheck(c.cap),
        defaultPayment: !c.defaultPayment || Number(c.defaultPayment) >= 0,
        aff_defaultPayment: !c.aff_defaultPayment || Number(c.aff_defaultPayment) >= 0,
        useLimitMPerBCE: !c.useLimitMPerBCE || typeof c.useLimitMPerBCE === 'boolean',
        limitMPerBCE: !c.limitMPerBCE || Number(c.limitMPerBCE) >= 0,
        mFtdsDailyCap: !c.mFtdsDailyCap || Number(c.mFtdsDailyCap) >= 0,
      };
    }),
  affiliate: (c) => oidRegex.test(c),
  manager: (c) => oidRegex.test(c),
  totalCap: (c) => capCheck(c),
  dailyCap: (c) => capCheck(c),
  cap: (c) => capCheck(c),
  csi: (c) => !c || typeof c === 'boolean',
  cso: (c) => !c || typeof c === 'boolean',
  dso: (c) => !c || typeof c === 'string',
  isTest: (c) => !c || typeof c === 'boolean',
  loadBalancer: (c) => !c || typeof c === 'boolean',
  loadBalancerCoefficient: (c) => {
    const n = Number(c);
    return !Number.isNaN(n) && n > 0;
  },
  isMBox: (c) => !c || typeof c === 'boolean',
  liveOmitTimeIntervalCheck: (c) => !c || typeof c === 'boolean',
};
