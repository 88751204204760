import { brokerApiTypes, countryOptions } from '@/consts';
import {
  oidRegex,
  urlRegex,
  twoDotsRegex,
  validateRefArray,
  capCheck,
  nameTest,
  validateAuthParams,
} from '@/validators/_utils';

export default {
  _id: (c) => !c || oidRegex.test(c),
  // broker: (c) => nameTest(c),
  brokerApiType: (c) => brokerApiTypes.includes(c),
  brokerApiURL: (c, campaign) =>
    campaign.brokerApiType === 'TECH_CAMPAIGN' || (urlRegex.test(c) && !twoDotsRegex.test(c)),
  brokerPullURL: (c, campaign) =>
    campaign.brokerApiType === 'TECH_CAMPAIGN' || (urlRegex.test(c) && !twoDotsRegex.test(c)),
  brokerAuthHeaders: (c, campaign) =>
    campaign.brokerApiType === 'TECH_CAMPAIGN' || validateAuthParams(c),
  brokerAuthBody: (c, campaign) =>
    campaign.brokerApiType === 'TECH_CAMPAIGN' || validateAuthParams(c),
  brokerPullAuthHeaders: (c, campaign) =>
    campaign.brokerApiType === 'TECH_CAMPAIGN' || validateAuthParams(c),
  brokerApiMapping: (c) => {
    return {
      email: !c.email || typeof c.email === 'string',
      password: !c.password || typeof c.password === 'string',
      firstname: !c.firstname || typeof c.firstname === 'string',
      lastname: !c.lastname || typeof c.lastname === 'string',
      phone: !c.phone || typeof c.phone === 'string',
      language: !c.language || typeof c.language === 'string',
      country: !c.country || typeof c.country === 'string',
      ip: !c.ip || typeof c.ip === 'string',
      geoip_country: !c.geoip_country || typeof c.geoip_country === 'string',
      so: !c.so || typeof c.so === 'string',
      aff_id: !c.aff_id || typeof c.aff_id === 'string',
    };
  },
  name: (c) => nameTest(c),
  active: (c) => typeof c === 'boolean',
  sendM: (c) => typeof c === 'boolean',
  ready: (c) => typeof c === 'boolean',
  pulling: (c) => typeof c === 'boolean',
  paranoid: (c) => typeof c === 'boolean',
  boxes: (c) => validateRefArray(c),
  managers: (c) => validateRefArray(c.map((ce) => ce._id)),
  totalCap: (c) => capCheck(c),
  dailyCap: (c) => capCheck(c),
  cap: (c) => capCheck(c),
  countries: (c, campaign) =>
    campaign.brokerApiType === 'TECH_CAMPAIGN' ||
    (Array.isArray(c) &&
      c.length > 0 &&
      c.every((d) => countryOptions.some((co) => d.value && co.value === d.value))),
  defaultPayment: (c) => Number(c) >= 0,
  aff_defaultPayment: (c) => Number(c) >= 0,
  crg: (c) => typeof c === 'boolean',
  crgRate: (c) => {
    const n = Number(c);
    return c !== '' && !Number.isNaN(n) && n >= 0 && n <= 100;
  },
  brokerDetails: (c) => {
    return {
      brokerName: typeof c.brokerName === 'string' && c.brokerName.length > 0, // !c.brokerName || (typeof c.brokerName === 'string' && c.brokerName.length > 0),
      skypeName: typeof c.skypeName === 'string' && c.skypeName.length > 0, // !c.skypeName || (typeof c.skypeName === 'string' && c.skypeName.length > 0),
      skypeUserName: typeof c.skypeUserName === 'string' && c.skypeUserName.length > 0, // !c.skypeUserName || (typeof c.skypeUserName === 'string' && c.skypeUserName > 0),
      rating: !c.rating || ['OK', 'Careful', 'NoWork'].includes(c.rating),
      campaignComment: !c.campaignComment || typeof c.campaignComment === 'string',
    };
  },
};
