import _ from 'lodash';
import parseQuery from '@/utils/parseQuery';

// eslint-disable-next-line import/prefer-default-export
export const fieldOptionsAndQuery = (ctx, field, metaField, tagsArray) => {
  const filterFormItems = ctx.filterForm[field]
    ? ctx.filterForm[field].map((c) => (c._id ? c._id : c))
    : [];
  const query = parseQuery(ctx, false);
  const itemsInQuery = query[field] ? query[field] : [];
  const itemsInContext = ctx[field] ? ctx[field] : [];
  if (!metaField) {
    let result = _.uniq([...itemsInContext, ...itemsInQuery]);
    if (tagsArray) {
      result = [...result, ...tagsArray];
    }
    // console.log('itemsInContextAndQuery', field, result);
    return _.uniq(result);
  }
  const itemsInFormAndQuery = _.uniq([...filterFormItems, ...itemsInQuery]);
  // console.log('itemsInFormAndQuery', field, itemsInFormAndQuery);
  const selectedItems = itemsInFormAndQuery.map((selected) => {
    const selectedFull = ctx[field] ? ctx[field].find((c) => c._id === selected) : undefined;
    return selectedFull || null;
  });
  // console.log('selectedItems', field, metaField, selectedItems);
  const metaItemsInQuery = query[metaField] ? query[metaField] : [];
  let result = [...metaItemsInQuery];
  // console.log('metaItemsInQuery', metaField, metaItemsInQuery);
  selectedItems.forEach((c) =>
    c && c[metaField]
      ? result.push(
          ...c[metaField].map((mc) => {
            return { name: mc.name, _id: mc._id };
          })
        )
      : null
  );
  // console.log('metaItems', metaField, result);
  if (tagsArray) {
    result = [...result, ...tagsArray];
  }
  return _.uniq(result);
};
