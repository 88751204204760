/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
import { RX_LOWER_UPPER, RX_START_SPACE_WORD, RX_UNDERSCORE } from '@/consts';

// Converts a string, including strings in camelCase or snake_case, into Start Case
// It keeps original single quote and hyphen in the word
// https://github.com/UrbanCompass/to-start-case

export const startCase = (str) =>
  str
    .replace(RX_UNDERSCORE, ' ')
    .replace(RX_LOWER_UPPER, (str, $1, $2) => `${$1} ${$2}`)
    .replace(RX_START_SPACE_WORD, (str, $1, $2) => $1 + $2.toUpperCase());
