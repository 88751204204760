<template>
  <UploadLeadsFile />
</template>

<script>
import UploadLeadsFile from '@/components/pages/UploadLeadsFile/UploadLeadsFile.vue';

export default {
  name: 'UploadLeadsFileView',
  components: {
    UploadLeadsFile,
  },
};
</script>
