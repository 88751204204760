import { countryOptions } from '@/consts';

export default function countryMultiSelectSearch(query) {
  const options = countryOptions;
  const formattedQuery = query.trim().toLowerCase();

  const byValue = options.filter(({ value }) => {
    const formattedValue = value.trim().toLowerCase();
    return formattedValue === formattedQuery;
  });

  const byText = options.filter(({ text }) => {
    const formattedText = text.trim().toLowerCase();
    return formattedText.includes(formattedQuery);
  });

  const searchResult = [...byValue, ...byText];
  const searchResultWithoutDuplicates = searchResult.filter(
    ({ value }, index, array) => index === array.findIndex(({ value: value2 }) => value2 === value)
  );

  this.countryOptions = searchResultWithoutDuplicates;
}
