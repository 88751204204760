<template>
  <MetaCampaigns />
</template>

<script>
import MetaCampaigns from '@/components/pages/MetaCampaigns/MetaCampaigns.vue';

export default {
  name: 'MetaCampaignsView',
  components: {
    MetaCampaigns,
  },
};
</script>
