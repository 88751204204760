<template>
  <b-container fluid>
    <b-row class="my-3" align-v="center">
      <b-col cols="12" lg="2" class="my-lg-0 my-1"><h2>Campaigns</h2></b-col>
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="4"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <!--        <div class="d-flex align-items-center justify-content-end">-->
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="4" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
        <b-button to="/create-campaign" variant="primary" v-if="isManager">Create</b-button>
        <!--        </div>-->

        <div>
          <span class="mx-2">Edit</span>
          <b-button v-b-toggle.sidebar-right-table @click="openSidebarColumns()" class="mx-2">
            Table
          </b-button>
          <b-button v-b-toggle.sidebar-right-details @click="openSidebarDetails()" class="mx-2">
            Details
          </b-button>
          <b-button v-b-toggle.sidebar-right-hidden @click="openSidebarHidden()" class="mx-2">
            Hidden
          </b-button>
          <b-sidebar
            id="sidebar-right-table"
            v-model="sidebarColumnsOpen"
            title="Table Fields"
            right
            shadow
          >
            <div class="px-3 py-2">
              <b-form-checkbox-group
                v-model="preSelectedColumns"
                :options="tableFieldsOptions"
                class="mb-3"
                @input="logSelectedColumns()"
                stacked
              ></b-form-checkbox-group>
              <b-button
                variant="primary"
                @click="applyFields()"
                :disabled="!canApplyFields"
                class="my-2 sidebar-button"
              >
                Apply
              </b-button>
              <b-button
                variant="success"
                @click="saveSelectedFields('campaigns_table')"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="warning"
                @click="restoreTablePreference()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
          <b-sidebar
            id="sidebar-right-details"
            v-model="sidebarDetailsOpen"
            title="Show Details"
            right
            shadow
          >
            <div class="px-3 py-2">
              <b-form-checkbox-group
                v-model="preSelectedDetails"
                :options="detailsOptions"
                class="mb-3"
                @input="logSelectedDetails()"
                stacked
              ></b-form-checkbox-group>
              <b-button
                variant="primary"
                @click="applyDetails()"
                :disabled="!canApplyDetails"
                class="my-2 sidebar-button"
              >
                Apply
              </b-button>
              <b-button
                variant="success"
                @click="saveSelectedFields('campaigns_details')"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="warning"
                @click="restoreDetailsPreference()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
          <b-sidebar
            id="sidebar-right-hidden"
            v-model="sidebarHiddenOpen"
            title="Hidden Campaigns"
            right
            shadow
          >
            <div class="px-3 py-2">
              <multiselect
                id="user-hidden-campaigns"
                v-model="hidden"
                :options="campaignsOptions"
                track-by="name"
                label="name"
                :closeOnSelect="false"
                :multiple="true"
              ></multiselect>
              <b-button
                variant="success"
                @click="editHiddenCampaigns()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="secondary"
                @click="takeDefaultHidden()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-3">
      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3">
        <span class="d-block h5 font-weight-normal">Managers</span>
        <multiselect
          id="managers"
          v-model="filterForm.managers"
          :options="managersOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
          @input="updateBackdoor()"
        ></multiselect>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3">
        <span class="d-block h5 font-weight-normal">Geos</span>
        <multiselect
          v-model="filterForm.countries"
          :options="countryOptions"
          track-by="value"
          @search-change="countryMultiSelectSearch"
          :custom-label="countryWithCode"
          :closeOnSelect="false"
          :multiple="true"
          @input="updateBackdoor()"
        >
          <!--                        :showLabels="false"
-->
        </multiselect>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
        <span class="d-block h5 font-weight-normal">Boxes</span>
        <multiselect
          v-model="filterForm.boxes"
          :options="boxesOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
          @input="updateBackdoor()"
        ></multiselect>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0" v-if="!$rent">
        <span class="d-block h5 font-weight-normal">Daily Cap type</span>
        <multiselect
          v-model="filterForm.dailyCapType"
          :options="actionTypeOptions"
          track-by="text"
          label="text"
          :closeOnSelect="false"
          :multiple="true"
          @input="updateBackdoor()"
        ></multiselect>
      </b-col>

      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0" v-if="!$rent">
        <span class="d-block h5 font-weight-normal">Total Cap type</span>
        <multiselect
          v-model="filterForm.totalCapType"
          :options="actionTypeOptions"
          track-by="text"
          label="text"
          :closeOnSelect="false"
          :multiple="true"
          @input="updateBackdoor()"
        ></multiselect>
      </b-col>

      <b-col class="col-4 col-md-2 col-lg-1" v-if="!$rent">
        <span class="d-block h5 font-weight-normal mt-1">Status</span>
        <b-form-checkbox-group
          v-model="filterForm.active"
          @input="updateBackdoor()"
          :options="activityStatusOptions"
        ></b-form-checkbox-group>
      </b-col>
      <b-col class="col-4 col-md-2 col-lg-1" v-if="!$rent">
        <span class="d-block h5 font-weight-normal mt-1">Pulling</span>
        <b-form-checkbox-group
          v-model="filterForm.pulling"
          @input="updateBackdoor()"
          :options="pullingStatusOptions"
        ></b-form-checkbox-group>
      </b-col>
      <b-col class="col-4 col-md-2 col-lg-1" v-if="!$rent">
        <span class="d-block h5 font-weight-normal mt-1">Ready</span>
        <b-form-checkbox-group
          v-model="filterForm.ready"
          @input="updateBackdoor()"
          :options="pullingStatusOptions"
        ></b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-button @click="clearFilterForm()" class="mb-4">Clear filters</b-button>
    <b-table
      class="mb-3"
      :fields="sortedSelectedFields"
      :items="realitems"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      :busy="requesting"
      @filtered="onFiltered"
      responsive
      show-empty
      striped
      hover
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <!--      style="white-space: nowrap"-->
      <template #cell(id)="data">
        <!--        <b-link @click="data.toggleDetails" style="color: inherit">-->
        {{ data.item.id }}
        <!--        </b-link>-->
      </template>
      <template #cell(name)="data">
        <!--        <b-link @click="data.toggleDetails" style="color: inherit">-->
        {{ data.item.name }}
        <!--        </b-link>-->
        <!--        <b-button size="sm" variant="primary">-->
        <!--          <b-icon icon="pencil-square"></b-icon>-->
        <!--          Rename-->
        <!--        </b-button>-->
      </template>

      <template #cell(active)="data">
        <h5>
          <b-badge
            class="badge-toggle"
            :variant="data.value === true ? 'success' : 'danger'"
            @click="makeActive(data.item._id)"
            :disabled="data.item.blockedByAdmin"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(sendM)="data">
        <h5>
          <b-badge
            class="badge-toggle"
            :variant="data.value === true ? 'success' : 'danger'"
            @click="makeSendM(data.item._id)"
            :disabled="data.item.blockedByAdmin"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(limit1MPerGeo)="data">
        <h5>
          <b-badge
            class="badge-toggle"
            :variant="data.value === true ? 'warning' : 'info'"
            @click="makeLimit1MPerGeo(data.item._id)"
            :disabled="data.item.blockedByAdmin"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(blockedByAdmin)="data">
        <h5>
          <b-badge
            class="badge-toggle"
            :variant="data.value === true ? 'danger' : 'success'"
            @click="makeBlockedByAdmin(data.item._id)"
            :disabled="!iAmOtherLevelTrustedUser"
          >
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(ready)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'danger'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(pulling)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'danger'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <!--          <b-button variant="success">-->
          <!--            <b-icon icon="play-fill"></b-icon>-->
          <!--            Start-->
          <!--          </b-button>-->
          <!--          TODO Method to make campaign active|paused from here -->
          <!--          <b-button variant="warning">-->
          <!--            <b-icon icon="pause-fill"></b-icon>-->
          <!--            Pause-->
          <!--          </b-button>-->
          <b-button variant="primary" :to="`/campaign/edit/${data.item._id}`" v-if="isManager">
            <b-icon icon="pencil-square"></b-icon>
            Edit
          </b-button>
          <!--          <b-button @click="data.toggleDetails">-->
          <!--            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details-->
          <!--          </b-button>-->
          <b-button variant="danger" @click="hideCampaign(data.item._id)">
            <b-icon icon="eye-slash"></b-icon>
            Hide
          </b-button>
          <!--          <b-button variant="danger">-->
          <!--            <b-icon icon="trash-fill"></b-icon>-->
          <!--            Delete-->
          <!--          </b-button>-->
          <b-button
            variant="primary"
            v-if="iAmOtherLevelTrustedUser && data.item.brokerApiType === 'Daughter'"
            v-b-modal="`modal-edit-gm-box-${data.item._id}`"
          >
            <b-icon icon="pencil-square"></b-icon>
            GMBox
          </b-button>
        </b-button-group>
        <b-modal
          v-if="iAmOtherLevelTrustedUser"
          :id="`modal-edit-gm-box-${data.item._id}`"
          centered
          no-close-on-esc
          lazy
          hide-header
          hide-footer
          size="giant"
        >
          <!--          size="xl"-->
          <!--          style="max-width: 1200px"-->
          <DaughterBoxEdit :daughter_id="data.item._id" />
        </b-modal>
      </template>

      <template #cell(dailyFtdsLimit)="data">
        <div class="d-flex">
          <!--        v-if="data.value" -->
          <h5 class="mx-2">
            <b-badge :variant="data.value ? 'warning' : 'info'">
              {{ data.value ? data.value : 'no limit' }}
            </b-badge>
          </h5>
          <b-dropdown right no-caret size="sm" class="mx-1">
            <template #button-content>
              <b-icon icon="pencil-square"></b-icon>
            </template>
            <b-dropdown-form style="width: 240px">
              <ChangeDailyFtdsLimit
                :id="data.item._id"
                :initialValue="data.value"
                :onSave="changeDailyFtdsLimit"
              ></ChangeDailyFtdsLimit>
            </b-dropdown-form>
          </b-dropdown>
          <b-button size="sm" @click="changeDailyFtdsLimit(data.item._id, undefined)">
            <!--            no-caret -->
            ❌
          </b-button>
        </div>
      </template>

      <template #cell(countries)="data">
        <div class="d-flex">
          {{ data.value }}
          <b-button-group size="sm" class="mx-2">
            <b-button
              variant="primary"
              v-if="iAmOtherLevelTrustedUser"
              v-b-modal="`modal-edit-geos-${data.item._id}`"
            >
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
          </b-button-group>
        </div>
        <b-modal
          v-if="iAmOtherLevelTrustedUser"
          :id="`modal-edit-geos-${data.item._id}`"
          centered
          no-close-on-esc
          lazy
          hide-header
          hide-footer
          size="xl"
        >
          <EditCampaignsCountries
            :_id="data.item._id"
            :name="data.item.name"
            :countries="data.item.countries"
            @edit="handleCampaignsCountriesEdited"
          />
          <!--          :countries="campaigns.find((c) => c._id === data.item._id).countries" -->
        </b-modal>
      </template>

      <!-- TODO: Render all needed details properly -->
      <template #row-details="row">
        <!--        <b-row>-->
        <!--        {{ row.item }}-->
        <!--          <b-col cols="12">-->
        <b-card-group columns class="rowdetails">
          <b-card v-if="detailAvailable('countries')">
            <b-card-title>
              Geos
              <b-badge
                variant="info"
                class="mx-1"
                v-for="(c, index) in row.item.countries"
                v-bind:key="index"
              >
                {{ c.value }}
              </b-badge>
              <!--              This is a wider card with supporting text below as a natural lead-in to additional content.-->
              <!--              This content is a little bit longer.-->
            </b-card-title>
          </b-card>

          <b-card v-if="detailAvailable('boxes')">
            <b-card-title>
              Boxes
              <!--              <div v-for="(b, index) in row.item.boxesArray" v-bind:key="index">-->
              <router-link
                v-for="(b, index) in row.item.boxesArray"
                v-bind:key="index"
                :to="`/box/edit/${b._id}`"
              >
                <b-badge variant="primary" class="mx-1">{{ b.name }}</b-badge>
              </router-link>
              <!--              </div>-->
            </b-card-title>
          </b-card>
          <b-card v-if="detailAvailable('managers')">
            <b-card-title>
              Managers
              <!--              <div v-for="(b, index) in row.item.managersArray" v-bind:key="index">-->
              <router-link
                v-for="(b, index) in row.item.managersArray"
                v-bind:key="index"
                :to="`/user/edit/${b._id}`"
              >
                <b-badge variant="primary" class="mx-1">{{ b.name }}</b-badge>
              </router-link>
              <!--              </div>-->
            </b-card-title>
          </b-card>
          <b-card header="Edit CRG for past campaign's actions">
            <b-row>
              <b-col cols="12" md="3">
                <b-form-checkbox
                  class="my-2"
                  v-model="row.item.changeCrgForm.crg"
                  :state="validateCrg(row.item.changeCrgForm.crg)"
                  :invalid-feedback="invalidFeedback.campaign.crg"
                >
                  CRG
                </b-form-checkbox>
              </b-col>
              <b-col cols="12" md="9" v-if="row.item.changeCrgForm.crg">
                <b-form-group
                  class="my-2"
                  label="CRG Rate"
                  label-for="crg-rate"
                  :state="validateCrgRate(row.item.changeCrgForm.crgRate)"
                  :invalid-feedback="invalidFeedback.campaign.crgRate"
                >
                  <b-form-input
                    type="number"
                    id="crg-rate"
                    v-model="row.item.changeCrgForm.crgRate"
                    :state="validateCrgRate(row.item.changeCrgForm.crgRate)"
                  ></b-form-input>
                  <span class="mx-1">%</span>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group
                  class="mb-2"
                  label="Date"
                  label-for="start-select-date"
                  :invalid-feedback="invalidFeedback.injection.date"
                >
                  <!--                  :state="validateDate(row.item.changeCrgForm.fromDate)"-->
                  <b-form-datepicker
                    :date-format-options="dateFormatOptions"
                    id="start-select-date"
                    today-button
                    reset-button
                    close-button
                    :max="row.item.changeCrgForm.toDate"
                    v-model="row.item.changeCrgForm.fromDate"
                  ></b-form-datepicker>
                  <!--                  :state="validateDate(row.item.changeCrgForm.fromDate)"-->
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group
                  class="mb-2"
                  label="Time"
                  label-for="start-select-time"
                  :invalid-feedback="invalidFeedback.injection.time"
                >
                  <!--                  :state="validateTime(row.item.changeCrgForm.fromTime)"-->
                  <b-form-timepicker
                    id="start-select-time"
                    now-button
                    reset-button
                    right
                    :hour12="false"
                    v-model="row.item.changeCrgForm.fromTime"
                  ></b-form-timepicker>
                  <!--                  :state="validateTime(row.item.changeCrgForm.fromTime)"-->
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group
                  class="mb-2"
                  label="Date"
                  label-for="end-select-date"
                  :invalid-feedback="invalidFeedback.injection.date"
                >
                  <!--                  :state="validateDate(row.item.changeCrgForm.toDate)"-->
                  <b-form-datepicker
                    :date-format-options="dateFormatOptions"
                    id="end-select-date"
                    today-button
                    reset-button
                    close-button
                    :min="row.item.changeCrgForm.fromDate"
                    v-model="row.item.changeCrgForm.toDate"
                  ></b-form-datepicker>
                  <!--                  :state="validateDate(row.item.changeCrgForm.toDate)"-->
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group
                  class="mb-2"
                  label="Time"
                  label-for="end-select-time"
                  :invalid-feedback="invalidFeedback.injection.time"
                >
                  <!--                  :state="validateTime(row.item.changeCrgForm.toTime)"-->
                  <b-form-timepicker
                    id="end-select-time"
                    now-button
                    reset-button
                    right
                    :hour12="false"
                    v-model="row.item.changeCrgForm.toTime"
                  ></b-form-timepicker>
                  <!--                  :state="validateTime(row.item.changeCrgForm.toTime)"-->
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              :disabled="requesting"
              variant="warning"
              @click="changeCrgRatesBackwards(row.item)"
            >
              Edit CRGs for past campaign's actions
            </b-button>
          </b-card>
          <b-card header="Add Campaign to Multiple Boxes for Multiple GEOs">
            <AddCampaignToBox :campaign="getThisCampaign(row.item)" />
          </b-card>
          <b-card header="Remove Campaign from Multiple Boxes for Multiple GEOs">
            <RemoveCampaignsFromBoxes :campaign="getThisCampaign(row.item)" />
          </b-card>
        </b-card-group>
        <!--          </b-col>-->
        <!--        </b-row>-->
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row class="mb-3">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <!--        <div class="d-flex align-items-center justify-content-center">-->
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
        <!--        </div>-->
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <!--        <div class="d-flex align-items-center justify-content-end">-->
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
        <!--        </div>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import '@/assets/badge-toogle.css';
import { mapState } from 'vuex';
import {
  castCountriesFromServer,
  // tableFormatterArrayValues,
  // tableFormatterId,
  applyFilterToArray,
  applyCapFilter,
  countryMultiSelectSearch,
  showCard,
  handleRequestError,
} from '@/utils';
import store from '@/store';
// import { isAdmin } from '@/utils';
import {
  actionTypeOptions,
  activityStatusOptions,
  pullingStatusOptions,
  countryOptions,
  invalidFeedback,
  dateFormatOptions,
  OTHER_LEVEL_TRUSTED_USERS,
} from '@/consts';
import _ from 'lodash';
import {
  tableFieldsOptions,
  sortedSelectedFields,
  detailsOptions,
  sortedSelectedDetails,
  saveSelectedFieldsMethod,
  takeDefaultTableFields,
  takeDefaultDetailsOptions,
  takeDefaultHidden,
  canApplyFields,
  canApplyDetails,
  applyFields,
  applyDetails,
} from '@/utils/fieldsAndDetailsOptionsHelpers';
import { userAvailableFields, userAvailableDetails } from '@/consts/userAvailableFields';
import {
  hideCampaign,
  changeCrgRatesBackwards,
  editHiddenCampaigns,
  makeCampaignActive,
  makeCampaignSendM,
  makeCampaignLimit1MPerGeo,
  makeCampaignBlockedByAdmin,
  changeDailyFtdsLimit,
} from '@/utils/api';
import campaignValidator from '@/validators/campaign';
// import injectionValidator from '@/validators/injection';
import AddCampaignToBox from '@/components/shared/AddCampaignToBox.vue';
import RemoveCampaignsFromBoxes from '@/components/shared/RemoveCampaignsFromBoxes.vue';
import DaughterBoxEdit from '@/components/pages/Campaigns/CampaignsTable/components/DaughterBoxEdit.vue';
import EditCampaignsCountries from '@/components/pages/Campaigns/CampaignsTable/components/EditCampaignsCountries.vue';
import ChangeDailyFtdsLimit from './components/ChangeDailyFtdsLimit.vue';

const initialFilterForm = {
  managers: [],
  countries: [],
  boxes: [],
  active: [],
  pulling: [],
  ready: [],
  dailyCapType: [],
  totalCapType: [],
};

export default {
  name: 'CampaignsTable',
  components: {
    EditCampaignsCountries,
    DaughterBoxEdit,
    AddCampaignToBox,
    RemoveCampaignsFromBoxes,
    ChangeDailyFtdsLimit,
  },
  data() {
    return {
      dateFormatOptions,
      invalidFeedback,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      activityStatusOptions,
      pullingStatusOptions,
      actionTypeOptions,
      countryOptions,
      filterForm: _.cloneDeep(initialFilterForm),
      selectedColumns: [],
      preSelectedColumns: [],
      selectedDetails: [],
      preSelectedDetails: [],
      hidden: [],
      sidebarColumnsOpen: false,
      sidebarDetailsOpen: false,
      sidebarHiddenOpen: false,
      filterIncludedFields: ['_id', 'name', 'brokerApiType', 'broker'], // 'countries'
    };
  },
  computed: {
    ...mapState([
      'user',
      'token',
      'campaigns',
      'actions',
      'boxes',
      'requesting',
      'managers',
      'isAdmin',
      'isManager',
      'isMasterAffiliate',
    ]),
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    canApplyFields() {
      return canApplyFields(this);
    },
    canApplyDetails() {
      return canApplyDetails(this);
    },
    userAvailableFields() {
      console.time('userAvailableFields');
      let r;
      if (this.isAdmin) r = userAvailableFields.campaigns.admin();
      else if (this.isManager) r = userAvailableFields.campaigns.manager();
      else if (this.isMasterAffiliate) r = userAvailableFields.campaigns.masteraffiliate();
      else r = userAvailableFields.campaigns.affiliate();
      console.timeEnd('userAvailableFields');
      return r;
    },
    tableFieldsOptions() {
      return tableFieldsOptions(this);
    },
    sortedSelectedFields() {
      return sortedSelectedFields(this);
    },
    userAvailableDetails() {
      if (this.isAdmin) return userAvailableDetails.campaigns.admin();
      if (this.isManager) return userAvailableDetails.campaigns.manager();
      if (this.isMasterAffiliate) return userAvailableDetails.campaigns.masteraffiliate();
      return userAvailableDetails.campaigns.affiliate();
    },
    detailsOptions() {
      return detailsOptions(this);
    },
    sortedSelectedDetails() {
      return sortedSelectedDetails(this);
    },
    realitems() {
      this.backdoor;
      console.log('making real items');
      const f = this.filterForm;
      const campaigns = this.campaigns
        .filter((c) => {
          if (!this.user) return false;
          const hidden = this.user.hiddenCampaigns
            ? this.user.hiddenCampaigns.map((h) => (h._id ? h._id : h))
            : undefined;
          if (hidden && Array.isArray(hidden) && hidden.includes(c._id)) return false;
          if (!f) return true;
          if (f.managers.length > 0) {
            console.log('filtering managers');
            console.log(f.managers[0]);
            console.log(c.managers);
          }
          return (
            applyFilterToArray(f.managers, c.managers, '_id') &&
            applyFilterToArray(f.countries, c.countries, 'value') &&
            applyFilterToArray(f.boxes, c.boxes, '_id') &&
            (f.active.length === 0 ||
              (c.active && f.active.includes('active')) ||
              (!c.active && f.active.includes('inactive'))) &&
            (f.pulling.length === 0 ||
              (c.pulling && f.pulling.includes('yes')) ||
              (!c.pulling && f.pulling.includes('no'))) &&
            (f.ready.length === 0 ||
              (c.ready && f.ready.includes('yes')) ||
              (!c.ready && f.ready.includes('no'))) &&
            applyCapFilter(f.dailyCapType, c.dailyCap, 'value') &&
            applyCapFilter(f.totalCapType, c.totalCap, 'value')
          );
        })
        .map((c) => {
          const cr = _.cloneDeep(c);
          if (cr.brokerApiType === 'TECH_CAMPAIGN') cr.brokerApiType = 'TECH';
          if (cr.name === 'TECH_CAMPAIGN') cr.name = 'TECH';
          // cr.status = c.active ? 'Active' : 'Inactive'; // there are only active campaigns now
          cr.broker = cr.brokerDetails ? cr.brokerDetails.brokerName : cr.broker;
          cr.boxes = c.boxes ? c.boxes.length : 0;
          cr.boxesArray =
            c.boxes && Array.isArray(c.boxes)
              ? c.boxes.map((b) => {
                  const fb = this.boxes.find((bf) => bf._id === b);
                  return fb ? { name: fb.name, _id: fb._id } : { name: 'unknown', _id: b };
                })
              : [];
          cr.managers = c.managers.length;
          cr.managersArray =
            c.managers && Array.isArray(c.managers)
              ? c.managers.map((b) => {
                  const fb = this.managers ? this.managers.find((bf) => bf._id === b) : undefined;
                  return fb ? { name: fb.name, _id: fb._id } : { name: 'unknown', _id: b };
                })
              : [];
          cr.dailyCap = c.dailyCap ? c.dailyCap.amount : '-';
          cr.dailyCapType = c.dailyCap ? c.dailyCap.type : '-';
          cr.totalCap = c.totalCap ? c.totalCap.amount : '-';
          cr.totalCapType = c.totalCap ? c.totalCap.type : '-';
          cr.countries = castCountriesFromServer(c.countries);
          cr.defaultPayment = c.defaultPayment;
          cr.aff_defaultPayment = c.aff_defaultPayment;
          cr.currency = c.currency;
          // cr.impressionsToday= campaignActionsToday.filter((a) => a.type === 'impression').length;
          // cr.clicksToday= campaignActionsToday.filter((a) => a.type === 'click').length;
          // cr.leadsToday= campaignActionsToday.filter((a) => a.type === 'lead').length;
          // cr.ftdsToday= campaignActionsToday.filter((a) => a.type === 'ftd').length;
          // cr.payoutsToday= campaignActionsToday.reduce((s, a) => (a.payout ? s + a.payout : s), 0);
          // cr.revenueToday= '$1215423412'; // need broker payout
          // cr.profitToday= '$1212298889'; // need broker payout
          cr.crg = c.crg;
          cr.crgRate = c.crgRate;
          cr.changeCrgForm = {
            crg: c.crg,
            crgRate: c.crgRate,
            fromDate: undefined,
            fromTime: undefined,
            toDate: undefined,
            toTime: undefined,
          };
          cr.metaCampaigns = cr.metaCampaigns ? cr.metaCampaigns.length : 0;
          //       .map((mc) => {
          //     return { _id: mc._id, name: mc.name };
          //   })
          // : [];
          return cr;
        });
      console.log('campaigns realitems', campaigns);
      this.onFiltered(campaigns);
      return campaigns;
    },
    managersOptions() {
      return this.managers.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    campaignsOptions() {
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    boxesOptions() {
      return this.boxes && Array.isArray(this.boxes)
        ? this.boxes.map((b) => {
            return { name: b.name, _id: b._id };
          })
        : [];
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    // updateCampaign(campaignId, newCampaign) {
    //   if (newCampaign) {
    //     const clonedItems = _.cloneDeep(this.campaigns);
    //     const itemIndex = clonedItems.findIndex(({ _id }) => _id === campaignId);
    //     const { STCIList, _showDetails } = clonedItems[itemIndex];
    //     clonedItems[itemIndex] = _.cloneDeep(newCampaign);
    //     if (STCIList) clonedItems[itemIndex].STCIList = STCIList;
    //     if (_showDetails) clonedItems[itemIndex]._showDetails = _showDetails;
    //     this.campaigns = clonedItems;
    //     this.$store.commit('make_requesting', { req: false });
    //     console.log('updateCampaign', { campaignId, itemIndex });
    //     this.backdoor += 1;
    //   } else {
    //     this.updateData();
    //   }
    // },
    changeDailyFtdsLimit(_id, dailyFtdsLimit) {
      this.$store.commit('make_requesting', { req: true });
      changeDailyFtdsLimit(this.token, { _id, dailyFtdsLimit })
        .then((r) => {
          showCard(
            this,
            `Changed Daily Ftds Limit of campaign ${r.data.name} to ${r.data.dailyFtdsLimit}`,
            'success',
            ''
          );
          this.updateData();
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on change daily ftds limit`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    getThisCampaign(campaign) {
      return { _id: campaign._id, name: campaign.name };
    },
    handleCampaignsCountriesEdited(data) {
      const { _id, countries } = data;
      console.log('handleCampaignsCountriesEdited', _id, countries);
      const i = this.campaigns.findIndex((c) => c._id === _id);
      if (i < 0) return;
      this.campaigns[i].countries = countries;
      this.backdoor += 1;
    },
    makeActive(_id) {
      if (this.isManager) {
        makeCampaignActive(this.token, { _id })
          .then((r) => {
            if (r.data.success) {
              const i = this.campaigns.findIndex((c) => c._id === _id);
              if (i >= 0) {
                this.campaigns[i].active = r.data.active;
                this.backdoor += 1;
              }
            } else {
              handleRequestError(this, r.data, `Error changing active status for campaign ${_id}`);
            }
          })
          .catch((e) =>
            handleRequestError(this, e, `Error changing active status for campaign ${_id}`)
          );
      }
    },
    makeSendM(_id) {
      if (this.isManager) {
        makeCampaignSendM(this.token, { _id })
          .then((r) => {
            if (r.data.success) {
              const i = this.campaigns.findIndex((c) => c._id === _id);
              if (i >= 0) {
                this.campaigns[i].sendM = r.data.sendM;
                this.backdoor += 1;
              }
            } else {
              handleRequestError(this, r.data, `Error changing SendM status for campaign ${_id}`);
            }
          })
          .catch((e) =>
            handleRequestError(this, e, `Error changing SendM status for campaign ${_id}`)
          );
      }
    },
    makeLimit1MPerGeo(_id) {
      if (this.isManager) {
        makeCampaignLimit1MPerGeo(this.token, { _id })
          .then((r) => {
            if (r.data.success) {
              const i = this.campaigns.findIndex((c) => c._id === _id);
              if (i >= 0) {
                this.campaigns[i].limit1MPerGeo = r.data.limit1MPerGeo;
                this.backdoor += 1;
              }
            } else {
              handleRequestError(this, r.data, `Error changing Only1M status for campaign ${_id}`);
            }
          })
          .catch((e) =>
            handleRequestError(this, e, `Error changing Only1M status for campaign ${_id}`)
          );
      }
    },
    makeBlockedByAdmin(_id) {
      if (this.iAmOtherLevelTrustedUser) {
        makeCampaignBlockedByAdmin(this.token, { _id })
          .then((r) => {
            if (r.data.success) {
              const i = this.campaigns.findIndex((c) => c._id === _id);
              if (i >= 0) {
                this.campaigns[i].blockedByAdmin = r.data.blockedByAdmin;
                this.backdoor += 1;
              }
            } else {
              handleRequestError(
                this,
                r.data,
                `Error changing blockedByAdmin status for campaign ${_id}`
              );
            }
          })
          .catch((e) =>
            handleRequestError(this, e, `Error changing blockedByAdmin status for campaign ${_id}`)
          );
      }
    },
    validateCrg(c) {
      return campaignValidator.crg(c);
    },
    validateCrgRate(c) {
      return campaignValidator.crgRate(c);
    },
    changeCrgRatesBackwards(campaign) {
      this.$store.commit('make_requesting', { req: true });
      if (!campaign.changeCrgForm) return;
      const form = campaign.changeCrgForm;
      const { crg, crgRate, fromDate, fromTime, toDate, toTime } = form;
      if (!(fromDate && fromTime && toDate && toTime)) return;

      const data = {
        campaign: campaign._id,
        crg,
        crgRate,
        from: `${fromDate}T${fromTime}`,
        to: `${toDate}T${toTime}`,
      };
      changeCrgRatesBackwards(this.token, data)
        .then((r) => {
          if (r.status === 200 && r.data.success) {
            showCard(
              this,
              `Actions for ${
                campaign.name
              } from ${fromDate} ${fromTime} to ${toDate} ${toTime} marked as ${
                crg ? `CRG with ${crgRate}% rate` : 'no-CRG'
              }`,
              'success'
            );
            // this.$store.dispatch('get_me');
          } else {
            handleRequestError(this, r.data, `Unknown error marking past actions CRG`);
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown error marking past actions CRG`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    hideCampaign(campaign) {
      this.$store.commit('make_requesting', { req: true });
      hideCampaign(this.token, { campaign })
        .then((r) => {
          if (r.status === 200 && r.data.success) {
            showCard(this, `Campaign ${campaign} hidden`, 'success');
            this.$store.dispatch('get_me');
          } else {
            handleRequestError(this, r.data, `Unknown error hiding campaign ${campaign}`);
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error hiding campaign ${campaign}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    applyFields() {
      applyFields(this);
    },
    applyDetails() {
      applyDetails(this);
    },
    saveSelectedFields(type) {
      saveSelectedFieldsMethod(this, type);
    },
    takeDefaultTableFields() {
      takeDefaultTableFields(this, 'campaignsTableSelectedFields');
    },
    takeDefaultDetailsOptions() {
      takeDefaultDetailsOptions(this, 'campaignsTableSelectedDetails');
    },
    takeDefaultHidden() {
      takeDefaultHidden(this, 'hiddenCampaigns');
    },
    restoreTablePreference() {
      this.takeDefaultTableFields();
      this.saveSelectedFields('campaigns_table');
    },
    restoreDetailsPreference() {
      this.takeDefaultDetailsOptions();
      this.saveSelectedFields('campaigns_details');
    },
    openSidebarDetails() {
      this.sidebarColumnsOpen = false;
      this.sidebarHiddenOpen = false;
    },
    openSidebarColumns() {
      this.sidebarDetailsOpen = false;
      this.sidebarHiddenOpen = false;
    },
    openSidebarHidden() {
      this.takeDefaultHidden();
      this.sidebarDetailsOpen = false;
      this.sidebarColumnsOpen = false;
    },
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    logSelectedColumns() {
      console.log(this.selectedColumns);
    },
    logSelectedDetails() {
      console.log(this.selectedDetails);
    },
    countryMultiSelectSearch,
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    updateBackdoor() {
      this.backdoor += 1;
    },
    clearFilterForm() {
      this.filter = null;
      this.filterForm = _.cloneDeep(initialFilterForm);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async updateData() {
      // await this.$store.dispatch('get_all_resources', { vm: this });
      // await this.$store.dispatch('get_campaigns', { vm: this });
      // await this.$store.dispatch('get_actions', { vm: this });
      // await this.$store.dispatch('get_boxes', { vm: this });
      // this.backdoor += 1;
      await store.dispatch('get_all_resources_one_request', { vm: this });
      this.totalRows = this.realitems.length;
      this.takeDefaultHidden();
    },
    editHiddenCampaigns() {
      editHiddenCampaigns(this.token, { hidden: this.hidden })
        .then((r) => {
          if (r.status === 200 && r.data.success) {
            showCard(
              this,
              `Edited hidden campaigns`,
              'success',
              undefined,
              'b-toaster-bottom-right'
            );
            this.$store.dispatch('get_me');
          } else {
            handleRequestError(
              this,
              r.data,
              `Unknown error on editing hidden campaigns`,
              undefined,
              'b-toaster-bottom-right'
            );
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on editing hidden campaigns`,
            undefined,
            'b-toaster-bottom-right'
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  watch: {
    'user.hiddenCampaigns': function () {
      this.takeDefaultHidden();
    },
  },
};
</script>

<style scoped>
.rowdetails {
  /*max-width: 95vw;*/
  /*max-width: 95%;*/
}
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
