<template>
  <div>
    <h3>Edit {{ name }} Geos</h3>
    <LoadingSpinner v-if="requesting" requesting="requesting" />
    <b-form-group class="my-2" label="Geos" label-for="campaign-countries" v-else>
      <multiselect
        id="campaign-countries"
        v-model="geos"
        :options="countryOptions"
        :closeOnSelect="false"
        track-by="value"
        @search-change="countryMultiSelectSearch"
        :multiple="true"
        :custom-label="countryWithCode"
        :disabled="disabled"
      ></multiselect>
    </b-form-group>
    <div class="my-2 d-flex">
      <b-button class="mx-2" @click="selectAllCountries()" :disabled="disabled">
        Select all geos
      </b-button>
      <b-button class="mx-2" @click="clearCountries()" :disabled="disabled">Clear geos</b-button>
    </div>
    <b-button
      variant="primary"
      @click="editCampaignsCountries()"
      :disabled="disabled"
      class="my-2"
      block
    >
      Save
    </b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _, { cloneDeep } from 'lodash';
import { countryOptions, OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import {
  // castCountriesFromServer,
  countryMultiSelectSearch,
  handleRequestError,
  showCard,
} from '@/utils';
import { editCampaignsCountries } from '@/utils/api';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

export default {
  name: 'EditCampaignsCountries',
  components: { LoadingSpinner },
  props: {
    _id: { type: String, default: '' },
    name: { type: String, default: '' },
    countries: { type: Array, default: () => [] },
  },
  data() {
    return {
      // geos: castCountriesFromServer(cloneDeep(this.countries)),
      geos: cloneDeep(this.countries),
      countryOptions,
      requesting: false,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'campaigns']),
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    disabled() {
      return this._id !== '' && !(this.iAmOtherLevelTrustedUser || this.user.integrator);
    },
  },
  methods: {
    selectAllCountries() {
      this.geos = _.cloneDeep(countryOptions);
    },
    clearCountries() {
      this.geos = [];
    },
    countryMultiSelectSearch,
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    editCampaignsCountries() {
      this.requesting = true;
      const countries = this.geos.map((c) => c.value);
      editCampaignsCountries(this.token, { _id: this._id, countries })
        .then((r) => {
          if (r.data.success) {
            this.$emit('edit', { _id: this._id, countries: r.data.countries });
          } else {
            showCard(this, `Error editing geos for campaign ${this._id}`, 'warning');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown error editing geos for campaign ${this._id}`);
          this.requesting = false;
        });
    },
  },
};
</script>

<style scoped>
.multiselect__tags {
  height: 500px !important;
}
</style>
