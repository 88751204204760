<template>
  <header>
    <b-navbar toggleable="lg" type="light" variant="transparent" class="border-bottom">
      <b-navbar-toggle target="nav-collapse" v-if="loggedIn" class="mr-1"></b-navbar-toggle>
      <b-navbar-brand to="/">{{ servicename_as_is }}</b-navbar-brand>
      <b-collapse id="nav-collapse" v-if="loggedIn" is-nav>
        <b-navbar-nav>
          <b-nav-item
            v-if="isAffiliate && user && user.maff"
            to="/geo-selection"
            active-class="active"
          >
            Geo Selection
          </b-nav-item>
          <b-nav-item v-if="isAdmin" to="/users" active-class="active">Users</b-nav-item>
          <b-nav-item v-if="isManager" to="/campaigns" active-class="active">Campaigns</b-nav-item>
          <b-nav-item v-if="isManager" to="/boxes" active-class="active">Boxes</b-nav-item>
          <b-nav-item-dropdown v-if="isManager" text="Test" active-class="active">
            <b-dropdown-item v-if="isManager" to="/test-lead" active-class="active">
              Test Lead
            </b-dropdown-item>
            <b-dropdown-item v-if="isManager" to="/test-pulling" active-class="active">
              Test Pulling
            </b-dropdown-item>
            <b-dropdown-item v-if="isAdmin" to="/test-gm-level-distribution" active-class="active">
              Test GMLevelDistribution
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="isAdmin" to="/upload-leads-file" active-class="active">
            Upload Leads File
          </b-nav-item>
          <b-nav-item v-if="isAdmin" to="/injections" active-class="active">Injections</b-nav-item>
          <b-nav-item to="/crm" :active="$route.path === '/crm' || $route.path === '/actions'">
            CRM
          </b-nav-item>
          <!--          <b-nav-item-->
          <!--            v-if="(isAdmin || (user && user.maff)) && servicename === 'grandmaster'"-->
          <!--            to="/prepared-to-push"-->
          <!--            active-class="active"-->
          <!--          >-->
          <!--            Prepared To Push-->
          <!--          </b-nav-item>-->
          <b-nav-item
            to="/deposits"
            :active="$route.path === '/deposits'"
            v-if="user && !user.maff"
          >
            Deposits
          </b-nav-item>
          <b-nav-item
            target="_blank"
            :href="maffRegisterLink"
            v-if="canPush && maffRegisterLink !== ''"
          >
            Add Lead
          </b-nav-item>
          <b-nav-item-dropdown
            v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
            active-class="active"
            text="System Data"
          >
            <b-dropdown-item v-if="isAdmin" to="/work-history" active-class="active">
              History
            </b-dropdown-item>
            <b-dropdown-item
              v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
              to="/daughters"
              active-class="active"
            >
              Daughters
            </b-dropdown-item>
            <b-dropdown-item
              v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
              to="/metacampaigns"
              active-class="active"
            >
              MetaCampaigns
            </b-dropdown-item>
            <b-dropdown-item
              v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
              to="/finalreceivers"
              active-class="active"
            >
              FinalReceivers
            </b-dropdown-item>

            <b-dropdown-item
              v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
              to="/injections-send-results"
              active-class="active"
            >
              Injection Send Results
            </b-dropdown-item>
            <!--            <b-dropdown-item to="/system-commands" active-class="active">Commands</b-dropdown-item>-->
          </b-nav-item-dropdown>
          <b-nav-item
            v-if="
              (OTHER_LEVEL_TRUSTED_USERS.includes(user.email) || canPush) &&
              (servicename === 'grandmaster' || servicename === 'localgm')
            "
            to="/psp"
            active-class="active"
          >
            PSP
          </b-nav-item>
          <b-nav-item
            v-if="
              (OTHER_LEVEL_TRUSTED_USERS.includes(user.email) || hasWorkers) &&
              (servicename === 'grandmaster' || servicename === 'localgm')
            "
            to="/workers"
            active-class="active"
          >
            Workers
          </b-nav-item>
          <b-nav-item-dropdown
            v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
            text="System Settings"
          >
            <b-dropdown-item
              v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
              to="/bce-preset"
              active-class="active"
            >
              BoxCampaign Default Settings
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)" text="Finance">
            <b-dropdown-item
              v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
              to="/financial-transactions"
              active-class="active"
            >
              Transactions
            </b-dropdown-item>
            <b-dropdown-item
              v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
              to="/balances"
              active-class="active"
            >
              Balances
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
      <b-navbar-nav class="mr-3 ml-auto" v-if="loggedIn">
        <b-nav-text>
          <h5>
            <b>
              <b-badge v-if="showFtds" :variant="ftds !== undefined ? 'success' : ''">
                FTDs
                <!--                  <span style="font-size: 2rem">-->
                <!--                    <b-badge :variant="ftds !== undefined ? 'success' : ''">-->
                {{ ftds !== undefined ? ftds : '...' }}
                <!--                    </b-badge>-->
                <!--                  </span>-->
              </b-badge>
            </b>
          </h5>
        </b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav class="mr-3 ml-auto" v-if="loggedIn">
        <b-nav-text>{{ currentTime }} GMT</b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav class="mr-3 ml-auto" v-if="loggedIn">
        <b-nav-text>Version: {{ metadata.build }}</b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav v-if="loggedIn" class="mr-3 ml-auto">
        <b-nav-item-dropdown>
          <template #button-content>
            <div class="d-inline-flex align-items-center">
              <span class="mr-3">{{ user && user.name ? user.name : 'Unknown' }}</span>
              <b-avatar></b-avatar>
            </div>
          </template>
          <b-dropdown-item :to="`/user/edit/${user._id}`" v-if="!isAffiliate">
            Profile
          </b-dropdown-item>
          <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav v-if="!loggedIn">
        <b-nav-item v-if="isAdmin">Version: {{ metadata.build }}</b-nav-item>
        <b-nav-item to="/login">Login</b-nav-item>
        <!--        <b-nav-item to="/system-commands">System Commands</b-nav-item>-->
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import * as metadata from '@/metadata.json';
import { getFtds } from '@/utils/api';
import { handleRequestError } from '@/utils';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';

export default {
  name: 'Header',
  data() {
    return {
      currentTime: DateTime.utc().toFormat('HH:mm'),
      metadata,
      servicename_as_is: process.env.VUE_APP_SERVICENAME,
      servicename: process.env.VUE_APP_SERVICENAME
        ? String(process.env.VUE_APP_SERVICENAME).toLowerCase()
        : undefined,
      ftds: undefined,
      showFtds: true,
      OTHER_LEVEL_TRUSTED_USERS,
    };
  },
  mounted() {
    this.getFtds();
    this.clockInterval = setInterval(() => {
      this.currentTime = DateTime.utc().toFormat('HH:mm');
    }, 2000);
    this.headerInterval = setInterval(() => this.getFtds(), 120000);
  },
  computed: {
    ...mapState(['loggedIn', 'user', 'isAdmin', 'isManager', 'isAffiliate', 'token']),
    canPush() {
      return this.user && (this.user.maff || this.user.canUploadLeads);
    },
    hasWorkers() {
      return this.user && this.user.hasWorkers;
    },
    maffRegisterLink() {
      console.log('maffRegisterLink user', this.user);
      if (process.env.VUE_APP_SERVICENAME.toLowerCase() !== 'grandmaster') return '';
      if (!(this.user && (this.user.maff || this.user.canUploadLeads))) return '';
      const regurl = process.env.VUE_APP_REGURL;
      const box =
        this.user.boxes && this.user.boxes.length > 0
          ? this.user.boxes[0]._id
            ? this.user.boxes[0]._id
            : this.user.boxes[0]
          : undefined;
      if (!regurl || !box) return '';
      return `${regurl}?key=${box}`;
    },
  },
  methods: {
    getFtds() {
      if (!this.loggedIn || !this.token) return;
      if (!this.user || this.user.integrator) return;
      getFtds(this.token)
        .then((r) => {
          if (r.data.success && r.data.ftds !== undefined) {
            this.showFtds = false;
            this.ftds = r.data.ftds;
            this.showFtds = true;
          } else {
            handleRequestError(this, r.data, `Unknown error updating headers ftds`);
          }
        })
        .catch((e) => {
          this.showFtds = false;
          console.error('Error updating headers ftds', e);
          this.showFtds = true;
          // handleRequestError(this, e, `Unknown server error updating headers ftds`);
        });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
