<template>
  <div>
    <!--    <div v-if="requesting" class="text-center text-primary my-2">-->
    <!--      <b-spinner class="align-middle"></b-spinner>-->
    <!--      <strong>Loading...</strong>-->
    <!--    </div>-->
    <LoadingSpinner :requesting="requesting" v-if="requesting" />
    <div v-else>
      <b-card-group columns>
        <b-card v-if="detailAvailable('affiliates') && !isAffiliate">
          <b-card-title>Affiliates</b-card-title>
          <b-list-group v-if="psp.affiliates">
            <b-list-group-item v-for="(b, index) in psp.affiliates" v-bind:key="index">
              {{ b.name }} {{ b.email }}
              <router-link :to="`/user/edit/${b._id}`">Edit</router-link>
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <b-card v-if="detailAvailable('leads')">
          <b-card-title>Leads</b-card-title>
          <b-list-group v-if="psp.actions">
            <b-list-group-item v-for="(b, index) in psp.actions" v-bind:key="index">
              <b-badge variant="info" class="mx-1" v-if="b.leadContactInfo">
                {{ b.leadContactInfo.email }}
              </b-badge>
              <b-badge variant="info" class="mx-1" v-if="b.leadContactInfo">
                {{ b.leadContactInfo.phone }}
              </b-badge>
              <b-badge :variant="b.leadContactInfo ? 'info' : 'warning'" class="mx-1">
                {{ b._id }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card v-if="detailAvailable('details')">
          <b-card-title>Details</b-card-title>
          <b-list-group>
            <b-list-group-item>Type: {{ psp.type }}</b-list-group-item>
            <b-list-group-item>Number: {{ psp.number }}</b-list-group-item>
          </b-list-group>
        </b-card>
      </b-card-group>
      <p v-if="detailAvailable('_id')">_id: {{ psp._id }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getPSP } from '@/utils/api';
import { handleRequestError, showCard, tableFormatterFullCardNumber } from '@/utils';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

export default {
  name: 'PSPTableDetails',
  components: { LoadingSpinner },
  props: {
    _id: { type: String, default: '' },
    sortedSelectedDetails: { type: Array, default: () => [] },
  },
  data() {
    return { psp: {}, requesting: false };
  },
  computed: {
    ...mapState(['token', 'isAffiliate']),
  },
  methods: {
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    getPSP() {
      this.requesting = true;
      const { _id } = this;
      console.log(`Getting psp ${_id} for details`);
      getPSP(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castPSPToForm(r.data);
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting psp`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning', 'Error getting psp');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error on getting psp', 'Error getting psp');
          this.requesting = false;
        });
    },
    castPSPToForm(psp) {
      // cast campaign to edit form
      // prettier-ignore
      this.psp = {
        _id: psp._id ? psp._id : undefined,
        affiliates: psp.affiliates.filter(a => a).map((a) => {
          return {_id: a._id ? a._id : a, name: a.name ? a.name : 'unknown' };
        }),
        actions:psp.actions?psp.actions:[],
        number:this.numberFormatter(psp.number? psp.number : undefined),
        comment:psp.comment? psp.comment : undefined,
        type:psp.type? psp.type : undefined,
      };
    },
    numberFormatter(value) {
      return this.psp.type === 'card' ? tableFormatterFullCardNumber(value) : value;
    },
  },
  mounted() {
    this.getPSP();
  },
};
</script>

<style scoped></style>
