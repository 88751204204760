<template>
  <div>
    <!--    <b-button variant="primary" class="mb-4 h5 font-weight-normal mt-5 mx-2" v-b-modal.filter-modal>-->
    <!--      Filter-->
    <!--    </b-button>-->
    <!--    <b-modal id="filter-modal" ref="filter-modal" size="giant" hide-footer hide-header>-->
    <b-row class="mt-2 mb-3">
      <!--      class="col-12 col-12 col-md-3 col-lg-2 mb-3"-->
      <b-col :class="filterColClass" v-if="!isAffiliate && !user.integrator">
        <span class="d-block h5 font-weight-normal mt-1">Affiliates</span>
        <multiselect
          id="affiliates"
          v-model="filterForm.affiliates"
          :options="affiliatesOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        >
          <!--                    @input="updateBackdoor()"
    -->
        </multiselect>
      </b-col>
      <!--class="col-12 col-12 col-md-8 col-lg-4 mb-3"-->
      <b-col :class="filterColClass">
        <span class="d-block h5 font-weight-normal mt-1">Date From</span>
        <div class="d-sm-flex align-items-center padded-plain-input">
          <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
          <!--            <span class="mr-sm-2">From</span>-->
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="date-from"
            today-button
            reset-button
            close-button
            :max="filterForm.to"
            v-model="filterForm.from"
          ></b-form-datepicker>
          <!--                          @input="updateBackdoor()"
    -->
        </div>
      </b-col>
      <!--class="col-12 col-12 col-md-8 col-lg-4 mb-3"-->
      <b-col :class="filterColClass">
        <span class="d-block h5 font-weight-normal mt-1">Date To</span>
        <div class="d-sm-flex align-items-center padded-plain-input">
          <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
          <!--            <span class="mx-sm-2">To</span>-->
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="date-to"
            today-button
            reset-button
            close-button
            :min="filterForm.from"
            v-model="filterForm.to"
          ></b-form-datepicker>
          <!--                          @input="updateBackdoor()"
    -->
        </div>
        <!--        </div>-->
      </b-col>
      <!--class="col-12 col-12 col-md-6 col-lg-4 mb-3"-->
      <b-col :class="filterColClass">
        <span class="d-block h5 font-weight-normal mt-1">Geos</span>
        <multiselect
          v-model="filterForm.locations"
          :options="countryOptions"
          track-by="value"
          :custom-label="countryWithCode"
          @search-change="countryMultiSelectSearch"
          :closeOnSelect="false"
          :multiple="true"
        >
          <!--                    @input="updateBackdoor()"
    -->
          <!--                        :showLabels="false"
    -->
        </multiselect>
      </b-col>
      <!--class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0"-->
      <b-col :class="filterColClass" v-if="!isAffiliate">
        <span class="d-block h5 font-weight-normal mt-1">Campaigns</span>
        <multiselect
          v-model="filterForm.campaigns"
          :options="campaignsOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
        <!--                  @input="updateBackdoor()"
    -->
      </b-col>
      <b-col :class="filterColClass" v-if="!isAffiliate && !user.integrator && !$rent">
        <span class="d-block h5 font-weight-normal mt-1">Meta Campaigns</span>
        <multiselect
          v-model="filterForm.metaCampaigns"
          :options="metaCampaignsOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
          :taggable="true"
          @tag="addMetaCampaignTag"
        ></multiselect>
        <!--                  @input="updateBackdoor()"
    -->
      </b-col>
      <b-col
        :class="filterColClass"
        v-if="!isAffiliate && iAmOtherLevelTrustedUser && !user.integrator && !$rent"
      >
        <span class="d-block h5 font-weight-normal mt-1">Final Receivers</span>
        <multiselect
          v-model="filterForm.finalReceivers"
          :options="finalReceiversOptions"
          :closeOnSelect="false"
          :multiple="true"
          :taggable="true"
          @tag="addFinalReceiverTag"
        ></multiselect>
        <!--                  @input="updateBackdoor()"
    -->
      </b-col>
      <!--      class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0"-->
      <b-col
        :class="filterColClass"
        v-if="!isAffiliate && !onDeposits && !user.integrator && !$rent"
      >
        <span class="d-block h5 font-weight-normal mt-1">Campaigns In Selection Dropouts</span>
        <multiselect
          v-model="filterForm.campaignsInSelectionDropouts"
          :options="campaignsOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
        <!--                  @input="updateBackdoor()"
    -->
      </b-col>
      <!--class="col-12 col-12 col-md-6 col-lg-4 mb-3 mb-lg-0"-->
      <b-col :class="filterColClass" v-if="!isAffiliate && !user.integrator">
        <span class="d-block h5 font-weight-normal mt-1">Boxes</span>
        <multiselect
          v-model="filterForm.boxes"
          :options="boxesOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        >
          <!--                    @input="updateBackdoor()"
    -->
        </multiselect>
      </b-col>
      <!--class="col-12 col-12 col-md-3 col-lg-2 mb-3 mb-lg-0"-->
      <b-col
        :class="filterColClass"
        v-if="!isAffiliate && !onDeposits && !user.integrator && !$rent"
      >
        <span class="d-block h5 font-weight-normal mt-1">Avoid Boxes</span>
        <multiselect
          v-model="filterForm.avoidBoxes"
          :options="boxesOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        >
          <!--                    @input="updateBackdoor()"
    -->
        </multiselect>
      </b-col>
      <!--class="col-12 col-12 col-md-3 col-lg-2 mb-3 mb-lg-0"-->
      <b-col :class="filterColClass" v-if="(!isAffiliate || user.hasWorkers) && !user.integrator">
        <span class="d-block h5 font-weight-normal mt-1">Workers</span>
        <div class="d-flex">
          <LoadingSpinner
            :requesting="requesting_workersOptions"
            v-if="requesting_workersOptions"
            class="mx-1"
          />
          <multiselect
            v-model="filterForm.workers"
            :options="workersOptions"
            track-by="name"
            label="name"
            :closeOnSelect="false"
            :multiple="true"
            :disabled="requesting_workersOptions"
          />
        </div>
      </b-col>
      <!--class="col-12 col-12 col-md-3 col-lg-2"-->
      <b-col :class="filterColClass" v-if="isAdmin">
        <span class="d-block h5 font-weight-normal mt-1">Injection ID</span>
        <b-form-input v-model="filterForm.sentByInj"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--      class="col-12 col-12 col-md-3 col-lg-2"-->
      <b-col :class="filterColClass">
        <span class="d-block h5 font-weight-normal mt-1">Email</span>
        <b-form-input v-model="filterForm.email"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--      class="col-12 col-12 col-md-3 col-lg-2"-->
      <b-col :class="filterColClass" v-if="!onDeposits">
        <span class="d-block h5 font-weight-normal mt-1">Phone</span>
        <b-form-input v-model="filterForm.phone"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--      class="col-12 col-12 col-md-3 col-lg-2"-->
      <b-col :class="filterColClass" v-if="!onDeposits && !user.trader">
        <span class="d-block h5 font-weight-normal mt-1">IP</span>
        <b-form-input v-model="filterForm.ip"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--      class="col-12 col-12 col-md-3 col-lg-2"-->
      <b-col :class="filterColClass" v-if="!$rent">
        <span class="d-block h5 font-weight-normal mt-1">ID</span>
        <b-form-input v-model="filterForm._id"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--      class="col-12 col-12 col-md-3 col-lg-2"-->
      <b-col :class="filterColClass" v-if="!onDeposits && !user.trader">
        <span class="d-block h5 font-weight-normal mt-1">Sales Status</span>
        <b-form-input v-model="filterForm.salesStatus"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--      class="col-12 col-12 col-md-3 col-lg-2" -->
      <b-col :class="filterColClass" v-if="!isAffiliate && !onDeposits && !$rent">
        <span class="d-block h5 font-weight-normal mt-1">Sales Status In History</span>
        <b-form-input v-model="filterForm.salesStatusInHistory"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--class="col-12 col-12 col-md-3 col-lg-2"-->
      <b-col :class="filterColClass" v-if="!isAffiliate && !onDeposits">
        <span class="d-block h5 font-weight-normal mt-1">Client's API URL</span>
        <b-form-input v-model="filterForm.clientsApiURL"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <!--    </b-row>-->
      <!--     class="col-12 col-12 col-md-4 col-lg-3"-->
      <!--    <b-row>-->
      <b-col :class="filterColClass" v-if="!isAffiliate && !onDeposits && !user.integrator">
        <b-form-checkbox
          v-model="filterForm.showPositiveStatuses"
          class="mb-2 d-block h5 font-weight-normal mt-2"
        >
          Show positive sales statuses
        </b-form-checkbox>
        <b-form-checkbox
          v-model="filterForm.showPositiveToNegatives"
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-if="!isAffiliate && !onDeposits && !user.integrator"
        >
          Show positive-to-negative changers only
        </b-form-checkbox>
        <b-form-checkbox
          v-model="filterForm.showOnlyM"
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-if="!isAffiliate && !(!user || user.integrator) && !$rent"
        >
          Show only M
        </b-form-checkbox>
        <b-form-checkbox
          v-model="filterForm.showOnlyChecked"
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-if="!isAffiliate && !user.integrator && !$rent"
        >
          Show only Checked (FTD Done)
        </b-form-checkbox>
        <b-form-checkbox
          v-model="filterForm.showOnlyShaved"
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-if="!isAffiliate && !user.integrator && !$rent"
        >
          Show only Shaved
        </b-form-checkbox>
        <b-form-checkbox
          v-model="filterForm.errorOnSend"
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-if="user && user.name === 'blacklight'"
        >
          Show only leads with Error On Send
        </b-form-checkbox>
      </b-col>
      <!--      class="col-12 col-12 col-md-4 col-lg-3"-->
      <b-col :class="filterColClass" v-if="!user.trader">
        <!--        <input-with-help-->
        <!--          class="mb-2 mt-2"-->
        <!--          help-text="Hide FTDs that we marked as test for our affiliates"-->
        <!--        >-->
        <b-form-checkbox
          v-model="filterForm.hideTestFtds"
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-if="!isAffiliate"
        >
          Hide Affiliates' Test Ftds
        </b-form-checkbox>
        <!--        </input-with-help>-->
        <!--        <input-with-help-->
        <!--          class="mb-2 d-block h5 font-weight-normal mt-2"-->
        <!--          help-text="Hide all actions that we sent to the clients through Test Boxes"-->
        <!--        >-->
        <b-form-checkbox
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-model="filterForm.hideTestActions"
        >
          Hide Our Test Actions
        </b-form-checkbox>
        <!--        </input-with-help>-->
        <b-form-checkbox
          v-if="isAdmin && !onDeposits && !$rent"
          v-model="filterForm.showOnlyParanoidUniq"
          class="mb-2 d-block h5 font-weight-normal mt-2"
        >
          Show only paranoid uniq
        </b-form-checkbox>
        <b-form-checkbox
          v-model="filterForm.showPreparedToWork"
          class="mb-2 d-block h5 font-weight-normal mt-2"
          v-if="
            (iAmOtherLevelTrustedUser ||
              canPush ||
              user.canSendCAutomatically ||
              user.campaignsAccess) &&
            !$rent
          "
        >
          Show Prepared To Work
        </b-form-checkbox>
        <b-form-checkbox
          v-if="isAdmin && !onDeposits && !onPreparedToPush && !$rent"
          v-model="filterForm.showOnlyPreparedToPush"
          class="mb-2 d-block h5 font-weight-normal mt-2"
        >
          <!--           || canPush-->
          Show only Prepared to Push
        </b-form-checkbox>
        <b-form-checkbox
          v-if="isAdmin && !onDeposits && !onPreparedToPush && !$rent"
          v-model="filterForm.hidePreparedToPush"
          class="mb-2 d-block h5 font-weight-normal mt-2"
        >
          <!--           || canPush-->
          Hide Prepared to Push
        </b-form-checkbox>
      </b-col>
      <!--      class="col-12 col-12 col-md-4 col-lg-2"-->
      <b-col :class="filterColClass" v-if="!onDeposits && !$rent">
        <span class="d-block h5 font-weight-normal mt-1">Sort by</span>
        <b-form-select
          v-model="filterForm.sortBy"
          :options="sortByOptions"
          class="mb-2 d-block h5 font-weight-normal"
        ></b-form-select>
        <b-form-radio-group
          id="btn-radios-1"
          size="md"
          button-variant="outline-primary"
          v-model="filterForm.sortOrder"
          :options="sortOrderOptions"
          name="radios-btn-default"
          buttons
        ></b-form-radio-group>
      </b-col>
      <!--      class="col-12 col-12 col-md-8 col-lg-4"-->
      <b-col :class="filterColClass">
        <span class="d-block h5 font-weight-normal mx-2">Controls</span>
        <div>
          <b-button
            @click="applyFilter()"
            :disabled="!canApplyFilter"
            variant="primary"
            class="mb-2 h5 font-weight-normal mt-2 mx-2"
          >
            <b-spinner small v-if="requesting"></b-spinner>
            Apply filter
          </b-button>
          <b-button
            @click="clearFilterForm()"
            class="mb-2 h5 font-weight-normal mt-2 mx-2"
            :disabled="requesting"
          >
            <b-spinner small v-if="requesting"></b-spinner>
            Clear filter
          </b-button>
          <b-button
            @click="updateData()"
            :disabled="requesting"
            class="mb-2 h5 font-weight-normal mt-2 mx-2"
            v-if="!$rent"
          >
            <b-spinner small v-if="requesting"></b-spinner>
            <span v-else>🔄</span>
          </b-button>
          <!--          <b-checkbox v-model="useNewFilterAlgorithm">Use New Filter Algorithm</b-checkbox>-->
        </div>
        <div
          v-if="
            (isAdmin || (user.maff && user.canSendMAutomatically) || user.canSendCAutomatically) &&
            !$rent
          "
        >
          <b-form-checkbox v-model="urlsRequestParams.useTTL" class="mx-2 my-1">
            Use FinalReceiver TimeToLive
          </b-form-checkbox>
          <b-form-checkbox v-model="urlsRequestParams.usePartialPhoneFilter" class="mx-2 my-1">
            Filter By Similar Phones Sent
          </b-form-checkbox>
          <b-form-checkbox
            v-model="urlsRequestParams.filterByWorkersConnectedFinalReceivers"
            class="mx-2 my-1"
          >
            Filter By Action's Worker Sent To FinalReceivers
          </b-form-checkbox>
          <b-button
            @click="checkListedActionsCanSendAsM()"
            variant="primary"
            class="mb-2 h5 font-weight-normal mt-2 mx-2"
            :disabled="checkingTimesCanSendInProgress"
          >
            <b-spinner small v-if="checkingTimesCanSendInProgress" class="mx-1"></b-spinner>
            Calculate TimesCanSendM
          </b-button>
          <b-button
            @click="abortTimesCanSendCheck()"
            variant="warning"
            class="mb-2 h5 font-weight-normal mt-2 mx-2"
            :disabled="!checkingTimesCanSendInProgress"
          >
            Abort Calculating
          </b-button>
        </div>
      </b-col>

      <!--      :disabled="!canApplyFilter"-->
    </b-row>
    <!--    </b-modal>-->
  </div>
</template>

<script>
import _, { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import {
  countryOptions,
  dateFormatOptions,
  initialFilterForm,
  OTHER_LEVEL_TRUSTED_USERS,
  sortOrderOptions,
} from '@/consts';
import { startCase } from '@/utils/string';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import {
  countryMultiSelectSearch,
  countryWithCode,
  finalReceiversOptions,
  workersOptions,
  handleRequestError,
  metaCampaignsOptions,
} from '@/utils';
import { getWorkersOptions } from '@/utils/api';
// import InputWithHelp from '@/components/shared/InputWithHelp.vue';

export default {
  name: 'ActionsFilterForm',
  components: {
    // InputWithHelp,
    LoadingSpinner,
  },
  props: {
    checkingTimesCanSendInProgress: { type: Boolean, default: true, required: true },
    form: {
      type: Object,
      default: () => {
        return cloneDeep(initialFilterForm);
      },
      required: true,
    },
    appliedFilterForm: {
      type: Object,
      default: () => {
        return cloneDeep(initialFilterForm);
      },
      required: true,
    },
    userAvailableFields: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
  },
  data() {
    return {
      dateFormatOptions,
      countryOptions,
      filterForm: cloneDeep(this.$props.form),
      requesting_workersOptions: false,
      workersOptions_inner: [],
      sortOrderOptions,
      urlsRequestParams: {
        useTTL: false,
        usePartialPhoneFilter: true,
        filterByWorkersConnectedFinalReceivers: true,
      },
      filterColClass: 'col-12 col-12 col-md-4 col-lg-3',
      finalReceiversTags: [],
      metaCampaignsTags: [],
    };
  },
  computed: {
    ...mapState([
      'user',
      'isAdmin',
      'isManager',
      'isAffiliate',
      'token',
      'affiliates',
      'campaigns',
      'boxes',
      'requesting',
      'finalReceivers',
    ]),
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email); // TRUSTED_USERS
    },
    onDeposits() {
      return this.$route.path === '/deposits';
    },
    onPreparedToPush() {
      return this.$route.path === '/prepared-to-push';
    },
    canPush() {
      return this.user.maff;
    },
    canApplyFilter() {
      return (
        !this.requesting && !_.isEqual(this.appliedFilterForm, this.filterForm) // &&
        // !_.isEqual(this.filterForm, initialFilterForm)
      );
    },
    sortByOptions() {
      return this.userAvailableFields.map((field) => {
        return {
          text: field.label ? field.label : startCase(field.key ? field.key : String(field)),
          value: field.key,
        };
      });
    },
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    campaignsOptions() {
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    metaCampaignsOptions() {
      return metaCampaignsOptions(this, this.metaCampaignsTags);
    },
    finalReceiversOptions() {
      // return this.finalReceivers ? this.finalReceivers : [];
      return finalReceiversOptions(this, this.finalReceiversTags);
    },
    workersOptions() {
      return workersOptions(this);
    },
    boxesOptions() {
      return this.boxes.map((b) => {
        return { name: b.name, _id: b._id };
      });
    },
  },
  methods: {
    addFinalReceiverTag(newTag) {
      const tag = newTag;
      this.finalReceiversTags = [...this.finalReceiversTags, tag];
      if (this.filterForm.finalReceivers) {
        this.filterForm.finalReceivers.push(tag);
      } else {
        this.filterForm.finalReceivers = [tag];
      }
    },
    addMetaCampaignTag(newTag) {
      const tag = {
        name: newTag,
        _id: newTag,
      };
      this.metaCampaignsTags = [...this.metaCampaignsTags, tag];
      if (this.filterForm.metaCampaigns) {
        this.filterForm.metaCampaigns.push(tag);
      } else {
        this.filterForm.metaCampaigns = [tag];
      }
    },
    applyFilter() {
      // console.log('ActionsFilterForm applyFilter', this.filterForm);
      this.$emit('applyFilter', this.filterForm);
    },
    clearFilterForm() {
      this.filterForm = _.cloneDeep(initialFilterForm);
      this.$emit('clearFilterForm', this.filterForm);
    },
    checkListedActionsCanSendAsM() {
      this.$emit('checkListedActionsCanSendAsM', this.urlsRequestParams);
    },
    abortTimesCanSendCheck() {
      this.$emit('abortTimesCanSendCheck');
    },
    updateData() {
      this.$emit('updateData', this.filterForm);
    },
    countryMultiSelectSearch,

    countryWithCode(c) {
      return countryWithCode(c);
    },
    getWorkersOptions() {
      this.requesting_workersOptions = true;
      getWorkersOptions(this.token)
        .then((r) => {
          this.workersOptions_inner = Array.isArray(r.data) ? r.data : [];
          this.requesting_workersOptions = false;
        })
        .catch((e) => {
          this.workersOptions_inner = [];
          handleRequestError(this, e, 'Error getting workers options');
          this.requesting_workersOptions = false;
        });
    },
  },
  async mounted() {
    this.getWorkersOptions();
  },
  watch: {
    $route() {
      this.getWorkersOptions();
    },
    workersOptions() {
      this.$emit('updateWorkersOptions', this.workersOptions);
    },
  },
};
</script>

<style scoped>
.bordered-column {
  border: 1px solid #eaeaea;
}
/*.padded-plain-input {*/
/*  padding: 0rem 0rem 0.375rem 0rem;*/
/*}*/
</style>
