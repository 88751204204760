<template>
  <b-container fluid>
    <b-row class="my-3" align-v="center">
      <b-col cols="12" lg="2" class="my-lg-0 my-1"><h2>Boxes</h2></b-col>
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="4"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <!--        <div class="d-flex align-items-center justify-content-end">-->
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="4" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
        <b-button to="/create-box" variant="primary">Create</b-button>
        <!--        </div>-->
        <div>
          <span class="mx-2">Edit</span>
          <b-button v-b-toggle.sidebar-right-table @click="openSidebarColumns()" class="mx-2">
            Table
          </b-button>
          <b-button v-b-toggle.sidebar-right-details @click="openSidebarDetails()" class="mx-2">
            Details
          </b-button>
          <b-button v-b-toggle.sidebar-right-hidden @click="openSidebarHidden()" class="mx-2">
            Hidden
          </b-button>
          <b-sidebar
            id="sidebar-right-table"
            v-model="sidebarColumnsOpen"
            title="Table Fields"
            right
            shadow
          >
            <div class="px-3 py-2">
              <b-form-checkbox-group
                v-model="preSelectedColumns"
                :options="tableFieldsOptions"
                class="mb-3"
                @input="logSelectedColumns()"
                stacked
              ></b-form-checkbox-group>
              <b-button
                variant="primary"
                @click="applyFields()"
                :disabled="!canApplyFields"
                class="my-2 sidebar-button"
              >
                Apply
              </b-button>
              <b-button
                variant="success"
                @click="saveSelectedFields('boxes_table')"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="warning"
                @click="restoreTablePreference()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
          <b-sidebar
            id="sidebar-right-details"
            v-model="sidebarDetailsOpen"
            title="Show Details"
            right
            shadow
          >
            <div class="px-3 py-2">
              <b-form-checkbox-group
                v-model="preSelectedDetails"
                :options="detailsOptions"
                class="mb-3"
                @input="logSelectedDetails()"
                stacked
              ></b-form-checkbox-group>
              <b-button
                variant="primary"
                @click="applyDetails()"
                :disabled="!canApplyDetails"
                class="my-2 sidebar-button"
              >
                Apply
              </b-button>
              <b-button
                variant="success"
                @click="saveSelectedFields('boxes_details')"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="warning"
                @click="restoreDetailsPreference()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
          <b-sidebar
            id="sidebar-right-hidden"
            v-model="sidebarHiddenOpen"
            title="Hidden Boxes"
            right
            shadow
          >
            <div class="px-3 py-2">
              <multiselect
                id="user-hidden-boxes"
                v-model="hidden"
                :options="boxesOptions"
                track-by="name"
                label="name"
                :closeOnSelect="false"
                :multiple="true"
              ></multiselect>
              <b-button
                variant="success"
                @click="editHiddenBoxes()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Save
              </b-button>
              <b-button
                variant="secondary"
                @click="takeDefaultHidden()"
                :disabled="requesting"
                class="my-2 sidebar-button"
              >
                <b-spinner small v-if="requesting"></b-spinner>
                Restore
              </b-button>
            </div>
          </b-sidebar>
        </div>
      </b-col>
    </b-row>
    <b-table
      class="mb-3"
      sort-by="active"
      :sort-desc="true"
      :fields="sortedSelectedFields"
      :items="realitems"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterIncludedFields"
      :busy="requesting"
      @filtered="onFiltered"
      responsive
      show-empty
      striped
      hover
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>

      <template #cell(active)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'danger'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(isTest)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'warning' : 'info'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(isMBox)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'warning' : 'info'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(loadBalancer)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'warning'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>
      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button
            variant="success"
            v-if="!data.item.active"
            @click="makeBoxActive(data.item._id, true)"
          >
            <b-icon icon="play-fill"></b-icon>
            Play
          </b-button>
          <b-button
            variant="warning"
            v-if="data.item.active"
            @click="makeBoxActive(data.item._id, false)"
            :disabled="data.item.defaultCampaignsBox"
          >
            <b-icon icon="pause-fill"></b-icon>
            Pause
          </b-button>
          <b-button
            variant="primary"
            :to="`/box/edit/${data.item._id}`"
            :disabled="data.item.defaultCampaignsBox"
          >
            <b-icon icon="pencil-square"></b-icon>
            Edit
          </b-button>
          <b-button variant="danger" @click="hideBox(data.item._id)">
            <b-icon icon="eye-slash"></b-icon>
            Hide
          </b-button>
          <b-button
            v-if="data.item._id"
            v-b-modal="`modal-affiliate-api-docs-${data.item._id}`"
            style="display: inline; vertical-align: middle"
            variant="info"
            v-b-popover.hover.top="'Affiliate API Docs'"
          >
            <i :class="[`bi-gear-wide-connected`]"></i>
            API
          </b-button>
          <b-button
            v-if="data.item._id && iAmOtherLevelTrustedUser"
            v-b-modal="`modal-affiliate-api-docs-${data.item._id}`"
            style="display: inline; vertical-align: middle"
            variant="info"
            v-b-popover.hover.top="'Create Full Copy Of This Box'"
          >
            <i :class="[`bi-clipboard`]"></i>
            Full Copy
          </b-button>
          <b-modal
            v-if="data.item._id"
            :id="`modal-affiliate-api-docs-${data.item._id}`"
            :ref="`modal-affiliate-api-docs-${data.item._id}`"
            centered
            ok-only
            ok-title="Close"
            size="giant"
            scrollable
            :title="`Affiliate API Integration Docs`"
          >
            <affiliate-api-docs
              :aff-id="data.item.affiliate._id"
              :box-id="data.item._id"
            ></affiliate-api-docs>
          </b-modal>
          <!--          <b-button @click="data.toggleDetails">-->
          <!--            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details-->
          <!--          </b-button>-->
        </b-button-group>
      </template>
      <template #cell(countries)="data">
        <span v-if="data.item.countries.length <= 4">{{ data.item.countries.join(', ') }}</span>
        <span v-else>{{ data.item.countries.length }}</span>
      </template>
      <template #row-details="row">
        <!-- TODO: Decide how we want details to be and Render all needed details properly -->
        <h4 v-if="detailAvailable('id')">ID: {{ row.item._id }}</h4>
        <b-card-group columns>
          <b-card v-if="detailAvailable('affiliate')">
            <b-card-title>
              Affiliate
              <router-link :to="`/user/edit/${row.item.affiliate._id}`">
                <b-badge variant="primary">{{ row.item.affiliate.name }}</b-badge>
              </router-link>
            </b-card-title>
          </b-card>
          <b-card v-if="detailAvailable('manager')">
            <b-card-title>
              Manager
              <router-link
                v-if="row.item.manager._id !== 'unknown'"
                :to="`/user/edit/${row.item.manager._id}`"
                class="mx-1"
              >
                <b-badge variant="primary">{{ row.item.manager.name }}</b-badge>
              </router-link>
              <b-badge class="mx-1" variant="warning" v-else>not assigned</b-badge>
            </b-card-title>
          </b-card>
          <b-card v-if="detailAvailable('countries')">
            <b-card-title>
              Geos
              <b-badge
                variant="info"
                class="mx-1"
                v-for="(c, index) in row.item.countries"
                v-bind:key="index"
              >
                {{ c }}
              </b-badge>
              <!--              This is a wider card with supporting text below as a natural lead-in to additional content.-->
              <!--              This content is a little bit longer.-->
            </b-card-title>
          </b-card>

          <b-card v-if="detailAvailable('campaigns')">
            <b-card-title>
              Campaigns
              <!--              <div v-for="(b, index) in row.item.boxesArray" v-bind:key="index">-->
              <router-link
                v-for="(c, index) in row.item.campaignsToShow"
                v-bind:key="index"
                :to="`/campaign/edit/${c._id}`"
              >
                <b-badge variant="primary" class="mx-1">{{ c.name }}</b-badge>
              </router-link>
              <!--              </div>-->
            </b-card-title>
          </b-card>
          <b-card header="Add Multiple Campaigns to this Box for Multiple GEOs">
            <AddCampaignToBox :box="getThisBox(row.item)" />
          </b-card>
          <b-card header="Remove Multiple Campaigns from this Box for Multiple GEOs">
            <RemoveCampaignsFromBoxes :box="getThisBox(row.item)" />
          </b-card>
          <b-card header="Remove Multiple Campaigns from this Box for Multiple GEOs">
            <ActionsDistributionSimulator
              v-if="detailAvailable('ActionsDistributionSimulator')"
              :_id="row.item._id"
              :countries="row.item.countries"
            ></ActionsDistributionSimulator>
          </b-card>
        </b-card-group>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row class="mb-3">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <!--        <div class="d-flex align-items-center justify-content-end">-->
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
        ></b-form-select>
        <!--        </div>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import '@/assets/badge-toogle.css';

import { saveBox, hideBox, editHiddenBoxes } from '@/utils/api'; // getBoxes,
import {
  handleRequestError,
  showCard,
  tableFormatterArrayValues,
  tableFormatterId,
  tableFormatterNameAndId,
  tableFormatterCapOneCell,
  tableFormatterHourLimit,
} from '@/utils';
import { mapState } from 'vuex';
import _ from 'lodash';
import store from '@/store';
import { userAvailableDetails, userAvailableFields } from '@/consts/userAvailableFields';
import {
  applyDetails,
  applyFields,
  canApplyDetails,
  canApplyFields,
  detailsOptions,
  saveSelectedFieldsMethod,
  sortedSelectedDetails,
  sortedSelectedFields,
  tableFieldsOptions,
  takeDefaultDetailsOptions,
  takeDefaultHidden,
  takeDefaultTableFields,
} from '@/utils/fieldsAndDetailsOptionsHelpers';
import AddCampaignToBox from '@/components/shared/AddCampaignToBox.vue';
import RemoveCampaignsFromBoxes from '@/components/shared/RemoveCampaignsFromBoxes.vue';
import AffiliateApiDocs from '@/components/shared/AffiliateApiDocs/AffiliateApiDocs.vue';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import ActionsDistributionSimulator from './components/ActionsDistributionSimulator.vue';

export default {
  name: 'BoxesTable',
  components: {
    ActionsDistributionSimulator,
    AddCampaignToBox,
    RemoveCampaignsFromBoxes,
    AffiliateApiDocs,
  },
  data() {
    return {
      backdoor: 0,
      fields: [
        { key: '_id', label: 'ID', sortable: true, formatter: tableFormatterId },
        { key: 'name', sortable: true },
        { key: 'active', sortable: true },
        'controls',
        { key: 'affiliate', sortable: true, formatter: tableFormatterNameAndId },
        { key: 'manager', sortable: true, formatter: tableFormatterNameAndId },
        {
          key: 'countries',
          sortable: true,
          formatter: tableFormatterArrayValues,
        },
        { key: 'campaigns', sortable: true }, // label: 'Campaigns',
        {
          key: 'workingHours',
          sortable: true,
          formatter: (value) => tableFormatterHourLimit(value),
        },
        { key: 'dailyCap', sortable: true, formatter: tableFormatterCapOneCell }, // label: 'Cap',
        { key: 'totalCap', sortable: true, formatter: tableFormatterCapOneCell }, // label: 'Type',
        { key: 'isTest', label: 'Test', sortable: true },
        // { key: 'actionsToday', label: 'Actions Today', sortable: true },
        // { key: 'impressionsToday', sortable: true }, // label: 'Impressions',
        // { key: 'clicksToday', sortable: true }, // label: 'Clicks',
        // { key: 'leadsToday', sortable: true }, // label: 'Leads',
        // { key: 'ftdsToday', sortable: true }, // label: 'FTDs',
        // { key: 'payoutsToday', sortable: true }, // label: 'Payouts',
        // { key: 'revenue', label: 'Revenue Today', sortable: true }, // need broker payout
        // { key: 'profit', label: 'Profit Today', sortable: true }, // need broker payout
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      // boxes: [],
      // actions: [],
      selectedColumns: [],
      preSelectedColumns: [],
      selectedDetails: [],
      preSelectedDetails: [],
      hidden: [],
      sidebarColumnsOpen: false,
      sidebarDetailsOpen: false,
      sidebarHiddenOpen: false,
      filterIncludedFields: ['_id', 'name'],
    };
  },
  computed: {
    ...mapState([
      'user',
      'loggedIn',
      'token',
      'actions',
      'boxes',
      'campaigns',
      'store_backdoor',
      'isAdmin',
      'isManager',
      'isMasterAffiliate',
      'requesting',
    ]),
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    boxesOptions() {
      this.backdoor;
      // console.log('boxesOptions');
      return this.boxes.map((b) => {
        return { name: b.name, _id: b._id };
      });
    },
    canApplyFields() {
      return canApplyFields(this);
    },
    canApplyDetails() {
      return canApplyDetails(this);
    },
    userAvailableFields() {
      console.time('userAvailableFields');
      let r;
      if (this.isAdmin) r = userAvailableFields.boxes.admin();
      else if (this.isManager) r = userAvailableFields.boxes.manager();
      else if (this.isMasterAffiliate) r = userAvailableFields.boxes.masteraffiliate();
      else r = userAvailableFields.boxes.affiliate();
      console.timeEnd('userAvailableFields');
      return r;
    },
    tableFieldsOptions() {
      return tableFieldsOptions(this);
    },
    sortedSelectedFields() {
      return sortedSelectedFields(this);
    },
    userAvailableDetails() {
      if (this.isAdmin) return userAvailableDetails.boxes.admin();
      if (this.isManager) return userAvailableDetails.boxes.manager();
      if (this.isMasterAffiliate) return userAvailableDetails.boxes.masteraffiliate();
      return userAvailableDetails.boxes.affiliate();
    },
    detailsOptions() {
      return detailsOptions(this);
    },
    sortedSelectedDetails() {
      return sortedSelectedDetails(this);
    },
    realitems() {
      // this.backdoor;
      this.store_backdoor;
      return this.boxes
        .filter((b) => {
          if (!this.user) return false;
          const hidden = this.user.hiddenBoxes
            ? this.user.hiddenBoxes.map((h) => (h._id ? h._id : h))
            : undefined;
          return !(hidden && Array.isArray(hidden) && hidden.includes(b._id));
        })
        .map((b) => {
          // const boxActionsToday = this.actions.filter(
          //   (a) => a.box && (a.box === b._id || a.box._id === b._id)
          // );
          return {
            _id: b._id,
            name: b.name,
            active: b.active,
            affiliate: b.affiliate, // .name,
            manager: b.manager ? b.manager : { name: '?', _id: 'unknown' }, // .name : '?',
            countries: _.uniq(_.flatten((b.campaigns ? b.campaigns : []).map((c) => c.countries))),
            campaigns: b.campaigns ? b.campaigns.length : 0,
            campaignsArray: b.campaigns ? b.campaigns : [],
            campaignsToShow: b.campaigns
              ? _.uniqBy(
                  b.campaigns.map((c) => {
                    const ca = this.campaigns.find((cf) => cf._id === c.campaign);
                    return ca
                      ? { _id: ca._id, name: ca.name }
                      : { _id: c.campaign, name: 'unknown' };
                  }),
                  '_id'
                )
              : [],
            workingHours:
              b.workingHours && Array.isArray(b.workingHours)
                ? [
                    b.workingHours[0] !== null ? b.workingHours[0] : 0,
                    b.workingHours[1] !== null ? b.workingHours[1] : 0,
                  ]
                : [0, 23],
            dailyCap: b.dailyCap,
            // dailyCapType: b.dailyCap ? b.dailyCap.type : '-',
            totalCap: b.totalCap,
            // totalCapType: b.totalCap ? b.totalCap.type : '-',
            isTest: b.isTest,
            isMBox: b.isMBox,
            loadBalancer: b.loadBalancer,
            loadBalancerCoefficient: b.loadBalancerCoefficient,
            defaultCampaignsBox:
              typeof b.defaultCampaignsBox === 'boolean' ? b.defaultCampaignsBox : false,
            // actionsToday: boxActionsToday.length,

            // impressionsToday: boxActionsToday.filter((a) => a.type === 'impression').length,
            // clicksToday: boxActionsToday.filter((a) => a.type === 'click').length,
            // leadsToday: boxActionsToday.filter((a) => a.type === 'lead').length,
            // ftdsToday: boxActionsToday.filter((a) => a.type === 'ftd').length,
            // payoutsToday: boxActionsToday.reduce((s, a) => (a.payout ? s + a.payout : s), 0),
            // revenueToday: '$1215423412', // need broker payout
            // profitToday: '$1212298889', // need broker payout
          };
        });
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    getThisBox(box) {
      return { _id: box._id, name: box.name };
    },
    hideBox(box) {
      this.$store.commit('make_requesting', { req: true });
      hideBox(this.token, { box })
        .then((r) => {
          if (r.status === 200 && r.data.success) {
            showCard(this, `Box ${box} hidden`, 'success');
            this.$store.dispatch('get_me');
          } else {
            handleRequestError(this, r.data, `Unknown error hiding box ${box}`);
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error hiding box ${box}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    applyFields() {
      applyFields(this);
    },
    applyDetails() {
      applyDetails(this);
    },
    saveSelectedFields(type) {
      saveSelectedFieldsMethod(this, type);
    },
    takeDefaultTableFields() {
      takeDefaultTableFields(this, 'boxesTableSelectedFields');
    },
    takeDefaultDetailsOptions() {
      takeDefaultDetailsOptions(this, 'boxesTableSelectedDetails');
    },
    takeDefaultHidden() {
      takeDefaultHidden(this, 'hiddenBoxes');
    },
    restoreTablePreference() {
      this.takeDefaultTableFields();
      this.saveSelectedFields('boxes_table');
    },
    restoreDetailsPreference() {
      this.takeDefaultDetailsOptions();
      this.saveSelectedFields('boxes_details');
    },
    openSidebarDetails() {
      this.sidebarColumnsOpen = false;
      this.sidebarHiddenOpen = false;
    },
    openSidebarColumns() {
      this.sidebarDetailsOpen = false;
      this.sidebarHiddenOpen = false;
    },
    openSidebarHidden() {
      this.sidebarDetailsOpen = false;
      this.sidebarColumnsOpen = false;
    },
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    logSelectedColumns() {
      console.log(this.selectedColumns);
    },
    logSelectedDetails() {
      console.log(this.selectedDetails);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async updateData() {
      // await this.$store.dispatch('get_all_resources', { vm: this });
      // await this.$store.dispatch('get_boxes', { vm: this });
      // await this.$store.dispatch('get_actions', { vm: this });
      // this.backdoor += 1;
      await store.dispatch('get_all_resources_one_request', { vm: this });
      this.totalRows = this.realitems.length;
      this.takeDefaultHidden();
    },
    makeBoxActive(_id, active) {
      const boxToSave = {
        _id,
        active,
      };
      saveBox(this.token, boxToSave)
        .then(async (r) => {
          if (r.status === 200) {
            showCard(
              this,
              `${r.data.name} box set ${active ? 'Active' : 'Paused'}`,
              active ? 'success' : 'warning',
              'Success! 🥳'
            );
            this.$store.commit('update_item_by_id', { index: 'boxes', item: r.data });
            // try {
            //   await this.$store.dispatch('get_boxes', { vm: this }); // getBoxes();
            //   // eslint-disable-next-line no-empty
            // } catch (e) {}
          } else {
            const cardError =
              r.data.error ||
              `${r.status}: Unknown error on setting box ${active ? 'Active' : 'Paused'}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
        })
        .catch((e) => {
          handleRequestError(
            e,
            `Unknown server error on setting box ${active ? 'Active' : 'Paused'}`,
            'Error making box active'
          );
        });
    },
    editHiddenBoxes() {
      editHiddenBoxes(this.token, { hidden: this.hidden })
        .then((r) => {
          if (r.status === 200 && r.data.success) {
            showCard(this, `Edited hidden boxes`, 'success', undefined, 'b-toaster-bottom-right');
            this.$store.dispatch('get_me');
          } else {
            handleRequestError(
              this,
              r.data,
              `Unknown error on editing hidden boxes`,
              undefined,
              'b-toaster-bottom-right'
            );
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown server error on editing hidden boxes`,
            undefined,
            'b-toaster-bottom-right'
          );
          this.$store.commit('make_requesting', { req: false });
        });
    },
  },
  watch: {
    'user.hiddenBoxes': function () {
      this.takeDefaultHidden();
    },
  },
};
</script>
<style scoped>
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
