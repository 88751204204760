<template>
  <div>
    <LoadingSpinner :requesting="requesting" v-if="requesting" />
    <div v-else>
      <ul>
        <li v-for="(item, index) in items" :key="index">
          <div v-if="!showClient2ndLevel(item)">
            <div class="client-item">
              <div>
                <router-link
                  :to="getRouterLink(item.campaign._id)"
                  class="mx-1 my-2 dataspan bluetext"
                  target="_blank"
                >
                  Campaign {{ item.campaign.name }}
                </router-link>
              </div>
              <div
                :class="[
                  'dataspan',
                  type === 'positive' ? 'bluetext' : 'orangetext',
                  'my-2',
                  'mx-1',
                ]"
              >
                {{ type === 'positive' ? item.positive : item.positiveToNegativeChanger }}
              </div>
              <div class="toggle-icon" @click="toggleVisibility(index)">
                <span v-show="!visibleClients.includes(index)">▶</span>
                <span v-show="visibleClients.includes(index)">▼</span>
              </div>
            </div>
            <SalesStatusClientsList_Receivers
              :show="visibleClients.includes(index)"
              :item="item"
              :type="type"
            ></SalesStatusClientsList_Receivers>
          </div>
          <div v-else>
            <div class="client-item">
              <router-link
                :to="getRouterLink(item.campaign._id)"
                class="mx-1 my-2 dataspan bluetext"
                target="_blank"
              >
                Campaign {{ item.campaign.name }}
              </router-link>

              <div
                :class="[
                  'dataspan',
                  type === 'positive' ? 'bluetext' : 'orangetext',
                  'my-2',
                  'mx-1',
                ]"
              >
                {{ type === 'positive' ? item.positive : item.positiveToNegativeChanger }}
              </div>
              <div class="toggle-icon mx-1" @click="toggleVisibility(index)">
                <span v-show="!visibleClients.includes(index)">▶</span>
                <span v-show="visibleClients.includes(index)">▼</span>
              </div>
              <div class="toggle-icon mx-1" @click="expandAll(index)">
                <span>expand</span>
              </div>
              <div class="toggle-icon mx-1" @click="shrinkAll(index)">
                <span>shrink</span>
              </div>
            </div>
            <ul v-show="visibleClients.includes(index)">
              <li v-for="(client, j) in item.clients" :key="j">
                <div class="client-item">
                  <div>
                    <router-link
                      :to="getRouterLink(item.campaign._id, client.client)"
                      class="mx-1 my-2 dataspan bluetext"
                      target="_blank"
                    >
                      Client {{ client.client.name }}
                    </router-link>
                  </div>
                  <div
                    :class="[
                      'dataspan',
                      type === 'positive' ? 'bluetext' : 'orangetext',
                      'my-2',
                      'mx-1',
                    ]"
                  >
                    {{ type === 'positive' ? client.positive : client.positiveToNegativeChanger }}
                  </div>
                  <div
                    class="toggle-icon"
                    v-if="visibleClients2Level[index]"
                    @click="toggleVisibility2Level(index, j)"
                  >
                    <span v-show="!visibleClients2Level[index].includes(j)">▶</span>
                    <span v-show="visibleClients2Level[index].includes(j)">▼</span>
                  </div>
                </div>
                <!--                :show="getVisibility2Level(index, j)"-->

                <SalesStatusClientsList_Receivers
                  v-if="visibleClients2Level[index]"
                  :show="!!visibleClients2Level[index] && visibleClients2Level[index].includes(j)"
                  :item="client"
                  :type="type"
                ></SalesStatusClientsList_Receivers>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <b-modal
      :id="`sales-status-report-modal`"
      ref="sales-status-report-modal"
      centered
      ok-only
      ok-title="Close"
      size="md"
      scrollable
      :title="
        type === 'positive'
          ? `Positive Sales Status Report`
          : `Positive-To-Negative Changers Report`
      "
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      <SalesStatusReportForClient
        :appliedFilterForm="appliedFilterForm"
        :type="type"
        :campaign="campaign"
        :client="client"
      ></SalesStatusReportForClient>
    </b-modal>
  </div>
</template>

<script>
import _, { cloneDeep } from 'lodash';
import { handleRequestError, getRouterLink_SalesStatusClientsList } from '@/utils';
import { getSalesStatusClientsList } from '@/utils/api';
import { initialFilterForm } from '@/consts';
import SalesStatusReportForClient from '@/components/pages/Actions/ActionsTable/components/SalesStatusReportForClient.vue';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import SalesStatusClientsList_Receivers from '@/components/pages/Actions/ActionsTable/components/SalesStatusClientsList_Receivers.vue';

export default {
  name: 'SalesStatusClientsList',
  components: { SalesStatusClientsList_Receivers, LoadingSpinner, SalesStatusReportForClient },
  props: {
    type: {
      type: String,
      validator(value) {
        // Define the allowed values for the prop
        const allowedValues = ['positive', 'positive-to-negative'];

        // Check if the prop value is included in the allowed values
        return allowedValues.includes(value);
      },
      required: true,
    },
    appliedFilterForm: {
      type: Object,
      default: () => {
        return cloneDeep(initialFilterForm);
      },
      required: true,
    },
  },
  data() {
    return {
      items: [],
      // _.cloneDeep(this.$props.data),
      visibleClients: [],
      visibleClients2Level: {},
      requesting: false,
      campaign: undefined,
      client: undefined,
      receiver: undefined,
    };
  },
  computed: {
    ...mapState(['user', 'isAdmin', 'isManager', 'isAffiliate', 'isMasterAffiliate', 'token']),
    headerBgVariant() {
      return this.type === 'positive' ? 'success' : 'warning';
    },
    headerTextVariant() {
      return this.type === 'positive' ? 'light' : 'dark';
    },
    footerBgVariant() {
      return this.type === 'positive' ? 'success' : 'warning';
    },
    footerTextVariant() {
      return this.type === 'positive' ? 'light' : 'dark';
    },
  },
  methods: {
    showClient2ndLevel(item) {
      return (
        item.clients &&
        (item.clients.length > 1 ||
          (item.clients.length === 1 && item.clients[0].client._id !== item.campaign._id))
      );
    },
    getReport(campaign, client, receiver) {
      this.campaign = campaign;
      this.client = client;
      this.receiver = receiver;
      this.$refs['sales-status-report-modal'].show();
    },
    getSalesStatusClientsList() {
      this.requesting = true;
      const options = { ...this.appliedFilterForm, statusType: this.type };
      getSalesStatusClientsList(this.token, options)
        .then((r) => {
          if (r.data.success) {
            let items = _.sortBy(
              r.data.items,
              (o) => {
                const i = this.type === 'positive' ? o.positive : o.positiveToNegativeChanger;
                return i ? -i : 0;
              }
              // this.type === 'positive' ? 'positive' : 'positiveToNegativeChanger'
            );
            items = items.map((i) => {
              const j = _.cloneDeep(i);
              j.clients = _.sortBy(j.clients, (o) => {
                const jj = this.type === 'positive' ? o.positive : o.positiveToNegativeChanger;
                return jj ? -jj : 0;
              });
              j.clients = j.clients.map((k) => {
                const h = _.cloneDeep(k);
                h.receivers = _.sortBy(h.receivers, (o) => {
                  const hh = this.type === 'positive' ? o.positive : o.positiveToNegativeChanger;
                  return hh ? -hh : 0;
                });
                return h;
              });
              return j;
            });
            this.items = items;
            console.log('items', this.items);
            this.makeClientsVisible();
          } else {
            handleRequestError(this, r.data, 'Error getting SalesStatusClientsList');
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, 'Error getting SalesStatusClientsList');
          this.requesting = false;
        });
    },
    toggleVisibility(index) {
      if (this.visibleClients.includes(index)) {
        // Client is already visible, so hide it
        const clientIndex = this.visibleClients.indexOf(index);
        this.visibleClients.splice(clientIndex, 1);
        this.visibleClients2Level[clientIndex] = [];
        this.visibleClients2Level = _.cloneDeep(this.visibleClients2Level);
      } else {
        if (this.items[index] && this.items[index].clients) {
          this.visibleClients2Level[index] = this.items[index].clients.map((i, j) => j);
          this.visibleClients2Level = _.cloneDeep(this.visibleClients2Level);
        }
        // Client is not visible, so show it
        this.visibleClients.push(index);
        // this.$refs.secondLevelList.makeClientsVisible();
      }
    },
    toggleVisibility2Level(index, index2) {
      if (this.visibleClients2Level[index]) {
        // Client is already visible, so hide it
        if (this.visibleClients2Level[index].includes(index2)) {
          const clientIndex = this.visibleClients2Level[index].indexOf(index2);
          this.visibleClients2Level[index].splice(clientIndex, 1);
        } else {
          // Client is not visible, so show it
          this.visibleClients2Level[index].push(index2);
        }
      } else {
        this.visibleClients2Level[index] = this.items[index].clients.map((i, j) => j);
      }
      this.visibleClients2Level = _.cloneDeep(this.visibleClients2Level);
    },
    expandAll(index) {
      this.visibleClients2Level[index] = this.items[index].clients.map((i, j) => j);
      this.visibleClients2Level = _.cloneDeep(this.visibleClients2Level);
    },
    shrinkAll(index) {
      this.visibleClients2Level[index] = [];
      this.visibleClients2Level = _.cloneDeep(this.visibleClients2Level);
    },
    getRouterLink(campaignId, client, receiver) {
      return getRouterLink_SalesStatusClientsList(this, campaignId, client, receiver);
    },
    makeClientsVisible() {
      this.visibleClients = this.items.map((i, index) => index);
      this.items.forEach((i, index) => {
        if (i.clients) this.visibleClients2Level[index] = i.clients.map((j, index2) => index2);
      });
    },
  },
  mounted() {
    this.makeClientsVisible();
    this.getSalesStatusClientsList();
  },
};
</script>

<style scoped>
.toggle-icon {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
}

.client-item {
  display: flex;
  align-items: center;
}
</style>
