import { cloneDeep } from 'lodash';

export default function handleMultipleKeysEditEvent(data, keys, formKey = 'form') {
  if (!Array.isArray(keys)) this[formKey][keys] = cloneDeep(data);
  else {
    for (let i = 0; i < keys.length; i += 1) {
      if (data[keys[i]] !== undefined) this[formKey][keys[i]] = cloneDeep(data[keys[i]]);
    }
  }
  if (this.emitEditEvent) this.emitEditEvent();
}
