<template>
  <div>
    <div class="my-2 formdiv" v-if="countryPaymentsArrayWithoutDefault.length > 0">
      <div
        class="mx-2 my-1 dataspan"
        v-for="(country, index) in countryPaymentsArrayWithoutDefault"
        :key="index"
      >
        {{ country.country }}: {{ country.payment }}
      </div>
    </div>
    <b-button v-b-toggle="`collapse-breakdown-payments-by-countries-${cid}`" variant="primary">
      Payments by geos
    </b-button>
    <b-collapse :id="`collapse-breakdown-payments-by-countries-${cid}`" class="mt-2">
      <b-card title="Payments by countries">
        <b-form inline>
          <div v-for="(country, index) in countryPaymentsArray" :key="index" class="m-2 formdiv">
            <b-input-group
              :prepend="getCountryCode(countryPaymentsArray[index].country)"
              class="mb-2 mr-sm-2 mb-sm-0"
            >
              <b-form-input
                type="number"
                id="inline-form-input-payment"
                placeholder="Payment"
                v-model="countryPaymentsArray[index].payment"
                :disabled="countryPaymentsDefaultStates[index]"
                @input="updateCountryPaymentsArray()"
              ></b-form-input>
            </b-input-group>
            <b-form-checkbox
              v-model="countryPaymentsArray[index].isDefault"
              @input="updateDefault(index)"
            >
              Default
            </b-form-checkbox>
            <!--            <b-button variant="primary" v-b-popover.hover="'Edit'">-->
            <!--              <b-icon icon="pencil-square"></b-icon>-->
            <!--            </b-button>-->
            <!--            <b-button variant="warning" v-b-popover.hover="'Default'">-->
            <!--              <b-icon icon="backspace"></b-icon>-->
            <!--            </b-button>-->
          </div>
        </b-form>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import '@/assets/breakdown-data.css';

class CountryPayment {
  isDefault = true;

  payment;

  country;

  constructor(c, p, d) {
    this.country = c;
    this.payment = p;
    this.isDefault = d === false ? d : true;
  }

  toJSONObject() {
    return {
      isDefault: this.isDefault,
      payment: this.payment,
      country: this.country,
    };
  }
}

function getCountryCode(country) {
  if (typeof country === 'string') return country;
  return country.value ? country.value : country;
}

export default {
  name: 'CBFinanceSettings',
  props: {
    cid: { type: String, default: 'default' },
    countries: { type: Array, default: () => [] },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      backdoor: 0,
    };
  },
  computed: {
    countriesInPayments() {
      return this.value.map((c) => c.country);
    },
    countryCodes() {
      return this.countries.map((c) => getCountryCode(c));
    },
    countryPaymentsArray() {
      // console.log('this.value', this.value);
      // console.log('this.countryCodes', this.countryCodes);
      // console.log('this.countriesInPayments', this.countriesInPayments);
      const countryPaymentsArray = [
        ...this.value.map((cp) => new CountryPayment(cp.country, cp.payment, cp.isDefault)),
        ...this.countryCodes
          .filter((c) => !this.countriesInPayments.includes(c))
          .map((c) => new CountryPayment(c)),
      ];
      // console.log('countryPaymentsArray', countryPaymentsArray);
      return countryPaymentsArray;
    },
    countryPaymentsDefaultStates() {
      this.backdoor;
      return this.countryPaymentsArray.map((c) => c.isDefault);
    },
    countryPaymentsArrayWithoutDefault() {
      return this.countryPaymentsArray.filter(({ payment }) => payment);
    },
  },
  methods: {
    getCountryCode(country) {
      return getCountryCode(country);
    },
    updateCountryPaymentsArray() {
      const countryPaymentsChanged = this.countryPaymentsArray.map((c) =>
        c instanceof CountryPayment ? c.toJSONObject() : c
      );
      // console.log('updateCountryPaymentsArray', countryPaymentsChanged);
      this.$emit('input', countryPaymentsChanged);
    },
    updateDefault(index) {
      this.backdoor += 1;
      // console.log('updateDefault', this.countryPaymentsArray[index]);
      const { isDefault } = this.countryPaymentsArray[index];
      // console.log(isDefault);
      if (isDefault) {
        this.countryPaymentsArray[index].payment = undefined;
        this.updateCountryPaymentsArray();
      }
    },
    getDefaultState(index) {
      const state = this.countryPaymentsArray[index];
      // console.log('getDefaultState', state);
      return state.isDefault;
    },
  },
};
</script>

<style scoped>
.formdiv {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1.25rem;
}
</style>
<!--s-->
