/* eslint-disable no-restricted-syntax */
import showCard from '@/utils/showCard';
import { actionTypeOptions, countryOptions, daysOfWeek, positiveSalesStatus } from '@/consts';
import { noCap } from '@/validators/_utils';
import _, { cloneDeep, omit } from 'lodash';
import { DateTime } from 'luxon';

export { default as getAuthHeader } from './getAuthHeader';
export { default as getDecodedToken } from './getDecodedToken';
export { default as getIdentity } from './getIdentity';
export { default as showCard } from './showCard';
export { default as isNotSet } from './isNotSet';
export { default as countAvgLeads } from './countAvgLeads';
export { default as countryMultiSelectSearch } from './countryMultiSelectSearch';
export { default as apiTypeMultiselectSearch } from './apiTypeMultiselectSearch';
export { default as makeUrl } from './makeUrl';
export { default as assembleMetaCampaignOptions } from './assembleMetaCampaignOptions';
export { default as parseQuery } from './parseQuery';
export { default as metaCampaignsOptions } from './metaCampaignsOptions';
export { default as finalReceiversOptions } from './finalReceiversOptions';
export { default as workersOptions } from './workersOptions';
export { default as actionDateTime } from './actionDateTime';
export { getRouterLink_SalesStatusClientsList } from './getRouterLink_SalesStatusClientsList';

export function handleRequestError(
  ctx,
  e,
  defaultMessage,
  title = undefined,
  toaster = 'b-toaster-top-right'
) {
  console.error(e);
  const cardError = (e.response ? e.response.data : undefined) || e || { message: defaultMessage };
  showCard(ctx, cardError.message, 'danger', title, toaster);
}
//
export function isAffiliate(user) {
  // console.log('checking user affiliate', user);
  return !!user && !!user.type && (user.type === 'affiliate' || user.type === 'masteraffiliate');
}

export function isMasterAffiliate(user) {
  // console.log('checking user affiliate', user);
  return !!user && !!user.type && user.type === 'masteraffiliate';
}

export function isManager(user) {
  // console.log('checking user manager', user);
  return !!user && !!user.type && (user.type === 'manager' || user.type === 'admin');
}

export function isAdmin(user) {
  // console.log('checking user admin', user);
  return !!user && !!user.type && user.type === 'admin';
}
export async function updateResources(t) {
  try {
    await t.$store.dispatch('get_users', { vm: this });
    await t.$store.dispatch('get_affiliates', { vm: this });
    await t.$store.dispatch('get_managers', { vm: this });
    await t.$store.dispatch('get_managers', { vm: this });

    await t.$store.dispatch('get_campaigns', { vm: this });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function castCountriesFromServer(c) {
  return Array.isArray(c)
    ? c
        .map((cc) => {
          const ci = countryOptions.map((co) => co.value).indexOf(cc);
          return ci >= 0 ? countryOptions[ci] : false;
        })
        .filter((cc) => cc !== false)
    : [];
}

export function tableFormatterId(value, length = 7) {
  // console.log(value, value.substring(0, 7));
  // return value.substring(0, 7);
  return value && typeof value === 'string' ? value.substring(value.length - length) : 'undefined';
}

export function tableFormatterFullCardNumber(value) {
  const v = value.replace(/\D/g, '');
  let r = v.slice(0, 4);
  if (v.length > 4) r += ` ${v.slice(4, 8)}`;
  if (v.length > 8) r += ` ${v.slice(8, 12)}`;
  if (v.length > 12) r += ` ${v.slice(12)}`;
  return r;
}

export function tableFormatterInj(value) {
  return value ? tableFormatterId(value) : '-';
}

export function tableFormatterNameAndId(value) {
  return value ? (value.name ? value.name : value) : '';
}
export function tableFormatterLongString(value) {
  const l = 20;
  const vs =
    typeof value === 'string'
      ? value
      : typeof value === 'object'
      ? // ? Array.isArray(value)
        //   ? String(value)
        JSON.stringify(value)
      : String(value);
  return `${vs.substring(0, l - 1)}${vs.length > l ? '...' : ''}`;
}
export function tableFormatterArrayValues(value, maxLength = 3) {
  // console.log('countries', value);
  if (!Array.isArray(value)) return value;
  if (value.length === 0) return 'None';
  return value.length > maxLength
    ? value.length
    : value.map((v) => (v.value ? v.value : v)).join(', ');
}
export function tableFormatterHourLimit(value) {
  return `${value[0]}:00 — ${value[1]}:59`;
}
export function tableFormatterDays(value) {
  return value.length === 7
    ? 'All'
    : value.length === 0
    ? 'None'
    : daysOfWeek
        .filter((dow) => value.includes(dow.item))
        .map((d) => d.name.substring(0, 3))
        .join(', ');
}
export function tableFormatterCapOneCell(value) {
  return noCap(value)
    ? '-'
    : `${value.amount} ${
        value && value.type && actionTypeOptions.map((aco) => aco.value).includes(value.type)
          ? actionTypeOptions[actionTypeOptions.findIndex((aco) => aco.value === value.type)].text
          : 'unknown'
      }`;
}

export function tableFormatterDate(value) {
  const date =
    value && value !== 'unknown' ? DateTime.fromISO(value, { setZone: true }) : { isValid: false };
  return date.isValid ? date.toFormat('TT dd.LL.yy') : value;
  // return value.isValid ? value.toFormat('TT dd.LL.yyyy') : value;
  // return value;
  // zxc
}

export function tableFormatterDateTime(value, necessary = false) {
  if (!value) return necessary ? 'Invalid Date' : 'none';
  const dt = DateTime.fromISO(value, { setZone: true });
  return dt.isValid ? dt.toFormat('TT dd.LL.yyyy') : 'Invalid Date';
}

export function tableFormatterValueToText(value, array) {
  console.log('ValueToText', value, array);
  if (!Array.isArray(array)) return value;
  const el = array.find((ae) => ae.value === value);
  return el ? (el.text ? el.text : value) : '-';
}

export function deleteUnchangedFields(initialForm, changedForm, where) {
  const res = _.cloneDeep(where);
  const formKeys = Object.keys(initialForm);
  // console.log('Checking unchanged fields', formKeys);
  for (let i = 0; i < formKeys.length; i += 1) {
    console.log(formKeys[i], initialForm[formKeys[i]], changedForm[formKeys[i]]);
    console.log(where[formKeys[i]]);
    if (formKeys[i] !== '_id' && _.isEqual(changedForm[formKeys[i]], initialForm[formKeys[i]])) {
      // console.log('Key unchanged, deleting');
      delete res[formKeys[i]];
    }
  }
  console.log('Checked unchanged', res);
  return res;
}

export function applyFilterToArray(filter, array, filterMapping, arrayMapping) {
  if (!filter || !Array.isArray(filter) || filter.length === 0) return true;
  if (!array) return false;
  const mid = filterMapping ? filter.map((a) => a[filterMapping]) : filter;
  if (mid.length > 0) console.log('filter sample', mid[0]);
  return array.some((m) => {
    const me = arrayMapping ? m[arrayMapping] : m;
    console.log('array sample', me);
    return !!me && mid.includes(me);
  });
}

export function applyCapFilter(filter, cap, filterMapping) {
  if (!filter || !Array.isArray(filter) || filter.length === 0) return true;
  if (noCap(cap)) return false;
  const mid = filterMapping ? filter.map((a) => a[filterMapping]) : filter;
  return mid.includes(cap.type);
}

export function statusPositive(s) {
  const positive = positiveSalesStatus.some((ps) =>
    ps.test(s && typeof s === 'string' ? s.toLowerCase() : '')
  );
  console.log('is this positive?', s, positive);
  return positive;
}

export function hasPositiveStatus(statusChangeLog) {
  return (
    Array.isArray(statusChangeLog) &&
    statusChangeLog.map((s) => s.status).some((s) => statusPositive(s))
  );
}

export function mapIdsOrEmpty(mapFrom) {
  return mapFrom && Array.isArray(mapFrom) ? mapFrom.map((b) => (b._id ? b._id : b)) : [];
}

export function countryWithCode({ text, value }) {
  return `${value} — ${text}`;
}

export const affiliatesOptions = () => {
  return this.affiliates.map((a) => {
    return { name: a.name, _id: a._id };
  });
};

export const campaignOptions = () => {
  return this.campaigns.map((c) => {
    return { text: c.name, value: c._id };
  });
};
export const boxesOptions = () => {
  return this.boxes.map((b) => {
    return { text: b.name, value: b._id };
  });
};

export const mapPropsToValid = (props) => {
  const k = cloneDeep(omit(props, ['name', 'noAuth']));
  for (const i of Object.keys(k)) {
    k[i] = false; // || i === 'Auth';
  }
  return k;
};
// export const handleChildComponentValidation
export const mapPropFieldToValid = (prop, initial) => {
  const k = cloneDeep(prop || initial);
  for (const i of Object.keys(k)) {
    k[i] = false; // || i === 'Auth';
  }
  return k;
};

export function formDate(date, format = 'dd.LL.yy TT') {
  const datetime = DateTime.fromISO(date, { setZone: true });
  return datetime.isValid ? datetime.toFormat(format) : 'Invalid DateTime';
}

export function formDateInKey(a, key, format = 'dd.LL.yy TT') {
  const b = cloneDeep(a);
  const datetime = DateTime.fromISO(b[key], { setZone: true });
  b[key] = datetime.isValid ? datetime.toFormat(format) : 'Invalid DateTime';
  return b;
}

export function formatDatesInKey(array, key, format = 'dd.LL.yy TT') {
  return array.map((a) => formDateInKey(a, key, format));
}

export function bceFilterFunction(item, filter) {
  const countryCodes = filter.countries.map((cc) => cc.value);
  const campaigns = filter.campaigns.map((c) => (c && c._id ? c._id : c));
  const noCampaignInItem = !item.campaign || item.campaign === '';
  const noCountriesInItem = !item.countries || item.countries.length === 0;
  const campaignInCampaigns = campaigns.length === 0 || campaigns.includes(item.campaign);
  const countryInCountries =
    countryCodes.length === 0 ||
    item.countries.some((c) => countryCodes.includes(c.value ? c.value : c));
  // console.log('campaignInCampaigns', campaignInCampaigns, 'countryInCountries', countryInCountries);
  return noCampaignInItem || noCountriesInItem || (campaignInCampaigns && countryInCountries);
}

export async function delay(t, val) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(val);
    }, t);
  });
}

export const dataUpdateTimeoutCondition = (period, to) =>
  (period !== 'last month' && period !== 'custom') ||
  (period === 'custom' &&
    (!to ||
      DateTime.fromISO(to) >
        DateTime.utc().minus({ day: 14 }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })));

export function dataToFile(data, filename, useBlob = false) {
  const href = URL.createObjectURL(useBlob ? new Blob([data]) : data);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename); // or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function validateBoolean(c) {
  return typeof c === 'boolean'
    ? c
    : typeof c === 'string'
    ? c === 'true'
      ? true
      : c === 'false'
      ? false
      : undefined
    : typeof c === 'number'
    ? c === 1
      ? true
      : c === 0
      ? false
      : undefined
    : undefined;
}

export const config = {
  rent: validateBoolean(process.env.VUE_APP_RENT),
};
