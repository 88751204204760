import { defaultIntegrationSettings, brokerApiTypes } from '@/consts';

function checkHeader(headers, formattedQuery) {
  return headers.some((h) => !!h && h[0].trim().includes(formattedQuery)); // .toLowerCase()
}

function parseUrlSearchQuery(query) {
  try {
    const parsed = new URL(query);
    const pathAndSearch = `${parsed.pathname}${parsed.search}`.trim().toLowerCase();
    if (pathAndSearch.length > 0) return pathAndSearch;
    return undefined;
  } catch (e) {
    return undefined;
  }
}

export default function apiTypeMultiselectSearch(query) {
  const formattedQuery = query.trim(); // .toLowerCase();
  const urlParsedQuery = parseUrlSearchQuery(formattedQuery);

  const byName = brokerApiTypes.filter((name) => {
    const formattedName = name.trim().toLowerCase();
    return formattedName.includes(formattedQuery.toLowerCase());
  });

  const defaultSettingsTypes = Object.keys(defaultIntegrationSettings).filter(
    (dst) => dst !== 'default'
  );
  // console.log('defaultSettingsTypes', defaultSettingsTypes);
  const byAuthHeaders = [];
  const byAuthBody = [];
  const byPullAuthHeaders = [];
  const byApiMapping = [];
  const byApiEndpoint = [];
  for (let i = 0; i < defaultSettingsTypes.length; i += 1) {
    const dst = defaultSettingsTypes[i];
    const j = brokerApiTypes.findIndex((t) => t === dst);
    // console.log('dst', dst);
    const dis = defaultIntegrationSettings[dst];
    if (checkHeader(dis.brokerAuthHeaders, formattedQuery)) {
      byAuthHeaders.push(brokerApiTypes[j]);
      // console.log(`${dst} found byAuthHeaders`);
    }
    if (checkHeader(dis.brokerAuthBody, formattedQuery)) {
      byAuthBody.push(brokerApiTypes[j]);
      // console.log(`${dst} found byAuthBody`);
    }
    if (checkHeader(dis.brokerPullAuthHeaders, formattedQuery)) {
      byPullAuthHeaders.push(brokerApiTypes[j]);
      // console.log(`${dst} found byPullAuthHeaders`);
    }
    const apiMappingValues = Object.values(dis.brokerApiMapping).filter(
      (v) => typeof v === 'string' && v.trim().toLowerCase().length > 0
    );
    if (apiMappingValues.some((v) => v.trim().includes(formattedQuery))) {
      // .toLowerCase()
      byApiMapping.push(brokerApiTypes[j]);
      // console.log(`${dst} found byApiMapping`);
    }
    if (
      dis.apiEndpoints &&
      (dis.apiEndpoints.some(
        (e) =>
          e.trim().includes(formattedQuery) || // .toLowerCase()
          formattedQuery.includes(e.trim()) // .toLowerCase()
      ) ||
        (urlParsedQuery &&
          dis.apiEndpoints.some(
            (e) =>
              e.trim().includes(urlParsedQuery) || // .toLowerCase()
              urlParsedQuery.includes(e.trim()) // .toLowerCase()
          )))
    ) {
      byApiEndpoint.push(brokerApiTypes[j]);
      // console.log(`${dst} found byApiEndpoint`);
    }
  }
  const searchResult = [
    ...byName,
    ...byAuthHeaders,
    ...byAuthBody,
    ...byPullAuthHeaders,
    ...byApiMapping,
    ...byApiEndpoint,
  ];
  // console.log('searchResult', searchResult);
  const searchResultWithoutDuplicates = searchResult.filter(
    (r, index, array) => index === array.findIndex((r2) => r2 === r)
  );
  // console.log('searchResultWithoutDuplicates', searchResultWithoutDuplicates);
  this.brokerApiTypeOptions = searchResultWithoutDuplicates;
}
