<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <label class="mx-1 my-2">{{ name }}</label>
    <b-button @click="addPathArray()" class="ml-2 mx-1 my-2" variant="info">
      <font-awesome-icon icon="plus"></font-awesome-icon>
    </b-button>
    <div v-for="(p, i1) in form" :key="i1">
      <div class="array-item-inline">
        <span class="mx-1">{{ i1 }}</span>
        <b-button @click="removePathArray(i1)" class="ml-2 mx-1" variant="danger">
          <font-awesome-icon icon="times"></font-awesome-icon>
        </b-button>
      </div>
      <PathForm
        :arr="form[i1]"
        @edit="handleEditEvent($event, i1)"
        @validate="handleValidateEvent($event, i1)"
      ></PathForm>
      <hr />
    </div>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
import handleEditEvent from '@/utils/handleEditEvent';
import PathForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PathForm.vue';
// import handleEditEventDirectKey from '@/utils/handleEditEventDirectKey';
import handleValidateEvent from '@/utils/handleValidateEvent';
import { mapPropFieldToValid } from '@/utils';
import { formValid } from '@/validators'; // isValid

export default {
  name: 'PathArrayForm',
  components: { PathForm },
  props: {
    name: { type: String, default: 'PathArrayForm' },
    pathArray: { type: Array, default: () => [] },
    disallowEmpty: { type: Boolean, default: false },
  },
  data() {
    return {
      form: cloneDeep(this.$props.pathArray ? this.$props.pathArray : []),
      valid: mapPropFieldToValid(this.$props.pathArray, []),
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = cloneDeep(this.valid);
      const form = (!this.disallowEmpty || fields.length > 0) && formValid(fields);
      // this.$emit('validate', form);
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    addPathArray() {
      this.form.push(['']);
      this.valid.push(false);
      this.backdoor += 1;
      // console.log('addPathArray result', this.form, this.valid);
      this.emitEditEvent();
    },
    removePathArray(i1) {
      if (this.form) this.form.splice(i1, 1);
      if (this.valid) this.valid.splice(i1, 1);
      this.backdoor += 1;
      // console.log('removePathArray result', this.form, this.valid);
      this.emitEditEvent();
    },
    // addPathElement(i1, i2) {
    //   if (this.pathArray[i1]) {
    //     if (i2 < this.pathArray[i1].length) this.pathArray[i1].splice(i2 + 1, 0, '');
    //     else this.pathArray[i1].push('');
    //   }
    //   this.emitEditEvent();
    // },
    // removePathElement(i1, i2) {
    //   console.log('removePathElement', i1, i2);
    //   if (this.pathArray && this.pathArray[i1]) this.pathArray[i1].splice(i2, 1);
    //   console.log('removePathElement result', this.pathArray[i1]);
    //   this.emitEditEvent();
    // },
    handleEditEvent,
    // handleEditEventDirectKey,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
