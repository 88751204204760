import parseQuery from '@/utils/parseQuery';
import { initialFilterForm } from '@/consts';

// eslint-disable-next-line import/prefer-default-export
export const getRouterLink_SalesStatusClientsList = (ctx, campaignId, client, receiver) => {
  const query = parseQuery(ctx, false);
  Object.keys(query).forEach((key) => {
    if (initialFilterForm[key] === query[key]) {
      delete query[key];
    }
  });
  query.campaigns = [campaignId];
  if (client && campaignId !== client._id) query.metaCampaigns = [client];
  if (query.metaCampaigns) query.metaCampaigns = JSON.stringify(query.metaCampaigns);
  if (query.workers) query.workers = JSON.stringify(query.workers);
  if (receiver) query.finalReceivers = [receiver];
  if (ctx.type === 'positive') {
    query.showPositiveStatuses = true;
  } else {
    query.showPositiveToNegatives = true;
  }
  return { path: '/crm', query };
};
