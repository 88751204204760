<template>
  <fragment>
    <Header />
    <Login />
  </fragment>
</template>

<script>
import Header from '@/components/shared/Header.vue';
import Login from '@/components/pages/Login/Login/Login.vue';

export default {
  name: 'LoginView',
  components: {
    Header,
    Login,
  },
};
</script>
