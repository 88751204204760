import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {
  TRUSTED_ADMIN_ROUTES,
  ADMIN_ROUTES,
  AFFILIATE_ROUTES,
  MASTERAFFILIATE_ROUTES,
  MANAGER_ROUTES,
  OTHER_LEVEL_TRUSTED_USERS,
  PUBLIC_ROUTES,
  RENT_DISALLOWED_ROUTES,
} from '@/consts';
import MainLayout from '@/views/Layouts/MainLayout.vue';
import Login from '@/views/Login.vue';
import Users from '@/views/Users.vue';
import CreateUser from '@/views/CreateUser.vue';
import Campaigns from '@/views/Campaigns.vue';
import CreateCampaign from '@/views/CreateCampaign.vue';
import Boxes from '@/views/Boxes.vue';
import CreateBox from '@/views/CreateBox.vue';
import CreateInjection from '@/views/CreateInjection.vue';
import UploadLeadsFile from '@/views/UploadLeadsFile.vue';
import TestLead from '@/views/TestLead.vue';
import TestPulling from '@/views/TestPulling.vue';
import TestGMLevelDistribution from '@/views/TestGMLevelDistribution.vue';
import Actions from '@/views/Actions.vue';
import Daughters from '@/views/Daughters.vue';
import FinalReceivers from '@/views/FinalReceivers.vue';
import MetaCampaigns from '@/views/MetaCampaigns.vue';
import GeoSelection from '@/views/GeoSelection.vue';
import PSPTable from '@/views/PSPTable.vue';
import BCEPresetTable from '@/views/BCEPresetTable.vue';
import WorkersTable from '@/views/WorkersTable.vue';
import FinancialTransactions from '@/views/FinancialTransactions.vue';
import BalancesTable from '@/views/BalancesTable.vue';
import InjectionSendResultTable from '@/views/InjectionSendResultTable.vue';
import AffiliateApiDocs from '@/views/AffiliateApiDocs.vue';
// import Stats from '@/views/Stats.vue';
import Injections from '@/views/Injections.vue';
import Home from '@/views/Home.vue';
import WorkHistory from '@/views/WorkHistory.vue';
import {
  // validateBoolean,
  config,
} from '@/utils';
// import SystemCommands from '@/views/SystemCommands.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: MainLayout,
    redirect: '/dashboard',
    name: 'Main Layout',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Home,
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
      },
      {
        path: '/create-user',
        name: 'CreateUser',
        component: CreateUser,
      },
      {
        path: '/user/edit/:id',
        name: 'EditUser',
        component: CreateUser,
      },
      {
        path: '/campaigns',
        name: 'Campaigns',
        component: Campaigns,
      },
      {
        path: '/create-campaign',
        name: 'CreateCampaign',
        component: CreateCampaign,
      },
      {
        path: '/campaign/edit/:id',
        name: 'EditCampaign',
        component: CreateCampaign,
      },
      {
        path: '/boxes',
        name: 'Boxes',
        component: Boxes,
      },
      {
        path: '/create-box',
        name: 'CreateBox',
        component: CreateBox,
      },
      {
        path: '/box/edit/:id',
        name: 'EditBox',
        component: CreateBox,
      },
      {
        path: '/actions',
        name: 'Actions',
        component: Actions,
      },
      {
        path: '/crm',
        name: 'crm',
        component: Actions,
      },
      {
        path: '/deposits',
        name: 'Deposits',
        component: Actions,
      },
      {
        path: '/prepared-to-push',
        name: 'prepared-to-push',
        component: Actions,
      },
      // {
      //   path: '/stats',
      //   name: 'Stats',
      //   component: Stats,
      // },
      {
        path: '/injections/:id',
        name: 'Injections',
        component: Injections,
      },
      {
        path: '/injections',
        name: 'Injections',
        component: Injections,
      },
      {
        path: '/create-injection',
        name: 'CreateInjection',
        component: CreateInjection,
      },
      {
        path: '/upload-leads-file',
        name: 'UploadLeadsFile',
        component: UploadLeadsFile,
      },
      {
        path: '/test-lead',
        name: 'TestLead',
        component: TestLead,
      },
      {
        path: '/test-pulling',
        name: 'TestPulling',
        component: TestPulling,
      },
      {
        path: '/test-gm-level-distribution',

        name: 'TestGMLevelDistribution',
        component: TestGMLevelDistribution,
      },
      {
        path: '/work-history',
        name: 'WorkHistory',
        component: WorkHistory,
      },
      {
        path: '/daughters',
        name: 'Daughters',
        component: Daughters,
      },
      {
        path: '/finalreceivers',
        name: 'FinalReceivers',
        component: FinalReceivers,
      },
      {
        path: '/metacampaigns',
        name: 'MetaCampaigns',
        component: MetaCampaigns,
      },
      {
        path: '/geo-selection',
        name: 'GeoSelection',
        component: GeoSelection,
      },
      {
        path: '/psp',
        name: 'PSP',
        component: PSPTable,
      },
      {
        path: '/bce-preset',
        name: 'BCEPreset',
        component: BCEPresetTable,
      },
      {
        path: '/workers',
        name: 'Workers',
        component: WorkersTable,
      },
      {
        path: '/financial-transactions',
        name: 'FinancialTransactions',
        component: FinancialTransactions,
      },
      {
        path: '/balances',
        name: 'BalancesTable',
        component: BalancesTable,
      },
      {
        path: '/injections-send-results',
        name: 'InjectionSendResultTable',
        component: InjectionSendResultTable,
      },
      {
        path: '/affiliate-api-docs',
        name: 'AffiliateApiDocs',
        component: AffiliateApiDocs,
      },
    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  // {
  //   path: '/system-commands',
  //   name: 'SystemCommands',
  //   component: SystemCommands,
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  // sync(store, router)
  const isAuthRequired = !PUBLIC_ROUTES.includes(to.path);
  store.commit('checkLogin');
  const { loggedIn } = store.state;

  if (isAuthRequired && loggedIn && !store.state.user) {
    try {
      console.log('No user in state. Getting me');
      await store.dispatch('get_me');
    } catch (e) {
      console.error('Error getting me in router. ', e);
      // store.commit('logout');
      return next('/login');
    }
  }
  // console.log('Getting all resources'); zx
  // await store.dispatch('get_all_resources_one_request', { vm: this });

  // Redirect to login page if not logged in and trying to access a restricted page
  if (isAuthRequired && !loggedIn) {
    return next('/login');
  }

  let ALLOWED_ROUTES =
    store.state.user && OTHER_LEVEL_TRUSTED_USERS.includes(store.state.user.email)
      ? TRUSTED_ADMIN_ROUTES
      : store.state.isAdmin
      ? ADMIN_ROUTES
      : store.state.isManager
      ? MANAGER_ROUTES
      : store.state.isMasterAffiliate
      ? MASTERAFFILIATE_ROUTES
      : store.state.isAffiliate
      ? AFFILIATE_ROUTES
      : PUBLIC_ROUTES;
  if (to.path !== '/' && !ALLOWED_ROUTES.some((ar) => to.path.match(new RegExp(`^${ar}`)))) {
    // store.commit('logout');
    return next('/');
  }
  const servicename = process.env.VUE_APP_SERVICENAME
    ? String(process.env.VUE_APP_SERVICENAME).toLowerCase()
    : undefined;
  if (
    !(
      (servicename === 'grandmaster' || servicename === 'localgm') &&
      store.state.user &&
      (store.state.user.maff ||
        store.state.user.canUploadLeads ||
        // store.state.isAdmin ||
        OTHER_LEVEL_TRUSTED_USERS.includes(store.state.user.email))
    ) &&
    // !store.state.isManager &&
    (to.path === '/prepared-to-push' || to.path === '/psp')
  ) {
    return next('/');
  }

  if (
    !(
      (servicename === 'grandmaster' || servicename === 'localgm') &&
      store.state.user &&
      (store.state.user.maff ||
        // store.state.isAdmin ||
        OTHER_LEVEL_TRUSTED_USERS.includes(store.state.user.email))
    ) &&
    // !store.state.isManager &&
    to.path === '/geo-selection'
  ) {
    return next('/');
  }

  if (
    !(
      (servicename === 'grandmaster' || servicename === 'localgm') &&
      store.state.user &&
      (store.state.user.hasWorkers || OTHER_LEVEL_TRUSTED_USERS.includes(store.state.user.email))
    ) &&
    // !store.state.isManager &&
    to.path === '/workers'
  ) {
    return next('/');
  }

  const $rent = config.rent; // validateBoolean(process.env.VUE_APP_RENT);
  if ($rent) {
    ALLOWED_ROUTES = ALLOWED_ROUTES.filter((r) => !RENT_DISALLOWED_ROUTES.includes(r));
  }

  return next();
});

export default router;
