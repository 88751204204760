export default {
  RequestType: (c) => ['get', 'post'].includes(c),
  RequestDataTransferForm: (c) =>
    [
      'json',
      'x-www-form-urlencoded',
      'formdata',
      'querystring',
      'querystring-array-serialized',
      'endpoint',
      'endpoint-array-serialized',
    ].includes(c),
  EncodeData: (c) => typeof c === 'boolean' || !c,
  // RequestHeaders: (c) => c && typeof c === 'number' && c > 0,
  Auth: (c) => c && c.method === 'direct',
};
