<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h6 class="mt-3">{{ name }}</h6>
    <label class="mb-2" style="color: #00a700">&lt;Key&gt;: &lt;Value&gt;</label>
    <b-button @click="addElement()" class="ml-2" variant="info">
      <font-awesome-icon icon="plus"></font-awesome-icon>
    </b-button>
    <b-form inline v-for="(kv, i) in arr" :key="i">
      <!--      <b-form-group class="my-3" label="Key" :label-for="`kvoa-key-${i}`">-->
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
      :state="isValid.fields.name"-->
      <b-form-input
        :id="`kvoa-key-${i}`"
        class="array-item-inline"
        type="text"
        v-model="arr[i].key"
        @input="emitEditEvent"
        :state="isValid.fields[i].key"
      ></b-form-input>
      <!--      </b-form-group>-->
      <!--      <b-form-group class="my-3" label="Value" :label-for="`kvoa-value-${i}`">-->
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
      :state="isValid.fields.name"-->
      <b-form-input
        :id="`kvoa-value-${i}`"
        class="array-item-inline"
        type="text"
        v-model="arr[i].value"
        @input="emitEditEvent"
        :state="isValid.fields[i].value"
      ></b-form-input>
      <!--      </b-form-group>-->
      <b-button @click="removeElement(i)" variant="danger">
        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import '@/assets/form.scss';
// import handleValidateEvent from '@/utils/handleValidateEvent';
import { formValid } from '@/validators';

export default {
  name: 'KeyValueObjectArrayForm',
  props: {
    name: { type: String, default: 'KeyValueObjectArrayForm' },
    arr: { type: Array, default: () => [] },
    disallowEmpty: { type: Boolean, default: false },
  },
  data() {
    return { backdoor: 0, hoverMe: false };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = this.arr.map((e) => {
        return {
          key:
            !!e.key &&
            typeof e.key === 'string' &&
            e.key.length > 0 &&
            /^[a-zA-Z0-9\-_<> ]*$/.test(e.key),
          value:
            !!e.value &&
            typeof e.value === 'string' &&
            e.value.length > 0 &&
            /^[a-zA-Z0-9\-_<> ]*$/.test(e.value),
        };
      });
      const form = (!this.disallowEmpty || fields.length > 0) && formValid(fields);
      // this.$emit('validate', form);
      // console.log('KeyValueObjectArrayForm valiudated', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    addElement() {
      this.arr.push({ key: '', value: '' });
      this.backdoor += 1;
      this.emitEditEvent();
    },
    removeElement(index) {
      this.arr.splice(index, 1);
      this.backdoor += 1;
      this.emitEditEvent();
    },
    emitEditEvent() {
      this.backdoor += 1;
      this.$emit('edit', this.arr);
    },
    // handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
