<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">
        {{ $route.path === '/create-injection' ? 'Create Injection' : 'Edit Injection' }}
      </h2>
    </div>
    <!--    <b-form-checkbox class="my-2" v-model="injectionForm.active" disabled>Active</b-form-checkbox>-->

    <b-form-group
      class="my-2"
      label="Name"
      label-for="injection-name"
      :invalid-feedback="invalidFeedback.injection.name"
      :state="isValid.fields.name"
    >
      <b-form-input
        type="text"
        id="injection-name"
        v-model="injectionForm.name"
        :state="isValid.fields.name"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="mt-2 mb-1"
      label="From Boxes"
      label-for="box-from"
      :invalid-feedback="invalidFeedback.injection.boxFrom"
      :state="isValid.fields.boxFrom"
    >
      <multiselect
        id="box-from"
        v-model="injectionForm.boxFrom"
        :options="boxOptions"
        track-by="name"
        label="name"
        :state="isValid.fields.boxFrom"
        :closeOnSelect="false"
        :multiple="true"
      ></multiselect>
      <!--        type="text"
      text-field="name"
        value-field="_id"-->
    </b-form-group>
    <b-button class="mt-1 mb-2" variant="danger" size="sm" @click="clearFormField('boxFrom')">
      Clear
    </b-button>
    <b-button
      class="mt-1 mb-2 mx-2"
      variant="primary"
      size="sm"
      @click="selectAllOptions('boxFrom', 'boxOptions')"
    >
      Select All
    </b-button>

    <b-form-group
      class="mt-2 mb-1"
      label="Avoid Boxes"
      label-for="box-avoid"
      :invalid-feedback="invalidFeedback.injection.boxAvoid"
      :state="isValid.fields.boxAvoid"
    >
      <multiselect
        id="box-avoid"
        v-model="injectionForm.boxAvoid"
        :options="boxOptions"
        track-by="name"
        label="name"
        :state="isValid.fields.boxAvoid"
        :closeOnSelect="false"
        :multiple="true"
      ></multiselect>
    </b-form-group>
    <b-button class="mt-1 mb-2" variant="danger" size="sm" @click="clearFormField('boxAvoid')">
      Clear
    </b-button>
    <b-button
      class="mt-1 mb-2 mx-2"
      variant="primary"
      size="sm"
      @click="selectAllOptions('boxAvoid', 'boxOptions')"
    >
      Select All
    </b-button>

    <b-form-checkbox class="my-2" v-model="injectionForm.sendAll">
      Send leads from All Campaigns
    </b-form-checkbox>
    <div v-if="injectionForm.sendAll">
      <p>
        Will send leads from
        <b>Selected Campaigns</b>
        <!--        in-->
        <!--        <b>{{ injectionForm.boxFrom && injectionForm.boxFrom.length > 0 ? 'BoxFrom' : 'BoxTo' }}</b>-->
      </p>
      <p>If no campaigns selected, will send from all</p>

      <b-form-group
        class="mt-2 mb-1"
        label="From Campaigns"
        label-for="campaigns-from"
        :invalid-feedback="invalidFeedback.injection.campaignsFrom"
        :state="isValid.fields.campaignsFrom"
      >
        <multiselect
          id="campaigns-from"
          v-model="injectionForm.campaignsFrom"
          :options="campaignsOptions"
          track-by="name"
          label="name"
          :state="isValid.fields.campaignsFrom"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-form-group>
      <b-button
        class="mt-1 mb-2"
        variant="danger"
        size="sm"
        @click="clearFormField('campaignsFrom')"
      >
        Clear
      </b-button>
      <b-button
        class="mt-1 mb-2 mx-2"
        variant="primary"
        size="sm"
        @click="selectAllOptions('campaignsFrom', 'campaignsOptions')"
      >
        Select All
      </b-button>

      <b-form-group
        class="mt-2 mb-1"
        label="Avoid Campaigns"
        label-for="campaigns-avoid"
        :invalid-feedback="invalidFeedback.injection.campaignsAvoid"
        :state="isValid.fields.campaignsAvoid"
      >
        <multiselect
          id="campaigns-avoid"
          v-model="injectionForm.campaignsAvoid"
          :options="campaignsOptions"
          track-by="name"
          label="name"
          :state="isValid.fields.campaignsAvoid"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-form-group>
      <b-button
        class="mt-1 mb-2"
        variant="danger"
        size="sm"
        @click="clearFormField('campaignsAvoid')"
      >
        Clear
      </b-button>
      <b-button
        class="mt-1 mb-2 mx-2"
        variant="primary"
        size="sm"
        @click="selectAllOptions('campaignsAvoid', 'campaignsOptions')"
      >
        Select All
      </b-button>
    </div>
    <p v-else>
      Will send leads
      <b>only from TECH_CAMPAIGN</b>
      in
      <b>{{ injectionForm.boxFrom && injectionForm.boxFrom.length > 0 ? 'BoxFrom' : 'BoxTo' }}</b>
    </p>

    <b-form-group
      class="mt-2 mb-1"
      label="From Affiliates"
      label-for="affiliates-from"
      :invalid-feedback="invalidFeedback.injection.affiliatesFrom"
      :state="isValid.fields.affiliatesFrom"
    >
      <multiselect
        id="affiliates-from"
        v-model="injectionForm.affiliatesFrom"
        :options="affiliatesOptions"
        track-by="name"
        label="name"
        :state="isValid.fields.affiliatesFrom"
        :closeOnSelect="false"
        :multiple="true"
      ></multiselect>
    </b-form-group>
    <b-button
      class="mt-1 mb-2"
      variant="danger"
      size="sm"
      @click="clearFormField('affiliatesFrom')"
    >
      Clear
    </b-button>
    <b-button
      class="mt-1 mb-2 mx-2"
      variant="primary"
      size="sm"
      @click="selectAllOptions('affiliatesFrom', 'affiliatesOptions')"
    >
      Select All
    </b-button>

    <b-form-group
      class="mt-2 mb-1"
      label="Avoid Affiliates"
      label-for="affiliates-avoid"
      :invalid-feedback="invalidFeedback.injection.affiliatesAvoid"
      :state="isValid.fields.affiliatesAvoid"
    >
      <multiselect
        id="affiliates-avoid"
        v-model="injectionForm.affiliatesAvoid"
        :options="affiliatesOptions"
        track-by="name"
        label="name"
        :state="isValid.fields.affiliatesAvoid"
        :closeOnSelect="false"
        :multiple="true"
      ></multiselect>
    </b-form-group>
    <b-button
      class="mt-1 mb-2"
      variant="danger"
      size="sm"
      @click="clearFormField('affiliatesAvoid')"
    >
      Clear
    </b-button>
    <b-button
      class="mt-1 mb-2 mx-2"
      variant="primary"
      size="sm"
      @click="selectAllOptions('affiliatesAvoid', 'affiliatesOptions')"
    >
      Select All
    </b-button>

    <b-form-group
      class="my-2"
      label="Box To"
      label-for="box-to"
      :invalid-feedback="invalidFeedback.injection.boxTo"
      :state="isValid.fields.boxTo"
    >
      <!--      <b-form-select-->
      <!--        type="text"-->
      <!--        id="box-to"-->
      <!--        v-model="injectionForm.boxTo"-->
      <!--        :options="boxToOptions"-->
      <!--        text-field="name"-->
      <!--        value-field="_id"-->
      <!--        :state="isValid.fields.boxTo"-->
      <!--      ></b-form-select>-->
      <multiselect
        id="box-to"
        v-model="injectionForm.boxTo"
        :options="boxToOptions"
        track-by="name"
        label="name"
        :state="isValid.fields.boxTo"
        :closeOnSelect="true"
        :multiple="false"
      ></multiselect>
    </b-form-group>
    <b-form-checkbox class="my-2" v-model="injectionForm.paranoid">Paranoid</b-form-checkbox>
    <!--    <h4>{{ selectedBoxCountries }}</h4>-->
    <!--    <div class="mt-3 mb-4" v-if="selectedBoxCountries.selected">-->
    <!--      <b-card class="my-2">-->
    <!--        <b-card-text>-->
    <!--          Box accepts geos:-->
    <!--          <b-badge-->
    <!--            class="mx-1"-->
    <!--            v-for="(c, index) in selectedBoxCountries.boxAccept"-->
    <!--            v-bind:key="index"-->
    <!--          >-->
    <!--            {{ c }}-->
    <!--          </b-badge>-->
    <!--        </b-card-text>-->
    <!--      </b-card>-->
    <!--      <b-card-->
    <!--        v-if="selectedBoxCountries.dontMatch.length > 0"-->
    <!--        border-variant="warning"-->
    <!--        header="Warning"-->
    <!--        header-bg-variant="warning"-->
    <!--        footer-bg-variant="transparent"-->
    <!--        class="my-2"-->
    <!--      >-->
    <!--        <b-card-text>-->
    <!--          Campaigns in this box don't accept some of the geos it sends. Consider to-->
    <!--          <router-link :to="`/box/edit/${injectionForm.boxTo}`">edit box</router-link>-->
    <!--          before making injection.-->
    <!--        </b-card-text>-->
    <!--        <b-card-text>-->
    <!--          Not accepted geos:-->
    <!--          <b-badge-->
    <!--            class="mx-1"-->
    <!--            variant="warning"-->
    <!--            v-for="(c, index) in selectedBoxCountries.dontMatch"-->
    <!--            v-bind:key="index"-->
    <!--          >-->
    <!--            {{ c }}-->
    <!--          </b-badge>-->
    <!--        </b-card-text>-->
    <!--      </b-card>-->

    <b-form-group
      class="my-2"
      label="Geos"
      label-for="countries"
      :state="isValid.fields.countries"
      :invalid-feedback="invalidFeedback.injection.automated"
    >
      <multiselect
        v-model="injectionForm.countries"
        :options="countryFilteredOptions"
        id="countries"
        track-by="value"
        @search-change="countryMultiSelectSearch"
        :custom-label="countryWithCode"
        :closeOnSelect="false"
        :multiple="true"
      ></multiselect>
    </b-form-group>
    <b-form-group
      class="my-2"
      :state="isValid.fields.automated"
      :invalid-feedback="invalidFeedback.injection.automated"
      label-for="automated"
    >
      <b-form-checkbox
        id="automated"
        v-model="injectionForm.automated"
        :state="isValid.fields.automated"
        :disabled="injectionAutomationDisabled"
      >
        Automated
        {{
          injectionAutomationDisabled ? ' | Exactly 1 Geo must be selected to use this option' : ''
        }}
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      class="my-2"
      :state="isValid.fields.considerRMRatio"
      :invalid-feedback="invalidFeedback.injection.automated"
      label-for="considerRMRatio"
    >
      <b-form-checkbox
        id="considerRMRatio"
        v-model="injectionForm.considerRMRatio"
        :state="isValid.fields.considerRMRatio"
        :disabled="injectionAutomationDisabled"
      >
        Consider RM Ratio
        {{
          injectionAutomationDisabled ? ' | Exactly 1 Geo must be selected to use this option' : ''
        }}
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      class="my-2"
      :state="isValid.fields.considerWorkingAffiliates"
      :invalid-feedback="invalidFeedback.injection.automated"
      label-for="considerWorkingAffiliates"
    >
      <b-form-checkbox
        id="considerWorkingAffiliates"
        v-model="injectionForm.considerWorkingAffiliates"
        :state="isValid.fields.considerWorkingAffiliates"
        :disabled="injectionAutomationDisabled"
      >
        Consider Working Affiliates
        {{
          injectionAutomationDisabled ? ' | Exactly 1 Geo must be selected to use this option' : ''
        }}
      </b-form-checkbox>
    </b-form-group>
    <!--        </div>-->
    <b-form-group label="Status groups to send" label-for="inj-status-groups" class="my-3">
      <b-form-checkbox-group
        id="inj-status-groups"
        v-model="injectionForm.statusGroups"
        :options="statusGroupsOptions"
      ></b-form-checkbox-group>
    </b-form-group>

    <h6 class="mt-4">Select leads received from</h6>
    <b-row>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Date"
          label-for="start-select-date"
          :invalid-feedback="invalidFeedback.injection.date"
          :state="isValid.fields.startSelectDate"
        >
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="start-select-date"
            today-button
            reset-button
            close-button
            :max="injectionForm.endSelectDate"
            v-model="injectionForm.startSelectDate"
            :state="isValid.fields.startSelectDate"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Time"
          label-for="start-select-time"
          :invalid-feedback="invalidFeedback.injection.time"
          :state="isValid.fields.startSelectTime"
        >
          <!--          <b-form-input-->
          <!--            id="start-time"-->
          <!--            v-model="injectionForm.startTime"-->
          <!--            :state="isValid.fields.startTime"-->
          <!--            type="text"-->
          <!--            placeholder="HH:mm:ss"-->
          <!--          ></b-form-input>-->
          <!--          <b-input-group-append>-->
          <b-form-timepicker
            id="start-select-time"
            now-button
            reset-button
            right
            :hour12="false"
            v-model="injectionForm.startSelectTime"
            :state="isValid.fields.startSelectTime"
          ></b-form-timepicker>
          <!--            aria-controls="start-time"-->
          <!--          </b-input-group-append>-->
        </b-form-group>
      </b-col>
    </b-row>

    <h6 class="mt-2">Select leads received till</h6>
    <b-row>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Date"
          label-for="end-select-date"
          :invalid-feedback="invalidFeedback.injection.date"
          :state="isValid.fields.endSelectDate"
        >
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="end-select-date"
            today-button
            reset-button
            close-button
            :min="injectionForm.startSelectDate"
            v-model="injectionForm.endSelectDate"
            :state="isValid.fields.endSelectDate"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Time"
          label-for="end-select-time"
          :invalid-feedback="invalidFeedback.injection.time"
          :state="isValid.fields.endSelectTime"
        >
          <!--          <b-form-input-->
          <!--            id="end-time"-->
          <!--            v-model="injectionForm.endTime"-->
          <!--            :state="isValid.fields.endTime"-->
          <!--            type="text"-->
          <!--            placeholder="HH:mm:ss"-->
          <!--          ></b-form-input>-->
          <!--          <b-input-group-append>-->
          <b-form-timepicker
            id="end-select-time"
            now-button
            reset-button
            right
            :hour12="false"
            v-model="injectionForm.endSelectTime"
            :state="isValid.fields.endSelectTime"
          ></b-form-timepicker>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="my-2">
      <h5>
        Leads available by Country:
        <b-spinner small v-if="requesting"></b-spinner>
      </h5>
      <ul
        v-if="
          !requesting &&
          countriesCounts &&
          Array.isArray(countriesCounts) &&
          countriesCounts.length > 0
        "
      >
        <li v-for="(c, index) in countriesCounts" v-bind:key="index">
          <!--          <b-badge class="mx-1">-->
          {{ c.country }}: {{ c.count }}
          <!--          </b-badge>-->
        </li>
      </ul>
    </div>

    <!--    <b-form-group-->
    <!--      class="my-2"-->
    <!--      label="Manager"-->
    <!--      label-for="injection-manager"-->
    <!--      :invalid-feedback="invalidFeedback.manager"-->
    <!--      :state="isValid.fields.manager"-->
    <!--    >-->
    <!--      &lt;!&ndash;            :valid-feedback="validFeedback"&ndash;&gt;-->
    <!--      &lt;!&ndash;            <h6 class="my-2">Manager</h6>&ndash;&gt;-->
    <!--      <b-form-select-->
    <!--        disabled-->
    <!--        id="box-manager"-->
    <!--        :state="isValid.fields.manager"-->
    <!--        v-model="injectionForm.manager"-->
    <!--        :options="managersOptions"-->
    <!--        text-field="name"-->
    <!--        value-field="_id"-->
    <!--      ></b-form-select>-->
    <!--    </b-form-group> zxc-->
    <div v-if="iAmTrustedUser && false">
      <b-row>
        <b-col cols="5" md="3">
          <b-form-group
            class="my-2"
            label-for="inj-m"
            :state="isValid.fields.m"
            :invalid-feedback="invalidFeedback.injection.m"
          >
            <b-form-checkbox
              class="my-2"
              id="inj-m"
              v-model="injectionForm.m"
              :state="isValid.fields.m"
            >
              Make this injection depend on other leads flows to BoxTo's campaigns
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="7" md="4">
          <b-form-group
            class="my-2"
            label="Send 1 lead per this amount of leads from other flows"
            label-for="inj-x"
            :state="isValid.fields.x"
            :invalid-feedback="invalidFeedback.injection.x"
          >
            <b-form-input
              type="number"
              id="inj-leads-to-send"
              v-model="injectionForm.x"
              :state="isValid.fields.x"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        class="my-2"
        label-for="inj-salat"
        :state="isValid.fields.makeSalat"
        :invalid-feedback="invalidFeedback.injection.makeSalat"
      >
        <b-form-checkbox
          class="my-2"
          id="inj-salat"
          v-model="injectionForm.makeSalat"
          :state="isValid.fields.makeSalat"
        >
          Make Salat (This Is Very Dangerous. You Have To Be Absolutely Sure About This.)
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        class="my-2"
        label-for="inj-count-all-actions"
        :state="isValid.fields.countAllActions"
        :invalid-feedback="invalidFeedback.injection.countAllActions"
      >
        <b-form-checkbox
          class="my-2"
          id="inj-count-all-actions"
          v-model="injectionForm.countAllActions"
          :state="isValid.fields.countAllActions"
        >
          Count All Actions, not only starting from this inj start date
        </b-form-checkbox>
      </b-form-group>
    </div>
    <div class="mt-3 mb-4">
      <b-card class="my-2">
        <b-card-text>
          BoxTo sends to Campaigns:
          <span class="mx-1" v-for="(c, index) in boxToCampaigns" v-bind:key="index">
            <b-badge
              :variant="
                c.cf_exists
                  ? c.bce_active
                    ? c.active
                      ? 'success'
                      : 'danger'
                    : 'warning'
                  : 'secondary'
              "
              v-b-popover.hover.html="c.geos"
              :title="
                c.cf_exists
                  ? `<b>${
                      c.active
                        ? '<span style=\'color: green\'>Campaign</span>'
                        : '<span style=\'color: red\'>Campaign</span>'
                    }</b>, <b>${
                      c.bce_active
                        ? '<span style=\'color: green\'>BoxCampaign</span>'
                        : '<span style=\'color: red\'>BoxCampaign</span>'
                    }</b>`
                  : 'Active status unknown'
              "
            >
              {{ c.name }}
            </b-badge>
          </span>
        </b-card-text>
      </b-card>
      <b-card
        v-if="injectionForm.m && boxToCampaigns.length > 1"
        border-variant="warning"
        header="Warning"
        header-bg-variant="warning"
        footer-bg-variant="transparent"
        class="my-2"
      >
        <b-card-text>
          <b>Be careful!</b>
          This BoxTo has more than 1 campaign. This can be
          <b>VERY</b>
          powerful and hard to manage. Consider choosing box that has only 1 campaign instead.
        </b-card-text>
        <b-card-text>
          BoxTo Campaigns:
          <b-badge
            class="mx-1"
            variant="warning"
            v-for="(c, index) in boxToCampaigns"
            v-bind:key="index"
          >
            {{ c.name }}
          </b-badge>
        </b-card-text>
      </b-card>
      <div class="my-2" v-if="boxToCampaigns.length > 0">
        <!--        &&
          (user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg') -->
        <h6 class="mt-3">Round-Robin Order</h6>
        <label class="mb-2" style="color: #00a700">
          &lt;Campaign&gt;: &lt;Order amount to send&gt;
        </label>
        <!--        <b-button @click="addOrderedAmount()" class="ml-2" variant="info">-->
        <!--          <font-awesome-icon icon="plus"></font-awesome-icon>-->
        <!--        </b-button>-->
        <b-form inline v-for="(oa, i) in injectionForm.roundRobinOrderedAmounts" :key="i">
          <!--          <multiselect-->
          <!--            class="array-item-inline form-control"-->
          <!--            v-model="oa.campaign"-->
          <!--            :options="boxToCampaigns"-->
          <!--            track-by="name"-->
          <!--            label="name"-->
          <!--            :multiple="false"-->
          <!--          ></multiselect>-->
          <span class="mx-2">{{ oa.name }}</span>
          <!--                      :state="isValid.fields.roundRobinOrderedAmounts[i].campaign"
-->
          <b-form-input class="array-item-inline" type="number" v-model="oa.amount"></b-form-input>
          <!--          :state="isValid.fields.roundRobinOrderedAmounts[i].amount"-->

          <!--          <span class="mx-2">Available: {{ getCampaignCounts(oa) }}</span>-->
          <!--          <b-button @click="removeOrderedAmount(i)" variant="danger">-->
          <!--            <font-awesome-icon icon="trash-alt"></font-awesome-icon>-->
          <!--          </b-button>-->
        </b-form>
      </div>
    </div>

    <b-form-group
      class="my-2"
      label="Maximum Leads to Select"
      label-for="inj-order-to-select"
      :state="isValid.fields.orderToSelect"
    >
      <b-form-input
        type="number"
        id="inj-order-to-select"
        v-model="injectionForm.orderToSelect"
        :state="isValid.fields.orderToSelect"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Amount of leads order to send"
      label-for="inj-leads-to-send"
      :state="isValid.fields.amountLeadsToSend"
    >
      <b-form-input
        type="number"
        id="inj-leads-to-send"
        v-model="injectionForm.amountLeadsToSend"
        :state="isValid.fields.amountLeadsToSend"
      ></b-form-input>
    </b-form-group>

    <!--    <b-form-group-->
    <!--      label="Leads to send"-->
    <!--      label-for="leads-to-send"-->
    <!--      :invalid-feedback="invalidFeedback.injection.leadsToSend"-->
    <!--      :state="isValid.fields.leadsToSend"-->
    <!--      class="mt-2 mb-1"-->
    <!--    >-->
    <!--      <b-form-file-->
    <!--        id="leads-to-send"-->
    <!--        v-model="injectionForm.leadsToSend"-->
    <!--        :state="isValid.fields.leadsToSend"-->
    <!--        placeholder="Choose a file or drop it here..."-->
    <!--        drop-placeholder="Drop file here..."-->
    <!--        accept=".xlsx .csv"-->
    <!--      ></b-form-file>-->
    <!--    </b-form-group>-->
    <!--    <b-button class="mt-1 mb-2" variant="danger" size="sm" @click="clearFormField('leadsToSend')">-->
    <!--      Clear Leads to send-->
    <!--    </b-button>-->
    <!--    <b-form-checkbox class="my-2" v-model="injectionForm.randomizeIps">-->
    <!--      Randomize IPs in file-->
    <!--    </b-form-checkbox>-->

    <!--    <b-form-group class="my-1" label="Default Country" label-for="injection-default-country">-->
    <!--      <multiselect-->
    <!--        id="injection-default-country"-->
    <!--        v-model="injectionForm.defaultCountry"-->
    <!--        placeholder="Select one"-->
    <!--        :options="countryOptions"-->
    <!--        :allow-empty="true"-->
    <!--        track-by="value"-->
    <!--        @search-change="countryMultiSelectSearch"-->
    <!--        :custom-label="countryWithCode"-->
    <!--      ></multiselect>-->
    <!--    </b-form-group>-->

    <b-form-group class="my-2" label="Source" label-for="inj-so" :state="isValid.fields.so">
      <b-form-input
        type="text"
        id="inj-so"
        v-model="injectionForm.so"
        :state="isValid.fields.so"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Batch size"
      label-for="batch-size"
      :invalid-feedback="invalidFeedback.injection.batchSize"
      :state="isValid.fields.batchSize"
    >
      <b-form-input
        type="number"
        id="batch-size"
        v-model="injectionForm.batchSize"
        :state="isValid.fields.batchSize"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Min time between batches"
      label-for="box-time-between-batches-1"
      :invalid-feedback="invalidFeedback.injection.timeBetweenBatches"
      :state="isValid.fields.timeBetweenBatches1"
    >
      <b-form-select
        type="text"
        id="box-time-between-batches-1"
        v-model="injectionForm.timeBetweenBatches1"
        :options="timeBetweenBatchesOptions.slice(0, -1)"
        :state="isValid.fields.timeBetweenBatches1"
      ></b-form-select>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Max time between batches"
      label-for="box-time-between-batches-2"
      :invalid-feedback="invalidFeedback.injection.timeBetweenBatches"
      :state="isValid.fields.timeBetweenBatches2"
    >
      <b-form-select
        type="text"
        id="box-time-between-batches-2"
        v-model="injectionForm.timeBetweenBatches2"
        :options="timeBetweenBatchesOptions.slice(1)"
        :state="isValid.fields.timeBetweenBatches2"
      ></b-form-select>
    </b-form-group>

    <b-card class="my-2">
      <b-card-text>
        Average leads amount to send per 1 hour:
        <b-badge class="mx-1 px-1" variant="primary">{{ avgLeadsPerHour }}</b-badge>
        , 24 hours:
        <b-badge class="mx-1 px-1" variant="primary">{{ avgLeadsPerDay }}</b-badge>
      </b-card-text>
    </b-card>

    <b-card
      v-if="injectionSpeedIsHigh"
      border-variant="warning"
      header="Warning"
      header-bg-variant="warning"
      footer-bg-variant="transparent"
      class="my-2"
    >
      <b-card-text>
        Are you sure about that? Injection speed is
        <i><b>very</b></i>
        high
      </b-card-text>
    </b-card>

    <h6 class="mt-4">Start</h6>
    <b-row>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Date"
          label-for="start-date"
          :invalid-feedback="invalidFeedback.injection.date"
          :state="isValid.fields.startDate"
        >
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="start-date"
            today-button
            reset-button
            close-button
            :max="injectionForm.endDate"
            v-model="injectionForm.startDate"
            :state="isValid.fields.startDate"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Time"
          label-for="start-time"
          :invalid-feedback="invalidFeedback.injection.time"
          :state="isValid.fields.startTime"
        >
          <!--          <b-form-input-->
          <!--            id="start-time"-->
          <!--            v-model="injectionForm.startTime"-->
          <!--            :state="isValid.fields.startTime"-->
          <!--            type="text"-->
          <!--            placeholder="HH:mm:ss"-->
          <!--          ></b-form-input>-->
          <!--          <b-input-group-append>-->
          <b-form-timepicker
            id="start-time"
            now-button
            reset-button
            right
            :hour12="false"
            v-model="injectionForm.startTime"
            :state="isValid.fields.startTime"
          ></b-form-timepicker>
          <!--            aria-controls="start-time"-->
          <!--          </b-input-group-append>-->
        </b-form-group>
      </b-col>
    </b-row>

    <h6 class="mt-2">End</h6>
    <b-row>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Date"
          label-for="end-date"
          :invalid-feedback="invalidFeedback.injection.date"
          :state="isValid.fields.endDate"
        >
          <b-form-datepicker
            :date-format-options="dateFormatOptions"
            id="end-date"
            today-button
            reset-button
            close-button
            :min="injectionForm.startDate"
            v-model="injectionForm.endDate"
            :state="isValid.fields.endDate"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group
          class="mb-2"
          label="Time"
          label-for="end-time"
          :invalid-feedback="invalidFeedback.injection.time"
          :state="isValid.fields.endTime"
        >
          <!--          <b-form-input-->
          <!--            id="end-time"-->
          <!--            v-model="injectionForm.endTime"-->
          <!--            :state="isValid.fields.endTime"-->
          <!--            type="text"-->
          <!--            placeholder="HH:mm:ss"-->
          <!--          ></b-form-input>-->
          <!--          <b-input-group-append>-->
          <b-form-timepicker
            id="end-time"
            reset-button
            right
            :hour12="false"
            v-model="injectionForm.endTime"
            :state="isValid.fields.endTime"
          ></b-form-timepicker>
          <!--                      aria-controls="end-time"
-->
          <!--          </b-input-group-append>-->
        </b-form-group>
      </b-col>
    </b-row>
    <h5>Schedule</h5>
    <b-row>
      <b-col cols="12" lg="6" md="6">
        <h6 class="mb-2">Hour Limit</h6>
        <h6 class="mr-1">From: {{ injectionForm.hourLimit[0] }}:00</h6>
        <b-form-input
          v-model="injectionForm.hourLimit[0]"
          type="range"
          min="0"
          :max="23"
        ></b-form-input>
        <h6 class="mx-1 my-2">to: {{ injectionForm.hourLimit[1] }}:59</h6>
        <b-form-input
          v-model="injectionForm.hourLimit[1]"
          type="range"
          :min="0"
          max="23"
        ></b-form-input>
      </b-col>
      <b-col cols="12" lg="6" md="6">
        <b-form-group class="my-2" label="Days" :label-for="`days`">
          <b-form-checkbox-group
            :id="`days`"
            v-model="injectionForm.days"
            :options="daysOfWeek"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            size="lg"
            stacked
          ></b-form-checkbox-group>
          <b-button
            class="mr-2"
            variant="primary"
            @click="injectionForm.days = [0, 1, 2, 3, 4, 5, 6]"
          >
            All
          </b-button>
          <b-button @click="injectionForm.days = []">None</b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      class="mt-2 mb-1"
      label="Assign to Affiliate"
      label-for="assign-to-affiliate"
      :invalid-feedback="invalidFeedback.injection.assignToAffiliate"
      :state="isValid.fields.assignToAffiliate"
    >
      <multiselect
        id="assign-to-affiliate"
        v-model="injectionForm.assignToAffiliate"
        :options="affiliatesOptions"
        track-by="name"
        label="name"
        :state="isValid.fields.assignToAffiliate"
        :closeOnSelect="true"
        :multiple="false"
      ></multiselect>
      <b-button @click="clearAssignToAffiliate()" variant="danger">Clear</b-button>
    </b-form-group>
    <div class="d-flex justify-content-between my-3">
      <b-button @click="saveInjection()" variant="primary" :disabled="!isValid.form || requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        {{ $route.params.id ? 'Save Injection' : 'Create Injection' }}
      </b-button>
    </div>
  </b-container>
</template>

<script>
/* eslint-disable func-names */

import { mapState } from 'vuex';
import { formValid, isValid } from '@/validators';
import {
  TRUSTED_USERS,
  invalidFeedback,
  statusGroupsOptions,
  countryOptions,
  daysOfWeek,
  dateFormatOptions,
} from '@/consts';
import _ from 'lodash';
import {
  handleRequestError,
  showCard,
  countAvgLeads,
  countryMultiSelectSearch,
  mapIdsOrEmpty,
  tableFormatterArrayValues,
} from '@/utils';
import { saveInjection, countActionsForInj } from '@/utils/api';
import areYouSureAudio from '@/assets/are-you-sure.mp3';
import store from '@/store';

const itemInitialValues = {
  name: undefined,
  boxFrom: [],
  boxAvoid: [],
  campaignsFrom: [],
  campaignsAvoid: [],
  affiliatesFrom: [],
  affiliatesAvoid: [],
  boxTo: undefined,
  statusGroups: [],
  active: true,
  sendAll: false,
  paranoid: false,
  manager: undefined,
  amountLeadsToSend: undefined,
  orderToSelect: undefined,
  leadsToSave: undefined,
  so: undefined,
  startDate: undefined,
  startTime: undefined,
  endDate: undefined,
  endTime: undefined,
  startSelectDate: undefined,
  startSelectTime: undefined,
  endSelectDate: undefined,
  endSelectTime: undefined,
  batchSize: 1,
  timeBetweenBatches1: 5,
  timeBetweenBatches2: 15,
  m: undefined,
  x: undefined,
  makeSalat: undefined,
  countAllActions: undefined,
  randomizeIps: undefined,
  defaultCountry: undefined,
  roundRobinOrderedAmounts: [],
  hourLimit: [0, 23],
  days: daysOfWeek.map((d) => d.item),
  countries: [],
  assignToAffiliate: undefined,
  automated: false,
  considerRMRatio: false,
  considerWorkingAffiliates: false,
};

export default {
  name: 'CreateInjection',
  data() {
    return {
      dateFormatOptions,
      campaignsCounts: [],
      countriesCounts: [],
      audioPlaying: 0,
      backdoor: 0,
      invalidFeedback,
      injectionForm: _.cloneDeep(itemInitialValues),
      timeBetweenBatchesOptions: [
        { text: '5 sec', value: 0.083 },
        { text: '30 sec', value: 0.5 },
        { text: '1 min', value: 1 },
        { text: '2 min', value: 2 },
        { text: '3 min', value: 3 },
        { text: '4 min', value: 4 },
        { text: '5 min', value: 5 },
        { text: '7 min', value: 7 },
        { text: '10 min', value: 10 },
        { text: '12 min', value: 12 },
        { text: '15 min', value: 15 },
        { text: '20 min', value: 20 },
        { text: '25 min', value: 25 },
        { text: '30 min', value: 30 },
        { text: '45 min', value: 45 },
        { text: '1 hour', value: 60 },
        { text: '2 hours', value: 120 },
      ],
      countryOptions,
      statusGroupsOptions,
      daysOfWeek,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'requesting', 'boxes', 'campaigns', 'affiliates', 'managers']),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    isValid() {
      this.backdoor;
      const fields = isValid('injection', this.injectionForm, this.injectionForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    boxRawOptions() {
      if (!this.user) return [];
      return this.boxes.filter((b) => {
        const hidden = this.user.hiddenBoxes;
        return !(hidden && Array.isArray(hidden) && hidden.includes(b._id));
      });
    },
    boxOptions() {
      return this.boxRawOptions.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    campaignsOptions() {
      if (!this.user) return [];
      return this.campaigns
        .filter((c) => {
          const hidden = this.user.hiddenCampaigns;
          return !(hidden && Array.isArray(hidden) && hidden.includes(c._id));
        })
        .map((m) => {
          return { name: m.name, _id: m._id };
        });
    },
    affiliatesOptions() {
      if (!this.user) return [];
      return this.affiliates.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    boxToOptions() {
      return this.boxRawOptions
        .filter((b) => b.active)
        .map((m) => {
          return { name: m.name, _id: m._id };
        });
    },
    managersOptions() {
      return this.managers.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    selectedBoxCountries() {
      const result = { selected: false, boxAccept: [], campaignsAccept: [], dontMatch: [] };
      if (!this.injectionForm.boxTo) return result;
      const b = this.boxes.find((_b) => _b._id === this.injectionForm.boxTo._id);
      if (!b) return result;
      const bcc = _.uniq(_.flatten((b.campaigns ? b.campaigns : []).map((c) => c.countries)));
      const cc = _.uniq(
        _.flatten(this.campaigns.filter((c) => c.boxes.includes(b._id)).map((c) => c.countries))
      );
      const countriesDontMatch = bcc.filter((c) => !cc.includes(c));
      result.selected = true;
      result.boxAccept = bcc;
      result.campaignsAccept = cc;
      result.dontMatch = countriesDontMatch;
      return result;
    },
    injectionAutomationDisabled() {
      return this.injectionForm.countries.length !== 1;
    },
    boxToCampaigns() {
      if (!this.injectionForm.boxTo) return [];
      const bt = this.boxes.find((b) => b._id === this.injectionForm.boxTo._id);
      return bt && bt.campaigns
        ? bt.campaigns.map((c) => {
            if (c.name && c._id) return { name: c.name, _id: c._id };
            const cid = c.campaign._id ? c.campaign._id : c.campaign;
            const cf = this.campaigns.find((ctf) => ctf._id === cid);
            if (!cf) {
              return { name: 'unknown', id: cid, geos: 'Geos unknown', cf_exists: false };
            }
            const geos = `Geos: ${tableFormatterArrayValues(
              c.countries.filter((country) => cf.countries.includes(country)),
              12
            )}`;
            return {
              name: cf.name ? cf.name : 'unknown',
              _id: cf._id ? cf._id : cf,
              cf_exists: true,
              active: cf.active,
              bce_active: c.active,
              geos,
            };
          })
        : [];
    },
    avgLeadsPerHour() {
      return countAvgLeads(
        this.injectionForm.timeBetweenBatches1,
        this.injectionForm.timeBetweenBatches2,
        60,
        this.injectionForm.batchSize
      );
    },
    avgLeadsPerDay() {
      return countAvgLeads(
        this.injectionForm.timeBetweenBatches1,
        this.injectionForm.timeBetweenBatches2,
        60 * 24,
        this.injectionForm.batchSize
      );
    },
    injectionSpeedIsHigh() {
      return (
        (this.injectionForm.timeBetweenBatches1 < 1 &&
          this.injectionForm.timeBetweenBatches2 < 5) ||
        this.avgLeadsPerHour >= 60
      );
    },
    timeBetweenBatches1() {
      return this.injectionForm.timeBetweenBatches1;
    },
    timeBetweenBatches2() {
      return this.injectionForm.timeBetweenBatches2;
    },
    countryFilteredOptions() {
      console.log('selectedBoxCountries', this.selectedBoxCountries);
      return countryOptions.filter(({ value }) =>
        this.selectedBoxCountries.boxAccept.includes(value)
      );
    },
  },
  methods: {
    clearAssignToAffiliate() {
      this.injectionForm.assignToAffiliate = undefined;
    },
    // selectAllBoxesFrom() {
    //   this.injectionForm.boxFrom = _.cloneDeep(this.boxOptions);
    // },
    selectAllOptions(formkey, optionskey) {
      this.injectionForm[formkey] = _.cloneDeep(this[optionskey]);
    },
    getCampaignCounts(oa) {
      const cc = this.campaignsCounts.find(
        (c) => c._id === (oa.campaign && oa.campaign._id ? oa.campaign._id : oa.campaign)
      );
      return cc && cc.count ? cc.count : 0;
    },
    addOrderedAmount() {
      this.injectionForm.roundRobinOrderedAmounts.push({ campaign: undefined, amount: 0 });
    },
    removeOrderedAmount(index) {
      this.injectionForm.roundRobinOrderedAmounts.splice(index, 1);
    },
    countActionsForInj() {
      this.$store.commit('make_requesting', { req: true });
      const injection = _.cloneDeep(this.injectionForm);
      console.log(this.injectionForm.boxTo);
      console.log(injection.boxTo);
      const data = {
        countries: this.injectionForm.countries,
        boxFrom: mapIdsOrEmpty(this.injectionForm.boxFrom),
        boxAvoid: mapIdsOrEmpty(this.injectionForm.boxAvoid),
        campaignsFrom: mapIdsOrEmpty(this.injectionForm.campaignsFrom),
        campaignsAvoid: mapIdsOrEmpty(this.injectionForm.campaignsAvoid),
        affiliatesFrom: mapIdsOrEmpty(this.injectionForm.affiliatesFrom),
        affiliatesAvoid: mapIdsOrEmpty(this.injectionForm.affiliatesAvoid),
        boxTo: this.injectionForm.boxTo
          ? this.injectionForm.boxTo._id
            ? this.injectionForm.boxTo._id
            : this.injectionForm.boxTo
          : undefined,
        startSelectDateTime:
          this.injectionForm.startSelectDate && this.injectionForm.startSelectTime
            ? `${this.injectionForm.startSelectDate}T${this.injectionForm.startSelectTime}`
            : undefined,
        endSelectDateTime:
          this.injectionForm.endSelectDate && this.injectionForm.endSelectTime
            ? `${this.injectionForm.endSelectDate}T${this.injectionForm.endSelectTime}`
            : undefined,
        sendAll: this.injectionForm.sendAll,
        paranoid: this.injectionForm.paranoid,
        statusGroups: this.injectionForm.statusGroups,
      };
      console.log('data before api function', data);
      countActionsForInj(this.token, data)
        .then((r) => {
          if (r.status === 200 && !r.data.error && r.data.success) {
            if (r.data.countriesCounts) {
              this.countriesCounts = r.data.countriesCounts;
            } else {
              showCard(this, `Something went wrong on counting actions for injetion`, 'warning');
              this.countriesCounts = [];
            }
            if (r.data.campaignsCounts) {
              this.campaignsCounts = r.data.campaignsCounts;
              this.injectionForm.roundRobinOrderedAmounts = r.data.campaignsCounts.map((cc) => {
                return {
                  campaign: cc._id,
                  name: cc.name ? cc.name : cc._id,
                  amount: 0,
                };
              });
            } else {
              showCard(this, `Something went wrong on counting actions for injetion`, 'warning');
              this.campaignsCounts = [];
            }
            showCard(this, `Counted actions for injection`, 'success');
          } else {
            const cardError =
              r.data.message || `${r.status}: Unknown error on counting actions for injection`;
            this.countriesCounts = [];
            this.campaignsCounts = [];
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on counting actions for injection`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    countryMultiSelectSearch,
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    playWarning() {
      if (this.injectionSpeedIsHigh && Date.now() - this.audioPlaying > 3000) {
        this.audioPlaying = Date.now();
        const audio = new Audio(areYouSureAudio);
        audio.play();
      }
    },
    clearFormField(field) {
      if (Object.keys(this.injectionForm).includes(field)) {
        this.injectionForm[field] = _.cloneDeep(itemInitialValues[field]);
      }
    },
    saveInjection() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        const injectionToSave = _.cloneDeep(this.injectionForm);
        injectionToSave.boxTo = injectionToSave.boxTo
          ? injectionToSave.boxTo._id
            ? injectionToSave.boxTo._id
            : injectionToSave.boxTo
          : undefined;
        // if (!injectionToSave.boxFrom || injectionToSave.boxFrom.length === 0) {
        //   injectionToSave.boxFrom = [injectionToSave.boxTo];
        // } else {
        //   injectionToSave.boxFrom = injectionToSave.boxFrom.map((bf) => (bf._id ? bf._id : bf));
        // }
        injectionToSave.boxFrom = mapIdsOrEmpty(injectionToSave.boxFrom);
        injectionToSave.boxAvoid = mapIdsOrEmpty(injectionToSave.boxAvoid);
        injectionToSave.campaignsFrom = mapIdsOrEmpty(injectionToSave.campaignsFrom);
        injectionToSave.campaignsAvoid = mapIdsOrEmpty(injectionToSave.campaignsAvoid);
        injectionToSave.affiliatesFrom = mapIdsOrEmpty(injectionToSave.affiliatesFrom);
        injectionToSave.affiliatesAvoid = mapIdsOrEmpty(injectionToSave.affiliatesAvoid);
        if (injectionToSave.startDate && injectionToSave.startTime) {
          injectionToSave.startDateTime = `${injectionToSave.startDate}T${injectionToSave.startTime}`;
        }
        if (injectionToSave.endDate && injectionToSave.endTime) {
          injectionToSave.endDateTime = `${injectionToSave.endDate}T${injectionToSave.endTime}`;
        }
        if (injectionToSave.startDate && injectionToSave.startTime) {
          injectionToSave.startDateTime = `${injectionToSave.startDate}T${injectionToSave.startTime}`;
        }
        if (injectionToSave.endDate && injectionToSave.endTime) {
          injectionToSave.endDateTime = `${injectionToSave.endDate}T${injectionToSave.endTime}`;
        }
        if (injectionToSave.startSelectDate && injectionToSave.startSelectTime) {
          injectionToSave.startSelectDateTime = `${injectionToSave.startSelectDate}T${injectionToSave.startSelectTime}`;
        }
        if (injectionToSave.endSelectDate && injectionToSave.endSelectTime) {
          injectionToSave.endSelectDateTime = `${injectionToSave.endSelectDate}T${injectionToSave.endSelectTime}`;
        }

        if (injectionToSave.countries) {
          injectionToSave.countries = injectionToSave.countries.map(({ value }) => value);
        }
        if (injectionToSave.defaultCountry)
          injectionToSave.defaultCountry = injectionToSave.defaultCountry.value
            ? injectionToSave.defaultCountry.value
            : injectionToSave.defaultCountry;

        injectionToSave.roundRobinOrderedAmounts = JSON.stringify(
          this.injectionForm.roundRobinOrderedAmounts.filter(
            (oa) => oa && oa.amount && Number(oa.amount) > 0
          )
        );

        const days = [true, true, true, true, true, true, true];
        injectionToSave.days = days.map((d, j) => injectionToSave.days.includes(j));
        // if (injectionToSave.m && injectionToSave.x) {
        //   injectionToSave.m = { m: injectionToSave.m, x: injectionToSave.x };
        // }
        // delete injectionToSave.x;

        if (injectionToSave.assignToAffiliate) {
          injectionToSave.assignToAffiliate = injectionToSave.assignToAffiliate._id;
        }

        const formData = new FormData();
        Object.keys(injectionToSave).forEach((key) => {
          formData.append(key, injectionToSave[key]);
        });
        saveInjection(this.token, formData)
          .then(async (r) => {
            if (r.status === 200 && !r.data.error) {
              showCard(this, `Injection saved`, 'success');
              await this.$router.push(`/injections/${r.data._id}`);
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving injection`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving injection`);
          });
        this.$store.commit('make_requesting', { req: false });
      }
    },
    async updateData() {
      await store.dispatch('get_all_resources_one_request', { vm: this });
      // if (this.$route.params.id) this.getBox(this.$route.params.id);
    },
  },
  mounted() {
    this.updateData();
    // if (this.$route.params.id) this.getBox(this.$route.params.id);
  },
  watch: {
    'injectionForm.countries': function () {
      this.countActionsForInj();
    },
    'injectionForm.boxTo': function () {
      this.injectionForm.roundRobinOrderedAmounts = [];
      this.countActionsForInj();
    },
    'injectionForm.boxFrom': function () {
      this.countActionsForInj();
    },
    'injectionForm.boxAvoid': function () {
      this.countActionsForInj();
    },
    'injectionForm.campaignsFrom': function () {
      this.countActionsForInj();
    },
    'injectionForm.campaignsAvoid': function () {
      this.countActionsForInj();
    },
    'injectionForm.affiliatesFrom': function () {
      this.countActionsForInj();
    },
    'injectionForm.affiliatesAvoid': function () {
      this.countActionsForInj();
    },
    // 'injectionForm.startSelectDateTime': function () {
    //   this.countActionsForInj();
    // },
    // 'injectionForm.endSelectDateTime': function () {
    //   this.countActionsForInj();
    // },
    'injectionForm.sendAll': function () {
      this.countActionsForInj();
    },
    'injectionForm.startSelectDate': function () {
      this.countActionsForInj();
    },
    'injectionForm.startSelectTime': function () {
      this.countActionsForInj();
    },
    'injectionForm.endSelectDate': function () {
      this.countActionsForInj();
    },
    'injectionForm.endSelectTime': function () {
      this.countActionsForInj();
    },
    'injectionForm.paranoid': function () {
      this.countActionsForInj();
    },
    'injectionForm.statusGroups': function () {
      this.countActionsForInj();
    },
    timeBetweenBatches1() {
      this.playWarning();
    },
    timeBetweenBatches2() {
      this.playWarning();
    },
    avgLeadsPerHour() {
      this.playWarning();
    },
    $route() {
      // this will be called any time the route changes
      console.log(this.$route.path);
      this.updateData();
      // if (this.$route.params.id) this.getBox(this.$route.params.id);
      // else {
      this.injectionForm = _.cloneDeep(itemInitialValues);
      this.initialForm = {};
      // }//
    },
  },
};
</script>

<style scoped></style>
