<template>
  <div>
    <LoadingSpinner :requesting="requesting" v-if="requesting" />
    <div v-else>
      <div v-if="worker">
        <h5>Worker</h5>
        <div style="display: block">
          Name
          <b>{{ worker.name }}</b>
        </div>
        <div style="display: block">
          <span>Active</span>
          <div class="datapiece mx-1 d-flex" v-if="simpleView">
            <b-badge class="databadge mx-1" :variant="worker.active ? 'success' : 'danger'">
              {{ worker.active ? 'Active' : 'Inactive' }}
            </b-badge>
            <b-badge class="databadge mx-1" :variant="worker.available ? 'success' : 'danger'">
              {{ worker.available ? 'Available' : 'Unavailable' }}
            </b-badge>
          </div>
        </div>
        <div style="display: block">
          Assigned
          <b>{{ worker.assigned }}</b>
        </div>
        <div style="display: block" v-if="worker.todayLeads">
          Today Assigned:
          <b>{{ worker.todayLeads.length }}</b>
        </div>
        <div class="border-tab my-2" style="display: block" v-if="worker.todayLeads">
          <b-button
            v-b-toggle="`collapse-worker-assign-details`"
            variant="primary"
            :style="cssVars"
            class="my-2 mx-2"
          >
            Show Assigned By Copy, Client and FinalReceiver
          </b-button>
          <b-collapse id="collapse-worker-assign-details" style="display: block">
            <ul>
              <li v-for="(r, i) in worker.todayLeads" :key="i" class="my-2">
                <div class="dataspan mx-1 my-1">{{ r._id.campaign }}</div>
                <div class="dataspan mx-1 my-1">{{ r._id.client }}</div>
                <div class="dataspan mx-1 my-1">{{ r._id.finalReceiver }}</div>
                <br />
                <div class="dataspan orangetext mx-1 my-1">In progress {{ r.leads }}</div>
                <div class="dataspan greentext mx-1 my-1">FTDs {{ r.ftds }}</div>
                <br />
                <div class="dataspan orangetext mx-1 my-1">
                  Last In Progress {{ r.lastLeadDate ? formDate(r.lastLeadDate) : '-' }}
                </div>
                <div class="dataspan greentext mx-1 my-1">
                  Last FTD {{ r.lastFtdDate ? formDate(r.lastFtdDate) : '-' }}
                </div>
              </li>
            </ul>
          </b-collapse>
        </div>
      </div>
      <h5 v-else>Worker undefined</h5>
    </div>
  </div>
</template>

<script>
import '@/assets/form.scss';
import '@/assets/breakdown-data.css';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { getWorkerByLeadId } from '@/utils/api';
import { formDateInKey, handleRequestError, formDate } from '@/utils';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

export default {
  name: 'SendContextWorkerInfo',
  components: { LoadingSpinner },
  props: { lead_id: { type: String, default: '' } },
  data() {
    return { requesting: false, worker: {} };
  },
  computed: {
    ...mapState(['token', 'user']),
    simpleView() {
      return this.user.email !== 'zerothreethree@default.gg';
    },
  },
  methods: {
    formDate,
    getWorkerByLeadId() {
      this.requesting = true;
      getWorkerByLeadId(this.token, this.lead_id)
        .then((r) => {
          this.worker = r.data ? formDateInKey(cloneDeep(r.data), 'assigned') : undefined;
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(this, e, `Error getting context worker info for lead ${this.lead_id}`);
          this.requesting = false;
        });
    },
  },
  mounted() {
    this.getWorkerByLeadId();
  },
};
</script>

<style scoped></style>
