/* eslint-disable no-unneeded-ternary */
import { oidRegex } from '@/validators/_utils';

export default {
  _id: (c) => !!(!c || oidRegex.test(c)),
  type: (c) => c === 'card' || c === 'crypto',
  number: (c, h) =>
    c && h.type === 'card'
      ? c.replace(/\D/g, '').length === 16
      : h.type === 'crypto'
      ? true // c.length > 20
      : false,
  affiliates: (c) => Array.isArray(c),
};
