<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h4 class="mx-1 my-2">{{ name }}</h4>
    <RequestParameters
      :RequestType="form.RequestType"
      :RequestDataTransferForm="form.RequestDataTransferForm"
      :EncodeData="form.EncodeData"
      :RequestHeaders="form.RequestHeaders"
      :RequestBody="form.RequestBody"
      :Auth="form.Auth"
      :noAuth="noAuth"
      @edit="
        handleMultipleKeysEditEvent($event, [
          'RequestType',
          'RequestDataTransferForm',
          'EncodeData',
          'RequestHeaders',
          'RequestBody',
          'Auth',
        ])
      "
      @validate="
        handleMultipleKeysValidateEvent($event, [
          'RequestType',
          'RequestDataTransferForm',
          'EncodeData',
          'RequestHeaders',
          'RequestBody',
          'Auth',
        ])
      "
    ></RequestParameters>
    <b-form-group
      class="my-3"
      label="Endpoint"
      label-for="sendlead-endpoint"
      :state="isValid.fields.endpoint"
    >
      <b-form-input
        type="text"
        id="sendlead-endpoint"
        v-model="form.endpoint"
        @input="emitEditEvent"
        :state="isValid.fields.endpoint"
      ></b-form-input>
    </b-form-group>
    <PathArrayForm
      :pathArray="form.ResultIdPath"
      name="ResultId Paths"
      @edit="handleEditEvent($event, 'ResultIdPath')"
      @validate="handleValidateEvent($event, 'ResultIdPath')"
      disallowEmpty
    ></PathArrayForm>
    <dataPreprocessingParameters
      :dataPreprocessing="form.dataPreprocessing"
      @edit="handleEditEvent($event, 'dataPreprocessing')"
      @validate="handleValidateEvent($event, 'dataPreprocessing')"
    ></dataPreprocessingParameters>
    <dataPreprocessingParameters
      name="Data Postprocessing"
      :dataPreprocessing="form.dataPostprocessing"
      @edit="handleEditEvent($event, 'dataPostprocessing')"
      @validate="handleValidateEvent($event, 'dataPostprocessing')"
    ></dataPreprocessingParameters>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep } from 'lodash';
import PathArrayForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PathArrayForm.vue';
import handleEditEvent from '@/utils/handleEditEvent';
import dataPreprocessingParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/dataPreprocessingParameters.vue';
import RequestParameters from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/RequestParameters.vue';
import handleMultipleKeysEditEvent from '@/utils/handleMultipleKeysEditEvent';
import handleValidateEvent from '@/utils/handleValidateEvent';
import handleMultipleKeysValidateEvent from '@/utils/handleMultipleKeysValidateEvent';
import { formValid, isValid } from '@/validators';
import { mapPropFieldToValid } from '@/utils';

const initial = {
  RequestType: '',
  RequestDataTransferForm: '',
  EncodeData: true,
  // RequestHeaders: {},
  Auth: { method: 'direct' },
  endpoint: '',
  // ResultIdPath: [],
  // dataPreprocessing: {},
  // dataPostprocessing: {},
};
export default {
  name: 'SendLeadParameters',
  components: { RequestParameters, dataPreprocessingParameters, PathArrayForm },
  props: {
    name: { type: String, default: 'SendLead Parameters' },
    noAuth: { type: Boolean, default: false },
    parameters: {
      RequestType: { type: String, default: '' },
      RequestDataTransferForm: { type: String, default: '' },
      EncodeData: { type: Boolean, default: true },
      RequestHeaders: {
        type: Object,
        default: () => {
          return {};
        },
      },
      RequestBody: {
        type: Object,
        default: () => {
          return {};
        },
      },
      Auth: {
        type: Object,
        default: () => {
          return { method: 'direct' }; // TODO Auth with separateRequest
        },
      },
      endpoint: { type: String, default: '' },
      ResultIdPath: { type: Array, default: () => [] },
      dataPreprocessing: {
        type: Object,
        default: () => {
          return {};
        },
      },
      dataPostprocessing: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  },
  data() {
    return {
      form: cloneDeep(this.$props.parameters ? this.$props.parameters : initial),
      valid: cloneDeep(mapPropFieldToValid(this.$props.parameters, initial)),
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      // console.log('SendLeadParameters backdoor', this.backdoor, this.valid);
      const fields = {
        ...this.valid,
        ...isValid('integration_SendLeadParameters', this.form, this.form),
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      // console.log('SendLeadParameters validated', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    handleMultipleKeysEditEvent,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    handleValidateEvent,
    handleMultipleKeysValidateEvent,
    // handleMultipleKeysValidateEvent(data, keys, formKey) {
    //   handleMultipleKeysValidateEvent(this, data, keys, formKey);
    // },
    emitValidateEvent() {
      // console.log(`this.$emit('validate', ${this.isValid.form});`);
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
    // console.log('SendLeadParameters mounted');
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
