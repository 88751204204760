/* eslint-disable import/prefer-default-export */
import { USER_TOKEN } from '@/consts';
import { showCard } from '@/utils';

// export function makeRequesting(commit, state, index, req) {
//   console.log(index);
//   console.log(state.requesting_resources.actions);
//   commit('make_requesting_resources', { res: index, req });
//   const requestingStatuses = Object.values(state.requesting_resources);
//   if (req && !state.requesting && requestingStatuses.some((r) => r === true)) {
//     console.log(req, requestingStatuses);
//     commit('make_requesting', { req: true });
//   }
//   if (!req && state.requesting && requestingStatuses.every((r) => r === false)) {
//     console.log(req, requestingStatuses);
//     commit('make_requesting', { req: false });
//   }
// }

export async function getItems(commit, state, vm, index, promise) {
  // makeRequesting(commit, state, index, true);
  if (!state.token) {
    state.token = localStorage.getItem(USER_TOKEN);
  }
  try {
    const r = await promise(state.token);
    console.log(`Got ${index}, `);
    console.log(r);
    if (r.status === 200 && !r.data.error) {
      const items = r.data;
      commit('put_items', { index, items });
    } else {
      commit('put_items', { index, items: [] });
      console.error(`Error getting ${index}: `, r.status, r.data);

      const cardError = r.data.error || `${r.status}: Unknown error on getting ${index}`;
      showCard(vm, cardError, r.status === 500 ? 'danger' : 'warning');
      // commit('logout');
    }
  } catch (e) {
    commit('put_items', { index, items: [] });
    console.error(`Error getting ${index} in store: `, e);

    const cardError = e || { message: `Unknown server error on getting ${index}` };
    showCard(vm, cardError.message, 'danger', `Error getting ${index} in store`);
    // commit('logout');
    throw e;
  }
  // makeRequesting(commit, state, index, false);
}
