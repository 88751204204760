import { oidRegex, nameTest } from '@/validators/_utils';
import { DateTime } from 'luxon';
import { datepickerFormat } from '@/consts';

function startDate(c, h, dateKey = 'Date', timeKey = 'Time') {
  // console.log('validate start date', c, h[`start${timeKey}`]);
  const startDateTime = DateTime.fromFormat(
    `${String(h[`start${dateKey}`])} ${String(h[`start${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  const endDateTime = DateTime.fromFormat(
    `${String(h[`end${dateKey}`])} ${String(h[`end${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  return (
    (((!c || c === '') && (!h[`start${timeKey}`] || h[`start${timeKey}`] === '')) ||
      (!!c && DateTime.fromFormat(c, datepickerFormat).isValid)) &&
    (startDateTime.isValid ? startDateTime : -Infinity) <
      (endDateTime.isValid ? endDateTime : Infinity)
  );
}

function endDate(c, h, dateKey = 'Date', timeKey = 'Time') {
  // console.log('validate end date', c, h[`end${timeKey}`]);
  const startDateTime = DateTime.fromFormat(
    `${String(h[`start${dateKey}`])} ${String(h[`start${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  const endDateTime = DateTime.fromFormat(
    `${String(h[`end${dateKey}`])} ${String(h[`end${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  return (
    (((!c || c === '') && (!h[`end${timeKey}`] || h[`end${timeKey}`] === '')) ||
      (!!c && DateTime.fromFormat(c, datepickerFormat).isValid)) &&
    (startDateTime.isValid ? startDateTime : -Infinity) <
      (endDateTime.isValid ? endDateTime : Infinity)
  );
}

function startTime(c, h, dateKey = 'Date', timeKey = 'Time') {
  // console.log('validate start time', c, h[`start${dateKey}`]);
  const startDateTime = DateTime.fromFormat(
    `${String(h[`start${dateKey}`])} ${String(h[`start${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  const endDateTime = DateTime.fromFormat(
    `${String(h[`end${dateKey}`])} ${String(h[`end${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  return (
    (((!c || c === '') && (!h[`start${dateKey}`] || h[`start${dateKey}`] === '')) ||
      (!!c && DateTime.fromFormat(`2020-01-01 ${c}`, 'yyyy-L-d TT').isValid)) &&
    (startDateTime.isValid ? startDateTime : -Infinity) <
      (endDateTime.isValid ? endDateTime : Infinity)
  );
}

function endTime(c, h, dateKey = 'Date', timeKey = 'Time') {
  // console.log('validate end time', c, h[`end${dateKey}`]);
  const startDateTime = DateTime.fromFormat(
    `${String(h[`start${dateKey}`])} ${String(h[`start${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  const endDateTime = DateTime.fromFormat(
    `${String(h[`end${dateKey}`])} ${String(h[`end${timeKey}`])}`,
    'yyyy-L-d TT'
  );
  return (
    (((!c || c === '') && (!h[`end${dateKey}`] || h[`end${dateKey}`] === '')) ||
      (!!c && DateTime.fromFormat(`2020-01-01 ${c}`, 'yyyy-L-d TT').isValid)) &&
    (startDateTime.isValid ? startDateTime : -Infinity) <
      (endDateTime.isValid ? endDateTime : Infinity)
  );
}

function validateRefArray(c) {
  return !c || (Array.isArray(c) && c.every((ce) => ce._id && oidRegex.test(ce._id)));
}

export default {
  name: (c) => nameTest(c),
  boxFrom: (c) => validateRefArray(c),
  boxAvoid: (c) => validateRefArray(c),
  campaignsFrom: (c) => validateRefArray(c),
  campaignsAvoid: (c) => validateRefArray(c),
  affiliatesFrom: (c) => validateRefArray(c),
  affiliatesAvoid: (c) => validateRefArray(c),
  boxTo: (c) => !!c && ((c._id && oidRegex.test(c._id)) || oidRegex.test(c)),
  manager: (c) => !c || oidRegex.test(c),
  leadsToSend: (c) => !c || c instanceof File,
  amountLeadsToSend: (c) => !c || c === '' || (!Number.isNaN(Number(c)) && Number(c) > 0),
  orderToSelect: (c) => !c || c === '' || (!Number.isNaN(Number(c)) && Number(c) > 0),
  so: (c) => !c || nameTest(c),
  batchSize: (c, h) =>
    !Number.isNaN(Number(c)) && ((!h.m && Number(c) > 0) || (h.m && Number(c) === 1)),
  timeBetweenBatches1: (c) => !Number.isNaN(Number(c)) && Number(c) > 0,
  timeBetweenBatches2: (c) => !Number.isNaN(Number(c)) && Number(c) > 0,
  startDate: (c, h) => startDate(c, h),
  endDate: (c, h) => endDate(c, h),
  startTime: (c, h) => startTime(c, h),
  endTime: (c, h) => endTime(c, h),
  startSelectDate: (c, h) => startDate(c, h, 'SelectDate', 'SelectTime'),
  endSelectDate: (c, h) => endDate(c, h, 'SelectDate', 'SelectTime'),
  startSelectTime: (c, h) => startTime(c, h, 'SelectDate', 'SelectTime'),
  endSelectTime: (c, h) => endTime(c, h, 'SelectDate', 'SelectTime'),
  m: (c, h) => {
    if (c && (h.boxFrom.length !== 1 || h.boxFrom.some((bf) => bf._id === h.boxTo))) return false;
    const hx = Number(h.x);
    return (
      !c ||
      (typeof c === 'boolean' &&
        ((c && !Number.isNaN(hx) && hx >= 5) || (!c && (hx === 0 || Number.isNaN(hx)))))
    );
  },
  x: (c, h) => {
    if (c && (h.boxFrom.length !== 1 || h.boxFrom.some((bf) => bf._id === h.boxTo))) return false;
    const x = Number(c);
    return (
      (h.m === true && !Number.isNaN(x) && x >= 5) || (!h.m && (!c || x === 0 || Number.isNaN(x)))
    );
  },
  makeSalat: (c, h) => {
    if (c && (h.boxFrom.length !== 1 || h.boxFrom.some((bf) => bf._id === h.boxTo))) return false;
    const hx = Number(h.x);
    const hc = h.c;
    return !c || (typeof c === 'boolean' && hc && !Number.isNaN(hx) && hx >= 5);
  },
  countAllActions: (c, h) => {
    if (c && (h.boxFrom.length !== 1 || h.boxFrom.some((bf) => bf._id === h.boxTo))) return false;
    const hx = Number(h.x);
    const hc = h.c;
    return !c || (typeof c === 'boolean' && hc && !Number.isNaN(hx) && hx >= 5);
  },
  assignToAffiliate: (c) => !c || (c._id && oidRegex.test(c._id)),
  // eslint-disable-next-line no-unused-vars
  countries: (c, h) => Array.isArray(c) && (!h.automated || c.length === 1),
  // eslint-disable-next-line no-unused-vars
  automated: (c, h) => !c || h.countries.length === 1,
  considerRMRatio: (c, h) => !c || h.countries.length === 1,
};

// don't use, too many small differences
// function validateStartEndDates(c, s, vc, sd, ed) {
//   return (
//     (((!c || c === '') && (!s || s === '')) || (!!c && vc.isValid)) &&
//     (sd.isValid ? sd : -Infinity) < (ed.isValid ? ed : Infinity)
//   );
// }
