import { render, staticRenderFns } from "./DashboardCharts.vue?vue&type=template&id=d9a792b4&scoped=true&"
import script from "./DashboardCharts.vue?vue&type=script&lang=js&"
export * from "./DashboardCharts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9a792b4",
  null
  
)

export default component.exports