import { DateTime } from 'luxon';

export const USER_TOKEN = 'usertoken';

export const RENT_ONLY_EMAILS = [
  // 'executive@default.gg',
  // 'executive2@default.gg',
  'inaff@mm.gg',
  'mma@gmail.com',
];

export const PUBLIC_ROUTES = ['/login']; // , '/create-box', '/create-campaign' '/system-commands'

export const AFFILIATE_ROUTES = [
  ...PUBLIC_ROUTES,
  '/actions',
  '/crm',
  '/deposits',
  '/prepared-to-push',
  '/geo-selection',
  '/psp',
  '/workers',
  '/dashboard',
  '/affiliate-api-docs',
];
export const MASTERAFFILIATE_ROUTES = [...AFFILIATE_ROUTES, '/users'];
export const MANAGER_ROUTES = [
  ...AFFILIATE_ROUTES,
  // '/users',
  '/campaigns',
  '/boxes',
  '/test-lead',
  '/create-campaign',
  '/create-box',
  // '/create-user',
  '/campaign/edit',
  '/box/edit/',
  // '/user/edit/',
  '/test-lead',
  '/test-pulling',
];
export const ADMIN_ROUTES = [
  ...AFFILIATE_ROUTES,
  '/users',
  '/campaigns',
  '/boxes',
  '/test-lead',
  '/create-campaign',
  '/create-box',
  '/create-user',
  '/campaign/edit',
  '/box/edit/',
  '/user/edit/',
  '/test-lead',
  '/test-pulling',
  '/injections',
  '/create-injection',
  '/upload-leads-file',
  '/work-history',
  '/bce-preset',
];

export const TRUSTED_ADMIN_ROUTES = [
  ...ADMIN_ROUTES,
  '/daughters',
  '/metacampaigns',
  '/finalreceivers',
  '/financial-transactions',
  '/balances',
  '/test-gm-level-distribution',
  '/injections-send-results',
];

export const RENT_DISALLOWED_ROUTES = [
  '/finalreceivers',
  '/financial-transactions',
  '/daughters',
  '/metacampaigns',
  '/balances',
  '/test-gm-level-distribution',
];

export const actionTypeValues = ['impression', 'click', 'lead', 'ftd'];

export const TRUSTED_USERS = [
  'blacklight@default.gg',
  'zerothreethree@default.gg',
  'thehighestadmin@defaulted.gg',
  'thehighestadmin@default.gg',
];
export const OTHER_LEVEL_TRUSTED_USERS = [
  'blacklight@default.gg',
  'zerothreethree@default.gg',
  'thehighestadmin@defaulted.gg',
  'thehighestadmin@default.gg',
];

// export const countryOptions = ['RU', 'KZ', 'UA', 'UZ'];
export const countryOptions = [
  { value: 'AF', text: 'Afghanistan' },
  { value: 'AX', text: 'Aland Islands' },
  { value: 'AL', text: 'Albania' },
  { value: 'DZ', text: 'Algeria' },
  { value: 'AS', text: 'American Samoa' },
  { value: 'AD', text: 'Andorra' },
  { value: 'AO', text: 'Angola' },
  { value: 'AI', text: 'Anguilla' },
  { value: 'AQ', text: 'Antarctica' },
  { value: 'AG', text: 'Antigua And Barbuda' },
  { value: 'AR', text: 'Argentina' },
  { value: 'AM', text: 'Armenia' },
  { value: 'AW', text: 'Aruba' },
  { value: 'AU', text: 'Australia' },
  { value: 'AT', text: 'Austria' },
  { value: 'AZ', text: 'Azerbaijan' },
  { value: 'BS', text: 'Bahamas' },
  { value: 'BH', text: 'Bahrain' },
  { value: 'BD', text: 'Bangladesh' },
  { value: 'BB', text: 'Barbados' },
  { value: 'BY', text: 'Belarus' },
  { value: 'BE', text: 'Belgium' },
  { value: 'BZ', text: 'Belize' },
  { value: 'BJ', text: 'Benin' },
  { value: 'BM', text: 'Bermuda' },
  { value: 'BT', text: 'Bhutan' },
  { value: 'BO', text: 'Bolivia' },
  { value: 'BA', text: 'Bosnia And Herzegovina' },
  { value: 'BW', text: 'Botswana' },
  { value: 'BV', text: 'Bouvet Island' },
  { value: 'BR', text: 'Brazil' },
  { value: 'IO', text: 'British Indian Ocean Territory' },
  { value: 'BN', text: 'Brunei Darussalam' },
  { value: 'BG', text: 'Bulgaria' },
  { value: 'BF', text: 'Burkina Faso' },
  { value: 'BI', text: 'Burundi' },
  { value: 'KH', text: 'Cambodia' },
  { value: 'CM', text: 'Cameroon' },
  { value: 'CA', text: 'Canada' },
  { value: 'CV', text: 'Cape Verde' },
  { value: 'KY', text: 'Cayman Islands' },
  { value: 'CF', text: 'Central African Republic' },
  { value: 'TD', text: 'Chad' },
  { value: 'CL', text: 'Chile' },
  { value: 'CN', text: 'China' },
  { value: 'CX', text: 'Christmas Island' },
  { value: 'CC', text: 'Cocos (Keeling) Islands' },
  { value: 'CO', text: 'Colombia' },
  { value: 'KM', text: 'Comoros' },
  { value: 'CG', text: 'Congo' },
  { value: 'CD', text: 'Congo, Democratic Republic' },
  { value: 'CK', text: 'Cook Islands' },
  { value: 'CR', text: 'Costa Rica' },
  { value: 'CI', text: "Cote D'Ivoire" },
  { value: 'HR', text: 'Croatia' },
  { value: 'CU', text: 'Cuba' },
  { value: 'CY', text: 'Cyprus' },
  { value: 'CZ', text: 'Czech Republic' },
  { value: 'DK', text: 'Denmark' },
  { value: 'DJ', text: 'Djibouti' },
  { value: 'DM', text: 'Dominica' },
  { value: 'DO', text: 'Dominican Republic' },
  { value: 'EC', text: 'Ecuador' },
  { value: 'EG', text: 'Egypt' },
  { value: 'SV', text: 'El Salvador' },
  { value: 'GQ', text: 'Equatorial Guinea' },
  { value: 'ER', text: 'Eritrea' },
  { value: 'EE', text: 'Estonia' },
  { value: 'ET', text: 'Ethiopia' },
  { value: 'FK', text: 'Falkland Islands (Malvinas)' },
  { value: 'FO', text: 'Faroe Islands' },
  { value: 'FJ', text: 'Fiji' },
  { value: 'FI', text: 'Finland' },
  { value: 'FR', text: 'France' },
  { value: 'GF', text: 'French Guiana' },
  { value: 'PF', text: 'French Polynesia' },
  { value: 'TF', text: 'French Southern Territories' },
  { value: 'GA', text: 'Gabon' },
  { value: 'GM', text: 'Gambia' },
  { value: 'GE', text: 'Georgia' },
  { value: 'DE', text: 'Germany' },
  { value: 'GH', text: 'Ghana' },
  { value: 'GI', text: 'Gibraltar' },
  { value: 'GR', text: 'Greece' },
  { value: 'GL', text: 'Greenland' },
  { value: 'GD', text: 'Grenada' },
  { value: 'GP', text: 'Guadeloupe' },
  { value: 'GU', text: 'Guam' },
  { value: 'GT', text: 'Guatemala' },
  { value: 'GG', text: 'Guernsey' },
  { value: 'GN', text: 'Guinea' },
  { value: 'GW', text: 'Guinea-Bissau' },
  { value: 'GY', text: 'Guyana' },
  { value: 'HT', text: 'Haiti' },
  { value: 'HM', text: 'Heard Island & Mcdonald Islands' },
  { value: 'VA', text: 'Holy See (Vatican City State)' },
  { value: 'HN', text: 'Honduras' },
  { value: 'HK', text: 'Hong Kong' },
  { value: 'HU', text: 'Hungary' },
  { value: 'IS', text: 'Iceland' },
  { value: 'IN', text: 'India' },
  { value: 'ID', text: 'Indonesia' },
  { value: 'IR', text: 'Iran, Islamic Republic Of' },
  { value: 'IQ', text: 'Iraq' },
  { value: 'IE', text: 'Ireland' },
  { value: 'IM', text: 'Isle Of Man' },
  { value: 'IL', text: 'Israel' },
  { value: 'IT', text: 'Italy' },
  { value: 'JM', text: 'Jamaica' },
  { value: 'JP', text: 'Japan' },
  { value: 'JE', text: 'Jersey' },
  { value: 'JO', text: 'Jordan' },
  { value: 'KZ', text: 'Kazakhstan' },
  { value: 'KE', text: 'Kenya' },
  { value: 'KI', text: 'Kiribati' },
  { value: 'KR', text: 'Korea' },
  { value: 'KW', text: 'Kuwait' },
  { value: 'KG', text: 'Kyrgyzstan' },
  { value: 'LA', text: "Lao People's Democratic Republic" },
  { value: 'LV', text: 'Latvia' },
  { value: 'LB', text: 'Lebanon' },
  { value: 'LS', text: 'Lesotho' },
  { value: 'LR', text: 'Liberia' },
  { value: 'LY', text: 'Libyan Arab Jamahiriya' },
  { value: 'LI', text: 'Liechtenstein' },
  { value: 'LT', text: 'Lithuania' },
  { value: 'LU', text: 'Luxembourg' },
  { value: 'MO', text: 'Macao' },
  { value: 'MK', text: 'Macedonia' },
  { value: 'MG', text: 'Madagascar' },
  { value: 'MW', text: 'Malawi' },
  { value: 'MY', text: 'Malaysia' },
  { value: 'MV', text: 'Maldives' },
  { value: 'ML', text: 'Mali' },
  { value: 'MT', text: 'Malta' },
  { value: 'MH', text: 'Marshall Islands' },
  { value: 'MQ', text: 'Martinique' },
  { value: 'MR', text: 'Mauritania' },
  { value: 'MU', text: 'Mauritius' },
  { value: 'YT', text: 'Mayotte' },
  { value: 'MX', text: 'Mexico' },
  { value: 'FM', text: 'Micronesia, Federated States Of' },
  { value: 'MD', text: 'Moldova' },
  { value: 'MC', text: 'Monaco' },
  { value: 'MN', text: 'Mongolia' },
  { value: 'ME', text: 'Montenegro' },
  { value: 'MS', text: 'Montserrat' },
  { value: 'MA', text: 'Morocco' },
  { value: 'MZ', text: 'Mozambique' },
  { value: 'MM', text: 'Myanmar' },
  { value: 'NA', text: 'Namibia' },
  { value: 'NR', text: 'Nauru' },
  { value: 'NP', text: 'Nepal' },
  { value: 'NL', text: 'Netherlands' },
  { value: 'AN', text: 'Netherlands Antilles' },
  { value: 'NC', text: 'New Caledonia' },
  { value: 'NZ', text: 'New Zealand' },
  { value: 'NI', text: 'Nicaragua' },
  { value: 'NE', text: 'Niger' },
  { value: 'NG', text: 'Nigeria' },
  { value: 'NU', text: 'Niue' },
  { value: 'NF', text: 'Norfolk Island' },
  { value: 'MP', text: 'Northern Mariana Islands' },
  { value: 'NO', text: 'Norway' },
  { value: 'OM', text: 'Oman' },
  { value: 'PK', text: 'Pakistan' },
  { value: 'PW', text: 'Palau' },
  { value: 'PS', text: 'Palestinian Territory, Occupied' },
  { value: 'PA', text: 'Panama' },
  { value: 'PG', text: 'Papua New Guinea' },
  { value: 'PY', text: 'Paraguay' },
  { value: 'PE', text: 'Peru' },
  { value: 'PH', text: 'Philippines' },
  { value: 'PN', text: 'Pitcairn' },
  { value: 'PL', text: 'Poland' },
  { value: 'PT', text: 'Portugal' },
  { value: 'PR', text: 'Puerto Rico' },
  { value: 'QA', text: 'Qatar' },
  { value: 'RE', text: 'Reunion' },
  { value: 'RO', text: 'Romania' },
  { value: 'RU', text: 'Russian Federation' },
  { value: 'RW', text: 'Rwanda' },
  { value: 'BL', text: 'Saint Barthelemy' },
  { value: 'SH', text: 'Saint Helena' },
  { value: 'KN', text: 'Saint Kitts And Nevis' },
  { value: 'LC', text: 'Saint Lucia' },
  { value: 'MF', text: 'Saint Martin' },
  { value: 'PM', text: 'Saint Pierre And Miquelon' },
  { value: 'VC', text: 'Saint Vincent And Grenadines' },
  { value: 'WS', text: 'Samoa' },
  { value: 'SM', text: 'San Marino' },
  { value: 'ST', text: 'Sao Tome And Principe' },
  { value: 'SA', text: 'Saudi Arabia' },
  { value: 'SN', text: 'Senegal' },
  { value: 'RS', text: 'Serbia' },
  { value: 'SC', text: 'Seychelles' },
  { value: 'SL', text: 'Sierra Leone' },
  { value: 'SG', text: 'Singapore' },
  { value: 'SK', text: 'Slovakia' },
  { value: 'SI', text: 'Slovenia' },
  { value: 'SB', text: 'Solomon Islands' },
  { value: 'SO', text: 'Somalia' },
  { value: 'ZA', text: 'South Africa' },
  { value: 'GS', text: 'South Georgia And Sandwich Isl.' },
  { value: 'ES', text: 'Spain' },
  { value: 'LK', text: 'Sri Lanka' },
  { value: 'SD', text: 'Sudan' },
  { value: 'SR', text: 'Suriname' },
  { value: 'SJ', text: 'Svalbard And Jan Mayen' },
  { value: 'SZ', text: 'Swaziland' },
  { value: 'SE', text: 'Sweden' },
  { value: 'CH', text: 'Switzerland' },
  { value: 'SY', text: 'Syrian Arab Republic' },
  { value: 'TW', text: 'Taiwan' },
  { value: 'TJ', text: 'Tajikistan' },
  { value: 'TZ', text: 'Tanzania' },
  { value: 'TH', text: 'Thailand' },
  { value: 'TL', text: 'Timor-Leste' },
  { value: 'TG', text: 'Togo' },
  { value: 'TK', text: 'Tokelau' },
  { value: 'TO', text: 'Tonga' },
  { value: 'TT', text: 'Trinidad And Tobago' },
  { value: 'TN', text: 'Tunisia' },
  { value: 'TR', text: 'Turkey' },
  { value: 'TM', text: 'Turkmenistan' },
  { value: 'TC', text: 'Turks And Caicos Islands' },
  { value: 'TV', text: 'Tuvalu' },
  { value: 'UG', text: 'Uganda' },
  { value: 'UA', text: 'Ukraine' },
  { value: 'AE', text: 'United Arab Emirates' },
  { value: 'GB', text: 'United Kingdom' },
  { value: 'US', text: 'United States' },
  { value: 'UM', text: 'United States Outlying Islands' },
  { value: 'UY', text: 'Uruguay' },
  { value: 'UZ', text: 'Uzbekistan' },
  { value: 'VU', text: 'Vanuatu' },
  { value: 'VE', text: 'Venezuela' },
  { value: 'VN', text: 'Viet Nam' },
  { value: 'VG', text: 'Virgin Islands, British' },
  { value: 'VI', text: 'Virgin Islands, U.S.' },
  { value: 'WF', text: 'Wallis And Futuna' },
  { value: 'EH', text: 'Western Sahara' },
  { value: 'YE', text: 'Yemen' },
  { value: 'ZM', text: 'Zambia' },
  { value: 'ZW', text: 'Zimbabwe' },
];

export const countryCodes = countryOptions.map((co) => co.value);

export const actionTypeOptions = [
  { text: 'Impressions', value: 'impression' },
  { text: 'Clicks', value: 'click' },
  { text: 'Leads', value: 'lead' },
  { text: 'FTDs', value: 'ftd' },
];

export const statsActionTypeOptions = [
  { text: 'Impressions', value: 'impression' },
  { text: 'Clicks', value: 'click' },
  { text: 'Leads', value: 'lead' },
  { text: 'FTDs', value: 'ftd' },
];

export const pspTypeOptions = [
  { text: 'All', value: 'all' },
  { text: 'Cards', value: 'card' },
  { text: 'Crypto', value: 'crypto' },
];

export const pspTypeClosedOptions = [
  { text: 'Card', value: 'card' },
  { text: 'Crypto', value: 'crypto' },
];

export const financialTransactionTypeOptions = [
  { text: 'All', value: 'all' },
  { text: 'Postpay', value: 'postpay' },
  { text: 'Prepay', value: 'prepay' },
  { text: 'Refund', value: 'refund' },
  { text: 'Adjustment', value: 'adjustment' },
];
export const financialTransactionTypeClosedOptions = [
  { text: 'Postpay', value: 'postpay' },
  { text: 'Prepay', value: 'prepay' },
  { text: 'Refund', value: 'refund' },
  { text: 'Adjustment', value: 'adjustment' },
];

export const activityStatusOptions = [
  { text: 'Active', value: 'active' },
  { text: 'Idle', value: 'inactive' },
];

export const pullingStatusOptions = [
  { text: 'Yes', value: 'yes' },
  { text: 'No', value: 'no' },
];

export const daysOfWeek = [
  { name: 'Sunday', item: 0 },
  { name: 'Monday', item: 1 },
  { name: 'Tuesday', item: 2 },
  { name: 'Wednesday', item: 3 },
  { name: 'Thursday', item: 4 },
  { name: 'Friday', item: 5 },
  { name: 'Saturday', item: 6 },
];

export const brokerApiTypes = [
  'MT4',
  'Trackbox',
  'CRM-APIV2',
  'Skaleapps',
  'Getlinked',
  'GetlinkedV2',
  'CmaxCRM',
  'DrMailer',
  'Daughter',
  'Mother',
  'RoiBees',
  'Yoloads',
  'Platform500',
  'FICRM',
  'EDEAL',
  'XGlobal',
  'BlackDiamondSwiss',
  'Profitmarketgsl',
  'Telexsale',
  'EpcClub',
  'Excentral',
  'FALCON',
  'Axela',
  'Ultracrms',
  'OVO',
  'Affcountry',
  'Affclub',
  'IndexEvolve',
  'HugeOffers',
  'Afftech',
  'Oxibanco',
  'Intraders',
  'SharkSoft',
  'Megasource',
  'UniversalIntegration',
  'TECH_CAMPAIGN',
];

export * from './defaultIntegrationSettings';

// export const defaultApiMapping = {
//   email: 'email',
//   password: 'password',
//   firstname: 'firstname',
//   lastname: 'lastname',
//   phone: 'phone',
//   language: 'language',
//   country: 'country',
//   ip: 'ip',
//   geoip_country: 'geoip_country',
//   so: 'so',
// };

// export const defaultTrackboxApiMapping = {
//   email: 'email',
//   password: 'password',
//   firstname: 'firstname',
//   lastname: 'lastname',
//   phone: 'phone',
//   language: 'language',
//   country: 'country',
//   ip: 'userip',
//   geoip_country: 'geoip_country',
//   so: 'so',
// };

// export const defaultCrmV2ApiMapping = {
//   email: 'email',
//   password: 'password',
//   firstname: 'first_name',
//   lastname: 'last_name',
//   phone: 'phone',
//   language: 'language',
//   country: 'country',
//   ip: 'ip',
//   geoip_country: 'geoip_country',
//   so: 'source',
// };

// export const defaultSkaleappApiMapping = {
//   email: 'email',
//   password: 'password',
//   firstname: 'first_name',
//   lastname: 'last_name',
//   phone: 'phone',
//   language: 'language',
//   country: 'country',
//   ip: 'ip',
//   geoip_country: 'geoip_country',
//   so: 'source',
// };

export const userTypeValues = ['affiliate', 'masteraffiliate', 'manager', 'admin'];

export const userTypeOptions = [
  { text: 'Affiliate', value: 'affiliate' },
  { text: 'Manager', value: 'manager' },
  { text: 'Admin', value: 'admin' }, // , disabled: true
  { text: 'Masteraffiliate', value: 'masteraffiliate' },
];

export const validFeedback = 'Perfect!';

export const invalidFeedback = {
  financialTransaction: {
    type: `Type can be only one of the following: ${financialTransactionTypeClosedOptions
      .map((o) => o.text)
      .join(', ')}`,
    amount: 'Amount must be greater than 0',
    date: 'Invalid date',
    comment: undefined,
  },
  psp: {
    type: '',
    number: '',
    affiliates: '',
  },
  worker: {
    name: "Worker's Name must be more 4 or more symbols long",
    affiliates: '',
  },
  campaign: {
    name: 'Please enter 3+ symbols',
    broker: 'Please enter 3+ symbols',
    countries: 'Please select at least 1 country',
    brokerApiType: 'Bad API type',
    brokerApiURL: 'Please enter valid URL starting with http...',
    brokerApiMapping: {
      email: 'bad email mapping',
      password: 'bad password mapping',
      firstname: 'bad firstname mapping',
      lastname: 'bad lastname mapping',
      phone: 'bad phone mapping',
      language: 'bad language mapping',
      country: 'bad country mapping',
      ip: 'bad ip mapping',
      geoip_country: 'bad geoip_country mapping',
      so: 'bad so mapping',
    },
    managers: "Please select campaign's managers",
    defaultPayment: 'Default payment must be number >= 0',
    aff_defaultPayment: 'Default payment must be number >= 0',
    crg: 'CRG flag can only be true or false',
    crgRate: 'CRG rate can only be number between 0 and 100 %',
    brokerDetails: {
      brokerName: 'Broker Name can not be empty',
      skypeName: 'Broker Skype Name can not be empty',
      skypeUserName: 'Broker Skype User Name can not be empty',
      rating: '',
      campaignComment: '',
    },
  },
  countries: 'Please select geos',
  dailyCap: {
    type: 'Bad cap type',
    amount: 'Enter amount > 0 or leave it blank for infinite cap',
  },
  totalCap: {
    type: 'Bad cap type',
    amount: 'Enter amount > 0 or leave it blank for infinite cap',
  },
  affiliate: 'Please select affiliate',
  manager: 'Please select manager',
  user: {
    name: 'Please enter 3+ symbols',
    email: 'Invalid email',
    messenger: { skype: 'Invalid Skype name', telegram: 'Invalid Telegram contact' },
    type: 'Bad user type',
    campaigns: 'Please select campaigns',
    boxes: 'Please select boxes',
    affiliates: 'Please select affiliates',
  },
  box: {
    campaigns: {
      campaign: 'Please select campaign',
      days: 'Bad days selection',
      priority: 'Priority should be integer greater than 0',
      weight: 'Weight should be integer greater than 0',
      defaultPayment: "BoxCampaign's Default payment must be empty or >= 0",
      aff_defaultPayment: "BoxCampaign's Default payment must be empty or >= 0",
      limitMPerBCE: "BoxCampaign's limit M must be empty or >= 0",
    },
    loadBalancerCoefficient: 'Load Balancer Coefficient must be number > 0',
  },
  injection: {
    name: 'Please enter 3+ symbols',
    boxFrom: 'From Boxes must be empty or selected',
    boxAvoid: 'Avoid Boxes must be empty or selected',
    campaignsFrom: 'From Campaigns must be empty or selected',
    campaignsAvoid: 'Avoid Campaigns must be empty or selected',
    affiliatesFrom: 'From Affiliates must be empty or selected',
    affiliatesAvoid: 'Avoid Affiliates must be empty or selected',
    boxTo: 'Select Box',
    batchSize: "Leads batch can't be smaller than 1 lead",
    leadsToSend: 'Invalid leads file',
    m: 'Can be set only if BoxFrom is single. If set, both M and X are required. X must be >= 5',
    x: 'Can be set only if BoxFrom is single. If set, both M and X are required. X must be >= 5',
    makeSalat: 'Can`t make salat with invalid M or X',
    countAllActions: 'Can`t count all actions with invalid M or X',
    automated: 'Automated Injection must have exactly 1 Country selected',
  },
  test: {
    box: 'Select box to test',
    b:
      "TestBox not found. Edit the box you want to use as TestBox and set it's 'Is Test' flag to true",
    c: 'Select Campaign to test',
    firstname: 'First name must be valid name',
    lastname: 'Last name must be valid name',
    password:
      'Password must be 8+ symbols long and contain Lowercase, Uppercase, Digit and Special symbol',
    country: 'Country must be a valid 2-letters Country Code',
    email: 'Email must be a valid email',
    so: 'Must be valid source name',
  },
  sendlead: {
    box: 'Select box to test',
    b:
      "TestBox not found. Edit the box you want to use as TestBox and set it's 'Is Test' flag to true",
    c: 'Select Campaign to test',
    firstname: 'First name must be valid name',
    lastname: 'Last name must be valid name',
    password:
      'Password must be 8+ symbols long and contain Lowercase, Uppercase, Digit and Special symbol',
    country: 'Country must be a valid 2-letters Country Code',
    email: 'Email must be a valid email',
    so: 'Must be valid source name',
  },
};
//
export const initialBoxForm = {
  _id: undefined,
  active: true,
  name: undefined,
  campaigns: [], // [campaignInitialValue],
  affiliate: undefined,
  manager: undefined,
  dailyCap: { type: undefined, amount: undefined },
  totalCap: { type: undefined, amount: undefined },
  csi: undefined,
  cso: undefined,
  dso: undefined,
  isTest: false,
  loadBalancer: false,
  loadBalancerCoefficient: 1000,
  isMBox: false,
  liveOmitTimeIntervalCheck: false,
  defaultCampaignsBox: false,
  followBox: undefined,
  boxFollowers: [],
};

export const datepickerFormat = 'yyyy-L-d';

export const positiveSalesStatus = [
  new RegExp('^call[_ ]?back', 'i'),
  new RegExp('^potential', 'i'),
  new RegExp('^follow[_ ]?up', 'i'),
  new RegExp('^r[_ ]?potential[_ ]?low$', 'i'),
  new RegExp('^low[_ ]?potential$', 'i'),
  new RegExp('^high[_ ]?r[_ ]?potential$', 'i'),
  new RegExp('^high[_ ]?potential$', 'i'),
  new RegExp('^depositor$', 'i'),
  new RegExp('^call[_ ]?again$', 'i'),
  new RegExp('^interested$', 'i'),
  new RegExp('^potential[_ ]?low$', 'i'),
  new RegExp('^potential[_ ]?high$', 'i'),
  new RegExp('^hot$', 'i'),
  new RegExp('^meeting$', 'i'),
  new RegExp('^call[_ ]?in[_ ]?a[_ ]?while$', 'i'),
  new RegExp('^re[_ ]?new$', 'i'),
  new RegExp('^initial[_ ]?call$', 'i'),
  new RegExp('^appointment$', 'i'),
];

export const statusGroupsOptions = [
  { text: 'Deposits', value: 0 },
  { text: 'Hot Leads', value: 1 },
  { text: 'Call Back', value: 2 },
  { text: 'Not Interesting', value: 3 },
  { text: 'Invalid', value: 4 },
];

export const LeadContactInfoFields = [
  'email',
  'password',
  'firstname',
  'lastname',
  'phone',
  'language',
  'country',
  'ip',
  'geoip_country',
  'so',
];

export const RX_ARRAY_NOTATION = /\[(\d+)]/g;
export const RX_DIGITS = /^\d+$/;
export const RX_EXTENSION = /^\..+/;
export const RX_HASH = /^#/;
export const RX_HASH_ID = /^#[A-Za-z]+[\w\-:.]*$/;
export const RX_HTML_TAGS = /(<([^>]+)>)/gi;
export const RX_HYPHENATE = /\B([A-Z])/g;
export const RX_LOWER_UPPER = /([a-z])([A-Z])/g;
export const RX_NUMBER = /^[0-9]*\.?[0-9]+$/;
export const RX_PLUS = /\+/g;
export const RX_REGEXP_REPLACE = /[-/\\^$*+?.()|[\]{}]/g;
export const RX_SPACES = /[\s\uFEFF\xA0]+/g;
export const RX_SPACE_SPLIT = /\s+/;
export const RX_STAR = /\/\*$/;
export const RX_START_SPACE_WORD = /(\s|^)(\w)/g;
export const RX_TRIM_LEFT = /^\s+/;
export const RX_TRIM_RIGHT = /\s+$/;
export const RX_UNDERSCORE = /_/g;
export const RX_UN_KEBAB = /-(\w)/g;

export const dateFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  weekday: 'short',
};

export const testIntegration = {
  SendLead: {
    RequestType: 'get',
    RequestDataTransferForm: 'json',
    RequestHeaders: { 'Content-Type': 'application/json', kek: 'pek' },
    Auth: { method: 'direct' },
    endpoint: 'qwerty',
    ResultIdPath: [
      ['a', 'b', 'c'],
      ['d', 'e', 'f'],
    ],
    dataPreprocessing: { type: 'plain' },
  },
  FinalReceiverIs: 'url',
  FinalReceiverPath: [
    ['a', 'b', 'c'],
    ['d', 'e', 'f'],
  ],
  FinalReceiverSource: 'brokerApiAnswer',
  BrokerAcceptedORConditions: [
    { key: 'kek', value: 'pek' },
    { key: 'sas', value: 'mem' },
  ],
  BrokerNotAcceptedORConditions: [
    { key: 'kek', value: 'pek' },
    { key: 'sas', value: 'mem' },
  ],
  Pulling: {
    RequestType: 'get',
    RequestDataTransferForm: 'json',
    RequestHeaders: { 'Content-Type': 'application/json', kek: 'pek', sas: 'mem' },
    Auth: { method: 'direct' },
    AttachAuthHeadersTo: 'header',
    FromToDate: {
      Format: 'DD-MM-YYYY',
      DateToIsNowPlus1Day: false,
      DaysInterval: 25,
      fromField: 'dafeFrom',
      toField: 'dateTo',
    },
    Pagination: {
      use: true,
      type1: 'page',
      pageNumberField: 'page',
      itemsPerPageField: 'itemsPerPage',
      itemsPerPage: 50,
      totalItemsField: 'items',
      totalPagesField: 'totalPages',
    },
    Leads: {
      endpoint: '/qwertyasd',
      ResultsArrayPath: [
        ['a', 'b', 'c'],
        ['d', 'e', 'f'],
      ],
      LeadIdPath: [
        ['a', 'b', 'c'],
        ['d', 'e', 'f'],
      ],
      SalesStatusPath: [
        ['a', 'b', 'c'],
        ['d', 'e', 'f'],
      ],
      SalesStatusEncoded: true,
      StatusCodes: { kek: 'pek', sas: 'mem' },
      // dataPreprocessing: { type: 'custom', function: 'qwerty asdada' },
    },
    FTDs: {
      endpoint: '/fqfqwgq',
      ResultsArrayPath: [
        ['a', 'b', 'c'],
        ['d', 'e', 'f'],
      ],
      LeadIdPath: [
        ['a', 'b', 'c'],
        ['d', 'e', 'f'],
      ],
      HaveDate: true,
      DatePath: ['a', 'b', 'c'],
      DateFormat: 'DD-MM-YYYY',
      // dataPreprocessing: { type: 'custom', function: 'qwerty asdada' },
    },
  },
};

export const authAttachToOptions = ['header', 'body'];
export const authMethodOptions = ['direct', 'separateRequest'];

export const campaignInitialValue = {
  campaign: '',
  campaign_Multiselect: null,
  active: true,
  hourLimit: [0, 23],
  days: daysOfWeek.map((d) => d.item),
  countries: [],
  priority: '',
  weight: 100,
  dailyCap: { type: undefined, amount: undefined },
  totalCap: { type: undefined, amount: undefined },
  defaultPayment: undefined,
  aff_defaultPayment: undefined,
  currency: 'USD',
  useLimitMPerBCE: false,
  limitMPerBCE: undefined,
  useLinkCheckService: undefined,
  _showDetails: true,
};

export const timePeriodOptions = [
  { text: 'Today', value: 'this day' },
  { text: 'Yesterday', value: 'last day' },
  { text: 'This Week', value: 'this week' },
  { text: 'Last Week', value: 'last week' },
  { text: 'This Month', value: 'this month' },
  { text: 'Last Month', value: 'last month' },
  { text: 'Custom', value: 'custom' },
]; // ['day', 'week', 'month', 'year'];

export const selectByOptions = ['geo', 'affiliate', 'campaign']; // , 'box'

export const initialFilterForm = {
  from: DateTime.utc()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toFormat(datepickerFormat),
  to: DateTime.utc().toFormat(datepickerFormat),
  affiliates: undefined,
  campaigns: undefined,
  campaignsInSelectionDropouts: undefined,
  metaCampaigns: undefined,
  finalReceivers: undefined,
  boxes: undefined,
  locations: undefined,
  email: undefined,
  phone: undefined,
  ip: undefined,
  _id: undefined,
  salesStatus: undefined,
  salesStatusInHistory: undefined,
  showPositiveStatuses: false,
  showPositiveToNegatives: false,
  showOnlyM: false,
  showOnlyChecked: false,
  showOnlyShaved: false,
  showPreparedToWork: false,
  hideTestFtds: true,
  hideTestActions: false,
  sortBy: 'actionDate',
  sortOrder: 'desc',
  showOnlyParanoidUniq: undefined,
  showOnlyPreparedToPush: false,
  hidePreparedToPush: false,
  sentByInj: undefined,
  errorOnSend: false,
  clientsApiURL: undefined,
  workers: undefined,
};

export const sortOrderOptions = [
  { text: 'Asc', value: 'asc' },
  { text: 'Desc', value: 'desc' },
];
