import { twoDotsRegex, urlRegex } from '@/validators/_utils';

export default {
  url: (c) => {
    if (!c || c.length === 0) return false;
    const url = !c.includes('http://') && !c.includes('https://') ? `https://${c}` : c;
    return urlRegex.test(url) && !twoDotsRegex.test(url);
  },
  username: (c) => !!c && c.length > 0,
  password: (c) => !!c && c.length > 0,
};
