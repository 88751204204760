<template>
  <b-container fluid>
    <div :class="isValid.form ? 'tab' : 'tab-invalid'">
      <b-form-group label="Injection" label-for="injection" class="my-1">
        <b-input id="injection" v-model="testForm.injection"></b-input>
      </b-form-group>
      <b-form-group label="Box" label-for="box" class="my-1">
        <multiselect
          id="box"
          v-model="testForm.box"
          :options="boxOptions"
          track-by="name"
          label="name"
          :multiple="false"
          :closeOnSelect="true"
        ></multiselect>
      </b-form-group>
      <b-form-group label="Country" label-for="country" class="my-1">
        <multiselect
          id="country"
          v-model="testForm.country"
          :options="countryOptions"
          track-by="value"
          :custom-label="countryWithCode"
          @search-change="countryMultiSelectSearch"
          :closeOnSelect="true"
          :multiple="false"
        ></multiselect>
      </b-form-group>
      <div class="d-flex justify-content-between my-3">
        <b-button @click="sendTest()" variant="primary" :disabled="!isValid.form || requesting">
          <b-spinner small v-if="requesting"></b-spinner>
          Send Test
        </b-button>
        <b-button @click="sendTestAll()" variant="warning" :disabled="requesting">
          <b-spinner small v-if="requesting"></b-spinner>
          Test All Injections GMLevelDistribution
        </b-button>
      </div>
    </div>

    <div v-if="lastTestResult !== undefined" class="my-2">
      <h4>Last test result</h4>
      <b-card header="Test Result">
        <vue-json-pretty :data="lastTestResult"></vue-json-pretty>
        <!--        v-if="!!lastTestResult && lastTestResult.brokerApiAnswer"-->
      </b-card>
      <b-card header="Test Data">
        <vue-json-pretty v-if="!!lastTestData" :data="lastTestData"></vue-json-pretty>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import { sendTestCheckAllGMLevelDistributions, sendTestGMLevelDistribution } from '@/utils/api';
import { countryMultiSelectSearch, countryWithCode, handleRequestError, showCard } from '@/utils';
import store from '@/store';
import { formValid, isValid } from '@/validators';
import VueJsonPretty from 'vue-json-pretty';
import { countryOptions } from '@/consts';

export default {
  name: 'TestGMLevelDistribution',
  components: { VueJsonPretty },
  data() {
    return {
      countryOptions,
      testForm: {
        box: undefined,
        country: undefined,
        injection: undefined,
      },
      lastTestResult: undefined,
      lastTestData: undefined,
      backdoor: 0,
    };
  },
  computed: {
    ...mapState(['boxes', 'requesting', 'token']),
    isValid() {
      this.backdoor;
      const fields = isValid('test_gm_level_distribution', this.testForm, this.testForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    boxOptions() {
      return this.boxes.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
  },
  methods: {
    countryMultiSelectSearch,
    countryWithCode(c) {
      return countryWithCode(c);
    },
    sendTest() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        const testForm = _.cloneDeep(this.testForm);
        testForm.box = testForm.box._id;
        testForm.country = testForm.country.value;
        // if (testForm.injection) testForm.injection = testForm.injection._id;

        sendTestGMLevelDistribution(this.token, testForm)
          .then((r) => {
            if (r.status === 200 && !r.data.error) {
              showCard(this, `Test sent`, 'success');
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on sending test`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.lastTestResult = _.cloneDeep(r.data);
            this.lastTestData = _.cloneDeep(testForm);
            console.log('r.data', r.data);
            console.log('this.lastTestResult', this.lastTestResult);
            this.$store.commit('make_requesting', { req: false });
          })
          .catch((e) => {
            handleRequestError(this, e, 'Unknown server error on sending test');
            this.$store.commit('make_requesting', { req: false });
          });
      }
    },
    sendTestAll() {
      this.$store.commit('make_requesting', { req: true });
      sendTestCheckAllGMLevelDistributions(this.token)
        .then((r) => {
          if (r.status === 200 && !r.data.error) {
            showCard(this, `Test sent`, 'success');
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on sending test`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }

          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, 'Unknown server error on sending test');
          this.$store.commit('make_requesting', { req: false });
        });
    },
    async updateData() {
      await store.dispatch('get_all_resources_one_request', { vm: this });
    },
  },
  mounted() {
    this.updateData();
    // if (this.$route.params.id) this.getBox(this.$route.params.id);
  },
};
</script>

<style scoped></style>
