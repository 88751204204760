import { userTypeValues } from '@/consts';
import { nameTest, oidRegex, validateRefArray } from '@/validators/_utils';
import { isManager } from '@/utils';

const { isEmail, isStrongPassword } = require('validator');
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default {
  _id: (c) => !!(!c || oidRegex.test(c)),
  email: (c) => typeof c === 'string' && isEmail(c),
  password: (c, h) =>
    !!(
      (h.hasPassword && (!c || c === '')) ||
      (c && typeof c === 'string' && isStrongPassword(c, { minSymbols: 0 }))
    ),
  name: (c) => nameTest(c),
  campaigns: (c) => validateRefArray(c.map((ce) => ce._id)),
  boxes: (c) => validateRefArray(c.map((ce) => ce._id)),
  affiliates: (c) => validateRefArray(c.map((ce) => ce._id)),
  manager: (c, h) => isManager(h.form) || oidRegex.test(c),
  type: (c) => userTypeValues.includes(c),
  messenger: {
    telegram: (c) => {
      if (!c || c === '') return true;
      if (
        /^[a-zA-z0-9_]{5,}$/.test(c) ||
        /^@[a-zA-z0-9_]{5,}$/.test(c) ||
        /^(https?:\/\/)?(www\.)?t\.me\/[a-zA-z0-9_]{5,}$/.test(c)
      ) {
        return true;
      }
      try {
        const p = c[0] === '+' ? c : `+${c}`;
        return phoneUtil.isValidNumber(phoneUtil.parse(p));
      } catch (e) {
        return false;
      }
    },
    skype: (c) => !!(!c || c === '' || (c && typeof c === 'string' && c.length > 3)),
    hiddenBoxes: (c) => validateRefArray(c.map((ce) => ce._id)),
    hiddenCampaigns: (c) => validateRefArray(c.map((ce) => ce._id)),
  },
};
