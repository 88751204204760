export default function showCard(
  ctx,
  message = '',
  mode = 'success',
  title,
  toaster = 'b-toaster-top-right'
) {
  let titleToShow = title;

  if (!title) {
    switch (mode) {
      case 'success':
        titleToShow = 'Success! 🥳';
        break;
      case 'warning':
        titleToShow = 'Error 😐';
        break;
      case 'danger':
        titleToShow = 'Server error 😭';
        break;
      default:
    }
  }

  ctx.$bvToast.toast(message, {
    title: titleToShow,
    toaster,
    variant: mode,
    solid: true,
    static: true,
    // noAutoHide: true,
    autoHideDelay: 5000,
  });
}
