<template>
  <InjectionSendResultTable />
</template>

<script>
import InjectionSendResultTable from '@/components/pages/InjectionSendResult/InjectionSendResultTable.vue';

export default {
  name: 'InjectionSendResultTableView',
  components: {
    InjectionSendResultTable,
  },
};
</script>

<style scoped></style>
