<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">Test Lead</h2>
    </div>
    <b-form-group
      class="mt-2 mb-1"
      label="box"
      label-for="box"
      :invalid-feedback="invalidFeedback.test.b"
      :state="isValid.fields.b"
    >
      <b-form-select
        id="box"
        v-model="testForm.b"
        :options="boxOptions"
        text-field="name"
        value-field="_id"
        :state="isValid.fields.b"
        disabled
      ></b-form-select>
    </b-form-group>

    <b-form-group
      class="mt-2 mb-1"
      label="Campaign"
      label-for="Campaign"
      :invalid-feedback="invalidFeedback.test.c"
      :state="isValid.fields.c"
    >
      <multiselect
        v-model="testForm.c"
        :options="campaignsOptions"
        track-by="name"
        label="name"
        :closeOnSelect="true"
      ></multiselect>
    </b-form-group>

    <b-form-group
      class="mt-2 mb-1"
      label="email"
      label-for="email"
      :invalid-feedback="invalidFeedback.test.email"
      :state="isValid.fields.email"
    >
      <b-form-input
        type="text"
        id="email"
        v-model="testForm.email"
        :state="isValid.fields.email"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      class="mt-2 mb-1"
      label="password"
      label-for="password"
      :invalid-feedback="invalidFeedback.test.password"
      :state="isValid.fields.password"
    >
      <!--      <b-form-input-->
      <!--        type="text"-->
      <!--        id="password"-->
      <!--        v-model="testForm.password"-->
      <!--        :state="isValid.fields.password"-->
      <!--      ></b-form-input>-->
      <password
        id="password"
        v-on:input="
          testForm.password = $event;
          backdoor += 1;
        "
        size="12"
        :auto="true"
        type="text"
        placeholder="Password"
        class=""
        characters="a-z,A-Z,0-9,#"
      ></password>
    </b-form-group>
    <b-form-group
      class="mt-2 mb-1"
      label="firstname"
      label-for="firstname"
      :invalid-feedback="invalidFeedback.test.firstname"
      :state="isValid.fields.firstname"
    >
      <b-form-input
        type="text"
        id="firstname"
        v-model="testForm.firstname"
        :state="isValid.fields.firstname"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      class="mt-2 mb-1"
      label="lastname"
      label-for="lastname"
      :invalid-feedback="invalidFeedback.test.lastname"
      :state="isValid.fields.lastname"
    >
      <b-form-input
        type="text"
        id="lastname"
        v-model="testForm.lastname"
        :state="isValid.fields.lastname"
      ></b-form-input>
    </b-form-group>
    <!--    <b-form-group-->
    <!--      class="mt-2 mb-1"-->
    <!--      label="language"-->
    <!--      label-for="language"-->
    <!--      :invalid-feedback="invalidFeedback.test.language"-->
    <!--      :state="isValid.fields.language"-->
    <!--    >-->
    <!--      <b-form-input-->
    <!--        type="text"-->
    <!--        id="language"-->
    <!--        v-model="testForm.language"-->
    <!--        :state="isValid.fields.language"-->
    <!--      ></b-form-input>-->
    <!--    </b-form-group>-->
    <b-form-group
      class="mt-2 mb-1"
      label="country"
      label-for="country"
      :invalid-feedback="invalidFeedback.test.country"
      :state="isValid.fields.country"
    >
      <b-form-input
        type="text"
        id="country"
        v-model="testForm.country"
        :state="isValid.fields.country"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      class="mt-2 mb-1"
      label="language"
      label-for="language"
      :invalid-feedback="invalidFeedback.test.language"
      :state="isValid.fields.language"
    >
      <b-form-select
        id="language"
        v-model="testForm.language"
        :options="languageOptions"
        :state="isValid.fields.language"
      ></b-form-select>
    </b-form-group>
    <b-button
      class="mt-2 mb-1"
      @click="generatePhone()"
      :disabled="!isValid.fields.country || requesting"
      variant="success"
    >
      Generate Phone
    </b-button>
    <b-form-group
      class="mt-1 mb-1"
      label="phone"
      label-for="phone"
      :invalid-feedback="invalidFeedback.test.phone"
      :state="isValid.fields.phone"
    >
      <b-form-input
        type="text"
        id="phone"
        v-model="testForm.phone"
        :state="isValid.fields.phone"
      ></b-form-input>
    </b-form-group>
    <!--    <b-form-group-->
    <!--      class="mt-2 mb-1"-->
    <!--      label="ip"-->
    <!--      label-for="ip"-->
    <!--      :invalid-feedback="invalidFeedback.test.ip"-->
    <!--      :state="isValid.fields.ip"-->
    <!--    >-->
    <!--      <b-form-input-->
    <!--        type="text"-->
    <!--        id="ip"-->
    <!--        v-model="testForm.ip"-->
    <!--        :state="isValid.fields.ip"-->
    <!--      ></b-form-input>-->
    <!--    </b-form-group>-->
    <b-form-group
      class="mt-2 mb-1"
      label="so"
      label-for="so"
      :invalid-feedback="invalidFeedback.test.so"
      :state="isValid.fields.so"
    >
      <b-form-input
        type="text"
        id="so"
        v-model="testForm.so"
        :state="isValid.fields.so"
      ></b-form-input>
    </b-form-group>
    <!--    <b-form-group-->
    <!--      class="mt-2 mb-1"-->
    <!--      label="geoip_country"-->
    <!--      label-for="geoip_country"-->
    <!--      :invalid-feedback="invalidFeedback.test.geoip_country"-->
    <!--      :state="isValid.fields.geoip_country"-->
    <!--    >-->
    <!--      <b-form-input-->
    <!--        type="text"-->
    <!--        id="geoip_country"-->
    <!--        v-model="testForm.geoip_country"-->
    <!--        :state="isValid.fields.geoip_country"-->
    <!--      ></b-form-input>-->
    <!--    </b-form-group>-->
    <div class="d-flex justify-content-between my-3">
      <b-button @click="sendTest()" variant="primary" :disabled="!isValid.form || requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        Send Test
      </b-button>
    </div>
    <div v-if="lastTestResult !== undefined">
      <h4>Last test result</h4>
      <h5>
        Broker Accepted:
        <b-badge
          :variant="
            lastTestResult &&
            lastTestResult.brokerAccepted &&
            (lastTestResult.brokerAccepted === 'true' ||
              lastTestResult.brokerAccepted.value === 'true')
              ? 'success'
              : lastTestResult &&
                lastTestResult.brokerAccepted &&
                (lastTestResult.brokerAccepted === 'false' ||
                  lastTestResult.brokerAccepted.value === 'false')
              ? 'danger'
              : 'warning'
          "
        >
          {{
            lastTestResult &&
            lastTestResult.brokerAccepted &&
            (lastTestResult.brokerAccepted === 'true' ||
              lastTestResult.brokerAccepted.value === 'true')
              ? 'Yes'
              : lastTestResult &&
                lastTestResult.brokerAccepted &&
                (lastTestResult.brokerAccepted === 'false' ||
                  lastTestResult.brokerAccepted.value === 'false')
              ? 'No'
              : 'unknown'
          }}
        </b-badge>
      </h5>
      <h5 v-if="lastTestResult">Internal Action Id: {{ lastTestResult.action_id }}</h5>
      <h5 v-if="lastTestResult">Broker Action Id: {{ lastTestResult.brokerActionId }}</h5>
      <b-card header="Send Lead Request Config">
        <vue-json-pretty
          v-if="!!lastTestResult && lastTestResult.sendLeadRequestConfig"
          :data="lastTestResult.sendLeadRequestConfig"
        ></vue-json-pretty>
      </b-card>
      <b-card header="Broker API answer">
        <vue-json-pretty
          v-if="!!lastTestResult && lastTestResult.brokerApiAnswer"
          :data="lastTestResult.brokerApiAnswer"
        ></vue-json-pretty>
      </b-card>
      <b-card header="Test Data">
        <vue-json-pretty v-if="!!lastTestData" :data="lastTestData"></vue-json-pretty>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import { invalidFeedback } from '@/consts';
import { formValid, isValid } from '@/validators';
import _ from 'lodash';
import { sendTest } from '@/utils/api';
import { handleRequestError, showCard } from '@/utils';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import store from '@/store';
import PhoneNumber from 'awesome-phonenumber';
import codes from 'iso-lang-codes';
import locale from 'locale-codes';
import Password from '@/components/shared/Password.vue';
import testbox from '@/validators/testbox';

//
// const testname = `test${String(Date.now())}`; // .substring(String(Date.now()).length - 3)
const itemInitialValues = {
  b: undefined,
  c: undefined,
  email: '', // `${testname}@test.com`,
  password: '', // 'Qwerty@12345',
  firstname: '', // `Test`,
  lastname: '', // `Testing`,
  phone: undefined,
  language: undefined,
  country: undefined,
  // ip: undefined,
  so: '', // 'Test',
  lf: true,
  geoip_country: undefined,
};
export default {
  name: 'TestBox',
  components: {
    VueJsonPretty,
    Password,
  },
  data() {
    return {
      testForm: _.cloneDeep(itemInitialValues),
      lastTestResult: undefined,
      lastTestData: undefined,
      invalidFeedback,
    };
  },
  computed: {
    ...mapState(['boxes', 'campaigns', 'requesting', 'token']),
    campaignsOptions() {
      return this.campaigns.map((c) => {
        return { name: c.name, _id: c._id };
      });
    },
    isValid() {
      this.backdoor;
      const fields = isValid('testbox', this.testForm, this.testForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    boxOptions() {
      return this.boxes.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    languageOptions() {
      const localeCodes = codes.findCountryLocales(
        this.testForm.country ? this.testForm.country.toUpperCase() : undefined
      );
      if (localeCodes.length > 0 && !localeCodes.some((lc) => lc.substr(0, 2) === 'en'))
        localeCodes.push('en-US');
      return localeCodes
        .map((value) => {
          let text;
          try {
            text = locale.getByTag(value.toLowerCase()).name;
            // eslint-disable-next-line no-empty
          } catch (e) {}
          return { text, value };
        })
        .filter((o) => !!o.text);
    },
  },
  methods: {
    generatePhone() {
      function randomNumber(length) {
        let text = '';
        const possible = '0123456789';
        for (let i = 0; i < length; i += 1)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
      }
      this.testForm.phone = 'generating...';
      let phone = '';
      let phoneValid = false;
      let i = 0;
      while (!phoneValid && i < 1000) {
        const example = PhoneNumber.getExample(
          this.testForm.country.toUpperCase(),
          'mobile'
        ).getNumber();
        const npos = example.indexOf('12345');
        const nlen = example.length - npos;
        phone = `${example.substring(0, npos)}${randomNumber(nlen)}`.substr(1);
        phoneValid = testbox.phone(phone, this.testForm);
        i += 1;
      }
      this.testForm.phone = phone;
    },
    sendTest() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        const testForm = _.cloneDeep(this.testForm);
        testForm.country = this.testForm.country.toUpperCase();
        sendTest(this.token, testForm)
          .then((r) => {
            if (r.status === 200 && !r.data.error) {
              showCard(this, `Test sent. Result: ${r.data}`, 'success');
              this.lastTestResult = r.data;
              this.lastTestData = _.cloneDeep(testForm);
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on sending test`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.$store.commit('make_requesting', { req: false });
          })
          .catch((e) => {
            handleRequestError(this, e, 'Unknown server error on sending test');
            this.$store.commit('make_requesting', { req: false });
          });
      }
    },
    async updateData() {
      await store.dispatch('get_all_resources_one_request', { vm: this });
      // if (this.$route.params.id) this.getBox(this.$route.params.id);
      const testBox = this.boxes.find((b) => b.isTest);
      this.testForm.b = testBox ? testBox._id : undefined;
    },
  },
  mounted() {
    this.updateData();
    // if (this.$route.params.id) this.getBox(this.$route.params.id);
  },
};
// zxc
</script>

<style scoped></style>
