<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h4 class="mx-1 my-2">{{ name }}</h4>
    <b-form-group
      class="my-3"
      label="FinalReceiver Is"
      label-for="finalreceiverparameters-finalreceiveris"
      :state="isValid.fields.FinalReceiverIs"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
    -->
      <b-form-select
        id="finalreceiverparameters-finalreceiveris"
        v-model="form.FinalReceiverIs"
        :options="FinalReceiverIsOptions"
        @input="emitEditEvent"
        :state="isValid.fields.FinalReceiverIs"
      ></b-form-select>
    </b-form-group>
    <b-form-group
      v-if="form.FinalReceiverIs === 'static'"
      class="my-3"
      label="FinalReceiver Is"
      label-for="finalreceiverparameters-finalreceiverconst"
      :state="isValid.fields.FinalReceiverIs"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
    -->
      <b-form-input
        id="finalreceiverparameters-finalreceiverconst"
        v-model="form.FinalReceiverConst"
        @input="emitEditEvent"
        :state="isValid.fields.FinalReceiverConst"
      ></b-form-input>
    </b-form-group>
    <PathArrayForm
      v-if="form.FinalReceiverIs === 'url'"
      name="FinalReceiver Paths"
      :pathArray="form.FinalReceiverPath"
      @edit="handleEditEvent($event, 'FinalReceiverPath')"
      @validate="handleValidateEvent($event, 'FinalReceiverPath')"
      disallowEmpty
    ></PathArrayForm>
    <b-form-group
      class="my-3"
      label="FinalReceiver Source"
      label-for="finalreceiverparameters-finalreceiversource"
      :state="isValid.fields.FinalReceiverSource"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
    :state="isValid.fields.name"-->
      <b-form-select
        id="finalreceiverparameters-finalreceiversource"
        v-model="form.FinalReceiverSource"
        :options="FinalReceiverSourceOptions"
        @input="emitEditEvent"
        :state="isValid.fields.FinalReceiverSource"
      ></b-form-select>
      <!--      :state="isValid.fields.name"-->
    </b-form-group>
  </div>
</template>

<script>
import '@/assets/form.scss';
import { cloneDeep, omit } from 'lodash';
import handleEditEvent from '@/utils/handleEditEvent';
import PathArrayForm from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegrationPieces/PathArrayForm.vue';
import handleValidateEvent from '@/utils/handleValidateEvent';
import { mapPropsToValid } from '@/utils';
import { formValid, isValid } from '@/validators';

export default {
  name: 'FinalReceiverParameters',
  components: { PathArrayForm },
  props: {
    name: { type: String, default: 'FinalReceiver Parameters' },
    FinalReceiverIs: { type: String, default: '' },
    FinalReceiverConst: { type: String, default: '' },
    FinalReceiverPath: { type: Array, default: () => [] },
    FinalReceiverSource: { type: String, default: 'brokerApiAnswer' },
  },
  data() {
    return {
      form: cloneDeep(omit(this.$props, ['name'])),
      valid: mapPropsToValid(this.$props),
      FinalReceiverIsOptions: ['itself', 'url', 'static'],
      FinalReceiverSourceOptions: ['brokerApiAnswer', 'leadContactInfo'],
      backdoor: 0,
      hoverMe: false,
    };
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = {
        ...this.valid,
        ...isValid('integration_FinalReceiverParameters', this.form, this.form),
        FinalReceiverPath: this.form.FinalReceiverIs !== 'url' || this.valid.FinalReceiverPath,
      };
      const form = formValid(fields);
      // this.$emit('validate', form);
      // console.log('validated FinalReceiverParameters', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    handleEditEvent,
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    handleValidateEvent,
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    emitValidateEvent() {
      this.$emit('validate', this.isValid.fields);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
};
</script>

<style scoped></style>
