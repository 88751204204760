<template>
  <TestPulling />
</template>

<script>
import TestPulling from '@/components/pages/TestPulling/TestPulling.vue';

export default {
  name: 'TestPullingView',
  components: {
    TestPulling,
  },
};
</script>

<style scoped></style>
