/* eslint-disable import/prefer-default-export */
export const defaultIntegrationSettings = {
  Trackbox: {
    apiEndpoints: ['/api/signup/procform', '/api/pull/customers'],
    brokerApiURL: 'https://platform.{yourdomain}.com', // /api/signup/procform',
    brokerPullURL: 'https://platform.{yourdomain}.com', // /api/pull/customers',
    brokerAuthHeaders: [
      ['x-trackbox-username', undefined],
      ['x-trackbox-password', undefined],
      ['x-api-key', '2643889w34df345676ssdas323tgc738'],
      ['Content-Type', 'application/json'],
    ],
    brokerAuthBody: [
      ['ai', undefined],
      ['ci', undefined],
      ['gi', undefined],
    ],
    brokerPullAuthHeaders: [
      ['x-trackbox-username', undefined],
      ['x-trackbox-password', undefined],
      ['x-api-key', '264388973aaa9b2f9eb2aa84a9c7382e'],
      ['Content-Type', 'application/json'],
    ],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstname',
      lastname: 'lastname',
      phone: 'phone',
      language: '',
      country: '',
      ip: 'userip',
      geoip_country: '',
      so: 'so',
      aff_id: '',
    },
  },
  Getlinked: {
    apiEndpoints: [
      '/api/v1/signups/add.php',
      '/api/v1/brokers/accounts/apis/leads.php',
      '/api/v1/brokers/accounts/apis/deposits.php',
    ],
    brokerApiURL: 'https://maxtrack.net',
    brokerPullURL: 'https://maxtrack.net',
    brokerAuthHeaders: [['Api-Key', undefined]], // [undefined, undefined]
    brokerAuthBody: [], // [undefined, undefined]
    brokerPullAuthHeaders: [['Api-Key', undefined]], // [undefined, undefined]
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstName',
      lastname: 'lastName',
      phone: 'phone',
      language: '',
      country: '',
      ip: 'ip',
      geoip_country: '',
      so: 'offerName',
      aff_id: '',
    },
  },
  GetlinkedV2: {
    apiEndpoints: ['/api/v2/leads', '/api/v2/leads', '/api/v2/conversions'],
    brokerApiURL: 'https://{example}.net',
    brokerPullURL: 'https://{example}.net',
    brokerAuthHeaders: [['Api-Key', undefined]], // [undefined, undefined]
    brokerAuthBody: [], // [undefined, undefined]
    brokerPullAuthHeaders: [['Api-Key', undefined]], // [undefined, undefined]
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstName',
      lastname: 'lastName',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: 'ip',
      geoip_country: '',
      so: 'offerName,comment,custom2',
    },
  },
  XGlobal: {
    apiEndpoints: [
      '/api/affiliate/generateauthtoken',
      '/api/aff/accounts',
      '/api/aff/itemsfor/Accounts',
      '/api/aff/itemsfor/Deposits',
    ],
    brokerApiURL: 'https://affiliate.xglobalfin.com',
    brokerPullURL: 'https://affiliate.xglobalfin.com',
    brokerAuthHeaders: [
      ['userName', ''],
      ['password', ''],
    ],
    brokerAuthBody: [],
    brokerPullAuthHeaders: [
      ['userName', ''],
      ['password', ''],
    ],
    brokerApiMapping: {
      email: 'Email',
      password: '',
      firstname: 'FirstName',
      lastname: 'LastName',
      phone: 'Phone',
      language: '',
      country: 'Country',
      ip: '',
      geoip_country: '',
      so: '',
      aff_id: '',
    },
  },
  DrMailer: {
    apiEndpoints: [
      '/repost.php?act=register',
      '/repost.php?act=get_leads_status',
      '/repost.php?act=get_depositors',
    ],
    brokerApiURL: 'https://tracker.doctor-mailer.com', // /repost.php',
    brokerPullURL: 'https://tracker.doctor-mailer.com', // /repost.php',
    brokerAuthHeaders: [], // [undefined, undefined]
    brokerAuthBody: [
      ['ApiKey', undefined],
      ['ApiPassword', undefined],
      ['ES', '289'],
    ], // [undefined, undefined]
    brokerPullAuthHeaders: [
      ['ApiKey', undefined],
      ['ApiPassword', undefined],
    ], // [undefined, undefined]
    brokerApiMapping: {
      email: 'Email',
      password: '',
      firstname: 'FirstName',
      lastname: 'LastName',
      phone: 'PhoneNumber',
      language: 'Language',
      country: '',
      ip: 'IP',
      geoip_country: '',
      so: 'Page',
      aff_id: '',
    },
  },
  Platform500: {
    apiEndpoints: [
      '/backend/api/v1/affiliates/fields',
      '/backend/api/v1/affiliates/leads',
      '/backend/api/v1/affiliates/leads',
      '/backend/api/v1/affiliates/conversions',
    ],
    brokerApiURL: 'https://{subdomain}.platform500.com', // /backend',
    brokerPullURL: 'https://{subdomain}.platform500.com', // /backend',
    brokerAuthHeaders: [], // [undefined, undefined]
    brokerAuthBody: [['token', undefined]], // [undefined, undefined]
    brokerPullAuthHeaders: [['token', undefined]], // [undefined, undefined]
    brokerApiMapping: {
      email: 'profile.email',
      password: 'profile.password',
      firstname: 'profile.first_name',
      lastname: 'profile.last_name',
      phone: 'profile.phone',
      language: '',
      country: '', // profile[country]
      ip: 'ip',
      geoip_country: '',
      so: 'tp_source',
      aff_id: '',
    },
  },
  'CRM-APIV2': {
    apiEndpoints: ['/api/create_lead.php', '/api/get_leads.php', '/api/get_deposits.php'],
    brokerApiURL: 'https://secure.{yourdomain}.com',
    brokerPullURL: 'https://secure.{yourdomain}.com',
    brokerAuthHeaders: [],
    brokerAuthBody: [
      ['apikey', undefined],
      ['lead_type', 'live'],
      ['source', undefined],
    ],
    brokerPullAuthHeaders: [
      ['apikey', undefined],
      ['source', undefined],
    ],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      aff_id: '',
    },
  },
  FICRM: {
    apiEndpoints: ['/Marketing/Leads/add.aspx', '/Marketing/Leads/campaigns.aspx'],
    brokerApiURL: 'https://apis.{BrandName}.com',
    brokerPullURL: 'https://apis.{BrandName}.com',
    brokerAuthHeaders: [], // [undefined, undefined]
    brokerAuthBody: [
      ['referral', undefined],
      ['cid', undefined],
    ], // [undefined, undefined]
    brokerPullAuthHeaders: [
      ['name', undefined],
      ['password', undefined],
      ['id', undefined],
    ], // [undefined, undefined]
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'fname',
      lastname: 'lname',
      phone: 'phone',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      aff_id: '',
    },
  },
  Excentral: {
    apiEndpoints: ['/v1/lead/create', '/v1/lead/getRegistrations', '/v1/lead/getConversions'],
    brokerApiURL: 'https://api-partners.eu.excentral.com',
    brokerPullURL: 'https://api-partners.eu.excentral.com',
    brokerAuthHeaders: [['partnerId', '']],
    brokerAuthBody: [['affiliateID', '']],
    brokerPullAuthHeaders: [['partnerId', '']],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'firstName',
      lastname: 'lastName',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      regDate: '',
      aff_id: '',
    },
  },
  Skaleapps: {
    apiEndpoints: ['CreateLiveAccount', 'GetAllLeadsStatus'],
    brokerApiURL: 'http://affiliate.api.skaleapps.io', // /api/v1/',
    brokerPullURL: 'http://affiliate.api.skaleapps.io', // /api/v1/',
    brokerAuthHeaders: [
      ['username', undefined],
      ['password', undefined],
      ['secret', undefined],
    ],
    brokerAuthBody: [['assigned_to', undefined]],
    brokerPullAuthHeaders: [
      ['username', undefined],
      ['password', undefined],
      ['secret', undefined],
    ],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: 'ip',
      geoip_country: 'registration_country',
      so: 'lead_source',
      aff_id: '',
    },
  },
  CmaxCRM: {
    apiEndpoints: [],
    brokerApiURL: 'https://api-aff.cmaxcrm.com',
    brokerPullURL: 'https://api-aff.cmaxcrm.com',
    brokerAuthHeaders: [], // [undefined, undefined]
    brokerAuthBody: [
      ['key', undefined],
      ['source', '10'],
    ], // [undefined, undefined]
    brokerPullAuthHeaders: [['key', undefined]], // [undefined, undefined]
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstName',
      lastname: 'lastName',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: 'ip',
      geoip_country: 'geoip_country',
      so: 'campaign',
      aff_id: '',
    },
  },
  RoiBees: {
    apiEndpoints: ['/lead', '/reportAPI', '/reportAPI?task=depositors'],
    brokerApiURL: 'https://leadpoint.jimmywho.media',
    brokerPullURL: 'https://leadpoint.jimmywho.media',
    brokerAuthHeaders: [
      ['secret', undefined],
      ['apikey', undefined],
      ['password', undefined],
    ], // [undefined, undefined]
    brokerAuthBody: [['CampaignId', undefined]], // [undefined, undefined]
    brokerPullAuthHeaders: [
      ['secret', undefined],
      ['apikey', undefined],
      ['password', undefined],
    ], // [undefined, undefined]
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'phone',
      language: 'funnel_language',
      country: 'country',
      ip: 'ip',
      geoip_country: '',
      so: 'funnel_lp',
      aff_id: '',
    },
  },
  Yoloads: {
    apiEndpoints: ['/introduceyourself/login', '/createlead', '/leads', '/deposits'],
    brokerApiURL: 'http://yolo.domain.io',
    brokerPullURL: 'http://yolo.domain.io',
    brokerAuthHeaders: [
      ['username', undefined],
      ['password', undefined],
    ], // [undefined, undefined]
    brokerAuthBody: [['aff_id', undefined]], // [undefined, undefined]
    brokerPullAuthHeaders: [
      ['username', undefined],
      ['password', undefined],
    ], // [undefined, undefined]
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'fname',
      lastname: 'lname',
      phone: 'phone',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: 'source',
      aff_id: '',
    },
  },
  EDEAL: {
    apiEndpoints: ['/api/v3/customers', '/api/v3/customers'],
    brokerApiURL: 'https://edjax.pro/api/v3/customers/{Unique_identifier}',
    brokerPullURL: 'https://fetch.edeal.io/api/v3/customers',
    brokerAuthHeaders: [['X-Api-Auth', '']],
    brokerAuthBody: [],
    brokerPullAuthHeaders: [['X-Api-Auth', '']],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: 'ip',
      geoip_country: '',
      so: 'utm_source',
      aff_id: '',
    },
  },
  Daughter: {
    brokerApiURL: 'https://{domain}.kek',
    brokerPullURL: 'https://{domain}.kek',
    brokerAuthHeaders: [],
    brokerAuthBody: [
      ['auth_email', ''],
      ['auth_password', ''],
      ['b', ''],
    ],
    brokerPullAuthHeaders: [
      ['auth_email', ''],
      ['auth_password', ''],
    ],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstname',
      lastname: 'lastname',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: 'ip',
      geoip_country: 'geoip_country',
      so: 'so',
      regDate: 'regDate',
      aff_id: '',
    },
  },
  Mother: {
    brokerApiURL: 'https://{domain}.kek',
    brokerPullURL: 'https://{domain}.kek',
    brokerAuthHeaders: [],
    brokerAuthBody: [
      ['auth_email', ''],
      ['auth_password', ''],
      ['b', ''],
      ['sender', ''],
      ['copy', ''],
    ],
    brokerPullAuthHeaders: [
      ['auth_email', ''],
      ['auth_password', ''],
    ],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstname',
      lastname: 'lastname',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: 'ip',
      geoip_country: 'geoip_country',
      so: 'so',
      regDate: 'regDate',
      aff_id: '',
    },
  },
  BlackDiamondSwiss: {
    apiEndpoints: ['/api/affilator', '/api/Partner/GetLeads', '/api/Partner/GetDeposits'],
    brokerApiURL: 'https://{domain}.kek',
    brokerPullURL: 'https://{domain}.kek',
    brokerAuthHeaders: [],
    brokerAuthBody: [['Token', '']],
    brokerPullAuthHeaders: [
      ['ID', ''],
      ['Token', ''],
    ],
    brokerApiMapping: {
      email: 'Email',
      password: 'Password',
      firstname: 'Name',
      lastname: 'LastName',
      phone: 'Phone',
      language: '',
      country: 'Country',
      ip: '',
      geoip_country: '',
      so: 'Source',
      regDate: '',
      aff_id: '',
    },
  },
  Profitmarketgsl: {
    apiEndpoints: ['/api-v2/leads-add.php', '/api-v2/leads.php'],
    brokerApiURL: 'https://{domain}.kek',
    brokerPullURL: 'https://{domain}.kek',
    brokerAuthHeaders: [],
    brokerAuthBody: [
      ['name', ''],
      ['pass', ''],
    ],
    brokerPullAuthHeaders: [
      ['name', ''],
      ['pass', ''],
    ],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'firstname',
      lastname: 'surname',
      phone: 'phonenumber',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: 'source',
      regDate: '',
      aff_id: '',
    },
  },
  Telexsale: {
    apiEndpoints: ['/v1/webhooks/trackbox/lead', '/v1/sources'],
    brokerApiURL: 'https://api.telexsale.com',
    brokerPullURL: 'https://primeapi.telexsale.com',
    brokerAuthHeaders: [],
    brokerAuthBody: [['source', '']],
    brokerPullAuthHeaders: [['source', '']],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'firstName',
      lastname: 'lastName',
      phone: 'phone',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      regDate: '',
      aff_id: '',
    },
  },
  EpcClub: {
    apiEndpoints: ['/api/v1/leads/create', '/api/v1/leads', '/api/v1/leads/ftd'],
    brokerApiURL: 'https://partners.epc.club',
    brokerPullURL: 'https://partners.epc.club',
    brokerAuthHeaders: [],
    brokerAuthBody: [['api_token', '']],
    brokerPullAuthHeaders: [['api_token', '']],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'phone',
      language: 'language',
      country: 'country_iso',
      ip: '',
      geoip_country: '',
      so: 'page_name',
      regDate: '',
      aff_id: '',
    },
  },
  FALCON: {
    apiEndpoints: ['/clients'],
    brokerApiURL: 'https://partner.absystem.cc',
    brokerPullURL: 'https://partner.absystem.cc',
    brokerAuthHeaders: [
      ['login', ''],
      ['password', ''],
    ],
    brokerAuthBody: [['affiliateID', '']],
    brokerPullAuthHeaders: [
      ['login', ''],
      ['password', ''],
    ],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstName',
      lastname: 'lastName',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      regDate: '',
      aff_id: '',
    },
  },
  Axela: {
    apiEndpoints: ['/api/v1/api-lead/create', '/api/v1/api-lead/statistic'],
    brokerApiURL: 'https://api.axela.network',
    brokerPullURL: 'https://api.axela.network',
    brokerAuthHeaders: [['X-Secret-Key', '']],
    brokerAuthBody: [
      ['PT', ''],
      ['IE', ''],
      ['GB', ''],
      ['ES', ''],
    ],
    brokerPullAuthHeaders: [['X-Secret-Key', '']],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'phone',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      regDate: '',
      aff_id: '',
    },
  },
  Ultracrms: {
    apiEndpoints: ['/api/integrate/', '/clients'],
    brokerApiURL: 'http://ultracrms.ru',
    brokerPullURL: 'http://ultracrms.ru',
    brokerAuthHeaders: [['key', '']],
    brokerAuthBody: [['secure', '']],
    brokerPullAuthHeaders: [['token', '']],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'phone_country,phone_operator,phone_number',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: 'offer_desc',
      regDate: '',
      aff_id: '',
    },
  },
  OVO: {
    apiEndpoints: ['/leads', '/deposit'],
    brokerApiURL: 'https://toptimesnews.com',
    brokerPullURL: 'https://toptimesnews.com',
    brokerAuthHeaders: [],
    brokerAuthBody: [['apikey', '']],
    brokerPullAuthHeaders: [['apikey', '']],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'fname',
      lastname: 'lname',
      phone: 'phone',
      language: 'lang',
      country: 'country',
      ip: 'ip',
      geoip_country: '',
      so: '',
      regDate: '',
      aff_id: '',
    },
  },
  Affcountry: {
    apiEndpoints: ['/s2s/new_lead/create_by_goal', '/api/v2/affiliate/reports/conversions'],
    brokerApiURL: 'https://track.affcountry.com/',
    brokerPullURL: 'https://go.affcountry.com/',
    brokerAuthHeaders: [],
    brokerAuthBody: [
      ['goal_id', ''],
      ['affiliate_id', ''],
    ],
    brokerPullAuthHeaders: [['api-key', '']],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'firstname',
      lastname: 'lastname',
      phone: 'phone',
      language: '',
      country: 'country',
      ip: 'ip',
      geoip_country: '',
      so: '',
      regDate: '',
      aff_id: '',
    },
  },
  Affclub: {
    apiEndpoints: ['/v2/affiliates/lead/create', '/v2/affiliates/leads'],
    brokerApiURL: 'https://af34trkapi.com',
    brokerPullURL: 'https://af34trkapi.com',
    brokerAuthHeaders: [['Token', '']],
    brokerAuthBody: [],
    brokerPullAuthHeaders: [['Token', '']],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstname',
      lastname: 'lastname',
      phone: 'phone',
      language: '',
      country: 'country_code',
      ip: 'ip',
      geoip_country: '',
      so: 'source',
      regDate: '',
      aff_id: '',
    },
  },
  IndexEvolve: {
    apiEndpoints: [
      '/api/AfilliateUser/lead',
      '/api/AfilliateUser/leads',
      '/api/AfilliateUser/deposits',
    ],
    brokerApiURL: 'https://www.indexevolve.com/',
    brokerPullURL: 'https://www.indexevolve.com/',
    brokerAuthHeaders: [
      ['username', ''],
      ['password', ''],
    ],
    brokerAuthBody: [['Currency', 'USD']],
    brokerPullAuthHeaders: [
      ['username', ''],
      ['password', ''],
    ],
    brokerApiMapping: {
      email: 'Email',
      password: '',
      firstname: 'FirstName',
      lastname: 'LastName',
      phone: 'Phone',
      language: '',
      country: 'Country',
      ip: '',
      geoip_country: '',
      so: 'FunnelLink',
      regDate: '',
      aff_id: '',
    },
  },
  Afftech: {
    apiEndpoints: ['/users/ajax/', '/v2/download'],
    brokerApiURL: 'https://{domain.io}/users/ajax/{code}',
    brokerPullURL: 'https://api.megasource.io/v2/download',
    brokerAuthHeaders: [],
    brokerAuthBody: [['from_url', 'http://example.com']],
    brokerPullAuthHeaders: [['token', undefined]],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'name',
      lastname: 'lastname',
      phone: 'phone',
      language: 'language',
      country: 'country_id',
      ip: 'ip',
      geoip_country: 'geoip_country',
      so: '', // p4
      aff_id: '',
    },
  },
  Oxibanco: {
    apiEndpoints: ['/api/createlead', '/api/getleads', '/api/depositlist'],
    brokerApiURL: 'https://{domain.io}',
    brokerPullURL: 'https://{domain.io}',
    brokerAuthHeaders: [],
    brokerAuthBody: [
      ['key', undefined],
      ['source', undefined],
    ],
    brokerPullAuthHeaders: [
      ['key', undefined],
      ['source', undefined],
    ],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'mobile_num',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      aff_id: '',
    },
  },
  Intraders: {
    apiEndpoints: ['/contacts'],
    brokerApiURL: 'https://{domain.io}',
    brokerPullURL: 'https://{domain.io}',
    brokerAuthHeaders: [
      ['api-key', undefined],
      ['affiliate', undefined],
    ],
    brokerAuthBody: [],
    brokerPullAuthHeaders: [['api-key', undefined]],
    brokerApiMapping: {
      email: 'emails',
      password: 'password',
      firstname: 'first_name',
      lastname: 'last_name',
      phone: 'numbers',
      language: 'language',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: '',
      aff_id: '',
    },
  },
  SharkSoft: {
    apiEndpoints: ['/affiliates/leads'],
    brokerApiURL: 'https://api.{domain.io}/affiliates/leads',
    brokerPullURL: 'https://api.{domain.io}/affiliates/leads',
    brokerAuthHeaders: [['token', undefined]],
    brokerAuthBody: [],
    brokerPullAuthHeaders: [['token', undefined]],
    brokerApiMapping: {
      email: 'email',
      password: '',
      firstname: 'firstname',
      lastname: 'lastname',
      phone: 'phone',
      language: '',
      country: 'country',
      ip: '',
      geoip_country: '',
      so: 'utm_campaign',
      aff_id: '',
    },
  },
  Megasource: {
    apiEndpoints: ['/users/ajax/', '/v2/download'],
    brokerApiURL: 'https://{domain.io}/users/ajax/{code}',
    brokerPullURL: 'https://api.megasource.io/v2/download',
    brokerAuthHeaders: [],
    brokerAuthBody: [['from_url', 'http://example.com']],
    brokerPullAuthHeaders: [['token', undefined]],
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'name',
      lastname: 'lastname',
      phone: 'phone',
      language: 'language',
      country: 'country_id',
      ip: 'ip',
      geoip_country: 'geoip_country',
      so: '', // p4
      aff_id: '',
    },
  },
  // HugeOffers: {
  //   brokerApiURL: 'https://track.w-trk10.com/lds/affiliate/registration',
  //   brokerPullURL: 'https://api.hugeoffers.com/rest/affiliate',
  //   brokerAuthHeaders: [['lds-token', undefined]], // [undefined, undefined]
  //   brokerAuthBody: [], // [undefined, undefined]
  //   brokerPullAuthHeaders: [['token', undefined]], // [undefined, undefined]
  //   brokerApiMapping: {
  //     email: 'email',
  //     password: 'password',
  //     firstname: 'firstname',
  //     lastname: 'lastname',
  //     phone: 'phone',
  //     language: 'language',
  //     country: 'country',
  //     ip: 'registration_ip',
  //     geoip_country: 'geoip_country',
  //     so: 'p4',
  //   },
  // },
  default: {
    apiEndpoints: [],
    brokerApiURL: undefined,
    brokerPullURL: undefined,
    brokerAuthHeaders: [], // [undefined, undefined]
    brokerAuthBody: [], // [undefined, undefined]
    brokerPullAuthHeaders: [], // [undefined, undefined]
    brokerApiMapping: {
      email: 'email',
      password: 'password',
      firstname: 'firstname',
      lastname: 'lastname',
      phone: 'phone',
      language: 'language',
      country: 'country',
      ip: 'ip',
      geoip_country: 'geoip_country',
      so: 'so',
      aff_id: '',
    },
  },
};
