import { oidRegex } from '@/validators/_utils';
import { datepickerFormat, financialTransactionTypeClosedOptions } from '@/consts';
import { DateTime } from 'luxon';

export default {
  _id: (c) => !!(!c || oidRegex.test(c)),
  campaign: (c) => !!c && c._id && oidRegex.test(c._id),
  metaCampaign: (c) => !!c && c._id && oidRegex.test(c._id),
  type: (c) => financialTransactionTypeClosedOptions.map((t) => t.value).includes(c),
  amount: (c) => !!c && !Number.isNaN(Number(c)) && Number(c) > 0,
  date: (c) => {
    if (typeof c !== 'string') return false;
    const date = DateTime.fromFormat(c, datepickerFormat, { zone: 'utc' });
    return date.isValid;
  },
  comment: (c) => !c || typeof c === 'string',
};
