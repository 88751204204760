<template>
  <b-container fluid>
    <b-row class="my-3" no-gutters>
      <b-col cols="12" lg="2" class="my-lg-0 my-1">
        <h2>Box Campaign Settings</h2>
      </b-col>
      <b-col cols="12" lg="1" class="my-lg-0 my-1">
        <b-button variant="primary" v-b-modal="'modal-create-psp'">Create</b-button>
        <b-modal id="modal-create-psp" centered hide-footer no-close-on-esc @hide="updateData()">
          <BCEPresetCreate />
        </b-modal>
      </b-col>
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="3"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="3" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2 mb-3">
      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">ID</span>
        <b-form-input v-model="filterForm._id"></b-form-input>
      </b-col>
      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3">
        <span class="d-block h5 font-weight-normal">Geos</span>
        <multiselect
          v-model="filterForm.geos"
          :options="countryOptions"
          track-by="value"
          :custom-label="countryWithCode"
          @search-change="countryMultiSelectSearch"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-col>
      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <span class="d-block h5 font-weight-normal">Sort by</span>
        <b-form-select
          v-model="filterForm.sortBy"
          :options="sortByOptions"
          class="mb-2 d-block h5 font-weight-normal"
        ></b-form-select>
        <b-form-radio-group
          id="btn-radios-1"
          size="md"
          button-variant="outline-primary"
          v-model="filterForm.sortOrder"
          :options="sortOrderOptions"
          name="radios-btn-default"
          buttons
        ></b-form-radio-group>
      </b-col>
      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <b-button
          @click="applyFilter()"
          :disabled="!canApplyFilter"
          variant="primary"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Apply filter
        </b-button>
        <b-button
          @click="clearFilterForm()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Clear filter
        </b-button>
      </b-col>
    </b-row>

    <b-table
      class="table mb-3"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(_id)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <!--          <b-button @click="data.toggleDetails">-->
          <!--            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details-->
          <!--          </b-button>-->
          <b-button variant="primary" v-b-modal="`modal-edit-bce-preset-${data.item._id}`">
            ✍️
          </b-button>
          <b-modal
            :id="`modal-edit-bce-preset-${data.item._id}`"
            centered
            hide-footer
            no-close-on-esc
          >
            <BCEPresetCreate
              :_id="data.item._id"
              @bcePresetSaved="updateItem(data.item._id, $event)"
            />
            <!--            @bcePresetSaved="updateData()"-->
          </b-modal>
          <b-button
            variant="danger"
            v-b-modal="`modal-remove-bce-preset-${data.item._id}`"
            v-if="isAdmin"
          >
            <b-icon icon="trash-fill"></b-icon>
          </b-button>
          <b-modal
            v-if="isAdmin"
            :id="`modal-remove-bce-preset-${data.item._id}`"
            centered
            @ok="deleteBCEPreset(data.item._id, data.item.geo)"
            ok-variant="danger"
            cancel-variant="success"
            ok-title="Yes, Delete!"
            cancel-title="No, Go Back To BCEPresets"
            footer-class="centered"
            hide-header
            lazy
            :ok-disabled="requesting"
          >
            Are you sure you want to
            <b>DELETE</b>
            BCEPreset
            <b>{{ data.item.geo }}</b>
          </b-modal>
        </b-button-group>
      </template>

      <template #cell(active)="data">
        <h5>
          <b-badge :variant="data.value === true ? 'success' : 'danger'">
            {{ data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
        <!--        class="badge-toggle"-->
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <!--      <template #cell(leads)="data">-->
      <!--        <span v-b-popover.hover.top="getEmailsForPopover(data)">-->
      <!--          {{ data.value }}-->
      <!--        </span>-->
      <!--      </template>-->

      <!--      <template #row-details="row">-->
      <!--        <b-card-group columns>-->
      <!--          <b-card v-if="detailAvailable('affiliates') && !isAffiliate">-->
      <!--            <b-card-title>Affiliates</b-card-title>-->
      <!--            <b-list-group v-if="row.item.affiliates">-->
      <!--              <b-list-group-item v-for="(b, index) in row.item.affiliates" v-bind:key="index">-->
      <!--                &lt;!&ndash;                <b-badge variant="info" class="mx-1">&ndash;&gt;-->
      <!--                {{ b.name }} {{ b.email }}-->
      <!--                &lt;!&ndash;                </b-badge>&ndash;&gt;-->
      <!--                &lt;!&ndash;                <b-badge variant="info" class="mx-1">{{ b.email }}</b-badge>&ndash;&gt;-->
      <!--                <router-link :to="`/user/edit/${b._id}`">-->
      <!--                  Edit-->
      <!--                  &lt;!&ndash;                  <b-badge variant="outline-primary" class="mx-1"></b-badge>&ndash;&gt;-->
      <!--                </router-link>-->
      <!--              </b-list-group-item>-->
      <!--            </b-list-group>-->
      <!--          </b-card>-->
      <!--        </b-card-group>-->
      <!--        <p v-if="detailAvailable('_id')">_id: {{ row.item._id }}</p>-->
      <!--      </template>-->
    </b-table>
    <b-row class="mb-3" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable no-nested-ternary */
import { mapState } from 'vuex';
import _ from 'lodash';
// import { DateTime } from 'luxon';
import {
  countryOptions,
  // datepickerFormat,
  TRUSTED_USERS,
  dateFormatOptions,
  OTHER_LEVEL_TRUSTED_USERS,
} from '@/consts';
import {
  countryMultiSelectSearch,
  countryWithCode,
  handleRequestError,
  showCard,
  tableFormatterCapOneCell,
  tableFormatterDays,
  tableFormatterHourLimit,
  tableFormatterId,
  // showCard,
  tableFormatterNameAndId,
} from '@/utils';
import { startCase } from '@/utils/string';
import { getBCEPresets, deleteBCEPreset } from '@/utils/api';
import '@/assets/badge-toogle.css';
import '@/assets/breakdown-data.css';
import BCEPresetCreate from './BCEPresetCreate.vue';

const initialFilterForm = {
  _id: undefined,
  geos: [],
  sortBy: '_id',
  sortOrder: 'desc',
};

export default {
  name: 'BCEPresetTable',
  components: { BCEPresetCreate },
  data() {
    return {
      dateFormatOptions,
      countryOptions,
      pspType: 'all',

      fields: [
        { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
        { key: 'geo', sortable: true },
        { key: 'controls', sortable: true },
        { key: 'active', sortable: true },
        {
          key: 'dailyCap',
          sortable: true,
          formatter: (value) => tableFormatterCapOneCell(value),
        },
        { key: 'defaultPayment', sortable: true },

        {
          key: 'hourLimit',
          sortable: true,
          formatter: (value) => tableFormatterHourLimit(value),
        },
        {
          key: 'days',
          sortable: true,
          formatter: (value) => tableFormatterDays(value),
        },
        { key: 'priority', sortable: true },
        { key: 'weight', sortable: true },
      ],

      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 500, text: '500' },
        { value: 1000, text: '1000' },
      ],
      filterForm: _.cloneDeep(initialFilterForm),
      appliedFilterForm: _.cloneDeep(initialFilterForm),
      lastTimeToProcess: 0,
      sortOrderOptions: [
        { text: 'Asc', value: 'asc' },
        { text: 'Desc', value: 'desc' },
      ],
      selectedColumns: [],
      preSelectedColumns: [],
      selectedDetails: [],
      preSelectedDetails: [],
      sidebarColumnsOpen: false,
      sidebarDetailsOpen: false,
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
    };
  },
  computed: {
    ...mapState([
      'user',
      'isAdmin',
      'isManager',
      'isAffiliate',
      'token',
      'affiliates',
      'campaigns',
      'boxes',
      'requesting',
    ]),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    sortByOptions() {
      return this.fields.map((field) => {
        return {
          text: field.label ? field.label : startCase(field.key ? field.key : String(field)),
          value: field.key,
        };
      });
    },
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    canApplyFilter() {
      return !this.requesting && !_.isEqual(this.appliedFilterForm, this.filterForm);
    },
  },
  mounted() {
    this.$store.dispatch('get_all_resources_one_request', { vm: this });
    this.updateData();
  },
  methods: {
    countryWithCode(c) {
      return countryWithCode(c);
    },
    countryMultiSelectSearch,
    deleteBCEPreset(_id, geo) {
      this.$store.commit('make_requesting', { req: true });
      deleteBCEPreset(this.token, _id)
        .then(() => {
          showCard(this, `BCEPreset ${geo} deleted`, 'success');
          this.$store.commit('make_requesting', { req: false });
          this.updateData();
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on deleting BCEPreset ${geo}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    updateItem(id, newAction) {
      if (newAction) {
        const clonedItems = _.cloneDeep(this.items);
        const itemIndex = clonedItems.findIndex(({ _id }) => _id === id);
        const { _showDetails } = clonedItems[itemIndex];
        clonedItems[itemIndex] = newAction;
        if (_showDetails) clonedItems[itemIndex]._showDetails = _showDetails;
        this.items = clonedItems;
        this.$store.commit('make_requesting', { req: false });
      } else {
        this.updateData();
      }
    },
    applyFilter() {
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    clearFilterForm() {
      this.filter = null;
      this.filterForm = _.cloneDeep(initialFilterForm);
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    updateBackdoor() {
      console.log('backdoor updated');
      this.backdoor += 1;
    },
    _updateLastTimeToProcess(t) {
      this.lastTimeToProcess = t;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateData() {
      this.$store.commit('make_requesting', { req: true });
      const appliedFilterForm = _.cloneDeep(this.appliedFilterForm);
      const options = {
        type: this.pspType,
        ..._.cloneDeep(appliedFilterForm),
        page: this.currentPage,
        itemsPerPage: this.perPage,
      };
      console.log('options', options);
      if (options.affiliates)
        options.affiliates = options.affiliates.map((c) => (c._id ? c._id : c));

      // if (options.from) {
      //   options.from = DateTime.fromFormat(options.from, datepickerFormat, { zone: 'utc' });
      //   options.from = options.from.isValid
      //     ? options.from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
      //     : undefined;
      // }

      // if (options.to) {
      //   options.to = DateTime.fromFormat(options.to, datepickerFormat, { zone: 'utc' });
      //   options.to = options.to.isValid ? options.to.toISO() : undefined;
      // }

      getBCEPresets(this.token, options)
        .then((r) => {
          this.$store.commit('make_requesting', { req: true });
          const { data } = r;
          console.log('got data; items length', data.items.length);
          if (Array.isArray(data.items))
            data.items.forEach(
              // eslint-disable-next-line no-return-assign,no-param-reassign
              (c) => (c.days = c.days.map((d, i) => (d ? i : false)).filter((d) => d !== false))
            );
          this.items = _.cloneDeep(data.items);
          console.log('put items in table; table items length', this.items.length);
          if (this.currentPage !== data.page) this.currentPage = _.cloneDeep(data.page);
          if (this.perPage !== data.itemsPerPage) this.perPage = _.cloneDeep(data.itemsPerPage);
          this.totalRows = _.cloneDeep(data.totalRows);
          this.filterForm.sortBy = _.cloneDeep(data.sortBy);
          this.appliedFilterForm.sortBy = _.cloneDeep(data.sortBy);
          this.filterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.appliedFilterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    tableFormatterNameAndId(value) {
      return tableFormatterNameAndId(value);
    },
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    pspType() {
      if (!this.$store.state.requesting) this.updateData();
    },
    $route() {
      this.$store.dispatch('get_all_resources_one_request', { vm: this });
      this.updateData();
    },
  },
};
</script>

<style scoped>
.table >>> th,
.table >>> th > *,
.table >>> td,
.table >>> td > * {
  white-space: nowrap !important;
}
.table >>> .card-columns {
  white-space: initial !important;
}
.table >>> .with-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
