<template>
  <div>
    <div class="my-2">
      <p>
        FinalReceivers for client
        <b>{{ client.name }}</b>
        <!--      <b-form-checkbox v-model="copyForm.m" class="mx-2 my-1">Send as M</b-form-checkbox>-->
      </p>
      <p>
        <i>Client's API URL: {{ client.brokerApiURL }}</i>
      </p>
      <b-button
        @click="getFinalReceiversByURL()"
        :disabled="requesting"
        variant="primary"
        class="my-1"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span v-else>🔄 Refresh List</span>
      </b-button>
    </div>
    <div>
      <ul>
        <li v-for="(r, i) in receivers" :key="i">{{ r.hostname }}: {{ r.lastTimeSeen }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getFinalReceiversByURL } from '@/utils/api';
import showCard from '@/utils/showCard';
import { handleRequestError, formatDatesInKey } from '@/utils';

export default {
  name: 'SendManualNodeFinalReceiversList',
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { requesting: false, receivers: [] };
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    getFinalReceiversByURL() {
      if (!this.client || !this.client.brokerApiURL) {
        showCard(this, "No Clients's URL provided", 'danger');
        return;
      }
      this.requesting = true;
      getFinalReceiversByURL(this.token, { brokerApiURL: this.client.brokerApiURL })
        .then((r) => {
          this.receivers = formatDatesInKey(r.data, 'lastTimeSeen');
          showCard(this, `Got FinalReceivers list for ${this.client.name}`);
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Unknown error getting FinalReceivers list for ${this.client.name}`
          );
          this.requesting = false;
        });
    },
  },
  created() {
    this.getFinalReceiversByURL();
  },
};
</script>

<style scoped></style>
