<template>
  <b-container fluid>
    <b-row class="my-3" no-gutters>
      <b-col cols="12" lg="2" class="my-lg-0 my-1">
        <h2>PSP</h2>
      </b-col>
      <b-col cols="12" lg="1" class="my-lg-0 my-1">
        <b-button variant="primary" v-b-modal="'modal-create-psp'">Create</b-button>
        <b-modal id="modal-create-psp" centered hide-footer no-close-on-esc @hide="updateData()">
          <PSPCreate />
        </b-modal>
      </b-col>
      <!--lg="2"-->
      <!--      <b-col-->
      <!--        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <b-input-group>-->
      <!--          <b-input-group-prepend is-text>-->
      <!--            <b-icon icon="search"></b-icon>-->
      <!--          </b-input-group-prepend>-->
      <!--          <b-form-input-->
      <!--            v-model="filter"-->
      <!--            type="search"-->
      <!--            id="filterInput"-->
      <!--            placeholder="Type to Search"-->
      <!--          ></b-form-input>-->
      <!--          <b-input-group-append>-->
      <!--            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
      <!--          </b-input-group-append>-->
      <!--        </b-input-group>-->
      <!--      </b-col>-->
      <b-col
        class="mx-auto my-lg-0 my-1 d-flex align-items-center justify-content-center"
        cols="12"
        lg="4"
      >
        <b-form-radio-group
          id="btn-radios-1"
          size="md"
          button-variant="outline-primary"
          v-model="pspType"
          :options="pspTypeOptions"
          name="radios-btn-default"
          buttons
          :disabled="requesting"
        ></b-form-radio-group>
      </b-col>
      <b-col cols="12" lg="3" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto mr-3"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
      <b-col cols="12" lg="2" class="my-lg-0 my-1 d-flex align-items-center justify-content-center">
        <JsonExcel
          class="m-2"
          :data="items"
          :fields="fieldsToExport"
          type="csv"
          :stringifyLongNum="true"
          v-if="iAmOtherLevelTrustedUser"
        >
          <b-button variant="outline-primary">📥CSV</b-button>
        </JsonExcel>
        <b-button @click="updateData()" :disabled="requesting" class="mx-2">
          <b-spinner small v-if="requesting"></b-spinner>
          <span v-else>🔄</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2 mb-3">
      <b-col
        class="col-12 col-12 col-md-6 col-lg-4 mb-3"
        v-if="affiliates && affiliates.length > 0 && !isAffiliate"
      >
        <span class="d-block h5 font-weight-normal">Affiliates</span>
        <multiselect
          id="affiliates"
          v-model="filterForm.affiliates"
          :options="affiliatesOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-col>

      <!--      <b-col class="col-12 col-12 col-md-6 col-lg-4 mb-3">-->
      <!--        <span class="d-block h5 font-weight-normal">Date</span>-->
      <!--        <div class="d-sm-flex align-items-center">-->
      <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
      <!--            <span class="mr-sm-2">From</span>-->
      <!--            <b-form-datepicker-->
      <!--              :date-format-options="dateFormatOptions"-->
      <!--              id="date-from"-->
      <!--              today-button-->
      <!--              reset-button-->
      <!--              close-button-->
      <!--              :max="filterForm.to"-->
      <!--              v-model="filterForm.from"-->
      <!--            ></b-form-datepicker>-->
      <!--            &lt;!&ndash;                          @input="updateBackdoor()"-->
      <!--&ndash;&gt;-->
      <!--          </div>-->
      <!--          <div class="d-sm-flex align-items-sm-center flex-sm-fill">-->
      <!--            <span class="mx-sm-2">To</span>-->
      <!--            <b-form-datepicker-->
      <!--              :date-format-options="dateFormatOptions"-->
      <!--              id="date-to"-->
      <!--              today-button-->
      <!--              reset-button-->
      <!--              close-button-->
      <!--              :min="filterForm.from"-->
      <!--              v-model="filterForm.to"-->
      <!--            ></b-form-datepicker>-->
      <!--            &lt;!&ndash;                          @input="updateBackdoor()"-->
      <!--&ndash;&gt;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">ID</span>
        <b-form-input v-model="filterForm._id"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>
      <b-col class="col-12 col-12 col-md-3 col-lg-2">
        <span class="d-block h5 font-weight-normal mt-1">Number</span>
        <b-form-input v-model="filterForm.number"></b-form-input>
        <!--        @input="updateBackdoor()"-->
      </b-col>

      <!--      <b-col class="col-12 col-12 col-md-3 col-lg-2">-->
      <!--        <span class="d-block h5 font-weight-normal mt-1">Lead Email</span>-->
      <!--        <b-form-input v-model="filterForm.lead.email"></b-form-input>-->
      <!--        &lt;!&ndash;        @input="updateBackdoor()"&ndash;&gt;-->
      <!--      </b-col>-->
      <!--      <b-col class="col-12 col-12 col-md-3 col-lg-2">-->
      <!--        <span class="d-block h5 font-weight-normal mt-1">Lead Phone</span>-->
      <!--        <b-form-input v-model="filterForm.lead.phone"></b-form-input>-->
      <!--        &lt;!&ndash;        @input="updateBackdoor()"&ndash;&gt;-->
      <!--      </b-col>-->

      <!--      <b-col class="col-12 col-12 col-md-3 col-lg-2">-->
      <!--        <span class="d-block h5 font-weight-normal mt-1">Lead ID</span>-->
      <!--        <b-form-input v-model="filterForm.lead._id"></b-form-input>-->
      <!--        &lt;!&ndash;        @input="updateBackdoor()"&ndash;&gt;-->
      <!--      </b-col>-->

      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <span class="d-block h5 font-weight-normal">Sort by</span>
        <b-form-select
          v-model="filterForm.sortBy"
          :options="sortByOptions"
          class="mb-2 d-block h5 font-weight-normal"
        ></b-form-select>
        <b-form-radio-group
          id="btn-radios-1"
          size="md"
          button-variant="outline-primary"
          v-model="filterForm.sortOrder"
          :options="sortOrderOptions"
          name="radios-btn-default"
          buttons
        ></b-form-radio-group>
      </b-col>
      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <b-button
          @click="applyFilter()"
          :disabled="!canApplyFilter"
          variant="primary"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Apply filter
        </b-button>
        <b-button
          @click="clearFilterForm()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
          :disabled="requesting"
        >
          <b-spinner small v-if="requesting"></b-spinner>
          Clear filter
        </b-button>
      </b-col>
      <b-col class="col-12 col-12 col-md-6 col-lg-4">
        <b-button
          v-b-toggle.sidebar-right-table
          @click="closeSidebarDetails()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          Edit Table
        </b-button>
        <b-button
          v-b-toggle.sidebar-right-details
          @click="closeSidebarColumns()"
          class="mb-4 h5 font-weight-normal mt-5 mx-2"
        >
          Edit Details
        </b-button>
        <b-sidebar
          id="sidebar-right-table"
          v-model="sidebarColumnsOpen"
          title="Table Fields"
          right
          shadow
        >
          <div class="px-3 py-2">
            <b-form-checkbox-group
              v-model="preSelectedColumns"
              :options="tableFieldsOptions"
              class="mb-3"
              @input="logSelectedColumns()"
              stacked
            ></b-form-checkbox-group>
            <b-button
              variant="primary"
              @click="applyFields()"
              :disabled="!canApplyFields"
              class="my-2 sidebar-button"
            >
              Apply
            </b-button>
            <b-button
              variant="success"
              @click="saveSelectedFields('psp_table')"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Save
            </b-button>
            <b-button
              variant="warning"
              @click="restoreTablePreference()"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Restore
            </b-button>
          </div>
        </b-sidebar>
        <b-sidebar
          id="sidebar-right-details"
          v-model="sidebarDetailsOpen"
          title="Show Details"
          right
          shadow
        >
          <div class="px-3 py-2">
            <b-form-checkbox-group
              v-model="preSelectedDetails"
              :options="detailsOptions"
              class="mb-3"
              @input="logSelectedDetails()"
              stacked
            ></b-form-checkbox-group>
            <b-button
              variant="primary"
              @click="applyDetails()"
              :disabled="!canApplyDetails"
              class="my-2 sidebar-button"
            >
              Apply
            </b-button>
            <b-button
              variant="success"
              @click="saveSelectedFields('psp_details')"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Save
            </b-button>
            <b-button
              variant="warning"
              @click="restoreDetailsPreference()"
              :disabled="requesting"
              class="my-2 sidebar-button"
            >
              <b-spinner small v-if="requesting"></b-spinner>
              Restore
            </b-button>
          </div>
        </b-sidebar>
      </b-col>
      <!--      :disabled="!canApplyFilter"-->
    </b-row>

    <b-table
      class="table mb-3"
      :fields="sortedSelectedFields"
      :items="items"
      :per-page="perPage"
      :current-page="1"
      :filter="filter"
      responsive
      show-empty
      striped
      hover
      primary-key="_id"
    >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title="data.field.tooltip" v-if="data.field.tooltip">
          {{ data.label }}
        </span>
        <span v-else>{{ data.label }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(_id)="data">
        <span v-b-popover.hover.top="data.unformatted">{{ data.value }}</span>
      </template>

      <template #cell(controls)="data">
        <b-button-group size="sm">
          <b-button @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
          <b-button variant="primary" v-b-modal="`modal-edit-psp-${data.item._id}`">✍️</b-button>
          <b-modal :id="`modal-edit-psp-${data.item._id}`" centered hide-footer no-close-on-esc>
            <!--            @hide="updateData()"-->
            <!--            <PSPCreate :_id="data.item._id" @pspSaved="updateData()" />-->
            <PSPCreate :_id="data.item._id" @pspSaved="updateItem(data.item._id, $event)" />
          </b-modal>
          <b-button variant="danger" v-b-modal="`modal-remove-psp-${data.item._id}`" v-if="isAdmin">
            <b-icon icon="trash-fill"></b-icon>
          </b-button>
          <b-modal
            v-if="isAdmin"
            :id="`modal-remove-psp-${data.item._id}`"
            centered
            @ok="deletePSP(data.item._id, data.item.number)"
            ok-variant="danger"
            cancel-variant="success"
            ok-title="Yes, Delete!"
            cancel-title="No, Go Back To PSPs"
            footer-class="centered"
            hide-header
            lazy
            :ok-disabled="requesting"
          >
            Are you sure you want to
            <b>DELETE</b>
            PSP
            <b>{{ pspFormatterFullCardNumber(data.item.type, data.item.number) }}</b>
          </b-modal>
        </b-button-group>
      </template>

      <template #cell(number)="data">
        <span v-b-popover.hover.top="data.unformatted">
          {{ pspFormatterFullCardNumber(data.item.type, data.item.number) }}
        </span>
      </template>

      <template #cell(affiliates)="data">
        <span v-b-popover.hover.top="getAffiliatesForPopover(data)">
          {{ data.value }}
        </span>
      </template>

      <template #cell(leads)="data">
        <!--        <span v-b-popover.hover.top="getEmailsForPopover(data)">-->
        {{ data.value }}
        <!--        </span>-->
      </template>

      <template #cell(available)="data">
        <h5 class="mb-0">
          <b-badge
            class="badge-toggle"
            :variant="data.value === 'true' || data.value === true ? 'success' : 'danger'"
            @click="togglePSPAvailable(data.item._id)"
          >
            {{ data.value === 'true' || data.value === true ? 'Yes' : 'No' }}
          </b-badge>
        </h5>
      </template>

      <template #cell(ftds)="data">
        <b-dropdown dropleft no-caret size="sm" boundary="window" lazy class="mx-1">
          <template #button-content size="sm">
            <b-icon icon="pencil-square"></b-icon>
          </template>
          <PSPEditFtdsAmount
            :_id="data.item._id"
            @pspFtdsSaved="updateItem(data.item._id, $event, ['ftds', 'available'])"
            style="max-width: 500px"
          />
        </b-dropdown>
        {{ data.value }}
      </template>

      <template #row-details="row">
        <PSPTableDetails :_id="row.item._id" :sorted-selected-details="sortedSelectedDetails" />
      </template>
    </b-table>
    <b-row class="mb-3" align-v="center">
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <b-pagination
          class="mr-3 mb-0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="requesting"
        ></b-pagination>
      </b-col>
      <b-col
        class="mx-auto my-md-0 my-1 d-flex align-items-center justify-content-center"
        md="6"
        cols="12"
      >
        <b-spinner small v-if="requesting"></b-spinner>
        <span class="text-nowrap mr-3">Items per page</span>
        <b-form-select
          class="w-auto"
          size="sm"
          v-model="perPage"
          :options="options"
          :disabled="requesting"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable no-nested-ternary */
import { mapState } from 'vuex';
import _ from 'lodash';
// import { DateTime } from 'luxon';
import {
  countryOptions,
  // datepickerFormat,
  TRUSTED_USERS,
  dateFormatOptions,
  pspTypeOptions,
  OTHER_LEVEL_TRUSTED_USERS,
} from '@/consts';
import { userAvailableFields, userAvailableDetails } from '@/consts/userAvailableFields';
import {
  handleRequestError,
  showCard,
  tableFormatterFullCardNumber,
  // showCard,
  tableFormatterNameAndId,
} from '@/utils';
import {
  tableFieldsOptions,
  sortedSelectedFields,
  detailsOptions,
  sortedSelectedDetails,
  saveSelectedFieldsMethod,
  takeDefaultTableFields,
  takeDefaultDetailsOptions,
  applyFields,
  applyDetails,
  canApplyFields,
  canApplyDetails,
} from '@/utils/fieldsAndDetailsOptionsHelpers';
import { startCase } from '@/utils/string';
import { getPSPs, deletePSP, togglePSPAvailable } from '@/utils/api';
import '@/assets/badge-toogle.css';
import '@/assets/breakdown-data.css';
import JsonExcel from 'vue-json-excel';
import PSPEditFtdsAmount from '@/components/pages/PSP/PSPEditFtdsAmount.vue';
import PSPTableDetails from '@/components/pages/PSP/PSPTableDetails.vue';
import PSPCreate from './PSPCreate.vue';

const initialFilterForm = {
  // from: DateTime.utc().startOf('year').toFormat(datepickerFormat),
  // to: DateTime.utc().toFormat(datepickerFormat),
  _id: undefined,
  affiliates: undefined,
  number: undefined,
  // type: 'all',
  lead: { email: undefined, phone: undefined, _id: undefined },
  sortBy: '_id',
  sortOrder: 'desc',
};

export default {
  name: 'PSPTable',
  components: { PSPTableDetails, PSPEditFtdsAmount, JsonExcel, PSPCreate },
  data() {
    return {
      dateFormatOptions,
      countryOptions,
      pspType: 'all',
      pspTypeOptions,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 500, text: '500' },
        { value: 1000, text: '1000' },
      ],
      filterForm: _.cloneDeep(initialFilterForm),
      appliedFilterForm: _.cloneDeep(initialFilterForm),
      lastTimeToProcess: 0,
      sortOrderOptions: [
        { text: 'Asc', value: 'asc' },
        { text: 'Desc', value: 'desc' },
      ],
      selectedColumns: [],
      preSelectedColumns: [],
      selectedDetails: [],
      preSelectedDetails: [],
      sidebarColumnsOpen: false,
      sidebarDetailsOpen: false,
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      // editingField: '',
      // editingItemId: '',
      // editingItemIndex: -1,
    };
  },
  computed: {
    ...mapState([
      'user',
      'isAdmin',
      'isManager',
      'isAffiliate',
      'isMasterAffiliate',
      'token',
      'affiliates',
      'campaigns',
      'boxes',
      'requesting',
    ]),
    iAmTrustedUser() {
      return TRUSTED_USERS.includes(this.user.email);
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    fieldsToExport() {
      return this.iAmOtherLevelTrustedUser
        ? {
            id: '_id',
            Type: 'type',
            Number: 'number',
            Affiliates: 'affiliates.length',
            Leads: 'leads.length',
          }
        : {};
    },
    canApplyFields() {
      return canApplyFields(this);
    },
    canApplyDetails() {
      return canApplyDetails(this);
    },
    userAvailableFields() {
      if (this.isAdmin) return userAvailableFields.psp.admin();
      if (this.isManager) return userAvailableFields.psp.manager();
      if (this.isMasterAffiliate) return userAvailableFields.psp.masteraffiliate();
      return userAvailableFields.psp.affiliate();
    },
    tableFieldsOptions() {
      return tableFieldsOptions(this);
    },
    sortedSelectedFields() {
      return sortedSelectedFields(this);
    },
    userAvailableDetails() {
      if (this.isAdmin) return userAvailableDetails.psp.admin();
      if (this.isManager) return userAvailableDetails.psp.manager();
      if (this.isMasterAffiliate) return userAvailableDetails.psp.manager();
      return userAvailableDetails.psp.affiliate();
    },
    detailsOptions() {
      return detailsOptions(this);
    },
    sortedSelectedDetails() {
      return sortedSelectedDetails(this);
    },
    sortByOptions() {
      return this.userAvailableFields.map((field) => {
        return {
          text: field.label ? field.label : startCase(field.key ? field.key : String(field)),
          value: field.key,
        };
      });
    },
    affiliatesOptions() {
      return this.affiliates.map((a) => {
        return { name: a.name, _id: a._id };
      });
    },
    canApplyFilter() {
      return !this.requesting && !_.isEqual(this.appliedFilterForm, this.filterForm);
    },
  },
  mounted() {
    this.$store.dispatch('get_all_resources_one_request', { vm: this });
    this.updateData();
  },
  methods: {
    // editFieldOn(e, field, i, elementIdToSelect) {
    //   e.stopPropagation();
    //   if (this.editingField === field && this.editingItem === i) return;
    //   this.editingField = field;
    //   this.editingItemId = i;
    //   this.editingItemIndex = this.items.findIndex((item) => item._id === this.editingItemId);
    //   this.editingFieldValue = this.items.find((item) => item._id === i)[this.editingField];
    //   this.$nextTick(() => {
    //     const el = document.getElementById(elementIdToSelect);
    //     if (el) el.select();
    //   });
    // },
    // editFieldOff() {
    //   if (this.editingItemIndex >= 0) {
    //     this.items[this.editingItemIndex][this.editingField] = this.editingFieldValue;
    //   }
    //   this.editingField = '';
    //   this.editingItemId = '';
    // },

    togglePSPAvailable(_id) {
      this.$store.commit('make_requesting', { req: true });
      togglePSPAvailable(this.token, { psp_id: _id })
        .then((r) => {
          if (r.data.success) {
            this.updateItem(_id, r.data, ['available']);
          } else {
            const cardError =
              r.data.message ||
              `${r.status}: Unknown error on toggling Available for PSP ${this._id}`;
            showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
          }
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown error on saving FTDs Available for PSP ${this._id}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    deletePSP(_id, number) {
      this.$store.commit('make_requesting', { req: true });
      deletePSP(this.token, _id)
        .then(() => {
          showCard(this, `PSP ${number} deleted`, 'success');
          this.$store.commit('make_requesting', { req: false });
          this.updateData();
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on deleting PSP ${number}`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    pspFormatterFullCardNumber(type, number) {
      return type === 'card' ? tableFormatterFullCardNumber(number) : number;
    },
    getEmailsForPopover(data) {
      function getEmail(d) {
        return d && d.leadContactInfo && d.leadContactInfo.email && d.leadContactInfo.email !== ''
          ? d.leadContactInfo.email
          : undefined;
      }
      const unformatted = _.cloneDeep(data.unformatted);
      const dots = unformatted.length > 7 ? '...' : '';
      return Array.isArray(unformatted)
        ? unformatted
            .slice(0, 7)
            .map((d) => getEmail(d))
            .filter((e, i) => !!e && unformatted.findIndex((d) => getEmail(d) === e) === i)
            .join(', ') + dots
          ? '...'
          : ''
        : '';
    },
    getAffiliatesForPopover(data) {
      console.log('getAffiliatesForPopover', data.unformatted);
      function getAffiliate(d) {
        return d && d.name && d.name !== '' ? d.name : undefined;
      }
      const unformatted = _.cloneDeep(data.unformatted);
      const dots = unformatted.length > 7 ? '...' : '';
      return Array.isArray(unformatted)
        ? _.uniq(
            unformatted.slice(0, 7).map((d) => getAffiliate(d))
            // .filter((e, i) => !!e && unformatted.findIndex((d) => getAffiliate(d) === e) === i)
          ).join(', ') + dots
        : '';
    },
    applyFields() {
      applyFields(this);
    },
    applyDetails() {
      applyDetails(this);
    },
    saveSelectedFields(type) {
      saveSelectedFieldsMethod(this, type);
    },
    takeDefaultTableFields() {
      takeDefaultTableFields(this, 'pspTableSelectedFields');
    },
    takeDefaultDetailsOptions() {
      takeDefaultDetailsOptions(this, 'pspTableSelectedDetails');
    },
    restoreTablePreference() {
      this.takeDefaultTableFields();
      this.saveSelectedFields('psp_table');
    },
    restoreDetailsPreference() {
      this.takeDefaultDetailsOptions();
      this.saveSelectedFields('psp_details');
    },
    closeSidebarColumns() {
      this.sidebarColumnsOpen = false;
    },
    closeSidebarDetails() {
      this.sidebarDetailsOpen = false;
    },
    detailAvailable(field) {
      return this.sortedSelectedDetails.includes(field);
    },
    logSelectedColumns() {
      console.log(this.selectedColumns);
    },
    logSelectedDetails() {
      console.log(this.selectedDetails);
    },
    updateItem(id, newAction, fields = []) {
      if (newAction) {
        const clonedItems = _.cloneDeep(this.items);
        const itemIndex = clonedItems.findIndex(({ _id }) => _id === id);
        const { _showDetails } = clonedItems[itemIndex];
        if (fields.length > 0) {
          Object.keys(newAction).forEach((k) => {
            if (fields.includes(k)) clonedItems[itemIndex][k] = newAction[k];
          });
        } else {
          clonedItems[itemIndex] = newAction;
        }
        if (_showDetails) clonedItems[itemIndex]._showDetails = _showDetails;
        this.items = clonedItems;
        this.$store.commit('make_requesting', { req: false });
      } else {
        this.updateData();
      }
    },
    applyFilter() {
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    clearFilterForm() {
      this.filter = null;
      this.filterForm = _.cloneDeep(initialFilterForm);
      this.appliedFilterForm = _.cloneDeep(this.filterForm);
      this.updateData();
    },
    updateBackdoor() {
      console.log('backdoor updated');
      this.backdoor += 1;
    },
    _updateLastTimeToProcess(t) {
      this.lastTimeToProcess = t;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateData() {
      this.$store.commit('make_requesting', { req: true });
      const appliedFilterForm = _.cloneDeep(this.appliedFilterForm);
      const options = {
        type: this.pspType,
        ..._.cloneDeep(appliedFilterForm),
        page: this.currentPage,
        itemsPerPage: this.perPage,
      };
      console.log('options', options);
      if (options.affiliates)
        options.affiliates = options.affiliates.map((c) => (c._id ? c._id : c));

      // if (options.from) {
      //   options.from = DateTime.fromFormat(options.from, datepickerFormat, { zone: 'utc' });
      //   options.from = options.from.isValid
      //     ? options.from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO()
      //     : undefined;
      // }

      // if (options.to) {
      //   options.to = DateTime.fromFormat(options.to, datepickerFormat, { zone: 'utc' });
      //   options.to = options.to.isValid ? options.to.toISO() : undefined;
      // }

      getPSPs(this.token, options)
        .then((r) => {
          this.$store.commit('make_requesting', { req: true });
          const { data } = r;
          console.log('got data; items length', data.items.length);
          if (this.pspType !== data.type) this.pspType = _.cloneDeep(data.type);
          this.items = _.cloneDeep(data.items);
          console.log('put items in table; table items length', this.items.length);
          if (this.currentPage !== data.page) this.currentPage = _.cloneDeep(data.page);
          if (this.perPage !== data.itemsPerPage) this.perPage = _.cloneDeep(data.itemsPerPage);
          this.totalRows = _.cloneDeep(data.totalRows);
          this.filterForm.sortBy = _.cloneDeep(data.sortBy);
          this.appliedFilterForm.sortBy = _.cloneDeep(data.sortBy);
          this.filterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.appliedFilterForm.sortOrder = _.cloneDeep(data.sortOrder);
          this.$store.commit('make_requesting', { req: false });
        })
        .catch((e) => {
          handleRequestError(this, e, `Unknown server error on retrieving actions`);
          this.$store.commit('make_requesting', { req: false });
        });
    },
    tableFormatterNameAndId(value) {
      return tableFormatterNameAndId(value);
    },
  },
  watch: {
    perPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    currentPage() {
      if (!this.$store.state.requesting) this.updateData();
    },
    pspType() {
      if (!this.$store.state.requesting) this.updateData();
    },
    $route() {
      this.$store.dispatch('get_all_resources_one_request', { vm: this });
      this.updateData();
    },
  },
};
</script>

<style scoped>
.table >>> th,
.table >>> th > *,
.table >>> td,
.table >>> td > * {
  white-space: nowrap !important;
}
.table >>> .card-columns {
  white-space: initial !important;
}
.table >>> .with-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.sidebar-button {
  display: block;
  width: 100%;
}
</style>
