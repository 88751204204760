<template>
  <!--  <fragment>-->
  <!--    <Header />-->
  <BCEPresetTable />
  <!--  </fragment>-->
</template>

<script>
// import Header from '@/components/shared/Header.vue';
import BCEPresetTable from '@/components/pages/BCESettingsPreset/BCEPresetTable.vue';

export default {
  name: 'BCEPresetTableView',
  components: {
    // Header,
    // Sections,
    BCEPresetTable,
  },
};
</script>
