<template>
  <!--  <div class="wrapper">-->
  <side-bar>
    <template slot="links">
      <sidebar-item
        v-if="isAdmin"
        :link="{ name: 'Dashboard', icon: 'speedometer2', color: 'darkgreen', path: '/dashboard' }"
      ></sidebar-item>
      <sidebar-item
        v-if="loggedIn"
        :link="{
          name: 'CRM',
          icon: 'table',
          color: 'darkgreen',
          path: '/crm',
        }"
      ></sidebar-item>
      <sidebar-item
        v-if="user && !user.maff"
        :link="{
          name: 'Deposits',
          icon: 'cash-coin',
          color: 'darkgreen',
          path: '/deposits',
        }"
      ></sidebar-item>
      <sidebar-item
        v-if="isManager"
        :link="{ name: 'Campaigns', icon: 'box', color: 'blue', path: '/campaigns' }"
      ></sidebar-item>
      <sidebar-item v-if="isManager" :link="{ name: 'Test', icon: 'wifi', color: 'orange' }">
        <sidebar-item v-if="isManager" :link="{ name: 'Lead', path: '/test-lead' }" />
        <sidebar-item v-if="isManager" :link="{ name: 'Pulling', path: '/test-pulling' }" />
        <sidebar-item
          v-if="isAdmin"
          :link="{ name: 'GMLevelDistribution', path: '/test-gm-level-distribution' }"
        />
      </sidebar-item>
      <sidebar-item
        v-if="isAdmin"
        :link="{
          name: 'Upload Leads File',
          icon: 'cloud-upload-fill',
          color: 'orange',
          path: '/upload-leads-file',
        }"
      ></sidebar-item>
      <sidebar-item
        v-if="isAdmin"
        :link="{
          name: 'Injection',
          icon: 'shuffle',
          color: 'orange',
          path: '/injections',
        }"
      ></sidebar-item>
      <sidebar-item
        v-if="isAdmin || isMasterAffiliate"
        :link="{ name: 'Users', icon: 'person', color: 'darkblue', path: '/users' }"
      ></sidebar-item>
      <sidebar-item
        v-if="isManager"
        :link="{ name: 'Boxes', icon: 'bounding-box', color: 'blue', path: '/boxes' }"
      ></sidebar-item>
      <!--      <sidebar-item-->
      <!--        v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"-->
      <!--        :link="{-->
      <!--          name: 'System Data',-->
      <!--          icon: 'database',-->
      <!--          color: 'violet',-->
      <!--        }"-->
      <!--      >-->
      <sidebar-item
        v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
        :link="{ name: 'Logs', icon: 'clock-history', color: 'violet', path: '/work-history' }"
      />

      <sidebar-item v-if="!$rent" :link="{ name: 'Daughters', path: '/daughters' }" />
      <sidebar-item v-if="!$rent" :link="{ name: 'MetaCampaigns', path: '/metacampaigns' }" />
      <sidebar-item
        v-if="!$rent"
        :link="{
          name: 'FinalReceivers',
          icon: 'bullseye',
          color: 'violet',
          path: '/finalreceivers',
        }"
      />
      <sidebar-item
        v-if="!$rent"
        :link="{ name: 'Injection Send Results', path: '/injections-send-results' }"
      />
      <!--        <sidebar-item :link="{ name: 'Commands', path: '/system-commands' }" />-->
      <!--      </sidebar-item>-->
      <!--      <sidebar-item-->
      <!--        v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"-->
      <!--        :link="{-->
      <!--          name: 'System Settings',-->
      <!--          icon: 'tools',-->
      <!--          color: 'violet',-->
      <!--        }"-->
      <!--      >-->
      <sidebar-item
        v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email)"
        :link="{
          name: 'Box Settings',
          icon: 'tools',
          color: 'violet',
          path: '/bce-preset',
        }"
      />
      <!--      </sidebar-item>-->

      <sidebar-item
        v-if="isAffiliate && user && user.maff"
        :link="{ name: 'Geo Selection', icon: 'geo-alt', path: '/geo-selection' }"
      ></sidebar-item>

      <b-nav-item
        v-if="canPush && maffRegisterLink !== ''"
        :href="maffRegisterLink"
        target="_blank"
        clas="text-nowrap"
      >
        <!--        <b-icon icon="table" class="mr-2" :style="{ color: 'forestgreen' }"></b-icon>-->
        <i :class="[`bi-plus-square`, 'mr-2']" :style="{ color: 'orange' }"></i>
        <span class="nav-link-text text-nowrap">Add Lead</span>
      </b-nav-item>

      <sidebar-item
        v-if="isAffiliate || (user && user.integrator)"
        :link="{ name: 'Api Docs', icon: 'gear-wide-connected', path: '/affiliate-api-docs' }"
      ></sidebar-item>
      <sidebar-item
        v-if="
          (OTHER_LEVEL_TRUSTED_USERS.includes(user.email) || canPush) &&
          (servicename === 'grandmaster' || servicename === 'localgm') &&
          !$rent
        "
        :link="{
          name: 'PSP',
          icon: 'wallet',
          color: 'blue',
          path: '/psp',
        }"
      ></sidebar-item>
      <sidebar-item
        v-if="
          (OTHER_LEVEL_TRUSTED_USERS.includes(user.email) || hasWorkers) &&
          (servicename === 'grandmaster' || servicename === 'localgm') &&
          !$rent
        "
        :link="{
          name: 'Workers',
          icon: 'headset',
          color: 'blue',
          path: '/workers',
        }"
      ></sidebar-item>

      <sidebar-item
        v-if="OTHER_LEVEL_TRUSTED_USERS.includes(user.email) && !$rent"
        :link="{
          name: 'Finance',
          icon: 'bank',
          color: 'darkgreen',
        }"
      >
        <sidebar-item :link="{ name: 'Transactions', path: '/financial-transactions' }" />
        <sidebar-item :link="{ name: 'Balances', path: '/balances' }" />
      </sidebar-item>
    </template>
    <template slot="links-after"></template>
  </side-bar>
  <!--  </div>-->
</template>

<script>
import { DateTime } from 'luxon';
import * as metadata from '@/metadata.json';
import { OTHER_LEVEL_TRUSTED_USERS } from '@/consts';
import { mapState } from 'vuex';

export default {
  name: 'RentSidebar',
  data() {
    return {
      currentTime: DateTime.utc().toFormat('HH:mm'),
      metadata,
      servicename_as_is: process.env.VUE_APP_SERVICENAME,
      servicename: process.env.VUE_APP_SERVICENAME
        ? String(process.env.VUE_APP_SERVICENAME).toLowerCase()
        : undefined,
      ftds: undefined,
      showFtds: true,
      OTHER_LEVEL_TRUSTED_USERS,
    };
  },
  computed: {
    ...mapState([
      'loggedIn',
      'user',
      'isAdmin',
      'isManager',
      'isAffiliate',
      'isMasterAffiliate',
      'token',
    ]),
    canPush() {
      return this.user && (this.user.maff || this.user.canUploadLeads);
    },
    hasWorkers() {
      return this.user && this.user.hasWorkers;
    },
    maffRegisterLink() {
      console.log('maffRegisterLink user', this.user);
      if (process.env.VUE_APP_SERVICENAME.toLowerCase() !== 'grandmaster') return '';
      if (!(this.user && (this.user.maff || this.user.canUploadLeads))) return '';
      const regurl = process.env.VUE_APP_REGURL;
      const box =
        this.user.boxes && this.user.boxes.length > 0
          ? this.user.boxes[0]._id
            ? this.user.boxes[0]._id
            : this.user.boxes[0]
          : undefined;
      if (!regurl || !box) return '';
      return `${regurl}?key=${box}`;
    },
  },
};
</script>

<style scoped></style>
