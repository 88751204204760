<template>
  <!--  <div :class="`${isValid.form ? 'tab' : 'tab-invalid'}`">-->
  <div
    :class="{
      tab: isValid.form,
      'tab-invalid': !isValid.form,
      'tab-hover-without-child': isValid.form && hoverMe,
      'tab-invalid-hover-without-child': !isValid.form && hoverMe,
      'hover-without-child-target': true,
    }"
    v-hover-without-child="{ method: 'changeHoverMe' }"
  >
    <h6 class="mx-1 my-2">{{ name }}</h6>
    <b-form-group class="my-3" label="Type" label-for="datapreprocessing-type">
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
    :state="isValid.fields.name"-->
      <b-form-select
        id="datapreprocessing-type"
        v-model="form.type"
        :options="typeOptions"
        @input="handleInput"
      ></b-form-select>
      <!--      :state="isValid.fields.name"-->
    </b-form-group>
    <b-form-group
      class="my-3"
      label="Function"
      label-for="datapreprocessing-type"
      v-if="form.type === 'custom'"
    >
      <!--      :invalid-feedback="invalidFeedback.campaign.name"
    :state="isValid.fields.name"-->
      <!--      <b-form-textarea-->
      <!--        type="text"-->
      <!--        id="datapreprocessing-type"-->
      <!--        v-model="dataPreprocessing.function"-->
      <!--        @input="emitEditEvent"-->
      <!--      ></b-form-textarea>-->
      <code-mirror
        :dark="false"
        :editable="true"
        v-model="form.function"
        :lang="lang"
        :extensions="extensions"
        id="datapreprocessing-type"
        @update="handleInput"
      ></code-mirror>
      <!--      -->
      <!--      -->
      <!--      :state="isValid.fields.name"-->
    </b-form-group>
    <!--    <p>{{ form.function }}</p>-->
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import '@/assets/form.scss';
import { ref, defineComponent } from '@vue/composition-api';
import { javascript } from '@codemirror/lang-javascript';
import CodeMirror from 'vue-codemirror6';
import { basicSetup } from '@codemirror/basic-setup';
// import handleValidateEvent from '@/utils/handleValidateEvent';
import { formValid, isValid } from '@/validators';

// const data = ref('');
// eslint-disable-next-line no-unused-vars
// const lang = ref(javascript());
// const extensions = [ref([basicSetup])];

export default defineComponent({
  name: 'dataPreprocessingParameters',
  components: { CodeMirror },
  props: {
    name: { type: String, default: 'Data Preprocessing' },
    dataPreprocessing: {
      type: Object,
      default: () => {
        return { type: 'plain', function: '' }; // function: ''
      },
    },
  },
  setup() {
    const lang = ref(javascript());
    // const pidar = ref('');
    const extensions = ref([basicSetup]);
    return { lang, extensions };
  },
  data() {
    return {
      form: (() => {
        const r = cloneDeep(this.$props.dataPreprocessing);
        if (!r.type) r.type = 'plain';
        if (!r.function) r.function = '';
        return r;
      })(),
      typeOptions: ['plain', 'custom'],
      backdoor: 0,
      hoverMe: false,
    }; // extensions // lang: javascript()
  },
  computed: {
    isValid() {
      this.backdoor;
      const fields = isValid('integration_dataPreprocessingParameters', this.form, this.form);
      const form = formValid(fields);
      // this.$emit('validate', form);
      // console.log('validate dataPreprocessingParameters', { fields, form });
      return { fields, form };
    },
  },
  methods: {
    changeHoverMe(value) {
      this.hoverMe = value;
    },
    emitEditEvent() {
      this.$emit('edit', this.form);
    },
    handleInput() {
      if (this.form.type === 'custom' && !this.form.function) {
        this.form.function = '';
      }
      this.backdoor += 1;
      this.emitEditEvent();
    },
    // handleValidateEvent,
    emitValidateEvent() {
      this.$emit('validate', this.isValid.form);
    },
  },
  watch: {
    'form.function': function () {
      this.backdoor += 1;
      this.emitEditEvent();
    },
    'isValid.form': function () {
      this.emitValidateEvent();
    },
  },
  mounted() {
    // this.emitEditEvent();
    this.emitValidateEvent();
  },
  updated() {
    // this.backdoor += 1;
    this.emitValidateEvent();
  },
});
</script>

<style scoped></style>
