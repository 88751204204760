<template>
  <b-badge
    :variant="regulated ? 'success' : 'warning'"
    class="mx-1 badge-toggle"
    @click="toggle_regulated_final_receiver(hostname)"
  >
    <b-spinner small v-if="requesting"></b-spinner>
    {{ requesting ? '' : regulated ? 'Regulated' : 'NotRegulated' }}
  </b-badge>
</template>

<script>
import '@/assets/badge-toogle.css';
import { mapState } from 'vuex';
import { toggleRegulatedFinalReceiver } from '@/utils/api';
import { handleRequestError } from '@/utils';

export default {
  name: 'FinalReceiverRegulatedIndicator',
  props: {
    _id: { type: String, default: '' },
    hostname: { type: String, default: '' },
    regulated: { type: Boolean, default: false },
  },
  data() {
    return {
      mapped: { regulated: this.$props.regulated },
      requesting: false,
      affectedByChange: [],
    };
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    toggle_regulated_final_receiver() {
      const { hostname } = this;
      if (!hostname) return;
      this.requesting = true;
      toggleRegulatedFinalReceiver(this.token, { hostname })
        .then((r) => {
          if (r.data.success) {
            // this.mapped.regulated = !this.mapped.regulated;
            this.regulated = !this.regulated;
            this.affectedByChange = r.data.affectedByChange;
            this.$emit('changed', { hostname, affectedByChange: this.affectedByChange });
          } else {
            handleRequestError(
              this,
              r.data,
              `Error changing Alert status of FinalReceiver ${hostname}`
            );
          }
          this.requesting = false;
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            `Error changing Alert status of FinalReceiverGroup ${hostname}`
          );
          this.requesting = false;
        });
    },
  },
};
</script>

<style scoped></style>
