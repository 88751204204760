import {
  tableFormatterArrayValues,
  tableFormatterCapOneCell,
  tableFormatterDate,
  tableFormatterDays,
  tableFormatterHourLimit,
  tableFormatterId,
  tableFormatterInj,
  tableFormatterLongString,
  tableFormatterNameAndId,
  config,
} from '@/utils';

// const isFTDApprovedAvailable = (t) =>
//   t.user.email === 'blacklight@default.gg' || t.user.email === 'zerothreethree@default.gg';

// const servicename = process.env.VUE_APP_SERVICENAME.toLowerCase();
// const gmservice = servicename === 'grandmaster' || servicename === 'localgm';

const pspCommonFields = () => [
  { key: '_id', sortable: true, formatter: (v) => tableFormatterId(v) },
  { key: 'controls' },
  { key: 'type', sortable: true },
  { key: 'number', sortable: true, formatter: (v) => tableFormatterId(v, 4) },
  { key: 'ftds', sortable: true },
  { key: 'available', sortable: true },
  { key: 'comment', sortable: true },
  { key: 'affiliates', sortable: true, formatter: (v) => tableFormatterArrayValues(v, 0) },
  { key: 'leads', sortable: true }, // , formatter: (v) => tableFormatterArrayValues(v, 0) },
];

const workerCommonFields = () => [
  { key: '_id', sortable: true, formatter: (v) => tableFormatterId(v) },
  { key: 'controls' },
  { key: 'name', sortable: true },
  { key: 'active', sortable: true },
  { key: 'callback', sortable: true },
  { key: 'available', sortable: true },
  { key: 'businessOpen', sortable: true },
  { key: 'affiliates', sortable: true, formatter: (v) => tableFormatterArrayValues(v, 0) },
  { key: 'leads', sortable: true }, // , formatter: (v) => tableFormatterArrayValues(v, 0) },
  {
    key: 'workingHours',
    sortable: true,
    formatter: (v) => {
      if (typeof v !== 'number') {
        return '?';
      }
      return (v / 60000).toFixed(1);
    },
  },
];

const injCommonFields = () =>
  [
    { key: '_id', sortable: true, formatter: (v) => tableFormatterId(v) },
    { key: 'inspect', sortable: true },
    { key: 'name', sortable: true },
    { key: 'active', sortable: true },
    // { key: 'useNewAlgorithm', label: 'NewAlgo', sortable: true, tooltip: 'Use New Algorithm' },
    { key: 'controls' },
    // { key: 'preparing', sortable: true },
    { key: 'paranoid', sortable: true },
    { key: 'automated', label: '🤖', sortable: true, tooltip: 'Automated based on Demands' },
    {
      key: 'considerRMRatio',
      label: 'RM',
      sortable: true,
      tooltip:
        'Consider RM Ratio: if sent to the Client more than 7 R per 1 M, stop sending R to this Client until it receives enough M',
    },
    {
      key: 'considerWorkingAffiliates',
      label: 'CWA',
      sortable: true,
      tooltip:
        'Consider Working Affiliates: sent leads only if have Affiliates working on this Injections country',
    },
    { key: 'leadsToSendLength', label: 'Select', sortable: true },
    { key: 'leadsSent', label: 'Sent Total', sortable: true },
    { key: 'sentToday', label: 'Sent Today', sortable: true },
    {
      key: 'countries',
      label: 'Geos',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 6),
    },
    {
      key: 'workingAffiliates',
      label: 'Working Affs',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 0),
    },
    { key: 'sendAll', sortable: true },
    { key: 'manager', sortable: true },
    {
      key: 'boxFrom',
      label: 'From Boxes',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 3),
    },
    {
      key: 'boxAvoid',
      label: 'Avoid Boxes',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 3),
    },
    {
      key: 'campaignsFrom',
      label: 'From Campaigns',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 3),
    },
    {
      key: 'campaignsAvoid',
      label: 'Avoid Campaigns',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 3),
    },
    {
      key: 'affiliatesFrom',
      label: 'From Affs',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 3),
    },
    {
      key: 'affiliatesAvoid',
      label: 'Avoid Affs',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 3),
    },
    { key: 'boxTo', sortable: true },
    // { key: 'm', sortable: true },
    // { key: 'x', sortable: true },
    // { key: 'weight', sortable: true },
    // { key: 'makeSalat', sortable: true },
    { key: 'countAllActions', sortable: true },
    {
      key: 'statusGroups',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value, 4),
    },
    { key: 'assignToAffiliate', sortable: true },
    { key: 'amountLeadsToSend', label: 'OrderToSend', sortable: true },
    { key: 'orderToSelect', label: 'OrderToSelect', sortable: true },
    {
      key: 'nextSendAt',
      label: 'NextSend',
      tooltip: 'Scheduled next send',
      sortable: true,
      formatter: tableFormatterDate,
    },
    {
      key: 'hourLimit',
      sortable: true,
      formatter: (value) => tableFormatterHourLimit(value),
    },
    {
      key: 'days',
      sortable: true,
      formatter: (value) => tableFormatterDays(value),
    },
    { key: 'startSelectDateTime', sortable: true },
    { key: 'endSelectDateTime', sortable: true },
    { key: 'createdDate', sortable: true },
    { key: 'startDateTime', sortable: true },
    { key: 'endDateTime', sortable: true },
    { key: 'batchSize', sortable: true },
    { key: 'timeBetweenBatches1', label: '📦⏱️📦 1', sortable: true },
    { key: 'timeBetweenBatches2', label: '📦⏱️📦 2', sortable: true },
    { key: 'boxDemandThisHour', label: 'Demand⏳', sortable: true },
    { key: 'leftToSendThisHour', label: 'Left⏳', sortable: true },
    { key: 'actionsSentThisHour', label: 'Sent⏳', sortable: true },
    { key: 'avgTimeBetweenBatches', label: 'Avg.📦⏱️📦', sortable: true },
    { key: 'so', sortable: true },
    { key: 'leadsSelectionDropouts', label: '❌' },
  ].filter((f) => f);

const campaignsCommonFields = () =>
  [
    { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
    { key: 'name', sortable: true },
    { key: 'broker', sortable: true },
    { key: 'brokerApiType', label: 'Api type', sortable: true },
    { key: 'active', label: 'Status', sortable: true },
    { key: 'sendM', label: 'SendM', sortable: true },
    { key: 'limit1MPerGeo', label: 'Only1M', sortable: true },
    { key: 'blockedByAdmin', label: 'Lock', tooltip: 'Blocked By SuperAdmin', sortable: true },
    {
      key: 'dailyFtdsLimit',
      label: 'DailyFtdsLimit',
      tooltip: 'Daily FTDs limit (not divided by Geo)',
      sortable: true,
    },
    { key: 'ready', sortable: true },
    { key: 'pulling', sortable: true },
    { key: 'controls' },
    {
      key: 'countries',
      label: 'Geos',
      sortable: true,
      formatter: (value) => tableFormatterArrayValues(value),
    },
    { key: 'defaultPayment', sortable: true },
    { key: 'aff_defaultPayment', sortable: true },
    { key: 'currency', sortable: true },
    { key: 'boxes', sortable: true },
    { key: 'managers', sortable: true },
    { key: 'dailyCap', sortable: true },
    { key: 'dailyCapType', sortable: true },
    { key: 'totalCap', sortable: true },
    { key: 'totalCapType', sortable: true },
    // { key: 'impressionsToday', sortable: true }, // label: 'Impressions',
    // { key: 'clicksToday', sortable: true }, // label: 'Clicks',
    // { key: 'leadsToday', sortable: true }, // label: 'Leads',
    // { key: 'ftdsToday', sortable: true }, // label: 'FTDs',
    // { key: 'payoutsToday', sortable: true }, // label: 'Payouts',
    // { key: 'payouts', sortable: true },
    // { key: 'revenue', sortable: true },
  ].filter((f) => f);

const usersCommonFields = () =>
  [
    { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
    { key: 'email', sortable: true },
    { key: 'name', sortable: true },
    { key: 'controls' },
    { key: 'active', sortable: true },
    { key: 'maff', sortable: true },
    { key: 'rtcMandatoryFlow', sortable: true },
    { key: 'rtcMandatoryFlowPSP', sortable: true },
    {
      key: 'workingOnCountries',
      label: 'WorkOn',
      sortable: true,
      formatter: tableFormatterArrayValues,
    },
    { key: 'online', sortable: true, tooltip: 'Got any activity from user within last 3 minutes' },
    {
      key: 'lastTimeSeen',
      sortable: true,
      formatter: tableFormatterDate,
      tooltip: 'When received last activity from user',
    },
    { key: 'type', sortable: true },
    { key: 'boxes', sortable: true },
    { key: 'campaigns', sortable: true },
    { key: 'affiliates', sortable: true, formatter: tableFormatterArrayValues },
    { key: 'manager', sortable: true },
    { key: 'extendedFieldsAccess', label: 'Sees FR', sortable: true },
    // { key: 'messenger', sortable: true },
    // { key: 'impressionsToday', sortable: true }, // label: 'Impressions',
    // { key: 'clicksToday', sortable: true }, // label: 'Clicks',
    // { key: 'leadsToday', sortable: true }, // label: 'Leads',
    // { key: 'ftdsToday', sortable: true }, // label: 'FTDs',
    // { key: 'payoutsToday', sortable: true }, // label: 'Payouts',
    // { key: 'payouts', sortable: true },
    // { key: 'revenue', sortable: true },
  ].filter((f) => f);

const boxesCommonFields = () =>
  [
    { key: 'name', sortable: true },
    { key: 'active', sortable: true },
    { key: 'controls' },
    { key: 'affiliate', sortable: true, formatter: tableFormatterNameAndId },
    { key: 'manager', sortable: true, formatter: tableFormatterNameAndId },
    {
      key: 'countries',
      label: 'Geos',
      sortable: true,
      formatter: tableFormatterArrayValues,
    },
    { key: 'campaigns', sortable: true }, // label: 'Campaigns',
    {
      key: 'workingHours',
      sortable: true,
      formatter: (value) => tableFormatterHourLimit(value),
    },
    { key: 'dailyCap', sortable: true, formatter: tableFormatterCapOneCell }, // label: 'Cap',
    { key: 'totalCap', sortable: true, formatter: tableFormatterCapOneCell }, // label: 'Type',
    { key: 'isTest', label: 'Test', sortable: true },
    { key: 'isMBox', label: 'M', sortable: true },
    { key: 'loadBalancer', label: 'Balanced', sortable: true },
    { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
    // { key: 'loadBalancerCoefficient', label: 'LBC', sortable: true },
    // { key: 'actionsToday', label: 'Actions Today', sortable: true },
    // { key: 'impressionsToday', sortable: true }, // label: 'Impressions',
    // { key: 'clicksToday', sortable: true }, // label: 'Clicks',
    // { key: 'leadsToday', sortable: true }, // label: 'Leads',
    // { key: 'ftdsToday', sortable: true }, // label: 'FTDs',
    // { key: 'payoutsToday', sortable: true }, // label: 'Payouts',
    // { key: 'revenue', label: 'Revenue Today', sortable: true }, // need broker payout
    // { key: 'profit', label: 'Profit Today', sortable: true }, // need broker payout
  ].filter((f) => f);

export const userAvailableFields = {
  psp: {
    admin: pspCommonFields,
    manager: pspCommonFields,
    affiliate: () => [
      { key: '_id', sortable: true, formatter: (v) => tableFormatterId(v) },
      { key: 'controls' },
      { key: 'type', sortable: true },
      { key: 'number', sortable: true, formatter: (v) => tableFormatterId(v, 4) },
      { key: 'ftds', sortable: true },
      { key: 'available', sortable: true },
      { key: 'comment', sortable: true },
      // { key: 'affiliates', sortable: true, formatter: (v) => tableFormatterArrayValues(v, 0) },
      { key: 'leads', sortable: true }, // , formatter: (v) => tableFormatterArrayValues(v, 0) },
    ],
    masteraffiliate: () => [],
  },
  worker: {
    admin: workerCommonFields,
    manager: workerCommonFields,
    affiliate: () => [
      { key: '_id', sortable: true, formatter: (v) => tableFormatterId(v) },
      { key: 'controls' },
      { key: 'name', sortable: true },
      { key: 'callback', sortable: true },
      { key: 'active', sortable: true, label: 'working' },
      { key: 'available', sortable: true },
      { key: 'businessOpen', sortable: true },
      // { key: 'affiliates', sortable: true, formatter: (v) => tableFormatterArrayValues(v, 0) },
      { key: 'leads', sortable: true }, // , formatter: (v) => tableFormatterArrayValues(v, 0) },
    ],
    masteraffiliate: () => [],
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    makeFtds: (isFTDApprovedAvailable) =>
      [
        // {
        //   key: '_id',
        //   label: 'ID',
        //   sortable: true,
        //   formatter: (v) => tableFormatterId(v),
        // },
        { key: 'actionDate', sortable: true, formatter: tableFormatterDate },
        { key: 'sentToBroker', label: 'Sent Date', sortable: true, formatter: tableFormatterDate },
        {
          key: 'affiliate',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        { key: 'm', sortable: true, formatter: tableFormatterDate },
        {
          key: 'box',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        { key: 'location', label: 'Geo', sortable: true },
        { key: 'controls' },
        // { key: 'timesCanSendM', label: 'TimesCanSend', sortable: true },
        {
          key: 'campaign',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        {
          key: 'sentToInstanceCampaign',
          label: 'Broker Name',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        { key: 'finalReceiver', label: 'Final Receiver', sortable: true },
        { key: 'brokerAccepted', sortable: true },
        {
          key: 'worker',
          label: 'Worker',
          sortable: true,
          formatter: (v) => (v ? (v._id && v._id.name ? v._id.name : v._id) : v),
        },
        {
          key: 'ftdCallbackStatus',
          label: 'FTD/Callback',
          sortable: true,
        },
        {
          key: 'salesStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        {
          key: 'workerSaleStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        // { key: 'sentByInj', label: 'Inj', sortable: true, formatter: tableFormatterInj },
        { key: 'type', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'requestToCheck', label: 'Check', sortable: true },
        {
          key: 'psp',
          label: 'PSP',
          sortable: true,
          formatter: (v) =>
            v ? tableFormatterId(v._id && v._id.number ? v._id.number : v._id, 4) : v,
        },
        { key: 'accessDetails', label: 'Cabinet', sortable: true },

        { key: 'email', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'phone', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'renew', sortable: true },
        // { key: 'payout', sortable: true },
        // { key: 'aff_payout', sortable: true },

        // {
        //   key: 'trader',
        //   sortable: true,
        //   formatter: tableFormatterNameAndId,
        //   tdClass: 'with-text-overflow',
        // },
        // { key: 'traderStatus', sortable: true, tdClass: 'with-text-overflow' },
        // { key: 'withdraw', label: 'Withdraw', sortable: true },
        // { key: 'withdrawDone', sortable: true },
        // isFTDApprovedAvailable && { key: 'ftdApproved', label: '✅' },
        // { key: 'isTest', label: 'Test', sortable: true },
        // { key: 'calls', sortable: true },
      ].filter((f) => f),
    admin: (isFTDApprovedAvailable) =>
      [
        {
          key: '_id',
          label: 'ID',
          sortable: true,
          formatter: (v) => tableFormatterId(v),
        },
        { key: 'actionDate', sortable: true, formatter: tableFormatterDate },
        { key: 'sentToBroker', label: 'Sent Date', sortable: true, formatter: tableFormatterDate },
        { key: 'm', sortable: true, formatter: tableFormatterDate },
        { key: 'cb', sortable: true, formatter: tableFormatterDate },

        { key: 'controls' },
        { key: 'timesCanSendM', label: 'TimesCanSend', sortable: true },
        { key: 'location', label: 'Geo', sortable: true },
        {
          key: 'affiliate',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        {
          key: 'campaign',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        {
          key: 'box',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        {
          key: 'sentToInstanceCampaign',
          label: 'Broker Name',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        { key: 'sentByInj', label: 'Inj', sortable: true, formatter: tableFormatterInj },
        { key: 'type', sortable: true, tdClass: 'with-text-overflow' },
        {
          key: 'salesStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        {
          key: 'workerSaleStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        { key: 'email', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'phone', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'renew', sortable: true },
        { key: 'payout', sortable: true },
        { key: 'aff_payout', sortable: true },
        {
          key: 'psp',
          label: 'PSP',
          sortable: true,
          formatter: (v) =>
            v ? tableFormatterId(v._id && v._id.number ? v._id.number : v._id, 4) : v,
        },
        {
          key: 'worker',
          label: 'Worker',
          sortable: true,
          formatter: (v) => (v ? (v._id && v._id.name ? v._id.name : v._id) : v),
        },
        {
          key: 'ftdCallbackStatus',
          label: 'FTD/Callback',
          sortable: true,
        },
        // {
        //   key: 'workerAssignResult',
        //   label: 'Worker Status',
        //   sortable: true,
        //   formatter: (v) =>
        //     v && (v.preferrability || v.preferrability === 0) ? v.preferrability : v,
        // },
        { key: 'accessDetails', label: 'Cabinet', sortable: true },
        {
          key: 'trader',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        { key: 'traderStatus', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'requestToCheck', label: 'Check', sortable: true },
        { key: 'withdraw', label: 'Withdraw', sortable: true },
        { key: 'withdrawDone', sortable: true },
        { key: 'finalReceiver', label: 'Final Receiver', sortable: true },
        { key: 'brokerAccepted', sortable: true },
        isFTDApprovedAvailable && { key: 'ftdApproved', label: '✅' },
        { key: 'isTest', label: 'Test', sortable: true },
        { key: 'calls', sortable: true },
      ].filter((f) => f),
    manager: (isFTDApprovedAvailable, integrator = false, seeLeadsBasedOnCampaigns = false) =>
      [
        {
          key: '_id',
          label: 'ID',
          sortable: true,
          formatter: (v) => tableFormatterId(v),
        },
        { key: 'actionDate', sortable: true, formatter: tableFormatterDate },
        { key: 'sentToBroker', label: 'Sent Date', sortable: true, formatter: tableFormatterDate },
        !seeLeadsBasedOnCampaigns && { key: 'm', sortable: true, formatter: tableFormatterDate },

        { key: 'controls' },
        { key: 'location', label: 'Geo', sortable: true },
        !seeLeadsBasedOnCampaigns && {
          key: 'affiliate',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        {
          key: 'campaign',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        !seeLeadsBasedOnCampaigns && {
          key: 'box',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        { key: 'brokerAccepted', sortable: true },
        // !seeLeadsBasedOnCampaigns && {
        //   key: 'sentByInj',
        //   label: 'Inj',
        //   sortable: true,
        //   formatter: tableFormatterInj,
        // },
        { key: 'type', sortable: true, tdClass: 'with-text-overflow' },
        {
          key: 'salesStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        !seeLeadsBasedOnCampaigns && {
          key: 'workerSaleStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        { key: 'email', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'phone', sortable: true, tdClass: 'with-text-overflow' },
        !seeLeadsBasedOnCampaigns && { key: 'renew', sortable: true },
        !seeLeadsBasedOnCampaigns && { key: 'payout', sortable: true },
        // !seeLeadsBasedOnCampaigns && { key: 'aff_payout', sortable: true },
        // !seeLeadsBasedOnCampaigns && {
        //   key: 'psp',
        //   label: 'PSP',
        //   sortable: true,
        //   formatter: (v) =>
        //     v ? tableFormatterId(v._id && v._id.number ? v._id.number : v._id, 4) : v,
        // },
        // !seeLeadsBasedOnCampaigns && {
        //   key: 'worker',
        //   label: 'Worker',
        //   sortable: true,
        //   formatter: (v) => (v ? (v._id && v._id.name ? v._id.name : v._id) : v),
        // },
        // !seeLeadsBasedOnCampaigns && {
        //   key: 'workerAssignResult',
        //   label: 'Worker Status',
        //   sortable: true,
        //   formatter: (v) =>
        //     v && (v.preferrability || v.preferrability === 0) ? v.preferrability : v,
        // },
        // !seeLeadsBasedOnCampaigns && { key: 'accessDetails', label: 'Cabinet', sortable: true },
        { key: 'requestToCheck', label: 'Check', sortable: true },
        // !seeLeadsBasedOnCampaigns && { key: 'withdraw', label: 'Withdraw', sortable: true },
        // { key: 'ip', sortable: true },
        (integrator || !seeLeadsBasedOnCampaigns) && {
          key: 'finalReceiver',
          label: 'Final Receiver',
          sortable: true,
        },
        isFTDApprovedAvailable && { key: 'ftdApproved', label: '✅' },
      ].filter((f) => f),
    affiliate: (
      extendedFieldsAccess,
      campaignsAccess = false,
      maff = false,
      hasWorkers = false,
      trader = false
    ) =>
      [
        { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
        { key: 'actionDate', sortable: true, formatter: tableFormatterDate },
        { key: 'controls' },
        campaignsAccess && { key: 'm', sortable: true },
        maff && { key: 'timesCanSendM', label: 'TimesCanSend', sortable: true },
        { key: 'location', label: 'Geo', sortable: true },
        campaignsAccess && {
          key: 'sentToInstanceCampaign',
          label: 'Broker Name',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        !trader && { key: 'type', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'email', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'phone', sortable: true, tdClass: 'with-text-overflow' },
        hasWorkers ? { key: 'renew', sortable: true } : undefined,

        !trader && {
          key: 'salesStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        hasWorkers && {
          key: 'workerSaleStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        trader ? { key: 'name', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'url', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'username', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'password', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'traderStatus', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        // { key: 'payout', sortable: true },
        // { key: 'currency', sortable: true },
        !trader && { key: 'aff_payout', sortable: true },
        maff
          ? {
              key: 'psp',
              label: 'PSP',
              sortable: true,
              formatter: (v) =>
                v ? tableFormatterId(v._id && v._id.number ? v._id.number : v._id, 4) : v,
            }
          : undefined,
        hasWorkers
          ? {
              key: 'worker',
              label: 'Worker',
              sortable: true,
              formatter: (v) => (v ? (v._id && v._id.name ? v._id.name : v._id) : v),
            }
          : undefined,
        hasWorkers
          ? {
              key: 'ftdCallbackStatus',
              label: 'FTD/Callback',
              sortable: true,
            }
          : undefined,
        // hasWorkers
        //   ? {
        //       key: 'workerAssignResult',
        //       label: 'Worker Status',
        //       sortable: true,
        //       formatter: (v) =>
        //         v && (v.preferrability || v.preferrability === 0) ? v.preferrability : v,
        //     }
        //   : undefined,
        maff || trader ? { key: 'accessDetails', label: 'Cabinet', sortable: true } : undefined,
        !trader
          ? { key: 'requestToCheck', label: maff ? 'FTD Done' : 'Check', sortable: true }
          : undefined,
        maff || trader ? { key: 'withdraw', label: 'Withdraw', sortable: true } : undefined,
        trader ? { key: 'withdrawDone', sortable: true } : undefined,
        !trader ? { key: 'ip', sortable: true } : undefined,
        extendedFieldsAccess
          ? { key: 'finalReceiver', label: 'Final Receiver', sortable: true }
          : undefined,
        !trader ? { key: 'brokerAccepted', sortable: true } : undefined,
      ].filter((f) => !!f),
    masteraffiliate: (
      extendedFieldsAccess,
      campaignsAccess = false,
      maff = false,
      hasWorkers = false,
      trader = false
    ) =>
      [
        { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
        { key: 'actionDate', sortable: true, formatter: tableFormatterDate },
        { key: 'controls' },
        campaignsAccess && { key: 'm', sortable: true },
        maff && { key: 'timesCanSendM', label: 'TimesCanSend', sortable: true },
        { key: 'location', label: 'Geo', sortable: true },
        campaignsAccess && {
          key: 'sentToInstanceCampaign',
          label: 'Broker Name',
          sortable: true,
          formatter: tableFormatterNameAndId,
          tdClass: 'with-text-overflow',
        },
        !trader && { key: 'type', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'email', sortable: true, tdClass: 'with-text-overflow' },
        { key: 'phone', sortable: true, tdClass: 'with-text-overflow' },
        hasWorkers ? { key: 'renew', sortable: true } : undefined,
        !trader && {
          key: 'salesStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        hasWorkers && {
          key: 'workerSaleStatus',
          sortable: true,
          formatter: tableFormatterLongString,
          tdClass: 'with-text-overflow',
        },
        trader ? { key: 'name', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'url', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'username', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'password', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        trader ? { key: 'traderStatus', sortable: true, tdClass: 'with-text-overflow' } : undefined,
        // { key: 'payout', sortable: true },
        // { key: 'currency', sortable: true },
        !trader && { key: 'aff_payout', sortable: true },
        maff
          ? {
              key: 'psp',
              label: 'PSP',
              sortable: true,
              formatter: (v) =>
                v ? tableFormatterId(v._id && v._id.number ? v._id.number : v._id, 4) : v,
            }
          : undefined,
        hasWorkers
          ? {
              key: 'worker',
              label: 'Worker',
              sortable: true,
              formatter: (v) => (v ? (v._id && v._id.name ? v._id.name : v._id) : v),
            }
          : undefined,
        hasWorkers
          ? {
              key: 'ftdCallbackStatus',
              label: 'FTD/Callback',
              sortable: true,
            }
          : undefined,
        // hasWorkers
        //   ? {
        //       key: 'workerAssignResult',
        //       label: 'Worker Status',
        //       sortable: true,
        //       formatter: (v) =>
        //         v && (v.preferrability || v.preferrability === 0) ? v.preferrability : v,
        //     }
        //   : undefined,
        maff || trader ? { key: 'accessDetails', label: 'Cabinet', sortable: true } : undefined,
        !trader
          ? { key: 'requestToCheck', label: maff ? 'FTD Done' : 'Check', sortable: true }
          : undefined,
        maff || trader ? { key: 'withdraw', label: 'Withdraw', sortable: true } : undefined,
        trader ? { key: 'withdrawDone', sortable: true } : undefined,
        !trader ? { key: 'ip', sortable: true } : undefined,
        extendedFieldsAccess
          ? { key: 'finalReceiver', label: 'Final Receiver', sortable: true }
          : undefined,
        !trader ? { key: 'brokerAccepted', sortable: true } : undefined,
      ].filter((f) => !!f),
  },
  injections: {
    admin: injCommonFields,
    manager: injCommonFields,
    affiliate: () => ['_id'],
    masteraffiliate: () => ['_id'],
  },
  campaigns: {
    admin: campaignsCommonFields,
    manager: campaignsCommonFields,
    affiliate: () => ['_id'],
    masteraffiliate: () => ['_id'],
  },
  users: {
    admin: usersCommonFields,
    manager: usersCommonFields,
    affiliate: () => ['_id'],
    masteraffiliate: () => [
      { key: '_id', label: 'ID', sortable: true, formatter: (v) => tableFormatterId(v) },
      { key: 'email', sortable: true },
      { key: 'name', sortable: true },
      {
        key: 'online',
        sortable: true,
        tooltip: 'Got any activity from user within last 3 minutes',
      },
      {
        key: 'lastTimeSeen',
        sortable: true,
        formatter: tableFormatterDate,
        tooltip: 'When received last activity from user',
      },
      { key: 'type', sortable: true },
    ],
  },
  boxes: {
    admin: boxesCommonFields,
    manager: boxesCommonFields,
    affiliate: () => ['_id'],
    masteraffiliate: () => ['_id'],
  },
};

const campaignsCommonDetails = () => ['countries', 'boxes', 'managers'].filter((f) => f);
const pspCommonDetails = () => ['_id', 'details', 'affiliates', 'leads'].filter((f) => f);
const workerCommonDetails = () => ['_id', 'details', 'affiliates', 'leads'].filter((f) => f);

const usersCommonDetails = () => ['manager', 'campaigns', 'boxes', 'affiliates'].filter((f) => f);
const boxesCommonDetails = () =>
  ['id', 'affiliate', 'manager', 'countries', 'campaigns', 'ActionsDistributionSimulator'].filter(
    (f) => f
  );

export const userAvailableDetails = {
  psp: {
    admin: pspCommonDetails,
    manager: pspCommonDetails,
    affiliate: () => ['_id', 'details', 'leads'],
    masteraffiliate: () => [],
  },
  worker: {
    admin: workerCommonDetails,
    manager: workerCommonDetails,
    affiliate: () => ['_id', 'details', 'leads'],
    masteraffiliate: () => [],
  },
  actions: {
    admin: (isTrustedUser) =>
      [
        'changeLog',
        'saleStatusChangeLog',
        'leadContactInfo',
        'sentToCampaigns',
        'sendLeadRequestConfig',
        'brokerApiAnswer',
        isTrustedUser && 'brokerLastLeadsPullingAnswer',
        isTrustedUser && 'brokerLastFtdsPullingAnswer',
        'campaignSelectionDropouts',
        'mChangeHistory',
        'rtcChangeHistory',
        'sentToInstanceCampaign',
        'finalReceiverCheckResult',
        'actionId',
        'brokerActionId',
        'errorOnSend',
      ].filter((f) => f),
    manager: (isTrustedUser, seeLeadsBasedOnCampaigns = false) =>
      [
        'changeLog',
        'saleStatusChangeLog',
        'leadContactInfo',
        !seeLeadsBasedOnCampaigns && 'sentToCampaigns',
        'sendLeadRequestConfig',
        'brokerApiAnswer',
        (isTrustedUser || seeLeadsBasedOnCampaigns) && 'brokerLastLeadsPullingAnswer',
        (isTrustedUser || seeLeadsBasedOnCampaigns) && 'brokerLastFtdsPullingAnswer',
        !seeLeadsBasedOnCampaigns && 'campaignSelectionDropouts',
        // 'mChangeHistory',
        !seeLeadsBasedOnCampaigns && 'rtcChangeHistory',
        'sentToInstanceCampaign',
        !seeLeadsBasedOnCampaigns && 'finalReceiverCheckResult',
        'actionId',
        'brokerActionId',
      ].filter((f) => f),
    affiliate: () => ['leadContactInfo'],
    masteraffiliate: () => ['leadContactInfo'],
  },
  campaigns: {
    admin: campaignsCommonDetails,
    manager: campaignsCommonDetails,
    affiliate: () => [],
    masteraffiliate: () => [],
  },
  users: {
    admin: usersCommonDetails,
    manager: usersCommonDetails,
    affiliate: () => [],
    masteraffiliate: () => [],
  },
  boxes: {
    admin: boxesCommonDetails,
    manager: boxesCommonDetails,
    affiliate: () => [],
    masteraffiliate: () => [],
  },
};

const forbiddenForRent = [
  // action fields
  'm',
  'timesCanSendM',
  'sentToInstanceCampaign',
  'renew',
  'psp',
  'worker',
  'ftdCallbackStatus',
  'accessDetails',
  'trader',
  'traderStatus',
  'requestToCheck',
  'withdraw',
  'withdrawDone',
  'ftdApproved', // TODO: will need to remake pendingFtds function on backend for those who don't have it
  'calls',
  'finalReceiver',
  // action details
  'sentToInstanceCampaign',
  'finalReceiverCheckResult',
  // injection fields
  'considerRMRatio',
  'considerWorkingAffiliates',
  'workingAffiliates',
  // campaign fields
  'sendM',
  'limit1MPerGeo',
  // user fields
  'maff',
  'rtcMandatoryFlow',
  'rtcMandatoryFlowPSP',
  'workingOnCountries',
  // box fields
  'isMBox',
];
const checkRentFactory = (func) => {
  return (...params) => {
    const fields = func(...params);
    const allowed = [];
    for (let i = 0; i < fields.length; i += 1) {
      if (!forbiddenForRent.includes(fields[i].key)) {
        allowed.push(fields[i]);
      }
    }
    return allowed;
  };
};
if (config.rent) {
  const fieldsKeys = Object.keys(userAvailableFields);
  for (let i = 0; i < fieldsKeys.length; i += 1) {
    const slotKeys = Object.keys(userAvailableFields[fieldsKeys[i]]);
    for (let j = 0; j < slotKeys.length; j += 1) {
      userAvailableFields[fieldsKeys[i]][slotKeys[j]] = checkRentFactory(
        userAvailableFields[fieldsKeys[i]][slotKeys[j]]
      );
    }
  }

  const detailsKeys = Object.keys(userAvailableDetails);
  for (let i = 0; i < detailsKeys.length; i += 1) {
    const slotKeys = Object.keys(userAvailableDetails[detailsKeys[i]]);
    for (let j = 0; j < slotKeys.length; j += 1) {
      userAvailableDetails[detailsKeys[i]][slotKeys[j]] = checkRentFactory(
        userAvailableDetails[detailsKeys[i]][slotKeys[j]]
      );
    }
  }
}
