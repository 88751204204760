/* eslint-disable camelcase */
import { cloneDeep } from 'lodash';
import { validateHourLimit } from '@/validators/box';
import { countryOptions, daysOfWeek } from '@/consts';

export const generatedFieldGeoOptions = [{ value: 'default', text: 'default' }, ...countryOptions];

// eslint-disable-next-line import/prefer-default-export
export class GeneratedLeadFieldValue {
  value;

  rWeight;

  mWeight;

  hourLimit;

  days;

  geos;

  item_index;

  static createArray(items) {
    if (!Array.isArray(items)) return [];
    const result = [];
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      try {
        result.push(new GeneratedLeadFieldValue(item));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    return result;
  }

  static deleteFromArray(array, index) {
    return cloneDeep(array.filter((v, i) => i !== index));
  }

  generateRandomNumberValue() {
    this.value = 1 + Math.floor(Math.random() * 200);
  }

  putDefaultSettings() {
    this.rWeight = 10;
    this.mWeight = 10;
    this.hourLimit = [0, 23];
    this.days = [true, true, true, true, true, true, true];
    this.geos = [];
  }

  static scheduleAllowsToSend(i, datetime = new Date()) {
    const hourNow = datetime.getHours();
    const dayNow = datetime.getDay();
    return (
      i.days[dayNow] &&
      ((i.hourLimit[0] < i.hourLimit[1] &&
        hourNow >= i.hourLimit[0] &&
        hourNow <= i.hourLimit[1]) ||
        (i.hourLimit[0] > i.hourLimit[1] &&
          (hourNow <= i.hourLimit[0] || hourNow >= i.hourLimit[1])) ||
        (i.hourLimit[0] === i.hourLimit[1] && hourNow === i.hourLimit[0]))
    );
  }

  static selectGeneratedLeadFieldValue(campaignIds, mode, geo) {
    let ids = (campaignIds && campaignIds.length > 0 ? campaignIds : []).filter(
      (i) => i.geos.includes(geo) && GeneratedLeadFieldValue.scheduleAllowsToSend(i)
    );
    if (ids.length === 0) {
      ids = (campaignIds && campaignIds.length > 0 ? campaignIds : []).filter(
        (i) => i.geos.includes('default') && GeneratedLeadFieldValue.scheduleAllowsToSend(i)
      );
    }
    if (ids.length === 0) return undefined;

    const ws = mode === 'm' ? 'mWeight' : 'rWeight';
    const totalWeight = ids.reduce((tw, i) => tw + (mode === 'm' ? i[ws] : i[ws]), 0);
    const dice = Math.floor(Math.random() * totalWeight);
    let om = 0;
    let nm = 0;
    for (let i = 0; i < ids.length; i += 1) {
      nm += ids[i][ws];
      if (om <= dice && dice <= nm) return ids[i].value;
      om = nm;
    }
    return ids[ids.length - 1].value;
  }

  constructor(item, mode = 'number') {
    this.value =
      item && (item.value || item.value === 0)
        ? item.value
        : mode === 'number'
        ? 1 + Math.floor(Math.random() * 200)
        : '';
    this.rWeight = item && (item.rWeight || item.rWeight === 0) ? item.rWeight : 10;
    this.mWeight = item && (item.mWeight || item.mWeight === 0) ? item.mWeight : 10;
    if (!item || !item.hourLimit) {
      this.hourLimit = [0, 23];
    } else {
      const hourLimitValidated = validateHourLimit(item.hourLimit);
      this.hourLimit = [
        hourLimitValidated[0] !== false && hourLimitValidated[0] >= 0 && hourLimitValidated[0] <= 23
          ? item.hourLimit[0]
          : 0,
        hourLimitValidated[1] !== false && hourLimitValidated[1] >= 0 && hourLimitValidated[1] <= 23
          ? item.hourLimit[1]
          : 23,
      ];
    }
    this.days =
      item && item.days
        ? item.days.map((d, i) => (d ? i : false)).filter((d) => d !== false)
        : daysOfWeek.map((d) => d.item); // [true, true, true, true, true, true, true];
    this.geos =
      item && item.geos ? generatedFieldGeoOptions.filter((g) => item.geos.includes(g.value)) : [];
  }

  castToSend() {
    return {
      value: this.value,
      rWeight: this.rWeight,
      mWeight: this.mWeight,
      hourLimit: this.hourLimit,
      days: [true, true, true, true, true, true, true].map((d, j) => this.days.includes(j)),
      geos: this.geos.map((c) => c.value),
    };
  }

  static castToSendArray(items) {
    if (!Array.isArray(items)) return undefined;
    return items.map((i) => i.castToSend());
  }

  static castToShowItemsArray(array, filter) {
    const geos = filter.geos.map((c) => c.value);
    const { value } = filter;
    return array
      .map((item, index) => {
        const i = cloneDeep(item);
        i.item_index = index;
        console.log(index);
        return i;
      })
      .filter((item) => {
        if (value && value.trim().length > 0 && !String(item.value).includes(value)) return false;
        const item_geos = item.geos.map((ig) => (ig.value ? ig.value : ig));
        return geos.length === 0 || geos.some((g) => item_geos.includes(g));
      });
  }
}
