<template>
  <b-container>
    <!--    <b-form @submit="onSubmit">-->
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">
        {{ $route.path === '/create-campaign' ? 'Create Campaign' : 'Edit Campaign' }}
      </h2>
      <b-button
        v-if="$route.path !== '/create-campaign'"
        @click="createNewCampaign()"
        style="display: inline; vertical-align: middle"
      >
        Create new
      </b-button>
    </div>
    <!--      <b-row class="d-flex align-items-center mb-3">-->
    <!--        <b-col class="d-flex flex-column align-items-center">-->
    <!--    <div-->
    <!--      v-if="-->
    <!--        campaignForm.ready ||-->
    <!--        user.email === 'blacklight@default.gg' ||-->
    <!--        user.email === 'zerothreethree@default.gg'-->
    <!--      "-->
    <!--    >-->
    <b-form-group
      class="my-2"
      label="Campaign Name"
      label-for="campaign-name"
      :invalid-feedback="invalidFeedback.campaign.name"
      :state="isValid.fields.name"
    >
      <!--            <h6 class="my-2">Campaign Name</h6> zxc -->
      <b-form-input
        type="text"
        id="campaign-name"
        v-model="campaignForm.name"
        :state="isValid.fields.name"
      ></b-form-input>
    </b-form-group>
    <b-form-checkbox class="my-2" v-model="campaignForm.ready" :state="isValid.fields.ready">
      <!--      v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"-->
      Ready
    </b-form-checkbox>
    <b-form-checkbox class="my-2" v-model="campaignForm.active" :state="isValid.fields.active">
      Active
    </b-form-checkbox>
    <b-form-checkbox
      class="my-2"
      v-model="campaignForm.sendM"
      :state="isValid.fields.sendM"
      v-if="!$rent"
    >
      Send M
    </b-form-checkbox>
    <b-form-checkbox
      class="my-2"
      v-model="campaignForm.blockedByAdmin"
      :state="isValid.fields.blockedByAdmin"
      v-if="iAmOtherLevelTrustedUser"
    >
      Blocked by Admin
    </b-form-checkbox>
    <b-form-checkbox
      class="my-2"
      v-model="campaignForm.pulling"
      :state="isValid.fields.pulling"
      v-if="
        user.email === 'blacklight@default.gg' ||
        (servicename !== 'grandmaster' && (user.type === 'admin' || user.integrator))
      "
    >
      <!--
              user.email === 'zerothreethree@default.gg' ||

-->
      Pulling
    </b-form-checkbox>
    <b-form-checkbox class="my-2" v-model="campaignForm.paranoid" :state="isValid.fields.paranoid">
      <!--      v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"-->
      Paranoid
    </b-form-checkbox>
    <b-form-checkbox
      v-if="!$rent"
      class="my-2"
      v-model="campaignForm.paranoid_xm"
      :state="isValid.fields.paranoid_xm"
    >
      <!--      v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"-->
      Paranoid Except M
    </b-form-checkbox>
    <b-form-checkbox
      v-if="!$rent"
      class="my-2"
      v-model="campaignForm.limit1MPerGeo"
      :state="isValid.fields.limit1MPerGeo"
    >
      <!--      v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"-->
      Limit 1 M per Geo
    </b-form-checkbox>
    <b-form-checkbox
      class="my-2"
      v-model="campaignForm.forbidLinkChecking"
      :state="isValid.fields.forbidLinkChecking"
    >
      <!--            v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"
-->
      Forbid Link Checking
    </b-form-checkbox>
    <b-form-checkbox class="my-2" v-model="campaignForm.isBroker" :state="isValid.fields.isBroker">
      <!--            v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"
-->
      Is Broker
    </b-form-checkbox>
    <b-form-checkbox
      class="my-2"
      v-model="campaignForm.canHaveFinalReceiverInPullingAPIAnswer"
      :state="isValid.fields.canHaveFinalReceiverInPullingAPIAnswer"
    >
      <!--            v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"
-->
      Can Have Final Receiver In Pulling API Answer (works only for Trackbox campaign and only in
      manual check)
    </b-form-checkbox>
    <b-row>
      <b-col cols="1">
        <b-form-checkbox
          class="my-2"
          v-model="campaignForm.crg"
          :state="isValid.fields.crg"
          :invalid-feedback="invalidFeedback.campaign.crg"
        >
          CRG
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="4" v-if="campaignForm.crg">
        <b-form-group
          class="my-2"
          label="CRG Rate"
          label-for="crg-rate"
          :state="isValid.fields.crgRate"
          :invalid-feedback="invalidFeedback.campaign.crgRate"
        >
          <b-form-input
            type="number"
            id="crg-rate"
            v-model="campaignForm.crgRate"
            :state="isValid.fields.crgRate"
          ></b-form-input>
          <span class="mx-1">%</span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      v-if="!$rent"
      class="my-2"
      label="Daily Ftds Limit"
      label-for="dailyFtdsLimit"
      :state="isValid.fields.dailyFtdsLimit"
      :invalid-feedback="invalidFeedback.campaign.dailyFtdsLimit"
      style="max-width: 280px"
    >
      <b-form-input
        id="dailyFtdsLimit"
        v-model="campaignForm.dailyFtdsLimit"
        :state="isValid.fields.dailyFtdsLimit"
        style="max-width: 280px"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="my-2"
      label="Broker"
      label-for="campaign-broker"
      :invalid-feedback="invalidFeedback.campaign.brokerDetails.brokerName"
      :state="isValid.fields.brokerDetails.brokerName"
    >
      <b-form-input
        type="text"
        id="campaign-broker"
        v-model="campaignForm.brokerDetails.brokerName"
        :state="isValid.fields.brokerDetails.brokerName"
        @blur="checkBrokersDetails()"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      v-if="!$rent"
      class="my-2"
      label="Skype Name"
      label-for="broker-skype-name"
      :invalid-feedback="invalidFeedback.campaign.brokerDetails.skypeName"
      :state="isValid.fields.brokerDetails.skypeName"
    >
      <b-form-input
        type="text"
        id="broker-skype-name"
        v-model="campaignForm.brokerDetails.skypeName"
        :state="isValid.fields.brokerDetails.skypeName"
        @blur="checkBrokersDetails()"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      v-if="!$rent"
      class="my-2"
      label="Skype User Name"
      label-for="broker-skype-user-name"
      :invalid-feedback="invalidFeedback.campaign.brokerDetails.skypeUserName"
      :state="isValid.fields.brokerDetails.skypeUserName"
    >
      <b-form-input
        type="text"
        id="broker-skype-user-name"
        v-model="campaignForm.brokerDetails.skypeUserName"
        :state="isValid.fields.brokerDetails.skypeUserName"
        @blur="checkBrokersDetails()"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      v-if="!$rent"
      class="my-2"
      label="Client Warning Status"
      label-for="broker-skype-user-name"
      :invalid-feedback="invalidFeedback.campaign.brokerDetails.rating"
      :state="isValid.fields.brokerDetails.rating"
    >
      <b-form-radio-group
        type="text"
        id="broker-skype-user-name"
        v-model="campaignForm.brokerDetails.rating"
        :state="isValid.fields.brokerDetails.rating"
        size="md"
        button-variant="outline-primary"
        :options="brokerRatingOptions"
        name="broker-skype-user-name"
        buttons
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      v-if="!$rent"
      class="my-2"
      label="Comment On Client"
      label-for="broker-campaign-comment"
      :invalid-feedback="invalidFeedback.campaign.brokerDetails.campaignComment"
      :state="isValid.fields.brokerDetails.campaignComment"
    >
      <b-form-textarea
        type="text"
        id="broker-campaign-comment"
        v-model="campaignForm.brokerDetails.campaignComment"
        :state="isValid.fields.brokerDetails.campaignComment"
        placeholder="Comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-card
      v-if="!$rent"
      :bg-variant="brokerCheckResultVariant"
      :text-variant="brokerCheckResultTextVariant"
      title="Broker Details Check Result"
    >
      <!--      <b-card-title>-->
      <!--      </b-card-title>-->
      <b-card-text>
        <h5 class="my-2">
          Rating:
          <b-badge :variant="brokerCheckResultVariant">{{ brokerCheckResult.worstRating }}</b-badge>
        </h5>
        <h5 class="my-2">Comments:</h5>
        <ul>
          <li v-for="(commentNode, j) in brokerCheckResult.generalizedComments" :key="j">
            <b-badge variant="light">
              <b-link :href="commentNode.campaign">{{ commentNode.copy }}</b-link>
            </b-badge>
            — {{ commentNode.comment }}
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <!--        </b-col>-->
    <!--        <b-col class="d-flex flex-column align-items-center">-->
    <b-form-group
      class="my-2"
      label="Geos"
      label-for="campaign-countries"
      :state="isValid.fields.countries"
      :invalid-feedback="invalidFeedback.campaign.countries"
    >
      <!--      :invalid-feedback="invalidFeedback.countries"
  -->
      <!--            <h6 class="my-2">Geos</h6>-->
      <multiselect
        id="campaign-countries"
        v-model="campaignForm.countries"
        :options="countryOptions"
        :closeOnSelect="false"
        track-by="value"
        @search-change="countryMultiSelectSearch"
        :multiple="true"
        :custom-label="countryWithCode"
        :state="isValid.fields.countries"
        :disabled="campaignForm._id && !(iAmOtherLevelTrustedUser || user.integrator)"
      >
        <!--              :showLabels="false"-->
      </multiselect>
    </b-form-group>
    <b-button
      class="mx-2"
      @click="selectAllCountries()"
      :disabled="campaignForm._id && !(iAmOtherLevelTrustedUser || user.integrator)"
    >
      Select all geos
    </b-button>
    <b-button
      class="mx-2"
      @click="clearCountries()"
      :disabled="campaignForm._id && !(iAmOtherLevelTrustedUser || user.integrator)"
    >
      Clear geos
    </b-button>

    <!--      <div-->
    <!--        v-if="user.email === 'blacklight@default.gg' || user.email === 'zerothreethree@default.gg'"-->
    <!--      >-->
    <div v-if="user.type === 'admin' || user.integrator">
      <b-form-group
        class="mt-5 mb-2"
        label="Broker API type"
        label-for="campaign-broker-api-type"
        :invalid-feedback="invalidFeedback.campaign.brokerApiType"
        :state="isValid.fields.brokerApiType"
      >
        <!--            <h6 class="my-2">Broker Api Type</h6>-->
        <!--        <b-form-select-->
        <!--          id="campaign-broker-api-type"-->
        <!--          v-model="campaignForm.brokerApiType"-->
        <!--          :options="brokerApiTypes"-->
        <!--          :state="isValid.fields.brokerApiType"-->
        <!--          @input="selectBrokerApiType()"-->
        <!--        ></b-form-select>-->

        <multiselect
          id="campaign-broker-api-type"
          v-model="campaignForm.brokerApiType"
          :options="brokerApiTypeOptions"
          :internal-search="false"
          @search-change="apiTypeMultiselectSearch"
          :state="isValid.fields.brokerApiType"
          @input="selectBrokerApiType()"
        ></multiselect>
      </b-form-group>

      <div class="my-3" v-if="campaignForm.brokerApiType === 'UniversalIntegration'">
        <b-form-group class="my-2" label="Broker API url" label-for="paste-json-field">
          <b-form-textarea
            type="text"
            id="paste-json-field"
            v-model="jsonToPaste"
            rows="6"
            max-rows="6"
          ></b-form-textarea>
          <b-button class="my-2" @click="pasteJson()" variant="warning">
            Paste Integration Settings
          </b-button>
          <b-button class="my-2" @click="copyFullSettingsToClipboard()" variant="info">
            Copy Integration Settings To Clipboard
          </b-button>
        </b-form-group>
      </div>

      <!--        </b-col>-->
      <!--        <b-col v-if="campaignForm.brokerApiType && campaignForm.brokerApiType !== 'TECH_CAMPAIGN'">-->
      <div v-if="campaignForm.brokerApiType && campaignForm.brokerApiType !== 'TECH_CAMPAIGN'">
        <div class="mt-2 mb-5">
          <!--        campaignForm.brokerApiType === 'Trackbox'-->
          <b-card
            v-if="false"
            align="center"
            border-variant="warning"
            header="⚠️ Attention!"
            header-bg-variant="transparent"
            header-text-variant="dark"
            footer-bg-variant="transparent"
            class="my-2"
          >
            <b-card-text align="center">
              To make Trackbox integration work, carefully follow this list.
            </b-card-text>
            <ol>
              <li class="my-1">
                Make sure you can login to your Trackbox account with username and password that you
                provide in auth headers.
              </li>
              <li class="my-1">
                <b>DO NOT</b>
                use email as Trackbox username in headers. Use only your trackbox username. Email
                and username are different things in Trackbox. You can find it in your Trackbox
                profile settings: "My contact information -> Username"
              </li>
              <li class="my-1">
                Make sure you use corresponding API keys for pushing and pulling. These keys can be
                different. Check both API keys with trackbox API documentation that they provided to
                you.
              </li>
            </ol>
          </b-card>
          <b-form-checkbox v-model="campaignForm.dontUseRequestInterceptor">
            Don't use request interceptor
          </b-form-checkbox>
          <b-form-group
            class="my-2"
            label="Broker API url"
            label-for="campaign-broker-api-url"
            :invalid-feedback="invalidFeedback.campaign.brokerApiURL"
            :state="isValid.fields.brokerApiURL"
          >
            <!--            <h6 class="mt-2">Broker Api URL</h6>-->
            <b-form-input
              type="text"
              id="campaign-broker-api-url"
              v-model="campaignForm.brokerApiURL"
              :state="isValid.fields.brokerApiURL"
            ></b-form-input>
          </b-form-group>

          <h6 class="mt-3">Broker Auth Headers</h6>
          <label class="mb-2" style="color: #00a700">
            &lt;Header&gt;: &lt;Header's Content&gt;
          </label>
          <b-button @click="addAuthHeader()" class="ml-2" variant="info">
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-form inline v-for="(authHeader, i) in campaignForm.brokerAuthHeaders" :key="i">
            <b-form-input
              class="array-item-inline"
              type="text"
              v-model="authHeader[0]"
              :state="isValid.fields.brokerAuthHeaders[i][0]"
            ></b-form-input>
            <b-form-input
              class="array-item-inline"
              type="text"
              v-model="authHeader[1]"
              :state="isValid.fields.brokerAuthHeaders[i][1]"
            ></b-form-input>
            <b-button @click="removeAuthHeader(i)" variant="danger">
              <font-awesome-icon icon="trash-alt"></font-awesome-icon>
            </b-button>
          </b-form>

          <h6 class="mb-2 mt-3">Auth Body</h6>
          <label class="mb-2" style="color: #00a700">
            &lt;Parameter&gt;: &lt;Parameter's Value&gt;
          </label>
          <b-button @click="addAuthBody()" class="ml-2" variant="info">
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-form inline v-for="(authBodyParam, i) in campaignForm.brokerAuthBody" :key="'A' + i">
            <b-form-input
              class="array-item-inline"
              type="text"
              v-model="authBodyParam[0]"
              :state="isValid.fields.brokerAuthBody[i][0]"
            ></b-form-input>

            <b-form-input
              class="array-item-inline"
              type="text"
              v-model="authBodyParam[1]"
              :state="isValid.fields.brokerAuthBody[i][1]"
            ></b-form-input>
            <b-button @click="removeAuthBody(i)" variant="danger">
              <font-awesome-icon icon="trash-alt"></font-awesome-icon>
            </b-button>
          </b-form>
        </div>

        <div class="my-5">
          <b-form-group
            class="my-2"
            label="Pulling url"
            label-for="campaign-broker-pull-url"
            :invalid-feedback="invalidFeedback.campaign.brokerPullURL"
            :state="isValid.fields.brokerPullURL"
          >
            <b-form-input
              type="text"
              id="campaign-broker-pull-url"
              v-model="campaignForm.brokerPullURL"
              :state="isValid.fields.brokerPullURL"
            ></b-form-input>
          </b-form-group>

          <h6 class="mt-3">Pulling Auth Headers</h6>
          <label class="mb-2" style="color: #00a700">
            &lt;Header&gt;: &lt;Header's Content&gt;
          </label>
          <b-button @click="addPullAuthHeader()" class="ml-2" variant="info">
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-form inline v-for="(authHeader, i) in campaignForm.brokerPullAuthHeaders" :key="i">
            <b-form-input
              class="array-item-inline"
              type="text"
              v-model="authHeader[0]"
              :state="isValid.fields.brokerPullAuthHeaders[i][0]"
            ></b-form-input>
            <b-form-input
              class="array-item-inline"
              type="text"
              v-model="authHeader[1]"
              :state="isValid.fields.brokerPullAuthHeaders[i][1]"
            ></b-form-input>
            <b-button @click="removePullAuthHeader(i)" variant="danger">
              <font-awesome-icon icon="trash-alt"></font-awesome-icon>
            </b-button>
          </b-form>
        </div>

        <div class="my-5">
          <h6 class="my-2">Api Mapping</h6>
          <b-form-group
            class="my-2"
            label="email"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="email-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.email"
            :state="isValid.fields.brokerApiMapping.email"
          >
            <!--            <label for="email-api-mapping">email</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.email"
              id="email-api-mapping"
              :state="isValid.fields.brokerApiMapping.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="password"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="password-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.password"
            :state="isValid.fields.brokerApiMapping.password"
          >
            <!--            <label for="password-api-mapping">password</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.password"
              id="password-api-mapping"
              :state="isValid.fields.brokerApiMapping.password"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="firstname"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="firstname-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.firstname"
            :state="isValid.fields.brokerApiMapping.firstname"
          >
            <!--            <label for="firstname-api-mapping">firstname</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.firstname"
              id="firstname-api-mapping"
              :state="isValid.fields.brokerApiMapping.firstname"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="lastname"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="lastname-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.lastname"
            :state="isValid.fields.brokerApiMapping.lastname"
          >
            <!--            <label for="lastname-api-mapping">lastname</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.lastname"
              id="lastname-api-mapping"
              :state="isValid.fields.brokerApiMapping.lastname"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="phone"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="phone-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.phone"
            :state="isValid.fields.brokerApiMapping.phone"
          >
            <!--            <label for="phone-api-mapping">phone</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.phone"
              id="phone-api-mapping"
              :state="isValid.fields.brokerApiMapping.phone"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="language"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="language-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.language"
            :state="isValid.fields.brokerApiMapping.language"
          >
            <!--            <label for="language-api-mapping">language</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.language"
              id="language-api-mapping"
              :state="isValid.fields.brokerApiMapping.language"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="country"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="country-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.country"
            :state="isValid.fields.brokerApiMapping.country"
          >
            <!--            <label for="country-api-mapping">country</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.country"
              id="country-api-mapping"
              :state="isValid.fields.brokerApiMapping.country"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="ip"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="ip-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.ip"
            :state="isValid.fields.brokerApiMapping.ip"
          >
            <!--            <label for="ip-api-mapping">ip</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.ip"
              id="ip-api-mapping"
              :state="isValid.fields.brokerApiMapping.ip"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="geoip_country"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="geoip_country-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.geoip_country"
            :state="isValid.fields.brokerApiMapping.geoip_country"
          >
            <!--            <label for="geoip_country-api-mapping">geoip_country</label>-->
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.geoip_country"
              id="geoip_country-api-mapping"
              :state="isValid.fields.brokerApiMapping.geoip_country"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="so"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="so-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.so"
            :state="isValid.fields.brokerApiMapping.so"
          >
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.so"
              id="so-api-mapping"
              :state="isValid.fields.brokerApiMapping.so"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="my-2"
            label="aff_id"
            label-cols="auto"
            label-cols-md="2"
            label-class="text-align-right"
            label-for="aff_id-api-mapping"
            :invalid-feedback="invalidFeedback.campaign.brokerApiMapping.aff_id"
            :state="isValid.fields.brokerApiMapping.aff_id"
          >
            <b-form-input
              type="text"
              v-model="campaignForm.brokerApiMapping.aff_id"
              id="aff_id-api-mapping"
              :state="isValid.fields.brokerApiMapping.aff_id"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>

    <b-row>
      <b-col cols="12" lg="6">
        <div v-if="user.type === 'admin'">
          <h5>Affiliate Ids</h5>
          <b-button v-b-toggle.collapse-affiliate-ids class="m-1">
            <span class="when-open">Hide</span>
            <span class="when-closed">Show</span>
          </b-button>
          <b-collapse id="collapse-affiliate-ids" visible>
            <div
              class="tab"
              style="background-color: white !important"
              id="affiliate-ids-filter-div"
            >
              <b-form-group label="aff_id" label-for="affiliate-ids-filter-value" class="m-2">
                <b-form-input v-model="affiliateIdsFilter.value"></b-form-input>
              </b-form-group>
              <b-form-group label="Geos" label-for="affiliate-ids-filter-geo" class="m-2">
                <multiselect
                  id="affiliate-ids-filter-geo"
                  v-model="affiliateIdsFilter.geos"
                  :options="generatedFieldGeoOptions"
                  :closeOnSelect="false"
                  track-by="value"
                  @search-change="generatedFieldGeoSearch"
                  :multiple="true"
                  :custom-label="countryWithCode"
                ></multiselect>
              </b-form-group>
              <b-button class="mx-2" @click="selectAllAffiliateIdCountries_Filter()">
                Select all geos
              </b-button>
              <b-button class="mx-2" @click="clearAffiliateIdCountries_Filter()">
                Clear geos
              </b-button>
            </div>
            <b-button @click="addAffiliateId()" class="m-2" variant="info">
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </b-button>
            <div
              v-for="(id, index) in affiliateIdsToShow"
              :key="index"
              class="tab"
              style="background-color: white !important"
            >
              <b-button @click="removeAffiliateId(id.item_index)" variant="danger" class="mx-2">
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </b-button>
              <b-button
                @click="affiliateId_putDefaultSettings(id.item_index)"
                variant="warning"
                class="mx-2"
              >
                Reset To Default
              </b-button>
              <b-form-group
                class="my-2"
                label="aff_id"
                label-cols="auto"
                label-cols-md="2"
                label-class="text-align-right"
                :label-for="`${id.item_index}-aff_id-value`"
              >
                <b-form-input
                  type="text"
                  v-model="campaignForm.affiliateIds[id.item_index].value"
                  :id="`${id.item_index}-aff_id-value`"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="R_Weight"
                label-cols="auto"
                label-cols-md="2"
                label-class="text-align-right"
                :label-for="`${id.item_index}-aff_id-rWeight`"
              >
                <b-form-input
                  type="number"
                  v-model="campaignForm.affiliateIds[id.item_index].rWeight"
                  :id="`${id.item_index}-aff_id-rWeight`"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="M_Weight"
                label-cols="auto"
                label-cols-md="2"
                label-class="text-align-right"
                :label-for="`${id.item_index}-aff_id-mWeight`"
              >
                <b-form-input
                  type="number"
                  v-model="campaignForm.affiliateIds[id.item_index].mWeight"
                  :id="`${id.item_index}-aff_id-mWeight`"
                ></b-form-input>
              </b-form-group>
              <b-row>
                <b-col cols="6">
                  <h6 class="mb-2">Hour Limit</h6>
                  <h6 class="mr-1">
                    From: {{ campaignForm.affiliateIds[id.item_index].hourLimit[0] }}:00
                  </h6>
                  <b-form-input
                    v-model="campaignForm.affiliateIds[id.item_index].hourLimit[0]"
                    type="range"
                    min="0"
                    :max="23"
                  ></b-form-input>
                  <h6 class="mx-1 my-2">
                    to: {{ campaignForm.affiliateIds[id.item_index].hourLimit[1] }}:59
                  </h6>
                  <b-form-input
                    v-model="campaignForm.affiliateIds[id.item_index].hourLimit[1]"
                    type="range"
                    :min="0"
                    max="23"
                  ></b-form-input>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    class="my-2"
                    label="Days"
                    :label-for="`${id.item_index}-aff_id-days`"
                  >
                    <b-form-checkbox-group
                      :id="`${id.item_index}-aff_id-days`"
                      v-model="campaignForm.affiliateIds[id.item_index].days"
                      :options="daysOfWeek"
                      class="mb-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      size="lg"
                      stacked
                    ></b-form-checkbox-group>
                    <b-button
                      class="mr-2"
                      variant="primary"
                      @click="campaignForm.affiliateIds[id.item_index].days = [0, 1, 2, 3, 4, 5, 6]"
                    >
                      All
                    </b-button>
                    <b-button @click="campaignForm.affiliateIds[id.item_index].days = []">
                      None
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group class="my-2" label="Geos" :label-for="`${id.item_index}-aff_id-geos`">
                <multiselect
                  :id="`${id.item_index}-aff_id-geos`"
                  v-model="campaignForm.affiliateIds[id.item_index].geos"
                  :options="generatedFieldGeoOptions"
                  :closeOnSelect="false"
                  track-by="value"
                  @search-change="generatedFieldGeoSearch"
                  :multiple="true"
                  :custom-label="countryWithCode"
                ></multiselect>
              </b-form-group>
              <b-button class="mx-2" @click="selectAllAffiliateIdCountries(id.item_index)">
                Select all geos
              </b-button>
              <b-button class="mx-2" @click="clearAffiliateIdCountries(id.item_index)">
                Clear geos
              </b-button>
            </div>
          </b-collapse>
        </div>
      </b-col>

      <b-col cols="12" lg="6">
        <div v-if="user.type === 'admin'">
          <h5>Sources</h5>
          <b-button v-b-toggle.collapse-sources class="m-1">
            <span class="when-open">Hide</span>
            <span class="when-closed">Show</span>
          </b-button>
          <b-collapse id="collapse-sources" visible>
            <div class="tab" style="background-color: white !important" id="sources-filter-div">
              <b-form-group label="Source" label-for="sources-filter-value" class="m-2">
                <b-form-input v-model="sourcesFilter.value"></b-form-input>
              </b-form-group>
              <b-form-group label="Geos" label-for="sources-filter-geo" class="m-2">
                <multiselect
                  id="sources-filter-geo"
                  v-model="sourcesFilter.geos"
                  :options="generatedFieldGeoOptions"
                  :closeOnSelect="false"
                  track-by="value"
                  @search-change="generatedFieldGeoSearch"
                  :multiple="true"
                  :custom-label="countryWithCode"
                ></multiselect>
              </b-form-group>
              <b-button class="mx-2" @click="selectAllSourceCountries_Filter()">
                Select all geos
              </b-button>
              <b-button class="mx-2" @click="clearSourceCountries_Filter()">Clear geos</b-button>
            </div>
            <b-button @click="addSource()" class="m-2" variant="info">
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </b-button>
            <div
              v-for="(id, index) in sourcesToShow"
              :key="index"
              class="tab"
              style="background-color: white !important"
            >
              <b-button @click="removeSource(id.item_index)" variant="danger" class="mx-2">
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </b-button>
              <b-button
                @click="source_putDefaultSettings(id.item_index)"
                variant="warning"
                class="mx-2"
              >
                Reset To Default
              </b-button>
              <b-form-group
                class="my-2"
                label="source"
                label-cols="auto"
                label-cols-md="2"
                label-class="text-align-right"
                :label-for="`${id.item_index}-source-value`"
              >
                <b-form-input
                  type="text"
                  v-model="campaignForm.sources[id.item_index].value"
                  :id="`${id.item_index}-source-value`"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="R_Weight"
                label-cols="auto"
                label-cols-md="2"
                label-class="text-align-right"
                :label-for="`${id.item_index}-source-rWeight`"
              >
                <b-form-input
                  type="number"
                  v-model="campaignForm.sources[id.item_index].rWeight"
                  :id="`${id.item_index}-source-rWeight`"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="my-2"
                label="M_Weight"
                label-cols="auto"
                label-cols-md="2"
                label-class="text-align-right"
                :label-for="`${id.item_index}-source-mWeight`"
              >
                <b-form-input
                  type="number"
                  v-model="campaignForm.sources[id.item_index].mWeight"
                  :id="`${id.item_index}-source-mWeight`"
                ></b-form-input>
              </b-form-group>
              <b-row>
                <b-col cols="6">
                  <h6 class="mb-2">Hour Limit</h6>
                  <h6 class="mr-1">
                    From: {{ campaignForm.sources[id.item_index].hourLimit[0] }}:00
                  </h6>
                  <b-form-input
                    v-model="campaignForm.sources[id.item_index].hourLimit[0]"
                    type="range"
                    min="0"
                    :max="23"
                  ></b-form-input>
                  <h6 class="mx-1 my-2">
                    to: {{ campaignForm.sources[id.item_index].hourLimit[1] }}:59
                  </h6>
                  <b-form-input
                    v-model="campaignForm.sources[id.item_index].hourLimit[1]"
                    type="range"
                    :min="0"
                    max="23"
                  ></b-form-input>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    class="my-2"
                    label="Days"
                    :label-for="`${id.item_index}-source-days`"
                  >
                    <b-form-checkbox-group
                      :id="`${id.item_index}-source-days`"
                      v-model="campaignForm.sources[id.item_index].days"
                      :options="daysOfWeek"
                      class="mb-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      size="lg"
                      stacked
                    ></b-form-checkbox-group>
                    <b-button
                      class="mr-2"
                      variant="primary"
                      @click="campaignForm.sources[id.item_index].days = [0, 1, 2, 3, 4, 5, 6]"
                    >
                      All
                    </b-button>
                    <b-button @click="campaignForm.sources[id.item_index].days = []">None</b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group class="my-2" label="Geos" :label-for="`${id.item_index}-source-geos`">
                <multiselect
                  :id="`${id.item_index}-source-geos`"
                  v-model="campaignForm.sources[id.item_index].geos"
                  :options="generatedFieldGeoOptions"
                  :closeOnSelect="false"
                  track-by="value"
                  @search-change="generatedFieldGeoSearch"
                  :multiple="true"
                  :custom-label="countryWithCode"
                ></multiselect>
              </b-form-group>
              <b-button class="mx-2" @click="selectAllSourceCountries(id.item_index)">
                Select all geos
              </b-button>
              <b-button class="mx-2" @click="clearSourceCountries(id.item_index)">
                Clear geos
              </b-button>
            </div>
          </b-collapse>
        </div>
      </b-col>
    </b-row>

    <div v-if="campaignForm.brokerApiType === 'UniversalIntegration'" class="my-3">
      <b-button @click="insertTestData" variant="primary" class="m-2">Insert Test Data</b-button>
      <UniversalIntegration
        :settings="campaignForm.universalIntegrationSettings"
        :key="childKey"
        @edit="handleEditEvent($event, 'universalIntegrationSettings', 'campaignForm')"
        @validate="
          handleValidateEvent(
            $event,
            'universalIntegrationSettings',
            'universalIntegration_valid',
            true,
            'deepBackdoor'
          )
        "
      ></UniversalIntegration>
    </div>
    <!--    <p>universalIntegration_valid {{ universalIntegration_valid }}</p>-->
    <!--    <p>deepValid.fields: {{ deepValid.fields }}</p>-->
    <!--    <p>deepValid.form: {{ deepValid.form }}</p>-->

    <!--        </b-col>-->
    <!--        <b-col class="d-flex flex-column align-items-center">-->
    <div class="my-5">
      <b-row v-if="campaignForm.brokerApiType !== 'TECH_CAMPAIGN'">
        <b-col cols="12" lg="6">
          <b-form-group
            label="Daily Cap"
            class="my-2"
            label-class="mr-2"
            label-for="campaign-daily-cap-type"
            :invalid-feedback="invalidFeedback.dailyCap.type"
            :state="isValid.fields.dailyCap.type"
          >
            <!--        :valid-feedback="validFeedback"-->
            <!--            <h6 class="mb-2">Daily Cap</h6>-->
            <b-form-select
              id="campaign-daily-cap-type"
              v-model="campaignForm.dailyCap.type"
              :options="actionTypeOptions"
              :state="isValid.fields.dailyCap.type"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group
            label="Amount"
            class="my-2"
            label-class="mr-2"
            label-for="campaign-daily-cap-amount"
            :invalid-feedback="invalidFeedback.dailyCap.amount"
            :state="isValid.fields.dailyCap.amount"
          >
            <!--        :valid-feedback="validFeedback"-->
            <!--            <h6 class="my-2">Amount</h6>-->
            <b-form-input
              id="campaign-daily-cap-amount"
              v-model="campaignForm.dailyCap.amount"
              :state="isValid.fields.dailyCap.amount"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <!--        </b-col>-->
      <!--        <b-col class="d-flex flex-column align-items-center">-->
      <b-row v-if="campaignForm.brokerApiType !== 'TECH_CAMPAIGN'">
        <b-col cols="12" lg="6">
          <b-form-group
            class="my-2"
            label-class="mr-2"
            label="Total Cap"
            label-for="campaign-total-cap-type"
            :invalid-feedback="invalidFeedback.totalCap.type"
            :state="isValid.fields.totalCap.type"
          >
            <!--            <h6 class="mb-2">Total Cap</h6>-->
            <b-form-select
              id="campaign-total-cap-type"
              v-model="campaignForm.totalCap.type"
              :options="actionTypeOptions"
              :state="isValid.fields.totalCap.type"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group
            label="Amount"
            class="my-2"
            label-class="mr-2"
            label-for="campaign-total-cap-amount"
            :invalid-feedback="invalidFeedback.totalCap.amount"
            :state="isValid.fields.totalCap.amount"
          >
            <!--            <h6 class="my-2">Amount</h6>-->
            <b-form-input
              id="campaign-total-cap-amount"
              v-model="campaignForm.totalCap.amount"
              :state="isValid.fields.totalCap.amount"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!--        </b-col>-->
    <!--        <b-col class="d-flex flex-column align-items-center">-->
    <div class="my-5">
      <b-form-group
        v-if="user.type === 'admin'"
        class="my-2"
        label="Managers"
        label-for="campaign-managers"
        :invalid-feedback="invalidFeedback.campaign.managers"
        :state="isValid.fields.managers"
      >
        <!--            <h6 class="my-2">Managers</h6>-->
        <multiselect
          id="campaign-managers"
          v-model="campaignForm.managers"
          :state="isValid.fields.managers"
          :options="managersOptions"
          track-by="name"
          label="name"
          :closeOnSelect="false"
          :multiple="true"
        ></multiselect>
      </b-form-group>
      <p v-else>Manager: {{ user.name }}</p>
    </div>
    <div class="my-5">
      <b-row>
        <b-col cols="12" lg="6">
          <b-form-group
            label="Default payment"
            class="my-2"
            label-class="mr-2"
            label-for="default-payment"
            :invalid-feedback="invalidFeedback.campaign.defaultPayment"
            :state="isValid.fields.defaultPayment"
          >
            <!--        :valid-feedback="validFeedback"-->
            <!--            <h6 class="my-2">Amount</h6>-->
            <b-form-input
              id="default-payment"
              v-model="campaignForm.defaultPayment"
              :state="isValid.fields.defaultPayment"
            ></b-form-input>
          </b-form-group>
          <CBFinanceSettings
            :countries="campaignForm.countries"
            v-model="campaignForm.countryPayments"
          ></CBFinanceSettings>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group
            label="Affiliate default payment"
            class="my-2"
            label-class="mr-2"
            label-for="aff-default-payment"
            :invalid-feedback="invalidFeedback.campaign.aff_defaultPayment"
            :state="isValid.fields.aff_defaultPayment"
          >
            <!--        :valid-feedback="validFeedback"-->
            <!--            <h6 class="my-2">Amount</h6>-->
            <b-form-input
              id="aff-default-payment"
              v-model="campaignForm.aff_defaultPayment"
              :state="isValid.fields.aff_defaultPayment"
            ></b-form-input>
          </b-form-group>
          <CBFinanceSettings
            :countries="campaignForm.countries"
            v-model="campaignForm.aff_countryPayments"
            cid="aff"
          ></CBFinanceSettings>
        </b-col>
      </b-row>
      <!--
          v-on:update="updateCountryPayments($event)"-->
      <!--        <p>{{ campaignForm.countryPayments }}</p>-->
    </div>
    <!--        </b-col>-->
    <!--      </b-row>-->

    <div class="d-flex justify-content-between mb-3">
      <b-button @click="saveCampaign()" variant="primary" :disabled="!isValid.form || requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        {{ $route.params.id ? 'Save Campaign' : 'Create Campaign' }}
      </b-button>
    </div>
    <div v-if="!isValid.form || requesting" class="my-2">
      <h5>Cannot save. Reasons:</h5>
      <ul v-if="!isValid.form">
        <li
          v-for="(f, i) in Object.keys(isValid.fields).filter((field) => !isValid.fields[field])"
          :key="i"
        >
          {{ f }}
        </li>
      </ul>
      <ul v-else>
        Requesting in progress
      </ul>
    </div>
    <!--        </div>-->
    <!--        <h3 v-else>This campaign is not ready</h3>-->
    <!--        </b-form>-->
    <!--    <div class="my-5">-->
    <!--      <b-button @click="insertTestData" variant="primary" class="m-2">Insert Test Data</b-button>-->
    <!--      <UniversalIntegration-->
    <!--        :settings="testform.universalIntegrationSettings"-->
    <!--        :key="childKey"-->
    <!--        @edit="handleEditEvent($event, 'universalIntegrationSettings', 'testform')"-->
    <!--        @validate="-->
    <!--          handleValidateEvent($event, 'universalIntegrationSettings', 'testform_valid', true)-->
    <!--        "-->
    <!--      ></UniversalIntegration>-->
    <!--    </div>-->
    <!--    <p>testform: {{ testform }}</p>-->
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import '@/assets/form.scss';
import { getCampaign, saveCampaign, checkBrokersDetails } from '@/utils/api';
import {
  castCountriesFromServer,
  deleteUnchangedFields,
  handleRequestError,
  isAdmin,
  isManager,
  showCard,
  countryMultiSelectSearch,
  apiTypeMultiselectSearch,
} from '@/utils';
import {
  actionTypeOptions,
  brokerApiTypes,
  // defaultApiMapping,
  countryOptions,
  validFeedback,
  invalidFeedback,
  // defaultTrackboxApiMapping,
  // defaultCrmV2ApiMapping,
  defaultIntegrationSettings,
  OTHER_LEVEL_TRUSTED_USERS,
  testIntegration,
  daysOfWeek,
} from '@/consts';
import { isValid, formValid, castAuthArrayToObject, castAuthObjectToArray } from '@/validators';
import { noCap } from '@/validators/_utils';
import _ from 'lodash';
import store from '@/store';
import CBFinanceSettings from '@/components/shared/CBFinanceSettings.vue';
import handleEditEvent from '@/utils/handleEditEvent';
import handleEditEventDirectKey from '@/utils/handleEditEventDirectKey';
import handleValidateEvent from '@/utils/handleValidateEvent';
import handleMultipleKeysValidateEvent from '@/utils/handleMultipleKeysValidateEvent';
import UniversalIntegration from '@/components/pages/CreateCampaign/CreateCampaign/UniversalIntegration/UniversalIntegration.vue';
import $ from 'jquery';
import {
  GeneratedLeadFieldValue,
  generatedFieldGeoOptions,
} from '@/models/GeneratedLeadFieldValue';

const itemInitialValues = {
  _id: undefined,
  // broker: undefined,
  brokerApiType: undefined, // 'TECH_CAMPAIGN',
  dontUseRequestInterceptor: false,
  brokerApiURL: undefined,
  brokerPullURL: undefined,
  brokerAuthHeaders: [], // [[undefined, undefined]],
  brokerAuthBody: [], // [[undefined, undefined]],
  brokerPullAuthHeaders: [], // [[undefined, undefined]],
  brokerApiMapping: defaultIntegrationSettings.default.brokerApiMapping,
  universalIntegrationSettings: {},
  name: undefined,
  active: true,
  sendM: true,
  blockedByAdmin: false,
  pulling: false,
  ready: false,
  paranoid: false,
  paranoid_xm: false,
  limit1MPerGeo: false,
  forbidLinkChecking: false,
  isBroker: false,
  canHaveFinalReceiverInPullingAPIAnswer: false,
  managers: [],
  dailyCap: { type: undefined, amount: undefined },
  totalCap: { type: undefined, amount: undefined },
  countries: [],
  defaultPayment: 0,
  aff_defaultPayment: 0,
  currency: 'USD',
  countryPayments: [],
  aff_countryPayments: [],
  brokerDetails: {
    brokerName: '',
    skypeName: '',
    skypeUserName: '',
    rating: 'OK',
    campaignComment: '',
  },
  dailyFtdsLimit: undefined,
  affiliateIds: [],
  sources: [],
};

// eslint-disable-next-line no-restricted-syntax
for (const k of [
  'brokerApiURL',
  'brokerPullURL',
  'brokerAuthHeaders',
  'brokerAuthBody',
  'brokerPullAuthHeaders',
  'brokerApiMapping',
]) {
  if (Object.keys(defaultIntegrationSettings.default).includes(k))
    itemInitialValues[k] = _.cloneDeep(defaultIntegrationSettings.default[k]);
}

export default {
  name: 'CreateCampaign',
  components: {
    UniversalIntegration,
    CBFinanceSettings,
  },
  data() {
    return {
      childKey: 0,
      jsonToPaste: '',
      testform: {
        universalIntegrationSettings: _.cloneDeep(testIntegration),
      }, // SendLead: { ResultIdPath: [] }, kek: 'pek'
      testform_valid: {},
      universalIntegration_valid: { universalIntegrationSettings: false },
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      validFeedback,
      invalidFeedback,
      backdoor: 0,
      deepBackdoor: 0,
      brokerApiTypes,
      brokerApiTypeOptions: brokerApiTypes,
      actionTypeOptions,
      affiliate: 'Affiliate 1',
      campaignForm: _.cloneDeep(itemInitialValues),
      initialForm: {},
      countryOptions,
      crg: false,
      crgRate: 0,
      brokerRatingOptions: ['OK', 'Careful', 'NoWork'],
      brokerCheckResult: {
        GO_Details: [],
        generalizedComments: [],
        worstRating: 'UNKNOWN',
        worstNumberRating: -1,
      },
      brokerDetailsBackdoor: 0,
      generatedFieldGeoOptions,
      daysOfWeek,
      affiliateIdsFilter: { value: '', geos: [] },
      sourcesFilter: { value: '', geos: [] },
      affid_backdoor: 0,
      source_backdoor: 0,
    };
  },
  computed: {
    ...mapState(['token', 'campaigns', 'affiliates', 'managers', 'requesting', 'user']),
    isValid() {
      this.backdoor;
      const fields = isValid('campaign', this.campaignForm, this.campaignForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
    deepValid() {
      this.deepBackdoor;
      const deepForm =
        this.campaignForm.brokerApiType === 'UniversalIntegration'
          ? this.universalIntegration_valid
          : {};
      const fields = {
        ...deepForm,
        ...this.isValid.fields,
      };
      const form = formValid(deepForm) && this.isValid.form;
      return { fields, form };
    },
    iAmOtherLevelTrustedUser() {
      return OTHER_LEVEL_TRUSTED_USERS.includes(this.user.email);
    },
    managersOptions() {
      return this.managers.map((m) => {
        return { name: m.name, _id: m._id };
      });
    },
    brokerCheckResultVariant() {
      this.brokerDetailsBackdoor;
      return this.brokerCheckResult.worstNumberRating === 0
        ? 'success'
        : this.brokerCheckResult.worstNumberRating === 1
        ? 'warning'
        : this.brokerCheckResult.worstNumberRating === 2
        ? 'danger'
        : 'secondary';
    },
    brokerCheckResultTextVariant() {
      this.brokerDetailsBackdoor;
      return this.brokerCheckResult.worstNumberRating === 0
        ? 'white'
        : this.brokerCheckResult.worstNumberRating === 1
        ? 'black'
        : this.brokerCheckResult.worstNumberRating === 2
        ? 'white'
        : 'white';
    },
    affiliateIdsToShow() {
      this.affid_backdoor;
      const result = GeneratedLeadFieldValue.castToShowItemsArray(
        this.campaignForm.affiliateIds,
        this.affiliateIdsFilter
      );
      console.log('affiliateIdsToShow', result);
      return result;
    },
    sourcesToShow() {
      this.source_backdoor;
      const result = GeneratedLeadFieldValue.castToShowItemsArray(
        this.campaignForm.sources,
        this.sourcesFilter
      );
      console.log('sourcesToShow', result);
      return result;
    },
  },
  methods: {
    insertTestData() {
      this.campaignForm.universalIntegrationSettings = _.cloneDeep(testIntegration);
      this.childKey += 1;
    },
    handleEditEvent,
    handleEditEventDirectKey,
    handleValidateEvent,
    handleMultipleKeysValidateEvent,
    // handleValidateEvent(data, key, formKey) {
    //   handleValidateEvent(this, data, key, formKey);
    // },
    // handleMultipleKeysValidateEvent(data, keys, formKey) {
    //   handleMultipleKeysValidateEvent(this, data, keys, formKey);
    // },
    handelSendLeadParametersEdit(data) {
      this.ResultIdPath = data ? _.cloneDeep(data.ResultIdPath) : undefined;
    },
    countryMultiSelectSearch,
    generatedFieldGeoSearch(query) {
      const options = generatedFieldGeoOptions;
      const formattedQuery = query.trim().toLowerCase();

      const byValue = options.filter(({ value }) => {
        const formattedValue = value.trim().toLowerCase();
        return formattedValue === formattedQuery;
      });

      const byText = options.filter(({ text }) => {
        const formattedText = text.trim().toLowerCase();
        return formattedText.includes(formattedQuery);
      });

      const searchResult = [...byValue, ...byText];
      const searchResultWithoutDuplicates = searchResult.filter(
        ({ value }, index, array) =>
          index === array.findIndex(({ value: value2 }) => value2 === value)
      );

      this.generatedFieldGeoOptions = searchResultWithoutDuplicates;
    },
    apiTypeMultiselectSearch,
    // updateCountryPayments(e) {
    //   console.log('campaignUpdateCountryPayments event', e);
    //   this.campaignForm.countryPayments = e;
    //   console.log(this.campaignForm.countryPayments);
    // },
    selectAllCountries() {
      this.campaignForm.countries = _.cloneDeep(countryOptions);
    },
    clearCountries() {
      this.campaignForm.countries = _.cloneDeep(itemInitialValues.countries);
    },
    selectBrokerApiType() {
      if (!this.campaignForm._id) {
        const apiTypeSettings = Object.keys(defaultIntegrationSettings).includes(
          this.campaignForm.brokerApiType
        )
          ? defaultIntegrationSettings[this.campaignForm.brokerApiType]
          : defaultIntegrationSettings.default;
        // eslint-disable-next-line no-restricted-syntax
        for (const k of [
          'brokerApiURL',
          'brokerPullURL',
          'brokerAuthHeaders',
          'brokerAuthBody',
          'brokerPullAuthHeaders',
          'brokerApiMapping',
        ]) {
          this.campaignForm[k] = _.cloneDeep(
            Object.keys(apiTypeSettings).includes(k)
              ? apiTypeSettings[k]
              : defaultIntegrationSettings.default[k]
          );
        }
      }
    },
    createNewCampaign() {
      this.$router.push('/create-campaign');
    },
    countryWithCode({ text, value }) {
      return `${value} — ${text}`;
    },
    addAuthHeader() {
      this.campaignForm.brokerAuthHeaders.push([undefined, undefined]);
    },
    removeAuthHeader(index) {
      this.campaignForm.brokerAuthHeaders.splice(index, 1);
    },
    addAuthBody() {
      this.campaignForm.brokerAuthBody.push([undefined, undefined]);
    },
    removeAuthBody(index) {
      this.campaignForm.brokerAuthBody.splice(index, 1);
    },
    addPullAuthHeader() {
      this.campaignForm.brokerPullAuthHeaders.push([undefined, undefined]);
    },
    removePullAuthHeader(index) {
      this.campaignForm.brokerPullAuthHeaders.splice(index, 1);
    },
    addAffiliateId() {
      this.campaignForm.affiliateIds.push(new GeneratedLeadFieldValue());
      this.affid_backdoor += 1;
    },
    removeAffiliateId(index) {
      this.campaignForm.affiliateIds = GeneratedLeadFieldValue.deleteFromArray(
        this.campaignForm.affiliateIds,
        index
      );
      this.affid_backdoor += 1;
    },
    affiliateId_putDefaultSettings(index) {
      this.campaignForm.affiliateIds[index].putDefaultSettings();
      this.affid_backdoor += 1;
    },
    selectAllAffiliateIdCountries(index) {
      if (
        !Array.isArray(this.campaignForm.affiliateIds) ||
        this.campaignForm.affiliateIds.length <= index ||
        !this.campaignForm.affiliateIds[index]
      )
        return;
      this.campaignForm.affiliateIds[index].geos = _.cloneDeep(generatedFieldGeoOptions);
      this.affid_backdoor += 1;
    },
    clearAffiliateIdCountries(index) {
      if (
        !Array.isArray(this.campaignForm.affiliateIds) ||
        this.campaignForm.affiliateIds.length <= index ||
        !this.campaignForm.affiliateIds[index]
      )
        return;
      this.campaignForm.affiliateIds[index].geos = [];
      this.affid_backdoor += 1;
    },
    selectAllAffiliateIdCountries_Filter() {
      this.affiliateIdsFilter.geos = _.cloneDeep(generatedFieldGeoOptions);
    },
    clearAffiliateIdCountries_Filter() {
      this.affiliateIdsFilter.geos = [];
    },
    addSource() {
      this.campaignForm.sources.push(new GeneratedLeadFieldValue(undefined, 'string'));
      this.source_backdoor += 1;
    },
    removeSource(index) {
      this.campaignForm.sources = GeneratedLeadFieldValue.deleteFromArray(
        this.campaignForm.sources,
        index
      );
      this.source_backdoor += 1;
    },
    source_putDefaultSettings(index) {
      this.campaignForm.sources[index].putDefaultSettings();
      this.source_backdoor += 1;
    },
    selectAllSourceCountries(index) {
      if (
        !Array.isArray(this.campaignForm.sources) ||
        this.campaignForm.sources.length <= index ||
        !this.campaignForm.sources[index]
      )
        return;
      this.campaignForm.sources[index].geos = _.cloneDeep(generatedFieldGeoOptions);
      this.source_backdoor += 1;
    },
    clearSourceCountries(index) {
      if (
        !Array.isArray(this.campaignForm.sources) ||
        this.campaignForm.sources.length <= index ||
        !this.campaignForm.sources[index]
      )
        return;
      this.campaignForm.sources[index].geos = [];
      this.source_backdoor += 1;
    },
    selectAllSourceCountries_Filter() {
      this.sourcesFilter.geos = _.cloneDeep(generatedFieldGeoOptions);
    },
    clearSourceCountries_Filter() {
      this.sourcesFilter.geos = [];
    },
    saveCampaign() {
      if (this.deepValid.form) {
        this.$store.commit('make_requesting', { req: true });
        let toSave = _.cloneDeep(this.campaignForm);
        toSave.countries = toSave.countries.map((c) => c.value);
        toSave.managers = toSave.managers.map((c) => c._id);

        if (toSave.brokerApiType === 'TECH_CAMPAIGN') {
          toSave.dailyCap = false;
          toSave.totalCap = false;
          toSave.brokerAuthBody = [];
          toSave.brokerAuthHeaders = [];
          toSave.brokerApiURL = process.env.VUE_APP_BASE_URL;
        } else {
          toSave.brokerAuthHeaders = castAuthArrayToObject(toSave.brokerAuthHeaders);
          toSave.brokerAuthBody = castAuthArrayToObject(toSave.brokerAuthBody);
          toSave.brokerPullAuthHeaders = castAuthArrayToObject(toSave.brokerPullAuthHeaders);
          if (toSave.dailyCap && noCap(toSave.dailyCap)) toSave.dailyCap = false;
          if (toSave.totalCap && noCap(toSave.totalCap)) toSave.totalCap = false;
        }

        toSave.countryPayments = this.campaignForm.countryPayments.filter((c) => !c.isDefault);
        toSave.aff_countryPayments = this.campaignForm.aff_countryPayments.filter(
          (c) => !c.isDefault
        );
        toSave.crgRate = Math.floor(toSave.crgRate);
        toSave.affiliateIds = GeneratedLeadFieldValue.castToSendArray(toSave.affiliateIds);
        toSave.sources = GeneratedLeadFieldValue.castToSendArray(toSave.sources);

        toSave = deleteUnchangedFields(this.initialForm, this.campaignForm, toSave);
        if (this.campaignForm.brokerApiType === 'UniversalIntegration')
          toSave.brokerApiType = 'UniversalIntegration';
        saveCampaign(this.token, toSave)
          .then(async (r) => {
            if (r.status === 200) {
              showCard(this, `${r.data.name} campaign saved`, 'success');
              this.castCampaignToForm(r.data);
              this.backdoor += 1;
              if (this.$route.query.b) {
                await this.$router.push(
                  this.$route.query.b === 'new'
                    ? '/create-box' // ?b=true
                    : `/box/edit/${this.$route.query.b}` // ?b=true
                );
              }
              // this.$router.push('/campaigns');
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving campaign`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving campaign`);
          });
        this.$store.commit('make_requesting', { req: false });
      }
    },
    getCampaign(_id) {
      console.log('Getting campaign', _id);
      getCampaign(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castCampaignToForm(r.data);
            this.backdoor += 1;
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting campaign`;
            showCard(
              this,
              cardError,
              r.status === 500 ? 'danger' : 'warning',
              'Error getting campaign'
            );
          }
        })
        .catch((e) => {
          handleRequestError(
            e,
            'Unknown server error on getting campaign',
            'Error getting campaign'
          );
        });
    },
    castCampaignToForm(campaign) {
      const initial = _.cloneDeep(itemInitialValues);
      // cast campaign to edit form
      // prettier-ignore
      this.campaignForm = {
        _id: campaign._id ? campaign._id : initial.id,
        broker: campaign.broker,
        brokerApiType: campaign.brokerApiType,
        dontUseRequestInterceptor: campaign.dontUseRequestInterceptor,
        brokerApiURL: campaign.brokerApiURL,
        brokerAuthHeaders: castAuthObjectToArray(campaign.brokerAuthHeaders, initial.brokerAuthHeaders),
        brokerAuthBody: castAuthObjectToArray(campaign.brokerAuthBody, initial.brokerAuthBody),
        brokerPullURL: campaign.brokerPullURL ? campaign.brokerPullURL : initial.brokerPullURL,
        brokerPullAuthHeaders: castAuthObjectToArray(campaign.brokerPullAuthHeaders, initial.brokerPullAuthHeaders),
        brokerApiMapping: campaign.brokerApiMapping,
        universalIntegrationSettings: _.cloneDeep(campaign.universalIntegrationSettings ? campaign.universalIntegrationSettings : initial.universalIntegrationSettings),
        name: campaign.name,
        active: campaign.active,
        sendM: campaign.sendM,
        blockedByAdmin: campaign.blockedByAdmin,
        forbidLinkChecking: campaign.forbidLinkChecking,
        isBroker: campaign.isBroker ? campaign.isBroker : initial.isBroker,
        canHaveFinalReceiverInPullingAPIAnswer: campaign.canHaveFinalReceiverInPullingAPIAnswer ? campaign.canHaveFinalReceiverInPullingAPIAnswer : initial.canHaveFinalReceiverInPullingAPIAnswer,
        managers: campaign.managers.filter(manager => manager).map((manager) => {
          return {_id: manager._id ? manager._id : manager, name: manager.name ? manager.name : 'unknown' };
        }),
        dailyCap: campaign.dailyCap ? campaign.dailyCap : initial.dailyCap,
        totalCap: campaign.totalCap ? campaign.totalCap : initial.totalCap,
        countries: castCountriesFromServer(campaign.countries),
        ready: campaign.ready ? campaign.ready: false,
        pulling: campaign.pulling,
        paranoid: campaign.paranoid || false,
        paranoid_xm: campaign.paranoid_xm || false,
        limit1MPerGeo: campaign.limit1MPerGeo || false,
        defaultPayment: campaign.defaultPayment,
        aff_defaultPayment: campaign.aff_defaultPayment,
        countryPayments: campaign.countryPayments.map(c=>{return {...c,isDefault:false}}),
        aff_countryPayments: campaign.aff_countryPayments.map(c=>{return {...c,isDefault:false}}),
        crg: campaign.crg,
        crgRate: campaign.crgRate,
        brokerDetails: _.cloneDeep(campaign.brokerDetails ? campaign.brokerDetails : itemInitialValues.brokerDetails),
        dailyFtdsLimit: campaign.dailyFtdsLimit,
        affiliateIds: campaign.affiliateIds ? GeneratedLeadFieldValue.createArray(campaign.affiliateIds):[],
        sources: campaign.sources ? GeneratedLeadFieldValue.createArray(campaign.sources):[],
      };
      console.log('this.campaignForm.countryPayments', this.campaignForm.countryPayments);
      console.log('this.campaignForm.aff_countryPayments', this.campaignForm.aff_countryPayments);
      this.childKey += 1;
      this.initialForm = _.cloneDeep(this.campaignForm);
      this.checkBrokersDetails();
    },
    async updateData() {
      await store.dispatch('get_all_resources_one_request', { vm: this });
      if (this.$route.path === '/create-campaign' && !isAdmin(this.user) && isManager(this.user)) {
        this.campaignForm.managers = [{ _id: this.user._id, name: this.user.name }];
      }
      // await this.$store.dispatch('get_campaigns', { vm: this });
      // await this.$store.dispatch('get_affiliates', { vm: this });
      // await this.$store.dispatch('get_managers', { vm: this });
      // this.backdoor += 1;
    },
    checkBrokersDetails() {
      checkBrokersDetails(this.token, this.campaignForm.brokerDetails)
        .then((r) => {
          this.brokerCheckResult = _.cloneDeep(r.data);
          this.brokerDetailsBackdoor += 1;
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.brokerCheckResult = {
              GO_Details: [],
              generalizedComments: [],
              worstRating: 'Unknown',
              worstNumberRating: -1,
            };
            this.brokerDetailsBackdoor += 1;
            handleRequestError(this, e, 'Not enough data to check brokers details');
          } else {
            handleRequestError(this, e, 'Server error while checking brokers details');
          }
        });
    },
    copyIntegrationToClipboard(data, name = 'custom field') {
      console.log('copyIntegrationToClipboard', data);
      const toCopy = typeof data !== 'object' ? data : JSON.stringify(data, null, 4);
      // if (typeof data !== 'object') {
      const dummy = $('<textarea  style="opacity: 100%">').val(toCopy).appendTo('body').select();
      document.execCommand('copy');
      dummy.remove();
      showCard(
        this,
        `Copied Integration Settings: ${
          typeof data === 'object' ? Object.keys(data).join(', ') : name
        }`,
        'success',
        ''
      );
      // }
    },
    copyFullSettingsToClipboard() {
      const {
        brokerApiType,
        dontUseRequestInterceptor,
        brokerApiURL,
        brokerAuthHeaders,
        brokerAuthBody,
        brokerPullURL,
        brokerPullAuthHeaders,
        brokerApiMapping,
        universalIntegrationSettings,
      } = _.cloneDeep(this.campaignForm);
      const data = {
        brokerApiType,
        dontUseRequestInterceptor,
        brokerApiURL,
        brokerAuthHeaders,
        brokerAuthBody,
        brokerPullURL,
        brokerPullAuthHeaders,
        brokerApiMapping,
        universalIntegrationSettings,
      };
      this.copyIntegrationToClipboard(JSON.stringify(data, null, 4));
    },
    pasteJson() {
      const data = JSON.parse(this.jsonToPaste);
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i += 1) {
        this.campaignForm[keys[i]] = _.cloneDeep(data[keys[i]]);
      }
      this.childKey += 1;
    },
  },
  mounted() {
    this.updateData();
    if (this.$route.params.id) this.getCampaign(this.$route.params.id);
  },
  watch: {
    $route() {
      // this will be called any time the route changes
      console.log(this.$route.path);
      this.updateData();
      if (this.$route.params.id) this.getCampaign(this.$route.params.id);
      else {
        this.campaignForm = _.cloneDeep(itemInitialValues);
        this.campaignForm.managers = [{ _id: this.user._id, name: this.user.name }];
        this.initialForm = {};
      }
    },
    // 'campaignForm.brokerDetails.brokerName': function () {
    //   this.checkBrokersDetails();
    // },
    // 'campaignForm.brokerDetails.skypeName': function () {
    //   this.checkBrokersDetails();
    // },
    // 'campaignForm.brokerDetails.skypeUserName': function () {
    //   this.checkBrokersDetails();
    // },
  },
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
