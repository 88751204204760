<template>
  <b-container>
    <div class="my-4" style="display: block">
      <h2 class="mr-3" style="display: inline; vertical-align: middle">
        {{ _id ? 'Edit' : 'Add' }} Worker
      </h2>
    </div>
    <b-form-group
      class="my-2"
      label="Name"
      label-for="workerform-name"
      :invalid-feedback="invalidFeedback.worker.name"
      :state="isValid.fields.name"
    >
      <b-form-input
        type="text"
        id="workerform-name"
        v-model="workerForm.name"
        :state="isValid.fields.name"
        :disabled="requesting"
      ></b-form-input>
    </b-form-group>

    <b-form-group class="my-2" label="Callback" label-for="workerform-callback">
      <b-form-checkbox
        id="workerform-callback"
        v-model="workerForm.callback"
        :disabled="requesting"
      ></b-form-checkbox>
    </b-form-group>

    <b-form-group
      v-if="!isAffiliate"
      class="my-2"
      label="Affiliates"
      label-for="workerform-affiliates"
      :state="isValid.fields.affiliates"
    >
      <multiselect
        id="workerform-affiliates"
        v-model="workerForm.affiliates"
        :options="affiliatesOptions"
        track-by="name"
        label="name"
        :closeOnSelect="false"
        :multiple="true"
        :disabled="requesting"
      ></multiselect>
    </b-form-group>
    <div class="d-flex justify-content-between mb-3">
      <b-button @click="saveWorker()" variant="primary" :disabled="!isValid.form || requesting">
        <b-spinner small v-if="requesting"></b-spinner>
        {{ workerForm._id ? 'Save Worker' : 'Create Worker' }}
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import '@/assets/form.scss';
import { getWorker, saveWorker } from '@/utils/api';
import { deleteUnchangedFields, handleRequestError, isAdmin, showCard } from '@/utils';
import { validFeedback, invalidFeedback } from '@/consts';
import { isValid, formValid } from '@/validators';
import _ from 'lodash';

const itemInitialValues = {
  _id: undefined,
  name: '',
  affiliates: [],
  actions: [],
  callback: false,
};

export default {
  name: 'WorkerCreate',
  components: {},
  props: {
    _id: { type: String, default: '' },
    action_id: { type: String, default: '' },
    checkAvailability: { type: Boolean, default: false },
  },
  data() {
    return {
      servicename: process.env.VUE_APP_SERVICENAME.toLowerCase(),
      validFeedback,
      invalidFeedback,
      backdoor: 0,
      workerForm: _.cloneDeep(itemInitialValues),
      initialForm: {},
    };
  },
  computed: {
    affiliatesOptions() {
      return this.affiliates
        .filter((a) => a.hasWorkers)
        .map((a) => {
          return { name: a.name, _id: a._id };
        });
    },
    ...mapState([
      'token',
      'campaigns',
      'affiliates',
      'managers',
      'requesting',
      'user',
      'isAffiliate',
    ]),
    isValid() {
      this.backdoor;
      const fields = isValid('worker', this.workerForm, this.workerForm);
      return {
        fields,
        form: formValid(fields),
      };
    },
  },
  methods: {
    saveWorker() {
      if (this.isValid.form) {
        this.$store.commit('make_requesting', { req: true });
        let toSave = _.cloneDeep(this.workerForm);
        toSave.affiliates = toSave.affiliates.map((c) => c._id);
        toSave = deleteUnchangedFields(this.initialForm, this.workerForm, toSave);
        if (this.checkAvailability && this.action_id && this.action_id !== '') {
          toSave.mods = { checkAvailability: this.checkAvailability, action_id: this.action_id };
        }
        saveWorker(this.token, toSave)
          .then(async (r) => {
            if (r.status === 200) {
              showCard(this, `${r.data.name} Worker saved`, 'success');
              this.castWorkerToForm(r.data);
              if (this.checkAvailability && this.action_id && this.action_id !== '') {
                this.$emit('workerSaved', _.cloneDeep(r.data));
              }
              this.backdoor += 1;
            } else {
              const cardError = r.data.error || `${r.status}: Unknown error on saving Worker`;
              showCard(this, cardError, r.status === 500 ? 'danger' : 'warning');
            }
            this.$store.commit('make_requesting', { req: false });
          })
          .catch((e) => {
            handleRequestError(this, e, `Unknown server error on saving Worker`);
            this.$store.commit('make_requesting', { req: false });
          });
      }
    },
    getWorker(_id) {
      console.log('Getting worker', _id);
      getWorker(this.token, _id)
        .then((r) => {
          if (r.status === 200) {
            this.castWorkerToForm(r.data);
            this.backdoor += 1;
          } else {
            const cardError = r.data.error || `${r.status}: Unknown error on getting worker`;
            showCard(
              this,
              cardError,
              r.status === 500 ? 'danger' : 'warning',
              'Error getting worker'
            );
          }
        })
        .catch((e) => {
          handleRequestError(
            this,
            e,
            'Unknown server error on getting worker',
            'Error getting worker'
          );
        });
    },
    castWorkerToForm(worker) {
      const initial = _.cloneDeep(itemInitialValues);
      // cast campaign to edit form
      // prettier-ignore
      this.workerForm = {
        _id: worker._id ? worker._id : initial.id,
        affiliates: worker.affiliates.filter(a => a).map((a) => {
          return {_id: a._id ? a._id : a, name: a.name ? a.name : 'unknown' };
        }),
        name: worker.name ? worker.name : initial.name,
        callback: worker.callback ? worker.callback : initial.callback,
      };
      this.initialForm = _.cloneDeep(this.workerForm);
    },
    async updateData() {
      // await store.dispatch('get_all_resources_one_request', { vm: this });
      if (!this.workerForm._id && !isAdmin(this.user)) {
        this.workerForm.affiliates = [{ name: this.user.name, _id: this.user._id }];
      }
    },
  },
  mounted() {
    this.updateData();
    if (this._id && this._id !== '') this.getWorker(this._id);
    else {
      this.workerForm = _.cloneDeep(itemInitialValues);
      if (!isAdmin(this.user))
        this.workerForm.affiliates = [{ name: this.user.name, _id: this.user._id }];
      this.initialForm = {};
    }
  },
  watch: {},
};
</script>
