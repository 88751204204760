<template>
  <b-card>
    <b-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <b-icon icon="file-earmark-arrow-up" font-scale="1.5"></b-icon>
          <h5 class="mb-0 ml-2">Pulling Leads and FTDs</h5>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center">
        <span class="font-weight-bold">API URL:</span>
        <a href="#" class="ml-2" @click.prevent="copyApiUrl">{{ fullApiUrl }}</a>
        <b-button variant="outline-secondary" @click="copyApiUrl" class="mx-3">
          <i class="bi bi-clipboard"></i>
          Copy URL
        </b-button>
      </div>
      <p class="font-weight-bold">Request type: POST</p>
      <div class="mb-3">
        <span class="font-weight-bold">Headers:</span>
        <pre><code>Content-type: application/json</code></pre>
      </div>
      <p class="font-weight-bold">Data format: JSON</p>

      <div class="mb-3">
        <span class="font-weight-bold">Data keys:</span>
        <pre>
            <code>
email: your aff account email for authentication
password: your aff password for authentication
type: "lead" | "ftd"
To pull leads and ftds respectively
page: number;
number of page to pull. Starting from 1
itemsPerPage: number;
Default: 250; Min: 10; Max: 15000
from: start date from, actions from which to pull
date format: "YYYY-MM-DD HH:mm:ss"
to: end date, actions till which to pull
date format: "YYYY-MM-DD HH:mm:ss"
            </code>
          </pre>
      </div>
      <div class="mb-3">
        <span class="font-weight-bold">Request data example:</span>
        <pre><code>{{ requestDataExample }}</code></pre>
      </div>
      <div class="mb-3">
        <p class="font-weight-bold">Notes:</p>
        <ul>
          <li>All dates are in UTC timezone</li>
          <li>
            When pulling type "lead", 'from' and 'to' dates take into account the date of lead
            registration.
          </li>
          <li>When pulling type "ftd", 'from' and 'to' dates take into account the date of ftd</li>
        </ul>
      </div>
      <br />
      <div class="mb-3">
        <span class="font-weight-bold">Response schema:</span>
        <pre><code>{{ responseSchema }}</code></pre>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState } from 'vuex';
import copyToClipboard from '@/utils/clipboard';

export default {
  name: 'PullingLeadsDocs',
  props: {
    apiUrl: String,
    email: String,
  },
  computed: {
    ...mapState(['token']),
    fullApiUrl() {
      return `${this.apiUrl}:2053/action/pull`;
    },
    requestDataExample() {
      return JSON.stringify(
        {
          email: this.email,
          password: 'YOUR PASSWORD',
          type: 'lead',
          page: 1,
          itemsPerPage: 250,
          from: '2022-11-15 00:00:00',
          to: '2022-11-25 23:59:59',
        },
        null,
        2
      );
    },
  },
  data() {
    return {
      responseSchema: `
Array of actions records. Each action looks like the following:
{
  action_id: string;

  ip: string;

  location: string;

  type: string;

  saleStatus: string;

  leadContactInfo: undefined | {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    phone: string;
    language: string;
    country: string;
    ip: string;
    geoip_country: string;
    so: string;
  };

  lead_date: Date | null;

  ftd_date: Date | null;

  regDate: Date;

  aff_payout: number;

  aff_currency: "USD";
}
        `,
    };
  },
  methods: {
    copyApiUrl() {
      copyToClipboard(this.fullApiUrl);
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
